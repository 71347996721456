import { Box, Button, Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import PaperBox from "components/PaperBox"
import { allRoutes } from "generated/routes"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  image: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    color: theme.palette.grey[50],
    textAlign: "center",
    minHeight: "220px",
    backgroundImage: (props) => `url(${props.image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    marginBottom: 25
  },
  details: {
    paddingTop: 15,
    textAlign: "center",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginRight: 25,
    marginLeft: 25
  },
  info: {
    marginBottom: 15,
    paddingRight: 25,
    paddingLeft: 25
  },
  button: {
    marginBottom: 25,
    textAlign: "center"
  }
}))

export const ExaminationMiniInfoCard = (props) => {
  const { item } = props
  const styleProps = {
    image: "/assets/learning/card_".concat(item.examinationType).concat(".jpg")
  }

  const { t } = useTranslation()
  const classes = useStyles(styleProps)

  return (
    <PaperBox p={0} {...props}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item className={classes.image} xs={12}></Grid>

        <Grid item className={classes.button} xs={12}>
          <Typography variant="paragraphLarge">{t("learning.titles.getStarted")}</Typography>
          <Box mt={2} mb={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={NavLink}
              to={allRoutes.LearningUserExamsExamination(item.userExaminationId)}>
              {t("learning.buttons.startCourse")}
            </Button>
            <Typography mt={3} variant="bodySmall" color="textSecondary">
              {t("learning.titles.save_on_submit")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </PaperBox>
  )
}
