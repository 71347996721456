import theme from "css/theme/light"
import { Calendar, CalendarProps } from "react-big-calendar"
import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"

export const StyledBigCalendar = styled<React.ComponentType<CalendarProps<object, object>>>(
  Calendar as unknown as React.ComponentType<CalendarProps<object, object>>
)({
  fontFamily: "Poppins, sans-serif;",
  "& button": {
    fontFamily: "Poppins, sans-serif;"
  },
  "& .rbc-header": {
    color: theme.palette.grey[500],
    textTransform: "capitalize",
    "& .columnheader": {}
  },
  "& .rbc-month-view": {
    borderRadius: "11px",
    border: 0,
    "& .rbc-header": {
      border: 0
    },
    "& .rbc-month-row": {
      borderRight: "1px solid #E0E0E0",
      borderLeft: "1px solid #E0E0E0",
      "&:nth-child(2)": {
        borderTop: "1px solid #E0E0E0",
        borderStartEndRadius: "8px"
      },
      "&:last-child": {
        borderBottom: "1px solid #E0E0E0",
        borderEndEndRadius: "8px"
      }
    }
  },
  "& .rbc-timeslot-group": {
    minHeight: "70px"
  },
  "& .rbc-row-segment": {
    marginBottom: "2px"
  },
  "& .rbc-day-slot .rbc-time-slot": { borderTop: 0 },
  "& .rbc-time-view": {
    borderRadius: "11px",
    border: 0,
    "& .rbc-time-content": {
      border: 0,
      overflowY: "scroll",
      borderRight: "1px solid #E0E0E0",
      borderStartEndRadius: "8px",
      borderEndEndRadius: "8px"
    },
    "& .rbc-time-header-content": { border: 0 },

    "& .rbc-time-header.rbc-overflowing": {
      border: 0
    },
    "& .rbc-time-gutter": {
      borderStartStartRadius: "8px",
      borderEndStartRadius: "8px",
      backgroundColor: "#D9EEF4",
      color: "rgba(0, 0, 0, 0.55)",
      "& .rbc-timeslot-group": {
        border: 0
      }
    },
    "& .rbc-header": {
      border: 0
    },
    "& .rbc-month-row": {
      borderRight: "1px solid #E0E0E0",
      borderLeft: "1px solid #E0E0E0",
      "&:nth-child(2)": {
        borderTop: "1px solid #E0E0E0",
        borderStartEndRadius: "8px"
      },
      "&:last-child": {
        borderBottom: "1px solid #E0E0E0",
        borderEndEndRadius: "8px"
      }
    }
  },
  "& .rbc-day-bg": {
    "&.rbc-off-range-bg": { background: "rgba(217, 230, 234, 0.25)" },
    "&.rbc-today": { background: "transparent" }
  },
  "& .rbc-event": {
    height: "40px",
    padding: 0,
    "& > div > div": {
      padding: "2px 5px",
    }
  },
  "& .rbc-event-label": {
    display: "none"
  },
  "& .rbc-date-cell": {
    display: "flex",
    justifyContent: "flex-end",

    "& > button": {
      fontWeight: 600,
      lineHeight: "140%",
      fontSize: "14px",
      marginTop: "5px",

      color: "rgba(0, 0, 0, 0.75)"
    }
  },
  "& .rbc-date-cell.rbc-now.rbc-current > button": {
    border: "1px solid #0093B2",
    borderRadius: "100%",
    width: "24px",
    height: "24px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#0093B2"
  },
  "& .rbc-today": {
    backgroundColor: "transparent"
  },
  ".rbc-time-view .rbc-row": {
    minHeight: "auto",
  },
  ".rbc-month-view .rbc-row": {
    minHeight: "auto",
  },
  "& .rbc-show-more": {
    fontSize: "14px",
    color: theme.palette.primary.main,
    margin: "15px"
  },
  "& .rbc-current-time-indicator": {
    backgroundColor: "#254467"
  },
  "&.rbc-week-view": {
    "& .rbc-label.rbc-time-header-gutter": {
      borderStartStartRadius: "8px",
      backgroundColor: "#D9EEF4"
    },
    "& .rbc-header": {
      border: "1px solid #ddd",
      borderRight: 0,
      "&:last-child": {
        borderRight: "1px solid #ddd",
        borderStartEndRadius: "8px"
      }
    },
    "& .rbc-time-gutter": {
      borderStartStartRadius: 0
    },
    "&  .rbc-time-content": {
      borderStartEndRadius: 0
    }
  }
})
export const ShortTypography = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  lineHeight: "1.5em",
  maxHeight: "1.5em",
  whiteSpace: "normal"
})

export const StyledTypographyOneLine = styled(Typography)({
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: "11px"
})

export const StyledTypographyTime = styled(Typography)(({ theme }) => ({
  fontSize: "11px"
}))