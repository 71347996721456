import { faCloudArrowDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, Link } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { useStyles } from "../../../components/useStyles"

const EmailBoxUploadedFiles = ({ file }) => {
  const classes = useStyles()

  return (
    <Box>
      <Grid pt={1} pb={1} container direction="row" alignItems="center">
        <FontAwesomeIcon className={classes.strongBlueText} icon={faCloudArrowDown} size="md" />
        <Link className={classes.strongBlueText} href={file.url} underline="hover">
          {file.name}
        </Link>
      </Grid>
    </Box>
  )
}

export default EmailBoxUploadedFiles
