import React from "react"

import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, FormControlLabel, IconButton, Switch, Tooltip, Typography } from "@mui/material"
import CompanyDepartmentSelection from "components/CompanyDepartmentSelection"

const FilterForm = ({
  onChange,
  setState,
  state,
  showShowAll,
  companies,
  showAllCompanies,
  setShowAllCompanies
}) => {
  return (
    <Box flexDirection="horizontal" mt={2}>
      <Typography variant="caption" gutterBottom>
        Bolag och avdelningar
      </Typography>
      {showShowAll ? (
        <Box mt={2}>
          <FormControlLabel
            control={
              <Switch
                onChange={(event) => {
                  const checked = event.target.checked
                  setShowAllCompanies(checked)
                }}
                id="showAllCompanies"
                checked={showAllCompanies}
              />
            }
            label="Filtrera på alla bolag"
          />
          <Tooltip title="Som administratör kan du även filtrera på bolag din användare inte tilldelats.">
            <IconButton size="large">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}
      <CompanyDepartmentSelection
        //key={companies} // component will rerender whenever key changes
        companies={companies} // only state causes component to rerender, not props
        initCompanies={state.companyIds}
        initDepartments={state.departmentIds}
        onChange={(newState) => {
          const companyIds = []
          const departmentIds = []

          newState.forEach((c) => {
            if (c.selected) companyIds.push(c.id)
            c.departments.forEach((d) => {
              if (d.selected) departmentIds.push(d.id)
            })
          })

          setState((s) => {
            return {
              ...s,
              companyIds,
              departmentIds
            }
          })
          onChange({
            ...state,
            companyIds,
            departmentIds
          })
        }}
      />
    </Box>
  )
}
export default FilterForm
