import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative"
    },
    label: {
        color: "rgba(0, 0, 0, 0.54)",
        top: "-11px",
        left: "9px",
        padding: "0 5px",
        background: theme.palette.background.paper,
        position: "absolute"
    }
}));

function PaperCard(props) {
    const { children, label } = props;
    const classes = useStyles();
    return (
        <Box className={classes.container} bgcolor="background.paper" border={1} borderColor="grey.400" borderRadius={2} {...props}>
            <Typography className={classes.label} variant="caption">
                {label}
            </Typography>
            <Box spacing={3} px={2} py={2}>
                {children}
            </Box>
        </Box>
    );
}

export default PaperCard;
