import { faLayerGroup } from "@fortawesome/pro-solid-svg-icons"
import { FilterList, GetApp, Print } from "@mui/icons-material"
import { Box, Button, Grid, Skeleton, Stack, TableFooter, Typography } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { LoadingButton } from "components/Button"
import NoResultFound from "components/Dialogs/NoResultFound"
import ErrorDialog from "components/ErrorDialog"
import { matchSorter } from "match-sorter"
import React, { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { AppContext } from "components/AppContextProvider"
import { useHistory } from "react-router-dom"
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table"
import { DefaultColumnFilter, GlobalFilter } from "./ClpTableFilterTypes"
import SubTable from "./SubTable"
import ClpTableRow from "./components/ClpTableRow"
import ManualPagination from "./components/ManualPagination"
import MoreFilters from "./components/MoreFilters"
import Pagination from "./components/Pagination"
import RadioButtons from "./components/RadioButtons"
import TableHeader from "./components/TableHeader"
import PrintTemplate from "./components/pdfTemplate/PrintTemplate"
import { useStyles } from "./style.js"
import useTableExport from "./useTableExport.js"
const emptyState = {
  pageSize: 25,
  pageIndex: 0,
  expanded: {},
  sortBy: [],
  filters: [],
  hiddenColumns: []
}

export default function ClpTable(props) {
  const classes = useStyles()
  const appContext = useContext(AppContext)
  const [inititalValuesContext, setValues] = useState(null)

  useEffect(() => {
    const initialValues =
      JSON.stringify(appContext.tableState.columns) === JSON.stringify(props.columns.map((x) => x.Header))
        ? appContext.tableState.state
        : emptyState
    setValues(initialValues)
  }, [props.columns])
  const {
    columns,
    rows,
    rowProps,
    cellProps,
    totalPage,
    subColumns,
    footer,
    manualPagination,
    manualSortBy,
    currentpage,
    setPage,
    setPageSizeManual,
    manualPageSize,
    hideFilter,
    hideSubFilter,
    hideSearch,
    manualSetSortState,
    manualSortState,
    filters,
    print,
    printTitle,
    status,
    initialFilters,
    sortParams
  } = props

  return inititalValuesContext !== null ? (
    <div className={classes.root}>
      <TableEl
        hideSubFilter={hideSubFilter}
        columns={columns}
        data={rows}
        rowProps={rowProps}
        cellProps={cellProps}
        subColumns={subColumns}
        footer={footer}
        totalPage={totalPage}
        manualPagination={manualPagination}
        manualSortBy={manualSortBy}
        currentpage={currentpage}
        setPage={setPage}
        setPageSizeManual={setPageSizeManual}
        manualPageSize={manualPageSize}
        hideFilter={hideFilter}
        hideSearch={hideSearch}
        manualSetSortState={manualSetSortState}
        manualSortState={manualSortState}
        filters={filters}
        print={print}
        printTitle={printTitle}
        status={status}
        initialFilters={initialFilters}
        inititalValuesContext={inititalValuesContext}
        sortParams={sortParams}></TableEl>
    </div>
  ) : (
    <></>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}
fuzzyTextFilterFn.autoRemove = (val) => !val
function customFilterFn(rows, id, filterValue) {
  return rows.filter((row) => {
    if (filterValue === "True" && (row.values[id] !== null || row.values[id] !== "")) {
      return row.values[id]
    } else if (filterValue === "False" && row.values[id] === "") {
      return row.values
    }
  })
}
customFilterFn.autoRemove = (val) => !val

function TableEl({
  columns,
  subColumns,
  footer,
  rowProps,
  cellProps,
  data,
  totalPage,
  manualPagination,
  currentpage,
  setPage,
  setPageSizeManual,
  manualPageSize,
  manualSortBy,
  hideFilter,
  hideSearch,
  manualSetSortState,
  manualSortState,
  hideSubFilter,
  filters,
  print,
  printTitle,
  status,
  initialFilters,
  inititalValuesContext,
  sortParams
}) {
  const [filtersOpen, expandFilters] = useState(false)
  const [exportTableState, exportTable] = useTableExport(footer)
  const appContext = useContext(AppContext)

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      customFilter: customFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]

          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  )

  const filterParams = initialFilters ? initialFilters : []
  for (const [key, value] of new URLSearchParams(useLocation().search)) {
    filterParams.push({ id: key, value: value })
  }

  const sort = sortParams ? sortParams : []
  const tableData = React.useMemo(
    () => (status === 1 ? Array(20).fill({}) : (status === undefined || status === 2) && data),
    [status, data]
  )
  const tableColumns = React.useMemo(
    () =>
      status === 1
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton />
          }))
        : status === undefined || status === 2
        ? columns
        : [],
    [status, columns]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    visibleColumns,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize = 0 }
  } = useTable(
    {
      subColumns,
      footer,
      columns: tableColumns,
      data: tableData,
      defaultColumn,
      filterTypes,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state
          }),
          [state]
        )
      },
      initialState: {
        ...inititalValuesContext,
        filters: filterParams.length > 0 ? filterParams : [],
        sortBy: sort.length > 0 ? sort : []
      },
      pageCount: manualPagination && totalPage,
      manualPagination: manualPagination
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  )

  const history = useHistory()

  useEffect(() => {
    const handleLeave = () => {}
    const unlisten = history.listen((location, action) => {
      appContext.setTableState(() => {
        return {
          state: state,
          columns: columns.map((x) => x.Header)
        }
      })
      if (action === "POP") {
        handleLeave()
      }
    })

    return () => {
      unlisten()
      handleLeave()
    }
  }, [history, state])

  const classes = useStyles()

  const renderRowSubComponent = React.useCallback(({ row, subColumns }) => {
    return (
      row.original.subRows && (
        <Box>
          <SubTable hideSubFilter={hideSubFilter} rows={row.original.subRows} columns={subColumns} />
        </Box>
      )
    )
  }, [])

  const renderNoRows = () => {
    return <NoResultFound icon={faLayerGroup} message="Inga resultat hittades" color="primary" size="lg" />
  }

  const renderFooter = () => {
    return (
      <TableFooter className={classes.footerRow}>
        {footer && footer.length > 0 && (
          <TableRow>
            <TableCell>TOTALT:</TableCell>
            {footer.map((item) => (
              <TableCell key={item}>
                {item.value > 0 && parseFloat(item.value).toFixed(2).replace(".", ",")}
              </TableCell>
            ))}
          </TableRow>
        )}
      </TableFooter>
    )
  }

  const renderPages = () => {
    return (
      <>
        {manualPagination ? (
          <ManualPagination
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            setPage={setPage}
            currentpage={currentpage}
            totalPage={totalPage}
          />
        ) : (
          <Pagination
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            setPage={setPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        )}
      </>
    )
  }
  const [open, setOpen] = React.useState(false)

  const toggleRowOpen = (id) => {
    if (open === id) {
      setOpen(false)
    } else {
      setOpen(id)
    }
  }

  return (
    <>
      <Box marginX={2} paddingTop={2}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item className={classes.globalFilter}>
            {!hideSearch && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            )}

            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column, index) => {
                return (
                  !column.hiddenFilter &&
                  column.mainFilter && (
                    <Grid item key={index}>
                      {column.render("Filter", column.render("Header"))}
                    </Grid>
                  )
                )
              })
            )}
          </Grid>

          <Grid item>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              {!hideFilter && (
                <Grid item>
                  <Button
                    color="primary"
                    startIcon={<FilterList />}
                    onClick={() => expandFilters(!filtersOpen)}>
                    {filtersOpen ? "Dölj filter" : "Visa alla filter"}
                  </Button>
                </Grid>
              )}
              <Grid item>
                {exportTableState > 2 && (
                  <ErrorDialog
                    title="Exporten misslyckades"
                    message="Exporten misslyckades, minska datats storlek genom att använda filtren och försök igen."
                  />
                )}
                {exportTableState === 1 ? (
                  <LoadingButton />
                ) : (
                  <Button
                    color="primary"
                    startIcon={<GetApp />}
                    onClick={() => {
                      exportTable(columns, rows, prepareRow)
                    }}>
                    <span>Exportera</span>
                  </Button>
                )}
                {print && (
                  <PDFDownloadLink
                    document={
                      <PrintTemplate
                        columns={columns}
                        data={rows}
                        prepareRow={prepareRow}
                        printTitle={printTitle}
                      />
                    }
                    fileName="Planering.pdf"
                    style={{ textDecoration: "none" }}>
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Laddar utskrift...</Button>
                      ) : (
                        <Button color="primary" startIcon={<Print />}>
                          Skriv ut
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                )}
              </Grid>
              <Grid item>
                <RadioButtons
                  manualPageSize={manualPageSize}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  setPageSizeManual={setPageSizeManual}
                  manualPagination={manualPagination}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {filtersOpen && <MoreFilters headerGroups={headerGroups} />}

      <Box marginX={2}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
          <Grid item>{renderPages()} </Grid>
        </Grid>
      </Box>
      <Box item className={classes.tableScroll}>
        <Table size="small" {...getTableProps()} className={classes.table}>
          <TableHead>
            {headerGroups.map((headerGroup, i) => {
              return (
                <TableHeader
                  key={i}
                  headerGroup={headerGroup}
                  subColumns={subColumns}
                  manualSortBy={manualSortBy}
                  manualSetSortState={manualSetSortState}
                  manualSortState={manualSortState}
                />
              )
            })}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <ClpTableRow
                  key={i}
                  hideSubFilter={hideSubFilter}
                  row={row}
                  rowProps={rowProps}
                  subColumns={subColumns}
                  toggleRowOpen={toggleRowOpen}
                  open={open}
                  visibleColumns={visibleColumns}
                  renderRowSubComponent={renderRowSubComponent}
                  cellProps={cellProps}
                />
              )
            })}
          </TableBody>

          {renderFooter()}
          {data.length > 0 && (
            <TableRow>
              <TableCell colSpan="20" align="left">
                <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2">Totalt: {rows.length} rader</Typography>

                  {renderPages()}
                </Stack>
              </TableCell>
            </TableRow>
          )}
        </Table>
        {data.length < 1 && renderNoRows()}
      </Box>
    </>
  )
}

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id]

    return rowValue >= filterValue
  })
}
filterGreaterThan.autoRemove = (val) => typeof val !== "number"
