import React from "react";
import { MapContextProvider } from "./components/MapContextProvider";
import Header from "./components/Header/Header";
import DeviceBar from "./components/DeviceBar/DeviceBar";
import LoadingMarkers from "./components/LoadingMarkers";
import "./components/marker.css";
import FullscreenMap from "./components/FullscreenMap";
import MapLayerIcons from "./components/Header/MapLayerIcons";

const Map = () => {
    return (
        <div>
            <MapContextProvider>
                <LoadingMarkers />
                <Header />
                <DeviceBar />
                <FullscreenMap />
                <MapLayerIcons />
            </MapContextProvider>
        </div>
    );
};
export default Map;
