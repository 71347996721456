import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material"
import { useApiDelete, useApiGet, useApiPut } from "auth/useApi2"
import ClpButton from "components/Button"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { PageTabs } from "components/navigation/PageTabs"
import PaperBox from "components/PaperBox"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import ArticleForm from "../../components/ArticleForm"

const EditNews = ({ match }) => {
  const [saveArticleResult, saveArticle] = useApiPut()
  const [getArticleResult, getArticle] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)
  const [deleteResult, deleteNews, resetDeleteState] = useApiDelete()

  const routerHistory = useHistory()

  useEffect(() => {
    // om man lyckats tagit bort nyheten redirectar vi tillbaks till listan
    if (deleteResult.status === 2) routerHistory.push(allRoutes.NewsAdmin())
  }, [deleteResult.status, routerHistory, match])

  useEffect(() => {
    getArticle(apiEndpoints.newsadmin.getarticle.replace("{id}", match.params.id))
  }, [getArticle, match.params.id])

  const [customerResult, getCustomer] = useApiGet()

  useEffect(() => {
    getCustomer(apiEndpoints.newsadmin.getcustomer)
  }, [getCustomer])

  useEffect(() => {
    loadingContext.setLoading(() => getArticleResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => customerResult.status === 1)
  })

  const feedback = {
    errorTitle: "Nyhet kunde inte sparas"
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const closeSettingsDropdown = () => {
    setAnchorEl(null)
  }

  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openRemoveDialog = () => {
    resetDeleteState()
    setDialogOpen(true)
  }

  const closeRemoveDialog = () => {
    resetDeleteState()
    setDialogOpen(false)
  }

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.NewsAdmin(),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        <Grid container justifyContent="center">
          <Grid item md={10} lg={8}>
            <Box my={3}>
              <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h2">Redigera nyhet</Typography>
              </Grid>
            </Box>

            {customerResult.status === 2 && getArticleResult.status === 2 ? (
              <ArticleForm
                customer={customerResult.data}
                editItem={getArticleResult.data}
                onSubmit={(values) => {
                  const toSave = {
                    ...values,
                    priorityEndDate: values.priorityEndDate === "" ? null : values.priorityEndDate
                  }
                  saveArticle(apiEndpoints.newsadmin.updatearticle, toSave)
                }}
                result={saveArticleResult}
                feedback={feedback}
              />
            ) : null}

            <Box marginTop={15} marginBottom={10}>
              <PaperBox>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignContent="center"
                  spacing={3}>
                  <Grid item>
                    <Typography variant="h5">Ta bort nyhetsinlägg</Typography>
                    <Typography variant="bodySmall" color="textSecondary">
                      Du kan inte ångra ditt val när du tagit bort inlägget.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button color="primary" onClick={openRemoveDialog}>
                      Ta bort nyhet
                    </Button>
                  </Grid>
                </Grid>
              </PaperBox>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dialog
              open={dialogOpen}
              onClose={closeSettingsDropdown}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{"Ta bort nyheten?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Är du säker att du vill ta bort nyheten? Ditt val går inte att ångra.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeRemoveDialog}>Avbryt</Button>
                <ClpButton
                  result={deleteResult}
                  onClick={() => {
                    deleteNews(apiEndpoints.newsadmin.deletearticle.replace("{id}", match.params.id))
                  }}
                  color="primary"
                  autoFocus>
                  Ja, jag är säker
                </ClpButton>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default EditNews
