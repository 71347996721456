import { Box, Container, Grid, Typography } from "@mui/material";
import { useApiGet, useApiPut } from "auth/useApi2";
import { apiEndpoints } from "generated/apiEndpoints";
import React, { useEffect } from "react";
import { ContractForm } from "../../components/ContractForm";
const AddContract = ({ match }) => {
    const [updateContractResult, updateContract] = useApiPut();
    const [contractResult, getContract] = useApiGet();

    const handleSubmit = (objectToSave) => {
        updateContract(apiEndpoints.vehicleregistryadmin.updatecontract, objectToSave);
    };
    useEffect(() => {
        getContract(apiEndpoints.vehicleregistryadmin.getcontractbyid.replace("{id}", match.params.id));
    }, [getContract, match.params.id]);

    const feedback = {
        errorTitle: "Avtal kunde inte skapas"
    };

    return (
        <div>
            <Container>
                <Grid container justifyContent="center">
                    <Grid item md={8} lg={6}>
                        <Box my={3}>
                            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h2">Redigera avtal</Typography>
                            </Grid>
                        </Box>

                        <ContractForm onSubmit={handleSubmit} result={updateContractResult} feedback={feedback} editContract={contractResult.data} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};
export default AddContract;
