import XLSX from "xlsx"

export const excelDateToJSDate = (excelDate: string): Date => {
  const parsedDate = XLSX.SSF.parse_date_code(parseFloat(excelDate))

  return new Date(
    parsedDate.y,
    parsedDate.m - 1,
    parsedDate.d,
    parsedDate.H,
    parsedDate.M,
    parsedDate.S,
    parsedDate.u
  )
}
