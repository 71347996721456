import { faCheck, faCircle, faWarning } from "@fortawesome/pro-solid-svg-icons"
import theme from "css/theme/light"
import { workorderPrimaryStatusColor } from "helpers/WorkOrder/WorkOrderStatusColor"
import { workorderStatusIcon } from "helpers/WorkOrder/WorkOrderStatusIcon"
import { workorderStatusLabel } from "helpers/WorkOrder/WorkOrderStatusLabel"
import { checkLightStatusColor, checksMainStatusColor, VehicleCheckStatus } from "helpers/Checks/ChecksStatusColor"
import { checkStatusIcon } from "helpers/Checks/CheckStatusIcon"

export const eventIcon = (status: any) => {
  switch (status) {
    case "approved":
      return faCheck
    case "error":
      return faWarning
    default:
      return faCircle
  }
}

export const eventColor = (status: any) => {
  switch (status) {
    case "approved":
      return theme.palette.success.dark
    case "error":
      return theme.palette.error.dark
    default:
      return theme.palette.primary.light
  }
}

export const eventStatusLabel = (status: number) => {
  switch (status) {
    case 2:
      return "Godkänd"
    case 3:
      return "Kritisk"
    default:
      return "Saknar status"
  }
}

function checksStatusLabel(status: VehicleCheckStatus) {
  switch (status) {
    case VehicleCheckStatus.Booked:
      return "Bokad"
    case VehicleCheckStatus.Upcoming:
      return "Behöver bokas"
    case VehicleCheckStatus.Late:
      return "Körförbud"
    case VehicleCheckStatus.passed:
      return "Godkänd"
    case VehicleCheckStatus.failed:
      return "Underkänd"
    case VehicleCheckStatus.waiting:
      return "Väntar på svar"
    default:
      return "-"
  }
}

export const statusLabel = (typeId: string | undefined, status: number, typeIdInLabel?: boolean) => {
  switch (typeId) {
    case "Arbetsorder":
      return `${typeIdInLabel ? typeId + ": " : ""} ${workorderStatusLabel(status)}`
    case "Besiktning":
    case "Service":
      return `${typeIdInLabel ? typeId + ": " : ""} ${checksStatusLabel(status)}`
    default:
      return eventStatusLabel(status)
  }
}

export const statusIcon = (typeId?: string, status?: number) => {
  switch (typeId) {
    case "Arbetsorder":
      return workorderStatusIcon(status ? status : 0)
    case "Besiktning":
    case "Service":
      return checkStatusIcon(status ? status : 0)
    default:
      return eventIcon(status)
  }
}

export const statusColor = (typeId?: string, status?: any) => {
  switch (typeId) {
    case "Arbetsorder":
      return workorderPrimaryStatusColor(status)
    case "Besiktning":
    case "Service":
      return checksMainStatusColor(status)
    default:
      return eventColor(status)
  }
}

export const statusIconColor = (typeId?: string, status?: any) => {
  switch (typeId) {
    case "Arbetsorder":
      return workorderPrimaryStatusColor(status)
    case "Besiktning":
    case "Service":
      return checksMainStatusColor(status)
    default:
      return theme.palette.primary.main
  }
}

export function backgroundStatusColor(typeId: string | null | undefined, status: any) {
  switch (typeId) {
    case "Besiktning":
    case "Service":
      return checkLightStatusColor(status)
    default:
      return eventColor(status)
  }
}