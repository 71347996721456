import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import React, { useEffect } from "react"

import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import TodoListCheckbox from "components/dataDisplay/TodoList/TodoListCheckbox"
import TodoListRow from "components/dataDisplay/TodoList/TodoListRow"
import { TodoListWrapper } from "components/dataDisplay/TodoList/TodoListWrapper"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { allRoutes } from "generated/routes"
import { NavLink } from "react-router-dom"
import { useStyles } from "../../examination/components/ExamForm/useStyles"
import { useTranslation } from "react-i18next"

const PassedExam = ({ item }) => {
  const { t } = useTranslation()
  return (
    <Grid spacing={2} container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <FontAwesomeIcon color={theme.palette.success["main"]} icon={faCheckCircle} size="3x" />
      </Grid>
      <Grid item>
        <Typography variant="h1" marginBottom={2} align="center">
          {t("learning.result.titles.passedTest")}
        </Typography>
        <Typography variant="bodyMedium" align="center" color="textSecondary">
          {t("learning.result.titles.youHad")} {item.correctAnswersCount}{" "}
          {t("learning.result.titles.correct")} {t("learning.result.titles.of")} {item.totalQuestionsCount}{" "}
          {t("learning.result.titles.possible")}. {t("learning.result.titles.approvedLimit")}{" "}
          {item.requiredPassingScore}.
        </Typography>
      </Grid>
    </Grid>
  )
}
const NotPassedExam = ({ item }) => {
  const { t } = useTranslation()
  return (
    <Grid spacing={2} container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <FontAwesomeIcon color={theme.palette.error["main"]} icon={faTimesCircle} size="3x" />
      </Grid>
      <Grid item>
        <Typography variant="h1" marginBottom={2} align="center">
          {t("learning.result.titles.failedTest")}
        </Typography>
        <Typography variant="bodyMedium" align="center" color="textSecondary">
          {t("learning.result.titles.redoTest")}
        </Typography>

        <Typography variant="bodyMedium" align="center" color="textSecondary">
          {t("learning.result.titles.youHad")} {item.correctAnswersCount}{" "}
          {t("learning.result.titles.correct")} {t("learning.result.titles.of")} {item.totalQuestionsCount}{" "}
          {t("learning.result.titles.possible")}. {t("learning.result.titles.approvedLimit")}{" "}
          {item.requiredPassingScore}.
        </Typography>
      </Grid>
    </Grid>
  )
}
export const ExamFinishedSummary = ({ item }) => {
  const { t } = useTranslation()
  const courses = t("learning.details.course", { returnObjects: true })
  const classes = useStyles()

  let courseDetail = null
  if (item && item.examinationType) {
    courseDetail = courses.find((course) => course.examinationType === item.examinationType)
  }

  return (
    <Container>
      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" color="primary">
            {t("learning.titles.course")}
          </Typography>
          <Typography variant="h3" marginBottom={3}>
            {courseDetail ? courseDetail?.examinationName : item.examinationName}
          </Typography>
          <PaperBox className={classes.content}>
            {item.calculatedPassed ? <PassedExam item={item} /> : <NotPassedExam item={item} />}
            <Box marginTop={3}>
              <TodoListWrapper>
                {item.areas
                  .filter((x) => x.totalCorrectCount > 0)
                  .map((data, index) => {
                    return (
                      <TodoListRow
                        id={index}
                        control={
                          data.allCorrect ? (
                            <TodoListCheckbox color="success" checked disabled />
                          ) : (
                            <TodoListCheckbox color="error" icon={faTimesCircle} disabled />
                          )
                        }>
                        <Grid container direction="row" justifyContent="space-between">
                          <Grid item>
                            {courseDetail
                              ? courseDetail.examinationDefinition?.areas[index].title
                              : data.title}
                          </Grid>
                          <Grid item>
                            <Typography
                              color={data.allCorrect ? "textSecondary" : theme.palette.error["main"]}>
                              ({data.totalCorrectAnswersCount} / {data.totalCorrectCount}{" "}
                              {t("learning.result.titles.correct")})
                            </Typography>
                          </Grid>
                        </Grid>
                      </TodoListRow>
                    )
                  })}
              </TodoListWrapper>
            </Box>
            <Grid container direction="row" justifyContent="space-between" marginTop={3}>
              <Grid item>
                <Typography variant="h3" marginBottom={3}>
                  {t("learning.result.titles.yourResult")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h3"
                  marginBottom={3}
                  color={item.calculatedPassed ? theme.palette.success["main"] : theme.palette.error["main"]}>
                  {item.correctAnswersCount} {t("learning.result.titles.of")} {item.totalQuestionsCount}{" "}
                  {t("learning.result.titles.correct")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              spacing={2}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              marginY={3}>
              <Grid item>
                {item.calculatedPassed ? (
                  <Button
                    component={NavLink}
                    to={allRoutes.LearningUserResultsDetails(item.id)}
                    variant="contained">
                    {t("learning.result.titles.downloadCert")}
                  </Button>
                ) : (
                  <Button
                    component={NavLink}
                    to={allRoutes.LearningUserExamsExamination(item.userExaminationId)}
                    variant="contained">
                    {t("learning.result.buttons.redoTest")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </PaperBox>
        </Grid>
      </Grid>
    </Container>
  )
}
