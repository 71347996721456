import React from "react"
import Grid from "@mui/material/Grid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarCirclePlus, faPenToSquare } from "@fortawesome/pro-solid-svg-icons"
import { Typography } from "@mui/material"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { CheckButtonProps } from "../interfaces/calendarDialog.interface"
import { StyledBoxLink } from "../style/CalendarDialog.styled"
import { StyledBox, StyledFontAwesomeIcon } from "pages/vehicleregistry/pages/checks/InspectionsHistoryTable.styled"
import { VehicleCheckStatus } from "helpers/Checks/ChecksStatusColor"

export const InspectionButton = ({ event, openSidebar, handleClose }: CheckButtonProps) => {
  const status = event?.status

  return (
    <StyledBoxLink
      onClick={() => {
        openSidebar(event)
        handleClose()
      }}
    >
      <StyledBox display="flex" justifyContent="space-between" gap={2} alignItems={"center"}>
        <Grid container gap={2}>
          {status === VehicleCheckStatus.Booked || status === VehicleCheckStatus.waiting ? (
            <>
              <FontAwesomeIcon size="xl" color="grey" icon={faPenToSquare} />
              <Typography variant="h5" color="primary">Ändra bokning</Typography>
            </>
          ) : (
            <>
              <FontAwesomeIcon size="xl" color="grey" icon={faCalendarCirclePlus} />
              <Typography variant="h5" color="primary">Boka</Typography>
            </>
          )}
        </Grid>
        <StyledFontAwesomeIcon size="xl" icon={faArrowRight} />
      </StyledBox>
    </StyledBoxLink>
  )
}
export const ServiceButton = ({ event, handleClose, openSidebar }: CheckButtonProps) => {
  const status = event?.status

  return status === VehicleCheckStatus.Upcoming || status === VehicleCheckStatus.Late ? (
    <StyledBoxLink
      onClick={() => {
        openSidebar(event)
        handleClose()
      }}
    >
      <StyledBox display="flex" justifyContent="space-between" gap={2} alignItems={"center"}>
        <Grid container gap={2}>
          <FontAwesomeIcon size="xl" color="grey" icon={faPenToSquare} />
          <Typography variant="h5" color="primary">Godkänn</Typography>
        </Grid>
        <StyledFontAwesomeIcon size="xl" icon={faArrowRight} />
      </StyledBox>
    </StyledBoxLink>
  ) : <></>
}