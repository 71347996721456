import { Grid, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { exceptionOptions, statusOptions } from "../helpers/violationHelper"
import { IViolationProps } from "../violation.interface"

export const ViewAdminViolation: React.FC<IViolationProps> = (props) => {
  const { values } = props
  const { t } = useTranslation("translation", { keyPrefix: "violation" })

  return (
    <PaperBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12} marginBottom={2}>
            <Typography variant="h3">{t(`info_from_admin`, ``)}</Typography>
          </Grid>

          <Grid item xs={12} marginBottom={2}>
            <Typography variant="labelMedium">{t(`updated_by_admin`, ``)}</Typography>
            <Typography>
              {values?.updatedDate ? moment.utc(values?.updatedDate).local().format("YYYY-MM-DD HH:mm") : "-"}
            </Typography>
          </Grid>

          <Grid item xs={12} marginBottom={2}>
            <Typography variant="labelMedium">{t(`status_title`, ``)}</Typography>
            <Typography variant="h5">
              {statusOptions?.find((x: any) => x.id === values?.status)?.name}
            </Typography>
          </Grid>

          <Grid item xs={12} marginBottom={2}>
            <Typography variant="labelMedium">{t(`exception`, ``)}</Typography>
            <Typography variant="h5">
              {exceptionOptions?.find((x: any) => x.name === values?.exception)?.name}
            </Typography>
          </Grid>

          <Grid item xs={12} marginBottom={2}>
            <Typography variant="labelMedium">{t(`action_plan`, ``)}</Typography>
            <Typography variant="h5">{values?.feedback}</Typography>
          </Grid>

          <Grid item xs={12} marginBottom={2}>
            <Typography variant="labelMedium">{t(`strip`, ``)}</Typography>
            <Typography variant="h5">
              {values?.updatedDate != null ? (values?.strip ? t(`yes`, ``) : t(`no`, ``)) : ""}
            </Typography>
          </Grid>

          <Grid item xs={12} marginBottom={2}>
            <Typography variant="labelMedium">{t(`driver_included`, ``)}</Typography>
            <Typography variant="h5">
              {values?.updatedDate != null ? (values?.driverIncluded ? t(`yes`, ``) : t(`no`, ``)) : ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </PaperBox>
  )
}
