import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import { Typography } from "@mui/material"
import React from "react"

const DeviceStatus = ({ device }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FiberManualRecordIcon
        style={{
          color: device.status === 3 ? "#E03838" : "#8AC979"
        }}
        fontSize="inherit"
      />
      <Typography
        style={{
          color: "#747474",
          fontSize: "11px",
          marginLeft: "4px"
        }}
        variant="bodyMedium">
        {device.status === 3 ? "Offline" : "Online"}
      </Typography>
    </div>
  )
}

export default DeviceStatus
