import * as Yup from "yup"

export const customFieldValidation = (customField) => {
  switch (customField.type) {
    case "Boolean":
      return customField.isRequired
        ? Yup.bool().required(customField.name + " är obligatoriskt")
        : Yup.bool()
    case "Date":
      return customField.isRequired
        ? Yup.date()
          .typeError("Du måste ange ett giltigt datumformat YYYY-MM-DD")
          .required(customField.name + " är obligatoriskt")
        : Yup.date().nullable().typeError("Du måste ange ett giltigt datumformat YYYY-MM-DD")
    case "Numeric":
      return customField.isRequired
        ? Yup.number().required(customField.name + " är obligatoriskt")
        : Yup.number().nullable()
    default:
      return customField.isRequired
        ? Yup.string().required(customField.name + " är obligatoriskt")
        : Yup.string().nullable()
  }
}