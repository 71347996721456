import { useApi } from "auth/useApi3"
import { useContext, useEffect, useState } from "react"
import { Grid } from "@mui/material"
import FormUploadFile from "components/Form/FormComponents/FormUploadFile/FormUploadFile"
import { apiEndpoints } from "generated/apiEndpoints"
import FileList from "pages/documents/components/FileList"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { IDocumentAttachment } from "pages/documents/interface/folder.interface"
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import { allRoles } from "generated/roles"
import FileFormList from "pages/documents/components/FileFormList"
import { FieldArray, Formik } from "formik"
import { fileValidationSchema } from "pages/documents/components/folderValidationSchema"
import PaperBox from "components/PaperBox"

interface DocumentData {
  id: string
  name: string
  createdTime: Date
  updatedTime: Date
  createdBy: { name: string; email: string }
  fileId: string
  fileName: string
}

interface BlobFile {
  fileId: string
  fileName: string
}

export const VehicleDocuments = ({ data }: any) => {
  const appContext = useContext(AppContext)
  const [blobResult, blobApi] = useApi() as [IApiResult, IApiFunc]
  const [attachmentPostResult, attachmentPostApi] = useApi() as [IApiResult, IApiFunc]
  const [attachmentResult, attachmentApi] = useApi() as [IApiResult, IApiFunc]
  const [upsertResult, upsertApi] = useApi() as [IApiResult, IApiFunc]
  const [attachments, setAttachments] = useState<Omit<IDocumentAttachment, "documentId">[]>([])
  const [targetFileIndex, setTargetFileIndex] = useState<null | any>(null)

  useEffect(() => {
    const url = apiEndpoints.vehicleregistry.getvehicleattachmentsbyvehicleid.replace("{vehicleId}", data.id)
    attachmentApi.get(url)
  }, [])

  useEffect(() => {
    const url = apiEndpoints.vehicleregistry.getvehicleattachmentsbyvehicleid.replace("{vehicleId}", data.id)
    attachmentApi.get(url)
  }, [upsertResult])

  useEffect(() => {
    if (attachmentResult.status !== 2) return
    const loadedAttachments = attachmentResult.data.map((attachment: DocumentData) => {
      return {
        ...attachment,
        name: attachment.fileName,
        updatedDate: attachment.updatedTime || attachment.createdTime,
        createdByUser: {
          email: attachment.createdBy.email,
          name: attachment.createdBy.name
        }
      }
    })
    
    setAttachments(loadedAttachments)
  }, [attachmentResult])

  useEffect(() => {
    if (blobResult.status !== 2) return
    const url = `${apiEndpoints.vehicleregistryadmin.upsertvehicleattachment}?vehicleId=${data.id}`
    blobResult.data.forEach((file: BlobFile) => {
      attachmentPostApi.post(url, file)
    })
  }, [blobResult])

  useEffect(() => {
    if (attachmentPostResult.status !== 2) return
    const newAttachment = {
      id: attachmentPostResult.data.id,
      name: attachmentPostResult.data.fileName,
      fileName: attachmentPostResult.data.fileName,
      fileId: attachmentPostResult.data.fileId,
      updatedDate: attachmentPostResult.data.updatedTime || attachmentPostResult.data.createdTime,
      createdByUser: {
        email: attachmentPostResult.data.createdBy.email,
        name: attachmentPostResult.data.createdBy.name
      }
    }
    setAttachments(prevState => [newAttachment, ...prevState])
  }, [attachmentPostResult])

  function uploadBlobFiles(uploadedFiles: File[]) {
    const url = apiEndpoints.attachment.addattachment.replace("{feature}", `vehicleregistry?containerName=${(data.regNumber)}`)
    uploadedFiles.forEach(file => {
      blobApi.file(url, file)
    })
  }


  const upsertClickedAttachment = (valuesObj: any) => {
    const clickedAttachment = valuesObj?.attachments[targetFileIndex]
    if (targetFileIndex === null) return
    const attachmentData = {
      ...clickedAttachment,
      fileName: clickedAttachment.name
    }
    const url = `${apiEndpoints.vehicleregistryadmin.upsertvehicleattachment}?vehicleId=${clickedAttachment.vehicleId}`
    upsertApi.post(url, attachmentData)
  }

  return (
    <Grid container direction="row" spacing={5}>
      <Grid item md={8}>
        {hasRole(appContext.appUser, allRoles.DocumentsAdmin) && (
          <FormUploadFile
            handleChange={(uploadedFiles: File[]) => {
              uploadBlobFiles(uploadedFiles)
            }}
          />
        )}
        <Formik
          validationSchema={fileValidationSchema}
          initialValues={{
            attachments
          }}
          onSubmit={upsertClickedAttachment}
          enableReinitialize={true}>
          {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form id="AttachmentForm" onSubmit={handleSubmit} noValidate>
              <PaperBox>
                <FieldArray
                  name="attachments"
                  render={() => (
                    <FileFormList
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      editFile={targetFileIndex}
                      setEditFile={setTargetFileIndex}
                      fileUpdateResult={upsertResult}
                      downloadUrl={apiEndpoints.vehicleregistry.getattachment + "?regNr=" + data.regNumber}
                      deleteUrl={apiEndpoints.vehicleregistryadmin.deletevehicleattachment}
                    />
                  )}
                />
              </PaperBox>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}