import {
  faFile,
  faFileExcel,
  faFilePdf,
  faFileVideo,
  faImageLandscape
} from "@fortawesome/pro-solid-svg-icons"

export const getFileType = (fileName: string) => {
  var fileType = fileName.substring(fileName.lastIndexOf(".") + 1)
  switch (fileType) {
    case "png":
    case "jpg":
      return faImageLandscape
    case "pdf":
      return faFilePdf
    case "xlsx":
    case "csv":
      return faFileExcel
    case "mp4":
      return faFileVideo
    default:
      return faFile
  }
}
