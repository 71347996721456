import { faAngleDown, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import { Field } from "formik";
import React, { useContext } from "react";
import { ReportTemplateContext } from "../ReportTemplateContextProvider";
import { HtmlTooltip } from "./HtmlTooltip";
import { useStyles } from "./styles";

export const FieldsToChoose = ({ onChange, onBlur }: any) => {
    const { selectedFields, setSelectedFields, fieldsToChoose } = useContext(ReportTemplateContext);
    const classes = useStyles();

    const addOrRemoveField = (field: any, groupIndex: number, index: number, fieldGroup: any): any => {
        var existingFields = selectedFields;
        const existingField = selectedFields?.findIndex((x: any) => x.name === field.name);

        if (existingField !== -1) {
            existingFields.splice(existingField, 1);
            setSelectedFields(existingFields);
        } else {
            field.groupIndex = groupIndex;
            field.index = index;
            field.title = fieldGroup.title;
            field.reportFieldCategoryEnum = fieldGroup.reportFieldCategoryEnum;
            setSelectedFields((prev: any) => [...prev, field]);
        }
    };

    return (
        fieldsToChoose !== null &&
        fieldsToChoose.fieldGroups?.length > 0 &&
        fieldsToChoose.fieldGroups?.map(
            (category: any, index: number) =>
                category.items.length > 0 && (
                    <>
                        <Box component={Card} marginBottom={1} key={index}>
                            <Accordion className={classes.accordion}>
                                <AccordionSummary
                                    expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
                                    aria-label="Expand"
                                    aria-controls="additional-actions1-content"
                                    id="additional-actions1-header"
                                >
                                    <CardHeader
                                        className={classes.cardHeader}
                                        title={category.title}
                                        subheader={`${category.items.filter((field: any) => selectedFields.findIndex((sf: any) => sf.name === field.name) !== -1).length
                                            }/${category.items.length} valda`}
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Divider />
                                </AccordionDetails>

                                <List className={classes.list} dense component="div" role="list">
                                    <div>
                                        {category.items &&
                                            category.items.length > 0 &&
                                            category.items.map((field: any, subindex: number) => (
                                                <ListItem
                                                    key={subindex}
                                                    role="listitem"
                                                    button
                                                    onClick={() => {
                                                        addOrRemoveField(field, index, subindex, category);
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <Field
                                                            id={`fieldGroups.${index}.items.${subindex}.checked`}
                                                            type="checkbox"
                                                            component={Checkbox}
                                                            value={field.checked}
                                                            checked={selectedFields.findIndex((sf: any) => sf.name === field.name) !== -1}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={`${field.name}`} />
                                                    {field.description && (
                                                        <HtmlTooltip title={<React.Fragment>{field.description}</React.Fragment>}>
                                                            <IconButton color="primary" aria-label="info">
                                                                <FontAwesomeIcon icon={faInfoCircle} size="xs" />
                                                            </IconButton>
                                                        </HtmlTooltip>
                                                    )}
                                                </ListItem>
                                            ))}
                                    </div>
                                </List>
                            </Accordion>
                        </Box>
                    </>
                )
        )
    );
};
