import { faDownload } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Container, Grid, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import { CertificateDownloadLink, CertificateRender } from "shared/learning/components/CertificateRender"
import { useStyles } from "./styles.js"

const NormalDetails = ({ item }) => {
  const { t } = useTranslation()
  const courseDetails = t("learning.details.course", { returnObjects: true })

  const classes = useStyles()

  let courseDetail = null
  if (item.examinationType && item) {
    courseDetail = courseDetails.find((course) => course.examinationType === item.examinationType)
  }

  return (
    <div>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">{t("learning.result.titles.certificate")}</Typography>
            <Typography variant="paragraphLarge">
              {courseDetail ? courseDetail?.examinationName : item.examinationName}
            </Typography>
            <Typography variant="paragraphLarge">
              {t("learning.result.titles.completed")}: <Moment format="LL">{item.completedDate}</Moment>
            </Typography>
          </Grid>
          <Grid item md={8}>
            <PaperBox>
              <CertificateRender item={item} />
            </PaperBox>
          </Grid>
          <Grid item md={4}>
            <CertificateDownloadLink item={item}>
              <PaperBox className={classes.downloadPaper}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Typography>{t("learning.result.titles.downloadCert")} </Typography>
                  <FontAwesomeIcon icon={faDownload} />
                </Grid>
              </PaperBox>
            </CertificateDownloadLink>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default NormalDetails
