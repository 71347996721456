import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from "@mui/material"
import Button from "components/Button"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light.js"
import { Formik } from "formik"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { recurringReminderName, reminderOptionName, validityOptionName } from "./helpers/nameSwitch"

const ReminderSettingsForm = ({ examSummaryResult, handleSettingsSubmit, examSettingsResult, result }) => {
  const { t } = useTranslation("translation", { keyPrefix: "learning.admin.settings" })

  const reminderValidationSchema = Yup.object().shape({
    sendRecurringReminders: Yup.boolean(),
    recurringRemindersInDays: Yup.number()
      .nullable()
      .when("sendRecurringReminders", {
        is: (value) => value === true,
        then: (schema) => schema.required(t(`validation.recurring_reminder`))
      }),
    sendFirstReminder: Yup.boolean(),
    firstReminderInDays: Yup.number()
      .nullable()
      .when("sendFirstReminder", {
        is: (value) => value === true,
        then: (schema) => schema.required(t(`validation.first_reminder`))
      })
      .when("sendRecurringReminders", {
        is: (value) => value === true,
        then: (schema) => schema.required(t(`validation.first_reminder`))
      })
  })
  const formRef = useRef()

  const initialValues = examSettingsResult
    ? examSettingsResult
    : {
        firstReminderDays: "",
        firstReminder: false,
        recurringReminderDays: "",
        recurringReminder: false,
        periodOfValidity: ""
      }

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={reminderValidationSchema}
        onSubmit={handleSettingsSubmit}
        enableReinitialize={true}>
        {({ handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue }) => (
          <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
            <Accordion>
              <AccordionSummary
                sx={{ py: "8px", px: "24px" }}
                expandIcon={<ExpandMoreIcon fontSize="large" />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography variant="h2">{t(`settings`)}</Typography>{" "}
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <PaperBox mt={0} pt={0}>
                  <Grid container spacing={3}>
                    <Grid item md={8}>
                      <Typography mb={1} fontWeight={600} variant="bodyLarge">
                        {t(`period_of_validity`)}
                      </Typography>
                      <Typography variant="bodyMedium">{t(`validity_text`)}</Typography>
                      {examSummaryResult.id === 1 && (
                        <Typography mt={2} fontWeight={500} variant="bodyMedium">
                          {t(`clp_recommend`)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <Typography mb={1} fontWeight={600} variant="bodyLarge">
                        {t(`validity_time`)}
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          id="periodOfValidity"
                          value={values.periodOfValidity}
                          onChange={(e) => {
                            setFieldValue("periodOfValidity", e.target.value)
                          }}>
                          {examSettingsResult?.periodOfValidityOptions?.map((item, index) => {
                            return (
                              <MenuItem value={item.value}>{t(validityOptionName(item.labelId))}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box my={3}>
                    <Divider light />
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      <Typography mb={1} fontWeight={600} variant="bodyLarge">
                        {t(`automatic_reminder`)}
                      </Typography>
                      <Typography variant="bodyMedium">{t(`automatic_reminder_text`)}</Typography>
                    </Grid>

                    <Grid item md={12}>
                      <Grid
                        container
                        alignItems="center"
                        gap={3}
                        p={2}
                        sx={{ border: "1px solid", borderColor: theme.palette.grey["100"] }}>
                        <Grid item md={6}>
                          <Typography mb={1} fontWeight={600} variant="bodyMedium">
                            {t(`first_reminder`)}
                          </Typography>
                          <Typography variant="bodyMedium">{t(`first_reminder_text`)}</Typography>
                        </Grid>
                        <Grid md={3}>
                          <Typography mb={1} fontWeight={600} variant="bodySmall">
                            {t(`first_reminder`)}
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              select
                              helperText={touched.firstReminderInDays ? errors.firstReminderInDays : ""}
                              error={touched.firstReminderInDays && errors.firstReminderInDays ? true : false}
                              disabled={values.sendFirstReminder ? false : true}
                              displayEmpty
                              id="firstReminderInDays"
                              value={values.firstReminderInDays}
                              onChange={(e) => {
                                setFieldValue("firstReminderInDays", e.target.value)
                              }}>
                              {examSettingsResult?.reminderOptions?.map((item, index) => {
                                return (
                                  <MenuItem value={item.value}>
                                    {t(reminderOptionName(item.labelId))}
                                  </MenuItem>
                                )
                              })}
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item display="flex" alignItems="center">
                          <FormControlLabel
                            sx={{ mr: "0px" }}
                            control={
                              <Switch
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setFieldValue("sendFirstReminder", true)
                                  } else {
                                    setFieldValue("sendFirstReminder", false)
                                    setFieldValue("firstReminderInDays", null)
                                  }
                                }}
                                id="sendFirstReminder"
                                checked={values.sendFirstReminder}
                              />
                            }
                          />
                          <Typography variant="bodyMedium">{t(`button.on`)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        gap={3}
                        p={2}
                        sx={{ border: "1px solid", borderColor: theme.palette.grey["100"] }}>
                        <Grid item md={6}>
                          <Typography mb={1} fontWeight={600} variant="bodyMedium">
                            {t(`recurring_reminder`)}
                          </Typography>
                          <Typography variant="bodyMedium">{t(`recurring_reminder_text`)}</Typography>
                        </Grid>
                        <Grid md={3}>
                          <Typography mb={1} fontWeight={600} variant="bodySmall">
                            {t(`recurring_reminder`)}
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              select
                              helperText={
                                touched.recurringRemindersInDays ? errors.recurringRemindersInDays : ""
                              }
                              error={
                                touched.recurringRemindersInDays && Boolean(errors.recurringRemindersInDays)
                              }
                              disabled={values.sendRecurringReminders ? false : true}
                              displayEmpty
                              id="recurringRemindersInDays"
                              value={values.recurringRemindersInDays}
                              onChange={(e) => {
                                setFieldValue("recurringRemindersInDays", e.target.value)
                              }}>
                              {examSettingsResult?.recurringReminderOptions?.map((item, index) => {
                                return (
                                  <MenuItem value={item.value}>
                                    {t(recurringReminderName(item.labelId))}
                                  </MenuItem>
                                )
                              })}
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid display="flex" alignItems="center">
                          <FormControlLabel
                            sx={{ mr: "0px" }}
                            control={
                              <Switch
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setFieldValue("sendRecurringReminders", true)
                                  } else {
                                    setFieldValue("sendRecurringReminders", false)
                                    setFieldValue("recurringRemindersInDays", null)
                                  }
                                }}
                                id="sendRecurringReminders"
                                checked={values.sendRecurringReminders}
                              />
                            }
                          />

                          <Typography variant="bodyMedium">{t(`button.on`)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="flex-end">
                    <Box mt={3}>
                      <Button result={result} color="primary" type="submit">
                        {t(`button.save_settings`)}
                      </Button>
                    </Box>
                  </Grid>
                </PaperBox>
              </AccordionDetails>
            </Accordion>
          </form>
        )}
      </Formik>
    </>
  )
}

export default ReminderSettingsForm
