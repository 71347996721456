import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"

import theme from "css/theme/light"
import { t } from "i18next"
import moment from "moment"
import { IFormAreaProps } from "../FormArea/formarea.interface"
import { IFormUploadAttachmentProps } from "../FormComponents/FormUploadFile/formuploadfile.interface"
import { IFormFieldProps } from "../FormField/formfield.interface"
import { IFormGroupProps } from "../FormGroup/formgroup.interface"
import { IFormResult } from "./formtemplate.interface"

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "assets/learning/Poppins-Regular.ttf"
    },
    {
      src: "assets/learning/Poppins-Bold.ttf",
      fontWeight: "bold"
    },
    {
      src: "assets/learning/Poppins-Light.ttf",
      fontWeight: "light"
    }
  ]
})

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "space-between"
  },
  section1: {
    margin: 10,
    marginTop: 30,
    marginBottom: 30,
    padding: 10,
    textAlign: "center",
    justifyContent: "center"
  },
  section2: {
    margin: 10,
    padding: 15,
    marginTop: 30,
    marginBottom: 30
  },
  title1: {
    fontFamily: "Poppins",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 32,
    letterSpacing: 4,
    marginBottom: 16,
    marginTop: 16
  },
  title2: {
    fontFamily: "Poppins",
    fontSize: 16,
    marginBottom: 10,
    marginTop: 15
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  bodyText: {
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: "medium",
    marginBottom: 10
  },
  logo: {
    marginHorizontal: "auto",
    marginVertical: 20,
    height: 38,
    width: 200
  }
})

export const PdfFormTemplate = (formResult: IFormResult) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section2}>
          {formResult.formAreas?.map((area: IFormAreaProps) => (
            <>
              <Text style={styles.title2}> {area.labelDefault}</Text>

              {area?.formGroups?.map((formGroup: IFormGroupProps) => {
                return formGroup.formFields.map((field: IFormFieldProps) => {
                  const parsedDependencyIds =
                    field.dependencyIds && JSON.parse(field.dependencyIds.toString())

                  const dependencyCheck = area?.formGroups?.findIndex((formGroup: IFormGroupProps) => {
                    const formValue = formGroup.formFields.findIndex((ff: IFormFieldProps) => {
                      return parsedDependencyIds?.findIndex(
                        (d: any) => d.formFieldKey === ff.formFieldKey && ff.value === d.value
                      )
                    })
                    return formValue
                  })
                  return dependencyCheck !== -1 && <PdfFormComponents {...field} />
                })
              })}
            </>
          ))}
        </View>
      </Page>
    </Document>
  )
}

function isJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const PdfImage = (attachment: IFormUploadAttachmentProps) => {
  console.log("PDFIMAGE", attachment)
  return attachment !== null ? (
    <>
      <Image style={{ maxWidth: "100%" }} src={`data:image/jpeg;base64,${attachment.Data}`} />
    </>
  ) : null
}

const PdfFormComponents: React.FC<IFormFieldProps> = (props) => {
  const { id, label, labelKey, labelDefault, value, component, options } = props
  const textLabel = labelKey ? t(labelKey, labelDefault) : labelDefault
  switch (component) {
    case "CardButton":
      return (
        <>
          <Text style={styles.label}>{t(textLabel)}</Text>
          <Text style={styles.bodyText}>{options?.find((x) => x.id.toString() === value)?.labelDefault}</Text>
        </>
      )

    case "BigRadioButtons": {
      return (
        <>
          <Text style={styles.label}>{t(textLabel)}</Text>
          <Text style={styles.bodyText}>{options?.find((x) => x.id.toString() === value)?.labelDefault}</Text>
        </>
      )
    }

    case "Checkbox": {
      return (
        <>
          <Text style={styles.label}>{t(textLabel)}</Text>
          <Text style={styles.bodyText}>{value === "true" ? "Ja" : "Nej"}</Text>
        </>
      )
    }

    case "DateTimePicker": {
      return (
        <>
          <Text style={styles.label}>{t(textLabel)}</Text>
          <Text style={styles.bodyText}>{moment(value).format("YYYY-MM-DD HH:mm")}</Text>
        </>
      )
    }

    case "DateTime": {
      return (
        <>
          <Text style={styles.label}>{t(textLabel)}</Text>
          <Text style={styles.bodyText}>{moment(value).format("YYYY-MM-DD")}</Text>
        </>
      )
    }
    case "LocationPicker": {
      const convertedValue = value && isJsonString(value) ? JSON.parse(value) : null

      return (
        <>
          <Text style={styles.label}>{t(textLabel)}</Text>
          <Text style={styles.bodyText}>{convertedValue?.interpretedValue}</Text>
        </>
      )
    }

    case "VehiclePicker": {
      const convertedValue = value && isJsonString(value) ? JSON.parse(value) : null

      return (
        <>
          <Text style={styles.label}>{t(textLabel)}</Text>
          <Text style={styles.bodyText}>{convertedValue?.RegNumber}</Text>
        </>
      )
    }
    case "Select": {
      return (
        <>
          <Text style={styles.label}>{t(textLabel)}</Text>
          <Text style={styles.bodyText}>
            {options?.find((x) => x.value.toString() === value)?.labelDefault}
          </Text>
        </>
      )
    }
    case "Upload":
      return (
        <>
          <Text style={styles.label}>{t(textLabel)}</Text>
          {value !== "" &&
            JSON.parse(value)?.map((file: IFormUploadAttachmentProps) => <PdfImage {...file} />)}
        </>
      )

    default:
      return (
        <>
          <Text style={styles.label}>{t(textLabel)}</Text>
          <Text style={styles.bodyText}>{value}</Text>
        </>
      )
  }
}
