import { Button, ButtonProps } from "@mui/material"
import React from "react"
import { Link, LinkProps } from "react-router-dom"

interface ButtonLinkProps extends ButtonProps {
  route: string
  children: React.ReactNode
}



const ButtonLink: React.FC<ButtonLinkProps> = ({ route, children, ...props }) => {
  return (
    <Button component={Link as React.ElementType<LinkProps>} to={route} {...props} style={{
      opacity: 1,
      
    }}>
      {children}
    </Button>
  )
}

export default ButtonLink
