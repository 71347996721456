import React, { useEffect, useRef } from "react"
import List from "@mui/material/List"
import { useStyles } from "./useStyles"
import InfoIcon from "@mui/icons-material/Info"
import InventoryIcon from "@mui/icons-material/Inventory"
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch
} from "@mui/material"
import { Formik } from "formik"
import { RouterPrompt } from "components/RouterPrompt"
import { technicalPackageValidation } from "../TechnicalPackageValidation"
import Button from "components/Button"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import BuildIcon from "@mui/icons-material/Build"
import { CircleIcon } from "components/icons/CircleIcon"
import { faCubes } from "@fortawesome/pro-solid-svg-icons"

export const TechnicalPackageForm = ({
  toggleMenu,
  technicalpackageData,
  equipmentTypesResult,
  onSubmit,
  editMode,
  addOrUpdateResultState,
  feedback
}) => {
  const initialValues = editMode
    ? { ...technicalpackageData }
    : {
        name: "",
        description: "",
        equipmentTypes: []
      }

  const formRef = useRef()
  const classes = useStyles()

  const handleSubmit = (values) => {
    onSubmit(values)
  }
  useEffect(() => {
    if (addOrUpdateResultState && addOrUpdateResultState.status === 2) {
      formRef.current.resetForm()
    }
  }, [addOrUpdateResultState])

  return (
    <Formik
      validationSchema={technicalPackageValidation}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      innerRef={formRef}
      enableReinitialize={true}>
      {({ values, errors, touched, handleSubmit, handleBlur, isSubmitting, handleChange, setFieldValue }) => (
        <form id="TechnicalPackageList" onSubmit={handleSubmit} noValidate>
          <RouterPrompt />

          <List className={classes.technicalPackageList}>
            <CloseSidebarButton toggleMenu={toggleMenu} />
            <ListItem className={classes.technicalPackageListHeading}>
              <ListItemIcon>
                <CircleIcon icon={faCubes} color="primary" size="lg" sx={{ marginRight: "15px" }} />
              </ListItemIcon>
              <ListItemText
                className={classes.technicalPackageListHeaderTitle}
                primary={
                  <Typography variant="h3">
                    {editMode ? "Uppdatera Teknikpaket" : "Skapa Teknikpaket"}
                  </Typography>
                }
                secondary={
                  <Typography color="secondary" variant="body2" style={{ color: "#747474" }}>
                    {editMode}
                  </Typography>
                }
              />
            </ListItem>

            <List>
              <ListItem className={classes.listItem}>
                <Box className={classes.fullWidth}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <InventoryIcon icon={InventoryIcon} size="1g" color="primary" />
                  </ListItemIcon>

                  <ListItemText
                    classes={{
                      secondary: classes.listItemText,
                      primary: classes.listItemTextPrimary
                    }}
                    style={{ paddingBottom: "8px" }}
                    primary="Namn"
                  />
                </Box>

                <TextField
                  Autocomplete="off"
                  fullWidth
                  className={classes.textField}
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && errors.name ? true : false}
                  helperText={touched.name && errors.name ? errors.name : null}
                  variant="outlined"
                  inputProps={{ classes: { input: classes.formAreaInput }, disableUnderline: true }}
                />
              </ListItem>

              <ListItem className={classes.listItem}>
                <Box className={classes.fullWidth}>
                  <ListItemIcon classname={classes.listItemIcon}>
                    <InfoIcon icon={InfoIcon} size="1g" color="primary" />
                  </ListItemIcon>

                  <ListItemText
                    classes={{
                      secondary: classes.listItemText,
                      primary: classes.listItemTextPrimary
                    }}
                    style={{ paddingBottom: "8px" }}
                    primary="Beskrivning"
                  />
                </Box>

                <TextField
                  autoComplete="off"
                  fullWidth
                  className={classes.textField}
                  id="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && errors.description ? true : false}
                  helperText={touched.description && errors.description ? errors.description : null}
                  variant="outlined"
                  InputProps={{ classes: { input: classes.formAreaInput }, disableUnderline: true }}
                />
              </ListItem>
            </List>
            <ListItem className={classes.listItem}>
              <Box className={classes.fullWidth}>
                <ListItemIcon classname={classes.listItemIcon}>
                  <BuildIcon icon={BuildIcon} size="1g" color="primary" />
                </ListItemIcon>

                <ListItemText
                  classes={{
                    secondary: classes.listItemText,
                    primary: classes.listItemTextPrimary
                  }}
                  style={{ paddingBottom: "8px" }}
                  primary="Utrustning"
                />
              </Box>
              <FormGroup className={classes.listItem}>
                {equipmentTypesResult.map((equipmentType, i) => {
                  return (
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(item) => {
                            const newarray = values.equipmentTypes

                            if (newarray.filter((x) => x.equipmentTypeId === equipmentType.id).length > 0) {
                              newarray.find((x) => x.equipmentTypeId === equipmentType.id).added = false
                            } else {
                              let newEquipmentType = { equipmentTypeId: equipmentType.id, added: true }
                              newarray.push(newEquipmentType)
                            }
                            setFieldValue("equipmentTypes", newarray)
                          }}
                          checked={
                            values.equipmentTypes?.find((x) => x.equipmentTypeId === equipmentType.id)?.added
                          }
                        />
                      }
                      label={equipmentType.name}
                    />
                  )
                })}
              </FormGroup>
            </ListItem>
            <Box pt={3} pb={5}>
              <Button color="primary" type="submit" result={addOrUpdateResultState} feedback={feedback}>
                {editMode ? "Spara teknikpaket" : "lägg till teknikpaket"}
              </Button>
            </Box>
          </List>
        </form>
      )}
    </Formik>
  )
}
