import { Box, Grid, Stack } from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import PaperBox from "components/PaperBox"
import LoadingTypography from "components/skeleton/LoadingTypography"
import React from "react"

const LoadingDocuments = () => {
  const numberOfSkeletonItems = [1, 2, 3, 4, 5, 6, 7]

  return (
    <Box>
      <LoadingTypography height={50} variant="h1" />
      <PaperBox>
        {numberOfSkeletonItems.map((item) => {
          return (
            <Box gap={2} mb={2} borderBottom={1} px={2} borderColor="#EEEEEE">
              <Grid
                container
                direction="row"
                alignItems="center"
                paddingBottom={1}
                spacing={0}
                justifyContent="space-between"
                style={{ color: "inherit", textDecoration: "inherit" }}>
                <Grid item xs={12} md={4} lg={5}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Skeleton animation="wave" width={20} height={20} />

                    <LoadingTypography variant="bodyMedium" />
                  </Stack>
                </Grid>

                <Grid item xs={4} md={3} lg={2}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <LoadingTypography variant="bodyMedium" />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          )
        })}
      </PaperBox>
    </Box>
  )
}
export default LoadingDocuments
