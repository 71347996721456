import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  redChip: {
    backgroundColor: "#FFB4B4",
    color: "#BD2121"
  },
  greenChip: {
    backgroundColor: "#BAE3C3",
    color: "#2F6C12"
  },
  yellowChip: {
    color: "#F49800",
    backgroundColor: "rgba(244, 152, 0, 0.2)"
  },
  popoverTypography: {
    padding: theme.spacing(2),
    maxWidth: "300px"
  },
  icon: {
    fontSize: "4rem",
    color: "#4BADC6"
  },
  textContainer: {
    display: "flex",
    alignItems: "flex-end"
  },
  dashHeading: {
    lineHeight: "0.9",
    paddingTop: "8px",
    fontSize: "38px",
    fontWeight: 500
  },

  /* DashCard */
  padding0: {
    padding: "0"
  },
  root: {
    margin: "0",
    padding: "0"
  },
  textGrey: {
    color: theme.palette.grey[500]
  },
  blue: {
    background: theme.palette.primary[50],
    color: theme.palette.primary[300],
    margin: 0
  },
  // cyan: {
  //     background: theme.palette.cyan[100],
  //     color: theme.palette.cyan[500],
  //     margin: 0
  // },
  grey: {
    background: theme.palette.grey[100],
    color: theme.palette.grey[600],
    margin: 0
  },
  red: {
    background: theme.palette.error["light"],
    color: theme.palette.error["main"],
    margin: 0
  },
  green: {
    background: theme.palette.success["light"],
    color: theme.palette.success["main"],
    margin: 0
  },
  overviewCard: {
    marginTop: "0"
  },
  avatar: {
    width: "60px",
    height: "60px",
    // background: theme.palette.cyan[100],
    color: theme.palette.primary[600],
    marginRight: "16px"
  },
  triangleIcon: {
    position: "absolute",
    right: "-8px",
    top: "-8px",
    color: theme.palette.warning["main"]
  },
  triangleIconRed: {
    position: "absolute",
    right: "-8px",
    top: "-8px",
    color: theme.palette.error["main"]
  },
  lastUpdate: {
    display: "flex",
    justifyContent: "center"
  },
  blackText: {
    color: "#000000",
    fontWeight: "500"
  },
  dashCard: {
    // maxWidth: "260px",
    // [theme.breakpoints.down('xs')]: {
    //     maxWidth: "100%",
    // },
  },
  link: {
    textDecoration: "none"
  }
}))
