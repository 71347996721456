import {StyleSheet, Text, View} from '@react-pdf/renderer'
import React from 'react'

const borderColor = '#rgba(239, 239, 239, 1)'
const styles = StyleSheet.create({
  container: {
    borderBottomColor: 'rgba(239, 239, 239, 1)',
    backgroundColor: 'rgba(239, 239, 239, 1)',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'left',
    fontStyle: 'bold',
    display: 'flex',
    flexDirection: 'row'
  },
  rate: {
    paddingLeft: '8px',
    flex: 1
  }
})

const TableHeader = ({columns}) => (
  <View style={styles.container}>
    {columns.map((col, index) => (
      <Text style={styles.rate}>{col.Header}</Text>
    ))}
  </View>
)

export default TableHeader
