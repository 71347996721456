import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { IconButton } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import React from "react"

const useStyles = makeStyles((theme) => ({
  closeMenuBtn: {
    padding: "8px",
    marginBottom: "1rem",
    position: "absolute",
    left: "0",
    top: "8px",
    zIndex: 2000,
    borderRadius: " 0 0.3rem 0.3rem 0 ",
    backgroundColor: "#2E3547",
    color: "#ffffff"
  }
}))

const CloseSidebarButton = ({ toggleMenu }) => {
  const classes = useStyles()

  return (
    <IconButton className={classes.closeMenuBtn} aria-label="close menu" onClick={toggleMenu} size="large">
      <ChevronRightIcon />
    </IconButton>
  )
}

export default CloseSidebarButton
