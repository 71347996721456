import { faDiamondExclamation } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Box, FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material"
import classnames from "classnames"
import globalUseStyles from "css/theme/globalUseStyles"
import { useEffect, useState } from "react"
import * as Yup from "yup"

import { useStyles } from "./useStyles"

const ViolationSwitch = (props: any) => {
  const { values, setFieldValue, handleChange, handleBlur, errors, touched, editCompany, setValidationStep } =
    props
  const globalClasses = globalUseStyles()
  const classes = useStyles()
  const [showViolation, setShowViolation] = useState(values.violationActive)
  const [showIdhaImport, setShowIdhaImport] = useState<boolean>(values.violationIdhaImport)
  const [showClientId, setShowClientId] = useState(values.violationId != null)

  useEffect(() => {
    setFieldValue("violationIdhaImport", showIdhaImport)
  }, [showIdhaImport])

  useEffect(() => {
    setFieldValue("violationActive", showViolation)
  }, [showViolation])

  useEffect(() => {
    if (showViolation && showClientId) {
      var violationObj = Yup.object().shape({
        violationId: Yup.string().nullable().required("Klient-id är obligatorisk")
      })
      setValidationStep(violationObj)
    }
  }, [values])

  return (
    <>
      <Box mb={3}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={2}>
            <Box>
              <Avatar
                aria-label="BPL Integration"
                className={classnames(globalClasses.avatar, globalClasses.backgroundPrimary)}>
                <FontAwesomeIcon icon={faDiamondExclamation} size="lg" />
              </Avatar>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.boldText} variant="bodyMedium" color="textPrimary">
              Överträdelse
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              onChange={() => {
                setShowViolation(!showViolation)
              }}
              id="violationActive"
              checked={showViolation}
            />
          </Grid>
        </Grid>
      </Box>

      {showViolation && (
        <>
          <Grid item xs={12} mb={3}>
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setShowIdhaImport(!showIdhaImport)
                  }}
                  checked={showIdhaImport}
                  id="violationIdhaImport"
                />
              }
              label="Idha import"
            />
          </Grid>
          <Grid item xs={12} mb={3}>
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setShowClientId(!showClientId)
                  }}
                  checked={showClientId}
                  id="tachosafe"
                />
              }
              label="Tacho4Safe integration"
            />
          </Grid>

          {showClientId && (
            <Grid item xs={12} mb={3}>
              <TextField
                autoComplete="off"
                fullWidth
                id="violationId"
                label="Klient-Id"
                value={values.violationId}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.violationId && errors.violationId ? true : false}
                helperText={touched.violationId && errors.violationId ? errors.violationId : null}
                variant="outlined"
              />
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default ViolationSwitch
