import * as Yup from "yup";

export const validationFile = Yup.object().shape({
    excelFile: Yup.mixed().required("Du måste välja en fil")
});
export const validationSelectedFields = Yup.object().shape({
    internNumber: Yup.string().nullable().required("Du måste ange internnr"),
    name: Yup.string().nullable().required("Du måste ange kund"),
    assignmentNr: Yup.string()
        .nullable()
        .required("Du måste ange avtalsnummer"),
    nrOfHours: Yup.string().nullable().required("Du måste ange timmar"),
    dateFrom: Yup.string().nullable().required("Du måste ange startdatum"),
    dateTo: Yup.string().nullable().required("Du måste ange slutdatum")
});
