import { Grid } from "@mui/material"
import { StyledButtonGroupSwitch, StyledButtonSwitch } from "../../../../components/ButtonSwitch/ButtonSwitch"
import React from "react"

interface SwitchOption {
  view: string
  setView: (data: string) => void
  viewList: ViewData[]
}

export interface ViewData {
  value: string
  label: string
}

export const ViewToggler = ({ view, setView, viewList }: SwitchOption) => {
  return (
    <Grid container flexDirection={"row-reverse"}>
      <StyledButtonGroupSwitch variant="outlined" color="inherit">
        {viewList.map((viewSwitch: ViewData) => (
          <StyledButtonSwitch
            key={viewSwitch.value}
            onClick={() => setView(viewSwitch.value)}
            active={view === viewSwitch.value}
          >
            {viewSwitch.label}
          </StyledButtonSwitch>
        ))}
      </StyledButtonGroupSwitch>
    </Grid>
  )
}