import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    // * filterMenu

    fullList: {
        width: "auto"
    },
    showFilterBtn: {
        color: "#ffff",
        textTransform: "none"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        padding: "16px 0 ",
        boxShadow: "0"
    },
    drawer: {
        flexShrink: 1
    },
    drawerPaper: {
        backgroundColor: "#ffff",
        width: "450px",
        top: 48
    },

    // * FilterMenuNav

    backButton: {
        textTransform: "none",
        color: "#254467		",
        backgroundColor: "var(--secondary)",
        border: "none",
        width: "30px",
        height: "30px",
        borderRadius: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    settingsBtn: {
        backgroundColor: "transparent",
        color: "var(--primary)"
    },
    navigation: {
        display: "flex",
        justifyContent: "space-between",
        margin: " 20px 16px 0 16px"
    },
    filterMenuIcons: {
        color: "#254467		"
    },

    // * search

    links: {
        color: "var(--color)",
        marginBottom: "16px",
        cursor: "pointer",
        border: "none"
    },
    input: {
        backgroundColor: "#F9F9F9",
        color: "#77DAFF",
        margin: "0 0 20px 0",
        borderRadius: "10px",
        padding: "0"
    },
    search: {
        margin: "0 16px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    notchedOutline: {
        border: "none"
    },

    // * status nav
    status: {
        display: "flex",
        justifyContent: "space-evenly",
        margin: "20px 16px",
        padding: "0"
    },
    statusNavBtn: {
        backgroundColor: "#F9F9F9",
        borderRadius: "0",
        width: "100%",
        border: "0",
        outline: "none",
        padding: "5px",
        cursor: "pointer"
    },
    activeButton: {
        backgroundColor: "rgba(109, 199, 220, .2)",
        borderRadius: "0",
        color: "#254467",
        width: "100%",
        border: "0",
        outline: "none",
        padding: "5px",
        cursor: "pointer"
    },
    text: {
        textTransform: "none",
        fontSize: "12px",
        fontWeight: "400"
    },
    activeText: {
        fontWeight: "600",
        color: "#254467",
        textTransform: "none",
        fontSize: "12px"
    },

    // * device list

    deviceList: {
        width: "100%",
        backgroundColor: "#ffff",
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid #f1f1f1"
    },
    device: {
        padding: "4px 16px",
        color: "#000000"
    },
    listItem: {
        // borderTop: "1px solid #E0E0E0",
        borderRadius: "0 !important",
        padding: "14px 0",
        alignItems: "flex-start !important",
        display: "flex",
        flexDirection: "column"
    },

    listItemIcon: {
        paddingRight: "12px",
        paddingTop: "4px"
    },

    // * vehicle list
    vehicleList: {
        width: "100%",
        backgroundColor: "theme.palette.background.paper",
        padding: "4rem 2rem 2rem 2rem"
    },

    vehicleListHeading: {
        display: "flex",
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        padding: "0 0 1rem 0"
    },
    vehicleListHeaderIcon: {
        backgroundColor: "#4BADC6",
        borderRadius: "100vh",
        width: "45px",
        height: "45px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "12px"
    },
    skeletonHeader: {
        borderRadius: "100vh",
        width: "45px",
        height: "45px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "12px"
    },

    vehicleListHeaderTitle: {
        color: "#000000",
        marginBottom: "0"
    },
    margin: {
        padding: "0"
    },
    listItemText: {
        fontSize: "16px"
    },
    listItemTextPrimary: {
        color: "#000000",
        fontWeight: 600
    },
    fullWidth: {
        width: "100%",
        display: "flex"
    },

    formAreaInput: {
        padding: "16px 12px"
    },

    // * Skeleton loading
    closeMenuBtnLoading: {
        padding: "8px",
        marginBottom: "1rem",
        position: "absolute",
        left: "0",
        top: "8px",
        borderRadius: " 0 0.3rem 0.3rem 0 "
    },
    listItemLoading: {
        padding: "14px 0",
        alignItems: "flex-start !important"
    }
}));
