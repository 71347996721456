import { Box, Card, Grid } from "@mui/material"
import TextField from "@mui/material/TextField"
import Button from "components/Button"
import { Formik } from "formik"
import { templateValidationSchema } from "../templateValidationSchema"
import { ChosenFields } from "./ChosenFields"
import { FieldsToChoose } from "./FieldsToChoose"
import { useStyles } from "./styles"

const TempleteForm = ({
  submitFeedbackResult,
  addFormTemplate,
  editTemplate,
  onSubmit,
  deleteTemplate,
  editMode
}: any) => {
  const classes = useStyles()

  const handleSubmit = async (values: any, { resetForm }: any) => {
    onSubmit(values)
  }
  const initialValues = editMode ? editTemplate : addFormTemplate

  return (
    <Formik
      onSubmit={handleSubmit}
      validateOnChange={false}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={templateValidationSchema}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
        setFieldValue
      }: any) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container direction="row" justifyContent="flex-end" spacing={5}>
            <Grid item xs={12} md={5}>
              <Box marginBottom={2}>
                <FieldsToChoose onChange={handleChange} onBlur={handleBlur} setFieldValue={setFieldValue} />
              </Box>
            </Grid>

            <Grid item xs={12} md={7}>
              <ChosenFields />
              <Box component={Card} paddingX={2} paddingY={3} marginTop={2}>
                <Grid container spacing={2} direction="row">
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="name"
                      label="Namn på rapport"
                      value={values?.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && errors.name ? true : false}
                      helperText={touched.name && errors.name ? errors.name : null}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="description"
                      label="Beskrivande text"
                      value={values?.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
            <Grid item>
              {editMode && (
                <Box marginTop={3}>
                  <Button color="primary" variant="outlined" onClick={() => deleteTemplate()}>
                    Ta bort rapportmall
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item>
              <Box marginTop={3}>
                <Button color="primary" type="submit" result={submitFeedbackResult}>
                  {editMode ? "Uppdatera" : "Spara"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default TempleteForm
