import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons"
import { faCloudUpload, faFile, faTrashCanXmark } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  Modal,
  Typography
} from "@mui/material"
import { styled } from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import { useApiDelete } from "auth/useApi2"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"

import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardMedia from "@mui/material/CardMedia"

const MaterialDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0, 2, 1, 2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  },
  "& .MuiPaper-root": {
    maxWidth: "70%",
    borderRadius: "12px",
    [theme.breakpoints.between("xs", "md")]: {
      margin: 0,
      maxWidth: "80%"
    }
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.30)"
  }
}))

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    borderWidth: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed"
  },
  label: {
    color: "rgba(0, 0, 0, 0.54)",
    top: "-11px",
    left: "9px",
    padding: "0 5px",
    background: theme.palette.background.paper,
    position: "absolute"
  },
  margin: {
    margin: theme.spacing(1)
  },
  gridImagesMin: {
    "&.MuiGrid-item": {
      minWidth: "min-content"
    }
  },
  gridImagesMax: {
    "&.MuiGrid-item": {
      minWidth: "100%"
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "8px 0",
    padding: 15
  },
  labelInBox: {
    color: theme.palette.primary.main
  },
  listItem: {
    marginBottom: "5px",
    borderRadius: 10
  },
  listItemText: {
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  listItemAction: {
    display: "flex",
    alignItems: "center",

    "&:hover": {
      cursor: "pointer"
    }
  },
  del: {
    color: theme.palette.error.main
  },
  modalImage: {
    maxWidth: "100%"
  },
  dialogTitle: {
    fontSize: 3,
    padding: 2,
    margin: 0
  },
  imageGap: {
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      gridGap: 0
    }
  }
}))

const boxStyle = {
  display: "block",
  "@media (max-width: 1920px)": {
    display: "flex"
  }
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "background .24s ease-in-out",
  cursor: "pointer",
  margin: "0 15px",
  borderRadius: 4,
  textAlign: "center"
}

const activeStyle = {
  backgroundColor: "rgba(233,233,233, 1)"
}

const acceptStyle = {
  backgroundColor: "rgba(233,233,233, 1)"
}

const rejectStyle = {
  borderColor: "#ff1744"
}

const MultipleFilePickerPreviewList = ({
  setFieldValue,
  fieldName,
  defaultPreviewFiles,
  setFiles,
  files,
  onLogoChanged,
  label,
  labelInBox,
  fileUploadResult,
  parentData,
  editMode
}) => {
  const classes = useStyles()
  const [deleteResult, deleteFile, resetDeleteState] = useApiDelete()
  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState("")
  const handleOpen = (previewFile) => {
    setOpen(true)
    setSelectedImage(previewFile)
    console.log(previewFile)
  }
  const handleClose = () => {
    setOpen(false)
    setSelectedImage("")
  }

  const [previewFiles, setPreviewFiles] = useState(defaultPreviewFiles)
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    multiple: true,
    paramName: () => "files",
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles)
    }
  })
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const removeFile =
    (index, { fileId, id }) =>
    () => {
      deleteFile(apiEndpoints.issuereports.deletefile.replace("{id}", id))
      const newPreviewFiles = [...previewFiles]
      newPreviewFiles.splice(index, 1)
      setPreviewFiles(newPreviewFiles)

      removeFormValues(index)
    }
  const removeFormValues = (index) => () => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
    setFieldValue(fieldName, newFiles)
  }

  useEffect(() => {
    if (fileUploadResult.status === 2) {
      setPreviewFiles(() => [...previewFiles, fileUploadResult.data])
    }
  }, [fileUploadResult])

  useEffect(() => {
    if (previewFiles) {
      setFieldValue(fieldName, previewFiles)
    }
  }, [previewFiles, onLogoChanged, setFieldValue, fieldName])

  return (
    <>
      {editMode ? (
        <Box
          className={classes.container}
          bgcolor="background.paper"
          border={1}
          borderColor="grey.400"
          borderRadius="4px">
          <Typography className={classes.label} variant="caption">
            {label}
          </Typography>
          <Box spacing={3} py={2}>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <FontAwesomeIcon icon={faCloudUpload} color={theme.palette.primary["main"]} size="3x" />
              <p className={classes.labelInBox}>{labelInBox}</p>
            </div>
          </Box>
        </Box>
      ) : !parentData ? (
        <Box
          className={classes.container}
          bgcolor="background.paper"
          border={1}
          borderColor="grey.400"
          borderRadius="4px">
          <Typography className={classes.label} variant="caption">
            {label}
          </Typography>
          <Box spacing={3} py={2}>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <FontAwesomeIcon icon={faCloudUpload} color={theme.palette.primary["main"]} size="3x" />
              <p className={classes.labelInBox}>{labelInBox}</p>
            </div>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <Grid container direction="row" marginTop={2} spacing={2}>
        {previewFiles &&
          previewFiles.length > 0 &&
          previewFiles.map((previewFile, index) => (
            <>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                key={index}
                className={parentData ? classes.gridImagesMin : classes.gridImagesMax}>
                <Card>
                  {previewFile.base64 ? (
                    <>
                      <CardMedia
                        component="img"
                        sx={{ height: 150 }}
                        alt={`Bild nr ${index + 1}`}
                        image={`data:image/png;base64,${previewFile.base64}`}
                        onClick={() => handleOpen(previewFile)}
                      />
                      <CardActions sx={{ justifyContent: "center" }}>
                        <Button size="small" onClick={() => handleOpen(previewFile)}>
                          {" "}
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            style={{ color: "#34727F", marginRight: "5px" }}
                          />{" "}
                          <Typography>Öppna</Typography>
                        </Button>
                        {editMode ? (
                          <Button
                            size="small"
                            className={classes.del}
                            onClick={removeFile(index, previewFile)}>
                            <FontAwesomeIcon
                              icon={faTrashCanXmark}
                              style={{ marginRight: "5px" }}
                              color={"#E45C41"}
                            />
                            <Typography>Ta bort</Typography>
                          </Button>
                        ) : !parentData ? (
                          <Button
                            size="small"
                            className={classes.del}
                            onClick={removeFile(index, previewFile)}>
                            <FontAwesomeIcon
                              icon={faTrashCanXmark}
                              style={{ marginRight: "5px" }}
                              color={"#E45C41"}
                            />
                            <Typography>Ta bort</Typography>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </CardActions>
                    </>
                  ) : (
                    <>
                      <ListItem key={index}>
                        <FontAwesomeIcon icon={faFile} /> <ListItemText primary={previewFile.name} />
                      </ListItem>

                      <Button size="small" className={classes.del} onClick={removeFile(index, previewFile)}>
                        <FontAwesomeIcon
                          icon={faTrashCanXmark}
                          style={{ marginRight: "5px" }}
                          color={"#E45C41"}
                        />
                        <Typography>Ta bort</Typography>
                      </Button>
                    </>
                  )}
                </Card>
              </Grid>
            </>
          ))}

        <Modal open={open} onClose={() => handleClose()} aria-labelledby="image">
          <MaterialDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <Grid container justifyContent={"space-between"} alignItems={"center"} flexWrap={"nowrap"}>
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {selectedImage.name}
              </DialogTitle>
              <Button
                aria-label="close"
                onClick={handleClose}
                color="primary"
                variant="outlined"
                sx={{
                  mr: 2
                }}
                startIcon={
                  <CloseIcon
                    sx={{
                      backgroundColor: "#0093B2",
                      color: theme.palette.primary.contrastText,
                      borderRadius: "10px"
                    }}
                  />
                }>
                Stäng
              </Button>
            </Grid>

            <DialogContent>
              <img
                className={classes.modalImage}
                src={`data:image/jpeg;base64,${selectedImage.base64}`}
                alt={`${selectedImage.name}`}
                onClick={() => handleClose()}
              />
            </DialogContent>
          </MaterialDialog>
        </Modal>
      </Grid>
    </>
  )
}
export default MultipleFilePickerPreviewList
