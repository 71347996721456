import { Grid, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"

import React from "react"

export const StatsCard = (props) => {
  const { statsText, bigIcon, statsValue, subText, statsDesc } = props
  return (
    <>
      <ListItem disableGutters>
        <ListItemAvatar> {bigIcon}</ListItemAvatar>
        <ListItemText>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="bodyMedium">{statsText}</Typography>
              <Typography variant="caption" color="textSecondary">
                {statsDesc}
              </Typography>
              <Typography variant="h2">{statsValue}</Typography>
              <Typography variant="caption" color="textSecondary">
                {subText}
              </Typography>
            </Grid>
          </Grid>
        </ListItemText>
      </ListItem>
    </>
  )
}
