import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid } from "@mui/material"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { LatLng, getGeocode } from "use-places-autocomplete"
import { PlacesAutocomplete } from "./FormLocationAutoComplete"
import { IFormLocationPickerProps } from "./formlocationpicker.interface"

const FormLocationPicker: React.FC<IFormLocationPickerProps> = (props) => {
  const { handleChange, labelKey, labelDefault, latLng, disableSelect, optional } = props
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null)

  const [selected, setSelected] = useState<{ lat: number; lng: number } | null>(latLng ? latLng : null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [selectedMapClick, setSelectedMapClick] = useState<string>("")

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCk4SCnuwj2yKZ_Z8CqIasGQIIiMh0lws8",
    libraries: ["places"]
  })
  const containerStyle: React.CSSProperties = {
    width: "100%",
    height: "300px",
    borderRadius: "4px"
  }
  const onLoad = useCallback((map: google.maps.Map | null) => setMap(map), [])
  // useEffect(() => {
  //   console.log(latLng)
  //   if (latLng !== null) {
  //     setSelected(latLng)
  //   }
  // }, [latLng])
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLat = position.coords.latitude
          const userLng = position.coords.longitude
          setUserLocation({ lat: userLat, lng: userLng })
        },
        (error) => {
          console.error("Error getting geolocation:", error)
        },
        { enableHighAccuracy: true }
      )
    } else {
      console.error("Geolocation is not supported by your browser")
    }
  }, [])

  const center = useMemo(
    () => ({
      lat: userLocation ? userLocation.lat : 62.39081947070066,
      lng: userLocation ? userLocation.lng : 17.307067930699166
    }),
    [userLocation]
  )

  useEffect(() => {
    if (selected !== null) {
      handleChange && handleChange(JSON.stringify(selected))
    }
  }, [selected])

  const handleMapClick = async (event: google.maps.MapMouseEvent) => {
    const lat = event?.latLng?.lat() as number
    const lng = event?.latLng?.lng() as number

    if (!disableSelect) {
      setSelected({ lat, lng })
    }

    try {
      const location = { lat, lng } as LatLng
      const results = await getGeocode({ location })
      const address = results[0]?.formatted_address

      if (address && !disableSelect) {
        setSelectedMapClick(address)
      }
    } catch (error) {
      console.error("Error fetching address: ", error)
    }
  }

  useEffect(() => {
    if (selected !== null && map !== null) {
      map.panTo(selected)
      const getZoom = map?.getZoom()
      if (getZoom && getZoom < 14) {
        map.setZoom(14)
      }
    }
  }, [selected, map])

  if (!isLoaded) return <FontAwesomeIcon icon={faSpinnerThird as IconProp} />

  return (
    <Grid md={12} xs={12}>
      {!disableSelect && (
        <PlacesAutocomplete
          handleChange={(e: any) => handleChange && handleChange(e)}
          setSelected={setSelected}
          selected={selected}
          selectedMapClick={selectedMapClick}
          {...props}
        />
      )}

      <GoogleMap
        zoom={10}
        center={center}
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        onClick={handleMapClick}>
        {selected && <Marker position={selected} />}
      </GoogleMap>
    </Grid>
  )
}

export default FormLocationPicker
