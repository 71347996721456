import * as Yup from "yup"

export const deviceValidationSchema = Yup.object().shape({
  deviceId: Yup.string().required("Regnummer är obligatoriskt"),
  internalDeviceId: Yup.string().required("Internnummer är obligatoriskt"),
  deviceDescription: Yup.string().nullable().max(100, "Beskrivning har en maxgräns på 100 tecken.")
})

export const equipmentValidationSchema = Yup.object().shape(
  {
    companyId: Yup.number()
      .typeError("Bolag är obligatoriskt")
      .min(1, "Bolag är obligatoriskt")
      .required("Bolag är obligatoriskt"),
    equipmentId: Yup.string().required("Id är obligatoriskt"),
    status: Yup.string().required("Status är obligatoriskt"),
    equipmentTypeId: Yup.number()
      .min(1, "Utrustningstyp är obligatoriskt")
      .typeError("Utrustningstyp är obligatoriskt")
      .required("Utrustningstyp är obligatoriskt"),
    subscriptionTime: Yup.date()
      // .required("Bindningstid är obligatoriskt")
      .nullable(),
    mountedOnDeviceId: Yup.number()
      .nullable()
      .when("mountedOnStorageId", {
        is: (value) => value === null,
        then: (schema) => schema.required("Koppling till lager eller enhet måste finnas")
      }),
    mountedOnStorageId: Yup.number()
      .nullable()
      .when("mountedOnDeviceId", {
        is: (value) => value === null,
        then: (schema) => schema.required("Koppling till lager eller enhet måste finnas")
      }),
    simCardSupplier: Yup.number().nullable(),
    avlId: Yup.number().nullable().max(85000, "Du kan max ange 85000")
  },
  [["mountedOnDeviceId", "mountedOnStorageId"]]
)

export const storageValidationSchema = Yup.object().shape({
  companyId: Yup.number().typeError("Bolag är obligatoriskt").required("Bolag är obligatoriskt"),
  name: Yup.string().required("Namn är obligatoriskt"),
  maximumCapacity: Yup.number().required("Ange minst 0 max antal i lager").min(0),
  storageDescription: Yup.string().nullable().max(100, "Beskrivning har en maxgräns på 100 tecken."),
  adress: Yup.string().nullable().max(100, "Adress har en maxgräns på 100 tecken.")
})
