import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import { FieldArray } from "formik"
import { allRoles } from "generated/roles"
import { ModuleBox } from "pages/systemadmin/pages/customers/components/ModuleBox"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

const CustomerModules = (props) => {
  const { values, handleChange, handleBlur, setFieldValue } = props

  const { t } = useTranslation("translation", { keyPrefix: "settings.form" })
  const appContext = useContext(AppContext)

  const [systemAdminModules, toggleSystemAdminModules] = useState(false)
  return (
    <>
      <FieldArray
        name="modules"
        render={() => (
          <>
            {values.modules &&
              values.modules.length > 0 &&
              values.modules.map((module, index) => (
                <ModuleBox
                  index={index}
                  module={module}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  menuIcon={module.icon}
                  setFieldValue={setFieldValue}
                  arrayName="modules"
                  modules={values.modules}
                />
              ))}
          </>
        )}
      />
      {hasRole(appContext.appUser, allRoles.SystemAdminCustomers) && (
        <>
          <Typography
            marginTop={3}
            variant="h5"
            sx={{ cursor: "pointer" }}
            onClick={() => toggleSystemAdminModules(!systemAdminModules)}>
            {t("systemadmin")} <FontAwesomeIcon icon={systemAdminModules ? faChevronUp : faChevronDown} />
          </Typography>

          <Typography variant="bodySmall">{t("systemadmin_description")}</Typography>
          {systemAdminModules && (
            <>
              <FieldArray
                name="systemModules"
                render={() => (
                  <>
                    {values.systemModules &&
                      values.systemModules.length > 0 &&
                      values.systemModules.map((module, index) => (
                        <ModuleBox
                          index={index}
                          module={module}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          menuIcon={module.icon}
                          setFieldValue={setFieldValue}
                          arrayName="systemModules"
                          modules={values.systemModules}
                        />
                      ))}
                  </>
                )}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default CustomerModules
