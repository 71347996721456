import makeStyles from "@mui/styles/makeStyles"

export const getSize = (size) => {
  switch (size) {
    case "xl":
      return "65px"
    case "lg":
      return "55px"
    case "md":
      return "35px"
    case "sm":
      return "25px"
    case "xs":
      return "15px"
    default:
      return "25px"
  }
}

const useStyles = makeStyles((theme) => ({
  iconBox: ({ size }) => ({
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: getSize(size),
    minWidth: getSize(size)
  })
}))

export default useStyles
