import theme from "css/theme/light"

export function workorderStatusColor(status: number) {
  switch (status) {
    case 1:
      return "warning"
    case 2:
      return "primary"
    case 3:
      return "primary"
    case 4:
      return "primary"
    case 5:
      return "success"
    case 6:
      return "error"
    default:
      return undefined
  }
}

export function workorderPrimaryStatusColor(status: number) {
  switch (status) {
    case 1:
      return theme.palette.warning.dark
    case 2:
      return theme.palette.primary.main
    case 3:
      return theme.palette.primary.main
    case 4:
      return theme.palette.success.dark
    case 5:
      return theme.palette.success.dark
    case 6:
      return theme.palette.error.dark
    default:
      return theme.palette.primary.main
  }
}
