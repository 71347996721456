const cyan = {
	50: "#D9EEF4",
	100: "#D9EEF4",
	200: "#8bc9da",
	main300: "#4BADC6",
	400: "#2ea2bf",
	main500: "#0093B2",
	600: "#00809b",
	700: "#34727F",
	800: "#004d5e",
	900: "#01343f",
	A100: "#8c9eff",
	A200: "#536dfe",
	A400: "#3d5afe",
	A700: "#304ffe",
	contrastDefaultColor: "light",
};

export default cyan;
