import React from "react";
import { ImportFormWizard } from "../../components/ImportFuelFormWizard";
import { ImportFuelFormStateProvider } from "../../components/ImportFuelFormStateProvider";
const ImportData = ({ match }) => {
    return (
        <div>
            <ImportFuelFormStateProvider>
                <ImportFormWizard />
            </ImportFuelFormStateProvider>
        </div>
    );
};
export default ImportData;
