import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faBuildingUser, faPlus } from "@fortawesome/pro-solid-svg-icons"
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItemText,
  Switch,
  TextField,
  Typography,
  useTheme
} from "@mui/material"
import ListItem from "@mui/material/ListItem"
import { Formik } from "formik"
import { t } from "i18next"
import { useContext, useEffect, useState } from "react"
import * as Yup from "yup"
import { hasRole } from "auth/hasRole"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import DefaultButton from "components/Button"
import IconForButton from "components/ButtonIcon"
import { RouterPrompt } from "components/RouterPrompt"
import { CircleIcon } from "components/icons/CircleIcon"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import EmailBoxTypography from "pages/issuereports/pages/workorders/components/EmailBox/EmailBoxTypography"
import { Sidebar } from "pages/vehicleregistry/pages/vehicles/pages/customfields/components/Sidebar"
import { IWorkshopProps, IWorkshopValues, WorkshopData } from "./Workshop.interfaces"

const workshopValidationSchema = Yup.object().shape({
  name: Yup.string().required("Namn är obligatoriskt").max(150, "Namn får max vara 150 tecken långt"),
  emailAddress: Yup.string().email("Måste vara en giltig e-postadress").required("E-postadress är obligatoriskt").max(255)
})

const Workshop = ({ handleSubmit, workshop, companyId, showSidebar, setShowSidebar }: IWorkshopProps) => {
  const initialFormValues = {
    id: 0,
    name: "",
    emailAddress: "",
    isActive: true,
    handleSubmit: (newWorkshop: WorkshopData) => {
      const url = apiEndpoints.vehicleregistrychecks.createcarworkshop
      workshopApi.post(url, newWorkshop).then((_) => {
        handleSubmit()
        setShowSidebar(false)
      })
    }
  }
  const appContext = useContext(AppContext)
  const [workshopResponse, workshopApi] = useApi() as [IApiResult, IApiFunc]
  const [formikValues, setFormikValues] = useState<IWorkshopValues>(initialFormValues)
  const theme = useTheme()

  useEffect(() => {
    if (!workshop) return
    setFormikValues({
      ...workshop,
      handleSubmit: (updatedWorkshop: WorkshopData) => {
        const url = apiEndpoints.vehicleregistrychecks.updatecarworkshop
        workshopApi.put(url, updatedWorkshop).then((_) => {
          handleSubmit()
          setShowSidebar(false)
        })
      }
    })
  }, [workshop])

  return (
    <>
      <Box order={3} p={1}>
        {hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin) && (
          <Button
            onClick={() => {
              setShowSidebar(!showSidebar)
              setFormikValues(initialFormValues)
            }}
            variant="contained"
            color="primary"
            startIcon={<IconForButton icon={faPlus as IconProp} color="primary" />}>
            Lägg till verkstad
          </Button>
        )}
      </Box>
      {hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin) && (
        <Sidebar showSidebar={showSidebar}>
          <Box sx={{ height: "100%", margin: "20px" }}>
            <Formik
              initialValues={formikValues}
              validationSchema={workshopValidationSchema}
              onSubmit={(values) => {
                formikValues.handleSubmit({ ...values, companyId })
                setFormikValues(initialFormValues)
              }}
              enableReinitialize={true}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                handleSubmit,
                setFieldValue
              }) => (
                <form style={{ height: "100%" }} id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
                  <RouterPrompt />
                  <List sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <Box style={{ position: "absolute", left: "-20px" }}>
                      <CloseSidebarButton
                        toggleMenu={() => {
                          setShowSidebar(!showSidebar)
                        }}
                      />
                    </Box>

                    <ListItem sx={{ borderBottom: "1px solid #e0e0e0" }}>
                      <Box pt={5} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <CircleIcon
                            icon={faBuildingUser}
                            color="primary"
                            size="lg"
                            sx={{ marginRight: "15px" }}
                          />
                          <Box justifyContent="center">
                            <ListItemText
                              primary={
                                <Typography color="textPrimary" variant="h2">
                                  {values.id < 1 ? "Lägg till ny verkstad" : "Redigera verkstad"}
                                </Typography>
                              }
                              secondary={
                                <Typography color="textSecondary" variant="bodySmall">
                                  {values.id < 1 ? "Skapa verkstad" : "Redigera verkstad"}
                                </Typography>
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <InputLabel>
                            <Typography variant="h6" color={"black"}>
                              Verkstad
                            </Typography>
                          </InputLabel>
                        }
                        secondary={
                          <TextField
                            placeholder={"Ange namn/företag"}
                            autoComplete="off"
                            fullWidth
                            id="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              readOnly: values.id > 0,
                            }}
                            sx={{
                              backgroundColor: values.id > 0 ? '#e8e8e8' : theme.palette.background.paper,
                              '& .Mui-disabled': {
                                backgroundColor: 'lightgrey',
                              }
                            }}
                            error={touched.name && errors.name ? true : false}
                            helperText={touched.name && errors.name ? errors.name : null}
                            variant="outlined"
                          />
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <Grid container direction="row" alignItems="center" mb={3}>
                        <EmailBoxTypography pt={1} pb={1} text="E-postadress" variant="h6" />
                        <TextField
                          placeholder={"Ange mail"}
                          autoComplete="off"
                          fullWidth
                          id="emailAddress"
                          value={values.emailAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.emailAddress && errors.emailAddress ? true : false}
                          helperText={
                            touched.emailAddress && errors.emailAddress ? errors.emailAddress : null
                          }
                          variant="outlined"
                        />
                      </Grid>
                    </ListItem>
                    <ListItem>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event) => {
                              setFieldValue("isActive", event.target.checked)
                            }}
                            checked={values.isActive}
                          />
                        }
                        label={"Aktiv"}
                      />
                    </ListItem>
                    <Box sx={{ marginTop: "Auto", marginBottom: "26px", width: "100%" }}>
                      <ListItem sx={{ flexDirection: "column", gap: "14px" }}>
                        <DefaultButton sx={{ width: "100%" }} result={workshopResponse} type="submit">
                          {values.id > 0 ? t("global.buttons.save") : "Lägg till"}
                        </DefaultButton>
                        <Button
                          sx={{ width: "100%", margin: "0 10px" }}
                          type="submit"
                          variant={"outlined"}
                          onClick={() => {
                            setShowSidebar(false)
                            setFormikValues(initialFormValues)
                          }}>
                          {t("global.buttons.cancel")}
                        </Button>
                      </ListItem>
                    </Box>
                  </List>
                </form>
              )}
            </Formik>
          </Box>
        </Sidebar>
      )}
    </>
  )
}

export default Workshop
