import { Box, IconButton, Tooltip } from "@mui/material"
import moment from "moment"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { apiEndpoints } from "generated/apiEndpoints"
import { useApi } from "auth/useApi3"
import ClpTable from "../../components/ClpTable"
import { hasRole } from "auth/hasRole"
import { allRoles } from "generated/roles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AppContext } from "components/AppContextProvider"
import {
  faCalendarCirclePlus,
  faCheck,
  faP,
  faPenToSquare,
  faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons"
import { StyledTypography } from "./InspectionService.styled"
import { InspectionBookingSidebar } from "./components/InspectionBookingSidebar"
import { ViewToggler } from "pages/vehicleregistry/pages/checks/ViewToggler"
import { VehicleInspectionsHistoryTable } from "./VehicleInspectionsHistoryTable"
import { VehicleStatus } from "pages/vehicleregistry/pages/checks/Checks.interfaces"
import { StyledInspectionStatusIcon, StyledSpanError } from "pages/vehicleregistry/pages/checks/Checks.styled"
import { ServiceApproveSidebar } from "./components/ServiceApproveSidebar"

const viewList = [
  { value: "checks", label: "Kontroller" },
  { value: "history", label: "Historik" }
]

const InspectionService = ({ data }) => {
  const appContext = useContext(AppContext)
  const [inspectionsResult, inspectionsApi] = useApi()

  const [clickedCheck, setClickedCheck] = useState()
  const [showInspectionSidebar, setShowInspectionSidebar] = useState(false)
  const [showServiceSidebar, setShowServiceSidebar] = useState(false)
  const [view, setView] = useState(viewList[0].value)

  useEffect(() => {
    loadChecks()
  }, [])

  useEffect(() => {
    if (showInspectionSidebar) return
    setClickedCheck(undefined)
  }, [showInspectionSidebar])

  useEffect(() => {
    if (showServiceSidebar) return
    setClickedCheck(undefined)
  }, [showServiceSidebar])

  const loadChecks = () => {
    const url = apiEndpoints.vehicleregistrychecks.getvehiclechecksbyvehicleid.replace("{id}", data.id)
    inspectionsApi.get(url)
  }

  function openInspectionSidebar(checkRow) {
    setClickedCheck(checkRow)
    setShowInspectionSidebar(!showInspectionSidebar)
  }

  function openServiceSidebar(checkRow) {
    setClickedCheck(checkRow)
    setShowServiceSidebar(!showServiceSidebar)
  }

  const columns = useMemo(
    () => [
      {
        accessor: "status",
        Cell: ({ cell }) => {
          return (
            <IconButton color="primary" size="large">
              {cell.row.original.status === VehicleStatus.DrivingBan ? (
                <Tooltip title={VehicleStatus.DrivingBan}>
                  <StyledInspectionStatusIcon status={VehicleStatus.DrivingBan} icon={faTriangleExclamation} size="xs" />
                </Tooltip>
              ) : cell.row.original.status === VehicleStatus.Deregistered ? (
                <Tooltip title={VehicleStatus.Deregistered}>
                  <StyledInspectionStatusIcon status={VehicleStatus.Deregistered} icon={faP} size="xs" />
                </Tooltip>
              ) : cell.row.original.status === VehicleStatus.Delayed ? (
                <></>
              ) : (
                <>{cell.row.original.status}</>
              )}
            </IconButton>
          )
        },
        Header: "Status"
      },
      {
        accessor: "customFieldName",
        Header: "Kontroll"
      },
      {
        accessor: (row) => row?.attendanceDate ? moment(row?.attendanceDate).local().format("YYYY-MM-DD") : "",
        Cell: ({ cell }) => {
          const original = cell.row.original
          const printDate = original.attendanceDate ? moment(original.attendanceDate).local().format("YYYY-MM-DD") : ""
          const isDatePassed = moment().isSameOrAfter(original.attendanceDate)
          if (
            original.status === VehicleStatus.DrivingBan ||
            original.status === VehicleStatus.Delayed ||
            original.status === VehicleStatus.Deregistered ||
            isDatePassed
          ) {
            return <StyledSpanError>{printDate}</StyledSpanError>
          }
          return <span>{printDate}</span>
        },
        Header: "Inställelse"
      },
      {
        accessor: "attendanceKm",
        Header: "Inställelse km"
      },
      {
        accessor: "kmLeft",
        Header: "km kvar"
      },
      {
        accessor: (row) => row?.inspectionBooking ? row.inspectionBooking.carWorkshop.name : "",
        Header: "Plats"
      },
      {
        accessor: (row) => row?.inspectionBooking ? moment(row.inspectionBooking.dateTime).local().format("YYYY-MM-DD HH:mm") : "",
        Header: "Datum/tid"
      }
    ],
    []
  )

  useEffect(() => {
    if (hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)) {
      columns.push({
        accessor: "edit",
        numeric: false,
        disablePadding: true,
        Header: "Hantera",
        align: "right",
        Cell: ({ cell }) => {
          const controlType = cell.row.original.controlType
          if (controlType === "Besiktning") {
            return (
              <IconButton color="primary" size="large" onClick={() => {
                openInspectionSidebar(cell.row.original)
              }}>
                {cell.row.original.inspectionBooking ? (
                  <>
                    <FontAwesomeIcon icon={faPenToSquare} size="xs" />
                    <StyledTypography>Ändra</StyledTypography>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCalendarCirclePlus} size="xs" />
                    <StyledTypography>Boka</StyledTypography>
                  </>
                )}
              </IconButton>
            )
          } else if (controlType === "Service") {
            return (
              <IconButton
                color="primary"
                size="large"
                onClick={() => {
                  openServiceSidebar(cell.row.original)
                }}>
                {cell.row.original.status === null ? (
                  <>
                    <FontAwesomeIcon icon={faCheck} size="xs" />
                    <StyledTypography>Godkänn</StyledTypography>
                  </>
                ) : <></>}
              </IconButton>
            )
          }
        }
      })
    }
  }, [])

  return (
    <>
      <InspectionBookingSidebar
        setShowSidebar={setShowInspectionSidebar}
        showSidebar={showInspectionSidebar}
        clickedInspection={clickedCheck}
        companyId={data.companyId}
        loadInspections={loadChecks}
      />
      <ServiceApproveSidebar
        setShowSidebar={setShowServiceSidebar}
        showSidebar={showServiceSidebar}
        clickedService={clickedCheck}
        loadChecks={loadChecks}
      />
      <Box py={3}>
        <ViewToggler view={view} setView={setView} viewList={viewList} />
        {view === "checks" ? (
          <ClpTable
            columns={columns}
            rows={inspectionsResult.status === 2 && inspectionsResult.data}
            status={inspectionsResult.status}
          />
        ) : view === "history" ? (
          <VehicleInspectionsHistoryTable vehicleId={data.id} />
        ) : <></>}
      </Box>
    </>
  )
}

export default InspectionService
