import { faEdit, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, ButtonGroup, Chip, Container, Grid, IconButton, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApiDelete } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { Sidebar } from "pages/systemadmin/pages/devices/pages/technicalpackage/components/Sidebar"
import React, { useContext, useEffect, useState } from "react"

const ListTechincalPackage = () => {
  const globalClasses = globalUseStyles()
  const appContext = useContext(AppContext)
  const loadingContext = useContext(GlobalLoaderContext)
  const [showSidebar, setShowSidebar] = useState(false)
  const [technicalPackageResult, technicalPackageApi] = useApi()
  const [technicalPackageByIdResult, technicalPackageByIdApi] = useApi()
  const [addtechnicalpackage, addtechnicalpackageApi] = useApi()
  const [updatetechnicalpackage, updatetechnicalpackageApi] = useApi()
  const [deleteResult, deleteNotification] = useApiDelete()
  const [editMode, setEditMode] = useState(false)
  const [addOrUpdateResultState, setAddOrUpdateResultState] = useState(null)
  const [equipmentTypesResult, equipmenttypesApi] = useApi()

  useEffect(() => {
    technicalPackageApi.get(apiEndpoints.systemadmindeviceregistry.listtechnicalpackage)
    return () => {
      technicalPackageApi.leave()
    }
  }, [technicalPackageApi])

  useEffect(() => {
    equipmenttypesApi.get(apiEndpoints.systemadmindeviceregistry.gettechnicalpackageequipmenttype)
  }, [equipmenttypesApi])

  useEffect(() => {
    loadingContext.setLoading(() => technicalPackageResult.status === 1)
  })

  const handleDelete = (id) => {
    deleteNotification(apiEndpoints.systemadmindeviceregistry.deletetechnicalpackage.replace("{id}", id))
  }

  const getFormData = (id) => {
    setAddOrUpdateResultState(null)
    if (id) {
      setEditMode(true)
      technicalPackageByIdApi.get(
        apiEndpoints.systemadmindeviceregistry.gettechnicalpackagebyid.replace("{id}", id)
      )
    } else {
      setEditMode(false)
    }
    setShowSidebar(true)
  }

  const onSubmit = (values) => {
    if (editMode) {
      updatetechnicalpackageApi.put(apiEndpoints.systemadmindeviceregistry.updatetechnicalpackage, values)
    } else {
      addtechnicalpackageApi.post(apiEndpoints.systemadmindeviceregistry.addtechnicalpackage, values)
    }
  }

  useEffect(() => {
    if (
      updatetechnicalpackage.status === 2 ||
      deleteResult.status === 2 ||
      addtechnicalpackage.status === 2
    ) {
      technicalPackageApi.get(apiEndpoints.systemadmindeviceregistry.listtechnicalpackage)
      setShowSidebar(false)
    }
    setAddOrUpdateResultState(updatetechnicalpackage)
  }, [updatetechnicalpackage, technicalPackageApi, deleteResult, addtechnicalpackage])

  useEffect(() => {
    if (addtechnicalpackage.status === 2 && technicalPackageResult.data) {
      technicalPackageApi.get(apiEndpoints.systemadmindeviceregistry.listtechnicalpackage)
      setShowSidebar(false)
    }
    setAddOrUpdateResultState(addtechnicalpackage)
  }, [addtechnicalpackage, technicalPackageApi, technicalPackageResult.data])

  const columns = [
    {
      accessor: "name",
      Header: "Namn"
    },
    {
      accessor: "description",
      Header: "Beskrivning"
    },
    {
      accessor: "equipmentTypes",
      Header: "Utrustning",
      Cell: ({ cell }) => {
        return cell.row.original.equipmentTypes.map((x) => {
          return <Chip sx={{ marginRight: 1.4 }} variant="outlined" label={x.name} size="small"></Chip>
        })
      }
    }
  ]

  if (hasRole(appContext.appUser, allRoles.SystemAdminDeviceRegistry)) {
    columns.push({
      Cell: ({ cell }) => {
        return (
          <ButtonGroup>
            <IconButton onClick={() => handleDelete(cell.row.original.id)}>
              <FontAwesomeIcon icon={faTrashCan} size="xs" color="red" />
            </IconButton>
            <IconButton onClick={() => getFormData(cell.row.original.id)}>
              <FontAwesomeIcon icon={faEdit} size="xs" />
            </IconButton>
          </ButtonGroup>
        )
      },
      align: "right",
      accessor: "action",
      Header: "",
      hiddenFilter: true
    })
  }

  return (
    <div>
      <Container>
        {equipmentTypesResult.status === 2 && (
          <Sidebar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            onSubmit={onSubmit}
            editMode={editMode}
            addOrUpdateResultState={addOrUpdateResultState}
            technicalPackageResult={technicalPackageByIdResult}
            equipmentTypesResult={equipmentTypesResult.data}
          />
        )}

        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Teknikpaket</Typography>

            {hasRole(appContext.appUser, allRoles.SystemAdminDeviceRegistry) && (
              <Box>
                <Button variant="contained" component={Button} onClick={() => getFormData()} color="primary">
                  Lägg till Teknikpaket
                </Button>
              </Box>
            )}
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          rows={technicalPackageResult.status === 2 && technicalPackageResult.data}
          columns={columns}
          status={technicalPackageResult.status}
        />
      </Box>
    </div>
  )
}
export default ListTechincalPackage
