import { Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const LoadingTypography = ({ width = -100, variant, height = -100, noAnimate = false}) => {
    return (
        <Typography component="div" variant={variant} style={{ flex: "1" }}>
            <Skeleton
                animation={noAnimate ? false : "wave"}
                variant="text"
                width={width === -100 ? width : "100%"}
                height={height === -100 ? height : "100%"}
                fitContent
            ></Skeleton>
        </Typography>
    );
};

export default LoadingTypography;
