import { InspectionCardProps } from "./Checks.interfaces"
import { Grid, ListItemAvatar, ListItemText, Typography, ListItem } from "@mui/material"
import { SkeletonCardAvatar } from "components/skeleton/SkeletonCardAvatar"
import PaperBox from "components/PaperBox"
import { StyledTypographyGrey } from "./Checks.styled"
import { CircleIcon } from "components/icons/CircleIcon"
import React from "react"

export const InspectionCard = ({ status, icon, title, count, quantityType }: InspectionCardProps) => {
  return (
    <Grid item xs={12} md={10} lg={5} xl={3}>
      {status === 1 && <SkeletonCardAvatar />}
      {status === 2 && (
        <PaperBox paddingX={3} paddingY={3} marginY={0}>
          <ListItem disableGutters sx={{
            padding: "0"
          }}>
            <ListItemAvatar>
              <CircleIcon icon={icon} color="primary" size="lg" sx={{ marginRight: "15px" }} />
            </ListItemAvatar>
            <ListItemText>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <StyledTypographyGrey color="secondary" variant="bodyMedium">
                    {title}
                  </StyledTypographyGrey>
                  <Typography variant="h1" sx={{
                    color: "#000000",
                    fontWeight: "500"
                  }}>
                    {count} {quantityType}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>
        </PaperBox>
      )}
    </Grid>
  )
}