import {makeStyles} from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: '1px solid #E0E0E0',
    borderRadius: '0 !important',
    padding: '14px 0 20px 0',
    alignItems: 'flex-start !important',
    display: 'flex',
    flexDirection: 'column'
  },

  greyBorder: {
    borderTop: '1px solid #E0E0E0'
  },

  listItemIcon: {
    paddingRight: '12px',
    fontSize: '50px'
  },

  vehicleList: {
    maxWidth: '100%',
    backgroundColor: 'theme.palette.background.paper',
    padding: '64px 42px'
  },

  fullWidth: {
    maxWidth: '100%',
    display: 'flex',
    padding: '0px'
  },

  boldText: {
    fontWeight: 600
  }
}))
