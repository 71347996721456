import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ListItem, Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { useStyles } from "./useStyles"

export interface IQuickLink {
  route?: any
  text?: string
}

export const QuickLink = ({ route, text }: IQuickLink) => {
  const classes = useStyles()

  return (
    <ListItem disableGutters disablePadding>
      <Link className={classes.link} to={route}>
        <Typography color="textSecondary">{text}</Typography>
        <FontAwesomeIcon className={classes.icon} icon={faLongArrowRight} />
      </Link>
    </ListItem>
  )
}
