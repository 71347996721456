import { Box, Grid } from "@mui/material"
import LoadingBox from "components/skeleton/LoadingBox"
import LoadingTypography from "components/skeleton/LoadingTypography"
import React from "react"
import { LoadingTextCell } from "./TextCell"

import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  grid: {
    "& .MuiGrid-item": {
      minWidth: 150
    }
  }
}))

const LoadingHeader = ({ noAnimate }) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" alignItems="flex-end" spacing={5}>
      <Grid item md={9}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch">
          <Box marginRight={2}>
            <LoadingBox noAnimate={noAnimate} />
          </Box>
          <LoadingTypography variant="h2" noAnimate={noAnimate} />
        </Grid>

        <Box marginY={1} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          className={classes.grid}>
          <Grid item>
            <LoadingTextCell noAnimate={noAnimate} />
          </Grid>
          <Grid item>
            <LoadingTextCell noAnimate={noAnimate} />
          </Grid>
          <Grid item>
            <LoadingTextCell noAnimate={noAnimate} />
          </Grid>
          <Grid item>
            <LoadingTextCell noAnimate={noAnimate} />
          </Grid>
          <Grid item>
            <LoadingTextCell noAnimate={noAnimate} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3}>
        <LoadingTypography variant="caption" noAnimate={noAnimate} />
        <LoadingTypography variant="bodySmall" noAnimate={noAnimate} />
        <LoadingTypography variant="bodySmall" noAnimate={noAnimate} />
        <LoadingTypography variant="bodySmall" noAnimate={noAnimate} width={"80%"} />
      </Grid>
    </Grid>
  )
}
export default LoadingHeader
