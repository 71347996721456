import React from "react"

import { Box, Container, Grid, List, ListItem, Typography } from "@mui/material"
import { PageTabs } from "components/navigation/PageTabs"
import PaperBox from "components/PaperBox"
import { allRoutes } from "generated/routes"
import ImportResult from "./components/ImportResult"
import { SimpleUploadFile } from "./components/SimpleUploadFile"

const ImportLegacyExams = ({ match }) => {
  const [importResult, setImportResult] = React.useState()
  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.SystemadminCustomersEditUsers(match.params.id),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        <Box marginY={3}>
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Importera utbildningsresultat från förra utbildningstjänsten</Typography>
          </Grid>
        </Box>
        <PaperBox>
          <Box mb={2}>
            <Typography>
              Importera data genom att ladda upp en fil i CSV-format. Avskiljare ska vara semikolon (;) och
              fält med listvärden ska separeras med kommatecken (,)
            </Typography>
            <Box mb={2}></Box>
            <Typography>Filen får inte använda rubriker på kolumnerna utan endast innehålla data.</Typography>
            <Box mb={2}></Box>
            <Typography>Formatet på varje rad måste innehålla följande kolumner:</Typography>
            <List>
              <ListItem>
                Användarnamn - Obligatoriskt, användaren som ska få resultaten i detta system
              </ListItem>
              <ListItem>
                Utbildningsnamn - Obligatoriskt, namnet på utbildningen i detta system som resultaten ska
                kopplas mot
              </ListItem>
              <ListItem>Poäng - Obligatoriskt, Antal poäng från förra systemet</ListItem>
              <ListItem>
                URL Till PDF - Obligatorisk, Fullständig URL+sökväg till dokumentet som innehåller detaljer
                för resultatet. (Troligtvis ex.
                https://cloudplanningpdfarchive.blob.core.windows.net/testpdfs/Utbildning%20och%20Namn.pdf)
              </ListItem>
              <ListItem>
                Godkänd - Obligatorisk, Ja/Nej om resultatet är ett godkänt resultat eller inte.
              </ListItem>
              <ListItem>
                Datum - Obligatoriskt, Datum och tid i lokal svensk tid när testet skapades. (Exempelvis
                "2019-04-06 15:50:00")
              </ListItem>
            </List>
            <Box mb={2}></Box>
            <Typography>
              Om någon rad har valideringsfel importeras inga rader. Korrigera isåfall den felande raden och
              ladda upp filen på nytt.
            </Typography>
            <Box mb={2}></Box>
          </Box>
          <SimpleUploadFile setImportResult={setImportResult} />
          {importResult?.status === 2 && <ImportResult result={importResult} />}
        </PaperBox>
      </Container>
    </div>
  )
}
export default ImportLegacyExams
