import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { StandardButton } from "components/Button"
import PaperCard from "components/PaperCard"
import React from "react"

export const PasswordSuggestion = ({ values, setFieldValue, passwordSuggestEndpoint }) => {
  const [newPasswordResult, newPasswordApi] = useApi()

  React.useEffect(async () => {
    await newPasswordApi.get(passwordSuggestEndpoint)
  }, [])

  React.useEffect(() => {
    if (newPasswordResult.status === 2) {
      setFieldValue("password", newPasswordResult.data)
    }
  }, [newPasswordResult])

  return (
    <PaperCard>
      <Box mb={2}>
        <Typography>Slumpmässigt genererat lösenord:</Typography>
      </Box>
      <Typography variant="h5" on>
        {values.password}
      </Typography>
      <Box mt={2} mb={2}>
        <StandardButton
          color="primary"
          startIcon={<FontAwesomeIcon spin={newPasswordResult.status === 1} icon={faCircleNotch} />}
          onClick={() => {
            newPasswordApi.get(passwordSuggestEndpoint)
          }}>
          Slumpa om
        </StandardButton>
      </Box>
      <Typography>
        Skriv ner detta lösenord och ge till användaren. Om lösenordet verkar för krångligt testa slumpa fram
        ett nytt.
      </Typography>
    </PaperCard>
  )
}
