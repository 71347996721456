import { faSquareUpRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Masonry } from "@mui/lab"
import {
  Autocomplete,
  BottomNavigation,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch
} from "@mui/material"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useApiGet } from "auth/useApi2"
import Button from "components/Button"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import { deviceValidationSchema } from "pages/systemadmin/pages/devices/components/validationSchema"
import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"

const serviceLevels = [
  { name: "Inget ", id: null },
  { name: "80", id: 80 },
  { name: "60", id: 60 },
  { name: "40", id: 40 },
  { name: "20", id: 20 }
]

const SwitchItem = ({ equipmentType, handleChange, handleBlur, equipments = [], setFieldValue }) => {
  const [opened, setOpened] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [equipmentTypeResult, getEquipmentsByType] = useApiGet({})

  useEffect(() => {
    const equipmentSelected =
      (equipments && equipments.find((i) => i.equipmentTypeId === equipmentType.id)) || null
    equipmentSelected && setOpened(true)
  }, [equipmentType, equipments])

  useEffect(() => {
    setSelectedId(
      equipments.find((i) => i.equipmentTypeId === equipmentType.id)
        ? equipments.find((i) => i.equipmentTypeId === equipmentType.id).id
        : null
    )
  }, [equipments])
  useEffect(() => {
    if (opened && !equipmentTypeResult.data) {
      getEquipmentsByType(
        apiEndpoints.systemadmindeviceregistry.getequipmentbytype.replace("{id}", equipmentType.id)
      )
    }
  }, [opened, equipmentTypeResult.data, getEquipmentsByType, equipmentType])

  const addOrRemoveToList = () => {
    setOpened(!opened)
    if (opened) {
      const newList = equipments
      const index = equipments.findIndex((item) => item.equipmentTypeId === equipmentType.id)
      if (index !== -1) {
        newList.splice(index, 1)
      }
      setFieldValue("equipments", newList)
      setSelectedId(
        newList.find((i) => i.equipmentTypeId === equipmentType.id)
          ? newList.find((i) => i.equipmentTypeId === equipmentType.id).id
          : null
      )
    }
  }

  const setEquipmentToList = (value) => {
    const newList = equipments
    if (value) {
      const index = newList.findIndex((item) => item.equipmentType === value.equipmentType)
      if (index === -1) {
        newList.push(value)
      } else {
        newList[index] = value
      }
    } else {
      const index = newList.findIndex((item) => item.equipmentType === equipmentType)
      newList.splice(index, 1)
    }
    setFieldValue("equipments", newList)
  }

  return (
    <ListItem
      style={{
        marginBottom: 8,
        background: opened ? theme.palette.primary["light"] : null,
        border: `1px solid ${opened ? theme.palette.primary["dark"] : theme.palette.grey["100"]}`,
        borderRadius: 4
      }}>
      <Stack direction="row" spacing={2} width="100%" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={<Switch onChange={() => addOrRemoveToList()} checked={opened} />}
          label={equipmentType.name}
        />

        <Box>
          {opened && (
            <Box>
              {equipmentTypeResult.data && (
                <Autocomplete
                  style={{ minWidth: 250, background: "white" }}
                  size="small"
                  id="equipmentId"
                  options={equipmentTypeResult.data}
                  getOptionLabel={(option) =>
                    option.equipmentId ? option.equipmentId : option.id ? option.id.toString() : ""
                  }
                  value={(equipments && equipments.find((i) => i.equipmentTypeId === equipmentType.id)) || ""}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id
                  }}
                  onChange={(e, value) => {
                    setEquipmentToList(value)
                    setSelectedId(value.id)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" onChange={handleChange} onBlur={handleBlur} />
                  )}
                />
              )}
            </Box>
          )}
        </Box>
        {equipments && equipments.find((i) => i.equipmentTypeId === equipmentType.id) !== undefined && (
          <Box
            color={theme.palette.primary["main"]}
            component={NavLink}
            target="_blank"
            to={allRoutes.SystemadminDevicesEquipmentEdit(selectedId)}>
            <FontAwesomeIcon icon={faSquareUpRight} />
          </Box>
        )}
      </Stack>
    </ListItem>
  )
}
const groupedData = (data) =>
  data.reduce((groups, item) => {
    const key = item.equipmentTypeName
    if (!groups[key]) {
      groups[key] = []
    }
    groups[key].push(item)
    return groups
  }, {})
export const DeviceForm = ({
  onSubmit,
  editDevice,
  result,
  feedback,
  companyResult,
  equipmentTypesResult
}) => {
  const initialValues = editDevice
    ? editDevice
    : {
        deviceId: "",
        internalDeviceId: "",
        deviceTypeId: 1,
        isActive: true
      }
  const editMode = editDevice ? true : false
  const handleSubmit = async (values, { resetForm }) => {
    onSubmit(values)
  }
  const [technicalPackagesResult, getTechnicalPackages] = useApiGet({})
  useEffect(() => {
    getTechnicalPackages(apiEndpoints.systemadmindeviceregistry.listtechnicalpackage)
  }, [])
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnChange={false}
      validationSchema={deviceValidationSchema}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container direction="row" spacing={2}>
            <Grid item md={8}>
              <PaperBox>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Masonry columns={{ xs: 1, md: 2 }} spacing={3}>
                      {equipmentTypesResult.data &&
                        Object.keys(groupedData(equipmentTypesResult.data))?.map((group, index) => {
                          return (
                            <Box key={index}>
                              <Box>
                                <Typography variant="h5" marginBottom={1}>
                                  {group}
                                </Typography>
                              </Box>
                              {Object.values(groupedData(equipmentTypesResult.data)[group]).map(
                                (equipmentType, index) => {
                                  return (
                                    <Box style={{ marginY: 15 }} key={index}>
                                      <SwitchItem
                                        equipmentType={equipmentType}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        equipments={values.equipments}
                                        setFieldValue={setFieldValue}
                                      />
                                    </Box>
                                  )
                                }
                              )}
                            </Box>
                          )
                        })}
                    </Masonry>
                  </Grid>
                </Grid>
              </PaperBox>
            </Grid>
            <Grid item md={4}>
              <PaperBox>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box backgroundColor={theme.palette.grey[100]} p={2} marginBottom={2}>
                      <Stack direction="column" spacing={3}>
                        <Box>
                          <Typography variant="h5">Teknikpaket</Typography>
                          <Typography variant="bodySmall">
                            Välj det paket som är sålt till kunden, det kan sedan användas för att ta ut
                            faktureringsunderlag.
                          </Typography>
                        </Box>
                        {technicalPackagesResult.status === 2 && (
                          <Autocomplete
                            style={{ minWidth: "100%" }}
                            id="technicalPackageId"
                            options={technicalPackagesResult.data}
                            getOptionLabel={(option) => (option.name ? option.name : "")}
                            value={
                              technicalPackagesResult.data.find((i) => i.id === values.technicalPackageId) ||
                              ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id
                            }}
                            onChange={(e, value) => {
                              setFieldValue("technicalPackageId", value?.id)
                            }}
                            renderInput={(params) => (
                              <TextField
                                style={{ background: theme.palette.background.paper }}
                                {...params}
                                label="Välj paket"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.technicalPackageId && errors.technicalPackageId ? true : false}
                                helperText={
                                  touched.technicalPackageId && errors.technicalPackageId
                                    ? errors.technicalPackageId
                                    : null
                                }
                              />
                            )}
                          />
                        )}
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="internalDeviceId"
                      label="Internnummer"
                      value={values.internalDeviceId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      error={touched.internalDeviceId && errors.internalDeviceId ? true : false}
                      helperText={
                        touched.internalDeviceId && errors.internalDeviceId ? errors.internalDeviceId : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="deviceId"
                      label="Registreringsnummer"
                      value={values.deviceId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.deviceId && errors.deviceId ? true : false}
                      helperText={touched.deviceId && errors.deviceId ? errors.deviceId : null}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      rows={2}
                      multiline
                      fullWidth
                      id="deviceDescription"
                      label="Beskrivning"
                      value={values.deviceDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.deviceDescription && errors.deviceDescription ? true : false}
                      helperText={
                        touched.deviceDescription && errors.deviceDescription
                          ? errors.deviceDescription
                          : null
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="simCardSupplier">Simkortsleverantör</FormLabel>
                      <RadioGroup
                        row
                        aria-label="simCardSupplier"
                        name="simCardSupplier"
                        value={values.simCardSupplier}
                        onChange={(e) => {
                          const value = e.target.value === "" ? null : Number.parseInt(e.target.value)
                          setFieldValue("simCardSupplier", value)
                        }}>
                        <FormControlLabel value={null} control={<Radio />} label="Ingen" />
                        <FormControlLabel value={0} control={<Radio />} label="Malux" />
                        <FormControlLabel value={1} control={<Radio />} label="Eget" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="phone"
                      label="Telefon"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      error={touched.phone && errors.phone ? true : false}
                      helperText={touched.phone && errors.phone ? errors.phone : null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 250 }}>
                      <InputLabel id="serviceLevel-label">Servicenivå</InputLabel>
                      <Select
                        variant="outlined"
                        labelId="serviceLevel-label"
                        id="serviceLevel"
                        label="Servicenivå"
                        value={values.serviceLevel}
                        onChange={(event) => {
                          const value = event.target.value
                          setFieldValue("serviceLevel", value)
                        }}>
                        {serviceLevels &&
                          serviceLevels.map((item) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>
                          })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(item) => {
                            const checked = item.target.checked
                            setFieldValue("isActive", checked)
                          }}
                          checked={values.isActive}
                        />
                      }
                      label="Aktiv"
                    />
                  </Grid>
                </Grid>
              </PaperBox>
            </Grid>
          </Grid>
          <Box marginTop={15}></Box>
          <Box sx={{ position: "fixed", bottom: 8, left: 0, right: 15 }} elevation={3}>
            <BottomNavigation
              sx={{ justifyContent: "flex-end", backgroundColor: "transparent", height: "auto" }}>
              <Button color="primary" type="submit" result={result} feedback={feedback}>
                {editMode ? "Spara" : "Lägg till"}
              </Button>
            </BottomNavigation>
          </Box>
        </form>
      )}
    </Formik>
  )
}
