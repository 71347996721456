import moment from "moment"
import { CalendarProps, stringOrDate } from "react-big-calendar"
// @ts-ignore //Saknas i big-react-calendar interface
import Month from "react-big-calendar/lib/Month"

const renderWeekNumbers = (date: stringOrDate | undefined) => {
  const weeks = []

  const startOfMonth = moment(date).startOf("month").startOf("week")
  const endOfMonth = moment(date).endOf("month").endOf("week")
  let weekStart = startOfMonth

  while (weekStart.isSameOrBefore(endOfMonth)) {
    weeks.push(
      <div
        key={weekStart.format("YYYY-MM-DD")}
        className="week-number"
        style={{
          height: "100%", // Set height to 100% to fill the parent container
          display: "flex",
          paddingTop: "4px",
          alignItems: "flex-start",
          color: "rgba(0, 0, 0, 0.55)",
          fontSize: "14px",
          fontWeight: "600"
        }}>
        {weekStart.format("w")}
      </div>
    )
    weekStart = weekStart.clone().add(1, "week")
  }

  return weeks
}

function CustomMonthView({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}: CalendarProps) {
  return (
    <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
      <div
        className="week-numbers"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "flex-start", // Align items to the start of the cross axis
          marginTop: "21px",
          backgroundColor: "#D9EEF4",
          padding: "0 6px",
          borderEndStartRadius: "8px",
          borderStartStartRadius: "8px"
        }}>
        {renderWeekNumbers(date)}
      </div>
      <Month date={date} localizer={localizer} scrollToTime={scrollToTime} {...props} />
    </div>
  )
}

CustomMonthView.title = (date: Date) => {
  return `${moment(date).format("MMMM")}`
}
export default CustomMonthView
