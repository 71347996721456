import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import classNames from "classnames"
import React from "react"

import { useStyles } from "../useStyles"

const GreenCheck = () => {
  const classes = useStyles()
  return (
    <Avatar sx={{ width: 24, height: 24 }} className={classNames(classes.green, classes.small)}>
      <FontAwesomeIcon icon={faCheck} size={"xs"} />
    </Avatar>
  )
}

const RedTimes = () => {
  const classes = useStyles()
  return (
    <Avatar sx={{ width: 24, height: 24 }} className={classNames(classes.red, classes.small)}>
      <FontAwesomeIcon icon={faTimes} size={"xs"} />
    </Avatar>
  )
}

export const Option = (props) => {
  const { item } = props
  const classes = useStyles()

  return (
    <ListItem
      className={classNames(
        classes.listItem,
        item.isCorrectAnswer && item.isAnswered && classes.green,
        !item.isCorrectAnswer && item.isAnswered && classes.red
      )}>
      <ListItemText primary={item.label} />

      {item.isCorrectAnswer && (
        <ListItemAvatar>
          <GreenCheck />
        </ListItemAvatar>
      )}
      {!item.isCorrectAnswer && item.isAnswered && (
        <ListItemAvatar>
          <RedTimes />
        </ListItemAvatar>
      )}
    </ListItem>
  )
}
