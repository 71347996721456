import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCircleEnvelope, faCirclePhone } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, Link, Typography } from "@mui/material"
import theme from "css/theme/light"
import { useStyles } from "./useStyles"

interface ContactInfoCardProps {
  data: {
    email?: string
    name: string
    phone: number
  }
}

const ContactInfoCard = ({ data }: ContactInfoCardProps) => {
  const { name, email, phone } = data
  const classes = useStyles()
  return (
    <>
      <Grid container direction="column" className={classes.contact} gap={2} padding={3}>
        <Grid item>
          <Typography variant="h4"> {name} </Typography>
        </Grid>
        {email && (
          <Grid item>
            <Link href={`mailto:${email}`} variant="bodyMedium" color="text.primary" underline="none">
              <FontAwesomeIcon
                style={{ marginRight: "8px" }}
                color={theme.palette.primary["main"]}
                icon={faCircleEnvelope as IconProp}
                size="2xl"
              />
              {email}
            </Link>
          </Grid>
        )}
        <Grid item>
          {phone && (
            <>
              <Link href={`tel:${phone}`} variant="bodyMedium" color="text.primary" underline="none">
                <FontAwesomeIcon
                  style={{ marginRight: "8px" }}
                  color={theme.palette.primary["main"]}
                  icon={faCirclePhone as IconProp}
                  size="2xl"
                />
                {phone}
              </Link>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ContactInfoCard
