import { Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import withStyles from "@mui/styles/withStyles"

const useStyles = makeStyles((theme) => ({
  noBorderBottom: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
}))

const StyledBox = withStyles({
  root: {
    boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 4%)",
    width: "100%"
  }
})(Box)

export const PaperBox = (props) => {
  const { children, noborderbottom } = props
  const classes = useStyles()
  return (
    <StyledBox
      mt={2}
      spacing={3}
      p={3}
      bgcolor="background.paper"
      borderRadius={2}
      className={noborderbottom && classes.noBorderBottom}
      {...props}>
      {children}
    </StyledBox>
  )
}

export default PaperBox
