import { Box, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Button from "components/Button"
import { Formik } from "formik"
import { nanoid } from "nanoid"

const ViolationDrivingTypesAdd = ({ onSubmit }) => {
  const initialValues = {
    name: "",
    id: 0
  }

  const handleSubmit = async (values, { resetForm }) => {
    const violationDriverTypesToSave = {
      ...values,
      listId: nanoid()
    }
    resetForm()
    onSubmit(violationDriverTypesToSave)
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize={true}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2} pb={2}>
            <Grid item xs={12}>
              <Typography variant="h5"> Bolagsspecifika kategorier för "typ av körning"</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                id="name"
                label="Typ av körning"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Box pb={2} display="flex" justifyContent="flex-end">
                <Button color="primary" type="button" variant="outlined" size="small" onClick={handleSubmit}>
                  Lägg till
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}
export default ViolationDrivingTypesAdd
