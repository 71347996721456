import { faBellExclamation, faBellOn } from "@fortawesome/pro-solid-svg-icons"
import { Grid } from "@mui/material"
import React from "react"
import { VehicleAlarmDashboardCard } from "./VehicleAlarmDashboardCard"

const titles = [
  {
    title: "Aktiva",
    icon: faBellExclamation
  },
  {
    title: "Pågående",
    icon: faBellOn
  }
]

export const VehicleAlarmDashboard = ({ alarmData, vehicleAlarmsResult }) => {
  const vehicleAlarmsLength = (title) => {
    if (title === "Aktiva") {
      return alarmData.open
    }
    if (title === "Pågående") {
      return alarmData.ongoing
    }
    if (title === "Avslutad") {
      return alarmData.done
    } else {
      return "0"
    }
  }

  return (
    <Grid container spacing={2}>
      {titles.map((item, index) => {
        const { title, icon } = item
        return (
          <VehicleAlarmDashboardCard
            vehicleAlarmsResult={vehicleAlarmsResult}
            icon={icon}
            key={index}
            itemLength={vehicleAlarmsLength}
            title={title}
          />
        )
      })}
    </Grid>
  )
}
