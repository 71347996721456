// listar alla typer av event och möjlighet att subscriba. visar också om du är subscribad typ en table och kryssruta för varje deliverymethod som idagsläget bara är epost

import { Grid } from "@mui/material"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import Typography from "@mui/material/Typography"
import { useApi } from "auth/useApi3"
import PaperBox from "components/PaperBox"
import { apiEndpoints } from "generated/apiEndpoints"
import React from "react"

const EventTypeControl = ({ item }) => {
  const [selected, setSelected] = React.useState(item.emailSelected)
  const [updateResult, updateFuncs] = useApi()

  return (
    <PaperBox>
      <Typography variant="bodyMedium">{item.name}</Typography>
      <Typography variant="caption">{item.description}</Typography>
      <FormControlLabel
        control={
          <Switch
            onChange={(e) => {
              const checked = e.target.checked
              setSelected(checked)
              if (checked) {
                updateFuncs.post(
                  apiEndpoints.systemadminsystem.subscribetoeventtype
                    .concat("?eventType=")
                    .concat(item.systemEventTypeId)
                    .concat("&deliveryMethod=Email")
                )
              } else {
                updateFuncs.post(
                  apiEndpoints.systemadminsystem.unsubscribetoeventtype
                    .concat("?eventType=")
                    .concat(item.systemEventTypeId)
                    .concat("&deliveryMethod=Email")
                )
              }
            }}
            id="selected"
            disabled={updateResult.status === 1}
            checked={selected}
          />
        }
        label="Notifiera mig via e-post när detta sker"
      />
    </PaperBox>
  )
}

export const EventTypesList = ({ items }) => {
  return (
    <Grid container spacing={1}>
      {items.map((item) => {
        return (
          <Grid key={item.systemEventTypeId} item xs={12}>
            <EventTypeControl item={item} />
          </Grid>
        )
      })}
    </Grid>
  )
}
