import { Box, Grid, Switch, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { useTranslation } from "react-i18next"

export const FeatureBox = ({ setFieldValue, feature, arrayName }: any) => {
  const { t } = useTranslation("translation", { keyPrefix: "global.features" })
  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={8}>
          <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Box>
              <Typography variant="bodyMedium">
                {t(`title.${feature?.feature.toLowerCase()}`, {defaultValue: feature?.name})}
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={1} sx={{ textAlign: "right" }}>
          <Switch
            onChange={() => setFieldValue(`${arrayName}.enabled`, !feature?.enabled)}
            checked={feature?.enabled}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
