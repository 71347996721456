import { faCar, faSeedling, faTruckClock } from "@fortawesome/pro-solid-svg-icons"

export const examOverviewName = (examinationName: String) => {
  switch (examinationName) {
    case "Kör- & vilotider":
      return "driving_rest_passenger_traffic"
    case "Kör- & vilotider (Godstrafik)":
      return "driving_rest_freight_traffic"
    case "Miljöutbildning - Transport":
      return "environmental"
    case "Bilkörning i offentlig miljö":
      return "driving_public"
    default:
      return null
  }
}

export const examOverviewIcons = (examinationId: any) => {
  switch (examinationId) {
    case 1:
      return faTruckClock
    case 2:
      return faSeedling
    case 10:
      return faCar
    default:
      return null
  }
}
