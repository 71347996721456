import { Box, Typography } from "@mui/material"
import React from "react"
import { useStyles } from "./useStyles"

export const ReadMoreReadLessTitle = () => {
  const classes = useStyles()

  return (
    <>
      <Typography mt={2} mb={2} fontSize="13px" color="warning.main">
        Kontrollera befintliga felrapporter för att undvika dubletter
      </Typography>
      <Box className={classes.greyBorderBottom} display="flex" justifyContent="space-between">
        <Typography mb={1} fontWeight="600" variant="bodySmall">
          Beskrivning
        </Typography>
        <Typography mb={1} fontWeight="600" variant="bodySmall">
          Datum
        </Typography>
      </Box>
    </>
  )
}
