import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    Link: {
        textDecoration: "none",
        color: theme.palette.grey[800],
        fontWeight: 300
    }
}));

export const NavigateTo = ({ to, children }) => {
    const classes = useStyles();

    return (
        <Link to={to} className={classes.Link}>
            {children}
        </Link>
    );
};
