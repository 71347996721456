
// Helper component to render a component by its string name
const SadComponent = ({name}) => {
    return <div><h4>:(</h4>
    <p>Komponent med namn <i>{name}</i> hittades inte.</p>    
    </div>
}

export const RenderComponent = (props) => {
    
    const { name, knownComponents } = props;

    var componentExists = knownComponents[name] !== undefined;
    
    var Component = knownComponents[name]; 

    return componentExists ? <Component {...props} /> : <SadComponent name={name} />;
}