import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faDownload, faLayerGroup, faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import NoResultFound from "components/Dialogs/NoResultFound"
import UserAvatar from "components/UserAvatar"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { t } from "i18next"
import React, { useState } from "react"
import Moment from "react-moment"
import { getFileType } from "../helpers/getFileType"
import { IDocumentAttachment } from "../interface/folder.interface"

const FileList = ({ attachments, downloadUrl }: any) => {
  const [clickedFileId, setClickedFile] = useState<string | null>(null)

  const [getAttachmentResult, getAttachment] = useApi() as [IApiResult, IApiFunc]

  const downloadAttachment = React.useCallback(
    (fileId, fileName) => {
      getAttachment.getBlob(downloadUrl.replace("{id}", fileId), fileName)
    },
    [getAttachment]
  )

  return (
    <>
      <div>
        {attachments && attachments.length > 0 ? (
          attachments.map((attachment: IDocumentAttachment, index: number) => (
            <Box
              gap={2}
              borderBottom={attachments.length - 1 !== index ? 1 : 0}
              px={2}
              borderColor="#EEEEEE"
              mb={attachments.length - 1 !== index ? 2 : 0}>
              <Grid
                container
                direction="row"
                alignItems="center"
                paddingBottom={1}
                spacing={0}
                justifyContent="space-between"
                style={{ color: "inherit", textDecoration: "inherit" }}>
                <Grid item xs={12} md={4} lg={5}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <FontAwesomeIcon
                      color={theme.palette.primary["main"]}
                      icon={getFileType(attachment.fileId) as IconProp}
                    />

                    <Typography
                      display="inline-block"
                      variant="bodyMedium"
                      fontWeight="600"
                      sx={{
                        "&:hover": {
                          cursor: "pointer"
                        }
                      }}
                      onClick={() => {
                        setClickedFile(attachment.fileId)
                        downloadAttachment(attachment.id, attachment.fileId)
                      }}>
                      {attachment.name}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={4} md={3} lg={2}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {attachment.createdByUser?.name && (
                      <UserAvatar
                        label={attachment.createdByUser?.name}
                        size="sm"
                        style={{ marginRight: 0 }}
                      />
                    )}

                    <Typography variant="bodySmall">{attachment.createdByUser?.name}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Typography variant="bodySmall" color="textSecondary">
                    {t(`documents.last_updated`)} <br />
                    {attachment.updatedDate && (
                      <Moment utc local format="YYYY-MM-DD">
                        {attachment.updatedDate}
                      </Moment>
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={4} sm={1}>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                    <IconButton
                      edge="end"
                      type="button"
                      aria-label="Ladda ner"
                      onClick={() => {
                        setClickedFile(attachment.fileId)
                        downloadAttachment(attachment.id, attachment.fileId)
                      }}>
                      <FontAwesomeIcon
                        color={theme.palette.primary["main"]}
                        spin={getAttachmentResult.status === 1 && clickedFileId === attachment.fileId}
                        icon={
                          getAttachmentResult.status === 1 && clickedFileId === attachment.fileId
                            ? (faSpinnerThird as IconProp)
                            : (faDownload as IconProp)
                        }
                        size="xs"
                      />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <NoResultFound
            icon={faLayerGroup as IconProp}
            message={t(`documents.attachment.files_empty`)}
            color="primary"
            size="lg"
          />
        )}
      </div>
    </>
  )
}
export default FileList
