import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { Autocomplete, Box, Card, Container, Grid, ListItem, TextField, Typography } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { hasRole } from "auth/hasRole"
import { useApiFile } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import ClpButton from "components/Button"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import SuccessDialog from "components/Dialogs/SuccessDialog"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import MultipleFilePickerPreviewList from "components/MultipleFilePickerPreviewList"
import { RouterPrompt } from "components/RouterPrompt"
import theme from "css/theme/light"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import { IssueReportPriorityList } from "helpers/IssueReports/IssueReportPriorityList"
import { issueReportStatusColor } from "helpers/IssueReports/IssueReportStatusColor"
import { issueReportStatusIcon } from "helpers/IssueReports/IssueReportStatusIcon"
import { issueReportStatusLabel } from "helpers/IssueReports/IssueReportStatusLabel"
import { IssueReportStatusList } from "helpers/IssueReports/IssueReportStatusList"
import React, { useContext, useEffect, useRef, useState } from "react"
import Moment from "react-moment"
import { Link, useHistory } from "react-router-dom"
import * as Yup from "yup"
import { useStyles } from "../../components/useStyles"
import WorkOrderTitle from "../../workorders/components/WorkOrderTitle"

const IssueReports = ({ issuereportResult }) => {
  // formik initialvalues
  const initialValues = {
    issueReportCategoryId: "",
    description: "",
    attachments: []
  }

  const classes = useStyles()
  const appContext = useContext(AppContext)

  // validation;
  const currentValidationSchema = Yup.object().shape({
    status: Yup.string().required("Status är obligatoriskt,"),
    priority: Yup.string().required("Prioritet är obligatoriskt"),
    description: Yup.string().max(300, "Beskrivning får max vara 300 tecken långt"),
    issueReportCategoryId: Yup.string()
  })

  const loadingContext = React.useContext(GlobalLoaderContext)

  const [userstoassign, userstoassignFuncs] = useApi([])
  const [showDeleteModel, setShowDeleteModal] = useState(false)
  const [showContinueModal, setShowContinueModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [parentData, setParentData] = useState(issuereportResult.data)
  const [chosenCategory, setChosenCategory] = useState("")
  const [updatedIssuereportResult, issuereportFuncs] = useApi()
  const [deleteResult, deleteIssueReportApi] = useApi()
  const [categorysResult, categoryApi] = useApi()
  const [addResult, addApi] = useApi()
  const routerHistory = useHistory()
  const formRef = useRef()

  /* GET Categories */
  useEffect(() => {
    categoryApi.get(apiEndpoints.issuereports.getissuereportcategories)
    return () => {
      categoryApi.leave()
    }
  }, [categoryApi])

  useEffect(() => {
    setParentData(issuereportResult.data)
  }, [issuereportResult.data])

  /* GET Issue Report information */

  useEffect(() => {
    userstoassignFuncs.get(apiEndpoints.issuereportsadmin.getuserstoassign)

    return () => {
      userstoassignFuncs.leave()
    }
  }, [userstoassignFuncs])

  useEffect(() => {
    loadingContext.setLoading(() => addResult.status === 1)
    loadingContext.setLoading(() => fileUploadResult.status === 1)
    loadingContext.setLoading(() => categorysResult.status === 1)
  })

  useEffect(() => {
    if (addResult.status === 2) {
      issuereportFuncs.get(apiEndpoints.issuereports.getissuereport.replace("{id}", parentData?.id))
    }
  }, [addResult])

  useEffect(() => {
    if (updatedIssuereportResult.status === 2) {
      setParentData(updatedIssuereportResult.data)
      setShowContinueModal(false)
      setShowSuccessModal(true)
      setEditMode(false)
    }
  }, [updatedIssuereportResult])

  // post data & set states for modals
  const onAddSubmit = (values) => {
    addApi.put(apiEndpoints.issuereportsadmin.updateissuereport, values)
    setShowSuccessModal(false)
  }

  const onDelete = () => {
    deleteIssueReportApi.del(
      apiEndpoints.issuereportsadmin.deleteissuereport.replace("{id}", issuereportResult.data.id)
    )
    setShowDeleteModal(false)
  }

  // handle submit formik form & show continue modal
  const handleSubmit = () => {
    setShowContinueModal(true)
  }

  useEffect(() => {
    // redirect back to list
    if (deleteResult.status === 2) routerHistory.push(allRoutes.IssuereportsDashboard())
  }, [deleteResult.status, routerHistory])

  const buttonFeedbackResult = addResult

  const buttonFeedbackResultDelete = deleteResult

  const [fileUploadResult, uploadFile] = useApiFile()
  const [files, setFiles] = useState([])
  const [filesPreview, setFilesPreview] = useState(initialValues.attachments)

  useEffect(() => {
    if (files) {
      files.map((file) => {
        return uploadFile(apiEndpoints.issuereports.uploadfile, file)
      })
    }
  }, [files, uploadFile])

  useEffect(() => {
    if (fileUploadResult.data && fileUploadResult.data.name) {
      setFilesPreview((p) => {
        return [...p, fileUploadResult.data]
      })
      issuereportResult.data.attachments.push(fileUploadResult.data)
    }
  }, [fileUploadResult])

  const onLogoChanged = (value) => {
    setFilesPreview(value)
  }

  const closeEdit = () => {
    setEditMode(false)
  }
  return (
    <div>
      <Container pb={2}>
        {parentData !== null && (
          <>
            <Formik
              initialValues={parentData}
              validationSchema={currentValidationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              innerRef={formRef}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                handleSubmit,
                setFieldValue
              }) => (
                <Box>
                  <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
                    <RouterPrompt />
                    <Grid container spacing={4} mt={0}>
                      <Grid item xs={12} md={12} direction="row">
                        {hasRole(appContext.appUser, allRoles.IssueReportsAdmin) ? (
                          <Grid
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                              alignItems: "baseline"
                            }}
                            className={classes.doubleButtons}>
                            <WorkOrderTitle title={"Felrapport"} initialValues={parentData} />
                            <Box sx={{ gridGap: "10px", display: "flex" }}>
                              {editMode ? (
                                <>
                                  <ClpButton onClick={closeEdit} color="primary" variant="outlined">
                                    Avbryt
                                  </ClpButton>

                                  <ClpButton
                                    onClick={() => setShowContinueModal(true)}
                                    color="secondary"
                                    startIcon={<SaveOutlinedIcon />}>
                                    Spara
                                  </ClpButton>
                                </>
                              ) : (
                                <>
                                  <ClpButton
                                    onClick={() => setEditMode(true)}
                                    variant="outlined"
                                    startIcon={<EditIcon />}>
                                    Redigera
                                  </ClpButton>

                                  <ClpButton
                                    color="primary"
                                    component={Link}
                                    to={{
                                      pathname: allRoutes.IssuereportsWorkordersAdd(),
                                      state: issuereportResult.data.vehicleId
                                    }}
                                    startIcon={<AddCircleIcon />}
                                    variant="contained">
                                    Skapa arbetsorder
                                  </ClpButton>
                                </>
                              )}
                            </Box>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Card
                          className={classes.flexResponsive}
                          sx={{ p: 4, display: "flex", gridGap: "90px" }}>
                          <Grid xs={12} md={7}>
                            <Box marginY={3} mb={2} mt={0}>
                              <Box
                                order={2}
                                flexGrow={2}
                                display="flex"
                                justifyContent="space-between"
                                flexWrap="wrap"
                                className={classes.inline}>
                                <Grid
                                  item
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  className={classes.inline}>
                                  {editMode ? (
                                    <>
                                      <Grid xs={4} md={8}>
                                        {categorysResult.status === 2 && (
                                          <Autocomplete
                                            style={{
                                              width: "240px"
                                            }}
                                            componentsProps={{ popper: { style: { width: "240px" } } }}
                                            id="issueReportCategoryId"
                                            options={categorysResult.data}
                                            getOptionLabel={(option) => (option.name ? option.name : "")}
                                            value={
                                              categorysResult.data.find(
                                                (i) => i.id === values.issueReportCategoryId
                                              ) || ""
                                            }
                                            getOptionSelected={(option, value) => {
                                              return option.id === value.id
                                            }}
                                            onChange={(e, value) => {
                                              setFieldValue("issueReportCategoryId", value?.id)
                                              setChosenCategory(value?.id)
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                variant="outlined"
                                                name="issueReportCategoryId"
                                                error={
                                                  touched.issueReportCategoryId &&
                                                  errors.issueReportCategoryId
                                                    ? true
                                                    : false
                                                }
                                                helperText={
                                                  touched.issueReportCategoryId &&
                                                  errors.issueReportCategoryId
                                                    ? errors.issueReportCategoryId
                                                    : null
                                                }
                                              />
                                            )}
                                          />
                                        )}{" "}
                                      </Grid>
                                    </>
                                  ) : (
                                    <Grid xs={12}>
                                      <Typography variant="h2" color={theme.palette.primary["main"]}>
                                        {issuereportResult.data?.issueReportCategory?.name
                                          ? issuereportResult.data.issueReportCategory.name
                                          : "-"}
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Box>
                            <Grid container spacing={2} pt={1} sx={{ justifyContent: "space-between" }}>
                              <Grid item xs={6} lg={2}>
                                <Typography>Reg.Nr:</Typography>

                                <Typography variant="h6">
                                  {parentData.vehicle?.regNumber ? parentData.vehicle.regNumber : "-"}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} lg={2}>
                                <Typography>FordonsID:</Typography>

                                <Typography variant="h6">
                                  {parentData.vehicle?.internnumber ? parentData.vehicle.internnumber : "-"}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} lg={2}>
                                <Typography>Skapad av:</Typography>
                                <Typography variant="h6">
                                  {parentData.createdByUser?.name ? parentData.createdByUser.name : "-"}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} lg={2}>
                                <Typography>Skapad den:</Typography>

                                <Typography variant="h6">
                                  {parentData.createdDate ? (
                                    <Moment format="YYYY-MM-DD">{parentData.createdDate}</Moment>
                                  ) : (
                                    "-"
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid>
                              <Typography pl={0} pt={2} variant="h6">
                                Beskrivning
                              </Typography>
                              {editMode ? (
                                <>
                                  <ListItem className={classes.listItem}>
                                    <TextField
                                      rows={4}
                                      multiline
                                      autoComplete="off"
                                      className={classes.textField}
                                      fullWidth
                                      id="description"
                                      value={values.description}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={touched.description && errors.description ? true : false}
                                      helperText={
                                        touched.description && errors.description ? errors.description : null
                                      }
                                      variant="outlined"
                                      InputProps={{
                                        classes: { input: classes.formAreaInput },
                                        disableUnderline: true
                                      }}
                                    />
                                  </ListItem>
                                </>
                              ) : (
                                <Typography>{parentData.description}</Typography>
                              )}
                            </Grid>

                            {editMode ? (
                              <></>
                            ) : (
                              <Grid>
                                <Typography pl={0} pt={2} variant="h6">
                                  Notering
                                </Typography>
                                <Typography>{parentData.notice}</Typography>
                              </Grid>
                            )}

                            <Grid>
                              <Typography pl={0} pt={2} variant="h6">
                                Mätarställning
                              </Typography>
                              {editMode ? (
                                <>
                                  <ListItem className={classes.listItem}>
                                    <TextField
                                      rows={1}
                                      multiline
                                      autoComplete="off"
                                      className={classes.textField}
                                      fullWidth
                                      id="meterSetting"
                                      value={values.meterSetting}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={touched.meterSetting && errors.meterSetting ? true : false}
                                      helperText={
                                        touched.meterSetting && errors.meterSetting
                                          ? errors.meterSetting
                                          : null
                                      }
                                      variant="outlined"
                                      InputProps={{
                                        classes: { input: classes.formAreaInput },
                                        disableUnderline: true
                                      }}
                                    />
                                  </ListItem>
                                </>
                              ) : (
                                <Typography>{parentData.meterSetting}</Typography>
                              )}
                            </Grid>

                            <Grid>
                              <Typography pl={0} pt={2} mb={2} variant="h6">
                                Media
                              </Typography>
                              <MultipleFilePickerPreviewList
                                parentData={parentData}
                                setFilesPreview={filesPreview}
                                setFieldValue={setFieldValue}
                                fieldName="attachments"
                                defaultPreviewFiles={parentData.attachments}
                                setFiles={setFiles}
                                files={files}
                                fileUploadResult={fileUploadResult}
                                onLogoChanged={onLogoChanged}
                                labelInBox={"Bifoga filer från din enhet"}
                                editMode={editMode}
                              />
                            </Grid>
                          </Grid>

                          <Grid item md={4} xs={12} direction="row">
                            <Typography variant="h6">Status</Typography>
                            {hasRole(appContext.appUser, allRoles.IssueReportsAdmin) ? (
                              <Box className={classes.whiteLabel} sx={{ minWidth: 200 }} mt={0.5} mb={2}>
                                <FormControl fullWidth>
                                  <InputLabel id="status"></InputLabel>
                                  <Select
                                    labelId="status"
                                    id="status"
                                    onChange={(event) => setFieldValue("status", event.target.value)}
                                    defaultValue={values.status ? values.status : ""}
                                    error={touched.status && errors.status ? true : false}>
                                    {IssueReportStatusList.map((data, index) => (
                                      <MenuItem value={data.value} key={index}>
                                        {data.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            ) : (
                              <Box color={`${issueReportStatusColor(values.status)}.main`}>
                                <FontAwesomeIcon icon={issueReportStatusIcon(values.status)} />
                                <Typography sx={{ marginLeft: "5px", display: "inline" }} variant="h6">
                                  {issueReportStatusLabel(values.status)}
                                </Typography>
                              </Box>
                            )}
                            {hasRole(appContext.appUser, allRoles.IssueReportsAdmin) && (
                              <>
                                <Typography variant="h6">Prioritet</Typography>
                                <Box className={classes.whiteLabel} sx={{ minWidth: 200 }} mt={0.5} mb={2}>
                                  <FormControl fullWidth>
                                    <InputLabel id="priority"></InputLabel>
                                    <Select
                                      labelId="priority"
                                      id="priority"
                                      onChange={(event) => setFieldValue("priority", event.target.value)}
                                      defaultValue={values.priority ? values.priority : ""}
                                      error={touched.priority && errors.priority ? true : false}>
                                      {IssueReportPriorityList.map((data, index) => (
                                        <MenuItem value={data.value} key={index}>
                                          {data.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>

                                <Typography variant="h6">Tilldela</Typography>

                                <Box className={classes.whiteLabel} sx={{ minWidth: 200 }} mt={0.5} mb={2}>
                                  {userstoassign.status === 2 && (
                                    <FormControl fullWidth>
                                      <InputLabel id="responsibleUserId"></InputLabel>
                                      <Select
                                        labelId="responsibleUserId"
                                        id="responsibleUserId"
                                        onChange={(event) => {
                                          setFieldValue("responsibleUserId", event.target.value)
                                        }}
                                        defaultValue={
                                          values.responsibleUserId ? values.responsibleUserId : ""
                                        }>
                                        {userstoassign.data.map((data, index) => (
                                          <MenuItem value={data.id} key={index}>
                                            {data.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )}
                                </Box>
                                <Typography variant="h6">Notering</Typography>

                                <Box className={classes.whiteLabel} sx={{ minWidth: 200 }} mt={0.5} mb={2}>
                                  <FormControl fullWidth>
                                    <TextField
                                      rows={3}
                                      multiline
                                      autoComplete="off"
                                      className={classes.textField}
                                      fullWidth
                                      id="notice"
                                      value={values.notice}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={touched.notice && errors.notice ? true : false}
                                      helperText={touched.notice && errors.notice ? errors.notice : null}
                                      variant="outlined"
                                      InputProps={{
                                        classes: { input: classes.formAreaInput },
                                        disableUnderline: true
                                      }}
                                    />
                                  </FormControl>
                                </Box>
                                <Grid container direction={"row-reverse"}>
                                  <ClpButton
                                    onClick={() => setShowContinueModal(true)}
                                    color="primary"
                                    variant="contained">
                                    Spara
                                  </ClpButton>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                    <Box mt={3}>
                      <ClpButton
                        result={buttonFeedbackResultDelete}
                        onClick={() => {
                          setShowDeleteModal(true)
                        }}
                        startIcon={<DeleteIcon />}
                        color="error"
                        variant="outlined">
                        Ta bort felrapport
                      </ClpButton>
                    </Box>

                    {showContinueModal && (
                      <ContinueDialog
                        setShowContinueModal={setShowContinueModal}
                        onAddSubmit={onAddSubmit}
                        values={values}
                        message={`Vill du spara ändringar?`}
                        continueBtnText="Spara"
                        cancelBtnText="Avbryt"
                      />
                    )}
                    {showDeleteModel && (
                      <ContinueDialog
                        result={deleteResult}
                        setShowContinueModal={setShowDeleteModal}
                        onAddSubmit={onDelete}
                        message={`Vill du ta bort felrapport?`}
                        continueBtnText="Ta bort felrapport"
                        cancelBtnText="Avbryt"
                      />
                    )}
                    {showSuccessModal && <SuccessDialog title="Din felrapport har sparats." />}
                  </form>
                </Box>
              )}
            </Formik>
          </>
        )}
      </Container>
    </div>
  )
}

export default IssueReports
