import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React, { useContext, useEffect, useRef, useState } from "react"
import { ImportContractContext } from "./ImportContractFormStateProvider"
import { useStyles } from "./useStyles"

const SelectColumn = (props) => {
  const classes = useStyles()
  const { row, setFieldValue, importDataContext, value } = props

  const [selectedOption, setSelectedOption] = useState(value)

  const [dataFields, setDataFields] = useState([
    { name: "Ej vald", id: "" },
    { name: "Kund", id: "name", dataType: "string" },
    { name: "Avtalsnummer", id: "assignmentNr", dataType: "string" },
    { name: "Timmar", id: "nrOfHours", dataType: "number" },
    { name: "Startdatum ", id: "dateFrom", dataType: "date" },
    { name: "Slutdatum ", id: "dateTo", dataType: "date" },
    { name: "Underavtal ", id: "subContract", dataType: "string" }
  ])

  const handleImportSelect = (event, fieldName) => {
    setFieldValue(event.target.value ? event.target.value : selectedOption, event.target.value)
    setSelectedOption(event.target.value)
    const dataType = event.target[event.target.selectedIndex].getAttribute("data-type")

    importDataContext.updateColumns(event.target.value, dataType, fieldName)
  }

  return (
    <FormControl className={classes.formControl} size="small">
      <Select native value={selectedOption} onChange={(e) => handleImportSelect(e, row.Header)}>
        {dataFields &&
          dataFields.map((item) => (
            <option key={item.id} value={item.id} data-type={item.dataType}>
              {item.name}
            </option>
          ))}
      </Select>
    </FormControl>
  )
}

export const SelectFields = (props) => {
  const importDataContext = useContext(ImportContractContext)

  const { touched, errors, setFieldValue, values } = props

  const classes = useStyles()
  const [dataValues, setTable] = useState(importDataContext.getImportedData())

  const [excelHeaders, getSelectedExcelHeaders] = useState(importDataContext.getExcelHeaders())

  const handleChange = (event) => {
    importDataContext.updateIdendifikation(event.target.value)
    setFieldValue("internNumber", event.target.value)
  }

  const tableRef = useRef()
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)

  const getSize = () => {
    if (!tableRef.current) return
    if (tableRef && tableRef.current.getBoundingClientRect() != null) {
      const elemFromTop = tableRef.current.getBoundingClientRect().top + 62
      setHeight(window.innerHeight - elemFromTop)
      const elemFromLeft = tableRef.current.getBoundingClientRect().left
      setWidth(window.innerWidth - elemFromLeft)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", getSize)
  }, [])

  useEffect(() => {
    if (tableRef) {
      const elemFromTop = tableRef.current.getBoundingClientRect().top + 58
      setHeight(window.innerHeight - elemFromTop)

      const elemFromLeft = tableRef.current.getBoundingClientRect().left
      setWidth(window.innerWidth - elemFromLeft)
    }
  }, [])

  return (
    <Box bgcolor="background.paper" className={classes.rootShadow} paddingY={1}>
      <Container>
        <Box paddingBottom={3}>
          <Grid container direction="row" spacing={3}>
            <Grid item>
              <Typography variant="h2" gutterBottom>
                Identifiera ditt fordon
              </Typography>

              <Typography variant="bodySmall">
                Välj sätt att identifiera dig med hjälp av listan till höger
              </Typography>
            </Grid>
            <Grid item>
              <FormControl
                className={classes.formControlLarge}
                error={touched.internNumber && errors.internNumber}
                variant="outlined">
                <InputLabel id="internNumber">Internnr</InputLabel>
                <Select
                  label="Internnr"
                  labelId="internNumber"
                  id="internNumber"
                  value={values.internNumber}
                  onChange={(e) => handleChange(e, setFieldValue)}>
                  {excelHeaders.map((item, index) => (
                    <MenuItem key={index} data-index={index} value={item.Header}>
                      {item.Header}
                    </MenuItem>
                  ))}
                </Select>
                {touched.internNumber && errors.internNumber && (
                  <FormHelperText>{errors.internNumber}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box>
        <TableContainer
          className={classes.tableContainer}
          style={{ height: height, width: width }}
          ref={tableRef}>
          <Table size="small" className={classes.table} stickyHeader>
            <TableHead>
              <TableRow>
                {excelHeaders.map((row, index) => {
                  if (values.internNumber !== row.Header) {
                    return (
                      <TableCell key={index} className={row.fieldName !== null && "locked"}>
                        <SelectColumn
                          row={row}
                          setFieldValue={setFieldValue}
                          importDataContext={importDataContext}
                          value={values[row.fieldName]}
                        />
                        <Typography variant="caption">{row.Header}</Typography>
                      </TableCell>
                    )
                  } else {
                    return (
                      <TableCell className={row.fieldName !== null && "locked"}>
                        <Typography variant="bodySmall">Internnr</Typography>
                        <Typography variant="caption">{row.Header}</Typography>
                      </TableCell>
                    )
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataValues &&
                dataValues.current.excelData
                  .slice(0, 100) // TO DO: Lazy loading
                  .map((row) => (
                    <TableRow key={row.name}>
                      {excelHeaders.map((header, index) => {
                        return (
                          <TableCell
                            component="td"
                            scope="row"
                            className={row[header.Header] != null && header.fieldName !== null && "locked"}>
                            {row[header.Header]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={
          (touched.name && errors.name) ||
          (touched.internNumber && errors.internNumber) ||
          (touched.assignmentNr && errors.assignmentNr) ||
          (touched.nrOfHours && errors.nrOfHours) ||
          (touched.dateFrom && errors.dateFrom) ||
          (touched.dateTo && errors.dateTo)
        }>
        <Box bgcolor="background.dark" color="foreground.inverted" borderRadius="8px" padding={1}>
          <Box bgcolor="background.dark" color="foreground.inverted" borderRadius={8} padding={1}>
            <FormControl
              className={classes.formControl}
              error={
                (touched.name && errors.name) ||
                (touched.internNumber && errors.internNumber) ||
                (touched.assignmentNr && errors.assignmentNr) ||
                (touched.nrOfHours && errors.nrOfHours) ||
                (touched.dateFrom && errors.dateFrom) ||
                (touched.dateTo && errors.dateTo)
              }
              variant="outlined">
              {touched.name && errors.name && <FormHelperText>{errors.name}</FormHelperText>}
              {touched.internNumber && errors.internNumber && (
                <FormHelperText>{errors.internNumber}</FormHelperText>
              )}
              {touched.assignmentNr && errors.assignmentNr && (
                <FormHelperText>{errors.assignmentNr}</FormHelperText>
              )}
              {touched.nrOfHours && errors.nrOfHours && <FormHelperText>{errors.nrOfHours}</FormHelperText>}
              {touched.dateFrom && errors.dateFrom && <FormHelperText>{errors.dateFrom}</FormHelperText>}
              {touched.dateTo && errors.dateTo && <FormHelperText>{errors.dateTo}</FormHelperText>}
            </FormControl>
          </Box>
        </Box>
      </Snackbar>
    </Box>
  )
}
