import { useCallback, useState } from "react";
import XLSX from "xlsx";

const useTableExport = (footer) => {
    const [state, setState] = useState(0); // samma som api, 0 inte startad, 1 in progress, 2 finished, 3 failed

    const exportTable = useCallback((columns, rows, prepareRow, subColumns) => {
        setState(1);
        console.log(footer);
        setTimeout(() => {
            try {
                rows.forEach((r) => {
                    prepareRow(r);
                });

                let newColumns = [];
                let newRows = [];

                columns.forEach((col) => {
                    newColumns.push(col.Header);
                });

                rows.forEach((row) => {
                    let newRow = [];
                    row.cells
                        .filter((x) => !x.column.hidden)
                        .forEach((cell) => {
                            newRow.push(cell.value);
                        });
                    newRows.push(newRow);
                });
                if (footer !== null) {
                    let newFooterRow = [];
                    for (const key in footer) {
                        newFooterRow.push(footer[key].value);
                    }
                    newRows.push(newFooterRow);
                }

                let data = newRows.slice();
                data.unshift(newColumns);

                const ws = XLSX.utils.aoa_to_sheet(data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
                /* generate XLSX file and send to client */
                XLSX.writeFile(wb, "transportportal_export.xlsx");

                setState(2);
            } catch {
                setState(3);
            }
        }, 200);
    }, []);

    return [state, exportTable];
};

const DebugTable = ({ exportTableData }) => {
    return (
        <table className="debug-table">
            <thead>
                <tr>
                    {exportTableData.columns.map((col) => {
                        return <th key={col}>{col}</th>;
                    })}
                </tr>
            </thead>
            <tbody>
                {exportTableData.rows.map((row) => {
                    return (
                        <tr>
                            {row.map((cell) => {
                                return <td>{cell}</td>;
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default useTableExport;
