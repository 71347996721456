import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useApi } from "auth/useApi3"
import Button from "components/Button"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useContext, useEffect } from "react"
import { UserForm } from "shared/customer/UserForm"

import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material"

import { PageTabs } from "components/navigation/PageTabs"
import { allRoutes } from "generated/routes"
import { useHistory } from "react-router-dom"

const EditUser = ({ match }) => {
  const [customerResult, getCustomerApi] = useApi()
  const [userResult, getUserApi] = useApi()
  const [updateUserResult, updateUserApi] = useApi()
  const [customerExams, getCustomerExamsApi] = useApi()
  const [customerReportTemplates, customerReportTemplatesApi] = useApi()
  const [deleteResult, deleteUserApi] = useApi()
  const loadingContext = useContext(GlobalLoaderContext)

  const routerHistory = useHistory()

  useEffect(() => {
    // om man lyckats tagit bort användaren redirectar vi tillbaks till listan
    if (deleteResult.status === 2)
      routerHistory.push(allRoutes.SystemadminCustomersEditUsers(match.params.id))
  }, [deleteResult.status, routerHistory, match])

  useEffect(() => {
    const loadStuff = async () => {
      getCustomerApi.get(apiEndpoints.systemadmincustomers.getcustomerbyid.replace("{id}", match.params.id))

      getCustomerExamsApi.get(
        apiEndpoints.systemadmincustomers.getcustomerexaminations.replace("{customerId}", match.params.id)
      )

      customerReportTemplatesApi.get(
        apiEndpoints.systemadmincustomers.getcustomerreporttemplates.replace("{customerId}", match.params.id)
      )

      getUserApi.get(apiEndpoints.systemadmincustomers.getuserbyid.replace("{id}", match.params.userId))
    }
    loadStuff()
  }, [
    customerReportTemplatesApi,
    getCustomerApi,
    getUserApi,
    getCustomerExamsApi,
    match.params.id,
    match.params.userId
  ])

  useEffect(() => {
    loadingContext.setLoading(() => customerResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => customerExams.status === 1)
  })

  useEffect(() => {
    loadingContext.setLoading(() => userResult.status === 1)
  })

  const handleSubmit = (saveUser) => {
    updateUserApi.put(apiEndpoints.systemadmincustomers.updateuser, saveUser)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const openSettingsDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeSettingsDropdown = () => {
    setAnchorEl(null)
  }

  const [dialogOpen, setDialogOpen] = React.useState(false)
  const openRemoveDialog = () => {
    deleteUserApi.reset()
    setDialogOpen(true)
  }

  const closeRemoveDialog = () => {
    deleteUserApi.reset()
    setDialogOpen(false)
  }

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.SystemadminCustomersEditUsers(match.params.id),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        <Box marginY={3}>
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Redigera användare</Typography>

            <IconButton
              aria-label="Inställningar"
              aria-controls="settings-menu"
              aria-haspopup="true"
              onClick={openSettingsDropdown}
              size="large">
              <MoreVertIcon fontSize="large" />
            </IconButton>
            <Menu id="settings-menu" anchorEl={anchorEl} open={open} onClose={closeSettingsDropdown}>
              <MenuItem
                onClick={() => {
                  routerHistory.push(
                    allRoutes.SystemadminCustomersEditUsersEditPasswordreset(
                      match.params.id,
                      match.params.userId
                    )
                  )
                }}>
                Sätt nytt lösenord
              </MenuItem>
              <MenuItem onClick={openRemoveDialog}>Ta bort användare</MenuItem>
            </Menu>
          </Grid>
        </Box>

        {customerReportTemplates.status === 2 &&
        customerResult.status === 2 &&
        userResult.status === 2 &&
        customerExams.status === 2 ? (
          <UserForm
            result={updateUserResult}
            initialValues={userResult.data}
            customer={customerResult.data}
            onSubmit={handleSubmit}
            parentIsSysAdmin={true}
          />
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dialog
              open={dialogOpen}
              onClose={closeSettingsDropdown}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{"Ta bort användare?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Är du säker att du vill ta bort användare? Ditt val går inte att ångra.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeRemoveDialog} variant="outlined">
                  Avbryt
                </Button>
                <Button
                  result={deleteResult}
                  onClick={() => {
                    deleteUserApi.del(
                      apiEndpoints.systemadmincustomers.deleteuser.replace("{id}", match.params.userId)
                    )
                  }}
                  color="primary"
                  autoFocus>
                  Ja, jag är säker
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default EditUser
