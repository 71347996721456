import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox } from "@mui/material"
import React from "react"

import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  checkboxDisabled: {
    padding: "0",
    marginLeft: "11px",
    "&.Mui-disabled.MuiCheckbox-root": {
      color: theme.palette.error["main"]
    },
    "&.Mui-disabled.Mui-checked": {
      color: theme.palette.success["main"]
    }
  }
}))

const TodoListCheckbox = ({ checked, updateFieldArray, icon, color, disabled }) => {
  const classes = useStyles()
  return (
    <Checkbox
      color={color && color}
      className={classes.checkboxDisabled}
      disabled={disabled}
      icon={<FontAwesomeIcon size="2x" icon={icon} />}
      checkedIcon={<FontAwesomeIcon size="2x" icon={faCircleCheck} />}
      checked={checked}
      onChange={(item) => {
        const checked = item.target.checked
        updateFieldArray(checked)
      }}
    />
  )
}

export default TodoListCheckbox
