import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faFile, faFilePdf } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Skeleton, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { useApiGetBlob } from "auth/useApi2"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints.js"
import React, { useEffect } from "react"
import styles from "./style.js"

interface attachment {
  id: number
  fileId: string
  contentType?: string
  name: string
}

interface Props {
  data: attachment
}

const DocumentBox: React.FC<Props> = (props) => {
  const [getAttachmentResult, getAttachmentApi] = useApiGetBlob() as any
  const [getAttachmentThumbnailResult, getAttachmentThumbnailApi] = useApi() as [IApiResult, IApiFunc]

  useEffect(() => {
    getAttachmentThumbnailApi.get(
      apiEndpoints.crisismanagement.getattachmentthumbnailbyid.replace("{id}", props.data.id as any)
    )
  }, [getAttachmentThumbnailApi])

  const downloadAttachment = React.useCallback(
    (id, fileName) => {
      getAttachmentApi(apiEndpoints.crisismanagement.getattachment.replace("{id}", id), fileName)
    },
    [getAttachmentApi]
  )

  if (props.data.name.length > 20) {
    props.data.name = props.data.name.substring(0, 17) + "..."
  }

  console.log(getAttachmentThumbnailResult)

  const style = styles()
  return (
    <>
      <Button
        className={style.documentButton}
        sx={{ margin: 2 }}
        onClick={() => {
          downloadAttachment(props.data.id, props.data.name)
        }}>
        <Box sx={{ backgroundColor: theme.palette.primary.light, width: "148px" }}>
          <Box
            display={"flex"}
            height={"89px"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}>
            {getAttachmentThumbnailResult.status === 2 ? (
              (() => {
                switch (getAttachmentThumbnailResult.data.contentType) {
                  case "application/pdf":
                    return (
                      <Box className={style.documentIconBackground}>
                        <FontAwesomeIcon icon={faFilePdf as IconProp} color="white" size="2xl" />
                      </Box>
                    )
                  case "image/jpeg" || "image/png" || "image/svg" || "image/gif":
                    return (
                      <img
                        src={`data:${getAttachmentThumbnailResult.data.contentType};base64, ${getAttachmentThumbnailResult.data.base64Contents}`}
                        style={{ objectFit: "cover", height: "100%", width: "100%" }}
                      />
                    )
                  default:
                    return (
                      <Box className={style.documentIconBackground}>
                        <FontAwesomeIcon icon={faFile as IconProp} color="white" size="2xl" />
                      </Box>
                    )
                }
              })()
            ) : (
              <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
            )}
          </Box>
          <Box sx={{ backgroundColor: "white", padding: 1, paddingLeft: 2 }}>
            <Typography variant={"h6"} fontSize={12} color={"black"}>
              {props.data.name}
            </Typography>
          </Box>
        </Box>
      </Button>
    </>
  )
}

export default DocumentBox
