import { InspectionHistoryStatus, VehicleCheckHistoryDto } from "./Checks.interfaces"
import Dialog from "@mui/material/Dialog"
import Grid from "@mui/material/Grid"
import {
  StyledBox,
  StyledBoxClose,
  StyledDialogActions,
  StyledDialogContent,
  StyledFontAwesomeIcon,
  StyledFontAwesomeIconStatus,
  StyledTypographyModal
} from "./InspectionsHistoryTable.styled"
import Box from "@mui/material/Box"
import { faCar, faCircleCheck, faCircleXmark, faClockRotateLeft, faXmark } from "@fortawesome/pro-solid-svg-icons"
import { Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { Link } from "react-router-dom"
import { allRoutes } from "../../../../generated/routes"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import React from "react"

interface DialogProps {
  showNavigation: boolean
  handleClose: () => void
  clickedHistory: VehicleCheckHistoryDto | undefined
}

export const InspectionHistoryModal = ({ showNavigation, handleClose, clickedHistory }: DialogProps) => {
  return (
    <Dialog
      open={clickedHistory !== undefined}
      onClose={handleClose}
      fullWidth
    >
      <Grid container direction="column" alignItems="center">
        <StyledDialogContent>
          <Grid container flexDirection={"row"}>
            <Box sx={{marginTop: "8px"}}>
              {clickedHistory?.status === InspectionHistoryStatus.Passed ? (
                <StyledFontAwesomeIconStatus status={InspectionHistoryStatus.Passed} icon={faCircleCheck} size="xl" />
              ) : clickedHistory?.status === InspectionHistoryStatus.Waiting ? (
                <StyledFontAwesomeIconStatus status={InspectionHistoryStatus.Waiting} icon={faClockRotateLeft} size="xl" sx={{
                  transform: "scaleX(-1)",
                  position: "relative",
                  left: "-50%"
                }}  />
              ) : clickedHistory?.status === InspectionHistoryStatus.Failed ? (
                <StyledFontAwesomeIconStatus status={InspectionHistoryStatus.Failed} icon={faCircleXmark} size="xl" />
              ) : <></>}
            </Box>
            <Box>
              <Typography align="left" variant="h4">
                {clickedHistory?.customFieldName}
              </Typography>
              <Typography align="left" variant="labelLarge" color="textSecondary">
                {clickedHistory && clickedHistory.regNumber}
              </Typography>
            </Box>
            <StyledBoxClose onClick={handleClose}>
              <FontAwesomeIcon size="xl" color="grey" icon={faXmark} />
            </StyledBoxClose>
          </Grid>
          <Grid container flexDirection={"column"}>
            <StyledTypographyModal align="left" variant="bodyMedium" color="textSecondary">
              Inställelsedatum
            </StyledTypographyModal>
            <Typography align="left" variant="h5">
              {clickedHistory && moment(clickedHistory?.attendanceDate).format("YYYY-MM-DD")}
            </Typography>
            <StyledTypographyModal align="left" variant="bodyMedium" color="textSecondary">
              Mätarinställning
            </StyledTypographyModal>
            <Typography align="left" variant="h5">
              {clickedHistory && clickedHistory?.odometerReading}
            </Typography>
            <StyledTypographyModal align="left" variant="bodyMedium" color="textSecondary">
              Händelselogg
            </StyledTypographyModal>
            {clickedHistory?.status === InspectionHistoryStatus.Passed ? (
              <Typography align="left" variant="h5">
                Godkänd {moment(clickedHistory.date).format("YYYY-MM-DD HH:mm")}
              </Typography>
            ) : clickedHistory?.status === InspectionHistoryStatus.Failed ? (
              <Typography align="left" variant="h5">
                Underkänd {moment(clickedHistory.date).format("YYYY-MM-DD HH:mm")}
              </Typography>
            ) : clickedHistory?.status === InspectionHistoryStatus.Waiting ? (
              <Typography align="left" variant="h5">
                Väntar på svar från Transportstyrelsen: {moment(clickedHistory.date).format("YYYY-MM-DD HH:mm")}
              </Typography>
            ) : <></>}
          </Grid>
        </StyledDialogContent>
        {showNavigation && clickedHistory && (
          <StyledDialogActions>
            <Link
              to={allRoutes.VehicleregistryVehiclesDetails(clickedHistory.vehicleId)}
              style={{ textDecoration: "none" }}
            >
              <StyledBox display="flex" justifyContent="left" mb={3} gap={2} alignItems={"center"}>
                <FontAwesomeIcon size="xl" color="grey" icon={faCar} />
                <Typography variant="h5" color="primary">Gå till fordon</Typography>
                <StyledFontAwesomeIcon size="xl" icon={faArrowRight} />
              </StyledBox>
            </Link>
          </StyledDialogActions>
        )}
      </Grid>
    </Dialog>
  )
}