import { Container, Grid } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import { Formik, FormikProps } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { EditAdminViolation } from "pages/violation/components/EditAdminViolation"
import { EditViolation } from "pages/violation/components/EditViolation"
import { EditAdminViolationSchema, EditViolationSchema } from "pages/violation/components/ValidationSchema"
import { ViewAdminViolation } from "pages/violation/components/ViewAdminViolation"
import { ViewViolation } from "pages/violation/components/ViewViolation"
import { ViolationDetail } from "pages/violation/components/ViolationDetail"
import { ISelectListItem, IViolation } from "pages/violation/violation.interface"
import { useContext, useEffect, useState } from "react"

const ViolationDetails: React.FC = ({ match }: any) => {
  const [violationGetResult, violationGetApi] = useApi() as [IApiResult, IApiFunc]
  const [violationPutResult, violationPutApi] = useApi() as [IApiResult, IApiFunc]
  const [drivingTypesResult, drivingTypesApi] = useApi() as [IApiResult, IApiFunc]
  const [initialValues, setInitialValues] = useState<IViolation | undefined>()
  const [drivingTypes, setDrivingTypes] = useState<ISelectListItem[] | undefined>()

  const appContext = useContext(AppContext)

  const isAdmin = hasRole(appContext.appUser, allRoles.ViolationAdmin)

  const [updatedByDriver, setUpdatedByDriver] = useState<boolean>(initialValues?.updatedByDriver != null)

  useEffect(() => {
    if (violationPutResult.status === 2) setUpdatedByDriver(violationPutResult.data?.updatedByDriver)
  }, [violationPutResult])

  useEffect(() => {
    violationGetApi.get(apiEndpoints.violation.getbyid.replace("{id}", match.params.id))
  }, [violationGetApi])

  useEffect(() => {
    if (violationGetResult.status === 2) {
      setInitialValues(violationGetResult.data)
    }
  }, [violationGetResult])

  useEffect(() => {
    if (violationGetResult.status === 2) {
      drivingTypesApi.get(
        apiEndpoints.violation.getdrivingtypes.replace("{companyid}", violationGetResult.data.companyId)
      )
    }
  }, [violationGetResult])

  useEffect(() => {
    if (drivingTypesResult.status === 2) {
      setDrivingTypes(drivingTypesResult.data)
    }
  }, [drivingTypesResult])

  const handleSubmit = (values: IViolation) => {
    violationPutApi.put(apiEndpoints.violation.updateviolation, values)
  }

  return (
    <Container>
      <Grid container spacing={3} display={"flex"} justifyContent={"space-between"}>
        <Grid item md={9} xs={12}>
          <ViolationDetail values={initialValues} />
          {violationGetResult.status === 2 && drivingTypesResult.status === 2 && initialValues && (
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              enableReinitialize={true}
              validateOnBlur={true}
              validationSchema={
                isAdmin ? EditAdminViolationSchema : updatedByDriver ? "" : EditViolationSchema
              }>
              {({
                handleSubmit,
                values,
                setFieldValue,
                handleBlur,
                handleChange,
                touched,
                errors
              }: FormikProps<IViolation>) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={5}>
                    <Grid item md={8}>
                      <Grid container spacing={3} display={"flex"} justifyContent={"space-between"}>
                        <Grid item md={9} xs={12}>
                          {isAdmin ? (
                            <>
                              <ViewViolation
                                values={values}
                                drivingTypes={drivingTypes}
                                isUpdated={updatedByDriver}
                              />
                              <EditAdminViolation
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                values={values}
                                touched={touched}
                                errors={errors}
                                result={violationPutResult}
                              />
                            </>
                          ) : (
                            <>
                              {updatedByDriver ? (
                                <ViewViolation
                                  values={values}
                                  drivingTypes={drivingTypes}
                                  isUpdated={updatedByDriver}
                                />
                              ) : (
                                <EditViolation
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  drivingTypes={drivingTypes}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  result={violationPutResult}
                                />
                              )}
                              <ViewAdminViolation values={values} />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ViolationDetails
