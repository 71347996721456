import { faCheck } from "@fortawesome/free-solid-svg-icons"
import {
  faClock,
  faCommentLines,
  faDownload,
  faFileMagnifyingGlass,
  faLayerGroup
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { DangerousHtml } from "components/DangerousHtml"
import theme from "css/theme/light"
import { allRoutes } from "generated/routes"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { CertificateDownloadLink } from "shared/learning/components/CertificateRender"

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  flex: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap"
    }
  },
  alignCenter: {
    alignItems: "center"
  },
  gridGap: {
    gridGap: "20px"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto",

    [theme.breakpoints.up("md")]: {
      padding: "25px 20px"
    }
  },
  cover: {
    minWidth: "100%",
    height: "170px",
    [theme.breakpoints.up("md")]: {
      minWidth: "35%",
      height: "auto"
    }
  },
  icon: {
    color: theme.palette.primary["main"],
    marginRight: 8
  },
  IconCompleted: {
    color: theme.palette.secondary["main"],
    backgroundColor: theme.palette.secondary["light"],
    borderRadius: 25,
    height: 18,
    width: 18,
    padding: 6
  },
  IconWarning: {
    color: theme.palette.warning["main"],
    backgroundColor: theme.palette.warning["light"],
    borderRadius: 25,
    height: 18,
    width: 18,
    padding: 6
  },
  IconActive: {
    color: theme.palette.primary["main"],
    backgroundColor: theme.palette.primary["light"],
    borderRadius: 25,
    height: 18,
    width: 18,
    padding: 6
  },
  IconError: {
    color: theme.palette.error["main"],
    backgroundColor: theme.palette.error["light"],
    borderRadius: 25,
    height: 18,
    width: 18,
    padding: 6
  }
}))

export const ExaminationInfoCard = (props) => {
  const { t } = useTranslation()

  const { item, assignedCourse } = props
  const { examinationSummary, examinationCertificate } = item

  const styleProps = {
    image: "/assets/learning/card_".concat(item.examinationType).concat(".jpg")
  }

  const classes = useStyles(styleProps)
  return (
    <>
      <Card className={classes.root}>
        <CardMedia
          className={classes.cover}
          image={"/assets/learning/card_".concat(item.examinationType).concat(".jpg")}
          title={item.examinationName}
        />

        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Grid item className={[classes.flex, classes.gridGap]} flexDirection="column">
              <Grid item className={classes.flex} justifyContent="space-between">
                <Typography variant="h6" gutterBottom color={theme.palette.primary["main"]}>
                  {t("learning.titles.course")}
                </Typography>

                {examinationSummary.hasPassed && (
                  <Box className={classes.flex} alignItems="center">
                    <FontAwesomeIcon className={classes.IconCompleted} icon={faCheck} size="2xs" />
                    <Typography variant="h4" color={theme.palette.secondary["main"]} ml={"10px"}>
                      {t("learning.titles.passed")}
                    </Typography>
                  </Box>
                )}
                {examinationSummary.isExpiresIn30Days && (
                  <Box className={classes.flex} alignItems="center">
                    <FontAwesomeIcon className={classes.IconWarning} icon={faClock} size="2xs" />
                    <Typography variant="h4" color={theme.palette.warning["main"]} ml={"10px"}>
                      {t("learning.titles.expires_soon")}
                    </Typography>
                  </Box>
                )}
                {examinationSummary.hasExpired && (
                  <Box className={classes.flex} alignItems="center">
                    <FontAwesomeIcon className={classes.IconError} icon={faClock} size="2xs" />
                    <Typography variant="h4" color={theme.palette.error["main"]} ml={"10px"}>
                      {t("learning.titles.expired")}
                    </Typography>
                  </Box>
                )}
              </Grid>

              <Typography variant="h4" gutterBottom>
                {assignedCourse ? assignedCourse?.examinationName : item.examinationName}
              </Typography>
              <Box>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <Grid item className={classes.flex}>
                    <FontAwesomeIcon icon={faLayerGroup} className={classes.icon} />
                    <Typography variant="bodySmall">
                      {`${item.examinationAreaCount} ${t("learning.titles.segments")}`}
                    </Typography>
                  </Grid>

                  <Grid item className={classes.flex}>
                    <FontAwesomeIcon icon={faCommentLines} className={classes.icon} />
                    <Typography variant="bodySmall">
                      {item.examinationQuestionCount} {t("learning.titles.questions")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {examinationSummary.isExpiresIn30Days && (
                <Box className={classes.flex} alignItems="center">
                  <Typography color={theme.palette.error["main"]}>
                    {`${t("learning.titles.certificate_expires")} ${moment(examinationSummary.date).format(
                      "DD-MM-YYYY"
                    )}`}
                  </Typography>
                </Box>
              )}
              {examinationSummary.hasExpired && examinationSummary.date != null && (
                <Box className={classes.flex} alignItems="center">
                  <Typography color={theme.palette.error["main"]}>
                    {`${t("learning.titles.certificate_expired")} ${moment(examinationSummary.date).format(
                      "DD-MM-YYYY"
                    )}`}
                  </Typography>
                </Box>
              )}
              <Typography variant="bodySmall" color="textSecondary">
                <DangerousHtml>
                  {assignedCourse
                    ? assignedCourse?.examinationDescription
                        .replace(/<[^>]+>/g, "")
                        .substring(0, 150)
                        .concat("...")
                    : item.examinationDescription
                        .replace(/<[^>]+>/g, "")
                        .substring(0, 150)
                        .concat("...")}
                </DangerousHtml>
              </Typography>
              <Grid item className={classes.flex} flexDirection="column">
                <Typography my="10px">{t("learning.titles.certificate")}</Typography>
                {examinationSummary.hasPassed && examinationCertificate.completedDate ? (
                  <Grid
                    container
                    className={classes.flex}
                    backgroundColor="primary.light"
                    p={"10px 14px"}
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius={2}>
                    <Box item className={classes.flex} columnGap="8px" alignItems="center">
                      <FontAwesomeIcon color={theme.palette.primary["main"]} icon={faFileMagnifyingGlass} />
                      <Typography fontWeight={500} color="primary.main">
                        {t("learning.result.titles.certificate")}
                      </Typography>
                      <Typography fontSize="14px" color="primary.main">{`${t(
                        "learning.titles.passed"
                      )} ${moment(examinationCertificate.completedDate).format("DD-MM-YYYY")}`}</Typography>
                    </Box>

                    <Box className={classes.downloadPaper} m={0}>
                      <CertificateDownloadLink item={item}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <FontAwesomeIcon color={theme.palette.primary["main"]} icon={faDownload} />

                          <Typography ml={1} color="primary.main">
                            {t("global.buttons.download")}
                          </Typography>
                        </Grid>
                      </CertificateDownloadLink>
                    </Box>
                  </Grid>
                ) : (
                  <Typography variant="bodySmall" color="textSecondary">
                    {t("learning.titles.no_certificate")}{" "}
                  </Typography>
                )}
              </Grid>

              <Box marginTop={2}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      component={NavLink}
                      to={{
                        pathname: allRoutes.LearningUserExamsDetails(item.userExaminationId)
                      }}>
                      {t("global.buttons.readmore")}
                    </Button>
                  </Grid>
                  {!examinationSummary.hasPassed && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        component={NavLink}
                        to={allRoutes.LearningUserExamsExamination(item.userExaminationId)}>
                        {t("learning.buttons.startCourse")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          </CardContent>
        </div>
      </Card>
    </>
  )
}
