import { Box } from "@mui/material"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import globalUseStyles from "css/theme/globalUseStyles"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import React from "react"
import { convertBeStructureToDataGridStructure } from "../datagridHelper"
import { LoadingTable } from "./LoadingTable"
import { NoReportSelectedTable } from "./NoReportSelectedTable"

export const ReportResult = ({ parameters }) => {
  const [reportDataResult, reportDataApi] = useApi()
  const globalClasses = globalUseStyles()

  const [currentReportData, setCurrentReportData] = React.useState(null)

  React.useEffect(() => {
    if (reportDataResult.status === 2) {
      const reportData = convertBeStructureToDataGridStructure(reportDataResult.data)

      setCurrentReportData(reportData)
    }
  }, [reportDataResult])

  React.useEffect(() => {
    if (parameters.selectedTemplate && parameters.selectedTemplate.requireDate) {
      let searchParams = new URLSearchParams(null)

      if (parameters.filter?.name !== null && parameters.filter?.name !== undefined) {
        searchParams.append("name", parameters.filter?.name)
      }

      if (parameters.filter?.subcontract !== null && parameters.filter?.subcontract !== undefined) {
        searchParams.append("subcontract", parameters.filter?.subcontract)
      }

      const values = {
        templateId: parameters.selectedTemplate.id,
        dateFrom: moment(parameters.dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(parameters.dateTo).format("YYYY-MM-DD"),
        queryParam: searchParams.toString()
      }

      parameters.dateFrom &&
        parameters.dateTo &&
        reportDataApi.post(
          apiEndpoints.vehicleregistryreporting.getreportresultbytemplateidandinterval,
          values
        )
    } else if (parameters.selectedTemplate) {
      reportDataApi.get(
        apiEndpoints.vehicleregistryreporting.getreportresultbytemplateid.replace(
          "{templateId}",
          parameters.selectedTemplate.id
        )
      )
    } else {
      setCurrentReportData()
    }
  }, [reportDataApi, parameters])

  return reportDataResult.status === 1 ? (
    <LoadingTable />
  ) : reportDataResult.status === 2 && currentReportData ? (
    <Box className={globalClasses.tableContainer}>
      <ClpTable
        rows={reportDataResult.data.rows}
        subColumns={currentReportData.subColumns}
        columns={currentReportData.columns}
        footer={reportDataResult.data.totalSum}
      />
    </Box>
  ) : (
    <NoReportSelectedTable />
  )
}
