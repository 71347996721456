import {
  faCalendarDay,
  faCheckCircle,
  faFolderOpen,
  faPaperPlane,
  faTimesCircle
} from "@fortawesome/pro-solid-svg-icons"

export function workorderStatusIcon(status: number) {
  switch (status) {
    case 1:
      return faFolderOpen
    case 2:
      return faPaperPlane
    case 3:
      return faCalendarDay
    case 4:
      return faCheckCircle
    case 5:
      return faCheckCircle
    case 6:
      return faTimesCircle
    default:
      return undefined
  }
}
