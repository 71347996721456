import { Box, FormControlLabel, Switch } from "@mui/material"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Button from "components/Button"
import PaperBox from "components/PaperBox"
import { Formik } from "formik"
import React from "react"
import { contractValidationSchema } from "./ContractValidationSchema"

export const ContractForm = ({ onSubmit, editContract, result, feedback }) => {
  const initialValues = editContract
    ? editContract
    : {
        name: "",
        assignmentNr: "",
        active: false
      }

  const editMode = editContract ? true : false

  const handleSubmit = async (values, { resetForm }) => {
    onSubmit(values)
  }
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={contractValidationSchema}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <PaperBox>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="name"
                  label="Namn"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && errors.name ? true : false}
                  helperText={touched.name && errors.name ? errors.name : null}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="assignmentNr"
                  label="Avtalsnummer"
                  value={values.assignmentNr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.assignmentNr && errors.assignmentNr ? true : false}
                  helperText={touched.assignmentNr && errors.assignmentNr ? errors.assignmentNr : null}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </PaperBox>

          <PaperBox>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Avtal aktiv</Typography>
                {/* <Typography variant="bodySmall">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Ut libero
                                </Typography> */}
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(item) => {
                        const checked = item.target.checked
                        setFieldValue("active", checked)
                      }}
                      checked={values.active}
                    />
                  }
                />
              </Grid>
            </Grid>
          </PaperBox>
          <Grid container direction="row" justifyContent="flex-end">
            <Box my={3}>
              <Button color="primary" type="submit" result={result} feedback={feedback}>
                {editMode ? "Spara avtal" : "Lägg till avtal"}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  )
}
