import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faChevronDown, faFolder } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  Typography
} from "@mui/material"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import CompanyDepartmentSelection from "components/CompanyDepartmentSelection"
import TextEditor from "components/Fields/TextEditor"
import { RouterPrompt } from "components/RouterPrompt"
import { CircleIcon } from "components/icons/CircleIcon"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { t } from "i18next"
import React, { useContext, useEffect, useRef, useState } from "react"
import { IDocumentFormValues } from "../../interface/folder.interface"
import { folderValidationSchema } from "../folderValidationSchema"

const FolderForm = (formValues: IDocumentFormValues) => {
  const appContext = useContext(AppContext)
  const [sharedByAll, setSharedToAll] = useState(formValues?.companyIds?.length < 1)
  const [expanded, setExpanded] = useState(false)
  const toggleAccordion = () => {
    setExpanded((p) => !p)
  }

  useEffect(() => {
    setSharedToAll(formValues?.companyIds?.length < 1)
  }, [formValues])

  const formRef = useRef(null)

  return (
    <Formik
      innerRef={formRef}
      initialValues={formValues}
      validationSchema={folderValidationSchema}
      onSubmit={formValues.handleSubmit}
      enableReinitialize={true}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
        <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
          <RouterPrompt />
          <List>
            {formValues.id < 1 && (
              <CloseSidebarButton
                toggleMenu={() => {
                  appContext.toggleSideBar()
                }}
              />
            )}

            {formValues.id < 1 && (
              <ListItem>
                <Box pt={5} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <CircleIcon icon={faFolder} color="primary" size="lg" sx={{ marginRight: "15px" }} />
                    <Box justifyContent="center">
                      <ListItemText
                        primary={
                          <Typography color="textPrimary" variant="h2">
                            {t(`documents.add_button`)}
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </ListItem>
            )}
            <ListItem>
              <ListItemText
                primary={
                  <InputLabel>
                    <Typography variant="labelMedium" fontWeight={500}>
                      {t(`documents.folder_form.name`)}
                    </Typography>
                  </InputLabel>
                }
                secondary={
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name ? true : false}
                    helperText={touched.name && errors.name ? errors.name : null}
                    variant="outlined"
                  />
                }
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <InputLabel>
                    <Typography variant="labelMedium" fontWeight={500}>
                      {t(`documents.folder_form.description`)}
                    </Typography>
                  </InputLabel>
                }
                secondary={
                  <TextField
                    multiline
                    rows={2}
                    autoComplete="off"
                    fullWidth
                    id="description"
                    value={values?.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && errors.description ? true : false}
                    helperText={touched.description && errors.description ? errors.description : null}
                    variant="outlined"
                  />
                }
              />
            </ListItem>
            <ListItem>
              <TextEditor
                value={formValues?.body}
                setData={(e: any) => {
                  setFieldValue("body", e)
                }}
                uploadApi={apiEndpoints.documents.uploadattachment}
              />
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => {
                      setFieldValue("isVisibleStartPage", e.target.checked)
                    }}
                    id="company"
                    checked={values.isVisibleStartPage}
                  />
                }
                label={t(`documents.folder_form.list_startpage`)}
              />
            </ListItem>
            <ListItem>
              <Box marginTop={3}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const companyIds: any[] = []
                        const departmentIds: any[] = []

                        setSharedToAll(e.target.checked)
                        if (e.target.checked === true) {
                          setFieldValue("companyIds", companyIds)
                          setFieldValue("departmentIds", departmentIds)
                          setExpanded(false)
                        } else {
                          setExpanded(true)
                        }
                      }}
                      id="allCompanies"
                      checked={sharedByAll}
                    />
                  }
                  label={t(`documents.folder_form.shared_text`)}
                />
                <Accordion expanded={expanded} onChange={toggleAccordion}>
                  <AccordionSummary
                    disabled={values.companyIds?.length < 1 && sharedByAll}
                    expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
                    aria-controls="settings">
                    <Typography>{t(`documents.folder_form.pick_company_and_departments`)}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <CompanyDepartmentSelection
                        companies={appContext.appUser.customer.companies}
                        initCompanies={values.companyIds}
                        initDepartments={values.departmentIds}
                        disabled={values.companyIds?.length < 1 && sharedByAll}
                        onChange={(newState: any) => {
                          const companyIds: any[] = []
                          const departmentIds: any[] = []

                          newState.forEach((c: any) => {
                            if (c.selected) companyIds.push(c.id)
                            c.departments.forEach((d: any) => {
                              if (d.selected) departmentIds.push(d.id)
                            })
                          })

                          setFieldValue("companyIds", companyIds)
                          setFieldValue("departmentIds", departmentIds)
                        }}
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </ListItem>
            <ListItem>
              <Button result={formValues.results} type="submit">
                {formValues.id > 0
                  ? t(`documents.folder_form.save_button`)
                  : t(`documents.folder_form.create_button`)}
              </Button>
            </ListItem>
          </List>
        </form>
      )}
    </Formik>
  )
}
export default FolderForm
