// The main App entry point
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import React, { useEffect } from "react";
import AppBar from "./AppBar";
import SideDrawer from "./SideDrawer";

const Navigation = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const [open, setOpen] = React.useState(matches ? true : false);
    const toggleDrawer = () => setOpen(!open);
    useEffect(() => {
        setOpen(matches ? true : false);
    }, [matches]);
    return (
        <div>
            <AppBar handleDrawerChange={toggleDrawer} openDrawer={open} />

            <SideDrawer handleDrawerChange={toggleDrawer} openDrawer={open} matches={matches} />
        </div>
    );
};

export default Navigation;
