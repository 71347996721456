import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useEffect, useState } from "react";
import { useStyles } from "./useStyles";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        display: "flex",
        justifyContent: "center",
        padding: '2px 0px 2px 14px',
        fontSize: "12px",
        '&:focus': {
            borderRadius: "100vh",
        },
    },

}));

export const ArchiveTableSelectChip = ({ statusId, onChangeStatus, issueReport, size }) => {

    const classes = useStyles();

    const [changeStatus, setStatus] = useState(statusId);

    const statusChoices = [
        {
            name: "Öppen",
            id: 1,
        },
        {
            name: "Planerad",
            id: 2,
        },
        {
            name: "Pågående",
            id: 3,
        },
        {
            name: "Åtgärdad",
            id: 4,
        },
        {
            name: "Avslutad",
            id: 5,
        }
    ];

    useEffect(() => {

    }, [changeStatus])

    const updateStatus = (id) => {
        setStatus(id);
        let newIssueReport = issueReport;
        newIssueReport.status = id;
        onChangeStatus(newIssueReport)
    }

    return (
        <FormControl size="small" variant="filled">
            <Select
                autoWidth
                input={<BootstrapInput />}
                id="status"
                value={changeStatus}
                disableUnderline
                size="small"
                defaultValue={statusId}
                IconComponent={ExpandMoreIcon}
                onChange={(e) => updateStatus(e.target.value)}
                className={
                    changeStatus === 1 //Öppen 
                        ? classes.blueChip
                        : changeStatus === 2 //Planerad
                            ? classes.yellowChip
                            : changeStatus === 3 //planerad åtgärd
                                ? classes.yellowChip
                                : changeStatus === 4 //Åtgärdad
                                    ? classes.yellowChip
                                    : changeStatus === 5 //avslutat
                                        ? classes.greenChip
                                        : null}
            >
                {statusChoices.map(
                    (item) =>
                    (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    )
                )}
            </Select>
        </FormControl>
    );
};