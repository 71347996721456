import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Container, Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useApiGet } from "auth/useApi2"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import moment from "moment"
import React, { useContext, useEffect, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  successColor: {
    color: theme.palette.success.main
  },
  errorColor: {
    color: theme.palette.error.main
  }
}))

const Register = () => {
  const globalClasses = globalUseStyles()
  const routerHistory = useHistory()

  const [deviceResult, getDevices] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)
  const classes = useStyles()

  useEffect(() => {
    getDevices(apiEndpoints.systemadmindeviceregistry.listdevices)
  }, [getDevices])

  useEffect(() => {
    loadingContext.setLoading(() => deviceResult.status === 1)
  })

  const initialFilters = [
    {
      id: "Utrustning kopplad",
      value: "Utrustning kopplad"
    }
  ]

  const columns = useMemo(
    () => [
      {
        accessor: "deviceId",
        numeric: false,
        disablePadding: false,
        Header: "Regnummer"
      },
      {
        accessor: "internalDeviceId",
        numeric: false,
        disablePadding: false,
        Header: "Internnummer"
      },
      {
        Header: "Kopplade utrustningar",
        accessor: "equipments",
        Cell: ({ row }) => {
          var label = row.original.equipments
          return <>{label}</>
        },
        FilterHeader: "Kopplade utrustningar",
        mainFilter: true,
        Filter: SelectColumnFilter,
        filter: "customFilter",
        FilterOptions: [
          {
            name: "Utrustning kopplad",
            value: "True"
          },
          {
            name: "Ingen utrustning",
            value: "False"
          }
        ]
      },
      {
        accessor: "companyName",
        disablePadding: false,
        Header: "Bolag",
        mainFilter: true,
        Filter: SelectColumnFilter
      },
      {
        accessor: "technicalPackageName",
        disablePadding: false,
        mainFilter: true,
        Header: "Teknikpaket",
        Filter: SelectColumnFilter
      },
      {
        accessor: (row) => {
          return row.equipmentOwnedByCustomer ? "Kund" : "Cloudplanning"
        },
        disablePadding: false,
        Header: "Ägare av utrustning",
        Filter: SelectColumnFilter
      },
      {
        accessor: (row) => {
          return row.created && moment(row.created).isValid()
            ? moment(row.created).format("YYYY-MM-DD hh:mm")
            : ""
        },
        numeric: false,
        Header: "Skapad"
      },
      {
        accessor: "status",
        numeric: false,
        Header: "Status",
        Filter: SelectColumnFilter,

        FilterHeader: "Visa alla",
        mainFilter: true,
        align: "center",
        FilterOptions: [
          {
            name: "Aktiva",
            value: "Aktiv"
          },
          {
            name: "Inaktiva",
            value: "Inaktiv"
          }
        ],
        Cell: ({ value }) => (
          <FontAwesomeIcon
            className={value === "Aktiv" ? classes.successColor : classes.errorColor}
            icon={faCircle}
          />
        )
      }
    ],
    [classes.errorColor, classes.successColor]
  )

  return (
    <div>
      <Container>
        <Box mb={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Enheter</Typography>
            <Button
              component={Link}
              to={allRoutes.SystemadminDevicesRegisterAdd()}
              color="primary"
              variant="contained">
              Lägg till enhet
            </Button>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rowProps={(row) => ({
            onClick: () => routerHistory.push(allRoutes.SystemadminDevicesRegisterEdit(row.original.id))
          })}
          rows={deviceResult.status === 2 && deviceResult.data}
          status={deviceResult.status}
          initialFilters={initialFilters}
        />
      </Box>
    </div>
  )
}

export default Register
