import { Typography, Box, ListItemText } from '@mui/material'
import { CircleIcon } from 'components/icons/CircleIcon'
import React from 'react'
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export interface CContent {
    icon: FontAwesomeIconProps["icon"];
    size: FontAwesomeIconProps["size"];
    color: string;
    subtitle: string;
    title: string;
    footer: any;
    infoText: any;
}

const SidebarHeader = (props: CContent) => {
    const { icon, size, color, subtitle, title, footer, infoText } = props;
    return (
        <Box pb={infoText && 2} sx={{ borderBottom: "1px solid #e0e0e0" }}>
            <Box pt={2} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <CircleIcon color={color} icon={icon} size={size} />
                    <Box ml={2} flexDirection="column" justifyContent="center">
                        <ListItemText
                            primary={
                                <Typography color="textPrimary" variant="h2">
                                    {title}
                                </Typography>
                            }
                            secondary={
                                <Typography color="textSecondary" variant="bodySmall">
                                    {subtitle}
                                </Typography>
                            }
                        />
                    </Box>
                </Box>
                <Box>
                    {footer && footer}
                </Box>
            </Box>
            {infoText && infoText}
        </Box>
    )
}

export default SidebarHeader

