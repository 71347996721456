import { Box, Button, Card, CardActions, CardContent, Chip, Container, Divider, Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { hasRole } from "auth/hasRole"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import "moment/locale/sv"
import React, { useContext, useEffect, useState } from "react"
import Moment from "react-moment"
import { Link } from "react-router-dom"
import FilterForm from "../../components/FilterForm"
import NoNewsCard from "../../components/NoNewsCard"
import SortForm from "../../components/SortForm"
import PaginationForPages from "components/paginationForPages/PaginationForPages"

const styles = makeStyles((theme) => ({
  iconMr: {
    marginRight: 5
  },
  link: {
    color: theme.palette.grey[800],
    textDecoration: "none"
  },
  column: {
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  grey: {
    color: theme.palette.grey[600],
    fontSize: "0.875em",
    [theme.breakpoints.between("sm", "md")]: {
      alignItems: "flex-start"
    }
  },
  cover: {
    minWidth: "35%"
  },
  root: {
    display: "flex",
    flexDirection: "column"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto",

    [theme.breakpoints.up("md")]: {
      padding: "35px 45px"
    }
  }
}))

const truncate = (str) => {
  return str.length > 249 ? str.substring(0, 250) + "..." : str
}

// initial page states:
const initialState = {
  sortby: 0,
  direction: 0,
  showAll: true,
  companyIds: [],
  departmentIds: [],
  pageSize: 10,
  page: 1
}

const NewsMain = () => {
  const classes = styles()
  const loadingContext = useContext(GlobalLoaderContext)
  const appContext = useContext(AppContext)
  const isNewsAdmin = hasRole(appContext.appUser, allRoles.NewsAdmin)
  const rows = [1, 2, 3, 4, 5, 6]

  // if we're newsadmin show the option to "show all"
  // which means if you _don't_ select any companies you can see the whole customers news
  // and you also get a new list of companies to select from
  // otherwise you can select from your own companies

  const [state, setState] = useState(initialState)

  const [showAllCompanies, setShowAllCompanies] = useState(false)

  // the list of available companies to select from
  const [companies, setCompanies] = useState(appContext.appUser.customer.companies)

  const [adminCompanies, setAdminCompanies] = useState([])

  // if we're newsadmin and select "show all = true", then we fetch the customer to get "all companies"
  const [newsAdminCustomerResult, newsAdminCustomerFuncs] = useApi()

  const [listResult, getListFuncs] = useApi()
  const [page, setPage] = useState(1)
  const pageSize = 10

  // first load, show all articles
  // and load the admin companies if we can
  useEffect(() => {
    getListFuncs.post(apiEndpoints.news.listarticles, { pageSize: pageSize })

    if (isNewsAdmin) newsAdminCustomerFuncs.get(apiEndpoints.newsadmin.getcustomer)
  }, [getListFuncs, newsAdminCustomerFuncs, isNewsAdmin])

  useEffect(() => {
    getListFuncs.post(apiEndpoints.news.listarticles, { ...state, pageSize, page })
  }, [page])
  // when we get data, set the admincompanies
  // it will stay empty if we're not admín
  useEffect(() => {
    if (newsAdminCustomerResult.status === 2) setAdminCompanies(newsAdminCustomerResult.data.companies)
  }, [newsAdminCustomerResult])

  useEffect(() => {
    loadingContext.setLoading(() => listResult.status === 1)
  })

  const count = Math.ceil(listResult.status === 2 && listResult.data.pageCount)
  const setPageNum = (e, page) => {
    setPage(page)
  }

  // when filter change, reload list of articles
  const onChange = React.useCallback(
    (newState) => {
      setPage(1)
      getListFuncs.post(apiEndpoints.news.listarticles, {
        direction: newState.sortby,
        showAll: showAllCompanies,
        companyIds: newState.companyIds,
        departmentIds: newState.departmentIds
      })
      setState((prevState) => {
        return {
          ...prevState,
          direction: newState.sortby,
          showAll: showAllCompanies,
          companyIds: newState.companyIds,
          departmentIds: newState.departmentIds
        }
      })
    },
    [getListFuncs, showAllCompanies]
  )

  useEffect(() => {
    if (showAllCompanies) {
      setCompanies(adminCompanies)
    } else {
      setCompanies(appContext.appUser.customer.companies)
    }
  }, [showAllCompanies, adminCompanies, appContext.appUser.customer.companies])

  return (
    <>
      <Container>
        <Box marginY={3}>
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Senaste nyheterna</Typography>
          </Grid>
        </Box>

        <Grid container spacing={4} direction="row" justifyContent="space-between">
          <Grid item xs={12} sm={12} md={8}>
            <Box mb={2}>
              <PaginationForPages count={count} page={page} onChange={setPageNum} size="medium" />
            </Box>
            {listResult.status === 2 ? (
              <>
                {listResult.data.results.length < 1 && <NoNewsCard />}
                <Grid container spacing={4} alignItems="flex-end">
                  {listResult.data.results.map((item, key) => {
                    return (
                      <Grid key={key} item xs={12}>
                        <Link to={allRoutes.NewsListRead(item.id)} className={classes.link}>
                          <Card className={classes.root}>
                            <div className={classes.details}>
                              <CardContent className={classes.content}>
                                {item.priority && (
                                  <Box mb={2}>
                                    <Chip label="Viktigt" color="primary" />
                                  </Box>
                                )}
                                <Typography variant="h4" gutterBottom>
                                  {item.subject}
                                </Typography>{" "}
                                <Typography variant="bodySmall" color="textSecondary" component="p">
                                  {truncate(item.subTitle)}
                                </Typography>
                                <Box mt={2}>
                                  <Divider light mb={2} />
                                </Box>
                                <CardActions>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className={classes.column}>
                                    <Grid item className={classes.grey}>
                                      Av {item.publishedByUserFullName}
                                      {" • "}
                                      <Moment format="LL" globallocale="sv">
                                        {item.publishedDate}
                                      </Moment>
                                    </Grid>
                                    <Button
                                      component={Link}
                                      size="small"
                                      color="primary"
                                      sx={{
                                        justifyContent: "flex-start",
                                        padding: "4px 0px"
                                      }}
                                      to={allRoutes.NewsListRead(item.id)}>
                                      Läs mer
                                    </Button>
                                  </Grid>
                                </CardActions>
                              </CardContent>
                            </div>
                          </Card>
                        </Link>
                      </Grid>
                    )
                  })}
                </Grid>
              </>
            ) : (
              rows.map(() => (
                <Grid item pb={4}>
                  <SkeletonCard padding="20px 30px" />
                </Grid>
              ))
            )}
            <Box mt={2}>
              <PaginationForPages count={count} page={page} onChange={setPageNum} size="medium" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <PaperBox marginY={0}>
              <SortForm onChange={onChange} setState={setState} state={state} />
              <Divider light />
              <FilterForm
                onChange={onChange}
                showShowAll={isNewsAdmin}
                setState={setState}
                state={state}
                setShowAllCompanies={setShowAllCompanies}
                companies={companies}
                showAllCompanies={showAllCompanies}
              />
            </PaperBox>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default NewsMain
