import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { faSort, faTrashAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Card, CardHeader, IconButton, List, ListItem } from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import classNames from "classnames"
import { FieldArray } from "formik"
import React, { useContext } from "react"
import { ReportTemplateContext } from "../ReportTemplateContextProvider"
import { useStyles } from "./styles"

const SortableItem = (props: any) => {
  const classes = useStyles()
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    activeIndex,
    overIndex
  } = useSortable({ id: props.id })
  const { field, index, onRemove } = props

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move"
  }

  return (
    <ListItem
      key={index}
      id={index}
      style={style}
      ref={setNodeRef}
      className={classNames(
        classes.listItem,
        activeIndex === index && classes.activeListItem,
        overIndex === index && overIndex !== activeIndex && classes.isOver
      )}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
        ref={setActivatorNodeRef}>
        <Grid item xs={1} className={classes.sortIcon} {...attributes} {...listeners}>
          <FontAwesomeIcon icon={faSort} />
        </Grid>
        <Grid item xs={5} {...attributes} {...listeners}>
          <Typography variant="bodySmall" color="textPrimary">
            {field.name}
          </Typography>
        </Grid>
        <Grid item xs={5} {...attributes} {...listeners}>
          <Typography variant="bodySmall" color="textSecondary">
            {field.title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          component={IconButton}
          edge="end"
          size="small"
          aria-label="delete"
          onClick={() => onRemove(index)}>
          <FontAwesomeIcon icon={faTrashAlt} size="xs" />
        </Grid>
      </Grid>
    </ListItem>
  )
}
export const ChosenFields = () => {
  const classes = useStyles()
  const { selectedFields, setSelectedFields } = useContext(ReportTemplateContext)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function handleDragEnd(event: any) {
    const { active, over } = event
    if (active.id !== over.id) {
      setSelectedFields((items: any[]) => {
        return arrayMove(items, active.id, over.id)
      })
    }
  }
  const removeField = (index: number) => {
    setSelectedFields([...selectedFields.slice(0, index), ...selectedFields.slice(index + 1)])
  }
  return (
    <FieldArray
      name="fieldGroups"
      render={() => (
        <Box component={Card} paddingY={2}>
          <CardHeader className={classes.cardHeader} title={`Valda fält`} />
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext
              id={"chosenFields"}
              items={selectedFields.map((i: any, index: number) => index)}
              strategy={rectSortingStrategy}>
              <List>
                {selectedFields.map((field: any, index: number) => (
                  <SortableItem key={index} id={index} index={index} field={field} onRemove={removeField} />
                ))}
              </List>
            </SortableContext>
          </DndContext>
        </Box>
      )}
    />
  )
}
