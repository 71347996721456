import { Grid, Typography } from '@mui/material'
import React from 'react'

const Header = ({
  logoResult,
  workorderResult: {
    data: { id }
  }
}) => {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item display="flex" alignItems="center">
        <Typography fontWeight={400} variant="h1">
          Arbetsorder
        </Typography>
        <Typography sx={{ ml: 1 }} color="primary" variant="h1">
          #{id}
        </Typography>
      </Grid>
      <Grid item>
        {logoResult.status === 2 && (
          <img
            src={`data:${logoResult.data.contenType};base64,${logoResult.data.base64Contents}`}
            width={150}
            alt=""
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Header
