import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 40px",
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  input: {
    flex: 1,
    padding: "18.5px 14px",
    fontSize: "1rem",
    "& input": {
      padding: 0
    }
  },
  iconButton: {
    padding: "16px"
  },
  search: {
    display: "flex",
    width: "100%"
  },
  container: {
    position: "relative"
  },
  label: {
    color: "rgba(0, 0, 0, 0.54)",
    top: "-11px",
    left: "9px",
    padding: "0 5px",
    background: theme.palette.background.paper,
    position: "absolute"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  list: {
    boxShadow: "rgba(149, 157, 165, 0.1) 0px 8px 24px;",
    borderRadius: "10px"
  },
  listItem: {
    cursor: "pointer",
    transition: "background  0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.grey[100]
      // color: theme.palette.color.primary
    }
  },
  removeButton: {
    paddingTop: "0!important",
    paddingBottom: "15px!important"
  },
  formAreaInput: {
    paddingRight: "2px",
    paddingLeft: "8px"
  }
}))
