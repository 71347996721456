import { faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import makeStyles from "@mui/styles/makeStyles"
import LinkPaperBox from "components/LinkPaperBox"

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "3rem 2rem"
  },
  textGrey: {
    color: grey[500]
  },
  link: {
    textDecoration: "inherit"
  }
}))

export const ExaminationPdfLinkCard = (props) => {
  const { title, pdf, icon } = props

  const classes = useStyles()

  return (
    <LinkPaperBox p={0} {...props}>
      <a className={classes.link} href={pdf} target="__blank">
        <Grid container direction="row" justifyContent="space-between">
          <Grid item className={classes.button} xs={12}>
            <Typography variant="paragraphLarge">{title}</Typography>
            <Grid item md={1}>
              <FontAwesomeIcon className={classes.textGrey} icon={icon ? icon : faChevronRight} size="2x" />
            </Grid>
          </Grid>
        </Grid>
      </a>
    </LinkPaperBox>
  )
}
