import { Grid, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { FormStepperProps, IStepIconProps } from "components/Form/Stepper/formstepper.interface"
import React from "react"
import { useTranslation } from "react-i18next"
import useStyles from "../../../pages/deviation/pages/components/useStyles"

const StepIcon: React.FC<IStepIconProps> = (props) => {
  const { active, completed } = props
  return active ? null : completed ? null : null
}

export const FormStepper: React.FC<FormStepperProps> = (props) => {
  const { areas, activeStep, name } = props
  const { t } = useTranslation("translation", { keyPrefix: "deviation.wizard" })
  const classes = useStyles()

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" pb={4}>
      <Grid item>
        <Typography variant="h3">
          {t("report_deviation", "Rapportera avvikelse")}: {name}
        </Typography>
      </Grid>
      {areas.length > 1 && (
        <Grid item>
          <Stepper activeStep={activeStep} className={classes.stepper} orientation="horizontal">
            {areas?.map((area: any, index: any) => {
              return (
                <Step sx={{ padding: 0 }} key={index}>
                  <StepLabel StepIconComponent={StepIcon} className={classes.stepLabel}>
                    {area.step + 1}. {area.stepLabel}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Grid>
      )}
    </Grid>
  )
}
