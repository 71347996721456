import { faAdd } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Grid, InputLabel, TextField, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { Field, FieldArray, Form } from "formik"
import VtrSwitch from "pages/customer/pages/settings/pages/companies/components/VtrSwitch"
import { useTranslation } from "react-i18next"

const CustomerCompanyInformation = (props) => {
  const { values, handleChange, handleBlur, setFieldValue } = props
  const { t } = useTranslation("translation", { keyPrefix: "settings" })

  return (
    <>
      <FieldArray
        name={`companies`}
        render={(arrayHelpers) => {
          const companies = values.companies
          return (
            <Form>
              {companies &&
                companies.length > 0 &&
                companies.map((company, index) => (
                  <>
                    <PaperBox sx={{ mt: index !== 0 ? "40px" : "0px" }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" row justifyContent="flex-end" marginBottom={3}>
                            {index !== 0 && (
                              <Button
                                type="button"
                                color="error"
                                variant="outlined"
                                onClick={() => arrayHelpers.remove(index)}>
                                {t("form.button.remove_company")}
                              </Button>
                            )}
                          </Box>

                          <Grid container spacing={2} mb={3}>
                            <Grid item xs={12} md={8}>
                              <InputLabel>
                                <Typography
                                  variant="labelMedium"
                                  fontWeight={500}
                                  color={theme.palette.foreground.default}>
                                  {t("form.name")}
                                </Typography>
                              </InputLabel>
                              {index === 0 ? (
                                company.name
                              ) : (
                                <Field name={`companies.${index}.name`}>
                                  {({ field, form: { touched, errors }, meta }) => (
                                    <TextField
                                      {...field}
                                      autoComplete="off"
                                      name={`companies.${index}.name`}
                                      fullWidth
                                      id={`companies.${index}.name`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={meta.touched && meta.error ? true : false}
                                      helperText={meta.touched && meta.error ? meta.error : null}
                                      variant="outlined"
                                    />
                                  )}
                                </Field>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <InputLabel>
                                <Typography
                                  variant="labelMedium"
                                  fontWeight={500}
                                  color={theme.palette.foreground.default}>
                                  {t("form.orgnr")}
                                </Typography>
                              </InputLabel>
                              {index === 0 ? (
                                company.organisationNumber
                              ) : (
                                <Field name={`companies.${index}.organisationNumber`}>
                                  {({ field, form: { touched, errors }, meta }) => (
                                    <TextField
                                      {...field}
                                      autoComplete="off"
                                      fullWidth
                                      id="organisationNumber"
                                      name={`companies.${index}.organisationNumber`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={meta.touched && meta.error ? true : false}
                                      helperText={meta.touched && meta.error ? meta.error : null}
                                      variant="outlined"
                                    />
                                  )}
                                </Field>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <InputLabel>
                                <Typography
                                  variant="labelMedium"
                                  fontWeight={500}
                                  color={theme.palette.foreground.default}>
                                  {t("form.streetAddress")}
                                </Typography>
                              </InputLabel>
                              {index === 0 ? (
                                company.streetAddress
                              ) : (
                                <Field name={`companies.${index}.streetAddress`}>
                                  {({ field, form: { touched, errors }, meta }) => (
                                    <TextField
                                      {...field}
                                      autoComplete="off"
                                      fullWidth
                                      id="streetAddress"
                                      name={`companies.${index}.streetAddress`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={meta.touched && meta.error ? true : false}
                                      helperText={meta.touched && meta.error ? meta.error : null}
                                      variant="outlined"
                                    />
                                  )}
                                </Field>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <InputLabel>
                                <Typography
                                  variant="labelMedium"
                                  fontWeight={500}
                                  color={theme.palette.foreground.default}>
                                  {t("form.postalCode")}
                                </Typography>
                              </InputLabel>
                              {index === 0 ? (
                                company.postalCode
                              ) : (
                                <Field name={`companies.${index}.postalCode`}>
                                  {({ field, form: { touched, errors }, meta }) => (
                                    <TextField
                                      {...field}
                                      autoComplete="off"
                                      fullWidth
                                      id="postalCode"
                                      name={`companies.${index}.postalCode`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={meta.touched && meta.error ? true : false}
                                      helperText={meta.touched && meta.error ? meta.error : null}
                                      variant="outlined"
                                    />
                                  )}
                                </Field>
                              )}
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <InputLabel>
                                <Typography
                                  variant="labelMedium"
                                  fontWeight={500}
                                  color={theme.palette.foreground.default}>
                                  {t("form.city")}
                                </Typography>
                              </InputLabel>
                              {index === 0 ? (
                                company.cityAddress
                              ) : (
                                <Field name={`companies.${index}.cityAddress`}>
                                  {({ field, form: { touched, errors }, meta }) => (
                                    <TextField
                                      {...field}
                                      autoComplete="off"
                                      fullWidth
                                      id="cityAddress"
                                      name={`companies.${index}.cityAddress`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={meta.touched && meta.error ? true : false}
                                      helperText={meta.touched && meta.error ? meta.error : null}
                                      variant="outlined"
                                    />
                                  )}
                                </Field>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {values.modules?.findIndex((m) => m.feature === "VehicleRegistry" && m.enabled) !==
                        -1 && (
                        <VtrSwitch
                          img="assets/Transportstyrelsen-icon.jpg"
                          title={t("form.vtr_title")}
                          id={`companies.${index}.hasVtrIntegration`}
                          message={t("form.vtr_description")}
                          value={company.hasVtrIntegration}
                          handleChange={() => {
                            setFieldValue(`companies.${index}.hasVtrIntegration`, !company.hasVtrIntegration)
                          }}
                        />
                      )}
                    </PaperBox>
                  </>
                ))}
              <Grid item pt={4} justifyContent="center" display="flex">
                <Button
                  startIcon={<FontAwesomeIcon icon={faAdd} />}
                  onClick={() =>
                    arrayHelpers.push({
                      name: "",
                      organisationNumber: "",
                      streetAddress: "",
                      postalCode: "",
                      cityAddress: "",
                      newHasVtrIntegration: ""
                    })
                  }
                  variant="outlined"
                  color="primary">
                  {t("form.button.add_company")}
                </Button>
              </Grid>
            </Form>
          )
        }}
      />
    </>
  )
}

// )
// }

export default CustomerCompanyInformation
