import React, { useContext } from "react";
import { useStyles } from "./useStyles";
import Drawer from "@mui/material/Drawer";
import { MapContext } from "../MapContextProvider";
import DeviceList from "./DeviceList/DeviceList";

const DeviceBar = (props) => {
    const { labelMapCardSetting } = { ...props };
    const classes = useStyles();
    const { openDeviceBar, devices } = useContext(MapContext);

    return (
        <div>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={openDeviceBar}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <DeviceList devices={devices} labelMapCardSetting={labelMapCardSetting} />
            </Drawer>
        </div>
    );
};
export default DeviceBar;
