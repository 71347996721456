import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { ExamFormContext } from "./ExamFormStateProvider"

export const SingleQuestion = (props) => {
  const { question, translationOptions } = props
  const answerContext = useContext(ExamFormContext)
  const [state, setState] = useState([])

  useEffect(() => {
    setState(answerContext.getSelectedAnswers(question?.id, true))
  }, [question])

  return (
    <div>
      <RadioGroup
        aria-label={question?.question}
        value={state}
        onChange={(e) => {
          const value = e.target.value
          setState(value)
          answerContext.updateAnswer({
            questionId: question?.id,
            answer: [value] // convert single answer to array
          })
        }}>
        {question.options?.map((o, key) => (
          <FormControlLabel
            key={key}
            value={o.id}
            control={<Radio />}
            label={translationOptions.find((x) => x.id === o.id).label}
          />
        ))}
      </RadioGroup>
    </div>
  )
}
