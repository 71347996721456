import { faCloudDownload, faCommentLines, faLayerGroup } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Container, Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useApi } from "auth/useApi3"
import { DangerousHtml } from "components/DangerousHtml"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { apiEndpoints } from "generated/apiEndpoints"
import { ExaminationMiniInfoCard } from "pages/learning/pages/user/components/ExaminationMiniInfoCard"
import { ExaminationPdfLinkCard } from "pages/learning/pages/user/components/ExaminationPdfLinkCard"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  button: {
    marginBottom: 25,
    textAlign: "center"
  },
  flex: {
    display: "flex"
  },
  icon: {
    color: theme.palette.primary["main"],
    marginRight: 8
  }
}))

const ExamDetails = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const courseDetails = t("learning.details.course", { returnObjects: true })

  const loadingContext = useContext(GlobalLoaderContext)
  const { match } = props
  const korVila1 = process.env.PUBLIC_URL + "/assets/learning/korVila1.pdf"

  const [examResult, examFuncs] = useApi()
  const [examinationType, setExaminationType] = useState()

  useEffect(() => {
    examFuncs.get(apiEndpoints.learning.getassignedexaminationdetails.replace("{id}", match.params.id))
  }, [examFuncs, match])
  useEffect(() => {
    loadingContext.setLoading(() => examResult.status === 1)
  })

  useEffect(() => {
    if (examResult && examResult.status === 2) {
      setExaminationType(examResult.data.examinationType)
    }
  }, [examResult])

  let courseDetail = null
  if (examinationType && examResult.status === 2) {
    courseDetail = courseDetails.find((course) => course.examinationType === examinationType)
  }

  return (
    <>
      <Container>
        {examResult.status === 2 ? (
          <Typography variant="h1" gutterBottom>
            {courseDetail ? courseDetail?.examinationName : examResult.data.examinationName}
          </Typography>
        ) : (
          <SkeletonCard />
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <PaperBox>
              <Box marginBottom={2}>
                {examResult.status === 2 ? (
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid item className={classes.flex}>
                      <FontAwesomeIcon icon={faLayerGroup} className={classes.icon} />
                      <Typography variant="bodySmall">
                        {examResult.data.examinationAreaCount} {t("learning.titles.segments")}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.flex}>
                      <FontAwesomeIcon icon={faCommentLines} className={classes.icon} />
                      <Typography variant="bodySmall">
                        {examResult.data.examinationQuestionCount} {t("learning.titles.questions")}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <SkeletonCard />
                )}
              </Box>
              {examResult.status === 2 ? (
                <>
                  <Typography variant="bodyMedium" gutterBottom>
                    <DangerousHtml>
                      {courseDetail
                        ? courseDetail?.examinationDescription
                        : examResult.data.examinationDescription}
                    </DangerousHtml>
                  </Typography>
                  <Typography variant="bodyMedium">
                    <DangerousHtml>
                      {courseDetail
                        ? courseDetail?.examinationDefinition.subTitle
                        : examResult.data.examinationDefinition.subTitle}
                    </DangerousHtml>
                  </Typography>
                  <Typography variant="bodyMedium">
                    <DangerousHtml>
                      {courseDetail
                        ? courseDetail?.examinationDefinition.description
                        : examResult.data.examinationDefinition.description}
                    </DangerousHtml>
                  </Typography>
                </>
              ) : (
                <SkeletonCard />
              )}
            </PaperBox>
          </Grid>
          <Grid item xs={12} md={4}>
            {examResult.status === 2 ? <ExaminationMiniInfoCard item={examResult.data} /> : <SkeletonCard />}
            {examResult.status === 2 ? (
              examResult.data.examinationType === "KorVila1" && (
                <ExaminationPdfLinkCard
                  icon={faCloudDownload}
                  pdf={korVila1}
                  title={t("learning.details.titles.download")}
                />
              )
            ) : (
              <SkeletonCard />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
export default ExamDetails
