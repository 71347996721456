import React from "react"

import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  LinearProgress,
  Typography
} from "@mui/material"
import { Question } from "./Question"

export const Area = (props) => {
  const { item } = props

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = () => {
    setExpanded((p) => !p)
  }
  const totalQuestions = item.questions.length

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="moment1-content">
        <Grid container alignItems="center">
          <Grid xs={12} item sm={7}>
            <Typography variant="bodyMedium">{item.areaTitle}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid container direction="column" justifyContent="flex-end">
              <Grid item>
                <Typography variant="caption" color="textSecondary">
                  {item.totalCorrectAnswersCount} / {item.totalCorrectCount} antal rätt
                </Typography>
              </Grid>
              <Grid item>
                <Box width={1}>
                  <LinearProgress
                    variant="determinate"
                    value={Math.round((100 / item.totalCorrectCount) * item.totalCorrectAnswersCount)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {/* list of <Question */}
        {item.questions.map((item, key) => {
          return (
            <Box marginBottom={2} key={key}>
              <Question item={item} totalQuestions={totalQuestions} index={key + 1} />
              <Divider light />
            </Box>
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}
