import { Grid, Typography } from "@mui/material"
import React from "react"

const ActionCardDetailText = ({ data, title }) => {
  return (
    <Grid item mb={4} mr={3}>
      <Typography color="textPrimary" mb={1} variant="bodySmall">
        {title}
      </Typography>
      <Typography color="textPrimary" variant="bodyMedium" fontWeight={600}>
        {data ? data : "-"}
      </Typography>
    </Grid>
  )
}

export default ActionCardDetailText
