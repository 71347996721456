import { InputLabel, Typography } from "@mui/material"
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers"
import globalUseStyles from "css/theme/globalUseStyles"
import theme from "css/theme/light"
import { t } from "i18next"
import moment from "moment"
import React from "react"
import { IFormDateFieldProps } from "./formdatetimepicker.interface"

const FormDateTimePicker: React.FC<IFormDateFieldProps> = (props) => {
  const globalClasses = globalUseStyles()
  const { labelKey, labelDefault, value, handleChange, optional, error, helperText, handleBlur, time } = props

  const textFieldTemplate = {
    textField: {
      sx: {
        background: theme.palette.background.paper,
        width: "100%"
      },
      onChange: (newValue: React.ChangeEvent<HTMLInputElement>) => {
        return handleChange && handleChange(newValue)
      },
      onBlur: handleBlur,
      error,
      helperText
    }
  }
  return (
    <>
      <InputLabel className={globalClasses.marginBottom5}>
        <Typography variant="labelLarge" fontWeight={500}>
          {labelKey ? t(labelKey, labelDefault) : labelDefault}
          {!optional && "*"}
        </Typography>
      </InputLabel>
      {time ? (
        <DateTimePicker
          value={value ? moment(value) : null}
          format={"YYYY-MM-DD HH:mm"}
          onChange={(newValue: any) => {
            handleChange && handleChange(newValue)
          }}
          slotProps={textFieldTemplate}
        />
      ) : (
        <DatePicker
          value={value ? moment(value) : null}
          format={"YYYY-MM-DD"}
          onChange={(newValue: any) => {
            handleChange && handleChange(newValue)
          }}
          slotProps={textFieldTemplate}
        />
      )}
    </>
  )
}

export default FormDateTimePicker
