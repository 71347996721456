import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faChevronRight, faFolder } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Grid, Stack, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { AppContext } from "components/AppContextProvider"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import { IDocumentFolder } from "pages/documents/interface/folder.interface"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import LoadingDocuments from "./LoadingDocuments"

const Folder = () => {
  const { t } = useTranslation("translation", { keyPrefix: "documents" })
  const appContext = useContext(AppContext)
  const [foldersResult, listFoldersApi] = useApi() as [IApiResult, IApiFunc]

  useEffect(() => {
    listFoldersApi.get(apiEndpoints.documents.listfoldersforstartpage)

    return () => {
      listFoldersApi.leave()
    }
  }, [listFoldersApi])

  return foldersResult.status === 1 ? (
    <LoadingDocuments />
  ) : (
    foldersResult.status === 2 && foldersResult.data.length > 0 && (
      <>
        <Box marginTop={3}>
          <Typography variant="h5"> {t(`title`)}</Typography>
        </Box>
        <PaperBox px={0}>
          {foldersResult.status === 2 &&
            foldersResult.data.length > 0 &&
            foldersResult.data.map((folder: IDocumentFolder, index: number) => (
              <Box gap={2} mb={2} borderBottom={1} px={3} borderColor="#EEEEEE">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  paddingBottom={1}
                  spacing={1}
                  justifyContent="space-between"
                  component={Link}
                  to={allRoutes.DocumentsDetails(folder.id)}
                  style={{ color: "inherit", textDecoration: "inherit" }}>
                  <Grid item xs={8} md={8}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <FontAwesomeIcon color={theme.palette.warning["main"]} icon={faFolder as IconProp} />
                      <Typography display="inline-block" variant="bodyMedium" fontWeight="600">
                        {folder.name}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <Typography variant="bodySmall">
                      {folder.totalAttachments} {t(`list.files`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sm={2}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                      <FontAwesomeIcon icon={faChevronRight as IconProp} />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ))}
        </PaperBox>
      </>
    )
  )
}
export default Folder
