import { Grid, List, Typography } from "@mui/material"
import React from "react"
import { useStyles } from "../useStyles"
import { Option } from "./Option"

export const Question = (props) => {
  const { item, index, totalQuestions } = props
  const classes = useStyles()

  return (
    <React.Fragment>
      <Typography variant="caption">
        Fråga {index}/{totalQuestions}
      </Typography>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{item.question}</Typography>{" "}
      </Grid>

      <List dense>
        {item.options.map((item, key) => {
          return <Option item={item} key={key} />
        })}
      </List>
    </React.Fragment>
  )
}
