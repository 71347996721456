import { Box, Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"

import { apiEndpoints } from "generated/apiEndpoints"
import { UserForm } from "shared/customer/UserForm"

import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { PageTabs } from "components/navigation/PageTabs"
import { allRoutes } from "generated/routes"
import { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
const AddUser = ({ match }) => {
  const [customerResult, customerApi] = useApi()
  const [suggestPasswordResult, suggestPasswordApi] = useApi()
  const [emptyUserResult, getEmptyUser] = useApi()
  const [addUserResult, addUserApi] = useApi()
  const loadingContext = useContext(GlobalLoaderContext)
  const routerHistory = useHistory()

  useEffect(() => {
    // efter skapa success, redirecta till listan
    if (addUserResult.status === 2) routerHistory.push(allRoutes.CustomerSettingsUsers())
  }, [addUserResult.status, routerHistory])

  useEffect(() => {
    const loadStuff = async () => {
      customerApi.get(apiEndpoints.customeradmin.getcustomer)
      getEmptyUser.get(apiEndpoints.customeradmin.getemptyuser)
      suggestPasswordApi.get(apiEndpoints.util.getpasswordsuggestion)
    }

    loadStuff()
  }, [customerApi, suggestPasswordApi])

  const handleSubmit = (objectToSave) => {
    addUserApi.post(apiEndpoints.customeradmin.adduser, objectToSave)
  }
  const feedback = {
    errorTitle: "Kunde inte skapa användare"
  }
  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.CustomerSettingsUsers(),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        <Box marginY={3}>
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Lägg till användare</Typography>
          </Grid>
        </Box>

        {customerResult.status === 2 && suggestPasswordResult.status === 2 && emptyUserResult.status === 2 ? (
          <UserForm
            initialValues={emptyUserResult.data}
            passwordSuggestEndpoint={apiEndpoints.util.getpasswordsuggestion}
            suggestedPassword={suggestPasswordResult.data}
            customer={customerResult.data}
            onSubmit={handleSubmit}
            result={addUserResult}
            feedback={feedback}
            addUser={true}
          />
        ) : null}
      </Container>
    </div>
  )
}
export default AddUser
