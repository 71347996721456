import * as Yup from "yup";

export const validationFile = Yup.object().shape({
    excelFile: Yup.mixed().required("Du måste välja en fil")
});
export const validationRegnr = Yup.object().shape({
    regnr: Yup.string().nullable().required("Du måste ange regnr"),
    propellant: Yup.string().nullable().required("Du måste ange drivmedel"),
    odometer: Yup.string().nullable().required("Du måste ange mätarställning"),
    fuelVolume: Yup.string().nullable().required("Du måste ange volym"),
    transactionTime: Yup.string()
        .nullable()
        .required("Du måste ange transaktionsdatum")
});
