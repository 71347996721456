import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0",
    padding: "0"
  },
  textGrey: {
    color: theme.palette.grey[500]
  },
  blue: {
    background: theme.palette.primary[50],
    color: theme.palette.primary[300],
    margin: 0
  },
  // cyan: {
  //     background: theme.palette.cyan[100],
  //     color: theme.palette.cyan[500],
  //     margin: 0
  // },
  grey: {
    background: theme.palette.grey[100],
    color: theme.palette.grey[600],
    margin: 0
  },
  red: {
    background: theme.palette.error["light"],
    color: theme.palette.error["main"],
    margin: 0
  },
  green: {
    background: theme.palette.success["light"],
    color: theme.palette.success["main"],
    margin: 0
  },
  overviewCard: {
    marginTop: "0"
  },
  avatar: {
    width: "60px",
    height: "60px",
    // background: theme.palette.cyan[100],
    color: "#254467",
    marginRight: "16px"
  },
  triangleIcon: {
    position: "absolute",
    right: "-8px",
    top: "-8px",
    color: theme.palette.warning["main"]
  },
  lastUpdate: {
    display: "flex",
    justifyContent: "center"
  },
  blackText: {
    color: "#000000",
    fontWeight: "500"
  }
}))
