import { Box, Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { apiEndpoints } from "generated/apiEndpoints"
import React from "react"
import ExamCard from "./components/ExamCard"

const SysAdmLearningDashboard = () => {
  const [examsResult, examFuncs] = useApi()
  React.useEffect(() => {
    examFuncs.get(apiEndpoints.systemadminlearning.getexamsummaries)
    return () => {
      examFuncs.leave()
    }
  }, [examFuncs])
  return (
    <div>
      <Container>
        <Box mb={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Utbildningsöversikt</Typography>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          {examsResult.status === 1 &&
            [0, 1, 2].map((item, key) => {
              return (
                <Grid key={key} item xs={12} md={5}>
                  <ExamCard skeleton />
                </Grid>
              )
            })}
          {examsResult.status === 2 &&
            examsResult.data.map((exam) => {
              return (
                <Grid key={exam.id} item xs={12} md={5}>
                  <ExamCard item={exam} />
                </Grid>
              )
            })}
        </Grid>
      </Container>
    </div>
  )
}
export default SysAdmLearningDashboard
