import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  navigation: {
    display: "flex"
  },

  // * switch
  switchRoot: {
    width: "50px",
    height: "24px",
    padding: "0px",
    marginLeft: "12px"
  },
  switchBase: {
    color: "#818181",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#89BE3F"
      }
    }
  },
  thumb: {
    color: "white",
    width: "20px",
    height: "20px",
    margin: "1px"
  },
  track: {
    borderRadius: "20px",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      backgroundColor: "#818181",
      position: "absolute",
      top: "6px"
    },
    "&:after": {
      //content: "'On'",
      left: "8px"
    },
    "&:before": {
      //content: "'Off'",
      right: "7px"
    }
  },
  checked: {
    color: "#23bf58 !important",
    transform: "translateX(26px) !important"
  },
  switch: {
    marginRight: "5px"
  },

  // * icons

  // * calendar
  // * header
  header: {
    maxWidth: "100%",
    margin: "0",
    width: "100%",
    position: "absolute",
    top: 5,
    zIndex: 999,
    paddingLeft: 15,
    "@media (max-width: 480px)": {
      paddingLeft: "8px",
      paddingRight: "8px"
    }
  },
  date: {
    marginLeft: "16px",
    marginRight: "16px",
    backgroundColor: "#ffff",
    borderRadius: "10px",
    padding: "2.5px 10px",
    width: "150px",
    marginTop: "8px"
  },

  dateRange: {
    background: "#fff",
    padding: "16px",
    minHeight: "55px",
    boxShadow: "4px 4px 10px 8px rgba(0, 0, 0, 0.09)",
    borderRadius: "3px",
    "& input": {},
    "@media (max-width: 480px)": {
      marginTop: "148px"
    }
  },
  blueBox: {
    marginLeft: "16px",
    background: theme.palette.background.blue,
    padding: "5px 10px",
    minHeight: "55px",
    boxShadow: "4px 4px 10px 8px rgba(0, 0, 0, 0.09)",
    borderRadius: "3px",
    "@media (max-width: 480px)": {
      marginLeft: "0px",
      marginTop: "8px",
      minHeight: "40px"
    }
  },
  deviceButton: {
    position: "absolute",
    right: 0,
    top: 0,
    paddingRight: "8px",
    "@media (max-width: 480px)": {
      top: "100px"
    }
  },
  mapLayerIcons: {
    position: "absolute",
    bottom: 15,
    left: 15,
    zIndex: 999,
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "3px",
    padding: "5px 0px 5px 18px",
    boxShadow: "4px 4px 10px 8px rgba(0, 0, 0, 0.09)"
  },
  icons: {
    marginRight: "16px",
    color: "rgb(33, 39, 55)"
  },
  closeIcon: {
    cursor: "pointer",
    paddingLeft: "8px"
  },
  close: {
    padding: theme.spacing(0.5)
  }
}))
