import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({

    // * filterMenu

    fullList: {
        width: "auto"
    },
    showFilterBtn: {
        color: "#ffff",
        textTransform: "none"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        padding: "16px 0 ",
        boxShadow: "0"
    },
    drawer: {
        flexShrink: 1
    },
    drawerPaper: {
        backgroundColor: "#ffff",
        width: "450px",
        top: 48
    },

    listItem: {
        // borderTop: "1px solid #E0E0E0",
        borderRadius: "0 !important",
        padding: "14px 0",
        alignItems: "flex-start !important",
        display: "flex",
        flexDirection: "column"
    },

    listItemIcon: {
        paddingRight: "12px",
        paddingTop: "4px"
    },

    // * vehicle list
    vehicleList: {
        width: "100%",
        backgroundColor: "theme.palette.background.paper",
        padding: "4rem 2rem 2rem 2rem"
    },

    listItemText: {
        fontSize: "16px"
    },
    listItemTextPrimary: {
        color: "#000000",
        fontWeight: 600
    },
    fullWidth: {
        width: "100%",
        display: "flex"
    },

    greyBorder: {
        borderTop: "1px solid",
        borderColor: theme.palette.grey[300],
    },

    greyBorderBottom: {
        borderBottom: "1px solid",
        borderBottomColor: theme.palette.grey[300],
    },


    bigListItemIconWrapper: {
        backgroundColor: theme.palette.primary[50],
        borderRadius: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "55px",
        width: "55px",
        marginRight: "12px",
    },

    bigListItemIcon: {
        color: theme.palette.primary,
        fontSize: "24px",
    },

    boldText: {
        fontWeight: 600
    },

    // readMoreReadLess

    readMoreListItem: {
        padding: "0",
        alignItems: "flex-start",
        minHeight: "0px",
    },

    readMoreIcon: {
        padding: "2px 0 0 0",
    },


}));
