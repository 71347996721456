import { DatePicker } from "@mui/x-date-pickers"

import { Box, Container, FormControlLabel, Grid, Switch, TextField, Tooltip, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import { AppContext } from "components/AppContextProvider"
import BplTokenLink from "components/BplTokenLink"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import { useContext, useEffect, useMemo, useState } from "react"

const Schedule = () => {
  const globalClasses = globalUseStyles()
  const [details, showDetails] = useState(false)
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
  const [forwardAndReturn, showDriveForwardAndReturn] = useState(false)

  const [assignmentResult, getAssignment] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)
  const appContext = useContext(AppContext)
  useEffect(() => {
    getAssignment(apiEndpoints.planning.getassignment.replace("{date}", date))
  }, [getAssignment, date])

  useEffect(() => {
    loadingContext.setLoading(() => assignmentResult.status === 1)
  })

  const [assignments, setAssignments] = useState([])

  useEffect(() => {
    let assignmentList = []
    assignmentResult.data?.map((a) => {
      if (!forwardAndReturn) {
        a.uppdrag.filter((x) => x.ejFramAterkorning).map((pass) => assignmentList.push(pass))
      } else {
        a.uppdrag.map((pass) => assignmentList.push(pass))
      }
    })
    setAssignments(assignmentList)
  }, [assignmentResult.data, forwardAndReturn])

  const columns = useMemo(
    () => [
      {
        accessor: (row) => {
          return row.passStarttid ? moment(row.passStarttid).format("YYYY-MM-DD") : ""
        },
        Header: "Datum"
      },
      {
        accessor: (row) => {
          return row.starttid ? moment(row.starttid).format("HH:mm") : ""
        },
        Header: "Start"
      },
      {
        accessor: "turStarttidsträng",
        Header: "Avg.tid"
      },
      {
        accessor: (row) => {
          return row.sluttid ? moment(row.sluttid).format("HH:mm") : ""
        },
        Header: "Slut"
      },
      {
        accessor: "beskrivning",
        numeric: true,
        disablePadding: false,
        Header: "Tjänst"
      },
      {
        accessor: "fordonStr",
        numeric: false,
        disablePadding: false,
        Header: "Fordon"
      },
      {
        accessor: "anmarkning",
        numeric: false,
        disablePadding: false,
        Header: "Notering"
      }
    ],
    []
  )

  const columnsDetails = useMemo(
    () => [
      {
        accessor: (row) => {
          return row.passStarttid ? moment(row.passStarttid).format("YYYY-MM-DD") : ""
        },
        Header: "Datum"
      },
      {
        accessor: (row) => {
          return row.starttid ? moment(row.starttid).format("HH:mm") : ""
        },
        Header: "Start"
      },
      {
        accessor: (row) => {
          return row.sluttid ? moment(row.sluttid).format("HH:mm") : ""
        },
        Header: "Slut"
      },
      {
        accessor: "beskrivning",
        numeric: true,
        disablePadding: false,
        Header: "Beskrivning"
      },
      {
        accessor: "fordonStr",
        numeric: false,
        disablePadding: false,
        Header: "Fordon"
      },
      {
        accessor: "startplats",
        numeric: false,
        disablePadding: false,
        Header: "Startplats"
      },
      {
        accessor: "slutplats",
        numeric: false,
        disablePadding: false,
        Header: "Slutplats"
      },
      {
        accessor: "anmarkning",
        numeric: false,
        disablePadding: false,
        Header: "Notering"
      }
    ],
    []
  )
  function disableDays(date) {
    return date > moment().add(appContext.appUser.customer.bplDaysShown, "days")
  }
  return (
    <div>
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Mina uppdrag</Typography>
            {assignmentResult.status === 2 && (
              <BplTokenLink
                label="Visa uppdrag i BPL"
                to={`client/utskrift/specifikation.aspx?personalid=${appContext.appUser.profile.bplId}&datum=${date}`}
                variant="contained"
                color="primary"
              />
            )}
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <Box paddingX={3} paddingY={2}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={3}>
            <Grid item>
              <FormControlLabel
                label="Visa detaljer"
                control={<Switch onChange={() => showDetails(!details)} id="details" checked={details} />}
              />
            </Grid>
            <Grid item>
              {!details ? (
                <Tooltip title={"Du kan endast visa fram-/återkörningar om visa detaljer är aktiverat"}>
                  <FormControlLabel
                    disabled={!details}
                    label="Visa fram-/återkörning"
                    control={
                      <Switch
                        onChange={() => showDriveForwardAndReturn(!forwardAndReturn)}
                        id="forwardAndReturn"
                        checked={forwardAndReturn}
                      />
                    }
                  />
                </Tooltip>
              ) : (
                <FormControlLabel
                  disabled={!details}
                  label="Visa fram-/återkörning"
                  control={
                    <Switch
                      onChange={() => showDriveForwardAndReturn(!forwardAndReturn)}
                      id="forwardAndReturn"
                      checked={forwardAndReturn}
                    />
                  }
                />
              )}
            </Grid>
            <Grid item>
              <DatePicker
                value={date ? moment(date) : null}
                onChange={(newValue) => setDate(moment(newValue).format("YYYY-MM-DD"))}
                shouldDisableDate={disableDays}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </Box>

        {!details && (
          <ClpTable
            rows={assignmentResult.status === 2 && assignmentResult.data}
            status={assignmentResult.status}
            columns={columns}
            print
            printTitle="Uppdrag"
          />
        )}
        {details && (
          <ClpTable
            rows={assignmentResult.status === 2 && assignments}
            status={assignmentResult.status}
            columns={columnsDetails}
            print
            printTitle="Uppdrag"
          />
        )}
      </Box>
    </div>
  )
}

export default Schedule
