import { faChalkboardTeacher, faHandshake } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Container, Grid, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApiGet } from "auth/useApi2"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import { useContext, useEffect, useState } from "react"
import Moment from "react-moment"
import { Link, useHistory } from "react-router-dom"
import { SearchUserDialog } from "./pages/edit/components/SearchUserDialog"

export const booleanAsString = (value) => {
  switch (value) {
    case true:
      return "Ja"
    case false:
      return "Nej"
    default:
      return "Alla"
  }
}

const Customers = () => {
  const globalClasses = globalUseStyles()
  const routerHistory = useHistory()
  const appContext = useContext(AppContext)

  const [customersResult, getCustomers] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)
  const [openUserDialog, setOpenUserDialog] = useState(false)

  useEffect(() => {
    getCustomers(apiEndpoints.systemadmincustomers.listcustomers)
  }, [getCustomers])

  useEffect(() => {
    loadingContext.setLoading(() => customersResult.status === 1)
  })

  const columns = [
    {
      accessor: "name",
      numeric: false,
      Header: "Namn"
    },
    {
      accessor: "organisationNumber",
      numeric: true,
      disablePadding: false,
      Header: "Orgnr"
    },
    {
      accessor: "streetAddress",
      numeric: false,
      disablePadding: false,
      Header: "Postadress"
    },
    {
      accessor: "postalCode",
      numeric: true,
      disablePadding: false,
      Header: "Postnummer"
    },
    {
      accessor: "cityAddress",
      numeric: false,
      disablePadding: false,
      Header: "Ort"
    },
    {
      accessor: (row) => {
        return booleanAsString(row.isDemoAccount)
      },
      Cell: ({ cell }) => {
        return (
          cell.row.original.isDemoAccount && (
            <Box color={`primary.main`}>
              <FontAwesomeIcon icon={faChalkboardTeacher} />
              <Typography sx={{ marginLeft: "5px" }} variant="caption">
                Demo
              </Typography>
            </Box>
          )
        )
      },
      Header: "Demokonto",
      mainFilter: true,
      Filter: SelectColumnFilter,
      FilterHeader: "Demokonto",
      align: "center",
      FilterOptions: [
        {
          name: "Demokonto",
          value: "Ja"
        },
        {
          name: "Ej demokonto",
          value: "Nej"
        }
      ]
    },
    {
      accessor: "createdDate",
      Header: "Skapad den",
      Filter: SelectColumnFilter,
      Cell: ({ cell }) => {
        return (
          cell.row.original.createdDate && (
            <Moment format="YYYY-MM-DD">{cell.row.original.createdDate}</Moment>
          )
        )
      }
    }
  ]

  if (hasRole(appContext.appUser, allRoles.SystemAdminCustomers)) {
    columns.push(
      {
        accessor: (row) => {
          return booleanAsString(row.isPartner)
        },
        Cell: ({ cell }) => {
          return (
            cell.row.original.isPartner && (
              <Box color={`primary.main`}>
                <FontAwesomeIcon icon={faHandshake} />
                <Typography sx={{ marginLeft: "5px" }} variant="caption">
                  Partner
                </Typography>
              </Box>
            )
          )
        },
        Header: "Partner",
        mainFilter: true,
        Filter: SelectColumnFilter,
        FilterHeader: "Partner",
        align: "center",
        FilterOptions: [
          {
            name: "Partner",
            value: "Ja"
          },
          {
            name: "Ej partner",
            value: "Nej"
          }
        ]
      },
      {
        accessor: "partnerName",
        numeric: false,
        disablePadding: false,
        Header: "Skapad av"
      }
    )
  }
  return (
    <div>
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Administrera kunder</Typography>
            <Box>
              <Button component={Link} color="primary" to={allRoutes.SystemadminCustomersAdd()}>
                Lägg till ny kund
              </Button>
              <Button color="primary" onClick={() => setOpenUserDialog(true)} sx={{ marginLeft: "15px" }}>
                Sök användare
              </Button>
            </Box>
          </Grid>
        </Box>
      </Container>
      <SearchUserDialog open={openUserDialog} onClose={() => setOpenUserDialog(false)} />
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          rows={customersResult.status === 2 && customersResult.data}
          status={customersResult.status}
          columns={columns}
          rowProps={(row) => ({
            onClick: () => routerHistory.push(allRoutes.SystemadminCustomersEditCustomer(row.original.id))
          })}
        />
      </Box>
    </div>
  )
}

export default Customers
