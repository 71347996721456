import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faDownload, faEllipsis, faLayerGroup, faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Grid, IconButton, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import ApiButton from "components/Button"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import NoResultFound from "components/Dialogs/NoResultFound"
import UserAvatar from "components/UserAvatar"
import theme from "css/theme/light"
import { Field } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import Moment from "react-moment"
import { getFileType } from "../helpers/getFileType"
import { IDocumentAttachment } from "../interface/folder.interface"

const FileFormList = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setEditFile,
  editFile,
  fileUpdateResult,
  downloadUrl,
  deleteUrl
}: any) => {
  const [deleteFileResult, deleteFile] = useApi() as [IApiResult, IApiFunc]
  const [clickedFileId, setClickedFile] = useState<string | null>(null)
  const [deleteModal, setDeleteModal] = useState<any>({ isOpen: false, index: 0, attachment: {} })

  useEffect(() => {
    if (deleteFileResult.status === 2) {
      const newPreviewFiles = [...values.attachments]
      newPreviewFiles.splice(deleteModal.index, 1)
      setFieldValue("attachments", newPreviewFiles)
      setDeleteModal({ isOpen: false, index: 0, attachment: null })
    }
  }, [deleteFileResult])

  const removeFile = () => {
    deleteFile.del(deleteUrl.replace("{id}", deleteModal?.attachment?.id))
  }

  useEffect(() => {
    if (fileUpdateResult.status === 2) {
      handleClose()
      setEditFile(null)
    }
  }, [fileUpdateResult])

  const [getAttachmentResult, getAttachment] = useApi() as [IApiResult, IApiFunc]

  const downloadAttachment = React.useCallback(
    (fileId, fileName) => {
      getAttachment.getBlob(downloadUrl.replace("{id}", fileId), fileName)
    },
    [getAttachment]
  )

  const [menuProp, setAnchorEl] = React.useState<null | any>({ editId: null, anchorEl: null })
  const handleClickListItem = (editId: any, event: { currentTarget: any }): any => {
    setAnchorEl({
      editId: editId,
      anchorEl: event.currentTarget
    })
    console.log(menuProp)
  }

  const handleClose = () => {
    setAnchorEl({
      editId: null,
      anchorEl: null
    })
  }

  return (
    <>
      <div>
        {values.attachments && values.attachments.length > 0 ? (
          values.attachments.map((attachment: IDocumentAttachment, index: number) => (
            <Box gap={2} mb={2} borderBottom={1} px={2} borderColor="#EEEEEE">
              <Grid
                container
                direction="row"
                alignItems="center"
                paddingBottom={1}
                spacing={0}
                justifyContent="space-between"
                style={{ color: "inherit", textDecoration: "inherit" }}>
                <Grid item xs={12} md={4} lg={5}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <FontAwesomeIcon
                      color={theme.palette.primary["main"]}
                      icon={getFileType(attachment.fileId) as IconProp}
                    />
                    {editFile === index ? (
                      <Field name={`attachments.${index}.name`}>
                        {({ field, form: meta }: any) => (
                          <TextField
                            {...field}
                            autoComplete="off"
                            name={`attachments.${index}.name`}
                            fullWidth
                            id={`attachments.${index}.name`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={meta.touched && meta.error ? true : false}
                            helperText={meta.touched && meta.error ? meta.error : null}
                            variant="outlined"
                          />
                        )}
                      </Field>
                    ) : (
                      <Typography
                        display="inline-block"
                        variant="bodyMedium"
                        fontWeight="600"
                        sx={{
                          "&:hover": {
                            cursor: "pointer"
                          }
                        }}
                        onClick={() => {
                          setClickedFile(attachment.fileId)
                          downloadAttachment(attachment.id, attachment.fileId)
                        }}>
                        {attachment.name}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                {editFile !== index && (
                  <>
                    <Grid item xs={4} md={3} lg={2}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        {attachment.createdByUser?.name && (
                          <UserAvatar
                            label={attachment.createdByUser?.name}
                            size="sm"
                            style={{ marginRight: 0 }}
                          />
                        )}

                        <Typography variant="bodySmall">{attachment.createdByUser?.name}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                      <Typography variant="bodySmall" color="textSecondary">
                        {t(`documents.last_updated`)} <br />
                        {attachment.updatedDate && (
                          <Moment utc local format="YYYY-MM-DD">
                            {attachment.updatedDate}
                          </Moment>
                        )}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={4} sm={1}>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                    {editFile === index ? (
                      <>
                        <ApiButton result={fileUpdateResult} size="small" type="submit" variant="contained">
                          {t(`global.buttons.save`)}
                        </ApiButton>
                        <Button size="small" variant="outlined" onClick={() => setEditFile(null)}>
                          {t(`global.buttons.cancel`)}
                        </Button>
                      </>
                    ) : (
                      <>
                        <IconButton
                          edge="end"
                          type="button"
                          aria-label="Ladda ner"
                          onClick={() => {
                            setClickedFile(attachment.fileId)
                            downloadAttachment(attachment.id, attachment.fileId)
                          }}>
                          <FontAwesomeIcon
                            color={theme.palette.primary["main"]}
                            spin={getAttachmentResult.status === 1 && clickedFileId === attachment.fileId}
                            icon={
                              getAttachmentResult.status === 1 && clickedFileId === attachment.fileId
                                ? (faSpinnerThird as IconProp)
                                : (faDownload as IconProp)
                            }
                            size="xs"
                          />
                        </IconButton>
                        <IconButton
                          edge="end"
                          id={`lock-button`}
                          aria-haspopup={`listbox`}
                          aria-controls={`lock-menu`}
                          aria-label="when device is locked"
                          aria-expanded={menuProp.editId === index ? "true" : undefined}
                          onClick={(e) => handleClickListItem(index, e)}>
                          <FontAwesomeIcon
                            color={theme.palette.primary["main"]}
                            icon={faEllipsis as IconProp}
                            size="xs"
                          />
                        </IconButton>
                        <Menu
                          id={`lock-menu`}
                          anchorEl={menuProp.anchorEl}
                          open={menuProp.editId === index}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": `lock-button`,
                            role: `listbox`
                          }}>
                          <MenuItem
                            onClick={() => {
                              setEditFile(index)
                              handleClose()
                            }}>
                            {t(`documents.attachment.edit_file`)}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setDeleteModal({ isOpen: true, index: index, attachment: attachment })
                              handleClose()
                            }}>
                            {t(`documents.attachment.remove_file`)}
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <NoResultFound
            icon={faLayerGroup as IconProp}
            message={t(`documents.attachment.files_empty_admin`)}
            color="primary"
            size="lg"
          />
        )}
      </div>
      {deleteModal.isOpen && (
        <ContinueDialog
          result={deleteFileResult}
          setShowContinueModal={setDeleteModal}
          onAddSubmit={removeFile}
          values={values}
          message={`Är du säker på att du vill ta bort ${deleteModal.attachment.name}, ditt val går inte att ångra?`}
          continueBtnText="Ja, ta bort"
          cancelBtnText="Avbryt"
        />
      )}
    </>
  )
}
export default FileFormList
