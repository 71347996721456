import PublicIcon from "@mui/icons-material/Public";
import TrafficIcon from "@mui/icons-material/Traffic";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import React, { useContext } from "react";
import { MapContext } from "../MapContextProvider";
import { useStyles } from "./useStyles";

const MapLayerIcons = () => {
    const { setMapType } = useContext(MapContext);
    const classes = useStyles();

    return (
        <div className={classes.mapLayerIcons}>
            <IconButton edge="start" className={classes.icons} color="inherit" aria-label="menu" onClick={() => setMapType("satellite")}>
                <PublicIcon fontSize="medium" />
            </IconButton>
            <IconButton edge="start" className={classes.icons} color="inherit" aria-label="menu" onClick={() => setMapType("hybrid")}>
                <TrafficIcon fontSize="medium" />
            </IconButton>
            <IconButton edge="start" className={classes.icons} color="inherit" aria-label="menu" onClick={() => setMapType("roadmap")}>
                <VisibilityIcon fontSize="medium" />
            </IconButton>
        </div>
    );
};

export default MapLayerIcons;
