import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useContext, useEffect, useState } from "react"
import Moment from "react-moment"
import { NavLink } from "react-router-dom"

const ListFuelData = () => {
  const globalClasses = globalUseStyles()

  const loadingContext = useContext(GlobalLoaderContext)

  const [vehiclesResult, vehiclesFuncs] = useApi()
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [pageSize, setPageSizeManual] = useState(25)
  const [sortState, setSortState] = useState({
    sortby: "regNumber",
    direction: "asc"
  })
  useEffect(() => {
    vehiclesFuncs.get(
      apiEndpoints.vehicleregistryadmin.getimporttedrows
        .replace("{page}", page)
        .replace("{pagesize}", pageSize)
        .replace("{sortby}", sortState.sortby + " " + sortState.direction)
    )
  }, [vehiclesFuncs, page, pageSize, sortState.direction, sortState.sortby])

  useEffect(() => {
    if (vehiclesResult.status === 2) {
      setRows(vehiclesResult.data.results)
      setPageCount(vehiclesResult.data.pageCount)
    }
  }, [vehiclesResult])

  useEffect(() => {
    loadingContext.setLoading(() => vehiclesResult.status === 1)
  })

  const columns = [
    {
      accessor: "regNumber",
      Header: "Regnr"
    },
    {
      accessor: "internnumber",
      Header: "Internnr"
    },
    {
      accessor: "propellant",
      Header: "Drivmedel"
    },
    {
      accessor: "odometer",
      Header: "Mätarställning"
    },
    {
      accessor: "propellantVolume",
      Header: "Volym"
    },
    {
      accessor: "transactionTime",
      Header: "Transaktionsdatum",
      Cell: ({ cell }) => {
        return (
          <Moment format="YYYY-MM-DD HH:mm" globallocale="sv">
            {cell.row.original.transactionTime}
          </Moment>
        )
      }
    }
  ]
  return (
    <div>
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {" "}
            <Typography variant="h1">Tankfiler</Typography>
            <Button
              component={NavLink}
              to={allRoutes.VehicleregistryReportsFueldataImport()}
              color="primary"
              variant="contained">
              Importera tankfiler
            </Button>
          </Grid>
        </Box>{" "}
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          pageCount={pageCount}
          pageSize={pageSize}
          currentpage={page}
          totalPage={pageCount}
          manualPagination={true}
          manualSortBy={true}
          setPage={setPage}
          setPageSizeManual={setPageSizeManual}
          manualPageSize={pageSize}
          hideSearch={true}
          hideFilter={true}
          manualSetSortState={setSortState}
          manualSortState={sortState}
          rows={vehiclesResult.status === 2 && rows}
          status={vehiclesResult.status}
        />
      </Box>
    </div>
  )
}
export default ListFuelData
