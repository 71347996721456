import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import React from "react"
import { ExamFormContainer } from "./components/ExamForm/ExamFormContainer"

const ExamsExamination = ({ match }) => {
  const [examResult, examFuncs] = useApi()

  const loadingContext = React.useContext(GlobalLoaderContext)

  React.useEffect(() => {
    examFuncs.get(apiEndpoints.learning.getassignedexaminationdetails.replace("{id}", match.params.id))
  }, [examFuncs, match])

  React.useEffect(() => {
    loadingContext.setLoading(() => examResult.status === 1)
  })

  return (
    <div>
      {examResult.status === 2 && (
        <>
          <ExamFormContainer
            examinationId={examResult.data.examinationId}
            exam={examResult.data}
            examinationName={examResult.data.examinationName}
            examQuestionCount={examResult.data?.examinationQuestionCount}
          />
        </>
      )}
    </div>
  )
}

export default ExamsExamination
