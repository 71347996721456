import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faSquare, faSquareCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { IFormCheckboxProps } from "./formchecklist.interface"
import useStyles from "./useStyles"

const FormCheckbox: React.FC<IFormCheckboxProps> = (props) => {
  const classes = useStyles()

  const { id, labelKey, labelDefault, value, handleChange } = props
  const [checkedItem, setCheckedItem] = useState<boolean>(false)

  useEffect(() => {
    setCheckedItem(value)
  }, [value])
  const setCheckbox = () => {
    handleChange && handleChange(!checkedItem)
  }

  return (
    <FormControlLabel
      className={classes.buttonCheckbox}
      key={id}
      control={
        <Checkbox
          icon={<FontAwesomeIcon className={classes.uncheckedIcon} size="xl" icon={faSquare as IconProp} />}
          checkedIcon={<FontAwesomeIcon size="xl" icon={faSquareCheck as IconProp} />}
          checked={checkedItem}
          onChange={() => setCheckbox()}
          value={checkedItem}
        />
      }
      label={
        <Typography lineHeight={"120%"} variant="labelMedium" fontWeight={500}>
          {labelKey ? t(labelKey, labelDefault) : labelDefault}
        </Typography>
      }
    />
  )
}

export default FormCheckbox
