import React from "react";
import { ImportFormWizard } from "../../components/wizard/ImportFormWizard";
import { ImportFormStateProvider } from "../../components/wizard/ImportFormStateProvider";
const ImportVehicle = ({ match }) => {
    return (
        <div>
            <ImportFormStateProvider>
                <ImportFormWizard />
            </ImportFormStateProvider>
        </div>
    );
};
export default ImportVehicle;
