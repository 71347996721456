import { Box, Container, Grid, Typography } from "@mui/material"
import { StandardButton } from "components/Button"
import PaperBox from "components/PaperBox"
import { allRoutes } from "generated/routes"
import { NavLink } from "react-router-dom"

const UserSettingsPage = () => {
  return (
    <div>
      <Container>
        <Box marginY={3}>
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Användarinställningar</Typography>
          </Grid>
        </Box>
        <PaperBox>
          <Box mb={2}>
            <Typography>Om du behöver ändra ditt lösenord klicka på "Ändra lösenord"</Typography>
          </Box>
          <NavLink style={{ textDecoration: "none" }} to={allRoutes.UserSettingsPasswordchange()}>
            <StandardButton color="primary">Ändra lösenord</StandardButton>
          </NavLink>
        </PaperBox>
      </Container>
    </div>
  )
}
export default UserSettingsPage
