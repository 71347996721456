import { Autocomplete, BottomNavigation, FormControl, Grid, TextField, Typography } from "@mui/material"
import Button from "components/Button"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { useTranslation } from "react-i18next"
import { reasonOptions } from "../helpers/violationHelper"
import { IEditViolationProps, ISelectListItem } from "../violation.interface"

export const EditViolation: React.FC<IEditViolationProps> = (props) => {
  const { values, setFieldValue, drivingTypes, handleBlur, handleChange, touched, errors, result } = props
  const { t } = useTranslation("translation")

  return (
    <PaperBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12} marginBottom={2}>
            <Typography variant="h3">{t(`driver`, ``)}</Typography>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <FormControl fullWidth>
              <Autocomplete
                sx={{ width: "100%", background: theme.palette.background.paper }}
                options={drivingTypes || []}
                getOptionLabel={(option: ISelectListItem) => option?.name}
                value={drivingTypes?.find((x: ISelectListItem) => x.id === values.drivingTypeId) || null}
                onChange={(e, option) => setFieldValue("drivingTypeId", option?.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"drivingtype"}
                    variant="outlined"
                    defaultValue={values.drivingTypeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={t(`violation.driving_type`, ``)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <FormControl fullWidth>
              <Autocomplete
                sx={{ width: "100%", background: theme.palette.background.paper }}
                options={reasonOptions}
                getOptionLabel={(option: ISelectListItem) => option?.name}
                value={reasonOptions?.find((x: any) => x.name === values.reason) || null}
                onChange={(e, option) => setFieldValue("reason", option?.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"reason"}
                    variant="outlined"
                    defaultValue={values.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={t(`violation.reason`, ``)}
                    error={touched.reason && errors.reason ? true : false}
                    helperText={touched.reason && errors.reason ? errors.reason : null}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <TextField
              autoComplete="off"
              fullWidth
              id={"explaination"}
              value={values.explaination}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              label={t(`violation.other`, ``)}
              error={touched.explaination && errors.explaination ? true : false}
              helperText={touched.explaination && errors.explaination ? errors.explaination : null}
            />
          </Grid>
          <BottomNavigation sx={{ justifyContent: "flex-end", height: "auto" }}>
            <Button color="primary" variant="contained" type="submit" result={result}>
              {t(`global.buttons.save`, ``)}
            </Button>
          </BottomNavigation>
        </Grid>
      </Grid>
    </PaperBox>
  )
}
