import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import Button from "components/Button"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { Formik, FormikProps } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { useStyles } from "pages/violation/components/useStyles"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  IAdminSettingProps,
  IEmailListItem,
  ISelectListItem,
  IViolationEmailSettingsProps
} from "../violation.interface"

const AdminEmailSetting: React.FC<IAdminSettingProps> = (props) => {
  const { companyId } = props
  const [violationGetResult, violationGetApi] = useApi() as [IApiResult, IApiFunc]
  const [violationEmailSettingResult, violationEmailSettingApi] = useApi() as [IApiResult, IApiFunc]
  const [departmentResult, departmentApi] = useApi() as [IApiResult, IApiFunc]
  const [usersResult, getUsers] = useApi() as [IApiResult, IApiFunc]
  const formRef = useRef<FormikProps<any>>(null)
  const initialValues =
    violationGetResult.status === 2
      ? {
          ...violationGetResult.data
        }
      : {
          emails: [],
          departmentId: 0
        }

  const [selectedDepartment, setDepartment] = useState<number>()
  const [departments, setDepartments] = useState<ISelectListItem[] | undefined>()
  const [selectedReceivers, setSelectedReceiver] = useState<Array<IEmailListItem> | undefined>(
    initialValues.emails
  )
  const { t } = useTranslation("translation", { keyPrefix: "violation.admin" })

  useEffect(() => {
    getUsers.get(
      apiEndpoints.violationadmin.getuserstoassign.replace(
        "{departmentid}",
        selectedDepartment?.toString() || "0"
      )
    )
  }, [selectedDepartment])

  useEffect(() => {
    departmentApi.get(
      apiEndpoints.violationadmin.getavailabledepartments.replace("{companyId}", companyId.toString())
    )
  }, [])

  useEffect(() => {
    if (departmentResult.status === 2) {
      setDepartments(departmentResult.data)
    }
  }, [departmentResult])

  useEffect(() => {
    if (violationGetResult.status === 2) {
      formRef?.current?.setFieldValue("emails", violationGetResult.data?.emails || [])
    }
  }, [violationGetResult.status])

  useEffect(() => {
    violationGetApi.get(
      apiEndpoints.violationadmin.getviolationemailsettings.replace(
        "{departmentid}",
        selectedDepartment?.toString() || "0"
      )
    )
  }, [selectedDepartment])

  const handleSubmit = (values: IViolationEmailSettingsProps) => {
    const violationValues: IViolationEmailSettingsProps = {
      ...values,
      departmentId: selectedDepartment || 0
    }
    violationEmailSettingApi.post(apiEndpoints.violationadmin.postviolationemailsetting, violationValues)
  }

  const removeReceiver = (setFieldValue: any, values: IViolationEmailSettingsProps, index: number) => {
    let newReceivers = values?.emails
    newReceivers?.splice(index, 1)
    setFieldValue("emails", newReceivers)
  }

  const classes = useStyles()
  return (
    <PaperBox>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} innerRef={formRef}>
        {/* validationSchema={ViolationSettingSchema} */}
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs={12} mb={3}>
                <Grid item xs={12} marginBottom={2}>
                  <Typography variant="labelLarge" fontWeight={500}>
                    {t(`email_info_text`, ``)}
                  </Typography>
                </Grid>
                <List>
                  <Box bgcolor={theme.palette.grey[100]} padding={2} width="100%">
                    <Box className={classes.fullWidth}>
                      <ListItemText
                        classes={{
                          secondary: classes.listItemText,
                          primary: classes.listItemTextPrimary
                        }}
                        primary="Välj avdelning"
                        style={{ paddingBottom: "8px", marginTop: 5 }}
                      />
                    </Box>
                    <ListItem
                      style={{
                        marginBottom: 8,
                        border: `1px solid ${theme.palette.grey["100"]}`,
                        borderRadius: 4
                      }}>
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={11}>
                          <FormControl fullWidth>
                            <Autocomplete
                              sx={{ width: "100%", background: theme.palette.background.paper }}
                              options={departments || []}
                              getOptionLabel={(option: any) => option?.name}
                              value={departments?.find((x: any) => x.id === selectedDepartment) || null}
                              onChange={(e, option) => setDepartment(option?.id)}
                              renderInput={(params) => (
                                <TextField {...params} name={"reason"} variant="outlined" label="Avdelning" />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Box className={classes.fullWidth}>
                      <ListItemText
                        classes={{
                          secondary: classes.listItemText,
                          primary: classes.listItemTextPrimary
                        }}
                        primary="Lägg till mottagare"
                        style={{ paddingBottom: "8px", marginTop: 5 }}
                      />
                    </Box>
                    <ListItem
                      style={{
                        marginBottom: 8,
                        border: `1px solid ${theme.palette.grey["100"]}`,
                        borderRadius: 4
                      }}>
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={11}>
                          {usersResult.status === 2 && (
                            <Autocomplete
                              style={{ minWidth: 250, background: "white" }}
                              size="small"
                              id="reciever"
                              options={usersResult.data}
                              getOptionDisabled={(option: IEmailListItem) =>
                                values?.emails?.findIndex((x) => x?.userId === option.userId) !== -1
                              }
                              getOptionLabel={(option: IEmailListItem) => option?.name ?? ""}
                              onChange={(e, value) => {
                                console.log(value)
                                if (value !== null) {
                                  setSelectedReceiver((prev) => [...(prev ?? []), value])
                                }
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Stack direction="row" spacing={2} justifyContent="space-between">
                                    <Box sx={{ fontWeight: 600, minWidth: "100px" }}>{option.name}</Box>
                                    <Box sx={{ opacity: 0.6 }}>{option.email}</Box>
                                  </Stack>
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              )}
                            />
                          )}
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => {
                              setFieldValue("emails", selectedReceivers)
                            }}
                            color="primary">
                            <FontAwesomeIcon icon={faPlus} />
                          </IconButton>
                        </Grid>{" "}
                      </Grid>
                    </ListItem>
                    {(values?.emails?.length ?? 0) > 0 && (
                      <>
                        <ListItem
                          style={{
                            marginBottom: 8,

                            border: `1px solid ${theme.palette.grey["100"]}`,
                            borderRadius: 4
                          }}>
                          <Box gap={2} borderColor="#EEEEEE" width="100%">
                            {values?.emails?.map((data: IEmailListItem, index: number) => (
                              <Grid
                                key={index}
                                borderBottom={values.emails?.length ?? 0 - 1 !== index ? 1 : 0}
                                borderColor="#EEEEEE"
                                mb={values.emails?.length ?? 0 - 1 !== index ? 1 : 0}
                                pb={1}
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item xs={11}>
                                  <Typography>{data.name}</Typography>
                                  <Typography variant="bodySmall">{data.email}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    size="small"
                                    onClick={() => removeReceiver(setFieldValue, values, index)}
                                    color="primary">
                                    <FontAwesomeIcon icon={faTrash} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        </ListItem>
                      </>
                    )}
                  </Box>
                </List>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box pb={2} display="flex" justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  result={violationEmailSettingResult}>
                  Spara
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Formik>
    </PaperBox>
  )
}

export default AdminEmailSetting
