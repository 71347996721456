import { Box, FormControlLabel, Grid, Switch, TextField } from "@mui/material"

import { useApi } from "auth/useApi3"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PasswordSuggestion } from "./PasswordSuggestion"

export const UsernameField = ({
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  touched,
  errors,
  passwordSuggestEndpoint
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "settings.user" })
  const [userResult, userApi] = useApi()

  const usernameExist = (username) => {
    userApi.get(apiEndpoints.customeradmin.getaduser.replace("{username}", username))
  }

  useEffect(() => {
    if (userResult.status === 2) {
      setFieldValue("hasAdUserAlready", userResult.data.status === "UserExistsInAd")
      setFieldValue(
        "userExists",
        userResult.data.status === "UserExistsInTPAndAd" || userResult.data.status === "UserExistsInTP"
          ? true
          : false
      )
    }
  }, [userResult, setFieldValue])

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch onChange={handleChange} id="useUsername" checked={values.useUsername} />}
          label="Registrera användare utan E-post"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          fullWidth
          id={"username"}
          label={t(values.useUsername ? `username` : `email`, ``)}
          value={values.username}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e)
            usernameExist(values.username)
          }}
          error={touched.username && errors.username ? true : false}
          helperText={touched.username && errors.username ? errors.username : null}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <div hidden={values.userExists || values.hasAdUserAlready}>
          <PasswordSuggestion
            values={values}
            setFieldValue={setFieldValue}
            passwordSuggestEndpoint={passwordSuggestEndpoint}
          />
        </div>

        {userResult.data?.status === "UserExistsInAd" && (
          <Box
            my={2}
            padding={3}
            bgcolor="background.default"
            width={1}
            border={1}
            borderColor="primary.main"
            color="primary.dark"
            borderRadius={2}>
            Användare finns redan som kund tidigare, du behöver skapa upp den i din organisation men den
            behåller befintligt lösenord.
          </Box>
        )}

        {userResult.data?.status === "UserExistsInTP" ||
          (userResult.data?.status === "UserExistsInTPAndAd" && (
            <Box
              my={2}
              padding={3}
              bgcolor="error.light"
              width={1}
              border={1}
              borderColor="error.main"
              color="error.dark"
              borderRadius={2}>
              Användarnamnet finns redan. Kontakta support om användaren ska flyttas
            </Box>
          ))}
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              onChange={handleChange}
              id="sendEmailOnCreate"
              checked={values.sendEmailOnCreate && !values.useUsername}
              disabled={values.useUsername}
            />
          }
          label="Skicka e-post med inloggningsuppgifter"
        />
      </Grid>
    </>
  )
}
