import { faCloudUpload } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Typography } from "@mui/material"
import theme from "css/theme/light"
import { useEffect, useMemo, useState } from "react"
import { DropzoneState, useDropzone } from "react-dropzone"

import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { IFormUploadFileProps } from "./formuploadfile.interface"

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  padding: "20px",
  color: theme.palette.primary.dark,
  outline: "none",
  transition: "background .24s ease-in-out",

  cursor: "pointer",
  margin: "0 15px",
  borderRadius: 4
}

const activeStyle = {
  backgroundColor: "white"
}

const acceptStyle = {
  backgroundColor: "white"
}

const rejectStyle = {
  borderColor: "#ff1744"
}

const FormUploadFile = ({ handleChange }: IFormUploadFileProps) => {
  const [previewFiles, setPreviewFiles] = useState<File[]>([])
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }: DropzoneState =
    useDropzone({
      multiple: true,
      onDrop: (acceptedFiles: File[]) => {
        setPreviewFiles(acceptedFiles)
        // handleChange && handleChange(acceptedFiles)
      }
    })
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  useEffect(() => {
    if (previewFiles && previewFiles.length > 0) {
      handleChange && handleChange(previewFiles)
    }
    return () => {
      // Cleanup function here if needed
    }
  }, [previewFiles])

  return (
    <>
      <Box
        bgcolor="primary.light"
        border="2px dashed"
        borderColor="primary.dark"
        borderRadius={2}
        marginBottom={1}>
        <Box py={2} {...getRootProps({ style })} flexDirection="column">
          <input {...getInputProps()} />
          <FontAwesomeIcon icon={faCloudUpload as IconProp} color={theme.palette.primary["dark"]} size="3x" />
          <Typography variant="bodyMedium" fontWeight="600">
            Släpp eller klicka här för att välja filer
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default FormUploadFile
