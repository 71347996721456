import { Box, Container, Grid, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import { MonthPicker } from "pages/planning/components/MonthPicker"
import { YearPicker } from "pages/planning/components/YearPicker"
import React, { useContext, useEffect, useMemo, useState } from "react"

const Schedule = () => {
  const globalClasses = globalUseStyles()

  const [scheduleResult, getSchedule] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)

  const [year, setYear] = useState(moment())
  const [month, setMonth] = useState(moment().format("MM"))

  useEffect(() => {
    getSchedule(
      apiEndpoints.planning.getschedule
        .replace("{year}", moment(year).format("YYYY"))
        .replace("{month}", month)
    )
  }, [getSchedule, year, month])

  useEffect(() => {
    loadingContext.setLoading(() => scheduleResult.status === 1)
  })

  const columns = useMemo(
    () => [
      {
        accessor: (row) => {
          return row.starttid ? moment(row.starttid).format("YYYY-MM-DD") : ""
        },
        Header: "Datum"
      },
      {
        accessor: "starttidDayName",
        numeric: true,
        disablePadding: false,
        Header: "Dag"
      },
      {
        accessor: (row) => {
          return row.starttid ? moment(row.starttid).format("HH:mm") : ""
        },
        Header: "Starttid"
      },
      {
        accessor: (row) => {
          return row.sluttid ? moment(row.sluttid).format("HH:mm") : ""
        },
        Header: "Sluttid"
      },
      {
        accessor: "beskrivning",
        numeric: false,
        disablePadding: false,
        Header: "Tjänst"
      }
    ],
    []
  )

  return (
    <div>
      <Container>
        <Box paddingX={3} paddingY={2}>
          <Typography variant="h1">Schema</Typography>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <Box paddingX={3} paddingY={2}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={3}>
            <Grid item>
              <YearPicker year={year} setYear={setYear} />
            </Grid>
            <Grid item>
              <MonthPicker month={month} setMonth={setMonth} />
            </Grid>
          </Grid>
        </Box>

        <ClpTable
          rows={scheduleResult.status === 2 && scheduleResult.data}
          status={scheduleResult.status}
          columns={columns}
          print
          printTitle="Schema"
        />
      </Box>
    </div>
  )
}

export default Schedule
