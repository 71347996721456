import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faFolder } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { AppContext } from "components/AppContextProvider"
import BodyRichContent from "components/Fields/BodyRichContent"
import LoadingPage from "components/Loading/LoadingPage"
import PaperBox from "components/PaperBox"
import { PageTabs } from "components/navigation/PageTabs"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import { t as globalT } from "i18next"
import FileList from "pages/documents/components/FileList"
import { useStyles } from "pages/documents/components/useStyles"
import { IDocumentFolder, IDocumentFormValues } from "pages/documents/interface/folder.interface"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink, useHistory } from "react-router-dom"

const DetailsFolder = ({ match }: any) => {
  const { t } = useTranslation("translation", { keyPrefix: "documents" })
  const appContext = useContext(AppContext)
  const classes = useStyles()
  const [folderResult, getFolderApi] = useApi() as [IApiResult, IApiFunc]
  const [deleteResult, deleteFolder] = useApi() as [IApiResult, IApiFunc]
  const [showContinueModal, setShowContinueModal] = useState(false)
  const [updateFolderResult, updateFolder] = useApi() as [IApiResult, IApiFunc]
  const [foldersResult, listFoldersApi] = useApi() as [IApiResult, IApiFunc]
  const [initialValues, setInitialValues] = useState<IDocumentFormValues>()
  const routerHistory = useHistory()

  const handleSubmit = (values: IDocumentFormValues) => {
    updateFolder.put(apiEndpoints.documents.updatedocument, values)
  }

  useEffect(() => {
    listFoldersApi.get(apiEndpoints.documents.listfolders)
  }, [listFoldersApi])

  useEffect(() => {
    getFolderApi.get(apiEndpoints.documents.getdocument.replace("{id}", match.params.id))
  }, [getFolderApi, match])

  useEffect(() => {
    if (folderResult.status === 2) {
      setInitialValues(folderResult.data)
    }
  }, [folderResult])

  useEffect(() => {
    if (updateFolderResult.status === 2) {
      getFolderApi.get(apiEndpoints.documents.getdocument.replace("{id}", match.params.id))
    }
  }, [updateFolderResult])

  useEffect(() => {
    if (deleteResult.status === 2) {
      routerHistory.push(allRoutes.Documents())
    }
  }, [deleteResult])

  const removeFile = () => {
    deleteFolder.del(apiEndpoints.documents.deletedocument.replace("{id}", match.params.id))
  }

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.Documents(),
            title: globalT(`global.buttons.back`)
          }
        ]}
      />
      <Container>
        <Grid container direction="row" justifyContent="space-between" spacing={3}>
          <Grid item xs={12} textAlign="right">
            {hasRole(appContext.appUser, allRoles.DocumentsAdmin) && (
              <Button variant="contained" component={NavLink} to={allRoutes.DocumentsEdit(match.params.id)}>
                Redigera mapp
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" spacing={3} marginBottom={3}>
          <Grid item xs={12} md={4} lg={3}>
            <PaperBox padding={1} marginTop={0}>
              {foldersResult.status === 2 &&
                foldersResult?.data?.map((folder: IDocumentFolder, index: number) => {
                  return (
                    <Box gap={2} mb={foldersResult.data.length - 1 !== index ? 1 : 0}>
                      <NavLink
                        style={{ color: "inherit", textDecoration: "inherit" }}
                        className={classes.folderFrame}
                        to={allRoutes.DocumentsDetails(folder.id)}
                        isActive={(match, location): any => {
                          if (!match) {
                            return false
                          }
                          return true
                        }}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          justifyContent="space-between">
                          <Grid item xs={8}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <FontAwesomeIcon
                                color={theme.palette.warning["main"]}
                                icon={faFolder as IconProp}
                              />
                              <Typography display="inline-block" variant="bodyMedium" fontWeight="600">
                                {folder.name}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={4} textAlign="right">
                            <Typography variant="bodySmall">
                              {folder.totalAttachments} {t(`list.files`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </NavLink>
                    </Box>
                  )
                })}
            </PaperBox>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            {folderResult.status === 1 ? (
              <Container>
                <LoadingPage />
              </Container>
            ) : (
              folderResult.status === 2 && (
                <Box>
                  <Typography variant="h1">{folderResult.data?.name}</Typography>
                  <Typography variant="bodyMedium" color="textSecondary">
                    {folderResult.data?.description}
                  </Typography>
                  <BodyRichContent content={folderResult.data?.body} />
                  <PaperBox>
                    <FileList
                      attachments={initialValues?.attachments}
                      downloadUrl={apiEndpoints.documents.getattachment}
                    />
                  </PaperBox>
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default DetailsFolder
