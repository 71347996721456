import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import { RouterPrompt } from "components/RouterPrompt"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import theme from "css/theme/light"
import { Formik, useFormikContext } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useContext, useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import { Sidebar } from "./Sidebar"
import { useStyles } from "./useStyles"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Namn är obligatoriskt"),
  type: Yup.string().required("Typ är obligatoriskt"),
  active: Yup.bool(),
  notes: Yup.string().nullable().max(200, "Du kan max ange 200 tecken"),
  dateId: Yup.string().when("isReminderDate", {
    is: true,
    then: (schema) => schema.required("Måste ange fält"),
    otherwise: (schema) => schema.nullable()
  }),
  timeInterval: Yup.number().when("isReminderDate", {
    is: true,
    then: (schema) => schema.integer("Måste vara heltal").min(1, "Antal dagar måste minst vara 1").required("Dagar är obligatoriskt"),
    otherwise: (schema) => schema.nullable()
  }),
  numberId: Yup.string().when("isReminderNumber", {
    is: true,
    then: (schema) => schema.required("Måste ange fält"),
    otherwise: (schema) => schema.nullable()
  }),
  numberInterval: Yup.number().when("isReminderNumber", {
    is: true,
    then: (schema) => schema.integer("Måste vara heltal").min(1, "Nummervärde måste minst vara 1").required("Nummervärde är obligatoriskt"),
    otherwise: (schema) => schema.nullable()
  })
})

const NameTextField = () => {
  const { errors, handleBlur, handleChange, touched, values } = useFormikContext()
  const classes = useStyles()

  return (
    <ListItem className={classes.listItem}>
      <Box className={classes.fullWidth}>
        <ListItemText
          classes={{
            secondary: classes.listItemText,
            primary: classes.listItemTextPrimary
          }}
          primary="Namn"
          style={{ paddingBottom: "8px" }}
        />
      </Box>
      <TextField
        autoComplete="off"
        className={classes.textField}
        fullWidth
        id="name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.name && errors.name ? true : false}
        helperText={touched.name && errors.name ? errors.name : null}
        variant="outlined"
        InputProps={{
          classes: { input: classes.formAreaInput },
          disableUnderline: true
        }}
      />
    </ListItem>
  )
}

const NoteTextField = () => {
  const { errors, handleBlur, handleChange, touched, values } = useFormikContext()
  const classes = useStyles()

  return <ListItem className={classes.listItem}>
    <Box className={classes.fullWidth}>
      <ListItemText
        classes={{
          secondary: classes.listItemText,
          primary: classes.listItemTextPrimary
        }}
        primary="Notering"
        style={{ paddingBottom: "8px" }}
      />
    </Box>
    <TextField
      multiline={true}
      rows={3}
      autoComplete="off"
      className={classes.textField}
      fullWidth
      id="notes"
      value={values.notes}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched.notes && errors.notes ? true : false}
      helperText={touched.notes && errors.notes ? errors.notes : null}
      variant="outlined"
      InputProps={{
        classes: { input: classes.formAreaInput },
        disableUnderline: true
      }}
    />
  </ListItem>
}

const MandatorySwitch = () => {
  const { setFieldValue, values } = useFormikContext()
  const classes = useStyles()

  return (
    <ListItem className={classes.listItem}>
      <FormControlLabel
        label="Obligatorisk"
        control={
          <Switch
            disabled={values.type === "Boolean"}
            onChange={(item) => {
              const checked = item.target.checked
              setFieldValue("isRequired", checked)
            }}
            checked={values.isRequired}
          />
        }
      />
    </ListItem>
  )
}

const ActiveSwitch = () => {
  const { setFieldValue, values } = useFormikContext()
  const classes = useStyles()

  return <ListItem className={classes.listItem}>
    <FormControlLabel
      label="Aktiv"
      control={
        <Switch
          onChange={(item) => {
            const checked = item.target.checked
            setFieldValue("active", checked)
          }}
          checked={values.active}
        />
      }
    />
  </ListItem>
}

const CategoryDropdown = () => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } = useFormikContext()
  const classes = useStyles()

  const categories = [
    { name: "Ingen" },
    { name: "Service" },
    { name: "Besiktning" }
  ]

  return (
    <ListItem className={classes.listItem}>
      <Box className={classes.fullWidth}>
        <ListItemText
          classes={{
            secondary: classes.listItemText,
            primary: classes.listItemTextPrimary
          }}
          style={{ paddingBottom: "8px" }}
          primary={"Kategori"}
        />
      </Box>

      <Autocomplete
        className={classes.fullWidth}
        id="category"
        options={categories}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        value={categories.find((i) => i.name === values.category) || ""}
        getOptionSelected={(option, value) => {
          return option.name === value.name
        }}
        onChange={(e, value) => {
          setFieldValue("category", value?.name)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            name="category"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.category && errors.category ? true : false}
            helperText={touched.category && errors.category ? errors.category : null}
          />
        )}
      />
    </ListItem>
  )
}

const TypeDropdown = ({ editMode }) => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } = useFormikContext()
  const classes = useStyles()

  const customFieldTypes = [
    {
      name: "Välj typ",
      id: ""
    },
    {
      name: "Nummer",
      id: "Numeric"
    },
    {
      name: "Datum",
      id: "Date"
    },
    {
      name: "Textfält",
      id: "Text"
    },
    {
      name: "Ja/Nej",
      id: "Boolean"
    },
    {
      name: "Påminnelse",
      id: "Notification"
    },
    {
      name: "Repeterande påminnelse",
      id: "RepeatedNotification"
    }
  ]

  return (
    <ListItem className={classes.listItem}>
      <Box className={classes.fullWidth}>
        <ListItemText
          classes={{
            secondary: classes.listItemText,
            primary: classes.listItemTextPrimary
          }}
          style={{ paddingBottom: "8px" }}
          primary="Typ"
        />
      </Box>

      <Autocomplete
        disabled={editMode}
        className={classes.fullWidth}
        id="status"
        options={customFieldTypes}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        value={customFieldTypes.find((i) => i.id === values.type) || ""}
        getOptionSelected={(option, value) => {
          return option.id === value.id
        }}
        onChange={(e, value) => {
          setFieldValue("type", value?.id)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            name="type"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.type && errors.type ? true : false}
            helperText={touched.type && errors.type ? errors.type : null}
          />
        )}
      />
    </ListItem>
  )
}

const TriggerSetting = ({ checkboxId, dropdownId, reminderInterval, triggerFieldList }) => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } = useFormikContext()
  const classes = useStyles()
  const dropdownTitle = checkboxId === "isReminderDate" ? "Datum" : "Nummer"
  const unitSuffix = checkboxId === "isReminderDate" ? "dagar" : ""

  return (
    <ListItem className={classes.listItem}>
      <Box className={classes.fullWidth} sx={{ alignItems: "end", marginLeft: "-10px" }}>
        <Checkbox
          id={checkboxId}
          checked={values[checkboxId]}
          onChange={handleChange}
          color="primary"
        />
        <ListItemText
          classes={{
            secondary: classes.listItemText,
            primary: classes.listItemTextPrimary
          }}
          style={{ paddingBottom: "8px" }}
          primary={dropdownTitle}
        />
      </Box>

      <Autocomplete
        disabled={!values[checkboxId]}
        style={{ background: theme.background.paper }}
        className={classes.fullWidth}
        id={dropdownId}
        options={triggerFieldList}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        value={triggerFieldList.find((i) => i.id === values[dropdownId]) || ""}
        getOptionSelected={(option, value) => {
          return option.id === value.id
        }}
        onChange={(e, value) => {
          setFieldValue(dropdownId, value?.id)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            name={dropdownId}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched[dropdownId] && errors[dropdownId] ? true : false}
            helperText={touched[dropdownId] && errors[dropdownId] ? errors[dropdownId] : null}
          />
        )}
      />
      <ListItemText
        classes={{
          secondary: classes.listItemText,
          primary: classes.listItemTextPrimary
        }}
        primary="Antal"
        style={{ paddingTop: "4px" }}
      />
      <Box className={classes.fullWidth} sx={{ display: "flex", alignItems: "baseline" }}>
        <TextField
          disabled={!values[checkboxId]}
          autoComplete="off"
          id={reminderInterval}
          type="number"
          value={values[reminderInterval]}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched[reminderInterval] && errors[reminderInterval] ? true : false}
          helperText={touched[reminderInterval] && errors[reminderInterval] ? errors[reminderInterval] : null}
          variant="outlined"
          InputProps={{
            classes: { input: classes.formAreaInput },
            disableUnderline: true
          }}
          sx={{
            backgroundColor: "white",
            width: "30%",
            "& .MuiInputBase-root": {
              height: "36px"
            }
          }}
        />
        <Typography sx={{ paddingLeft: "8px", color: "grey" }}>{unitSuffix}</Typography>
        {values.type === "Notification" && (
          <Box ml={2.5} position="relative" top="-5px">
            <RadioGroup
              aria-label={"interval-type"}
              name={reminderInterval + "BeforeAfter"}
              value={values[reminderInterval + "BeforeAfter"]}
              onChange={(event) => {
                setFieldValue(reminderInterval + "BeforeAfter", event.target.value)
              }}
            >
              <FormControlLabel  sx={{ height: "24px" }} value="before" control={<Radio disabled={!values[checkboxId]} />} label="före" />
              <FormControlLabel sx={{ height: "24px" }} value="after" control={<Radio disabled={!values[checkboxId]} />} label="efter" />
            </RadioGroup>
          </Box>
        )}
      </Box>
    </ListItem>
  )
}

const NotificationForm = ({ usersResult, selectedReceivers, setSelectedReceiver, removeReceiver }) => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } = useFormikContext()
  const [dateFieldsResult, dateFieldApi] = useApi()
  const [numberFieldsResult, numberFieldApi] = useApi()
  const [dateFields, setDateFields] = useState([])
  const [numberFields, setNumberFields] = useState([])
  const categories = {
    "Besiktning": 0,
    "Service": 1,
  }

  const classes = useStyles()

  useEffect(() => dateFieldApi.get(apiEndpoints.vehicleregistryadmin.getdatetimefields), [])
  useEffect(() => numberFieldApi.get(apiEndpoints.vehicleregistryadmin.getnumberfields), [])

  useEffect(() => {
    if (dateFieldsResult.status !== 2) return
    setDateFields(dateFieldsResult.data)
  }, [dateFieldsResult, values.category])

  useEffect(() => {
    if (numberFieldsResult.status !== 2) return
    if (values.category === "Service") {
      setNumberFields(numberFieldsResult.data.filter(numberField => numberField.category === categories["Service"]))
    } else {
      setNumberFields(numberFieldsResult.data)
    }
  }, [numberFieldsResult, values.category])

  return (
    <ListItem className={classes.listItem}>
      <Box bgcolor={theme.palette.grey[100]} padding={2} width="100%">
        <Box className={classes.fullWidth}>
          <ListItemText
            classes={{
              secondary: classes.listItemText,
              primary: classes.listItemTextPrimary
            }}
            style={{ paddingBottom: "8px" }}
            primary={"Triggerinställningar"}
          />
        </Box>
        <TriggerSetting
          checkboxId="isReminderDate"
          dropdownId="dateId"
          reminderInterval="timeInterval"
          triggerFieldList={dateFields}
        />
        {values.category === "Service" && (
          <TriggerSetting
            checkboxId="isReminderNumber"
            dropdownId="numberId"
            reminderInterval="numberInterval"
            triggerFieldList={numberFields}
          />
        )}
        <ListItem className={classes.listItem}>
          <FormControlLabel
            label="Visa i kalender"
            control={
              <Switch
                onChange={(item) => {
                  const checked = item.target.checked
                  setFieldValue("showInCalendar", checked)
                }}
                checked={values.showInCalendar}
              />
            }
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <FormControlLabel
            label="Skicka mejlavvisering"
            control={
              <Switch
                onChange={(item) => {
                  const checked = item.target.checked
                  setFieldValue("sendNotification", checked)
                }}
                checked={values.sendNotification}
              />
            }
          />
        </ListItem>
        {values.sendNotification && (
          <>
            {" "}
            <Box className={classes.fullWidth}>
              <ListItemText
                classes={{
                  secondary: classes.listItemText,
                  primary: classes.listItemTextPrimary
                }}
                primary="Lägg till mottagare"
                style={{ paddingBottom: "8px", marginTop: 5 }}
              />
            </Box>
            <ListItem
              style={{
                marginBottom: 8,
                border: `1px solid ${theme.palette.grey["100"]}`,
                borderRadius: 4
              }}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={11}>
                  {usersResult.status === 2 && (
                    <Autocomplete
                      style={{ minWidth: 250, background: "white" }}
                      size="small"
                      id="reciever"
                      options={usersResult.data}
                      getOptionDisabled={(option) =>
                        selectedReceivers.findIndex((x) => x.userId === option.userId) !== -1
                      }
                      getOptionLabel={(option) => (option.name ? option.name : null)}
                      getOptionSelected={(option, value) => {
                        return option.userId === value.userId
                      }}
                      onChange={(e, value) => {
                        if (value !== null) {
                          setSelectedReceiver((prev) => [...prev, value])
                        }
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Stack direction="row" spacing={2} justifyContent="space-between">
                            <Box sx={{ fontWeight: 600, minWidth: "100px" }}>{option.name}</Box>
                            <Box sx={{ opacity: 0.6 }}>{option.email}</Box>
                          </Stack>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      setFieldValue("recipients", selectedReceivers)
                    }}
                    color="primary">
                    <FontAwesomeIcon icon={faPlus} />
                  </IconButton>
                </Grid>{" "}
              </Grid>
            </ListItem>
            {values?.recipients?.length > 0 && (
              <>
                <ListItem
                  style={{
                    marginBottom: 8,
                    border: `1px solid ${theme.palette.grey["100"]}`,
                    borderRadius: 4
                  }}>
                  <Box gap={2} borderColor="#EEEEEE" width="100%">
                    {values?.recipients?.map((data, index) => (
                      <Grid
                        key={index}
                        borderBottom={selectedReceivers.length - 1 !== index ? 1 : 0}
                        borderColor="#EEEEEE"
                        mb={selectedReceivers.length - 1 !== index ? 1 : 0}
                        pb={1}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item xs={11}>
                          <Typography>{data.name}</Typography>
                          <Typography variant="bodySmall">{data.email}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            size="small"
                            onClick={() => removeReceiver(setFieldValue, values, index)}
                            color="primary">
                            <FontAwesomeIcon variant="contained" icon={faTrash} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </ListItem>{" "}
              </>
            )}
          </>
        )}
      </Box>
    </ListItem>
  )
}

const DateForm = ({ usersResult, selectedReceivers, setSelectedReceiver, removeReceiver }) => {
  const { values, setFieldValue, handleChange, handleBlur } = useFormikContext()

  const classes = useStyles()

  return <ListItem className={classes.listItem}>
    <FormControlLabel
      label="Skicka mailavisering"
      control={
        <Switch
          onChange={(item) => {
            const checked = item.target.checked
            setFieldValue("sendNotification", checked)
          }}
          checked={values.sendNotification}
        />
      }
    />

    {values.sendNotification && (
      <Box bgcolor={theme.palette.grey[100]} padding={2} width="100%">
        <Box className={classes.fullWidth}>
          <ListItemText
            classes={{
              secondary: classes.listItemText,
              primary: classes.listItemTextPrimary
            }}
            primary="Lägg till mottagare"
            style={{ paddingBottom: "8px", marginTop: 5 }}
          />
        </Box>
        <ListItem
          style={{
            marginBottom: 8,
            border: `1px solid ${theme.palette.grey["100"]}`,
            borderRadius: 4
          }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={11}>
              {usersResult.status === 2 && (
                <Autocomplete
                  style={{ minWidth: 250, background: "white" }}
                  size="small"
                  id="reciever"
                  options={usersResult.data}
                  getOptionDisabled={(option) =>
                    selectedReceivers.findIndex((x) => x.userId === option.userId) !== -1
                  }
                  getOptionLabel={(option) => (option.name ? option.name : null)}
                  getOptionSelected={(option, value) => {
                    return option.userId === value.userId
                  }}
                  onChange={(e, value) => {
                    if (value !== null) {
                      setSelectedReceiver((prev) => [...prev, value])
                    }
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Stack direction="row" spacing={2} justifyContent="space-between">
                        <Box sx={{ fontWeight: 600, minWidth: "100px" }}>{option.name}</Box>
                        <Box sx={{ opacity: 0.6 }}>{option.email}</Box>
                      </Stack>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => {
                  setFieldValue("recipients", selectedReceivers)
                }}
                color="primary">
                <FontAwesomeIcon icon={faPlus} />
              </IconButton>
            </Grid>{" "}
          </Grid>
        </ListItem>
        {values?.recipients?.length > 0 && (
          <>
            <ListItem
              style={{
                marginBottom: 8,

                border: `1px solid ${theme.palette.grey["100"]}`,
                borderRadius: 4
              }}>
              <Box gap={2} borderColor="#EEEEEE" width="100%">
                {values?.recipients?.map((data, index) => (
                  <Grid
                    key={index}
                    borderBottom={selectedReceivers.length - 1 !== index ? 1 : 0}
                    borderColor="#EEEEEE"
                    mb={selectedReceivers.length - 1 !== index ? 1 : 0}
                    pb={1}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={11}>
                      <Typography>{data.name}</Typography>
                      <Typography variant="bodySmall">{data.email}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        onClick={() => removeReceiver(setFieldValue, values, index)}
                        color="primary">
                        <FontAwesomeIcon variant="contained" icon={faTrash} />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </ListItem>{" "}
          </>
        )}
      </Box>
    )}
  </ListItem>
}

const IntervalTextField = () => {
  const { values, setFieldValue, handleChange, touched, handleBlur, errors } = useFormikContext()
  const [categoryType, setCategoryType] = useState()
  const [unitDescription, setUnitDescription] = useState()

  useEffect(() => {
    let categoryType
    if (values.type === "Numeric") {
      categoryType = "numberInterval"
      setUnitDescription("service intervall")
    } else {
      categoryType = "timeInterval"
      setUnitDescription("dagar mellan service")
    }
    setCategoryType(categoryType)
  }, [values.category, values.type])
  const classes = useStyles()
  
  return (
    <Box
      className={classes.fullWidth} sx={{ display: "flex", alignItems: "baseline" }}>
      <TextField
        autoComplete="off"
        id={categoryType}
        type="number"
        value={values[categoryType]}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched[categoryType] && errors[categoryType] ? true : false}
        helperText={touched[categoryType] && errors[categoryType] ? errors[categoryType] : null}
        variant="outlined"
        InputProps={{
          classes: { input: classes.formAreaInput },
          disableUnderline: true
        }}
        sx={{
          backgroundColor: "white",
          width: "30%",
          "& .MuiInputBase-root": {
            height: "36px"
          }
        }}
      />
      <Typography sx={{ paddingLeft: "8px", color: "grey" }}>{unitDescription}</Typography>
    </Box>
  )
}

const CustomFieldForm = (props) => {
  const appContext = useContext(AppContext)
  const isAdmin = hasRole(appContext.appUser)

  const {
    showSidebar,
    setShowSidebarForm,
    editItemResult,
    addResult,
    updateResult,
    editMode,
    onAddSubmit,
    onUpdateSubmit
  } = props
  const [usersResult, getUsers] = useApi()
  useEffect(() => {
    getUsers.get(apiEndpoints.vehicleregistryadmin.getuserstoassign)
  }, [])
  const classes = useStyles()
  const formRef = useRef()

  const initialValues =
    editMode && editItemResult.status === 2
      ? {
        ...editItemResult.data,
        timeInterval: editItemResult.data?.timeInterval ? Math.abs(editItemResult.data.timeInterval) : null,
        numberInterval: editItemResult.data?.numberInterval ? Math.abs(editItemResult.data.numberInterval) : null,
        timeIntervalBeforeAfter: editItemResult.data.timeInterval > 0 ? "after" : "before",
        numberIntervalBeforeAfter: editItemResult.data.numberInterval > 0 ? "after" : "before",
        isReminderDate: (editItemResult.data.dateId && editItemResult.data.timeInterval) ? true : false,
        isReminderNumber: (editItemResult.data.numberId && editItemResult.data.numberInterval) ? true : false
      }
      : {
        id: 0,
        name: "",
        type: "",
        active: true,
        isRequired: false,
        sendNotification: false,
        notes: "",
        recipients: [],
        isReminderDate: false,
        timeInterval: 0,
        timeIntervalBeforeAfter: "before",
        isReminderNumber: false,
        numberInterval: 0,
        numberIntervalBeforeAfter: "before"
      }

  const handleSubmit = (values) => {
    let filteredValues = { ...values }
    switch (values.type) {
      case "Numeric":
        delete filteredValues.timeInterval
        break
      case "Date":
        delete filteredValues.numberInterval
        break
      case "Notification":
      case "RepeatedNotification":
        if (!values.isReminderDate) {
          delete filteredValues.dateId
          delete filteredValues.timeInterval
        }
        if (!values.isReminderNumber) {
          delete filteredValues.numberId
          delete filteredValues.numberInterval
        }
        if (values.numberInterval && values.numberIntervalBeforeAfter === "before") {
          filteredValues.numberInterval = -filteredValues.numberInterval
        }
        if (values.timeInterval && values.timeIntervalBeforeAfter === "before") {
          filteredValues.timeInterval = -filteredValues.timeInterval
        }
        break
    }
    if (editMode) {
      onUpdateSubmit(filteredValues)
    } else {
      onAddSubmit(filteredValues)
    }
  }
  useEffect(() => {
    if (!showSidebar) {
      formRef.current.resetForm()
      setSelectedReceiver([])
    }
  }, [showSidebar])
  useEffect(() => {
    if (editItemResult.status === 2) {
      setSelectedReceiver(editItemResult.data?.recipients)
    }
  }, [editItemResult.status])

  const buttonFeedbackResult = editMode ? updateResult : addResult
  const [selectedReceivers, setSelectedReceiver] = useState(initialValues.recipients)

  const removeReceiver = (setFieldValue, values, index) => {
    let newReceivers = values.recipients
    newReceivers.splice(index, 1)
    setFieldValue("recipients", newReceivers)
  }

  return (
    <Sidebar showSidebar={showSidebar}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formRef}>
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            handleSubmit,
            setFieldValue
          }) => (
          <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
            <RouterPrompt />
            <List className={classes.vehicleList}>
              <CloseSidebarButton
                toggleMenu={() => {
                  setShowSidebarForm(!showSidebar)
                }}
              />
              <NameTextField />
              <NoteTextField />
              <MandatorySwitch />
              <ActiveSwitch />
              <CategoryDropdown />
              <TypeDropdown editMode={editMode} />
              {values.category === "Service" && (values.type === "Numeric" || values.type === "Date") && (
                <IntervalTextField  />
              )}
              {(values.type === "RepeatedNotification" || values.type === "Notification") &&
                <NotificationForm
                  usersResult={usersResult}
                  selectedReceivers={selectedReceivers}
                  setSelectedReceiver={setSelectedReceiver}
                  removeReceiver={removeReceiver}
                />
              }
              {values.type === "Date" &&
                <DateForm
                  usersResult={usersResult}
                  selectedReceivers={selectedReceivers}
                  setSelectedReceiver={setSelectedReceiver}
                  removeReceiver={removeReceiver}
                />
              }
              <Box pt={3} pb={5}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  result={buttonFeedbackResult}
                >
                  {editMode ? "Spara fält" : "Lägg till fält"}
                </Button>
              </Box>
            </List>
          </form>
        )}
      </Formik>
    </Sidebar>
  )
}

export default CustomFieldForm