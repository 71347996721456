import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import ClpButton from "components/Button"
import React from "react"

const ExternalSendButton = ({ buttonFeedbackResult, onAddSubmit }) => {
  return (
    <Grid pt={5} sx={{ m: "auto" }} item md={8} display="flex" justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mb={2}>
          <ClpButton onClick={onAddSubmit} color="success" result={buttonFeedbackResult} variant="contained">
            Skicka rapport
          </ClpButton>
        </Box>
        <Typography textAlign="center" variant="bodySmall">
          När du skickar rapporten markeras arbetsordern som utförd och meddelande om detta skickas till
          beställaren.
        </Typography>
      </Box>
    </Grid>
  )
}

export default ExternalSendButton
