import { Box, Container, Grid, Typography } from "@mui/material"
import { useApiGet, useApiPost } from "auth/useApi2"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { PageTabs } from "components/navigation/PageTabs"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { DeviceForm } from "../../components/DeviceForm"

const DeviceRegisterAdd = () => {
  const [addDeviceResult, addDevice] = useApiPost()
  const routerHistory = useHistory()
  const loadingContext = React.useContext(GlobalLoaderContext)

  const handleSubmit = (objectToSave) => {
    addDevice(apiEndpoints.systemadmindeviceregistry.adddevice, objectToSave)
  }

  useEffect(() => {
    if (addDeviceResult.status === 2) routerHistory.push(allRoutes.SystemadminDevicesRegister())
  }, [addDeviceResult.status, routerHistory])

  const feedback = {
    errorTitle: "Enhet kunde inte skapas"
  }

  const [companyResult, getCompany] = useApiGet()
  const [equipmentTypesResult, getEquipmentTypes] = useApiGet()

  useEffect(() => {
    loadingContext.setLoading(() => companyResult.status === 1)
  })

  useEffect(() => {
    loadingContext.setLoading(() => equipmentTypesResult.status === 1)
  })

  useEffect(() => {
    getCompany(apiEndpoints.systemadmindeviceregistry.listcompanies)
    getEquipmentTypes(apiEndpoints.systemadmindeviceregistry.listequipmenttypes)
  }, [getCompany, getEquipmentTypes])

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.SystemadminDevicesRegister(),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        <Box marginY={3}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Typography variant="h2">Lägg till enhet</Typography>
          </Grid>
        </Box>

        {companyResult.status === 2 && equipmentTypesResult.status === 2 && (
          <DeviceForm
            companyResult={companyResult}
            equipmentTypesResult={equipmentTypesResult}
            onSubmit={handleSubmit}
            feedback={feedback}
            result={addDeviceResult}
          />
        )}
      </Container>
    </div>
  )
}

export default DeviceRegisterAdd
