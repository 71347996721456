import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import MUIRichTextEditor from "mui-rte"
import React from "react"

const useStyles = makeStyles(() => ({
  rte: {
    width: "100%",
    "& img": {
      maxWidth: "100%",
      height: "auto"
    }
  }
}))

const BodyRichContent = ({ content }: any) => {
  const classes = useStyles()

  return (
    <Box className={classes.rte}>
      <MUIRichTextEditor defaultValue={content} readOnly={true} toolbar={false} />
    </Box>
  )
}

export default BodyRichContent
