import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import React from "react"
import LegacyDetails from "./components/LegacyDetails"
import NormalDetails from "./components/NormalDetails"

const ResultDetails = (props) => {
  const { match } = props
  const [detailsResult, detailsFuncs] = useApi()
  const loadingContext = React.useContext(GlobalLoaderContext)

  React.useEffect(() => {
    detailsFuncs.get(apiEndpoints.learning.getresultdetails.replace("{id}", match.params.id))
  }, [detailsFuncs, match.params.id])

  React.useEffect(() => {
    loadingContext.setLoading(() => detailsResult.status === 1)
  })

  return detailsResult.status === 2 && <DetailsSelector item={detailsResult.data} />
}

const DetailsSelector = ({ item }) => {
  return item.isLegacyResult === 1 ? <LegacyDetails item={item} /> : <NormalDetails item={item} />
}
export default ResultDetails
