import { faChalkboardTeacher, faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { BottomNavigation, Box, FormControlLabel, Grid, Switch } from "@mui/material"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { hasRole } from "auth/hasRole"
import { useApiFile } from "auth/useApi2"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import FilePicker from "components/FilePicker"
import PaperBox from "components/PaperBox"
import { CircleIcon } from "components/icons/CircleIcon"
import { FieldArray, Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { t as globalT } from "i18next"
import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ModuleBox } from "./ModuleBox"

import { customerValidationSchema } from "./validationSchema"
export const CustomerForm = ({
  editCustomer,
  saveResult,
  feedback,
  handleSubmit,
  logoResult,
  customerResult
}) => {
  const appContext = useContext(AppContext)

  const formRef = useRef()
  const { t } = useTranslation("translation", { keyPrefix: "settings.form" })
  const [fileUploadResult, uploadFile] = useApiFile()
  const [file, setFile] = useState(null)
  const [logoPreview, setLogoPreview] = useState()
  useEffect(() => {
    if (logoResult.status === 2) {
      onLogoChanged(logoResult.data)
    }
  }, [logoResult])

  useEffect(() => {
    const onAddImage = async () => {
      await uploadFile(apiEndpoints.systemadmincustomers.uploadcustomerlogo, file)
    }

    if (file) {
      onAddImage()
    }
  }, [file, uploadFile])

  const onLogoChanged = (value) => {
    setLogoPreview(value)
  }

  const initialValues = editCustomer ? editCustomer : customerResult

  const [systemAdminModules, toggleSystemAdminModules] = useState(false)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" gutterBottom>
            {editCustomer ? editCustomer.name : t("title")}
          </Typography>
        </Grid>
      </Grid>
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={customerValidationSchema}
        validateOnChange={false}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} md={7}>
                <PaperBox>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={8}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="name"
                        label={t("name")}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && errors.name ? true : false}
                        helperText={touched.name && errors.name ? errors.name : null}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="organisationNumber"
                        label={t("orgnr")}
                        value={values.organisationNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.organisationNumber && errors.organisationNumber ? true : false}
                        helperText={
                          touched.organisationNumber && errors.organisationNumber
                            ? errors.organisationNumber
                            : null
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="streetAddress"
                        label={t("streetAddress")}
                        value={values.streetAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.streetAddress && errors.streetAddress ? true : false}
                        helperText={
                          touched.streetAddress && errors.streetAddress ? errors.streetAddress : null
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="postalCode"
                        label={t("postalCode")}
                        value={values.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.postalCode && errors.postalCode ? true : false}
                        helperText={touched.postalCode && errors.postalCode ? errors.postalCode : null}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="cityAddress"
                        label={t("city")}
                        value={values.cityAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.cityAddress && errors.cityAddress ? true : false}
                        helperText={touched.cityAddress && errors.cityAddress ? errors.cityAddress : null}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={8} marginTop={3}>
                    <FilePicker
                      setFieldValue={setFieldValue}
                      fieldName="logoName"
                      defaultPreviewImage={
                        logoPreview && `data:${logoPreview.contenType};base64,${logoPreview.base64Contents}`
                      }
                      setFile={setFile}
                      fileUploadResult={fileUploadResult}
                      onLogoChanged={onLogoChanged}
                      label="Logotyp"
                    />
                  </Grid>
                </PaperBox>
                <Grid container>
                  <Grid item xs={12}>
                    <FieldArray
                      name="modules"
                      render={() => (
                        <div>
                          {values?.modules &&
                            values.modules?.length > 0 &&
                            values.modules.map((module, index) => (
                              <ModuleBox
                                index={index}
                                module={module}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                menuIcon={module.icon}
                                setFieldValue={setFieldValue}
                                arrayName="modules"
                                modules={values.modules}
                                touched={touched}
                                errors={errors}
                              />
                            ))}
                        </div>
                      )}
                    />
                    {hasRole(appContext.appUser, allRoles.SystemAdminCustomers) && (
                      <>
                        <Typography
                          marginTop={3}
                          variant="h5"
                          sx={{ cursor: "pointer" }}
                          onClick={() => toggleSystemAdminModules(!systemAdminModules)}>
                          {t("systemadmin")}{" "}
                          <FontAwesomeIcon icon={systemAdminModules ? faChevronUp : faChevronDown} />
                        </Typography>

                        <Typography variant="bodySmall">{t("systemadmin_description")}</Typography>
                        {systemAdminModules && (
                          <FieldArray
                            name="systemModules"
                            render={() => (
                              <div>
                                {values.systemModules &&
                                  values.systemModules.length > 0 &&
                                  values.systemModules.map((module, index) => (
                                    <ModuleBox
                                      index={index}
                                      module={module}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      menuIcon={module.icon}
                                      setFieldValue={setFieldValue}
                                      arrayName="systemModules"
                                      values={values}
                                      modules={values.systemModules}
                                      touched={touched}
                                      errors={errors}
                                    />
                                  ))}
                              </div>
                            )}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <PaperBox marginBottom={2}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <CircleIcon icon={faChalkboardTeacher} color="primary" size="lg" />
                    </Grid>

                    <Grid item xs={8}>
                      <Typography variant="h5">{t("demo_account")}</Typography>
                      <Typography variant="bodySmall">{t("demo_account_description")}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Switch checked={values.isDemoAccount} onChange={handleChange} id="isDemoAccount" />
                        }
                      />
                    </Grid>
                  </Grid>
                </PaperBox>

                {values.modules?.findIndex((m) => m.feature === "Payslips" && m.enabled) > -1 && (
                  <PaperBox>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item xs={12}>
                        <Typography variant="h5">Lön</Typography>
                        <Typography variant="bodyCompactSmall">
                          Inställningar för att aktivera Kontek på kund
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          id="kontekBaseUrl"
                          label={t("kontekBaseUrl", "Base Url")}
                          value={values.kontekBaseUrl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          id="kontekApiKey"
                          label={t("kontekApiKey", "Api Key")}
                          value={values.kontekApiKey}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          id="kontekCompanyId"
                          label={t("kontekCompanyId", "Company ID")}
                          value={values.kontekCompanyId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          id="kontekUserName"
                          label={t("kontekUserName", "Användarnamn")}
                          value={values.kontekUserName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          id="kontekPassword"
                          label={t("kontekPassword", "Lösenord")}
                          value={values.kontekPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          type="password"
                        />
                      </Grid>
                    </Grid>
                  </PaperBox>
                )}
              </Grid>
            </Grid>

            <Box marginTop={15}></Box>
            <Box sx={{ position: "fixed", bottom: 8, left: 0, right: 15 }} elevation={3}>
              <BottomNavigation
                sx={{ justifyContent: "flex-end", backgroundColor: "transparent", height: "auto" }}>
                <Button type="submit" color="primary" result={saveResult} feedback={feedback}>
                  {globalT("global.buttons.save")}
                </Button>
              </BottomNavigation>
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}
