import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material"
import { useApiDelete, useApiGet, useApiPut } from "auth/useApi2"
import Button from "components/Button"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { PageTabs } from "components/navigation/PageTabs"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { CompanyForm } from "../../components/CompanyForm"
import Workshop from "../../components/Workshop/Workshop"
import { useApi } from "auth/useApi3"
import SubTabs from "components/SubTabs"
import { useQuery } from "components/useQuery"

const EditCompany = ({ match }) => {
  const urlQuery = useQuery()
  const [customerResult, getCustomer] = useApiGet()
  const [companyResult, getCompany] = useApiGet()
  const [workshopsResults, workshopsApi] = useApi()
  const [updateCompanyResult, updateCompany] = useApiPut()
  const [deleteResult, deleteCompany, resetDeleteState] = useApiDelete()
  const [currentWorkshop, setCurrentWorkshop] = useState(null)

  const loadingContext = useContext(GlobalLoaderContext)
  const routerHistory = useHistory()

  useEffect(() => {
    // om man lyckats tagit bort användaren redirectar vi tillbaks till listan
    if (deleteResult.status === 2) routerHistory.push(allRoutes.CustomerSettingsCompanies())
  }, [deleteResult.status, routerHistory])

  const loadStuff = () => {
    getCustomer(apiEndpoints.customeradmin.getcustomer)
    getCompany(apiEndpoints.customeradmin.getcompanybyid.replace("{id}", match.params.id))
  }

  useEffect(() => {
    loadStuff()
  }, [match, getCustomer, getCompany])

  useEffect(() => {
    if (companyResult.status === 2) {
      const url = `${apiEndpoints.vehicleregistrychecks.getcompanycarworkshops}?companyId=${companyResult.data.id}`
      workshopsApi.get(url)
    }
  }, [companyResult])

  useEffect(() => {
    loadingContext.setLoading(() => customerResult.status === 1)
  })

  useEffect(() => {
    loadingContext.setLoading(() => companyResult.status === 1)
  })

  const handleSubmit = (saveCompany) => {
    updateCompany(apiEndpoints.customeradmin.updatecompany, saveCompany)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [showSidebar, setShowSidebar] = React.useState(false)

  const open = Boolean(anchorEl)

  const openSettingsDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeSettingsDropdown = () => {
    setAnchorEl(null)
  }

  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openRemoveDialog = () => {
    resetDeleteState()
    setDialogOpen(true)
  }

  const closeRemoveDialog = () => {
    resetDeleteState()
    setDialogOpen(false)
  }
  const [tabValue, setTabValue] = useState(0)
  const [tabs, setTabs] = useState([
    { index: 0, label: "Bolagsinformation", queryParam: "info" },
    { index: 1, label: "Avdelningar", queryParam: "departments" },
    { index: 2, label: "Verkstäder", queryParam: "workshops" }
  ])

  useEffect(() => {
    const tabParam = urlQuery.get("tab")
    if (!tabParam) return
    tabs.find(tab => {
      if (tab.queryParam === tabParam.toLowerCase()) {
        setTabValue(tab.index)
        return true
      }
    })
  }, [urlQuery, workshopsResults])

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.CustomerSettingsCompanies(),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        <Box marginY={3}>
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ height: "58px" }} variant="h2">Redigera bolag</Typography>
            {companyResult.status === 2 && tabValue === 2 ? (
              <Workshop
                handleSubmit={loadStuff} workshop={currentWorkshop} companyId={companyResult.data.id}
                setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
            ) : (<></>)}
          </Grid>
        </Box>
        {companyResult.status === 2 && workshopsResults.status === 2 ? (
          <>
            <SubTabs tabs={tabs} value={tabValue} setValue={setTabValue} />
            <CompanyForm
              customer={companyResult.data}
              company={companyResult.data}
              workshopsResults={workshopsResults}
              openRemoveDialog={openRemoveDialog}
              onSubmit={handleSubmit}
              result={updateCompanyResult}
              tabValue={tabValue}
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
              workshopsApi={workshopsApi}
              setCurrentWorkshop={setCurrentWorkshop}
            />
          </>
        ) : null}
      </Container>
      <Dialog open={dialogOpen} onClose={closeSettingsDropdown} aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Ta bort bolag?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Är du säker att du vill ta bort bolaget? Ditt val går inte
            att ångra.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRemoveDialog} variant="outlined">
            Avbryt
          </Button>
          <Button
            result={deleteResult}
            onClick={() => {
              deleteCompany(apiEndpoints.customeradmin.deletecompany.replace("{id}", match.params.id))
            }}
            color="primary"
            autoFocus
          >
            Ja, jag är säker
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default EditCompany
