import {
  faBuilding,
  faCheck,
  faChevronRight,
  faGraduationCap,
  faUser
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Divider, Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import LinkPaperBox from "components/LinkPaperBox"
import LoadingBox from "components/skeleton/LoadingBox"
import LoadingTypography from "components/skeleton/LoadingTypography"
import { allRoutes } from "generated/routes"
import React from "react"
import { NavLink } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  textGrey: {
    color: theme.palette.grey[500]
  }
}))

const ExamCard = ({ item, skeleton }) => {
  if (skeleton) {
    item = {}
  }
  const classes = useStyles()
  return (
    <LinkPaperBox>
      <NavLink to={allRoutes.SystemadminLearningExamDetails(item.id)}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            {skeleton ? (
              <LoadingTypography variant="h1" width="200px" />
            ) : (
              <Typography variant="h1">{item.name}</Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <FontAwesomeIcon className={classes.textGrey} icon={faChevronRight} size="2x" />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={10}>
            {skeleton ? (
              <div>
                <Box marginBottom={2}>
                  <LoadingBox width="100%" height="20px" />
                </Box>
                <Box marginBottom={2}>
                  <LoadingBox width="100%" height="20px" />
                </Box>
                <Box marginBottom={2}>
                  <LoadingBox width="100%" height="20px" />
                </Box>
                <Box marginBottom={2}>
                  <LoadingBox width="100%" height="20px" />
                </Box>
              </div>
            ) : (
              <table>
                <tr>
                  <td>
                    <Typography variant="bodyMedium">
                      <FontAwesomeIcon icon={faBuilding} />{" "}
                    </Typography>
                  </td>
                  <Typography variant="bodyMedium">
                    <td>Kunder: {item.customerCount} st</td>
                  </Typography>
                </tr>
                <tr>
                  <td>
                    <Typography variant="bodyMedium">
                      <FontAwesomeIcon icon={faUser} />
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="bodyMedium">Deltagare: {item.userCount} st</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="bodyMedium">
                      <FontAwesomeIcon icon={faGraduationCap} />
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="bodyMedium">
                      Inskickade prov: {item.finishedTestsCount} st
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="bodyMedium">
                      <FontAwesomeIcon icon={faCheck} />
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="bodyMedium">
                      Godkända prov: {item.passedTestCount} st (
                      {Math.round((item.passedTestCount / item.finishedTestsCount) * 1000000) / 10000} %)
                    </Typography>
                  </td>
                </tr>
              </table>
            )}
          </Grid>
        </Grid>
      </NavLink>
    </LinkPaperBox>
  )
}
export default ExamCard
