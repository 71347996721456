import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        transition: "all 0.5s ease",
        "&:hover": {
            boxShadow: "0px 3px 38px 0px rgb(205 205 205)"
        },
        "& a": {
            textDecoration: "none",
            color: theme.palette.grey[900]
        }
    }
}));

const StyledBox = withStyles({
    root: {
        boxShadow: "0px 0px 65px 0px rgb(0 0 0 / 6%)",
        width: "100%"
    }
})(Box);

export const LinkPaperBox = (props) => {
    const { children } = props;
    const classes = useStyles();
    return (
        <StyledBox mt={2} spacing={3} p={3} bgcolor="background.paper" borderRadius={2} className={classes.root} {...props}>
            {children}
        </StyledBox>
    );
};
LinkPaperBox.defaultProps = {
    noborderbottom: null
};

LinkPaperBox.propTypes = {
    noborderbottom: PropTypes.string
};

export default LinkPaperBox;
