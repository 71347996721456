import React from "react"
import { useStyles } from "./useStyles"
import { faBus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography, Card, CardContent, Avatar, Grid } from "@mui/material"
import { NavLink } from "react-router-dom"
import { allRoutes } from "generated/routes"

const NoDataCard = () => {
  const classes = useStyles()
  return (
    <Grid xs={12} md={8} item>
      <Card className={classes.card}>
        <CardContent className={classes.noNewsContent}>
          <Avatar aria-label="recipe" className={classes.noNewsAvatar}>
            <FontAwesomeIcon size="lg" icon={faBus} />
          </Avatar>
          <Typography variant="h3">
            Du har för närvarande inte några fordon upplagda i ditt fordonsregister. Klicka
            <NavLink className={classes.navLink} to={allRoutes.VehicleregistryVehiclesImport()}>
              {" "}
              här
            </NavLink>{" "}
            för att importera fordon.
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
export default NoDataCard
