import { Box, Grid } from "@mui/material";
import { useApiDelete } from "auth/useApi2";
import { useApi } from "auth/useApi3";
import { GlobalLoaderContext } from "components/GlobalLoadingProvider";
import { apiEndpoints } from "generated/apiEndpoints";
import { allRoutes } from "generated/routes";
import { RemoveDialog } from "pages/vehicleregistry/pages/reports/components/RemoveDialog/RemoveDialog";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TemplateForm from "../components/TemplateForm/TempleteForm";
import { ReportTemplateContext } from "./ReportTemplateContextProvider";

const UpdateTemplatePage = ({ match }) => {
    const loadingContext = useContext(GlobalLoaderContext);
    const { setRouteId, reportFields, selectedFields, setSelectedFields, setFieldsToChoose, fieldsToChoose } = useContext(ReportTemplateContext);
    const routerHistory = useHistory();
    const [updateFormTemplateResult, updateFormTemplateFuncs] = useApi(); // for getting the "base template" for the form (for edit this is the edit object)
    const [saveTemplateResult, saveTemplateFuncs] = useApi(); // for saving a new template based on the form
    const [deleteTemplateResult, deleteTemplateFuncs, resetDeleteState] = useApiDelete();

    React.useEffect(() => {
        updateFormTemplateFuncs.get(apiEndpoints.vehicleregistryreporting.getreporttemplatebyid.replace("{id}", match.params.id));
        return () => {
            updateFormTemplateFuncs.leave();
        };
    }, [match.params.id, updateFormTemplateFuncs]);

    useEffect(() => {
        if (updateFormTemplateResult.status === 2) {
            setFieldsToChoose(updateFormTemplateResult.data);
            let selectedField = [];
            updateFormTemplateResult.data?.fieldGroups?.map((fieldGroup, groupIndex) => {
                return fieldGroup?.items.map((checkedFilter, index) => {
                    if (checkedFilter.checked) {
                        checkedFilter.groupIndex = groupIndex;
                        checkedFilter.ReportFieldCategoryEnum = fieldGroup.reportFieldCategoryEnum;
                        checkedFilter.index = index;
                        checkedFilter.title = fieldGroup.title;
                        selectedField.push(checkedFilter);
                    }
                });
            });
            return setSelectedFields(selectedField.sort((a, b) => (a.order > b.order ? 1 : -1)));
        }
    }, [updateFormTemplateResult]);

    const saveTemplate = (values) => {
        let sortedSelectedFields = [];
        selectedFields.map((sf, index) => {
            sf.order = index;
            return sortedSelectedFields.push(sf);
        });

        const templateData = {
            id: values.id,
            name: values.name,
            description: values.description,
            fields: sortedSelectedFields
        };
        saveTemplateFuncs.put(apiEndpoints.vehicleregistryreporting.updatereporttemplate, templateData);
    };

    const [openDialog, setDialogOpen] = useState(false);

    const deleteTemplateDialog = () => {
        resetDeleteState();
        setDialogOpen(true);
    };
    useEffect(() => {
        if (deleteTemplateResult.status === 2) routerHistory.push(allRoutes.VehicleregistryReportsReporttemplates());
    }, [deleteTemplateResult.status, routerHistory]);

    const deleteTemplate = () => {
        deleteTemplateFuncs(apiEndpoints.vehicleregistryreporting.deletereporttemplate.replace("{id}", match.params.id));
        resetDeleteState();
    };

    return (
        <>
            <Box mb={4}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center"></Grid>
            </Box>
            {updateFormTemplateResult.status === 2 && (
                <TemplateForm
                    editMode={true}
                    onSubmit={saveTemplate}
                    editTemplate={fieldsToChoose}
                    submitFeedbackResult={saveTemplateResult}
                    deleteTemplate={deleteTemplateDialog}
                />
            )}
            <RemoveDialog deleteTemplate={deleteTemplate} deleteResult={deleteTemplateResult} openDialog={openDialog} setDialogOpen={setDialogOpen} />
        </>
    );
};
export default UpdateTemplatePage;
