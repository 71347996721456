import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ArrowDownwardOutlined, ArrowUpwardOutlined, FilterList, GetApp } from "@mui/icons-material"
import { Box, Button, Grid } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { LoadingButton } from "components/Button"
import ErrorDialog from "components/ErrorDialog"
import { matchSorter } from "match-sorter"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table"
import { DefaultColumnFilter, GlobalFilter } from "./ClpTableFilterTypes"
import { useStyles } from "./style.js"
import useTableExport from "./useTableExport.js"

export default function SubTable(props) {
  const classes = useStyles()

  const { columns, rows, rowProps, subColumns, hideFilter, hideSearch, hideSubFilter } = props

  return (
    <div className={classes.root}>
      <TableEl
        hideSubFilter={hideSubFilter}
        columns={columns}
        data={rows}
        rowProps={rowProps}
        subColumns={subColumns}
        hideFilter={hideFilter}
        hideSearch={hideSearch}></TableEl>
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = (val) => !val

function TableEl({ hideSubFilter, columns, subColumns, rowProps, data, hideFilter, hideSearch }) {
  const [filtersOpen, expandFilters] = useState(false)

  const [exportTableState, exportTable] = useTableExport()

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,

      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  )

  const filterParams = []
  for (const [key, value] of new URLSearchParams(useLocation().search)) {
    filterParams.push({ id: key, value: value })
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    visibleColumns,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      subColumns,
      columns,
      data,
      defaultColumn,
      filterTypes,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: state.pageIndex
          }),
          [state]
        )
      },
      initialState: {
        pageIndex: 0,
        pageSize: 5000,
        filters: filterParams
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  )
  const classes = useStyles()

  const renderRowSubComponent = React.useCallback(({ row }) => {
    return (
      row.original.subRows && (
        <Box>
          <SubTable rows={row.original.subRows} columns={subColumns} />
        </Box>
      )
    )
  }, [])

  const [open, setOpen] = React.useState(false)

  const toggleRowOpen = (id) => {
    if (open === id) {
      setOpen(false)
    } else {
      setOpen(id)
    }
  }

  return (
    <div>
      {!hideSubFilter && (
        <Box padding={3}>
          <Grid container direction="row" alignItems="end" justifyContent="space-between" spacing={2}>
            {!hideSearch && (
              <Grid item>
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </Grid>
            )}

            <Grid item>
              <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column, index) => {
                    return (
                      !column.hiddenFilter &&
                      column.mainFilter && (
                        <Grid item key={index}>
                          {column.render("Filter", column.render("Header"))}
                        </Grid>
                      )
                    )
                  })
                )}
                {!hideFilter && (
                  <Grid item>
                    <Button
                      color="primary"
                      startIcon={<FilterList />}
                      onClick={() => expandFilters(!filtersOpen)}>
                      {filtersOpen ? "Dölj filter" : "Visa alla filter"}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  {exportTableState > 2 && (
                    <ErrorDialog
                      title="Exporten misslyckades"
                      message="Exporten misslyckades, minska datats storlek genom att använda filtren och försök igen."
                    />
                  )}

                  {exportTableState === 1 ? (
                    <LoadingButton />
                  ) : (
                    <Button
                      color="primary"
                      startIcon={<GetApp />}
                      onClick={() => {
                        exportTable(columns, rows, prepareRow)
                      }}>
                      <span>Exportera</span>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}

      {filtersOpen && (
        <Box my={2} padding={3} bgcolor="background.default" width={1}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column, index) => {
                return (
                  !column.hiddenFilter &&
                  !column.mainFilter &&
                  column.Header && (
                    <Grid item key={index}>
                      {column.render("Filter", column.render("Header"))}
                    </Grid>
                  )
                )
              })
            )}{" "}
          </Grid>
        </Box>
      )}

      <Table size="small" {...getTableProps()} className={classes.table} stickyHeader>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            return (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {subColumns && subColumns.length > 0 && <TableCell></TableCell>}
                {headerGroup.headers.map((column, index) => {
                  const headerProps = {
                    align: column.align ? column.align : "left",
                    key: index,
                    ...{
                      ...column.getHeaderProps(
                        column.getSortByToggleProps({
                          title: column.tooltip
                        })
                      )
                    }
                  }

                  return (
                    !column.hidden && (
                      <TableCell {...headerProps}>
                        <span>
                          {column.render("Header")}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDownwardOutlined />
                            ) : (
                              <ArrowUpwardOutlined />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </TableCell>
                    )
                  )
                })}
              </TableRow>
            )
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <>
                <TableRow
                  className={row.isExpanded && "expanded"}
                  {...row.getRowProps(rowProps && rowProps(row))}
                  key={"row-data-" + row.id}>
                  {subColumns && subColumns.length > 0 && (
                    <TableCell>
                      <FontAwesomeIcon
                        style={{
                          cursor: "pointer"
                        }}
                        id={row.id}
                        onClick={() => toggleRowOpen(row.id)}
                        icon={open === row.id ? faMinus : faPlus}
                      />
                    </TableCell>
                  )}

                  {row.cells.map((cell) => {
                    return (
                      !cell.column.hidden && (
                        <TableCell
                          {...cell.getCellProps()}
                          data-label={cell.column.Header}
                          align={cell.column.align ? cell.column.align : "left"}
                          style={{
                            padding: cell.column.disablePadding && 0
                          }}>
                          {cell.render("Cell")}
                        </TableCell>
                      )
                    )
                  })}
                </TableRow>
                {open === row.id && (
                  <TableRow className="expanded">
                    <TableCell colSpan={visibleColumns.length}>
                      {renderRowSubComponent({
                        row
                      })}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}
filterGreaterThan.autoRemove = (val) => typeof val !== "number"
