// listar senaste eventsen i systemet, detaljer kommer i idictionary<string,string> form så nån form av key,value lista ploppas ut för varje rad
import { Grid } from "@mui/material"
import ClpTable from "components/ClpTable"
import globalUseStyles from "css/theme/globalUseStyles.js"
import Moment from "react-moment"

const DataCell = ({ properties }) => {
  return Object.entries(properties).map((item, key) => {
    return (
      <div key={key}>
        {item[0]}:{item[1]}
      </div>
    )
  })
}
export const EventsList = ({ items }) => {
  const globalClasses = globalUseStyles()

  return (
    <Grid className={globalClasses.tableContainer} marginTop={2}>
      <ClpTable
        rows={items}
        columns={[
          {
            accessor: "id",
            numeric: false,
            Header: "Id"
          },
          {
            accessor: "eventTime",
            numeric: false,
            Header: "Tidpunkt",
            Cell: ({ cell }) => {
              return <Moment format="lll:ss">{cell.row.original.eventTime}</Moment>
            }
          },
          {
            accessor: "name",
            numeric: false,
            Header: "Typ"
          },
          {
            accessor: "description",
            numeric: false,
            Header: "Beskrivning"
          },
          {
            accessor: "properties",
            numeric: false,
            Header: "Data",
            Cell: ({ cell }) => {
              return <DataCell properties={cell.row.original.properties} />
            }
          }
        ]}
      />
    </Grid>
  )
}
