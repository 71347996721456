import { Box, Typography } from "@mui/material"
import LoadingTypography from "components/skeleton/LoadingTypography"
import React from "react"

export const TextCell = ({ label, text }) => (
  <Box marginBottom={3}>
    <Typography variant="bodySmall" gutterBottom>
      {label}
    </Typography>
    <Typography variant="bodyMedium" fontWeight={600}>
      {text ? text : "-"}
    </Typography>
  </Box>
)

export const LoadingTextCell = ({ label, text, status, noAnimate }) => (
  <>
    <LoadingTypography variant="bodySmall" noAnimate>
      {label}
    </LoadingTypography>
    <LoadingTypography variant="bodyMedium" text={text} width={"80%"} noAnimate />
  </>
)
