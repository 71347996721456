import React, { useEffect, useContext } from "react";
import { apiEndpoints } from "generated/apiEndpoints";
import { Typography, Container, Grid, Box } from "@mui/material";
import { useApiPost, useApiGet } from "auth/useApi2";
import PaperBox from "components/PaperBox";
import { StorageForm } from "../../components/StorageForm";
import { useHistory } from "react-router-dom";
import { allRoutes } from "generated/routes";
import { GlobalLoaderContext } from "components/GlobalLoadingProvider";
import { PageTabs } from "components/navigation/PageTabs";

const StorageAdd = () => {
  const [addStorageResult, addStorage] = useApiPost();
  const loadingContext = useContext(GlobalLoaderContext);
  const routerHistory = useHistory();
  const [companyResult, getCompany] = useApiGet();

  const handleSubmit = (objectToSave) => {
    addStorage(apiEndpoints.systemadmindeviceregistry.addstorage, objectToSave);
  };
  useEffect(() => {
    if (addStorageResult.status === 2)
      routerHistory.push(allRoutes.SystemadminDevicesStorage());
  }, [addStorageResult.status, routerHistory]);

  useEffect(() => {
    getCompany(apiEndpoints.systemadmindeviceregistry.listcompanies);
  }, [getCompany]);

  useEffect(() => {
    loadingContext.setLoading(() => companyResult.status === 1);
  });

  const feedback = {
    errorTitle: "Lager kunde inte skapas",
  };

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.SystemadminDevicesStorage(),
            title: "Tillbaka",
          },
        ]}
      />
      <Container>
        <Box marginY={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h2">Lägg till lagerplats</Typography>
          </Grid>
        </Box>
        <PaperBox>
          {companyResult.status === 2 && (
            <StorageForm
              onSubmit={handleSubmit}
              companyResult={companyResult}
              feedback={feedback}
              result={addStorageResult}
            />
          )}
        </PaperBox>
      </Container>
    </div>
  );
};
export default StorageAdd;
