import React from "react";
import { ImportContractFormStateProvider } from "../../components/ImportContractFormStateProvider";
import { ImportFormWizard } from "../../components/ImportContractFormWizard";

const ImportContract = ({ match }) => {
    return (
        <div>
            <ImportContractFormStateProvider>
                <ImportFormWizard />
            </ImportContractFormStateProvider>
        </div>
    );
};
export default ImportContract;
