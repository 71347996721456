import { Box, Container, Grid, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import FormCardButton from "components/Form/FormComponents/FormCardButton/FormCardButton"
import { IFormCardButtonProps } from "components/Form/FormComponents/FormCardButton/formcardbutton.interface"
import FormTemplate from "components/Form/FormTemplate/FormTemplate"
import { IFormTemplateProps } from "components/Form/FormTemplate/formtemplate.interface"
import { apiEndpoints } from "generated/apiEndpoints"
import { useEffect, useState } from "react"
import { getTemplateSettings } from "./pages/helpers/templateSettings"
import { useQuery } from "components/useQuery"

const DeviationDashboard = () => {
  const [templateResults, getTemplates] = useApi() as [IApiResult, IApiFunc]
  const [formResult, getForm] = useApi() as [IApiResult, IApiFunc]
  const [queryParamId, setQueryParam] = useState<string | null>(null)

  let query = useQuery()
  useEffect(() => {
    getTemplates.get(apiEndpoints.form.getformtemplateminimized.replace("{feature}", "Deviation"))
  }, [getTemplates])

  useEffect(() => {
    if (query.get("id")) {
      setQueryParam(query.get("id") ?? null)
    } else {
      setQueryParam(null)
    }
  }, [query])

  useEffect(() => {
    if (queryParamId) {
      getForm.get(apiEndpoints.form.getformtemplate.replace("{id}", queryParamId))
    }
  }, [getForm, queryParamId])

  return (
    <Box pb={3}>
      <Container>
        {queryParamId === null && templateResults.status === 2 ? (
          <>
            <Grid container mx={"auto"} direction="row" display={"flex"} justifyContent="center">
              <Grid item>
                <Typography variant="h2" mb={2}>
                  Vad vill du rapportera?
                </Typography>
              </Grid>
            </Grid>
            <Grid container mx={"auto"} gap={3} display={"flex"} justifyContent={"center"} p={0}>
              {templateResults.data?.map((template: IFormTemplateProps) => {
                const settings = getTemplateSettings(template.key)
                const componentProps: IFormCardButtonProps = {
                  description: settings?.description,
                  title: template.name,
                  icon: settings?.icon,
                  size: "2xl",
                  id: template.id
                }
                return (
                  <Grid item md={5} xs={12}>
                    <FormCardButton {...componentProps} />
                  </Grid>
                )
              })}
            </Grid>
          </>
        ) : (
          queryParamId !== null &&
          formResult.status === 2 && (
            <Grid item md={12} xs={12}>
              <FormTemplate {...formResult.data} apiSubmitEndpoint={apiEndpoints.deviation.adddeviation} />
            </Grid>
          )
        )}
      </Container>
    </Box>
  )
}
export default DeviationDashboard
