import { Box, Grid } from "@mui/material"
import LoadingTypography from "components/skeleton/LoadingTypography"
import React from "react"

const LoadingNewsTemplate = () => {
  return (
    <Grid container direction="row">
      {[0, 1, 2, 3, 4, 5].map((value) => (
        <Box component={Grid} xs={12} item py={2} key={value}>
          <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item xs={12} md={4} lg={2}>
              <LoadingTypography variant="bodySmall" />
              <LoadingTypography variant="caption" />
            </Grid>

            <Grid item xs={12} md={8} lg={10}>
              <LoadingTypography variant="h6" />
              <LoadingTypography variant="bodySmall" width="60%" />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Grid>
  )
}
export default LoadingNewsTemplate
