import {
  Autocomplete,
  BottomNavigation,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from "@mui/material"
import Button from "components/Button"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { useTranslation } from "react-i18next"
import { exceptionOptions, statusOptions } from "../helpers/violationHelper"
import { IEditAdminViolationProps, ISelectListItem } from "../violation.interface"

export const EditAdminViolation: React.FC<IEditAdminViolationProps> = (props) => {
  const { values, setFieldValue, handleBlur, handleChange, touched, errors, result } = props
  const { t } = useTranslation("translation")

  return (
    <PaperBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12} marginBottom={2}>
            <Typography variant="h3">{t(`violation.admin_title`, ``)}</Typography>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <FormControl fullWidth>
              <Autocomplete
                sx={{ width: "100%", background: theme.palette.background.paper }}
                options={statusOptions}
                getOptionLabel={(option: ISelectListItem) => option?.name}
                value={statusOptions?.find((x: any) => x.id === values.status) || null}
                onChange={(e, option) => setFieldValue("status", option?.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"status"}
                    variant="outlined"
                    defaultValue={values.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={t(`violation.status_title`, ``)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <FormControl fullWidth>
              <Autocomplete
                sx={{ width: "100%", background: theme.palette.background.paper }}
                options={exceptionOptions}
                getOptionLabel={(option: ISelectListItem) => option?.name}
                value={exceptionOptions?.find((x: any) => x.name === values.exception) || null}
                onChange={(e, option) => setFieldValue("exception", option?.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"exception"}
                    variant="outlined"
                    defaultValue={values.exception}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={t(`violation.exception`, ``)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <TextField
              autoComplete="off"
              fullWidth
              id={"feedback"}
              value={values.feedback}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              label={t(`violation.action_plan`, ``)}
              error={touched.feedback && errors.feedback ? true : false}
              helperText={touched.feedback && errors.feedback ? errors.feedback : null}
            />
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(item) => {
                    const checked = item.target.checked
                    setFieldValue("strip", checked)
                  }}
                  checked={values.strip}
                />
              }
              label={t(`violation.strip`, ``)}
            />
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(item) => {
                    const checked = item.target.checked
                    setFieldValue("driverIncluded", checked)
                  }}
                  checked={values.driverIncluded}
                />
              }
              label={t(`violation.driver_included`, ``)}
            />
          </Grid>
          <BottomNavigation sx={{ justifyContent: "flex-end", height: "auto" }}>
            <Button color="primary" variant="contained" type="submit" result={result}>
              {t(`global.buttons.save`, ``)}
            </Button>
          </BottomNavigation>
        </Grid>
      </Grid>
    </PaperBox>
  )
}
