import InfoIcon from "@mui/icons-material/Info"
import { IconButton } from "@mui/material"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"
import React from "react"

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing(1)
  },
  commentIcon: {
    color: "#6DC7DC !important",
    padding: "0"
  },
  commentButton: {
    padding: "0"
  }
}))

export const VehicleAlarmComment = ({ action }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <IconButton
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.commentButton}
        size="large">
        <InfoIcon className={classes.commentIcon} />
      </IconButton>

      <Popover
        disableScrollLock
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography variant="paragraphMedium" className={classes.popoverTypography}>
          {!action ? "Det finns för tillfället ingen åtgärd" : action}
        </Typography>{" "}
      </Popover>
    </div>
  )
}
