import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, IconButton, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import { issueReportStatusColor } from "helpers/IssueReports/IssueReportStatusColor"
import { issueReportStatusIcon } from "helpers/IssueReports/IssueReportStatusIcon"
import { issueReportStatusLabel } from "helpers/IssueReports/IssueReportStatusLabel"
import { prio } from "pages/issuereports/pages/archive/components/table/helpers"

import React, { useContext } from "react"
import { Link } from "react-router-dom"

const IssueReportsCard = ({ data, vehicleId }) => {
  const appContext = useContext(AppContext)

  const columns = [
    {
      accessor: "issueReportId",
      Header: "ID",
      Cell: ({ row }) => {
        return (
          <Typography
            display="block"
            style={{
              color: "inherit",
              textDecoration: "inherit"
            }}
            component={Link}
            to={allRoutes.IssuereportsArchiveIssuereport(row.original.issueReportId)}>
            {row.original.issueReportId}
          </Typography>
        )
      }
    },
    {
      Header: "Beskrivning",
      Cell: ({ row }) => {
        return (
          <Typography
            display="block"
            style={{
              color: "inherit",
              textDecoration: "inherit"
            }}
            component={Link}
            to={allRoutes.IssuereportsArchiveIssuereport(row.original.issueReportId)}>
            {row.original.description}
          </Typography>
        )
      }
    },
    {
      accessor: "workOrderId",
      Header: "Arbetsorder",
      Cell: ({ row }) => {
        return (
          <Typography
            display="block"
            style={{
              color: "inherit",
              textDecoration: "inherit"
            }}
            component={Link}
            to={allRoutes.IssuereportsArchiveIssuereport(row.original.issueReportId)}>
            {row.original.workOrderId > 0 ? `#${row.original.workOrderId}` : `-`}
          </Typography>
        )
      }
    },
    {
      accessor: "priority",
      Cell: ({ row }) => {
        return (
          <Typography
            display="block"
            style={{
              color: "inherit",
              textDecoration: "inherit"
            }}
            component={Link}
            to={allRoutes.IssuereportsArchiveIssuereport(row.original.issueReportId)}>
            {prio(row.original.priority)}
          </Typography>
        )
      },
      Header: "Prioritet",
      mainFilter: true,
      Filter: SelectColumnFilter,
      FilterHeader: "Prio",
      align: "left",
      FilterOptions: [
        {
          name: "Låg",
          value: 1
        },
        {
          name: "Medel",
          value: 2
        },
        {
          name: "Hög",
          value: 3
        }
      ]
    },
    {
      Header: "Kategori",
      Cell: ({ row }) => {
        return (
          <Typography
            display="block"
            style={{
              color: "inherit",
              textDecoration: "inherit"
            }}
            component={Link}
            to={allRoutes.IssuereportsArchiveIssuereport(row.original.issueReportId)}>
            {row.original.categoryName}
          </Typography>
        )
      }
    },
    {
      accessor: (row) => {
        return issueReportStatusLabel(row.status)
      },
      Cell: ({ cell }) => {
        return (
          cell.row.original.status && (
            <Box
              color={`${issueReportStatusColor(cell.row.original.status)}.main`}
              style={{
                textDecoration: "inherit"
              }}
              component={Link}
              to={allRoutes.IssuereportsArchiveIssuereport(cell.row.original.issueReportId)}>
              <FontAwesomeIcon icon={issueReportStatusIcon(cell.row.original.status)} />
              <Typography sx={{ marginLeft: "5px" }} variant="caption">
                {issueReportStatusLabel(cell.row.original.status)}
              </Typography>
            </Box>
          )
        )
      },
      Header: "Status",
      Filter: SelectColumnFilter,
      FilterHeader: "Status",
      align: "left",
      mainFilter: true,
      FilterOptions: [
        {
          name: "Öppen",
          value: "Öppen"
        },
        {
          name: "Planerad",
          value: "Planerad"
        },
        {
          name: "Pågående",
          value: "Pågående"
        },
        {
          name: "Åtgärdad",
          value: "Åtgärdad"
        },
        {
          name: "Avslutad",
          value: "Avslutad"
        }
      ]
    },
    {
      align: "right",
      Cell: ({ cell }) => {
        return (
          hasRole(appContext.appUser, allRoles.IssueReportsAdmin) && (
            <IconButton
              component={Link}
              to={allRoutes.IssuereportsArchiveIssuereport(cell.row.original.issueReportId)}
              size="large">
              <FontAwesomeIcon icon={faEdit} size="xs" />
            </IconButton>
          )
        )
      },
      accessor: "action",
      Header: "",
      hiddenFilter: true
    }
  ]

  return (
    <>
      {hasRole(appContext.appUser, allRoles.IssueReportsAdmin) && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            component={Link}
            to={{
              pathname: allRoutes.IssuereportsWorkordersAdd(),
              state: vehicleId
            }}>
            Skapa arbetsorder
          </Button>
        </Box>
      )}

      <ClpTable
        rows={data.status === 2 && data.data}
        status={data.status}
        hideFilter={false}
        columns={columns}
      />
    </>
  )
}

export default IssueReportsCard
