import React from "react";
import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import PaperBox from "components/PaperBox";
import { StandardButton } from "components/Button";
import { allRoutes } from "generated/routes";
import { PageTabs } from "components/navigation/PageTabs";
import { Formik } from "formik";
import Button from "components/Button";
import { useApi } from "auth/useApi3";
import { apiEndpoints } from "generated/apiEndpoints";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    password: Yup.string().required("Lösenord är obligatoriskt"),
    passwordRepeat: Yup.string().test("passwords-match", "Lösenorden måste stämma", function (value) {
        return this.parent.password === value;
    })
});

const UserPasswordChangePage = () => {
    const [suggestedPassword, setSuggestedPassword] = React.useState("");
    const [suggestPasswordResult, suggestPasswordApi] = useApi();
    const [saveResult, saveApi] = useApi();

    React.useEffect(() => {
        if (suggestPasswordResult.status === 2) setSuggestedPassword(suggestPasswordResult.data);
    }, [suggestPasswordResult]);

    const handleSubmit = (values) => {
        saveApi.post(apiEndpoints.usersettings.changepassword.concat("?newPassword=").concat(window.btoa(values.password)));
    };

    return (
        <div>
            <PageTabs
                routeBack
                tabs={[
                    {
                        path: allRoutes.UserSettings(),
                        title: "Tillbaka"
                    }
                ]}
            />
            <Grid container>
                <Grid item sm={3}></Grid>
                <Grid item sm={6}>
                    <Container>
                        <Box marginY={3}>
                            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h2">Ändra lösenord</Typography>
                            </Grid>
                        </Box>
                        <PaperBox>
                            <Box mb={2}>
                                <Typography>Använd detta formulär för att ändra ditt lösenord</Typography>
                            </Box>
                            <Box mb={2}>
                                <Typography>
                                    Lösenordet måste uppfylla kraven: Minst 8 tecken och max 64 tecken långt. Om du vill få ett förslag på godkänt lösenord
                                    klicka på "Föreslå lösenord".
                                </Typography>
                            </Box>
                            <Box mb={2}>
                                <Box mb={2}>
                                    <StandardButton
                                        color="secondary"
                                        onClick={() => {
                                            suggestPasswordApi.get(apiEndpoints.util.getpasswordsuggestion);
                                        }}
                                    >
                                        Föreslå lösenord
                                    </StandardButton>
                                </Box>
                                <Box mb={2}>
                                    <Typography>Förslag på lösenord: {suggestedPassword} </Typography>
                                </Box>
                            </Box>
                            <Box mb={2}>
                                <Formik
                                    onSubmit={handleSubmit}
                                    initialValues={{
                                        password: "",
                                        passwordRepeat: ""
                                    }}
                                    enableReinitialize={true}
                                    validateOnChange={false}
                                    validationSchema={validationSchema}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
                                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                id="password"
                                                                type="password"
                                                                label="Nytt Lösenord"
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={touched.password && errors.password ? true : false}
                                                                helperText={touched.password && errors.password ? errors.password : null}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                id="passwordRepeat"
                                                                type="password"
                                                                label="Upprepa lösenord"
                                                                value={values.passwordRepeat}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={touched.passwordRepeat && errors.passwordRepeat ? true : false}
                                                                helperText={touched.passwordRepeat && errors.passwordRepeat ? errors.passwordRepeat : null}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-end"
                                                            alignItems="flex-end"
                                                            style={{ marginTop: "2rem" }}
                                                        >
                                                            <Button
                                                                color="primary"
                                                                type="submit"
                                                                feedback={{
                                                                    errorTitle: "Kunde inte uppdatera ditt lösenord"
                                                                }}
                                                                result={saveResult}
                                                            >
                                                                Spara
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                            <Box>
                                <Typography>Ändra lösenord genom att klicka "Spara" Glöm inte att skriva ned lösenordet innan du lämnar sidan!</Typography>
                            </Box>
                        </PaperBox>
                    </Container>
                </Grid>
                <Grid item sm={3}></Grid>
            </Grid>
        </div>
    );
};
export default UserPasswordChangePage;
