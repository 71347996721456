import { AppContext } from "components/AppContextProvider"
import React from "react"

import { Grid, Typography } from "@mui/material"
const Welcome = () => {
  const appContext = React.useContext(AppContext)

  return (
    <Grid item xs={12}>
      <Typography variant="h1">Hej, {appContext.appUser.profile.name}!</Typography>
    </Grid>
  )
}

export default Welcome
