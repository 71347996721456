import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { useApiDelete, useApiGet, useApiPut } from "auth/useApi2"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { PageTabs } from "components/navigation/PageTabs"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { EquipmentForm } from "../../components/EquipmentForm"

const EquipmentEdit = ({ match }) => {
  const routerHistory = useHistory()
  const [equipmentResult, getEquiptment] = useApiGet()
  const [editEquipmentResult, editEquipment] = useApiPut()
  const loadingContext = useContext(GlobalLoaderContext)

  const [companyResult, getCompany] = useApiGet()
  const [storagesResult, getStorages] = useApiGet()
  const [devicesResult, getDevices] = useApiGet()
  const [equipmentTypesResult, getEquipmentTypes] = useApiGet()
  const [equipmentStatuses, getEquipmentStatuses] = useApiGet()
  const [deleteResult, deleteEquipment] = useApiDelete()
  const [deleteDialogOpen, setDeleteDialog] = useState(false)
  const handleSubmit = (objectToSave) => {
    console.log(objectToSave)

    editEquipment(apiEndpoints.systemadmindeviceregistry.updateequipment, objectToSave)
  }

  useEffect(() => {
    getEquiptment(apiEndpoints.systemadmindeviceregistry.getequipmentbyid.replace("{id}", match.params.id))
    getCompany(apiEndpoints.systemadmindeviceregistry.listcompanies)
    getStorages(apiEndpoints.systemadmindeviceregistry.liststorages)
    getDevices(apiEndpoints.systemadmindeviceregistry.listdevices)
    getEquipmentTypes(apiEndpoints.systemadmindeviceregistry.listequipmenttypes)
    getEquipmentStatuses(apiEndpoints.systemadmindeviceregistry.getequipmentstatuses)
  }, [
    getCompany,
    getDevices,
    getStorages,
    getEquipmentTypes,
    getEquipmentStatuses,
    getEquiptment,
    match.params.id
  ])

  useEffect(() => {
    loadingContext.setLoading(() => companyResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => storagesResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => devicesResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => equipmentTypesResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => equipmentStatuses.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => equipmentResult.status === 1)
  })

  const feedback = {
    errorTitle: "Utrustning kunde inte skapas"
  }

  // delete row
  const handleDelete = () => {
    deleteEquipment(
      apiEndpoints.systemadmindeviceregistry.deleteequipment.replace("{id}", equipmentResult.data.id)
    )
  }
  useEffect(() => {
    if (deleteResult.status === 2) {
      routerHistory.push(allRoutes.SystemadminDevicesEquipment())
    }
  }, [deleteResult])

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.SystemadminDevicesEquipment(),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        {deleteDialogOpen && (
          <ContinueDialog
            setShowContinueModal={setDeleteDialog}
            onAddSubmit={handleDelete}
            message={`Vill du ta bort utrustning?`}
            continueBtnText="Ta bort utrustning"
            cancelBtnText="Avbryt"
            result={deleteResult}
          />
        )}

        <Box marginY={3}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Typography variant="h2">Redigera utrustning</Typography>
          </Grid>
        </Box>
        {companyResult.status === 2 &&
          devicesResult.status === 2 &&
          equipmentTypesResult.status === 2 &&
          equipmentStatuses.status === 2 &&
          storagesResult.status === 2 &&
          equipmentResult.status === 2 && (
            <EquipmentForm
              companyResult={companyResult}
              storagesResult={storagesResult}
              devicesResult={devicesResult}
              equipmentTypesResult={equipmentTypesResult}
              equipmentStatuses={equipmentStatuses}
              onSubmit={handleSubmit}
              feedback={feedback}
              editEquipment={equipmentResult.data}
              result={editEquipmentResult}
            />
          )}
        <Button onClick={() => setDeleteDialog(true)} variant="contained" color="primary">
          Ta bort utrustning
        </Button>
      </Container>
    </div>
  )
}

export default EquipmentEdit
