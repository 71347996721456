import { Grid } from "@mui/material"
import { useApi } from "auth/useApi3"
import { apiEndpoints } from "generated/apiEndpoints"
import React from "react"
import ExaminationInfoCardVertical from "shared/learning/components/ExaminationInfoCardVertical"
import AvailableExamsSkeletonGroup from "./components/AvailableExamsSkeletonGroup"
import { useTranslation } from "react-i18next"

const AvailableExams = () => {
  const { t } = useTranslation()
  const courseDetails = t("learning.details.course", { returnObjects: true })

  const [myExamsResult, myExamsApi] = useApi()

  React.useEffect(() => {
    myExamsApi.get(apiEndpoints.learning.getassignedexaminations)

    return () => {
      myExamsApi.leave()
    }
  }, [myExamsApi])

  return (
    <Grid container direction="row" spacing={2}>
      {myExamsResult.status === 2 ? (
        myExamsResult.data.map((item, i) => {
          const assignedCourses = courseDetails.find(
            (course) => course.examinationType === item.examinationType
          )
          return (
            <Grid item xs={12} md={6} key={i}>
              <ExaminationInfoCardVertical assignedCourses={assignedCourses} item={item} />
            </Grid>
          )
        })
      ) : (
        <AvailableExamsSkeletonGroup />
      )}
    </Grid>
  )
}
export default AvailableExams
