const blue = {
  50: "#e6f9fe",
  100: "#D3EEF5",
  200: "#D3EEF5",
  300: "#4badc6",
  400: "#2ea2bf",
  500: "#4BADC6",
  600: "#4BADC6",
  700: "#6DC7DC",
  800: "#254467",
  900: "#2E3547",
  A100: "#8c9eff",
  A200: "#536dfe",
  A400: "#3d5afe",
  A700: "#304ffe",
  contrastDefaultColor: "#ffffff"
}

export default blue
