import { Box, Checkbox, FormControlLabel } from "@mui/material"
import React, { useContext } from "react"

import { ExamFormContext } from "./ExamFormStateProvider"

export const MultiQuestion = (props) => {
  const { question } = props
  const answerContext = useContext(ExamFormContext)

  const [state, setState] = React.useState(answerContext.getSelectedAnswers(question.id))

  return (
    <div>
      {question.options.map((o, key) => {
        return (
          <Box key={key}>
            <FormControlLabel
              control={
                <Checkbox
                  key={key}
                  onChange={(e) => {
                    const checked = e.target.checked
                    setState((s) => {
                      const newArray = s.filter((s) => s !== o.id)
                      if (checked) newArray.push(o.id)
                      answerContext.updateAnswer({
                        questionId: question.id,
                        answer: newArray
                      })
                      return newArray
                    })
                  }}
                  checked={state.indexOf(o.id) > -1}
                />
              }
              label={o.label}
            />
          </Box>
        )
      })}
    </div>
  )
}
