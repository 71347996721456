import { Grid, Step, StepLabel, Stepper, Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useStyles } from "./useStyles"

const StepIcon = (props) => {
  const { active, completed } = props

  return active ? null : completed ? null : null
}

export const CustomerStepper = (props) => {
  const { content, steps, children, activeStep } = props
  const { t } = useTranslation("translation", { keyPrefix: "settings.wizard" })
  const classes = useStyles()
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" pb={4}>
      <Grid item>
        <Typography variant="h1">{t(`header.step${activeStep + 1}`)}</Typography>
      </Grid>
      <Grid item>
        <Stepper
          connector={false}
          activeStep={activeStep}
          className={classes.stepper}
          orientation="horizontal">
          {steps.map((data, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={StepIcon} className={classes.stepLabel}>
                {index + 1}. {t(`title.step${index + 1}`)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid container>
        <Grid item>{content && content(activeStep)}</Grid>
        <Grid item md={12}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}
