import * as Yup from "yup"

export const EditViolationSchema = Yup.object().shape({
  reason: Yup.string().required("Orsak är obligatoriskt"),
  explaination: Yup.string()
    .required("Övrig/förklaring är obligatoriskt")
    .max(3000, "Övrig/förklaring får inte överstiga 3000 tecken")
})

export const EditAdminViolationSchema = Yup.object().shape({
  feedback: Yup.string()
    .required("Handlingsplan är obligatoriskt")
    .max(3000, "Handlingsplan får inte överstiga 3000 tecken")
})

export const ViolationSettingSchema = Yup.object().shape({
  violationEmail: Yup.array().required("Överträdelse email är obligatorisk")
})
