import ClpTable from "components/ClpTable";

export const LoadingTable = () => {
    return (
        <ClpTable
            rows={[
                {
                    loadingReport: "Hämtar rapport.."
                }
            ]}
            columns={[
                {
                    accessor: "loadingReport",
                    Header: "Status"
                }
            ]}
        />
    );
};
