import Drawer from "@mui/material/Drawer";
import React from "react";
import { useStyles } from "./useStyles";

export const Sidebar = ({ showSidebar, children }) => {
    const classes = useStyles();

    return (
        <div>
            <React.Fragment key="right">
                <Drawer
                    variant="persistent"
                    className={classes.drawer}
                    anchor="right"
                    open={showSidebar}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    {children}
                </Drawer>
            </React.Fragment>
        </div>
    );
};
