import { Autocomplete, Box, TextField } from "@mui/material"
import globalUseStyles from "css/theme/globalUseStyles.js"
import theme from "css/theme/light"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete"
import { IPlacesAutocompleteProps } from "./formlocationpicker.interface"

export const PlacesAutocomplete: React.FC<IPlacesAutocompleteProps> = (props) => {
  const { handleChange, selected, selectedMapClick, setSelected, error } = props

  const {
    value,
    setValue,
    suggestions: { data },
    clearSuggestions
  } = usePlacesAutocomplete()

  const { t } = useTranslation()
  const globalClasses = globalUseStyles()

  useEffect(() => {
    if (selected !== null) {
      const getAddressFromLatLng = async () => {
        let lat = selected?.lat
        let lng = selected?.lng
        const location = { lat, lng }
        const results = await getGeocode({ location })
        const address = results[0]?.formatted_address

        if (address) {
          setValue(address, true)

          const newValue = JSON.stringify({
            interpretedValue: address,
            value: selected
          })
          handleChange && handleChange(newValue)
        }
      }
      getAddressFromLatLng()
    }
  }, [selected])

  useEffect(() => {
    if (selectedMapClick) {
      setValue(selectedMapClick, false)
    }
  }, [selectedMapClick])

  const handleSelect = async (address: string) => {
    setValue(address, false)
    clearSuggestions()

    const results = await getGeocode({ address })

    const { lat, lng } = await getLatLng(results[0])
    setSelected({ lat, lng })
    handleChange && handleChange({ lat, lng })
  }
  return (
    <Box mb={1}>
      <Autocomplete
        popupIcon={""}
        sx={{ width: "100%" }}
        options={data ? data : []}
        getOptionLabel={(option) => option?.description}
        onChange={(event, newValue) => newValue && handleSelect(newValue.description)}
        includeInputInList
        value={
          data?.find((i: google.maps.places.AutocompletePrediction) => i?.description.toString() === value) ||
          null
        }
        renderInput={(params) => (
          <TextField
            sx={{ background: theme.palette.background.paper }}
            onChange={(e) => setValue(e.target.value, true)}
            {...params}
            placeholder={t("search_address", "Sök address").toString()}
            {...props}
          />
        )}
      />
    </Box>
  )
}
