import React from "react"
import { useApi } from "auth/useApi3"
import { apiEndpoints } from "generated/apiEndpoints"
import { Typography, Grid } from "@mui/material"
import ExamSummaryCard from "shared/learning/components/ExamSummaryCard"
import AdminSummarySkeletonGroup from "./components/AdminSummarySkeletonGroup"
import { useTranslation } from "react-i18next"

const AdminSummary = () => {
  const { t } = useTranslation()
  const [examSummariesResult, examSummariesApi] = useApi()

  React.useEffect(() => {
    examSummariesApi.get(apiEndpoints.learningadmin.getexamsummaries)
    return () => {
      examSummariesApi.leave()
    }
  }, [examSummariesApi])

  return (
    <Grid item xs={12}>
      <Typography variant="h5">{t("global.titles.courses")}</Typography>
      <Grid container direction="row" spacing={0}>
        {examSummariesResult.status === 2 ? (
          examSummariesResult.data.map((item, index) => {
            return (
              <Grid item xs={12} key={index}>
                <ExamSummaryCard item={item} />
              </Grid>
            )
          })
        ) : (
          <AdminSummarySkeletonGroup />
        )}
      </Grid>
    </Grid>
  )
}
export default AdminSummary
