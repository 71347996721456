import {
  Autocomplete,
  Avatar,
  BottomNavigation,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from "@mui/material"

import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import * as Yup from "yup"

import CompanyDepartmentSelection from "components/CompanyDepartmentSelection"
import PaperBox from "components/PaperBox"

import { faLink, faUserSlash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Masonry } from "@mui/lab"
import { useApi } from "auth/useApi3"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import {
  userCreateValidationSchema,
  userValidationSchema
} from "pages/systemadmin/pages/customers/components/validationSchema"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BplUserInfo } from "./BplUserInfo"
import { UsernameField } from "./UsernameField"

export const UserForm = ({
  passwordSuggestEndpoint,
  customer,
  onSubmit,
  initialValues,
  result,
  feedback,
  parentIsSysAdmin,
  addUser
}) => {
  const globalClasses = globalUseStyles()
  const { t } = useTranslation("translation", { keyPrefix: "settings.user" })
  const appContext = useContext(AppContext)
  const isLimitedManager =
    !parentIsSysAdmin && hasRole(appContext.appUser, allRoles.CustomerAdminLimitedManagerAccess)
  const handleSubmit = async (values, { resetForm, errors }) => {
    console.log(errors)
    const customerId = customer.id

    const userToCredate = {
      ...values,
      password: window.btoa(values.password),
      customerId: customerId
    }

    onSubmit(userToCredate)
  }
  const [validationStep, setValidationStep] = useState(
    addUser ? userCreateValidationSchema : userValidationSchema
  )
  const [searchResult, searchApi] = useApi()

  useEffect(() => {
    searchApi.get(
      !addUser && parentIsSysAdmin
        ? apiEndpoints.systemadmincustomers.getusersselectlistformanagerdropdownedit
            .replace("{id}", customer.id)
            .replace("{userId}", initialValues.id)
        : !addUser && !parentIsSysAdmin
        ? apiEndpoints.customeradmin.getusersselectlistformanagerdropdownedit.replace(
            "{userId}",
            initialValues.id
          )
        : addUser && parentIsSysAdmin
        ? apiEndpoints.systemadmincustomers.getusersselectlistformanagerdropdown.replace("{id}", customer.id)
        : addUser && !parentIsSysAdmin
        ? apiEndpoints.customeradmin.getusersselectlistformanagerdropdown
        : ""
    )
  }, [])

  useEffect(() => {
    const planningActive =
      initialValues.modules?.findIndex((m) =>
        m.groups?.findIndex((x) => x.features?.findIndex((f) => f.featureId === "Planning" && f.enabled))
      ) > -1

    const violationActive =
      initialValues.modules?.findIndex((m) =>
        m.groups?.findIndex((x) => x.features?.findIndex((f) => f.featureId === "Violation" && f.enabled))
      ) > -1

    if (planningActive) {
      const bplInfoValidationObj = {
        bplInfo: Yup.object().shape({
          stationLocationId: Yup.string().nullable().required("Stationsort är obligatorisk"),
          agreementCode: Yup.string().nullable().required("Arbetsschema är obligatorisk"),
          workingScheduleCode: Yup.string().nullable().required("Avtal är obligatorisk"),
          workingPercentTime: Yup.number()
            .min(0, "Kan inte vara lägre än 0")
            .max(100, "Kan inte vara högre än 100"),
          managerId: Yup.string().nullable().required("Närmsta chef är obligatorisk")
        })
      }
      setValidationStep(Yup.object().shape(bplInfoValidationObj))
    }

    if (violationActive) {
      const violationObj = { driverCardNumber: Yup.string().nullable().required("Förarkort är obligatorisk") }
      setValidationStep(Yup.object().shape(violationObj))
    }
  }, [initialValues])

  const addAllFeatures = (featureGroupIndex, enabled, groups, setFieldValue) => {
    setFieldValue(`modules.${featureGroupIndex}.enabled`, !enabled)
    groups?.map((feature, groupIndex) => {
      setFieldValue(`modules.${featureGroupIndex}.groups.${groupIndex}.enabled`, !enabled)
      return feature.features?.map((feature, featureIndex) => {
        return setFieldValue(
          `modules.${featureGroupIndex}.groups.${groupIndex}.features.${featureIndex}.enabled`,
          !enabled
        )
      })
    })
  }

  return initialValues ? (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnBlur={true}
      validationSchema={validationStep}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
        setFieldValue,
        validateField
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={5}>
            <Grid item md={8}>
              <PaperBox>
                <Grid container spacing={2}>
                  {addUser && (
                    <UsernameField
                      values={values}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      passwordSuggestEndpoint={passwordSuggestEndpoint}
                    />
                  )}
                  {!addUser && (
                    <>
                      <Grid item xs={12} md={8}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          id={"username"}
                          label={t(`username`, ``)}
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.username && errors.username ? true : false}
                          helperText={touched.username && errors.username ? errors.username : null}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}
                  {!isLimitedManager && (
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        style={{
                          width: "100%"
                        }}
                        disabled={searchResult.status !== 2}
                        componentsProps={{ popper: { style: { width: "240px" } } }}
                        id="manager.id"
                        options={searchResult.status === 2 && searchResult.data}
                        getOptionLabel={(option) => (option.name ? option.name : "")}
                        value={
                          (searchResult.status === 2 &&
                            searchResult.data.find((i) => i.id === values.manager?.id)) ||
                          ""
                        }
                        getOptionSelected={(option, value) => {
                          return option.id === value.id
                        }}
                        onChange={(e, value) => {
                          setFieldValue("manager.id", value?.id)
                          setFieldValue("manager.bplUserId", value?.bplUserId)
                          if (
                            initialValues.modules?.findIndex((m) =>
                              m.groups?.findIndex((x) =>
                                x.features?.findIndex((f) => f.featureId === "Planning" && f.enabled)
                              )
                            ) > -1
                          ) {
                            setFieldValue("bplInfo.managerId", value?.bplUserId)
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={t(`manager`, ``)}
                            name="manager.id"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={8}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="name"
                      label={t(`name`, ``)}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && errors.name ? true : false}
                      helperText={touched.name && errors.name ? errors.name : null}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="employeeId"
                      label={t(`employeeId`, ``)}
                      value={values.employeeId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.employeeId && errors.employeeId ? true : false}
                      helperText={touched.employeeId && errors.employeeId ? errors.employeeId : null}
                      variant="outlined"
                    />
                  </Grid>
                  {!addUser && (
                    <Grid item xs={12} md={8}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id={"email"}
                        label={t(`email`, ``)}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && errors.email ? true : false}
                        helperText={touched.email && errors.email ? errors.email : null}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="phone"
                      label={t(`phone`, ``)}
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phone && errors.phone ? true : false}
                      helperText={touched.phone && errors.phone ? errors.phone : null}
                      variant="outlined"
                    />
                  </Grid>

                  {values.modules?.findIndex((m) =>
                    m.groups?.findIndex((x) =>
                      x.features?.findIndex((f) => f.featureId === "Violation" && f.enabled)
                    )
                  ) > -1 && (
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="driverCardNumber"
                        label={t(`drivercardnr`, ``)}
                        value={values.driverCardNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.driverCardNumber && errors.driverCardNumber ? true : false}
                        helperText={
                          touched.driverCardNumber && errors.driverCardNumber ? errors.driverCardNumber : null
                        }
                        variant="outlined"
                      />
                    </Grid>
                  )}

                  {!addUser && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Switch onChange={handleChange} id="disabled" checked={values.disabled} />}
                        label={t(`inactive_user`, ``)}
                      />
                    </Grid>
                  )}
                </Grid>
              </PaperBox>
              <PaperBox>
                <Masonry columns={{ xs: 1, md: 2 }} spacing={3}>
                  {values.modules?.map((category, moduleIndex) => {
                    return (
                      <Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="h5">
                            {t(`modules.${category.translateId}`, category.translateId)}
                          </Typography>
                          {category?.groups?.findIndex((x) => x.features.length > 1) === 0 && (
                            <FormControlLabel
                              className={globalClasses.margin0}
                              label={t(`modules.choose_all`)}
                              control={
                                <Checkbox
                                  onChange={() => {
                                    addAllFeatures(
                                      moduleIndex,
                                      category?.groups.every(
                                        (x) => x.features.every((x) => x.enabled) === true
                                      ),
                                      category?.groups,
                                      setFieldValue
                                    )
                                  }}
                                  id={category.translateId}
                                  checked={category?.groups.every(
                                    (x) => x.features.every((x) => x.enabled) === true
                                  )}
                                />
                              }
                            />
                          )}
                        </Box>

                        {category.groups?.map((group, groupIndex) => {
                          return (
                            <Box>
                              <Typography variant="caption">
                                {group.translateId && t(`modules.${group.translateId}`, group.translateId)}
                              </Typography>

                              {group.features?.map((feature, featureIndex) => {
                                return (
                                  <Box>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={() => {
                                            setFieldValue(
                                              `modules.${moduleIndex}.groups.${groupIndex}.features.${featureIndex}.enabled`,
                                              !feature?.enabled
                                            )
                                          }}
                                          checked={feature?.enabled}
                                        />
                                      }
                                      label={
                                        feature.translateId &&
                                        t(`modules.${feature.translateId}`, feature.translateId)
                                      }
                                    />
                                  </Box>
                                )
                              })}
                            </Box>
                          )
                        })}

                        <Divider />
                      </Box>
                    )
                  })}
                </Masonry>
              </PaperBox>
            </Grid>
            <Grid item md={4}>
              {!addUser && (
                <PaperBox>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={2}>
                      <Box>
                        <Avatar
                          aria-label="Synka användare till bpl"
                          className={[globalClasses.avatar, globalClasses.backgroundPrimary]}>
                          <FontAwesomeIcon icon={faUserSlash} size="lg" />
                        </Avatar>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="h5" marginBottom={1}>
                        {t(`inactive_user`, ``)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={<Switch onChange={handleChange} id="disabled" checked={values.disabled} />}
                      />
                    </Grid>
                  </Grid>
                </PaperBox>
              )}
              {values.modules?.findIndex((m) =>
                m.groups?.findIndex((x) =>
                  x.features?.findIndex((f) => f.featureId === "Planning" && f.enabled)
                )
              ) > -1 && (
                <PaperBox>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={2}>
                      <Box>
                        <Avatar
                          aria-label="Synka användare till bpl"
                          className={[globalClasses.avatar, globalClasses.backgroundPrimary]}>
                          <FontAwesomeIcon icon={faLink} size="lg" />
                        </Avatar>
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h5" marginBottom={1}>
                        BPL Integration
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} marginTop={0}>
                    <BplUserInfo
                      values={values}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      parentIsSysAdmin={parentIsSysAdmin}
                      customer={customer}
                    />
                  </Grid>
                </PaperBox>
              )}

              <PaperBox>
                <CompanyDepartmentSelection
                  companies={customer.companies}
                  initCompanies={initialValues.companyIds}
                  initDepartments={initialValues.departmentIds}
                  onChange={(newState) => {
                    const companyIds = []
                    const departmentIds = []

                    newState.forEach((c) => {
                      if (c.selected) companyIds.push(c.id)
                      c.departments.forEach((d) => {
                        if (d.selected) departmentIds.push(d.id)
                      })
                    })
                    setFieldValue("departmentIds", departmentIds)
                    setFieldValue("companyIds", companyIds)
                  }}
                />
              </PaperBox>
            </Grid>
          </Grid>

          <Box marginTop={15}></Box>
          <Box sx={{ position: "fixed", bottom: 8, left: 0, right: 15 }} elevation={3}>
            <BottomNavigation
              sx={{ justifyContent: "flex-end", backgroundColor: "transparent", height: "auto" }}>
              <Button color="primary" type="submit" result={result} feedback={feedback}>
                {!addUser ? "Spara" : "Lägg till"}
              </Button>
            </BottomNavigation>
          </Box>
        </form>
      )}
    </Formik>
  ) : null
}
