import React, { useState } from "react"

import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faMagnifyingGlass, faTrashXmark } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material"
import theme from "css/theme/light"
import FormUploadPreviewModal from "./FormUploadPreviewModal"
import { IFormUploadAttachmentProps, IFormUploadPreviewListProps } from "./formuploadfile.interface"

const FormUploadPreviewList = ({ files, handleRemove }: IFormUploadPreviewListProps) => {
  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState<IFormUploadAttachmentProps | null>(null)

  return files ? (
    <>
      {files.length > 0 &&
        files?.map((attachment: IFormUploadAttachmentProps, index: number) => (
          <>
            <Box
              gap={2}
              mb={1}
              px={2}
              py={1}
              borderColor="#EEEEEE"
              borderRadius={3}
              sx={{
                backgroundColor: theme.palette.primary.light
              }}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={0}
                justifyContent="space-between"
                style={{ color: theme.palette.primary["dark"], textDecoration: "inherit" }}>
                <Grid item xs={8} md={4} lg={5}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography display="inline-block" variant="bodyMedium" fontWeight="600">
                      {attachment?.fileName}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={4} sm={1}>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                    {attachment.Data && (
                      <Button
                        id={`lock-button`}
                        onClick={(e) => {
                          setOpen(true)
                          setSelectedImage(attachment)
                        }}
                        sx={{ color: theme.palette.primary["dark"] }}>
                        <FontAwesomeIcon
                          color={theme.palette.primary["dark"]}
                          icon={faMagnifyingGlass as IconProp}
                          style={{ marginRight: 2 }}
                        />
                        Öppna
                      </Button>
                    )}

                    <IconButton
                      edge="end"
                      id={`lock-button`}
                      aria-haspopup={`listbox`}
                      aria-controls={`lock-menu`}
                      aria-label="when device is locked"
                      color="error"
                      onClick={() => handleRemove && handleRemove(attachment)}>
                      <FontAwesomeIcon
                        color={theme.palette.error["main"]}
                        icon={faTrashXmark as IconProp}
                        size="xs"
                      />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </>
        ))}
      {selectedImage && <FormUploadPreviewModal attachment={selectedImage} open={open} />}
    </>
  ) : (
    <></>
  )
}

export default FormUploadPreviewList
