import { faNewspaper } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import React from "react"

const styles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  noNewsAvatar: {
    width: "60px",
    height: "60px",
    // background: theme.palette.cyan[100],
    color: "#254467",
    marginRight: "16px"
  },
  noNewsContent: {
    display: "flex",
    alignItems: "center",
    padding: "24px"
  }
}))

const NoNewsCard = () => {
  const classes = styles()
  return (
    <Grid xs={12} item>
      <Card className={classes.root}>
        <CardContent className={classes.noNewsContent}>
          <Avatar aria-label="recipe" className={classes.noNewsAvatar}>
            <FontAwesomeIcon size="lg" icon={faNewspaper} />
          </Avatar>
          <Typography variant="h3">Det finns för närvarande inga nyheter</Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
export default NoNewsCard
