import { Box, FormControlLabel, Grid, Radio, Typography } from "@mui/material"
import theme from "css/theme/light"
import React from "react"
import useStyles from "../../../../pages/deviation/pages/components/useStyles"
import { IRadioCardGroupProps } from "./formbigradiobuttons.interface"

const FormBigRadioButtons: React.FC<IRadioCardGroupProps> = (props) => {
  const { options, value, handleClick } = props

  const classes = useStyles()

  return (
    <Grid container gap={3} marginBottom={2}>
      {options?.map((option, index) => {
        return (
          <Grid md={12} xs={12} key={index}>
            <Box
              className={classes.bigRadioCard}
              onClick={() => handleClick && handleClick(option.value)} // TODO: change sort to id when implemented
              sx={{
                border:
                  value === option.value
                    ? `2px solid ${theme.palette.primary["main"]}`
                    : "2px solid transparent",
                backgroundColor: theme.palette.background.paper
              }}>
              <FormControlLabel
                sx={{ margin: 0, display: "flex", alignItems: "start" }}
                value={option.value}
                control={<Radio sx={{ padding: 0 }} checked={value === option.value} />}
                label={
                  <Box ml={2}>
                    <Typography variant="h4">{option.labelDefault}</Typography>
                    {option?.descriptionDefault && (
                      <Typography color="textSecondary">{option?.descriptionDefault}</Typography>
                    )}
                  </Box>
                }
              />
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default FormBigRadioButtons
