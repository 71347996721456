import { styled } from "@mui/material/styles"
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar"
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay"
import moment from "moment"
import * as React from "react"
import CalendarContext from "../context/CalendarStateProvider"
import { IWeekCalendarProps } from "../interfaces/calendar.interface"

interface CustomPickerDayProps extends PickersDayProps<moment.Moment> {
  isSelected: boolean
  isHovered: boolean
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered"
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ".MuiPickersDay-root::hover, .MuiPickersDay-root::focus": {
    backgroundColor: "transparent"
  },

  "&.MuiPickersDay-today": {
    paddingRight: "17px",
    paddingLeft: "17px",
    border: "0",
    color: theme.palette.primary.main,
    "& > span": {
      borderRadius: "100%",
      border: `1px solid ${theme.palette.primary.main}`
    }
  },
  ...(isSelected && {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary[theme.palette.mode]
    }
  }),
  ...(day.day() === 1 && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%"
  }),
  ...(day.day() === 0 && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  })
})) as React.ComponentType<CustomPickerDayProps>

const isInSameWeek = (dayA: moment.Moment, dayB: moment.Moment | null | undefined) => {
  if (dayB == null) {
    return false
  }

  return moment(dayA).isSame(dayB, "week")
}

function Day(
  props: PickersDayProps<moment.Moment> & {
    selectedDay?: moment.Moment | null
    hoveredDay?: moment.Moment | null
  }
) {
  const { day, selectedDay, hoveredDay, ...other } = props

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  )
}

export default function WeekPicker(props: IWeekCalendarProps) {
  const [hoveredDay, setHoveredDay] = React.useState<moment.Moment | null>(null)
  const { setDate, date, view } = React.useContext(CalendarContext)

  const [value, setValue] = React.useState<moment.Moment | null>(moment(date))

  React.useEffect(() => {
    setValue(moment(date))
  }, [date])

  return (
    <DateCalendar
      value={value}
      onChange={(newValue: any) => {
        setDate(new Date(newValue))
        setValue(newValue)
      }}
      showDaysOutsideCurrentMonth
      displayWeekNumber
      slots={{ day: Day }}
      slotProps={{
        day: (ownerState: any) =>
          ({
            selectedDay: value,
            hoveredDay,
            onPointerEnter: () => setHoveredDay(ownerState.day),
            onPointerLeave: () => setHoveredDay(null)
          } as any)
      }}
    />
  )
}
