import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Container, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApiDelete } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import SuccessDialog from "components/Dialogs/SuccessDialog"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import { issueReportStatusColor } from "helpers/IssueReports/IssueReportStatusColor"
import { issueReportStatusIcon } from "helpers/IssueReports/IssueReportStatusIcon"
import { issueReportStatusLabel } from "helpers/IssueReports/IssueReportStatusLabel"
import ImportIssueReportForm from "pages/issuereports/components/CustomForms/ImportIssueReportForm"
import { useContext, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import ArchiveSubCellTitle from "./components/table/ArchiveSubCellTitle"
import { ArchiveTableDeleteIcon } from "./components/table/ArchiveTableDeleteIcon"
import ArchiveTableLinkCell from "./components/table/ArchiveTableLinkCell"
import { prio, statusString } from "./components/table/helpers"

const Archive = () => {
  // open sidebar
  const [showSidebarForm, setShowSidebarForm] = useState(false)
  const openAdd = () => {
    setShowSidebarForm(true)
  }

  // context & styles
  const appContext = useContext(AppContext)
  const globalClasses = globalUseStyles()
  const loadingContext = useContext(GlobalLoaderContext)

  // states
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [clickedRow, setClickedRow] = useState()
  const [showContinueModal, setShowContinueModal] = useState(false)
  const [deleteResult, deleteIssueReport] = useApiDelete()
  const history = useHistory()
  const [groupVehicles, setGroupVehicles] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)
  const [formData, setFormData] = useState()
  const [issueReportsResults, issueReportsApi] = useApi([])
  const [issueReportsGroupedResults, issueReportsGroupedApi] = useApi([])
  const [updateIssueReportStatusResults, updateIssueReportStatusApi] = useApi()

  // fetch data
  useEffect(() => {
    issueReportsApi.get(apiEndpoints.issuereports.getissuereportsarchive)

    return () => {
      issueReportsApi.leave()
    }
  }, [issueReportsApi])

  useEffect(() => {
    issueReportsGroupedApi.get(apiEndpoints.issuereports.getissuereportsgroupedbyvehiclesandbycustomerid)

    return () => {
      issueReportsGroupedApi.leave()
    }
  }, [appContext.appUser, issueReportsGroupedApi])

  // loading
  useEffect(() => {
    loadingContext.setLoading(() => issueReportsResults.status === 1)
  })

  useEffect(() => {
    loadingContext.setLoading(() => issueReportsGroupedResults.status === 1)
  })

  // check if a row is deleted and fetch data again
  useEffect(() => {
    if (deleteResult.status === 2) {
      issueReportsApi.get(apiEndpoints.issuereportsadmin.getissuereportsarchive)
      if (hasRole(appContext.appUser, allRoles.IssueReportsAdmin)) {
        issueReportsGroupedApi.get(apiEndpoints.issuereports.getissuereportsgroupedbyvehiclesandbycustomerid)
      }
    }
  }, [deleteResult])

  const updateExistingData = () => {
    issueReportsApi.get(apiEndpoints.issuereportsadmin.getissuereportsarchive)
    if (hasRole(appContext.appUser, allRoles.IssueReportsAdmin)) {
      issueReportsGroupedApi.get(apiEndpoints.issuereports.getissuereportsgroupedbyvehiclesandbycustomerid)
    }
  }

  // delete row
  const handleDelete = () => {
    deleteIssueReport(apiEndpoints.issuereportsadmin.deleteissuereport.replace("{id}", clickedRow))
    setShowContinueModal(!showContinueModal)
    setShowSuccessModal(!showSuccessModal)
  }

  // show & close modals
  const openContinueModal = (cell) => {
    setClickedRow(cell)
    setShowContinueModal(!showContinueModal)
  }

  // change status from dropdown
  const onChangeStatus = (issueReport) => {
    updateIssueReportStatusApi.put(apiEndpoints.issuereportsadmin.updateissuereport, issueReport)
  }

  const groupedColumns = [
    {
      align: "left",
      accessor: "regNumber",
      Header: "Regnr"
    },
    {
      align: "left",
      accessor: "internNumber",
      Header: "Internnr"
    },
    {
      accessor: "vehicleId",
      Header: "",
      align: "left",
      Cell: ({ cell }) => {
        return (
          hasRole(appContext.appUser, allRoles.IssueReportsAdmin) && (
            <ArchiveSubCellTitle cell={cell} internNr={cell.row.original.internNumber} />
          )
        )
      }
    }
  ]

  const columns = [
    {
      accessor: "id",
      Header: "ID",
      Cell: ({ row }) => {
        return (
          <ArchiveTableLinkCell
            groupVehicles={groupVehicles}
            row={row}
            name={row.original.id}
            groupedName={row.original.id}
          />
        )
      }
    },
    {
      accessor: "vehicle.regNumber",
      Header: !groupVehicles && "Regnr",
      Cell: ({ row }) => {
        return (
          !groupVehicles && (
            <ArchiveTableLinkCell
              groupVehicles={groupVehicles}
              row={row}
              name={row.original.vehicle.regNumber}
            />
          )
        )
      }
    },
    {
      accessor: "vehicle.internnumber",
      Header: !groupVehicles && "Internnr",
      Cell: ({ row }) => {
        return (
          !groupVehicles && (
            <ArchiveTableLinkCell
              groupVehicles={groupVehicles}
              row={row}
              name={row.original.vehicle.internnumber}
            />
          )
        )
      }
    },
    {
      Header: "Mätarställning",
      Cell: ({ row }) => {
        return (
          <ArchiveTableLinkCell groupVehicles={groupVehicles} row={row} name={row.original.meterSetting} />
        )
      }
    },
    {
      Header: "Notering",
      Cell: ({ row }) => {
        return <ArchiveTableLinkCell groupVehicles={groupVehicles} row={row} name={row.original.notice} />
      }
    },
    {
      Header: "Beskrivning",
      Cell: ({ row }) => {
        return (
          <ArchiveTableLinkCell
            groupVehicles={groupVehicles}
            row={row}
            name={row.original.description}
            groupedName={row.original.description}
          />
        )
      }
    },
    {
      accessor: "workOrderId",
      Header: "Arbetsorder",
      Cell: ({ row }) => {
        return (
          <ArchiveTableLinkCell
            groupVehicles={groupVehicles}
            row={row}
            name={row.original.workOrderId > 0 ? `#${row.original.workOrderId}` : `-`}
            groupedName={row.original.workOrderId > 0 ? `#${row.original.workOrderId}` : `-`}
          />
        )
      }
    },
    {
      accessor: "priority",
      Cell: ({ row }) => {
        return (
          <ArchiveTableLinkCell
            groupVehicles={groupVehicles}
            row={row}
            name={prio(row.original.priority)}
            groupedName={prio(row.original.priority)}
          />
        )
      },
      Header: "Prioritet",
      mainFilter: true,
      Filter: SelectColumnFilter,
      FilterHeader: "Prio",
      align: "left",
      FilterOptions: [
        {
          name: "Låg",
          value: 1
        },
        {
          name: "Medel",
          value: 2
        },
        {
          name: "Hög",
          value: 3
        }
      ]
    },
    {
      Header: "Kategori",
      Cell: ({ row }) => {
        return (
          <Box
            display="block"
            component={Link}
            to={allRoutes.IssuereportsArchiveIssuereport(row.original.id)}
            style={{
              color: "inherit",
              textDecoration: "inherit"
            }}>
            {groupVehicles ? row.original.categoryName : row.original.issueReportCategory.name}
          </Box>
        )
      }
    },

    {
      accessor: (row) => {
        return statusString(row.status)
      },
      Cell: ({ cell }) => {
        return (
          cell.row.original.status && (
            <Box color={`${issueReportStatusColor(cell.row.original.status)}.main`}>
              <FontAwesomeIcon icon={issueReportStatusIcon(cell.row.original.status)} />
              <Typography sx={{ marginLeft: "5px" }} variant="caption">
                {issueReportStatusLabel(cell.row.original.status)}
              </Typography>
            </Box>
          )
        )
      },
      Header: "Status",
      Filter: SelectColumnFilter,
      FilterHeader: "Status",
      align: "left",
      mainFilter: true,
      FilterOptions: [
        {
          name: "Öppen",
          value: "Öppen"
        },
        {
          name: "Planerad",
          value: "Planerad"
        },
        {
          name: "Pågående",
          value: "Pågående"
        },
        {
          name: "Åtgärdad",
          value: "Åtgärdad"
        },
        {
          name: "Avslutad",
          value: "Avslutad"
        }
      ]
    },

    {
      align: "right",
      Cell: ({ cell }) => {
        return (
          hasRole(appContext.appUser, allRoles.IssueReportsAdmin) && (
            <ArchiveTableDeleteIcon handleClick={openContinueModal} data={cell} />
          )
        )
      },
      accessor: "action",
      Header: "",
      hiddenFilter: true
    }
  ]

  return (
    <div>
      {showContinueModal && (
        <ContinueDialog
          setShowContinueModal={setShowContinueModal}
          onAddSubmit={handleDelete}
          message={`Vill du ta bort felrapport?`}
          continueBtnText="Ta bort felrapport"
          cancelBtnText="Avbryt"
        />
      )}

      {showSuccessModal && (
        <SuccessDialog resetModalState={setShowSuccessModal} title="Din felrapport har tagits bort!" />
      )}

      <ImportIssueReportForm
        setShowSidebarForm={setShowSidebarForm}
        showSidebarForm={showSidebarForm}
        updateExistingData={updateExistingData}
      />

      <Container>
        <Grid pt={4} container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">Felrapporter</Typography>
          </Grid>

          <Grid item>
            {hasRole(appContext.appUser, allRoles.IssueReportsAdmin) && (
              <Button
                sx={{ mr: 2 }}
                component={Link}
                to={allRoutes.IssuereportsWorkordersAdd()}
                variant="outlined"
                color="primary">
                Skapa arbetsorder
              </Button>
            )}
            <Button onClick={openAdd} variant="contained" color="primary">
              Skapa felrapport
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Box mt={5} className={globalClasses.tableContainer}>
        {issueReportsResults && issueReportsResults.status === 2 && (
          <Box paddingX={3} paddingY={2}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={3}>
              <Grid item>
                <FormControlLabel
                  label="Gruppera per fordon"
                  control={
                    <Switch
                      onChange={() => setGroupVehicles(!groupVehicles)}
                      id="groupVehicles"
                      checked={groupVehicles}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {!groupVehicles ? (
          <ClpTable
            rows={issueReportsResults.status === 2 && issueReportsResults.data}
            status={issueReportsResults.status}
            hideFilter={false}
            columns={columns}
          />
        ) : (
          <ClpTable
            hideSubFilter={true}
            hideFilter={false}
            rows={issueReportsGroupedResults.status === 2 && issueReportsGroupedResults.data}
            status={issueReportsGroupedResults.status}
            subColumns={columns}
            columns={groupedColumns}
          />
        )}
      </Box>
    </div>
  )
}
export default Archive
