import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material"
import PaperBox from "components/PaperBox"
import LoadingBox from "components/skeleton/LoadingBox"
import LoadingTypography from "components/skeleton/LoadingTypography"
import { LoadingTextCell } from "./TextCell"

import { faCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative"
  },
  grid: {
    "& .MuiGrid-item": {
      minWidth: 125
    }
  },
  overlayText: {
    position: "absolute",
    width: "100%",
    height: "100%",
    "& > div": {
      maxWidth: "500px",
      zIndex: 1,
      padding: 15
    }
    // left: "50%"
  },
  list: {
    "& .MuiListItem-root:hover": {
      color: "rgba(0, 0, 0, 0.87)"
    }
  }
}))

const Section = () => {
  const classes = useStyles()
  return (
    <Box marginBottom={3}>
      <Grid container direction="row" justifyContent="space-between" spacing={2} className={classes.grid}>
        <Grid item xs={6}>
          <LoadingTypography variant="h4" />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" spacing={2} className={classes.grid}>
        <Grid item>
          <LoadingTextCell />
        </Grid>
        <Grid item>
          <LoadingTextCell />
        </Grid>
        <Grid item>
          <LoadingTextCell />
        </Grid>
        <Grid item>
          <LoadingTextCell />
        </Grid>
        <Grid item>
          <LoadingTextCell />
        </Grid>
      </Grid>
    </Box>
  )
}

const LoadingTabs = ({ noAnimate }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {!noAnimate && (
        <Grid container direction="row" justifyContent="flex-start" spacing={6} className={classes.grid}>
          <Grid item md={2}>
            <LoadingBox width={"100%"} height={25} />
          </Grid>
          <Grid item md={2}>
            <LoadingBox width={"100%"} height={25} />
          </Grid>
          <Grid item md={2}>
            <LoadingBox width={"100%"} height={25} />
          </Grid>
        </Grid>
      )}
      {noAnimate && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          className={classes.overlayText}
          alignItems="center">
          <Grid item>
            <PaperBox>
              <Typography variant="h2" gutterBottom>
                Lås upp integration mot Transportstryrelsen
              </Typography>
              <Typography variant="bodyMedium">Ta del av fördelarna med Transportstyrelsedata</Typography>
              <List dense={true} disabled className={classes.list}>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCheck} />
                  </ListItemIcon>
                  <ListItemText primary="Vilka fordon som har körförbud" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCheck} />
                  </ListItemIcon>
                  <ListItemText primary="Vilka fordon ska besiktigas inom 14 dagar" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCheck} />
                  </ListItemIcon>
                  <ListItemText primary="Använd data från Transportstyrelsen i dina rapporter" />
                </ListItem>
              </List>
              <Box marginBottom={2}>
                <Divider />
              </Box>

              <Button variant="contained" color="primary" href="mailto:info@cloudplanning.se">
                Kontakta oss
              </Button>
            </PaperBox>
          </Grid>
        </Grid>
      )}
      <Grid container direction="row">
        <Grid item md={12}>
          <PaperBox mt={1}>
            <Grid container direction="row" spacing={5}>
              <Grid item md={8}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                  className={classes.grid}>
                  <Grid item>
                    <LoadingTextCell noAnimate={noAnimate} />
                  </Grid>
                  <Grid item>
                    <LoadingTextCell noAnimate={noAnimate} />
                  </Grid>
                  <Grid item>
                    <LoadingTextCell noAnimate={noAnimate} />
                  </Grid>

                  <Grid item>
                    <LoadingTextCell noAnimate={noAnimate} />
                  </Grid>
                  <Grid item>
                    <LoadingTextCell noAnimate={noAnimate} />
                  </Grid>
                  <Grid item>
                    <LoadingTextCell noAnimate={noAnimate} />
                  </Grid>
                  <Grid item>
                    <LoadingTextCell noAnimate={noAnimate} />
                  </Grid>
                  <Grid item>
                    <LoadingTextCell noAnimate={noAnimate} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <Box bgcolor="background.light" padding={3}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    className={classes.grid}>
                    <Grid item>
                      <LoadingTextCell noAnimate={noAnimate} />
                    </Grid>
                    <Grid item>
                      <LoadingTextCell noAnimate={noAnimate} />
                    </Grid>
                    <Grid item>
                      <LoadingTextCell noAnimate={noAnimate} />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Section />
            <Section />
            <Section />
          </PaperBox>
        </Grid>
      </Grid>
    </div>
  )
}

export default LoadingTabs
