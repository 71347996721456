import { Box, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import PaperBox from "components/PaperBox"
import RouterLink from "components/RouterLink"
import UserAvatar from "components/UserAvatar"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import NoNewsCard from "pages/news/components/NoNewsCard"
import React from "react"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import { NavigateTo } from "../shared/NavigateTo"
import NewsSkeletonGroup from "./components/NewsSkeletonGroup"

const truncate = (str) => {
  return str.length > 199 ? str.substring(0, 200) + "..." : str
}

const NewsList = ({ items }) => {
  return (
    <Grid container direction="row">
      {items.results.map((item) => {
        return (
          <Box component={Grid} xs={12} item key={item.id} py={2}>
            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <Grid container direction="row" justifyContent="flex-start">
                  <UserAvatar label={item.publishedByUserFullName} />
                  <Grid item>
                    <Typography variant="bodySmall">{item.publishedByUserFullName}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      <Moment fromNow>{item.publishedDate}</Moment>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={8} lg={9}>
                <NavigateTo to={allRoutes.NewsListRead(item.id)}>
                  <Typography variant="h6">{item.subject}</Typography>
                  <Typography variant="bodySmall" color="textSecondary">
                    {truncate(item.subTitle)}
                  </Typography>
                </NavigateTo>
              </Grid>
            </Grid>
          </Box>
        )
      })}
    </Grid>
  )
}

const News = () => {
  const { t } = useTranslation()

  const [newsResult, newsFuncs] = useApi()
  React.useEffect(() => {
    newsFuncs.get(apiEndpoints.news.getnewslatest)
    return () => {
      newsFuncs.leave()
    }
  }, [newsFuncs])

  return (
    <>
      <Grid item xs={12}>
        <Box marginTop={3} />
        <Typography variant="h5"> {t("global.titles.news")}</Typography>
        {newsResult.status === 2 && newsResult.data.results.length < 1 && (
          <Grid item md={8} xs={12}>
            <Box mt={2}>
              <NoNewsCard />
            </Box>
          </Grid>
        )}
        {newsResult.status === 2 && newsResult.data.results.length > 0 && (
          <PaperBox>
            <NewsList items={newsResult.data} />
          </PaperBox>
        )}
        {newsResult.status !== 2 && (
          <PaperBox>
            <NewsSkeletonGroup />
          </PaperBox>
        )}
      </Grid>

      <Grid container justifyContent="center" alignItems="center">
        <Box marginTop={3} marginBottom={5}>
          <RouterLink to={allRoutes.NewsList()}>Alla nyheter</RouterLink>
        </Box>
      </Grid>
    </>
  )
}

export default News
