import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import React from "react"
import PhoneContactLink from "./PhoneContactLink"

interface ContactInfo {
  name: string
  phoneNumber: string
}
interface Props {
  managerData: ContactInfo
  otherData: ContactInfo[]
}

const PhoneContactsComponent: React.FC<Props> = (props) => {
  return (
    <Box>
      {props.managerData ? (
        <>
          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Närmsta Chef
          </Typography>
          <PhoneContactLink name={props.managerData.name} phoneNumber={props.managerData.phoneNumber} />
        </>
      ) : null}

      {props.otherData ? (
        <>
          <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 1 }}>
            Kontaktinfo Trafikområde
          </Typography>
          {props.otherData.map(function (ContactInfo) {
            return <PhoneContactLink name={ContactInfo.name} phoneNumber={ContactInfo.phoneNumber} />
          })}
        </>
      ) : null}
    </Box>
  )
}

export default PhoneContactsComponent
