import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
        padding: 0
    },
    rootShadow: { boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.1)" },
    paper: {
        color: theme.palette.text.secondary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: "45px",
        width: "100%"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    content: {
        marginBottom: 55
    },
    stepper: {
        background: "transparent",
        "& .MuiStepConnector-alternativeLabel": {
            top: "25px",
            left: "calc(-50% + 35px)",
            right: "calc(50% + 35px)"
        }
    },
    progress: {
        bottom: "0",
        position: "fixed",
        width: "100%"
    },
    areaContent: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "56.25%"
    },
    reponsiveIframe: {
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100%"
    },
    stepIcon: {
        opacity: 0.5,
        color: theme.palette.primary["dark"]
    },
    stepIconActive: {
        opacity: 1.0,
        color: theme.palette.primary["dark"]
    },
    stepIconCompleted: {
        opacity: 1.0,
        color: theme.palette.primary["dark"]
    },
    stepLabel: {
        fontSize: "2rem",
        cursor: "pointer",
        "& .MuiStepLabel-label": {
            fontSize: "1.2rem"
        }
    },
    mobileStepper: {
        maxWidth: "calc(100% - 11px)",
        flexGrow: 1,
        bottom: "0",
        position: "fixed",
        width: "100%",
        background: theme.palette.background.dark,
        color: theme.palette.background.paper,
        boxShadow: "0px 0px 65px 0px rgb(0 0 0 / 6%)",
        [theme.breakpoints.up("sm")]: {
            maxWidth: "calc(100% - 240px)"
        },
        [theme.breakpoints.down("xl")]: {
            "& .MuiLinearProgress-root": {
                display: "none"
            }
        }
    },
    accordion: {
        background: "none",
        color: theme.palette.foreground.inverted,
        borderBottom: 0,
        width: "100%",
        "& .MuiAccordionSummary-root": {
            padding: 0
        }
    },
    formControlLarge: {
        minWidth: 250
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    table: {
        marginBottom: "52px",
        overflowX: "auto",
        "& .MuiTableCell-sizeSmall": {
            padding: "6px 8px 6px 8px",
            "&.locked": {
                background: "#bdbdbd",
                borderBottomColor: "rgb(169, 169, 169)"
            },
            "& .MuiFormControl-root": {
                margin: 0,
                "& .MuiSelect-select": {
                    fontSize: "14px"
                },
                "& .MuiFormLabel-root": {
                    fontSize: "14px"
                }
            }
        },
        "& .MuiTableCell-sizeSmall:first-of-type": {
            paddingLeft: "24px"
        },
        "& .MuiFormControl-root": {
            minWidth: "90px"
        },
        "& .MuiTableRow-root": {
            verticalAlign: "bottom"
        },
        "& .MuiTableCell-head": {
            background: "rgba(239, 239, 239, 1)"
        },
        "& .MuiTypography-caption": {
            flex: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    },
    list: {
        "& .MuiListItem-root:hover": {
            background: "transparent",
            color: theme.palette.foreground.inverted
        },
        "& .MuiIconButton-root": {
            color: theme.palette.foreground.inverted
        }
    },
    icon: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center"
    },
    active: {
        backgroundImage: "linear-gradient( 136deg, rgb(109,199,220) 0%, rgb(0,152,184) 50%, rgb(46,53,71) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
    },
    completed: {
        backgroundImage: "linear-gradient( 136deg, rgb(109,199,220) 0%, rgb(0,152,184) 50%, rgb(46,53,71) 100%)"
    }
}));
