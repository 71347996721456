import { Avatar, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import { CircleIcon } from "components/icons/CircleIcon"
import PaperBox from "components/PaperBox"
import { SkeletonCardAvatar } from "components/skeleton/SkeletonCardAvatar"
import globalUseStyles from "css/theme/globalUseStyles"
import { useStyles } from "pages/vehiclealarm/components/useStyles"
import React from "react"
import { Link } from "react-router-dom"

export const DashboardCard = ({ title, itemLength, quantityType, route, icon, img, status }) => {
  const classes = useStyles()
  const globalClasses = globalUseStyles()
  return (
    <Grid item xs={12} md={10} lg={5} xl={4} className={classes.dashCard}>
      {status === 1 && <SkeletonCardAvatar />}
      {status === 2 && route ? (
        <Link className={classes.link} textDecoration={false} to={route}>
          <PaperBox paddingX={3} paddingY={3} marginY={0}>
            <ListItem disableGutters className={classes.padding0}>
              <ListItemAvatar>
                {img ? (
                  <Avatar
                    aria-label="icon"
                    className={globalClasses.backgroundPrimary}
                    sx={{ width: "55px", height: "55px", marginRight: "15px" }}>
                    <img height="24px" width="24px" src={img} alt={title} />
                  </Avatar>
                ) : (
                  <CircleIcon icon={icon} color="primary" size="lg" sx={{ marginRight: "15px" }} />
                )}
              </ListItemAvatar>
              <ListItemText>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography color="secondary" variant="bodyMedium" className={classes.textGrey}>
                      {title}
                    </Typography>
                    <Typography className={classes.blackText} variant="h1">
                      {itemLength} {quantityType}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>
          </PaperBox>
        </Link>
      ) : null}
    </Grid>
  )
}
