import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Box, Button, Dialog, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

export const SearchUserDialog: React.FC<any> = (props) => {
  const { open, onClose } = props
  const handleClose = () => {
    onClose(null)
  }
  const [searchResult, searchApi] = useApi() as [IApiResult, IApiFunc]
  const [searchParam, setSearchParam] = useState<string | null>(null)
  const [result, setResult] = useState<any[]>([])

  useEffect(() => {
    if (searchParam !== null && searchParam !== "") {
      searchApi.get(apiEndpoints.systemadmincustomers.getuserbysearch.replace("{searchparam}", searchParam))
    } else {
      setResult([])
    }
  }, [searchApi, searchParam])

  useEffect(() => {
    if (searchResult.status === 2) {
      setResult(searchResult.data)
    }
  }, [searchResult])

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="lg"
      PaperProps={{
        style: { height: "100vh", width: "100%" }
      }}>
      <DialogTitle style={{ height: "100%" }}>
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
          marginBottom={2}>
          <Grid item>
            <Typography variant="h1">Sök efter användare i Transportportalen</Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={() => handleClose()} size="large">
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container flexDirection="row" alignItems="center" spacing={3} marginBottom={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Sök användare efter namn, användarnamn eller e-post"
              name="user"
              fullWidth
              onChange={(e) => setSearchParam(e.target.value)}
            />
          </Grid>
          <Grid item>
            {searchParam !== null && searchResult.status === 2 && searchResult.data.length < 1 && (
              <Typography variant="h5" color="primary">
                Inget sökresultat kunde hittas för "{searchParam}"
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container flexDirection="row" alignItems="center" spacing={2}>
          {searchResult.status === 2 &&
            result.map((user: any) => (
              <Grid item xs={12}>
                <Box padding={2} border={1} borderRadius={8} borderColor="#EEEEEE">
                  <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Grid item md={2}>
                      <Typography variant="bodySmall" color="textSecondary">
                        Namn
                      </Typography>
                      <Typography variant="bodySmall" fontWeight="600" marginBottom={1}>
                        {user.name}
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography variant="bodySmall" color="textSecondary">
                        Kund
                      </Typography>
                      <Typography variant="bodySmall">{user.customerName}</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant="bodySmall" color="textSecondary">
                        E-post
                      </Typography>
                      <Typography variant="bodySmall"> {user.email ? user.email : "Ej angivet"}</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant="bodySmall" color="textSecondary">
                        Användarnamn
                      </Typography>
                      <Typography variant="bodySmall"> {user.username}</Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Button
                        component={Link}
                        variant="text"
                        to={allRoutes.SystemadminCustomersEditUsersEdit(user.customerId, user.id)}
                        endIcon={<OpenInNewIcon style={{ color: theme.palette.primary["main"] }} />}>
                        <Typography variant="labelSmallBold" color="textPrimary">
                          Gå till användare
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
        </Grid>
      </DialogTitle>
    </Dialog>
  )
}
