const { makeStyles } = require("@mui/styles")

export const useStyles = makeStyles((theme) => ({
  // Quicklinks
  link: {
    display: "flex",
    alignItems: "center",
    padding: "4px 0",
    textDecoration: "none"
  },
  icon: {
    marginLeft: "8px",
    color: theme.palette.primary["main"]
  }
}))
