import { Container, Grid, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import React from "react"
import Moment from "react-moment"
import { useStyles } from "./styles.js"

const LegacyDetails = ({ item }) => {
  const classes = useStyles()

  return (
    <div>
      <Container className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">Utbildningsbevis</Typography>
            <Typography variant="paragraphLarge">{item.examinationName}</Typography>
            <Typography variant="paragraphLarge">
              Slutförd: <Moment format="LL">{item.completedDate}</Moment>
            </Typography>
          </Grid>
          <Grid item md={8}>
            <PaperBox>
              <Typography>
                Det här är ett importerat resultat från tidigare system, för att ladda ned eventuellt diplom
                på nytt behöver du kontakta en administratör
              </Typography>
            </PaperBox>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default LegacyDetails
