import React from "react";
import { Box, ListItemIcon, ListItem } from "@mui/material";
import LoadingTypography from "components/skeleton/LoadingTypography";
import LoadingBox from "components/skeleton/LoadingBox";
import { useStyles } from "./useStyles";

export const VehicleLoadingItem = () => {
    const classes = useStyles();

    return (
        <ListItem className={classes.listItemLoading}>
            <ListItemIcon className={classes.listItemIcon}>
                <LoadingBox width={20} height={20} />
            </ListItemIcon>

            <Box className={classes.fullWidth}>
                <LoadingTypography width={200} variant="body" />
                <LoadingTypography height={80} variant="caption" />
            </Box>
        </ListItem>
    );
};
