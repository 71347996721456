import { faUser } from "@fortawesome/pro-solid-svg-icons"
import { Grid, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import { CircleIcon } from "components/icons/CircleIcon"
import { useStyles } from "./useStyles"

export const OverviewCard = ({ card }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} md={3} lg={2} className={classes.overviewCard}>
      <PaperBox paddingX={3} paddingY={3} marginY={0}>
        <ListItem
          disableGutters
          sx={{ flexDirection: { md: "column", lg: "row" }, flexWrap: { md: "wrap" } }}>
          <ListItemAvatar>
            <CircleIcon
              icon={card.icon ? card.icon : faUser}
              color="primary"
              size="lg"
              sx={{ marginRight: "15px" }}
            />
          </ListItemAvatar>
          <ListItemText>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography color="secondary" variant="bodyMedium" className={classes.textGrey}>
                  {card.title}
                </Typography>
                <Typography className={classes.blackText} variant="h2">
                  {card.value}
                </Typography>
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>
      </PaperBox>
    </Grid>
  )
}
