import React, { useContext, useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { mapStyles } from "./mapStyles";
import { MapContext } from "./MapContextProvider";
import DeviceMarker from "./DeviceMarker";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Wrapper } from "@googlemaps/react-wrapper";
import moment from "moment";
import MarkerIcon from "./MarkerIcon";
import MarkerIconArrow from "./MarkerIconArrow";
import { IDevicePosition, IDeviceHistoryPosition } from "./devices.interface";

const FullscreenMap = () => {
    const { mapRef, mapType, devices, historyPositions, onMarkerClick, activeMarker, setLoadingMarkers } = useContext(MapContext);
    const [currentZoom, setZoom] = useState<any>(7);

    const [googleHistoryMarkers, sethistoryMarkers] = useState<any>([]);
    const [googleLiveMarkers, setLiveMarkers] = useState<any>([]);
    const [googlePolylines, setPolylines] = useState<google.maps.Polyline | undefined>();
    const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | undefined>();
    const [cluster, setCluster] = useState<MarkerClusterer | undefined>();

    useEffect(() => {
        if (activeMarker === null && googleHistoryMarkers.length > 0) {
            clearHistoryMarkers();
            infoWindow?.close();
        }
    }, [activeMarker]);

    useEffect(() => {
        var map = mapRef.current;

        if (map) {
            devices
                .filter((d: IDevicePosition) => d.currentPosition !== null)
                .map((device: IDevicePosition) => {
                    let existingMarker: google.maps.Marker = googleLiveMarkers?.find((x: any) => device.label === x.title);

                    if (existingMarker === undefined) {
                        // Add new marker if not exist
                        const marker = DeviceMarker(device, map, onMarkerClick, infoWindow, activeMarker);

                        setLiveMarkers((prevState: any) => [...prevState, marker]);

                        return marker;
                    } else if (
                        device.currentPosition.lat !== existingMarker.getPosition()?.lat() &&
                        device.currentPosition.long !== existingMarker.getPosition()?.lng()
                    ) {
                        // Update marker if position changed
                        const rotation =
                            google.maps &&
                            google.maps.geometry.spherical.computeDistanceBetween(existingMarker.getPosition() as google.maps.LatLng, {
                                lat: device.currentPosition.lat,
                                lng: device.currentPosition.long
                            });

                        existingMarker.addListener("click", () => {
                            infoWindow?.setContent(
                                `${moment(device?.currentPosition?.timestamp).format("YYYY-MM-DD HH:mm")} <br> Hastighet: ${device?.speed} km/h`
                            );
                            infoWindow?.open(map, existingMarker);
                            var latLng = existingMarker.getPosition();
                            map.setCenter(latLng);
                        });
                        existingMarker.setPosition({ lat: device.currentPosition.lat, lng: device.currentPosition.long });
                        MarkerIcon(existingMarker, rotation, device, activeMarker);
                    }
                    return existingMarker;
                });

            cluster && cluster.addMarkers(googleLiveMarkers);
        }
    }, [devices, mapRef, onMarkerClick]);

    useEffect(() => {
        clearHistoryMarkers();

        var map = mapRef.current;
        if (map) {
            let markers: any = [];
            historyPositions.map((device: IDeviceHistoryPosition) => {
                var marker = new google.maps.Marker({
                    position: { lat: device.latitude!, lng: device.longitude! },
                    map,
                    visible: false
                });

                MarkerIconArrow(marker, device.angle);

                marker.addListener("click", () => {
                    infoWindow?.setContent(`${moment(device.timestamp).format("YYYY-MM-DD HH:mm")} <br> Hastighet: ${device.speed} km/h`);
                    infoWindow?.open(map, marker);
                });

                sethistoryMarkers((prevState: IDeviceHistoryPosition[]) => [...prevState, marker]);
                markers.push(new google.maps.LatLng(device.latitude!, device.longitude));

                return marker;
            });

            googlePolylines?.setPath(markers);
            map && googlePolylines?.setMap(map);
            var lastHistoryMarker = markers?.slice(-1)[0];

            lastHistoryMarker && map.setCenter(new google.maps.LatLng(lastHistoryMarker.lat(), lastHistoryMarker.lng()));
            setLoadingMarkers(false);
        }
    }, [historyPositions, mapRef]);

    const clearHistoryMarkers = () => {
        googlePolylines?.setMap(null);
        for (let i = 0; i < googleHistoryMarkers.length; i++) {
            googleHistoryMarkers[i].setMap(null);
        }
        sethistoryMarkers([]);
    };

    const hideAllMarkers = () => {
        for (let i = 0; i < googleHistoryMarkers.length; i++) {
            googleHistoryMarkers[i].setVisible(false);
        }
    };

    useEffect(() => {
        hideAllMarkers();
        if (googleHistoryMarkers.length > 0)
            if (currentZoom >= 17) {
                for (let i = 0; i < googleHistoryMarkers.length; i++) {
                    googleHistoryMarkers[i].setVisible(true);
                }
                return;
            } else if (currentZoom <= 17 && currentZoom >= 15) {
                for (let i = 0; i < googleHistoryMarkers.length; i += 20) {
                    googleHistoryMarkers[i].setVisible(true);
                }
                return;
            } else if (currentZoom <= 15 && currentZoom >= 10) {
                for (let i = 0; i < googleHistoryMarkers.length; i += 50) {
                    googleHistoryMarkers[i].setVisible(true);
                }
                return;
            } else if (currentZoom <= 10) {
                for (let i = 0; i < googleHistoryMarkers.length; i += 400) {
                    googleHistoryMarkers[i].setVisible(true);
                }
                return;
            }
    }, [currentZoom, googleHistoryMarkers]);

    const zoomChanged = () => {
        var zoom = mapRef.current.getZoom();
        setZoom(zoom);
    };
    const handleApiLoaded = (map: google.maps.Map) => {
        mapRef.current = map;
        setCluster(new MarkerClusterer({ map }));
        var polyline = new google.maps.Polyline({
            geodesic: true,
            strokeColor: "#83d4e7",
            strokeOpacity: 1,
            strokeWeight: 3
        });

        const infoWindow = new google.maps.InfoWindow({
            content: "",
            disableAutoPan: true
        });
        infoWindow.addListener("closeclick", () => {
            console.log("close");
        });
        setInfoWindow(infoWindow);
        setPolylines(polyline);
    };

    return (
        <Wrapper apiKey={"AIzaSyCk4SCnuwj2yKZ_Z8CqIasGQIIiMh0lws8"}>
            <GoogleMapReact
                ref={mapRef}
                style={{
                    height: "100%",
                    width: "100%",
                    pointerEvents: "all",
                    zIndex: "5",
                }}
                bootstrapURLKeys={{
                    key: "AIzaSyCk4SCnuwj2yKZ_Z8CqIasGQIIiMh0lws8"
                }}
                defaultCenter={{ lat: 62.38583179, lng: 16.321998712 }}
                defaultZoom={currentZoom}
                options={{
                    mapTypeControl: false,
                    zoomControl: true,
                    styles: mapStyles,
                    disableDefaultUI: true,
                    mapTypeId: `${mapType}`,
                    mapId: "38e89e5c91f932d2",
                    clickableIcons: false
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
                onZoomAnimationEnd={() => zoomChanged()}
            ></GoogleMapReact>
        </Wrapper>
    );
};
export default FullscreenMap;
