import { DateCalendar } from "@mui/x-date-pickers"
import moment from "moment"
import { useContext } from "react"
import { CalendarContext } from "../context/CalendarStateProvider"
import WeekPicker from "./WeekCalendar"

const BigCalendarDatePickers = () => {
  const { setDate, date, view } = useContext(CalendarContext)
  const handleDateChange = (newDate: Date) => {
    setDate(newDate)
  }
  switch (view) {
    case "month":
      return (
        <DateCalendar
          openTo="month"
          views={["year", "month"]}
          value={moment(date)}
          onChange={(e) => handleDateChange(new Date(moment(e).format("YYYY-MM-DD")))}
        />
      )
    case "week":
      return <WeekPicker value={moment(date)} />
    default:
      return (
        <DateCalendar
          openTo="day"
          views={["year", "month", "day"]}
          displayWeekNumber
          value={moment(date)}
          onChange={(e) => handleDateChange(new Date(moment(e).format("YYYY-MM-DD")))}
        />
      )
  }
}
export default BigCalendarDatePickers
