import { Box, Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { PageTabs } from "components/navigation/PageTabs"
import LoadingTypography from "components/skeleton/LoadingTypography"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useMemo } from "react"

const ExamDetails = ({ match }) => {
  const globalClasses = globalUseStyles()

  const columns = useMemo(
    () => [
      {
        accessor: "customerName",
        numeric: false,
        disablePadding: false,
        Header: "Kund"
      },
      {
        accessor: "userName",
        numeric: false,
        disablePadding: false,
        Header: "Användarnamn"
      },
      {
        accessor: "userFullname",
        numeric: false,
        disablePadding: false,
        Header: "Namn"
      },
      {
        accessor: "examResultCount",
        numeric: true,
        disablePadding: false,
        Header: "Antal resultat"
      },
      {
        accessor: "passedExamResultCount",
        numeric: true,
        disablePadding: false,
        Header: "Antal godkända resultat"
      },
      {
        accessor: (row) => {
          return row.isAttendee ? "Ja" : "Nej"
        },
        numeric: false,
        Header: "Är Deltagare"
      }
    ],
    []
  )

  const [examResult, examFuncs] = useApi()
  React.useEffect(() => {
    examFuncs.get(apiEndpoints.systemadminlearning.getexamdetails.replace("{id}", match.params.id))

    return () => {
      examFuncs.leave()
    }
  }, [match, examFuncs])

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.SystemadminLearning(),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        <Box mb={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {examResult.status === 1 && <LoadingTypography variant="h1" width="300px" />}
            {examResult.status === 2 && <Typography variant="h1">{examResult.data.name}</Typography>}
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rows={examResult.status === 2 && examResult.data.users}
          status={examResult.status}
        />
      </Box>
    </div>
  )
}
export default ExamDetails
