import React from 'react'
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";

const RadioButtons = (props) => {
    const { manualPageSize, setPageSize, pageSize, manualPagination, setPageSizeManual } = props;
    return (
        <Grid item>
            <RadioGroup
                row
                aria-label="pageSize"
                name="pageSize"
                value={manualPageSize ? manualPageSize : pageSize}
                onChange={(e) => {
                    !manualPagination ? setPageSize(Number(e.target.value)) : setPageSizeManual(() => Number(e.target.value));
                }}
            >
                {[25, 50, 75].map((pageSize) => (
                    <FormControlLabel key={pageSize} value={pageSize} control={<Radio />} label={pageSize} />
                ))}
            </RadioGroup>
        </Grid>
    )
}

export default RadioButtons