import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Box, Container, Grid, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"

import BigCalendar from "components/Calendar/BigCalendar"
import BigCalendarDatePickers from "components/Calendar/components/BigCalendarDatePicker"
import CalendarContext, { CalendarStateProvider } from "components/Calendar/context/CalendarStateProvider"
import { statusIcon, statusIconColor, statusLabel } from "components/Calendar/helpers/calendarStatusEvent"
import { IEventProps } from "components/Calendar/interfaces/event.interface"

import PaperBox from "components/PaperBox"
import TodoItem from "components/TodoList/TodoItem"
import { ITodoItemProps } from "components/TodoList/todolist.interface"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import { useCallback, useContext, useEffect, useState } from "react"

const CalendarContainer = () => {
  const { setFilters, setEvents, date, events, setLoadingEvents } = useContext(CalendarContext)
  const [eventsResult, eventsApi] = useApi() as [IApiResult, IApiFunc]
  const [previousDate, setPreviousDate] = useState<Date | null>(null)
  useEffect(() => {
    setFilters([
      { title: "Händelser", data: "typeId" },
      { title: "Status", data: "statusLabel" }
    ])
  }, [])

  const reloadCalendarEvents = useCallback(() => {
    setEvents([])
    if (date) {
      setLoadingEvents(true)
      eventsApi.get(
        apiEndpoints.vehicleregistry.getevents
          .replace("{start}", moment(date).startOf("month").format("YYYY-MM-DD"))
          .replace("{stop}", moment(date).endOf("month").format("YYYY-MM-DD"))
      )
    }
  }, [date, eventsApi, setLoadingEvents])

  useEffect(() => {
    if (date && previousDate !== date && !events.some((event) => moment(event.start).isSame(date, "month"))) {
      setLoadingEvents(true)
      eventsApi.get(
        apiEndpoints.vehicleregistry.getevents
          .replace("{start}", moment(date).startOf("month").format("YYYY-MM-DD"))
          .replace("{stop}", moment(date).endOf("month").format("YYYY-MM-DD"))
      )
      setPreviousDate(date)
    }
  }, [eventsApi, date, events])

  useEffect(() => {
    if (eventsResult.status === 1) {
      setLoadingEvents(true)
    }
    if (eventsResult.status === 2) {
      let updatedEvents = [
        ...events,
        ...eventsResult.data.map((event: IEventProps) => {
          return {
            ...event,
            end: event.end,
            allDay: event.end === null,
            statusLabel: event.typeId ? statusLabel(event.typeId, event.status || 0, true) : undefined
          }
        })
      ]
      setEvents(updatedEvents)
      setLoadingEvents(false)
    }
  }, [eventsResult])

  return (
    <>
      <PaperBox>
        <Box sx={{ position: "relative", height: "1050px", minHeight: "1050px" }}>
          <BigCalendar reloadCalendarEvents={reloadCalendarEvents} />
        </Box>
      </PaperBox>
    </>
  )
}
const UpcomingEvents = () => {
  const { date, setView, setDate } = useContext(CalendarContext)
  const [groupedEvents, setGroupedEvents] = useState<any[] | null>(null)
  const [upcomingEventsResult, upcomingEventsApi] = useApi() as [IApiResult, IApiFunc]
  useEffect(() => {
    if (date && groupedEvents === null) {
      upcomingEventsApi.get(
        apiEndpoints.vehicleregistry.getevents
          .replace("{start}", moment(date).format("YYYY-MM-DD"))
          .replace("{stop}", moment(date).add(1, "month").format("YYYY-MM-DD"))
      )
    }
  }, [upcomingEventsApi, date, groupedEvents])

  useEffect(() => {
    if (upcomingEventsResult.status === 2 && groupedEvents === null) {
      let groupedEvents1 = upcomingEventsResult.data
        .sort((a: any, b: any) => moment(a.start).valueOf() - moment(b.start).valueOf())
        .reduce((grouped: any, item: IEventProps) => {
          const yearMonth = `${moment(item.start).format("MMMM")}`

          if (!grouped[yearMonth]) {
            grouped[yearMonth] = []
          }

          const todoItem: ITodoItemProps = {
            title: item.title,
            subtitle: item.subtitle,
            description: item.description,
            start: item.start,
            end: item.end,
            type: item.typeId,
            status: item.status,
            iconProps: {
              icon: statusIcon(item.typeId, item.status) as IconProp,
              color: statusIconColor(item.typeId, item?.status)
            },
            onClick: () => {
              if (item.start) {
                setView("day")
                setDate(new Date(item.start))
              }
            }
          }

          grouped[yearMonth].push(todoItem)

          return grouped
        }, {})
      setGroupedEvents(groupedEvents1)
    }
  }, [upcomingEventsResult])
  return (
    <>
      {groupedEvents !== null &&
        Object.entries(groupedEvents).map(([yearMonth, items]) => {
          return (
            <>
              <Typography
                variant="bodySmall"
                color="text.secondary"
                textTransform="capitalize"
                marginBottom={1}>
                {yearMonth}
              </Typography>
              {items.map((item: ITodoItemProps) => {
                return <TodoItem {...item} />
              })}{" "}
            </>
          )
        })}
    </>
  )
}

const Calendar = () => {
  return (
    <>
      <Container>
        <CalendarStateProvider>
          <Grid spacing={2} container direction="row" justifyContent="spaceBetween">
            <Grid item xs direction="column">
              <CalendarContainer />
            </Grid>
            <Grid item xs="auto">
              <PaperBox>
                <Box
                  marginBottom={2}
                  style={{ boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 4%)", borderRadius: "8px" }}>
                  <BigCalendarDatePickers />
                </Box>

                <Typography variant="h4" color="text.primary" marginBottom={2}>
                  Kommande händelser
                </Typography>
                <UpcomingEvents />
              </PaperBox>
            </Grid>
          </Grid>{" "}
        </CalendarStateProvider>
      </Container>
    </>
  )
}
export default Calendar
