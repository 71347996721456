import React, { createContext, useEffect, useState } from "react"
import { View } from "react-big-calendar"
import { statusLabel } from "../helpers/calendarStatusEvent"
import { IEventProps } from "../interfaces/event.interface"
import { IFilterCalendarProps, IFilterSelectedCalendarProps } from "../interfaces/filter.interface"

export const CalendarContext = createContext({
  date: new Date(),
  setDate: (date: Date) => {},
  view: "week" as View | undefined,
  setView: (view: View) => {},
  columnView: false as boolean,
  setColumnView: (columnView: boolean) => {},
  filterView: false as boolean,
  setFilterView: (filterView: boolean) => {},
  filters: [] as IFilterCalendarProps[],
  setFilters: (filters: IFilterCalendarProps[]) => {},
  selectedFilters: [] as IFilterSelectedCalendarProps[],
  setSelectedFilters: (filters: IFilterSelectedCalendarProps[]) => {},
  events: [] as IEventProps[],
  filterEvents: [] as Event[],
  setEvents: (events: IEventProps[]) => {},
  loadingEvents: false as boolean,
  setLoadingEvents: (loadingEvents: boolean) => {}
})

export const CalendarStateProvider: React.FC = ({ children }) => {
  const [date, setDate] = useState<Date>(new Date())
  const [view, setView] = useState<View | undefined>("month")
  const [columnView, setColumnView] = useState<boolean>(true)
  const [filterView, setFilterView] = useState<boolean>(true)
  const [filters, setFilters] = useState<IFilterCalendarProps[]>([])
  const [selectedFilters, setSelectedFilters] = useState<IFilterSelectedCalendarProps[]>([])
  const [events, setEvents] = useState<IEventProps[]>([])
  const [filterEvents, setFilterEvents] = useState<any[]>([])
  const [loadingEvents, setLoadingEvents] = useState<boolean>(true)

  useEffect(() => {
    const filteredEvents =
      selectedFilters?.length > 0
        ? events
            .map((event) => {
              return {
                ...event,
                end: event.end ? event.end : event.start,
                allDay: event.end === null,
                statusLabel: event.typeId ? statusLabel(event.typeId, event.status || 0, true) : undefined
              }
            })
            .filter((event) =>
              selectedFilters.every((filter) =>
                filter.selected.includes(event[filter.data as keyof typeof event])
              )
            )
        : events.map((event) => {
            return {
              ...event,
              end: event.end ? event.end : event.start,
              allDay: event.end === null,
              statusLabel: event.typeId ? statusLabel(event.typeId, event.status || 0, true) : undefined
            }
          })
    setFilterEvents(filteredEvents)
  }, [selectedFilters, events])

  return (
    <CalendarContext.Provider
      value={{
        date,
        setDate,
        view,
        setView,
        columnView,
        setColumnView,
        filterView,
        setFilterView,
        setFilters,
        filters,
        events,
        setEvents,
        selectedFilters,
        setSelectedFilters,
        filterEvents,
        setLoadingEvents,
        loadingEvents
      }}>
      {children}
    </CalendarContext.Provider>
  )
}

export default CalendarContext
