import { Box, Grid } from "@mui/material"
import { useApi } from "auth/useApi3"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import SuccessDialog from "components/Dialogs/SuccessDialog"
import { RouterPrompt } from "components/RouterPrompt"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import { statusAlertText } from "pages/external/pages/workorder/components/helpers"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { statusButtonText } from "./EmailBox/helpers"
import WorkOrderEmailBox from "./EmailBox/WorkOrderEmailBox"

import WorkOrderButtons from "./WorkOrderButtons"
import WorkOrderCard from "./workOrderCard/WorkOrderCard"
import WorkOrderTitle from "./WorkOrderTitle"
import { workOrderValidationSchema } from "./workOrderValidationSchema"

const AlertDialog = (props) => {
  const { setShowContinueModal, setShowSuccessModal, onSubmit, values, result } = props

  return (
    <ContinueDialog
      result={result}
      setShowContinueModal={setShowContinueModal}
      setShowSuccessModal={setShowSuccessModal}
      cancelBtnText="Avbryt"
      continueBtnText={statusButtonText(values.status)}
      message={
        values.issueReports.find((report) => report.added === true) === undefined
          ? "OBS! Du har inte vald någon felrapport. " + statusAlertText(values.status)
          : statusAlertText(values.status)
      }
      onAddSubmit={() => onSubmit(values)}
    />
  )
}

const WorkOrderForm = (props) => {
  const {
    handleModals,
    setShowSuccessModal,
    addResult,
    updateResult,
    onUpdate,
    setVehicle,
    vehicleId,
    initialValues,
    match,
    vehiclesApi,
    vehiclesResult,
    setShowContinueModal,
    showContinueModal,
    showSuccessModal,
    showUpdateModal,
    setShowUpdateModal,
    onAddSubmit,
    workorderResult,
    vehicleIdFromDash,
    sendBooking
  } = props
  const routerHistory = useHistory()
  const [showDeleteModel, setShowDeleteModal] = useState(false)
  const [deleteResult, deleteWorkOrderApi] = useApi()
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  // handle submit formik form & show continue modal
  const handleSubmit = () => {
    setShowContinueModal(true)
  }

  // delete workorder
  const onDelete = () => {
    deleteWorkOrderApi.del(apiEndpoints.issuereportsadmin.deleteworkorder.replace("{id}", match.params.id))
    setShowDeleteModal(false)
  }

  // update grid on delete api call success finish
  useEffect(() => {
    if (deleteResult.status === 2) {
      routerHistory.push(allRoutes.IssuereportsWorkorders())
    }
  }, [deleteResult.status, routerHistory, vehiclesApi])

  return (
    <div>
      <WorkOrderTitle initialValues={initialValues} />
      <Formik
        initialValues={initialValues}
        validationSchema={workOrderValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setFieldValue
        }) => (
          <Box>
            <form id="WorkOrderForm" onSubmit={handleSubmit} noValidate>
              {!showAlertDialog && <RouterPrompt />}

              <Box marginY={3} mt={0}>
                <Grid container spacing={4} justifyContent="flex-start">
                  <Grid item xs={12} lg={8}>
                    <WorkOrderCard
                      vehicleId={vehicleId}
                      status={workorderResult?.status ? workorderResult?.status : 1}
                      vehicleIdFromDash={vehicleIdFromDash}
                      initialValues={initialValues}
                      vehiclesResult={vehiclesResult}
                      values={values}
                      setFieldValue={setFieldValue}
                      setVehicle={setVehicle}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>

                  <WorkOrderEmailBox
                    status={workorderResult?.status ? workorderResult?.status : 1}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    submit={setShowAlertDialog}
                  />
                </Grid>

                <WorkOrderButtons
                  buttonFeedbackResult={addResult}
                  values={values}
                  buttonFeedbackResultUpdate={updateResult}
                  initialValues={initialValues}
                  buttonFeedbackResultDelete={deleteResult}
                  setShowDeleteModal={setShowDeleteModal}
                  setShowUpdateModal={setShowUpdateModal}
                />
              </Box>
              {showAlertDialog && (
                <AlertDialog
                  onSubmit={sendBooking}
                  result={values.status > 1 ? updateResult : addResult}
                  setShowContinueModal={setShowAlertDialog}
                  values={values}
                />
              )}

              {showContinueModal && (
                <ContinueDialog
                  result={addResult}
                  setShowContinueModal={setShowContinueModal}
                  setShowSuccessModal={setShowSuccessModal}
                  message={`Vill du spara arbetsorder?`}
                  cancelBtnText="Avbryt"
                  continueBtnText="Spara"
                  onAddSubmit={handleModals}
                />
              )}

              {showDeleteModel && (
                <ContinueDialog
                  result={deleteResult}
                  setShowContinueModal={setShowDeleteModal}
                  onAddSubmit={onDelete}
                  cancelBtnText="Avbryt"
                  continueBtnText="Ta bort arbetsorder"
                  message={`Vill du ta bort arbetsorder?`}
                />
              )}

              {showUpdateModal && (
                <ContinueDialog
                  result={updateResult}
                  onAddSubmit={onUpdate}
                  values={values}
                  setShowContinueModal={setShowUpdateModal}
                  cancelBtnText="Avbryt"
                  continueBtnText="Spara"
                  message={`Vill du uppdatera din arbetsorder?`}
                />
              )}

              {showSuccessModal && (
                <SuccessDialog
                  values={values}
                  onAddSubmit={onAddSubmit}
                  title="Din arbetsorder har sparats."
                />
              )}
            </form>
          </Box>
        )}
      </Formik>
    </div>
  )
}

export default WorkOrderForm
