import { faCircleNotch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mui/material"
import { useApiPost } from "auth/useApi2"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect } from "react"

function BplTokenLink(props) {
  const { to, label, variant, color } = props
  const [bplLinkResult, linkToBplApi] = useApiPost()

  const linkToBpl = () => {
    linkToBplApi(apiEndpoints.planning.linktoken)
  }
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer")
  }
  useEffect(() => {
    if (bplLinkResult.status === 2 && bplLinkResult.data.token !== null) {
      var url = bplLinkResult.data.bplUrl
        .concat("/login.aspx?id=")
        .concat(bplLinkResult.data.id)
        .concat("&t=")
        .concat(encodeURIComponent(bplLinkResult.data.token))
        .concat("&ReturnURL=")
        .concat(encodeURIComponent(bplLinkResult.data.bplUrl.concat("/").concat(to)))

      openInNewTab(url)
    }
  }, [bplLinkResult])

  return (
    <Button
      {...props}
      variant={variant ? variant : "contained"}
      color={color && color}
      result={bplLinkResult}
      onClick={linkToBpl}
      startIcon={bplLinkResult.status === 1 && <FontAwesomeIcon spin icon={faCircleNotch} />}>
      {bplLinkResult.status === 1 ? "Laddar" : label ? label : "Gå till BPL"}
    </Button>
  )
}

export default BplTokenLink
