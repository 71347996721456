import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(3)
        },
        formControl: {
            minWidth: 150
        }
    })
);
