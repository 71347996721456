import { useApi } from "auth/useApi3"
import React, { useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { useTranslation } from "react-i18next"
import { useStyles } from "./useStyles"

import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  TextField,
  Typography
} from "@mui/material"

const PeoplePickerSearch = ({ onSearch, setSearchString, searchString, setValue, errors, touched }) => {
  const classes = useStyles()

  const key_up = (e) => {
    var enterKey = 13
    if (e.which === enterKey) {
      onSearch(e.target.value)
    }
  }

  const { t } = useTranslation("translation", { keyPrefix: "learning" })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.search} flexDirection="column">
        <Box mb={1}>
          <InputLabel>
            <Typography fontWeight={600} color="textPrimary" variant="labelMedium">
              {t(`admin.participants.search_for_users`)}
            </Typography>
          </InputLabel>
        </Box>
        <FormControl>
          <TextField
            variant="outlined"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value)
            }}
            onKeyUp={(e) => key_up(e)}
            InputProps={{
              startAdornment: (
                <IconButton
                  type="button"
                  aria-label="search"
                  onClick={() => {
                    onSearch(searchString)
                  }}
                  size="small">
                  <SearchIcon />
                </IconButton>
              ),
              endAdornment: (
                <IconButton
                  sx={{ padding: "8px" }}
                  type="button"
                  aria-label="search"
                  onClick={() => {
                    setSearchString("")
                    setValue("")
                  }}
                  size="small">
                  <CloseIcon />
                </IconButton>
              ),
              classes: { root: classes.formAreaInput }
            }}
          />
        </FormControl>

        <Typography mt={1} color="error" variant="bodySmall">
          {touched && errors ? errors : ""}
        </Typography>
      </Grid>
    </Grid>
  )
}

const PeoplePickerResultList = ({ searchResult, onSelect, value, formRef, setSearchString }) => {
  const { t } = useTranslation("translation", { keyPrefix: "learning" })
  const classes = useStyles()
  return (
    value && (
      <List className={classes.list}>
        {searchResult.status === 1 ? (
          <ListItem>
            <Typography variant="paragraphMedium">{t(`admin.participants.searching`)}</Typography>
          </ListItem>
        ) : null}
        {searchResult.status === 2 && searchResult.data.length === 0 && value ? (
          <ListItem>
            <Typography variant="paragraphMedium">{t(`admin.participants.no_participants_found`)}</Typography>
          </ListItem>
        ) : null}

        {searchResult.status === 2 && value
          ? searchResult.data.map((item, key) => {
              // det är ett paged result
              return (
                <ListItem
                  key={key}
                  className={classes.listItem}
                  onClick={(event) => {
                    onSelect(item, event)
                    formRef?.current.setFieldValue("userId", item.id)
                    setSearchString(item.name)
                  }}>
                  <div>
                    <Typography variant="bodySmall">{item.name}</Typography>
                    <Typography variant="caption">{item.email}</Typography>
                  </div>
                </ListItem>
              )
            })
          : null}
      </List>
    )
  )
}

const PeoplePicker = ({
  errors,
  touched,
  onSelect,
  searchUrl,
  children,
  clickedItem,
  selectedValue,
  setSelectedValue,
  formRef
}) => {
  // onSearch, define api here
  // onlistClick, and bubble up the click on list item
  const [searchResult, searchApi] = useApi()
  const [value, setValue] = useState(null)
  const [searchString, setSearchString] = useState("")
  const onSearch = (value) => {
    setSelectedValue("")
    // searches and passes result to list when done
    setValue(value)
    if (value) {
      searchApi.get(searchUrl.concat("?search=").concat(value))
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <PeoplePickerSearch
          errors={errors}
          touched={touched}
          clickedItem={clickedItem}
          setValue={setValue}
          searchString={searchString}
          setSearchString={setSearchString}
          onSearch={onSearch}
          formRef={formRef}
        />
      </Grid>
      <Grid item xs={12}>
        {!selectedValue && (
          <PeoplePickerResultList
            formRef={formRef}
            setSearchString={setSearchString}
            clickedItem={clickedItem}
            searchString={searchString}
            searchResult={searchResult}
            onSelect={onSelect}
            value={value}
          />
        )}
      </Grid>
      {children}
    </Grid>
  )
}

const PeoplePickerContainer = ({
  errors,
  touched,
  inititalSelectedValue,
  values,
  onChange,
  searchUrl,
  children,
  clickedItem,
  showUserError,
  formRef
}) => {
  // displays the result or lets you change it and bubble up the selection to whatever formik
  const [selectedValue, setSelectedValue] = React.useState(inititalSelectedValue)

  return (
    <>
      <PeoplePicker
        errors={errors}
        touched={touched}
        values={values}
        formRef={formRef}
        showUserError={showUserError}
        clickedItem={clickedItem}
        children={children}
        searchUrl={searchUrl}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        onSelect={(item) => {
          setSelectedValue(item)
          onChange(item)
        }}
      />
    </>
  )
}

export default PeoplePickerContainer
