export function workorderStatusLabel(status: number) {
  switch (status) {
    case 1:
      return "Öppen"
    case 2:
      return "Skickad"
    case 3:
      return "Bokad"
    case 4:
      return "Åtgärdad"
    case 5:
      return "Avslutad"
    case 6:
      return "Avbokad"
    default:
      return "-"
  }
}
