import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"

const ReadMessageBox = ({ message, title, bgColor }) => {
  return (
    <Box>
      <Typography fontWeight={500} color="primary" variant="h5">
        {title}
      </Typography>
      <Box p={3} borderRadius={2} backgroundColor={bgColor}>
        <Typography variant="h6">{message}</Typography>
      </Box>
    </Box>
  )
}

export default ReadMessageBox
