declare module "@mui/material/styles" {
  interface PaletteColor {
    500?: string
  }

  interface SimplePaletteColorOptions {
    500?: string
  }

  interface TypographyVariants {
    bodyLarge: React.CSSProperties
    bodyMedium: React.CSSProperties
    bodySmall: React.CSSProperties
    bodyCompactLarge: React.CSSProperties
    bodyCompactMedium: React.CSSProperties
    bodyCompactSmall: React.CSSProperties
    labelLarge: React.CSSProperties
    labelMedium: React.CSSProperties
    labelSmall: React.CSSProperties
    labelSmallBold: React.CSSProperties
    paragraphLarge: React.CSSProperties
    paragraphMedium: React.CSSProperties
    paragraphSmall: React.CSSProperties
    inputValue: React.CSSProperties
    radioLabel: React.CSSProperties
    inputLabel: React.CSSProperties
    helptext: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyLarge?: React.CSSProperties
    bodyMedium?: React.CSSProperties
    bodySmall?: React.CSSProperties
    bodyCompactLarge?: React.CSSProperties
    bodyCompactMedium?: React.CSSProperties
    bodyCompactSmall?: React.CSSProperties
    labelLarge?: React.CSSProperties
    labelMedium?: React.CSSProperties
    labelSmall?: React.CSSProperties
    labelSmallBold?: React.CSSProperties
    paragraphLarge?: React.CSSProperties
    paragraphMedium?: React.CSSProperties
    paragraphSmall?: React.CSSProperties
    inputValue: React.CSSProperties
    radioLabel: React.CSSProperties
    inputLabel: React.CSSProperties
    helptext: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodyLarge: true
    bodyMedium: true
    bodySmall: true
    bodyCompactLarge: true
    bodyCompactMedium: true
    bodyCompactSmall: true
    labelLarge: true
    labelMedium: true
    labelSmall: true
    labelSmallBold: true
    paragraphLarge: true
    paragraphMedium: true
    paragraphSmall: true
    inputValue: true
    radioLabel: true
    inputLabel: true
    helptext: true
    subtitle1: false
    subtitle2: false
    body1: false
    body2: false
    caption: false
    overline: false
  }
}

const typographyStyle = {
  fontFamily: ["Poppins", "sans-serif"].join(","),
  mainTitle: {
    fontSize: "1.875rem", // 30px
    fontWeight: "600",
    lineHeight: "150%"
  },
  h1: {
    fontSize: "1.5rem", // 24px
    fontWeight: "600",
    lineHeight: "150%"
  },
  h2: {
    fontSize: "1.375rem", // 22px
    fontWeight: "700"
  },
  h3: {
    fontSize: "1.25rem", // 20px
    fontWeight: "600"
  },
  h4: {
    fontSize: "1.125rem", // 18px
    fontWeight: "600"
  },
  h5: {
    fontSize: "1rem", // 16px
    fontWeight: "600"
  },
  h6: {
    fontSize: "0.938rem", // 15px
    fontWeight: "700"
  },
  bodyLarge: {
    fontSize: "1.125rem", // 18px
    fontWeight: "400",
    lineHeight: "150%",
    display: "block"
  },
  bodyMedium: {
    fontSize: "1rem", // 16px
    fontWeight: "400",
    lineHeight: "150%",
    display: "block"
  },
  bodySmall: {
    fontSize: "0.875rem", // 14px
    fontWeight: "400",
    lineHeight: "140%",
    display: "block"
  },
  bodyCompactLarge: {
    fontSize: "1.125rem", // 18px
    fontWeight: "400",
    lineHeight: "130%",
    display: "block"
  },
  bodyCompactMedium: {
    fontSize: "1rem", // 16px
    fontWeight: "400",
    lineHeight: "130%",
    display: "block"
  },
  bodyCompactSmall: {
    fontSize: "0.875rem", // 14px
    fontWeight: "400",
    lineHeight: "130%",
    display: "block"
  },
  labelLarge: {
    fontSize: "1rem", // 18px
    fontWeight: "300",
    lineHeight: "100%"
  },
  labelMedium: {
    fontSize: "0.875rem", // 14px
    fontWeight: "400",
    lineHeight: "100%"
  },
  labelSmall: {
    fontSize: "0.75rem", // 12px
    fontWeight: "400",
    lineHeight: "150%"
  },
  labelSmallBold: {
    fontSize: "0.75rem", // 12px
    fontWeight: "600",
    lineHeight: "150%"
  },
  paragraphLarge: {
    fontSize: "1.125rem", // 18px
    fontWeight: "400",
    lineHeight: "150%",
    display: "block"
  },
  paragraphMedium: {
    fontSize: "1rem", // 16px
    fontWeight: "400",
    lineHeight: "150%",
    display: "block"
  },
  paragraphSmall: {
    fontSize: "0.875rem", // 14px
    fontWeight: "400",
    lineHeight: "140%",
    display: "block"
  },
  inputValue: {
    fontSize: "1rem", // 16px
    fontWeight: "400",
    lineHeight: "125%",
    display: "block"
  },
  radioLabel: {
    fontSize: "1rem", // 16px
    fontWeight: "500",
    lineHeight: "100%",
    display: "block",
    letterSpacing: "-0.24px"
  },
  inputLabel: {
    fontSize: "1rem", // 16px
    fontWeight: "500",
    lineHeight: "187.5%",
    display: "block",
    letterSpacing: "-0.24px"
  },
  helptext: {
    fontSize: "0.75rem", // 12px
    fontWeight: "500",
    lineHeight: "110%",
    display: "block",
    letterSpacing: "-0.18px"
  }
}
export default typographyStyle
