import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  dashHeading: {
    paddingTop: "8px",
    fontSize: "3rem",
    fontWeight: 400
  },
  redChip: {
    backgroundColor: "#FFB4B4",
    color: "#BD2121"
  },
  greenChip: {
    backgroundColor: "#BAE3C3",
    color: "#2F6C12"
  },
  yellowChip: {
    color: "#F49800",
    backgroundColor: "rgba(244, 152, 0, 0.2)"
  },
  popoverTypography: {
    padding: theme.spacing(2),
    maxWidth: "300px"
  },
  icon: {
    fontSize: "4rem",
    color: "#4BADC6"
  },
  dashItem: {
    padding: "0 2rem"
  },
  commentIcon: {
    color: "#6DC7DC !important",
    padding: "0"
  },
  commentButton: {
    padding: "0"
  },
  fullList: {
    width: "auto"
  },
  showFilterBtn: {
    color: "#ffff",
    textTransform: "none"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0 ",
    boxShadow: "0"
  },
  drawer: {
    flexShrink: 1
  },
  drawerPaper: {
    backgroundColor: "#ffff",
    width: "400px",
    top: 48
  },

  // * FilterMenuNav

  backButton: {
    textTransform: "none",
    color: "#254467		",
    backgroundColor: "var(--secondary)",
    border: "none",
    width: "30px",
    height: "30px",
    borderRadius: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  settingsBtn: {
    backgroundColor: "transparent",
    color: "var(--primary)"
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    margin: " 20px 16px 0 16px"
  },
  filterMenuIcons: {
    color: "#254467		"
  },

  // * search

  links: {
    color: "var(--color)",
    marginBottom: "16px",
    cursor: "pointer",
    border: "none"
  },
  input: {
    backgroundColor: "#F9F9F9",
    color: "#77DAFF",
    margin: "0 0 20px 0",
    borderRadius: "10px",
    padding: "0"
  },
  search: {
    margin: "0 16px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  notchedOutline: {
    border: "none"
  },

  // * status nav
  status: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "20px 16px",
    padding: "0"
  },
  statusNavBtn: {
    backgroundColor: "#F9F9F9",
    borderRadius: "0",
    width: "100%",
    border: "0",
    outline: "none",
    padding: "5px",
    cursor: "pointer"
  },
  activeButton: {
    backgroundColor: "rgba(109, 199, 220, .2)",
    borderRadius: "0",
    color: "#254467",
    width: "100%",
    border: "0",
    outline: "none",
    padding: "5px",
    cursor: "pointer"
  },
  text: {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "400"
  },
  activeText: {
    fontWeight: "600",
    color: "#254467",
    textTransform: "none",
    fontSize: "12px"
  },

  // * device list

  deviceList: {
    width: "100%",
    backgroundColor: "#ffff",
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #f1f1f1"
  },
  device: {
    padding: "4px 16px",
    color: "#000000"
  },
  listItem: {
    borderRadius: "0 !important",
    padding: "14px 0",
    alignItems: "flex-start !important"
  },
  listItemIcon: {
    paddingRight: "12px",
    paddingTop: "4px"
  },

  // * vehicle list
  vehicleList: {
    width: "100%",
    backgroundColor: "theme.palette.background.paper",
    padding: "4rem 2rem 2rem 2rem"
  },

  vehicleListHeading: {
    display: "flex",
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    padding: "0 0 2rem 0"
  },
  vehicleListHeaderIcon: {
    backgroundColor: "#4BADC6",
    borderRadius: "100vh",
    width: "45px",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "12px"
  },

  vehicleListHeaderTitle: {
    color: "#000000",
    marginBottom: "0"
  },
  margin: {
    padding: "0"
  },
  listItemText: {
    fontSize: "16px"
  },
  listItemTextPrimary: {
    color: "#000000",
    fontWeight: 600
  },
  closeMenuBtn: {
    padding: "8px",
    marginBottom: "0rem",
    width: "70px"
  }
}))
