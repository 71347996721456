import React from "react"
import { Area } from "./examresult/Area"
import { Summary } from "./examresult/Summary"

import { Box, Grid, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import { useStyles } from "../components/useStyles"
import LegacyResultDescription from "./examresult/LegacyResultDescription"

export const Page = (props) => {
  const { item } = props
  const areaCount = item.isLegacyResult === 1 ? 0 : item.areas.length
  const classes = useStyles()
  return (
    <div>
      <Box marginBottom={3}>
        <Typography variant="h2" gutterBottom>
          {item.examinationName}
        </Typography>
      </Box>

      <Grid container spacing={3} direction={"row"} className={classes.rowReverseMobile}>
        <Grid item md={7} lg={8}>
          <PaperBox marginBottom={3} marginTop={0}>
            {item.isLegacyResult !== 1 ? (
              item.areas
                .filter((x) => x.questions.length > 0)
                .map((item, key) => {
                  return <Area item={item} key={key} index={key + 1} areaCount={areaCount} />
                })
            ) : (
              <LegacyResultDescription />
            )}
          </PaperBox>
        </Grid>

        <Grid item xs={12} md={5} lg={4}>
          <Summary item={item} />
        </Grid>
      </Grid>
    </div>
  )
}
