import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  stepper: {
    background: "transparent",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 40,
    marginBottom: 40,
    display: "flex",
    justifyContent: " center"
  },
  stepIcon: {
    color: theme.palette.grey["300"]
  },
  stepIconActive: {
    color: theme.palette.primary["main"]
  },
  stepIconCompleted: {
    color: theme.palette.success["main"]
  },
  stepLabel: {
    marginTop: "-2px",
    fontSize: "2rem",
    cursor: "pointer",
    padding: 0,
    "& .MuiStepLabel-label": {
      fontSize: "16px",
      paddingBottom: "4px"
    },
    "& .MuiStepLabel-label.Mui-active": {
      borderBottom: "2px solid",
      color: theme.palette.primary["main"]
    }
  }
}))
