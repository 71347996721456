import { faPlus, faSquareUpRight, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  Autocomplete,
  BottomNavigation,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography
} from "@mui/material"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import { DatePicker } from "@mui/x-date-pickers"
import Button from "components/Button"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { Formik } from "formik"
import { allRoutes } from "generated/routes"
import moment from "moment"
import { equipmentValidationSchema } from "pages/systemadmin/pages/devices/components/validationSchema"
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"

export const EquipmentForm = ({
  onSubmit,
  editEquipment,
  result,
  feedback,
  companyResult,
  storagesResult,
  devicesResult,
  equipmentTypesResult,
  equipmentStatuses
}) => {
  const initialValues = editEquipment
    ? editEquipment
    : {
        equipmentId: "",
        companyId: 0,
        status: "",
        equipmentTypeId: null,
        subscriptionTime: null,
        equipmentDescription: "",
        mountedOnDeviceId: null,
        mountedOnStorageId: null,
        hasConnectToDevice: false,
        supplements: []
      }

  const editMode = editEquipment ? true : false

  const [selectedSwitch, setSwitch] = useState(false)
  const [supplements, setSupplements] = useState(initialValues.supplements)
  const [supplement, setNewSupplement] = useState({ alarmType: null, avlId: null })

  useEffect(() => {
    initialValues.mountedOnDeviceId ? setSwitch(true) : setSwitch(false)
  }, [initialValues.mountedOnDeviceId])

  const toggleSwitch = (setFieldValue) => {
    setSwitch(!selectedSwitch)
    setFieldValue("mountedOnDeviceId", null)
    setFieldValue("mountedOnStorageId", null)
  }

  const handleSubmit = async (values, { resetForm }) => {
    onSubmit(values)
  }

  const addSupplement = (setFieldValue, values) => {
    if (supplement.alarmType !== null && supplement.avlId !== null) {
      let newSupplements = values.supplements
      newSupplements.push({ alarmType: supplement.alarmType, avlId: supplement.avlId })
      setFieldValue("supplements", newSupplements)
      setNewSupplement({ alarmType: null, avlId: undefined })
    }
  }

  const removeSupplement = (setFieldValue, values, index) => {
    let newSupplements = values.supplements
    newSupplements.splice(index, 1)
    setFieldValue("supplements", newSupplements)
  }
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={equipmentValidationSchema}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <PaperBox>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Inställningar</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      id="equipmentTypeId"
                      options={equipmentTypesResult.data}
                      getOptionLabel={(option) => (option.name ? option.name : "")}
                      value={equipmentTypesResult.data.find((i) => i.id === values.equipmentTypeId) || ""}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id
                      }}
                      onChange={(e, value) => {
                        setFieldValue("equipmentTypeId", value?.id)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Utrustningstyp"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.equipmentTypeId && errors.equipmentTypeId ? true : false}
                          helperText={
                            touched.equipmentTypeId && errors.equipmentTypeId ? errors.equipmentTypeId : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="equipmentId"
                      label="ID"
                      value={values.equipmentId ? values.equipmentId : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.equipmentId && errors.equipmentId ? true : false}
                      helperText={touched.equipmentId && errors.equipmentId ? errors.equipmentId : null}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      id="status"
                      options={equipmentStatuses.data}
                      getOptionLabel={(option) => (option.name ? option.name : "")}
                      value={equipmentStatuses.data.find((i) => i.id === values.status) || ""}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id
                      }}
                      onChange={(e, value) => {
                        setFieldValue("status", value?.id)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          variant="outlined"
                          name="status"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.status && errors.status ? true : false}
                          helperText={touched.status && errors.status ? errors.status : null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {equipmentTypesResult.data.findIndex(
                      (i) => i.id === values.equipmentTypeId && i.equipmentTypeName === "Positioning"
                    ) !== -1 && (
                      <Box backgroundColor={theme.palette.grey[100]} p={2} marginBottom={2}>
                        <Box>
                          <Typography variant="h5">Teltonika</Typography>
                          <Typography variant="bodySmall" marginBottom={2}>
                            Ange vilka larmtyper utrustningen har och vilket AVL ID som anger att det är den
                            larmtypen.
                          </Typography>
                        </Box>
                        <Box marginY={3} p={2} backgroundColor={theme.palette.background.paper}>
                          <Typography variant="h5">Lägg till larmtyp</Typography>
                          <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={5} sm={7}>
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-label="alarmType"
                                  name="alarmType"
                                  value={supplement.alarmType}
                                  onChange={(e) => {
                                    const value =
                                      e.target.value === "" ? null : Number.parseInt(e.target.value)
                                    setNewSupplement({ ...supplement, alarmType: value })
                                  }}>
                                  <FormControlLabel value={1} control={<Radio />} label="Alkolås" />
                                  <FormControlLabel value={2} control={<Radio />} label="Startspärr" />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={5} sm={3}>
                              <FormControl>
                                <TextField
                                  autoComplete="off"
                                  sx={{ background: theme.palette.background.paper }}
                                  fullWidth
                                  id="supplementavlId"
                                  name="supplementavlId"
                                  label="AVL ID"
                                  value={values.supplementavlId}
                                  type="number"
                                  placeholder="0 - 85000"
                                  onChange={(e) => {
                                    setNewSupplement({ ...supplement, avlId: e.target.value })
                                  }}
                                  variant="outlined"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                onClick={() => addSupplement(setFieldValue, values)}
                                color="primary">
                                <FontAwesomeIcon icon={faPlus} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                        {supplements.map((data, index) => (
                          <Box
                            gap={2}
                            borderBottom={supplements.length - 1 !== index ? 1 : 0}
                            borderColor="#EEEEEE"
                            mb={supplements.length - 1 !== index ? 2 : 0}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center">
                              <Grid item xs={7}>
                                {data.alarmType === 2 ? "Startspärr" : "Alkolås"}
                              </Grid>
                              <Grid item xs={3}>
                                {data.avlId}
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    removeSupplement(setFieldValue, values, index)
                                  }}
                                  color="primary">
                                  <FontAwesomeIcon variant="contained" icon={faTrash} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ marginY: 3 }} />
                    <Typography variant="h4">Faktureringsunderlag</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => setFieldValue("ownedByCustomer", e.target.checked)}
                          checked={values.ownedByCustomer}
                        />
                      }
                      label="Kund är ägare till utrustning"
                    />{" "}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Autocomplete
                      id="companyId"
                      options={companyResult.data}
                      getOptionLabel={(option) => (option.name ? option.name : "")}
                      value={companyResult.data.find((i) => i.id === values.companyId) || ""}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id
                      }}
                      onChange={(e, value) => {
                        setFieldValue("companyId", value?.id)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Bolag"
                          variant="outlined"
                          name="companyId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.companyId && errors.companyId ? true : false}
                          helperText={touched.companyId && errors.companyId ? errors.companyId : null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DatePicker
                      value={values?.subscriptionTime !== null ? moment(values?.subscriptionTime) : null}
                      onChange={(value) => {
                        setFieldValue("subscriptionTime", value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={touched.subscriptionTime && errors.subscriptionTime ? true : false}
                          helperText={
                            touched.subscriptionTime && errors.subscriptionTime
                              ? errors.subscriptionTime
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="equipmentDescription"
                      label="Beskrivning"
                      value={values.equipmentDescription}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </PaperBox>
            </Grid>
            <Grid item md={4}>
              <PaperBox>
                <Grid item xs={12}>
                  <Typography variant="h4" marginBottom={1}>
                    Koppla utrustning
                  </Typography>
                  <Typography variant="bodySmall">
                    Utrustning måste vara kopplad till en enhet eller till lagerplats
                  </Typography>
                  <FormControlLabel
                    control={<Switch onChange={() => toggleSwitch(setFieldValue)} checked={selectedSwitch} />}
                    label="Koppla till enhet"
                  />

                  <Box marginTop={2}>
                    <Stack
                      direction="row"
                      spacing={2}
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center">
                      <Autocomplete
                        style={{ width: "100%" }}
                        id="mountedOnDeviceId"
                        options={devicesResult.data}
                        getOptionLabel={(option) => (option.deviceId ? option.deviceId : "")}
                        value={devicesResult.data.find((i) => i.id === values.mountedOnDeviceId) || null}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id
                        }}
                        onChange={(e, value) => {
                          setFieldValue("mountedOnDeviceId", value?.id)
                        }}
                        disabled={!selectedSwitch}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="mountedOnDeviceId"
                            label="Sök enhet"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              touched.mountedOnDeviceId && errors.mountedOnDeviceId
                                ? errors.mountedOnDeviceId
                                : null
                            }
                            error={touched.mountedOnDeviceId && errors.mountedOnDeviceId ? true : false}
                          />
                        )}
                      />
                      {values.mountedOnDeviceId && (
                        <Box
                          color={theme.palette.primary["main"]}
                          component={NavLink}
                          target="_blank"
                          to={allRoutes.SystemadminDevicesRegisterEdit(values.mountedOnDeviceId)}>
                          <FontAwesomeIcon icon={faSquareUpRight} />
                        </Box>
                      )}
                    </Stack>
                  </Box>
                  <Box marginTop={2}>
                    <Stack
                      direction="row"
                      spacing={2}
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center">
                      <Autocomplete
                        style={{ width: "100%" }}
                        id="mountedOnStorageId"
                        options={storagesResult.data}
                        getOptionLabel={(option) => (option.name ? option.name : "")}
                        value={storagesResult.data.find((i) => i.id === values.mountedOnStorageId) || null}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id
                        }}
                        onChange={(e, value) => {
                          setFieldValue("mountedOnStorageId", value?.id)
                        }}
                        disabled={selectedSwitch}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Lagerplats"
                            variant="outlined"
                            name="mountedOnStorageId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              touched.mountedOnStorageId && errors.mountedOnStorageId
                                ? errors.mountedOnStorageId
                                : null
                            }
                            error={touched.mountedOnStorageId && errors.mountedOnStorageId ? true : false}
                          />
                        )}
                      />
                      {values.mountedOnStorageId && (
                        <Box
                          color={theme.palette.primary["main"]}
                          component={NavLink}
                          target="_blank"
                          to={allRoutes.SystemadminDevicesStorageEdit(values.mountedOnStorageId)}>
                          <FontAwesomeIcon icon={faSquareUpRight} />
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Grid>
              </PaperBox>
            </Grid>
          </Grid>
          <Box marginTop={15}></Box>
          <Box sx={{ position: "fixed", bottom: 8, left: 0, right: 15 }} elevation={3}>
            <BottomNavigation
              sx={{ justifyContent: "flex-end", backgroundColor: "transparent", height: "auto" }}>
              <Button color="primary" type="submit" result={result} feedback={feedback}>
                {editMode ? "Spara" : "Lägg till"}
              </Button>
            </BottomNavigation>
          </Box>
        </form>
      )}
    </Formik>
  )
}
