import React from "react";
import { List, ListItemText, ListItemIcon, ListItem } from "@mui/material";
import LoadingTypography from "components/skeleton/LoadingTypography";
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from "./useStyles";
import { VehicleLoadingItem } from "./VehicleLoadingItem";

const VehicleLoadingform = () => {
    const numberOfSkeletonItems = [1, 2, 3, 4, 5, 6, 7];
    const classes = useStyles();

    return (
        <List className={classes.vehicleList}>
            <Skeleton className={classes.closeMenuBtnLoading} animation="wave" variant="rectangular" width={"40px"} height={"40px"}></Skeleton>
            <ListItem className={classes.vehicleListHeading}>
                <ListItemIcon className={classes.skeletonHeader}>
                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                </ListItemIcon>
                <ListItemText
                    primary={<LoadingTypography height={30} width={80} variant="caption" />}
                    secondary={<LoadingTypography width={100} variant="caption" />}
                />
            </ListItem>
            {numberOfSkeletonItems.map((item) => {
                return <VehicleLoadingItem />;
            })}
        </List>
    );
};
export default VehicleLoadingform;
