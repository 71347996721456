import moment from "moment"

export interface ModalState {
  actionType: ActionType
  isOpen: boolean
  title: string
  message: string
}

export interface BookingData {
  id?: number
  customFieldVehicleValueId: number
  carWorkshopId: number
  dateTime: moment.Moment
  inspectionType: string
}

export enum ActionType {
  Save,
  Cancel
}