import { Container } from "@mui/material"
import { useApi } from "auth/useApi3"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect } from "react"
import { Page } from "./components/Page"

const AdminLearningResultDetails = (props) => {
  const { match } = props

  const [examResultResult, examResultFuncs] = useApi()

  useEffect(() => {
    examResultFuncs.get(apiEndpoints.learningadmin.getexamresultbyid.replace("{id}", match.params.id))
  }, [examResultFuncs, match.params.id])

  return (
    <div>
      <Container>{examResultResult.status === 2 && <Page item={examResultResult.data} />}</Container>
    </div>
  )
}
export default AdminLearningResultDetails
