import { ISelectListItem } from "../violation.interface"

export const reasonOptions: ISelectListItem[] = [
  {
    name: "Oförutsedd händelse (olycka, väderförhållande, trasig buss...)",
    id: 0
  },
  {
    name: "Eget misstag (glömt trycka rast, tagit för kort rast, inte tagit ansvar för korrekt dygnsvila, glömt kort på bussen...)",
    id: 1
  },
  {
    name: "Annan anledning",
    id: 2
  },
  {
    name: "Körde åt annat företag - ej vår överträdelse",
    id: 3
  }
]

export const exceptionOptions: ISelectListItem[] = [
  {
    name: "Linje under 50",
    id: 0
  },
  {
    name: "Verkstad",
    id: 1
  },
  {
    name: "Reservrutin",
    id: 2
  }
]
export const statusOptions: ISelectListItem[] = [
  {
    name: "Aktiv",
    id: 0
  },
  {
    name: "Pågående",
    id: 1
  },
  {
    name: "Avslutad",
    id: 2
  }
]
