import React, { createContext, useState } from "react";
import { useApi } from "auth/useApi3";
import { apiEndpoints } from "generated/apiEndpoints";
import moment from "moment";

export const MapContext = createContext();

const mergeDevicesAndData = (current, incoming) => {
    let result = [];

    // det är incoming (serverdatat) som styr vilka device som "finns" öht
    // sen kan vi petat på devices (t.ex visibleOnMap) som finns i current som vi vill behålla
    for (var i = 0; i < incoming.length; i++) {
        const a = incoming[i]; // det var "unsafe" att referera till incoming[i] direkt i filter tyckte vscode
        var existing = current.filter((x) => x.id === a.id)[0];
        incoming[i].visibleOnMap = true;
        if (existing && !existing.visibleOnMap) {
            // overrida false om den redan finns och är satt till false
            // annars alla nya är visible när de laddas
            incoming[i].visibleOnMap = false;
        }

        result.push(incoming[i]);
    }

    return result;
};

const getHours = (startDate) => {
    let hours = [
        { title: "30 min", date: startDate.add(30, "minutes").format("YYYY-MM-DD HH:mm") },
        { title: "45 min", date: startDate.add(15, "minutes").format("YYYY-MM-DD HH:mm") },
        { title: "1 h", date: startDate.add(15, "minutes").format("YYYY-MM-DD HH:mm") },
        { title: "2 h", date: startDate.add(1, "hours").format("YYYY-MM-DD HH:mm") },
        { title: "3 h", date: startDate.add(1, "hours").format("YYYY-MM-DD HH:mm") },
        { title: "4 h", date: startDate.add(1, "hours").format("YYYY-MM-DD HH:mm") },
        { title: "6 h", date: startDate.add(2, "hours").format("YYYY-MM-DD HH:mm") },
        { title: "8 h", date: startDate.add(2, "hours").format("YYYY-MM-DD HH:mm") },
        { title: "12 h", date: startDate.add(4, "hours").format("YYYY-MM-DD HH:mm") },
        { title: "24 h", date: startDate.add(12, "hours").format("YYYY-MM-DD HH:mm") }
    ];
    let dayAfter = moment().add(1, "day").format("YYYY-MM-DD HH:mm");
    startDate.isBetween(moment(), dayAfter) &&
        hours.unshift({
            title: "Just nu",
            date: moment().format("YYYY-MM-DD HH:mm:ss")
        });
    return hours;
};

export const MapContextProvider = (props) => {
    const [mapType, setMapType] = useState("roadmap");
    const [openDeviceBar, setOpenDeviceBar] = useState(false);
    const [loadingMarkers, setLoadingMarkers] = useState(false);
    const mapRef = React.useRef();

    const [devices, setDevices] = useState([]);
    const [historyPositions, setPositions] = useState([]);
    const [deviceDataResult, deviceDataApi] = useApi();

    const [historyPositionsData, positionsDataApi] = useApi([]);
    const [startDate, setStartDate] = useState(moment().local().add(-0.25, "hours").format());
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD HH:mm"));
    const [hourList, setHourList] = useState([]);
    const [activeMarker, setActiveMarker] = useState();

    // get devices on first load
    React.useEffect(() => {
        deviceDataApi.get(apiEndpoints.map.getdevicelist);

        return () => {
            deviceDataApi.leave();
        };
    }, [deviceDataApi]);

    // get devices every 5 seconds
    React.useEffect(() => {
        const interval = setInterval(() => {
            deviceDataApi.get(apiEndpoints.map.getdevicelist);
        }, 5000);

        return () => {
            deviceDataApi.leave();
            clearInterval(interval);
        };
    }, [deviceDataApi]);

    React.useEffect(() => {
        getData();
    }, [activeMarker]);

    React.useEffect(() => {
        setHourList(getHours(moment(startDate)));
        getData();
    }, [startDate]);

    React.useEffect(() => {
        getData();
    }, [endDate]);

    React.useEffect(() => {
        setEndDate(hourList[0]?.date);
    }, [hourList]);

    // when we got devicedata update devicestate
    React.useEffect(() => {
        if (deviceDataResult.status === 2) {
            setDevices((prevState) => mergeDevicesAndData(prevState, deviceDataResult.data));
        }
    }, [deviceDataResult]);

    const onDeviceListLabelClick = (device) => {
        if (device.currentPosition) {
            mapRef.current.panTo({
                lat: device.currentPosition.lat,
                lng: device.currentPosition.long
            });

            mapRef.current.setZoom(14);
            setActiveMarker(device);
        }
    };
    const onDeviceListEyeClick = (device) => {
        setDevices((prevState) => {
            const newArray = [];
            for (var i = 0; i < prevState.length; i++) {
                if (prevState[i].id === device.id) {
                    prevState[i].visibleOnMap = !prevState[i].visibleOnMap;
                }
                newArray.push(prevState[i]);
            }
            return newArray;
        });
    };

    const onMarkerClick = (device) => {
        setActiveMarker(device);
    };

    const getData = () => {
        if (activeMarker) {
            setLoadingMarkers(true);
            if (moment(startDate).isValid() && moment(endDate).isValid()) {
                var duration = moment.duration(moment(endDate).diff(moment(startDate)));
                var hours = duration.asHours();
                if (hours < 25) {
                    positionsDataApi.post(apiEndpoints.map.getdevicepositions, {
                        deviceId: activeMarker.id,
                        from: moment(startDate).format(),
                        to: moment(endDate).format()
                    });
                    return () => {
                        setLoadingMarkers(false);
                        positionsDataApi.leave();
                    };
                }
            }
            setLoadingMarkers(false);
        }
    };

    React.useEffect(() => {
        if (historyPositionsData.status === 2) {
            setPositions(historyPositionsData.data);
        }
    }, [historyPositionsData]);

    const toggleDeviceBar = () => {
        setOpenDeviceBar(!openDeviceBar);
    };

    const resetEndDate = () => {
        setHourList(getHours(moment(startDate)));
        getData();
    };
    const resetHistory = () => {
        setActiveMarker(null);
    };
    return (
        <MapContext.Provider
            value={{
                onDeviceListLabelClick,
                onDeviceListEyeClick,
                onMarkerClick,
                mapType,
                devices,
                toggleDeviceBar,
                openDeviceBar,
                setOpenDeviceBar,
                setMapType,
                mapRef,
                historyPositions,
                historyPositionsData,
                loadingMarkers,
                endDate,
                setEndDate,
                startDate,
                setStartDate,
                activeMarker,
                hourList,
                resetEndDate,
                resetHistory,
                setLoadingMarkers
            }}
        >
            {props.children}
        </MapContext.Provider>
    );
};
