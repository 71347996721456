import {
  faCalendarCirclePlus,
  faCalendarDay,
  faCircleCheck,
  faCircleXmark,
  faClockRotateLeft
} from "@fortawesome/pro-solid-svg-icons"

export function checkStatusIcon(status: number) {
  switch (status) {
    case 1:
    case 3:
      return faCalendarCirclePlus
    case 2:
      return faCalendarDay
    case 4:
      return faCircleCheck
    case 5:
      return faCircleXmark
    case 6:
      return faClockRotateLeft
    default:
      return undefined
  }
}