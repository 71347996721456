import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Typography } from "@mui/material"
import { AppContext } from "components/AppContextProvider"
import moment from "moment"
import { useContext } from "react"
import { TodoItemStyled } from "./style/TodoItem.styled"
import { ITodoItemProps } from "./todolist.interface"
import { VehicleCheckStatus } from "../../helpers/Checks/ChecksStatusColor"

const TodoItem = (item: ITodoItemProps) => {
  const { type, end, start, title, subtitle, iconProps, status } = item
  const { selectedLanguage } = useContext(AppContext)
  switch (type) {
    case "month":
      return <Box alignItems="flex-start"></Box>

    default:
      return (
        <TodoItemStyled
          display="flex"
          alignItems="center"
          onClick={() => item.onClick && item.onClick(item)}
          status={status}
          typeId={type}
        >
          <Box padding={1}>
            {iconProps && iconProps.icon && <FontAwesomeIcon icon={iconProps.icon} color={iconProps.color} style={{
              transform: status === VehicleCheckStatus.waiting ? "scaleX(-1)" : ""
            }} />}
          </Box>
          <Box padding={1}>
            <Box>
              <Typography component="span" variant="labelSmall" color="text.secondary">
                {moment(start).locale(selectedLanguage).format("D MMM YYYY")}
                {end !== null && (
                  <>
                    {moment(start).isValid() &&
                      ` | ${moment(start).locale(selectedLanguage).format("HH:mm")}`}
                    {moment(end).isValid() && `-${moment(end).locale(selectedLanguage).format("HH:mm")}`}
                  </>
                )}
              </Typography>
              <Typography component="span" variant="bodySmall" color="text.primary">
                {title} {subtitle}
              </Typography>
            </Box>
          </Box>
        </TodoItemStyled>
      )
  }
}
export default TodoItem
