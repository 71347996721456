import { faUser } from "@fortawesome/pro-solid-svg-icons"
import { Grid, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import { CircleIcon } from "components/icons/CircleIcon"
import React from "react"
import { useStyles } from "./useStyles"

export const VehicleAlarmDashboardCard = ({ title, icon, itemLength, vehicleAlarmsResult }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} md={4} lg={3} xl={2} className={classes.dashCard}>
      <PaperBox paddingX={3} paddingY={3} marginY={0}>
        <ListItem disableGutters className={classes.padding0}>
          <ListItemAvatar>
            <CircleIcon icon={icon ? icon : faUser} color="primary" size="lg" sx={{ marginRight: "15px" }} />
          </ListItemAvatar>
          <ListItemText>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography color="secondary" variant="bodyMedium" className={classes.textGrey}>
                  {title}
                </Typography>
                {/* <Typography className={classes.blackText} variant="h2">{summaryResult.status === 2 && summaryResult.data.brokenInStorage}</Typography> */}
                <Typography className={classes.blackText} variant="h1">
                  {itemLength(title)}
                </Typography>
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>
      </PaperBox>
    </Grid>
  )
}
