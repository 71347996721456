import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { backgroundStatusColor } from "../../Calendar/helpers/calendarStatusEvent"
import { VehicleCheckStatus } from "../../../helpers/Checks/ChecksStatusColor"

interface TodoItemProps {
  status?: VehicleCheckStatus
  typeId?: string | null
}

export const TodoItemStyled = styled(Box)<TodoItemProps>(({ theme, status, typeId }) => ({
  bgcolor: "background.paper",
  borderRadius: "8px",
  border: "1px solid #F0F0F0;",
  marginBottom: "8px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: backgroundStatusColor(typeId, status)
  }
}))
