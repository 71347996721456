import makeStyles from "@mui/styles/makeStyles"
const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0
  },
  subItem: {
    padding: 0,
    paddingLeft: 45
  },
  flexColumn: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap",
      alignItems: "center"
    }
  }
}))

export default useStyles
