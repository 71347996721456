import moment from "moment";
import React, { createContext, useRef } from "react";

export const ImportContractContext = createContext();

const convertData = (header, row) => {
    switch (header.dataType) {
        case "string":
            return row[header.Header];
        case "number":
            return parseFloat(row[header.Header]) ? parseFloat(row[header.Header]) : 0;
        case "date":
            return moment(row[header.Header]).local().format("YYYY-MM-DD HH:mm");
        default:
            return row[header.Header];
    }
};

export const ImportContractFormStateProvider = ({ children }) => {
    const values = useRef({
        current: {}
    });
    const updateImportData = (data) => {
        values.current = data;
    };
    const getImportedData = () => {
        return values;
    };

    const excelHeaders = useRef({
        current: {}
    });
    const addExcelHeaders = (data) => {
        excelHeaders.current = data;
    };
    const getExcelHeaders = (data) => {
        return excelHeaders.current;
    };

    const selectedData = useRef({
        fields: [],
        columns: [
            {
                name: "Radnr",
                id: "rowNr",
                dataType: "string",
                fieldName: ""
            },
            {
                name: "Internnr",
                id: "internNumber",
                dataType: "string",
                fieldName: ""
            },
            {
                name: "Kund",
                id: "name",
                dataType: "string",
                fieldName: ""
            },
            {
                name: "Avtalsnr",
                id: "assignmentNr",
                dataType: "string",
                fieldName: ""
            },
            {
                name: "Timmar",
                id: "nrOfHours",
                dataType: "number",
                fieldName: ""
            },
            {
                name: "Startdatum ",
                id: "dateFrom",
                dataType: "date",
                fieldName: ""
            },
            {
                name: "Slutdatum ",
                id: "dateTo",
                dataType: "date",
                fieldName: ""
            },
            {
                name: "Underavtal ",
                id: "subContract",
                dataType: "string",
                fieldName: ""
            }
        ]
    });
    const getSelectedData = () => {
        return selectedData;
    };

    const updateIdendifikation = (data) => {
        updateColumns("internNumber", "string", data);
    };

    const updateColumns = (value, dataType, fieldName) => {
        if (!value) {
            let removeFieldName = excelHeaders.current.find((x) => x.accessor === fieldName);
            if (removeFieldName) {
                removeFieldName.fieldName = null;
                removeFieldName.dataType = null;
            }
        }
        if (fieldName && value) {
            let removeFieldName = excelHeaders.current.find((x) => x.fieldName === value);
            if (removeFieldName) {
                removeFieldName.fieldName = null;
                removeFieldName.dataType = null;
            }

            let column = excelHeaders.current.find((x) => x.Header === fieldName);
            if (column) {
                column.fieldName = value;
                column.dataType = dataType;
            }
        }
        return excelHeaders;
    };

    const getDataToImport = () => {
        let excelData = [];
        values.current.excelData.map((row, index) => {
            const filteredRow = {};
            filteredRow["rowNr"] = index + 1;
            excelHeaders.current
                .filter((x) => x.fieldName !== null)
                .map((header) => {
                    filteredRow[header.fieldName] = convertData(header, row);
                });

            filteredRow["internNumber"] !== undefined && excelData.push(filteredRow);
        });
        return excelData;
    };

    return (
        <ImportContractContext.Provider
            value={{
                updateImportData,
                getImportedData,
                getSelectedData,
                getDataToImport,
                updateIdendifikation,
                updateColumns,
                addExcelHeaders,
                getExcelHeaders
            }}
        >
            {children}
        </ImportContractContext.Provider>
    );
};
