import { faCalendarCheck, faCheckCircle, faFolderOpen, faPaperPlane } from "@fortawesome/pro-solid-svg-icons"

export function issueReportStatusIcon(status: number) {
  switch (status) {
    case 1:
      return faFolderOpen
    case 2:
      return faPaperPlane
    case 3:
      return faCalendarCheck
    case 4:
      return faCheckCircle
    case 5:
      return faCheckCircle
    default:
      return undefined
  }
}
