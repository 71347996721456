import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Box, Button, Container, Grid, IconButton, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import moment from "moment"
import { Link, useHistory } from "react-router-dom"

import { workorderStatusColor } from "helpers/WorkOrder/WorkOrderStatusColor"
import { workorderStatusIcon } from "helpers/WorkOrder/WorkOrderStatusIcon"
import { workorderStatusLabel } from "helpers/WorkOrder/WorkOrderStatusLabel"
import React, { useContext, useEffect } from "react"
import Moment from "react-moment"

const WorkOrders = () => {
  const history = useHistory()
  const globalClasses = globalUseStyles()
  const loadingContext = useContext(GlobalLoaderContext)

  const [workOrdersResult, workOrdersFuncs] = useApi()

  useEffect(() => {
    workOrdersFuncs.get(apiEndpoints.issuereportsadmin.getworkorders)
    return () => {
      workOrdersFuncs.leave()
    }
  }, [workOrdersFuncs])

  useEffect(() => {
    loadingContext.setLoading(() => workOrdersResult.status === 1)
  })

  const columns = [
    {
      accessor: "id",
      Header: "Id"
    },
    {
      accessor: "regNumber",
      Header: "Regnr"
    },
    {
      accessor: "internNumber",
      Header: "Internnr"
    },
    {
      accessor: "message",
      Header: "Meddelande"
    },
    {
      accessor: "emailRecipient",
      Header: "Skickad till"
    },
    {
      accessor: "status",
      Header: "Status",
      mainFilter: true,
      Filter: SelectColumnFilter,
      FilterOptions: [
        {
          name: "Öppen",
          value: "1"
        },
        {
          name: "Skickad",
          value: "2"
        },
        {
          name: "Bokad",
          value: "3"
        },
        {
          name: "Avslutad",
          value: "5"
        },
        {
          name: "Avbokad",
          value: "6"
        }
      ],
      Cell: ({ cell }) => {
        return (
          cell.row.original.status && (
            <Box color={`${workorderStatusColor(cell.row.original.status)}.main`}>
              <FontAwesomeIcon icon={workorderStatusIcon(cell.row.original.status)} />
              <Typography sx={{ marginLeft: "5px" }} variant="caption">
                {workorderStatusLabel(cell.row.original.status)}
              </Typography>
            </Box>
          )
        )
      }
    },
    {
      accessor: "plannedDate",
      Header: "Planerad åtgärd",
      Cell: ({ cell }) => {
        return cell.row.original.plannedDate
          ? moment.utc(cell.row.original.plannedDate).local().format("YYYY-MM-DD HH:mm")
          : "-"
      }
    },
    {
      accessor: "resolvedDate",
      Header: "Åtgärdat",
      Cell: ({ cell }) => {
        return cell.row.original.resolvedDate ? (
          <Moment format="YYYY-MM-DD" globallocale="sv">
            {cell.row.original.resolvedDate}
          </Moment>
        ) : (
          "-"
        )
      }
    },

    {
      accessor: "edit",
      numeric: false,
      disablePadding: true,
      Header: "",
      align: "right",
      Cell: ({ cell }) => {
        return (
          <IconButton
            size="large"
            component={Link}
            to={allRoutes.IssuereportsWorkordersDetails(cell.row.original.id)}>
            <FontAwesomeIcon icon={faEdit} size="xs" />
          </IconButton>
        )
      }
    }
  ]

  const listRows = workOrdersResult.status === 2 ? workOrdersResult.data : []
  return (
    <div>
      <Container>
        <Box marginY={3} mb={2}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box order={2} p={1} flexGrow={2}>
              <Grid item>
                <Typography variant="h2">Arbetsordrar</Typography>
                <Typography>Administrera arbetsordrar</Typography>
              </Grid>
            </Box>
            <Box order={3} p={1}>
              <Button
                variant="contained"
                component={Link}
                to={allRoutes.IssuereportsWorkordersAdd()}
                endIcon={<AddCircleIcon />}
                color="primary">
                Skapa arbetsorder
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          cellProps={(row) => ({
            onClick: () => history.push(allRoutes.IssuereportsWorkordersDetails(row.original.id))
          })}
          columns={columns}
          rows={workOrdersResult.status === 2 && listRows}
          status={workOrdersResult.status}
        />
      </Box>
    </div>
  )
}

export default WorkOrders
