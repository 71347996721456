import { faCalendarDay, faCircleCheck, faCircleXmark, faSend } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import ClpButton from "components/Button"
import moment from "moment"
import React from "react"
import { useStyles } from "../../../components/useStyles"
import EmailBoxInput from "./EmailBoxInput"
import EmailBoxTypography from "./EmailBoxTypography"
import EmailBoxUploadedFiles from "./EmailBoxUploadedFiles"
import { statusButtonColor, statusButtonText, statusSubText } from "./helpers"

const WorkOrderEmailBox = ({ values, handleChange, handleBlur, touched, errors, submit, uploadedFiles }) => {
  const classes = useStyles()

  const statusIcon = (value) => {
    switch (value) {
      case 1:
        return null
      case 2:
        return <FontAwesomeIcon className={classes.strongBlueText} icon={faSend} size="xl" />
      case 3:
        return <FontAwesomeIcon className={classes.strongBlueText} icon={faCalendarDay} size="xl" />
      case 4:
        return <FontAwesomeIcon className={classes.strongGreenText} icon={faCircleCheck} size="xl" />
      case 5:
        return <FontAwesomeIcon className={classes.strongGreenText} icon={faCircleCheck} size="xl" />
      case 6:
        return <FontAwesomeIcon className={classes.strongRedText} icon={faCircleXmark} size="xl" />
      default:
        return null
    }
  }

  const statusText = (value) => {
    switch (value) {
      case 1:
        return (
          <Typography className={classes.strongBlueText} variant="h2">
            Skicka bokning
          </Typography>
        )
      case 2:
        return (
          <Typography className={classes.strongBlueText} variant="h2">
            Skickad
          </Typography>
        )
      case 3:
        return (
          <Typography className={classes.strongBlueText} variant="h2">
            Bekräftad
          </Typography>
        )
      case 4:
        return (
          <Typography className={classes.strongGreenText} variant="h2">
            Åtgärdad
          </Typography>
        )
      case 5:
        return (
          <Typography className={classes.strongGreenText} variant="h2">
            Avslutad
          </Typography>
        )
      case 6:
        return (
          <Typography className={classes.strongRedText} variant="h2">
            Avbokad
          </Typography>
        )
      default:
        return (
          <Typography className={classes.strongBlueText} variant="h2">
            Skicka bokning
          </Typography>
        )
    }
  }

  const statusContent = (value) => {
    switch (value) {
      case 1:
        return (
          <Box>
            <EmailBoxTypography pb={1} text="E-post" variant="h6" />
            <EmailBoxInput
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
            />
          </Box>
        )
      case 2:
        return (
          <Box>
            <EmailBoxTypography pt={2} text="Mottagare" variant="bodySmall" />
            <EmailBoxTypography text={values.emailRecipient ? values.emailRecipient : "-"} variant="h6" />
          </Box>
        )
      case 3:
        return (
          <Box>
            <EmailBoxTypography text="Planerad åtgärd" variant="bodySmall" />
            <EmailBoxTypography
              text={
                moment(values.plannedDate).isValid()
                  ? moment.utc(values.plannedDate).local().format("YYYY-MM-DD HH:mm")
                  : "-"
              }
              variant="h6"
            />
            <EmailBoxTypography pt={2} text="Mottagare" variant="bodySmall" />
            <EmailBoxTypography text={values.emailRecipient ? values.emailRecipient : "-"} variant="h6" />
          </Box>
        )
      case 4:
        return (
          <Box>
            <EmailBoxTypography text="Åtgärdad" variant="bodySmall" />
            <EmailBoxTypography
              text={
                moment(values.resolvedDate).isValid()
                  ? moment.utc(values.resolvedDate).local().format("YYYY-MM-DD HH:mm")
                  : "-"
              }
              variant="h6"
            />
            <EmailBoxTypography pt={2} text="Mottagare" variant="bodySmall" />
            <EmailBoxTypography text={values.emailRecipient ? values.emailRecipient : "-"} variant="h6" />
          </Box>
        )
      case 5:
        return (
          <Box>
            <EmailBoxTypography text="Åtgärdad" variant="bodySmall" />
            <EmailBoxTypography
              text={
                moment(values.resolvedDate).isValid()
                  ? moment.utc(values.resolvedDate).local().format("YYYY-MM-DD HH:mm")
                  : "-"
              }
              variant="h6"
            />
            <EmailBoxTypography pt={2} text="Mottagare" variant="bodySmall" />
            <EmailBoxTypography text={values.emailRecipient ? values.emailRecipient : "-"} variant="h6" />
          </Box>
        )
      case 6:
        return (
          <Box mb={2}>
            <EmailBoxTypography pt={2} text="Mottagare" variant="bodySmall" />
            <EmailBoxTypography text={values.emailRecipient ? values.emailRecipient : "-"} variant="h6" />
          </Box>
        )

      default:
        return (
          <Box>
            <EmailBoxTypography pb={1} text="E-post" variant="h6" />
            <EmailBoxInput
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
            />
          </Box>
        )
    }
  }

  return (
    <>
      <Grid item xs={12} md={12} lg={4} pl={2} direction="row" justifyContent="space-between">
        <Box
          className={
            values.status === 4
              ? classes.greenEmailBox
              : values.status === 5
              ? classes.greenEmailBox
              : values.status === 6
              ? classes.redEmailBox
              : classes.blueEmailBox
          }>
          <Box pt={4} pb={4} pl={3} pr={3}>
            <Grid container direction="row" alignItems="center" mb={3}>
              {statusIcon(values.status)}
              {statusText(values.status)}
            </Grid>
            {statusContent(values.status)}

            {values.status < 5 && (
              <Box mt={3} mb={2}>
                <ClpButton
                  color={statusButtonColor(values.status)}
                  variant="contained"
                  type="button"
                  onClick={() => submit(true)}
                  endIcon={
                    values.status < 2 ? <FontAwesomeIcon className={classes.sendIcon} icon={faSend} /> : null
                  }>
                  {statusButtonText(values.status)}
                </ClpButton>
              </Box>
            )}

            {values.status !== 5 && (
              <Typography variant="bodySmall">{statusSubText(values.status)}</Typography>
            )}
          </Box>
        </Box>

        <Box mt={4}>
          {uploadedFiles && (
            <>
              <Typography pb={1} variant="h6">
                Uppladdade filer
              </Typography>
              <Card sx={{ padding: "20px" }}>
                {uploadedFiles?.map((file) => (
                  <EmailBoxUploadedFiles file={file} uploadedFiles={uploadedFiles} />
                ))}
              </Card>
            </>
          )}
        </Box>
      </Grid>
    </>
  )
}

export default WorkOrderEmailBox
