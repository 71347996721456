import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Container, Grid, IconButton, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import moment from "moment"
import { VehicleAlarmChip } from "pages/vehiclealarm/components/VehicleAlarmChip"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"

const ViolationList = () => {
  const history = useHistory()
  const globalClasses = globalUseStyles()
  const loadingContext = useContext(GlobalLoaderContext)
  const [violationResult, violationApi] = useApi() as [IApiResult, IApiFunc]
  const { t } = useTranslation("translation", { keyPrefix: "violation" })

  useEffect(() => {
    violationApi.get(apiEndpoints.violation.get)
    return () => {
      violationApi.leave()
    }
  }, [])

  useEffect(() => {
    loadingContext.setLoading(() => violationResult.status === 1)
  })

  const statusString = (value: any) => {
    switch (value) {
      case 1:
        return "Pågående"
      case 2:
        return "Avslutad"
      default:
        return "Aktiv"
    }
  }
  const status = (value: any) => {
    switch (value) {
      case 1:
        return <VehicleAlarmChip status={statusString(1)} />
      case 2:
        return <VehicleAlarmChip status={statusString(2)} />
      default:
        return <VehicleAlarmChip status={statusString(0)} />
    }
  }

  const columns = [
    {
      accessor: "name",
      Header: t(`violation_driver`, ``),
      Cell: ({ cell }: any) => {
        return <Typography>{cell.row.original.name ?? "(Saknar kopplat fordonskort)"}</Typography>
      }
    },
    {
      accessor: "violationDate",
      Header: t(`violation_date`, ``),
      Cell: ({ cell }: any) => {
        return cell.row.original.violationDate
          ? moment.utc(cell.row.original.violationDate).local().format("YYYY-MM-DD HH:mm")
          : "-"
      }
    },
    {
      accessor: "createdDate",
      Header: t(`created_in_tp`, ``),
      Cell: ({ cell }: any) => {
        return cell.row.original.createdDate
          ? moment.utc(cell.row.original.createdDate).local().format("YYYY-MM-DD HH:mm")
          : "-"
      }
    },
    {
      accessor: "updatedByDriver",
      Header: t(`updated_by_driver`, ``),
      Cell: ({ cell }: any) => {
        return cell.row.original.updatedByDriver
          ? moment.utc(cell.row.original.updatedByDriver).local().format("YYYY-MM-DD HH:mm")
          : "-"
      }
    },
    {
      accessor: "updatedByName",
      Header: t(`updated_by_name`, ``),
      Cell: ({ cell }: any) => {
        return <Typography>{cell.row.original.updatedBy}</Typography>
      }
    },
    {
      accessor: "updatedDate",
      Header: t(`updated`, ``),
      Cell: ({ cell }: any) => {
        return cell.row.original.updatedDate
          ? moment.utc(cell.row.original.updatedDate).local().format("YYYY-MM-DD HH:mm")
          : "-"
      }
    },
    {
      accessor: "importType",
      Header: t(`import_type`, ``),
      Cell: ({ cell }: any) => {
        return <Typography>{cell.row.original.importType}</Typography>
      }
    },
    {
      accessor: (row: any) => {
        return statusString(row.status)
      },
      Filter: SelectColumnFilter,
      Cell: ({ cell }: any) => {
        return status(cell.row.original.status)
      },
      Header: "Status",
      mainFilter: true,
      FilterHeader: "Status",
      align: "center",
      FilterOptions: [
        {
          name: "Aktiv",
          value: "Aktiv"
        },
        {
          name: "Pågående",
          value: "Pågående"
        },
        {
          name: "Avslutad",
          value: "Avslutad"
        }
      ]
    },
    {
      accessor: "edit",
      numeric: false,
      disablePadding: true,
      Header: "",
      align: "right",
      Cell: ({ cell }: any) => {
        return (
          <IconButton size="large" component={Link} to={allRoutes.ViolationDetails(cell.row.original.id)}>
            <FontAwesomeIcon icon={faEdit as IconProp} size="xs" />
          </IconButton>
        )
      }
    }
  ]

  const listRows = violationResult.status === 2 ? violationResult.data : []

  return (
    <div>
      <Container>
        <Box marginY={3} mb={2}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box order={2} p={1} flexGrow={2}>
              <Grid item>
                <Typography variant="h2">{t(`violations`, ``)}</Typography>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          cellProps={(row: any) => ({
            onClick: () => history.push(allRoutes.ViolationDetails(row.original.id))
          })}
          columns={columns}
          rows={violationResult.status === 2 && listRows}
          status={violationResult.status}
        />
      </Box>
    </div>
  )
}

export default ViolationList
