import { Box, Divider, Grid } from "@mui/material"
import PaperBox from "components/PaperBox"

import LoadingBox from "components/skeleton/LoadingBox"
import LoadingTypography from "components/skeleton/LoadingTypography"
import React from "react"

export const ActionCardLoading = (props) => {
  return (
    <PaperBox {...props}>
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item sm={10}>
          <LoadingBox width="250px" />
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <LoadingTypography variant="bodyMedium" />
          </Grid>

          <LoadingTypography variant="h1" />
          <LoadingTypography variant="bodyMedium" />
          <Box marginY={2}>
            <Divider light />
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
        <Grid item>
          <LoadingBox width="150px" />
        </Grid>
        <Grid item>
          <LoadingBox width="150px" />
        </Grid>
      </Grid>
    </PaperBox>
  )
}
