import ClpTable from "components/ClpTable"
import { useMemo } from "react"

const ImportResult = ({ result }) => {
  const columns = useMemo(
    () => [
      {
        accessor: "legacyExamImportDto.username",
        Header: "Användarnamn"
      },
      {
        accessor: "legacyExamImportDto.examName",
        Header: "Utbildning"
      },
      {
        accessor: "legacyExamImportDto.score",
        Header: "Poäng"
      },
      {
        accessor: "legacyExamImportDto.urlToPdf",
        Header: "Url"
      },
      {
        accessor: (cell) => {
          return cell.legacyExamImportDto.pass ? "Ja" : "Nej"
        },
        Header: "Godkänd"
      },
      {
        accessor: "legacyExamImportDto.examDateSwedishLocal",
        Header: "Datum"
      },
      {
        accessor: (cell) => {
          return cell.successImport ? "Importerad" : "Trasig"
        },
        Header: "Importstatus"
      },
      {
        accessor: (cell) => {
          return cell.isValid ? "OK" : "Inte OK"
        },
        Header: "Valideringstatus"
      },
      {
        accessor: (cell) => {
          return cell.messages.map((item) => {
            return <span>{item}; </span>
          })
        },
        Header: "Meddelanden"
      }
    ],
    []
  )

  return <ClpTable rows={result?.status === 2 && result?.data} status={result?.status} columns={columns} />
}

export default ImportResult
