import React from "react"
import { ExamFormStateProvider } from "./ExamFormStateProvider"

import { ExamFormMain } from "./ExamFormMain"

export const ExamFormContainer = (props) => {
  const { exam, examinationId, examinationName, examQuestionCount } = props

  return (
    <ExamFormStateProvider>
      <ExamFormMain
        data={exam}
        examQuestionCount={examQuestionCount}
        examinationId={examinationId}
        examinationName={examinationName}
      />
    </ExamFormStateProvider>
  )
}
