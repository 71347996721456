import { Autocomplete, Container, FormControl, Grid, TextField, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import SubTabs from "components/SubTabs"
import { TabPanel } from "components/navigation/TabPanel"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import AdminDrivingTypes from "pages/violation/components/AdminDrivingTypes"
import AdminEmailSetting from "pages/violation/components/AdminEmailSettings"
import AdminUploadIdhaFile from "pages/violation/components/AdminUploadIdhaFile"
import { ISelectListItem } from "pages/violation/violation.interface"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const ViolationAdmin = () => {
  const { t } = useTranslation("translation", { keyPrefix: "violation.admin" })
  const [companiesResult, companiesApi] = useApi() as [IApiResult, IApiFunc]
  const [companyResult, companyApi] = useApi() as [IApiResult, IApiFunc]
  const [companies, setCompanies] = useState<ISelectListItem[] | undefined>()
  const [selectedCompany, setCompany] = useState<number | null>(null)
  const [tabValue, setTabValue] = useState(0)
  //const [tabs, setTabs] = useState([{ label: t(`file_upload`, ``) }, { label: t(`settings`, ``) }])
  const [tabs, setTabs] = useState([{ label: "Filuppladdning" }, { label: "Inställningar" }])

  useEffect(() => {
    companiesApi.get(apiEndpoints.violationadmin.getavailablecompanies)
  }, [])

  useEffect(() => {
    companyApi.get(apiEndpoints.violationadmin.getcompany.replace("{id}", selectedCompany?.toString() ?? "0"))
  }, [selectedCompany])

  useEffect(() => {
    if (companiesResult.status === 2) {
      setCompanies(companiesResult.data)
      setCompany(companiesResult.data[0].id)
    }
  }, [companiesResult])

  return (
    <Container>
      {(companies?.length ?? 0) < 1 && (
        <Grid container>
          <Grid item xs marginBottom={2}>
            <Typography variant="labelLarge" fontWeight={500}>
              {t(`missing_settings`, ``)}
            </Typography>
          </Grid>
        </Grid>
      )}
      {(companies?.length ?? 0) > 1 && (
        <Grid container>
          <Grid item xs md={6}>
            <Typography variant="labelLarge" display="block" marginBottom={2} gutterBottom fontWeight={500}>
              {t(`multiple_companies`, ``)}
            </Typography>
            <FormControl fullWidth>
              <Autocomplete
                sx={{ width: "100%", background: theme.palette.background.paper }}
                options={companies || []}
                getOptionLabel={(option: any) => option?.name}
                value={companies?.find((x: any) => x.id === selectedCompany) || null}
                onChange={(e, option) => setCompany(option?.id ?? 0)}
                renderInput={(params) => (
                  <TextField {...params} name={"reason"} variant="outlined" label="Bolag" />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      {selectedCompany !== null &&
        (companyResult.status === 2 && companyResult.data.violationIdhaImport === true ? (
          <Grid container>
            <Grid item xs md={6} marginBottom={2}>
              <SubTabs tabs={tabs} value={tabValue} setValue={setTabValue} />
              <TabPanel value={tabValue} index={0}>
                <AdminUploadIdhaFile companyId={1} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <AdminEmailSetting companyId={selectedCompany} />
                <AdminDrivingTypes companyId={selectedCompany} />
              </TabPanel>
            </Grid>{" "}
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs md={6} marginBottom={2}>
              <AdminEmailSetting companyId={selectedCompany} />
              <AdminDrivingTypes companyId={selectedCompany} />
            </Grid>
          </Grid>
        ))}
    </Container>
  )
}

export default ViolationAdmin
