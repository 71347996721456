import { allRoutes } from "generated/routes";
import { PageTabs } from "components/navigation/PageTabs";

export const EditCustomerNavigation = ({customerId}) => {

    const tabs1 = [
        {
            title: "Grundinformation",
            path: allRoutes.SystemadminCustomersEditCustomer(customerId)
        },
        {
            title: "Bolag",
            path: allRoutes.SystemadminCustomersEditCompanies(customerId)
        },
        {
            title: "Användare",
            path: allRoutes.SystemadminCustomersEditUsers(customerId)
        }
    ];

    return <PageTabs tabs={tabs1} />
};
