import React, { useState } from "react";
import axios from "axios";
import { msalInstance } from "../index";
import { loginRequest } from "./authConfig";

function getMessages(error) {
	const { response } = error;
	const { request, ...errorObject } = response; // take everything but 'request'

	if (errorObject) {
		if (errorObject.status === 400) {
			if (Array.isArray(errorObject.data)) {
				return errorObject.data;
			}
		} else if (errorObject.status === 401) {
			return ["Behörighet saknas för den valda operationen"];
		} else if (errorObject.status === 500) {
			if (Array.isArray(errorObject.data)) {
				return errorObject.data;
			}
		}
	}

	return ["Ett okänt fel inträffade"];
}


export const useApiGet = () => {


	const [result, setResult] = useState({
		status: 0, // 0 idle, 1 loading, 2 success, 3 error
		messages: [], // onlyif 3
		data: null, // only valid if 2
	});

	const get = React.useCallback(
		async (url) => {
			setResult({
				status: 1,
				messages: [],
				data: null,
			});

			var accessToken = "";
			try {
				const account = msalInstance.getActiveAccount();
				if (!account) {
					throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
				}
			
				const response = await msalInstance.acquireTokenSilent({
					...loginRequest,
					account: account
				});
				
				accessToken = response.accessToken;
			} catch {
				// TODO: hantera detta, den kan kasta fel "login+required"
			}

			try {
				var result = await axios.get(url, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				// hit kommer vi på "ok"
				setResult({
					status: 2,
					messages: [],
					data: result.data,
				});
			} catch (e) {
				try {
					console.log(e);

					setResult({
						status: 3,
						messages: getMessages(e),
						data: null,
					});
				} catch {
					setResult({
						status: 3,
						messages: [],
						data: null,
					});
				}
			}
		},
		[]
	);

	const reset = React.useCallback(() => {
		setResult({
			status: 0,
			messages: [],
			data: null,
		});
	}, [setResult]);

	return [result, get, reset];
};

export const useApiGetBlob = () => {
	
	const [result, setResult] = useState({
		status: 0, // 0 idle, 1 loading, 2 success, 3 error
		messages: [], // onlyif 3
		data: null, // only valid if 2
	});

	const get = React.useCallback(
		async (url, fileName) => {
			setResult({
				status: 1,
				messages: [],
				data: null,
			});

			var accessToken = "";
			try {
				const account = msalInstance.getActiveAccount();
				if (!account) {
					throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
				}
			
				const response = await msalInstance.acquireTokenSilent({
					...loginRequest,
					account: account
				});

				accessToken = response.accessToken;
			} catch {
				// TODO: hantera detta, den kan kasta fel "login+required"
			}

			try {
				axios({
					url: url, //your url
					headers: {
						Authorization: `Bearer ${accessToken}`,						
					},
					method: 'GET',
					responseType: 'blob', // important
				  }).then((response) => {
					 const url = window.URL.createObjectURL(new Blob([response.data]));
					 const link = document.createElement('a');
					 link.href = url;
					 link.setAttribute('download', fileName); //or any other extension
					 document.body.appendChild(link);
					 link.click();

				// hit kommer vi på "ok"
				setResult({
					status: 2,
					messages: [],
					data: null,
				});

					});

			} catch (e) {
				try {
					console.log(e);

					setResult({
						status: 3,
						messages: getMessages(e),
						data: null,
					});
				} catch {
					setResult({
						status: 3,
						messages: [],
						data: null,
					});
				}
			}
		},
		[]
	);

	const reset = React.useCallback(() => {
		setResult({
			status: 0,
			messages: [],
			data: null,
		});
	}, [setResult]);

	return [result, get, reset];
};

export const useApiPost = () => {

	const [result, setResult] = useState({
		status: 0, // 0 idle, 1 loading, 2 success, 3 error
		messages: [], // onlyif 3
		data: null, // only valid if 2
	});
	const reset = React.useCallback(() => {
		setResult({
			status: 0,
			messages: [],
			data: null,
		});
	}, [setResult]);

	const post = React.useCallback(
		async (url, data) => {
			setResult({
				status: 1,
				messages: [],
				data: null,
			});

			var accessToken = "";
			try {
				const account = msalInstance.getActiveAccount();
				if (!account) {
					throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
				}
			
				const response = await msalInstance.acquireTokenSilent({
					...loginRequest,
					account: account
				});

				accessToken = response.accessToken;
			} catch {
				// TODO: hantera detta, den kan kasta fel "login+required"
			}

			try {
				var result = await axios.post(url, data, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				// hit kommer vi på "ok"

				setResult({
					status: 2,
					messages: [],
					data: result.data,
				});
				
			} catch (e) {
				try {
					console.log(e);

					setResult({
						status: 3,
						messages: getMessages(e),
						data: null,
					});
				} catch {
					setResult({
						status: 3,
						messages: [],
						data: null,
					});
				}
			}
		},
		[]
	);
	
	return [result, post, reset];
};

export const useApiPut = () => {


	const [result, setResult] = useState({
		status: 0, // 0 idle, 1 loading, 2 success, 3 error
		messages: [], // onlyif 3
		data: null, // only valid if 2
	});
	const reset = React.useCallback(() => {
		setResult({
			status: 0,
			messages: [],
			data: null,
		});
	}, [setResult]);

	const put = React.useCallback(
		async (url, data) => {
			setResult({
				status: 1,
				messages: [],
				data: null,
			});

			var accessToken = "";
			try {
				const account = msalInstance.getActiveAccount();
				if (!account) {
					throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
				}
			
				const response = await msalInstance.acquireTokenSilent({
					...loginRequest,
					account: account
				});

				accessToken = response.accessToken;
			} catch {
				// TODO: hantera detta, den kan kasta fel "login+required"
			}

			try {
				var result = await axios.put(url, data, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				// hit kommer vi på "ok"
				setResult({
					status: 2,
					messages: [],
					data: result.data,
				});
				// put används vid redigera, och då behöver vi autoresetta state
				setTimeout(() => {
					reset();
				}, 1000);
			} catch (e) {
				try {
					console.log(e);

					setResult({
						status: 3,
						messages: getMessages(e),
						data: null,
					});
				} catch {
					setResult({
						status: 3,
						messages: [],
						data: null,
					});
				}
			}
		},
		[reset]
	);
	
	return [result, put, reset];
};

export const useApiDelete = () => {


	const [result, setResult] = useState({
		status: 0, // 0 idle, 1 loading, 2 success, 3 error
		messages: [], // onlyif 3
		data: null, // only valid if 2
	});

	const del = React.useCallback(
		async (url, data) => {
			setResult({
				status: 1,
				messages: [],
				data: null,
			});

			var accessToken = "";
			try {
				const account = msalInstance.getActiveAccount();
				if (!account) {
					throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
				}
			
				const response = await msalInstance.acquireTokenSilent({
					...loginRequest,
					account: account
				});

				accessToken = response.accessToken;
			} catch {
				// TODO: hantera detta, den kan kasta fel "login+required"
			}

			try {
				var result = await axios.delete(url, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				// hit kommer vi på "ok"
				setResult({
					status: 2,
					messages: [],
					data: result.data,
				});
			} catch (e) {
				try {
					console.log(e);

					setResult({
						status: 3,
						messages: getMessages(e),
						data: null,
					});
				} catch {
					setResult({
						status: 3,
						messages: [],
						data: null,
					});
				}
			}
		},
		[]
	);
	const reset = React.useCallback(() => {
		setResult({
			status: 0,
			messages: [],
			data: null,
		});
	}, [setResult]);
	return [result, del, reset];
};

export const useApiFile = () => {

	const [result, setResult] = useState({
		status: 0, // 0 idle, 1 loading, 2 success, 3 error
		messages: [], // onlyif 3
		data: null, // only valid if 2
	});

	const file = React.useCallback(
		async (url, data) => {
			setResult({
				status: 1,
				messages: [],
				data: null,
			});

			var accessToken = "";
			try {
				const account = msalInstance.getActiveAccount();
				if (!account) {
					throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
				}
			
				const response = await msalInstance.acquireTokenSilent({
					...loginRequest,
					account: account
				});

				accessToken = response.accessToken;
			} catch {
				// TODO: hantera detta, den kan kasta fel "login+required"
			}

			try {
				const formData = new FormData();
				formData.append("file", data);

				var result = await axios.post(url, formData, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						"Content-Type": "multipart/form-data",
					},
				});
				// hit kommer vi på "ok"
				setResult({
					status: 2,
					messages: [],
					data: result.data,
				});
			} catch (e) {
				try {
					console.log(e);

					setResult({
						status: 3,
						messages: getMessages(e),
						data: null,
					});
				} catch {
					setResult({
						status: 3,
						messages: [],
						data: null,
					});
				}
			}
		},
		[]
	);
	const reset = React.useCallback(() => {
		setResult({
			status: 0,
			messages: [],
			data: null,
		});
	}, [setResult]);
	return [result, file, reset];
};
