import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from "@mui/material"
import { useContext } from "react"
import XLSX from "xlsx"
import { ImportFuelContext } from "./ImportFuelFormStateProvider"

const filePathset = (e, setFieldValue, importDataContext, resetForm) => {
  e.stopPropagation()
  e.preventDefault()
  var file = e.target.files[0]

  if (file && file.name) {
    resetForm()
    setFieldValue("excelFile", file.name)
    readFile(file, importDataContext)
  }
}

const readFile = (state, importDataContext) => {
  var f = state

  const reader = new FileReader()

  reader.onload = (evt) => {
    const bstr = evt.target.result

    const wb = XLSX.read(bstr, { type: "binary" })

    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]

    let tempData = []
    const data = tempData.concat(XLSX.utils.sheet_to_json(ws, { raw: false }))
    const dataRawHeader = tempData.concat(XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 }))
    const excelData = data
    const excelHeader = []

    for (const headerAttrIndex in dataRawHeader[0]) {
      const columnHeader = dataRawHeader[0][headerAttrIndex]
      const header = {
        Header: columnHeader,
        accessor: columnHeader,
        key: columnHeader.replace(/\s/g, ""),
        fieldName: null
      }
      excelHeader.push(header)
    }

    importDataContext.updateImportData({
      excelHeader: excelHeader,
      excelData: excelData
    })
    importDataContext.addExcelHeaders(excelHeader)
  }
  reader.readAsBinaryString(f)
}

export const UploadExcelToJson = (props) => {
  const { touched, errors, setFieldValue, values, resetForm } = props

  const importDataContext = useContext(ImportFuelContext)
  return (
    <Container>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item>
          <Box bgcolor="background.dark" color="foreground.inverted" borderRadius="8px" padding={10}>
            <Typography variant="h1" align="center" gutterBottom>
              Ladda upp fil
            </Typography>
            <Typography variant="bodySmall" align="center">
              Viktigt att importfunktionen kan ändra många uppgifter för flera fordon i en enda import.
              Kontrollera därför alltid att uppgifter som visas i Importguidens steg är korrekta.
            </Typography>
            <Box marginTop={5} />
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item>
                <FormControl error={touched.excelFile && errors.excelFile} variant="outlined">
                  <TextField
                    style={{ display: "none" }}
                    id="excelFile"
                    type="file"
                    variant="outlined"
                    size="small"
                    label={values.excelFile}
                    onChange={(e) => filePathset(e, setFieldValue, importDataContext, resetForm)}
                    error={touched.excelFile && errors.excelFile ? true : false}
                    helperText={touched.excelFile && errors.excelFile ? errors.excelFile : null}
                  />
                  <Typography align="center" gutterBottom>
                    {values.excelFile}
                  </Typography>

                  <label htmlFor="excelFile" align="center">
                    <Button variant="contained" color="primary" component="span">
                      Välj fil
                    </Button>
                  </label>
                  {touched.excelFile && errors.excelFile && (
                    <FormHelperText>{errors.excelFile}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
