import {
  Box,
  FormControl,
  FormHelperText,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React, { useContext, useEffect, useRef, useState } from "react"
import { ImportContext } from "./ImportFormStateProvider"
import { useStyles } from "./useStyles"

const SelectColumn = (props) => {
  const classes = useStyles()
  const { row, setFieldValue, importDataContext, value } = props

  const [selectedOption, setSelectedOption] = useState(value)

  const [dataFields] = useState([
    { name: "Ej vald", id: "" },
    { name: "Regnr", id: "regNumber", dataType: "string" },
    { name: "Internnr", id: "internNumber", dataType: "string" },
    { name: "Drivmedel", id: "mainPropellant", dataType: "string" },
    { name: "Fordonstyp ", id: "vehicleType", dataType: "string" },
    {
      name: "Färdskrivare ",
      id: "tachographIntegrationActive",
      dataType: "bool"
    },
    {
      name: "Bolag",
      id: "company",
      dataType: "string"
    }
  ])

  const handleImportSelect = (event, fieldName) => {
    setFieldValue(event.target.value ? event.target.value : selectedOption, event.target.value)
    setSelectedOption(event.target.value)
    const dataType = event.target[event.target.selectedIndex].getAttribute("data-type")

    importDataContext.updateColumns(event.target.value, dataType, fieldName)
  }

  return (
    <FormControl className={classes.formControl} size="small">
      <Select native value={selectedOption} onChange={(e) => handleImportSelect(e, row.Header)}>
        {dataFields &&
          dataFields.map((item) => (
            <option key={item.id} value={item.id} data-type={item.dataType}>
              {item.name}
            </option>
          ))}
      </Select>
    </FormControl>
  )
}

export const SelectFields = (props) => {
  const importDataContext = useContext(ImportContext)

  const { touched, errors, setFieldValue, values } = props

  const classes = useStyles()
  const [dataValues] = useState(importDataContext.getImportedData())

  const [excelHeaders] = useState(importDataContext.getExcelHeaders())
  const tableRef = useRef()
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)

  const getSize = () => {
    if (!tableRef.current) return
    if (tableRef && tableRef.current.getBoundingClientRect() != null) {
      const elemFromTop = tableRef.current.getBoundingClientRect().top + 62
      setHeight(window.innerHeight - elemFromTop)
      const elemFromLeft = tableRef.current.getBoundingClientRect().left
      setWidth(window.innerWidth - elemFromLeft)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", getSize)
  }, [])

  useEffect(() => {
    if (tableRef) {
      const elemFromTop = tableRef.current.getBoundingClientRect().top + 58
      setHeight(window.innerHeight - elemFromTop)

      const elemFromLeft = tableRef.current.getBoundingClientRect().left
      setWidth(window.innerWidth - elemFromLeft)
    }
  }, [])
  return (
    <>
      <Box bgcolor="background.paper" className={classes.rootShadow}>
        <Box>
          <TableContainer
            className={classes.tableContainer}
            style={{ height: height, width: width }}
            ref={tableRef}>
            <Table size="small" className={classes.table} stickyHeader>
              <TableHead>
                <TableRow>
                  {excelHeaders.map((row, index) => {
                    return (
                      <TableCell key={index} className={row.fieldName !== null && "locked"}>
                        <SelectColumn
                          row={row}
                          setFieldValue={setFieldValue}
                          importDataContext={importDataContext}
                          value={values[row.fieldName]}
                        />
                        <br></br>
                        <Typography displayBlock variant="caption">
                          {row.Header}
                        </Typography>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataValues &&
                  dataValues.current.excelData
                    .slice(0, 100) // TO DO: Lazy loading
                    .map((row) => (
                      <TableRow key={row.name}>
                        {excelHeaders.map((header, index) => {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              className={row[header.Header] != null && header.fieldName !== null && "locked"}>
                              {row[header.Header]}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={
          (touched.regNumber && errors.regNumber) ||
          (touched.internNumber && errors.internNumber) ||
          (touched.mainPropellant && errors.mainPropellant) ||
          (touched.vehicleType && errors.vehicleType) ||
          (touched.company && errors.company)
        }>
        <Box bgcolor="background.dark" color="foreground.inverted" borderRadius="8px" padding={1}>
          <FormControl
            className={classes.formControl}
            error={
              (touched.regNumber && errors.regNumber) ||
              (touched.internNumber && errors.internNumber) ||
              (touched.mainPropellant && errors.mainPropellant) ||
              (touched.vehicleType && errors.vehicleType) ||
              (touched.company && errors.company)
            }
            variant="outlined">
            {touched.regNumber && errors.regNumber && <FormHelperText>{errors.regNumber}</FormHelperText>}
            {touched.internNumber && errors.internNumber && (
              <FormHelperText>{errors.internNumber}</FormHelperText>
            )}
            {touched.mainPropellant && errors.mainPropellant && (
              <FormHelperText>{errors.mainPropellant}</FormHelperText>
            )}
            {touched.vehicleType && errors.vehicleType && (
              <FormHelperText>{errors.vehicleType}</FormHelperText>
            )}
            {touched.company && errors.company && <FormHelperText>{errors.company}</FormHelperText>}
          </FormControl>
        </Box>
      </Snackbar>
    </>
  )
}
