import { Box } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import ErrorDialog from "components/ErrorDialog"
import MultipleFilePicker from "components/MultipleFilePicker"
import PaperBox from "components/PaperBox"
import { FieldArray, Formik, FormikProps } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect, useRef, useState } from "react"
import { IDocumentFormValues } from "../../interface/folder.interface"
import FileFormList from "../FileFormList"
import { fileValidationSchema, folderValidationSchema } from "../folderValidationSchema"

const FileForm = (document: IDocumentFormValues) => {
  const [fileUploadResult, uploadFile] = useApi() as [IApiResult, IApiFunc]
  const [fileUpsertResult, upsertFile] = useApi() as [IApiResult, IApiFunc]
  const [fileUpdateResult, updateFile] = useApi() as [IApiResult, IApiFunc]
  const [editFile, setEditFile] = React.useState<null | any>(null)
  const [files, setFiles] = useState([])
  const formRef = useRef<FormikProps<any>>(null)
  const formRefAttachments = useRef<FormikProps<any>>(null)

  useEffect(() => {
    if (files.length > 0) {
      files.map((file) => {
        return uploadFile.file(apiEndpoints.documents.uploadattachment, file)
      })
    }
  }, [files, uploadFile])

  useEffect(() => {
    if (fileUploadResult.status === 2) {
      let valuesObj = {
        ...fileUploadResult.data,
        documentId: document.id
      }

      upsertFile.post(apiEndpoints.documents.uploadattachmenttodocument, valuesObj)
    }
  }, [fileUploadResult])

  useEffect(() => {
    if (fileUpsertResult.status === 2) {
      formRefAttachments?.current?.setFieldValue("attachments", [
        ...formRefAttachments?.current?.values.attachments,
        fileUpsertResult.data
      ])
    }
  }, [fileUpsertResult])

  const saveFile = (valuesObj: any) => {
    if (editFile !== null)
      updateFile.put(apiEndpoints.documents.updateattachment, valuesObj?.attachments[editFile])
  }

  return (
    <>
      {fileUpsertResult.status === 3 ||
        (fileUploadResult.status === 3 && <ErrorDialog title="Uppladdning misslyckades" message="" />)}
      <Formik
        innerRef={formRef}
        initialValues={document}
        validationSchema={folderValidationSchema}
        onSubmit={(e: any) => document?.handleSubmit(e)}
        enableReinitialize={true}>
        {({ handleSubmit }) => (
          <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
            <Box marginTop={2}>
              <MultipleFilePicker
                isLoading={fileUpsertResult.status === 1 || fileUploadResult.status === 1}
                setFiles={setFiles}
              />
            </Box>
          </form>
        )}
      </Formik>
      <Formik
        innerRef={formRefAttachments}
        validationSchema={fileValidationSchema}
        initialValues={document}
        onSubmit={saveFile}
        enableReinitialize={true}>
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form id="AttachmentForm" onSubmit={handleSubmit} noValidate>
            <PaperBox>
              <FieldArray
                name="attachments"
                render={() => (
                  <FileFormList
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    editFile={editFile}
                    setEditFile={setEditFile}
                    fileUpdateResult={fileUpdateResult}
                    downloadUrl={apiEndpoints.documents.getattachment}
                    deleteUrl={apiEndpoints.documents.deleteattachment}
                  />
                )}
              />
            </PaperBox>
          </form>
        )}
      </Formik>
    </>
  )
}
export default FileForm
