import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import DocumentBox from "./DocumentBox"

interface attachment {
  id: number
  fileId: string
  contentType?: string
  name: string
}
interface Props {
  attachments: attachment[]
}

const DocumentsComponent: React.FC<Props> = (props) => {
  return (
    <Box padding={2}>
      {props.attachments ? (
        <>
          <Typography variant="h3" marginLeft={2} marginTop={2}>
            Dokument
          </Typography>
          <Grid container>
            {props.attachments.map(function (attachment) {
              return (
                <Grid item>
                  <DocumentBox data={attachment} />
                </Grid>
              )
            })}
          </Grid>
        </>
      ) : null}
    </Box>
  )
}

export default DocumentsComponent
