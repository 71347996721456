export function issueReportStatusLabel(status: number) {
  switch (status) {
    case 1:
      return "Öppen"
    case 2:
      return "Planerad"
    case 3:
      return "Pågående"
    case 4:
      return "Åtgärdad"
    case 5:
      return "Avslutad"
    default:
      return "-"
  }
}
