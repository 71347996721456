import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  sendIcon: {
    fontSize: "14px !important"
  },
  root: {
    paddingBottom: 0,
    marginBottom: 20
  },
  cardBox: {
    paddingBottom: 5,
    marginTop: 10,
    padding: "12px"
  },
  flex: {
    display: "flex"
  },
  icon: {
    color: theme.palette.primary["main"],
    marginRight: 5,
    paddingTop: 3
  },

  reports: {
    borderRadius: 25,
    backgroundColor: theme.palette.primary["main"],
    color: "white",
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 16,
    paddingLeft: 16
  },
  alarms: {
    borderRadius: 25,
    backgroundColor: "green",
    color: "white",
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 16,
    paddingLeft: 16
  },
  time: {
    flexGrow: 1,
    display: "flex"
  },
  ReadMoreBtn: {
    padding: 3,
    color: "white",
    maxWidth: 17,
    height: 20,
    backgroundColor: theme.palette.primary["main"]
  },

  /* WorkOrder */
  strongRedText: {
    color: theme.palette.error["main"],
    marginRight: 10
  },
  redEmailBox: {
    backgroundColor: theme.palette.error["light"],
    borderRadius: 10
  },
  blueEmailBox: {
    backgroundColor: theme.palette.primary["light"],
    borderRadius: 10
  },
  greenEmailBox: {
    backgroundColor: theme.palette.success["light"],
    borderRadius: 10
  },
  externalBlueEmailBox: {
    backgroundColor: theme.palette.primary["light"],
    borderRadius: 10
  },
  externalGreenEmailBox: {
    backgroundColor: theme.palette.success["light"],
    borderRadius: 10
  },
  whiteLabel: {
    background: "white"
  },
  iconBox: {
    height: 40,
    weight: 30,
    backgroundColor: theme.palette.primary["light"],
    borderRadius: 5
  },
  greyBox: {
    marginLeft: "30px",
    marginRight: "30px",
    padding: "16px",
    borderRadius: 5
  },
  completeBox: {
    backgroundColor: theme.palette.success["light"],
    height: 300,
    borderRadius: 10
  },
  blueBox: {
    backgroundColor: theme.palette.primary["light"],
    margin: 16,
    marginTop: 10,
    marginBottom: 35,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5
  },
  strongBlueText: {
    color: theme.palette.primary["main"],
    marginRight: 10
  },
  strongGreenText: {
    color: theme.palette.success["main"],
    marginRight: 10
  },
  bluelogocolor: {
    backgroundColor: theme.palette.primary["light"]
  },
  /* Category Sidebar */

  fullList: {
    width: "auto"
  },
  showFilterBtn: {
    color: "#ffff",
    textTransform: "none"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0 ",
    boxShadow: "0"
  },
  drawer: {
    flexShrink: 1
  },
  drawerPaper: {
    backgroundColor: "#ffff",
    width: "450px",
    top: 48
  },

  listItem: {
    // borderTop: "1px solid #E0E0E0",
    borderRadius: "0 !important",
    padding: "14px 0",
    alignItems: "flex-start !important",
    display: "flex",
    flexDirection: "column"
  },

  listItemIcon: {
    paddingRight: "12px",
    paddingTop: "4px"
  },

  // * vehicle list
  vehicleList: {
    width: "100%",
    backgroundColor: "theme.palette.background.paper",
    padding: "4rem 2rem 2rem 2rem"
  },

  listItemText: {
    fontSize: "16px"
  },
  listItemTextPrimary: {
    color: "#000000",
    fontWeight: 600
  },
  fullWidth: {
    width: "100%",
    display: "flex"
  },

  greyBorder: {
    borderTop: "1px solid",
    borderColor: theme.palette.grey[300]
  },

  greyBorderBottom: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey[300]
  },

  bigListItemIconWrapper: {
    backgroundColor: theme.palette.primary[50],
    borderRadius: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "55px",
    width: "55px",
    marginRight: "12px"
  },

  bigListItemIcon: {
    color: theme.palette.primary,
    fontSize: "24px"
  },

  boldText: {
    fontWeight: 600
  },
  //Felrapporter
  doubleButtons: {
    [theme.breakpoints.down("lg")]: {
      marginTop: "16px",
      gridGap: "10px"
    },
    [theme.breakpoints.up("md")]: {
      alignItems: "center"
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      marginBottom: "16px"
    }
  },
  inline: {
    [theme.breakpoints.down("sm")]: {
      display: "inline"
    }
  },
  maxWidth: {
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100%"
    }
  },
  noMargin: {
    [theme.breakpoints.down("sm")]: {
      margin: "5px 0"
    }
  },
  flexResponsive: {
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap"
    }
  }
}))
