import { Box, Typography } from "@mui/material"

import React from "react"
import { IFormAreaProps } from "../FormArea/formarea.interface"
import FormComponentsView from "../FormComponents/FormComponentsView"
import { IFormFieldProps } from "../FormField/formfield.interface"
import { IFormGroupProps } from "../FormGroup/formgroup.interface"
import { IFormTemplateProps } from "./formtemplate.interface"

const ViewFormTemplate: React.FC<IFormTemplateProps> = (props) => {
  const { formAreas } = props

  return (
    <>
      {formAreas.map((area: IFormAreaProps, index: number) => (
        <>
          <Typography variant="h3" gutterBottom>
            {area.labelDefault}
          </Typography>
          {area?.formGroups?.map((formGroup: IFormGroupProps) => (
            <Box marginBottom={3}>
              <Typography variant="h3" gutterBottom marginBottom="20px">
                {formGroup.labelDefault}
              </Typography>
              {formGroup.formFields.map((field: IFormFieldProps) => {
                const parsedDependencyIds = field.dependencyIds && JSON.parse(field.dependencyIds.toString())

                const dependencyCheck = area?.formGroups?.findIndex((formGroup: IFormGroupProps) => {
                  const formValue = formGroup.formFields.findIndex((ff: IFormFieldProps) => {
                    return parsedDependencyIds?.findIndex(
                      (d: any) => d.formFieldKey === ff.formFieldKey && ff.value === d.value
                    )
                  })
                  return formValue
                })
                return (
                  dependencyCheck !== -1 && (
                    <Box marginBottom={2}>
                      <FormComponentsView field={field} />
                    </Box>
                  )
                )
              })}
            </Box>
          ))}
        </>
      ))}
    </>
  )
}

export default ViewFormTemplate
