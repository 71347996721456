import { faFilter, faXmark } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CloseIcon from "@mui/icons-material/Close"
import { Alert, Box, Button, Grid, IconButton, Snackbar } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { MapContext } from "../MapContextProvider"
import DateRangePickerTimeline from "../Timeline/DateRangePicker"
import { useStyles } from "./useStyles"

const ButtonAppBar = () => {
  const classes = useStyles()
  const { toggleDeviceBar, activeMarker, resetHistory, historyPositionsData, loadingMarkers } =
    useContext(MapContext)
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setOpen(false)
    if (activeMarker !== null && historyPositionsData.status === 2 && historyPositionsData.data.length <= 0) {
      setOpen(true)
    }
  }, [historyPositionsData, activeMarker, loadingMarkers])

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={open} autoHideDuration={3000}>
        <Alert severity="info" elevation={6} variant="filled">
          Inga positioner hittades för det valda intervallet
          <IconButton aria-label="close" color="inherit" className={classes.close} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Alert>
      </Snackbar>
      <Grid container direction="row" alignItems="center" className={classes.header}>
        {activeMarker ? (
          <>
            <Grid item className={classes.dateRange}>
              <DateRangePickerTimeline />
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center" gap={1} className={classes.blueBox}>
                <Grid item>Valt fordon</Grid>
                <Grid item>
                  <Box sx={{ fontWeight: 600 }}> {activeMarker?.internalDeviceId}</Box>
                </Grid>
                <Grid item marginRight={1}>
                  | {activeMarker?.label}
                </Grid>
                <Grid item marginRight={1}>
                  <FontAwesomeIcon
                    className={classes.closeIcon}
                    icon={faXmark}
                    onClick={resetHistory}
                    size="1x"
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}

        <Grid item className={classes.deviceButton}>
          <Button onClick={toggleDeviceBar} color="primary" variant="contained">
            Fordonslista
            <FontAwesomeIcon
              style={{
                marginLeft: "8px",
                color: "var(--secondary)"
              }}
              icon={faFilter}
              size="sm"
            />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
export default ButtonAppBar
