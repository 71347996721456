import IssueReportCategorys from "pages/issuereports/pages/archive/components/IssueReportCategorys";
import React from "react";

const IssueReportCategories = () => {
    return (
        <div>
            <IssueReportCategorys />
        </div>
    );
};
export default IssueReportCategories;
