import { Container, Grid } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import FormCardButton from "components/Form/FormComponents/FormCardButton/FormCardButton"
import { IFormCardButtonProps } from "components/Form/FormComponents/FormCardButton/formcardbutton.interface"
import FormTemplate from "components/Form/FormTemplate/FormTemplate"
import { IFormTemplateProps } from "components/Form/FormTemplate/formtemplate.interface"
import { apiEndpoints } from "generated/apiEndpoints"
import { getTemplateSettings } from "pages/deviation/pages/helpers/templateSettings"
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
const CreateDeviation: React.FC = () => {
  const [templateResults, getTemplates] = useApi() as [IApiResult, IApiFunc]
  const [formResult, getForm] = useApi() as [IApiResult, IApiFunc]
  const [queryParamId, setQueryParam] = useState<string | null>(null)

  useEffect(() => {
    getTemplates.get(apiEndpoints.form.getformtemplateminimized.replace("{feature}", "Deviation"))
  }, [getTemplates])

  let query = useQuery()
  useEffect(() => {
    getTemplates.get(apiEndpoints.form.getformtemplateminimized.replace("{feature}", "Deviation"))
  }, [getTemplates])

  useEffect(() => {
    if (query.get("id")) {
      setQueryParam(query.get("id") ?? null)
    } else {
      setQueryParam(null)
    }
  }, [query])

  useEffect(() => {
    if (queryParamId) {
      getForm.get(apiEndpoints.form.getformtemplate.replace("{id}", queryParamId))
    }
  }, [getForm, queryParamId])
  return (
    <Container>
      <Grid container mx={"auto"} gap={3} display={"flex"} justifyContent={"center"} p={0}>
        {formResult.status !== 2 && templateResults.status === 2 ? (
          templateResults.data?.map((template: IFormTemplateProps) => {
            const settings = getTemplateSettings(template.key)
            const componentProps: IFormCardButtonProps = {
              description: settings?.description,
              title: template.name,
              icon: settings?.icon,
              size: "2xl",
              id: template.id
            }
            return (
              <Grid item md={5} xs={12}>
                <FormCardButton {...componentProps} />
              </Grid>
            )
          })
        ) : formResult.status === 2 ? (
          <Grid item md={12} xs={12}>
            <FormTemplate {...formResult.data} apiSubmitEndpoint={apiEndpoints.deviation.adddeviation} />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Container>
  )
}

export default CreateDeviation
