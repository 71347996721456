import { useStyles } from "pages/vehicleregistry/pages/vehicles/components/VehicleList/useStyles";
import Drawer from "@mui/material/Drawer";
import React from "react";
import { TechnicalPackageForm } from "./TechnicalPackageList/TechnicalPackageForm";

export const Sidebar = ({ showSidebar, setShowSidebar, onSubmit, technicalPackageResult, editMode, addOrUpdateResultState, equipmentTypesResult }) => {
    const classes = useStyles();

    const toggleMenu = () => {
        setShowSidebar(!showSidebar);
    };

    return (
        <div>
            <React.Fragment key="right">
                <Drawer
                    variant="persistent"
                    className={classes.drawer}
                    anchor="right"
                    open={showSidebar}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <TechnicalPackageForm
                        technicalpackageData={technicalPackageResult.data}
                        toggleMenu={toggleMenu}
                        onSubmit={onSubmit}
                        editMode={editMode}
                        addOrUpdateResultState={addOrUpdateResultState}
                        equipmentTypesResult={equipmentTypesResult}
                    />
                </Drawer>
            </React.Fragment>
        </div>
    );
};
