// this posts the file to the server and gets back a "file object" on success
// the name should be used as id
import Button from "components/Button"

import { useState, useRef, useEffect } from "react"
import { useApiFile } from "auth/useApi2"
import { apiEndpoints } from "generated/apiEndpoints"
import { Grid } from "@mui/material"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudUploadAlt } from "@fortawesome/pro-solid-svg-icons"
import { useParams } from "react-router"

export const SimpleUploadFile = ({ setImportResult }) => {
  const [file, setFile] = useState(null)
  const params = useParams()

  const [fileUploadResult, uploadFile] = useApiFile()

  const fileInput = useRef()

  // trigger a file state change when a file upload has been detected
  const onFileUploadChange = (e) => {
    // console.log(e);
    e.preventDefault()
    setImportResult(null)

    setFile(e.target.files[0])
  }

  // trigger hidden input field from button
  const triggerInputFile = (e) => {
    fileInput.current.click()
  }

  // handle upload
  useEffect(() => {
    const onAddImageClick = async () => {
      await uploadFile(
        apiEndpoints.systemadmincustomers.uploaduserimportcreateusersfile.replace("{customerId}", params.id),
        file
      )
    }

    if (file) {
      onAddImageClick()
    }
  }, [file, uploadFile, params, setImportResult])

  useEffect(() => {
    if (fileUploadResult.status === 2) setImportResult(fileUploadResult.data)
  }, [fileUploadResult, setImportResult])

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Button
        onClick={triggerInputFile}
        disabled={fileUploadResult.status === 1}
        variant="outlined"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faCloudUploadAlt} />}>
        Ladda upp fil{" "}
        {file ? (
          <span> {fileUploadResult.status === 1 ? <span>(Importerar {file.name})</span> : null}</span>
        ) : null}
      </Button>
      <input
        className="hidden"
        type="file"
        name="file"
        id="exampleFile"
        ref={fileInput}
        onChange={onFileUploadChange}></input>
    </Grid>
  )
}
