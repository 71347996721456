import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  fullList: {
    width: "auto"
  },
  fullWidth: {
    width: "100%",
    display: "flex"
  },
  listItem: {
    // borderTop: "1px solid #E0E0E0",
    borderRadius: "0 !important",
    padding: "14px 0",
    alignItems: "flex-start !important",
    display: "flex",
    flexDirection: "column"
  },

  listItemIcon: {
    paddingRight: "12px",
    paddingTop: "4px"
  },

  listItemText: {
    fontSize: "16px"
  },
  listItemTextPrimary: {
    color: "#000000",
    fontWeight: 600
  }
}))
