import Drawer from "@mui/material/Drawer"
import React from "react"
import { VehicleForm } from "./VehicleList/VehicleForm"
import VehicleLoadingform from "./VehicleList/VehicleLoadingform"
import { useStyles } from "./VehicleList/useStyles"

export const Sidebar = ({
  showSidebar,
  vehicleResult,
  customFieldsResult,
  mainPropellantResult,
  editMode,
  setShowSidebar,
  addOrUpdateResultState,
  onSubmit
}) => {
  const classes = useStyles()

  const toggleMenu = () => {
    setShowSidebar(!showSidebar)
  }

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          variant="persistent"
          className={classes.drawer}
          anchor="right"
          open={showSidebar}
          classes={{
            paper: classes.drawerPaper
          }}>
          {customFieldsResult.status === 1 ||
          vehicleResult.status === 1 ||
          mainPropellantResult.status === 1 ? (
            <VehicleLoadingform />
          ) : null}

          {(editMode &&
            vehicleResult.status === 2 &&
            mainPropellantResult.status === 2 &&
            customFieldsResult.status === 2) ||
          (!editMode && customFieldsResult.status === 2 && mainPropellantResult.status === 2) ? (
            <VehicleForm
              showSidebar={showSidebar}
              mainPropellantResult={mainPropellantResult}
              customFieldsData={customFieldsResult.data}
              vehicleData={vehicleResult.data}
              toggleMenu={toggleMenu}
              onSubmit={onSubmit}
              editMode={editMode}
              addOrUpdateResultState={addOrUpdateResultState}
            />
          ) : null}
        </Drawer>
      </React.Fragment>
    </div>
  )
}
