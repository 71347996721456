import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Avatar,
  Box,
  Grid,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material"
import "moment/locale/sv"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCar, faCarWrench, faXmark } from "@fortawesome/pro-solid-svg-icons"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import { allRoutes } from "generated/routes"
import { useHistory } from "react-router-dom"
import { EventDialogProps } from "../BigCalendar"
import { statusColor, statusIcon, statusIconColor, statusLabel } from "../helpers/calendarStatusEvent"
import {
  StyledBox,
  StyledBoxClose,
  StyledFontAwesomeIcon
} from "pages/vehicleregistry/pages/checks/InspectionsHistoryTable.styled"
import { InspectionBookingSidebar } from "shared/vehicle/components/InspectionBookingSidebar"
import { ServiceApproveSidebar } from "shared/vehicle/components/ServiceApproveSidebar"
import { VehicleCheckStatus } from "helpers/Checks/ChecksStatusColor"
import { StyledBoxLink, StyledDialog } from "../style/CalendarDialog.styled"
import { EventType, IInspection } from "../interfaces/calendarDialog.interface"
import { InspectionButton, ServiceButton } from "./CalendarDialogActionButtons"

export const EventDialog: React.FC<EventDialogProps> = ({ event, onClose, open, reloadCalendarEvents }) => {
  const [clickedInspection, setClickedInspection] = useState<IInspection>()
  const [clickedService, setClickedService] = useState()
  const [showInspectionSidebar, setShowInspectionSidebar] = useState(false)
  const [showServiceSidebar, setShowServiceSidebar] = useState(false)
  const routerHistory = useHistory()
  const handleClose = () => {
    onClose(null)
  }

  const openInspectionSidebar = (event: any) => {
    const data = JSON.parse(event.data)
    const clickedInspectionData: any = {
      vehicleId: data.vehicle.Id,
      companyId: data.vehicle.CompanyId,
      internNumber: data.vehicle.Internnumber,
      regNumber: data.vehicle.RegNumber,
      customFieldVehicleValueId: data.customFieldVehicleValueId
    }
    if (data?.inspectionBooking) {
      clickedInspectionData.inspectionBooking = {
        carWorkshopId: data.inspectionBooking.CarWorkshopId,
        carWorkshop: data.inspectionBooking.CarWorkshop,
        dateTime: data.inspectionBooking.DateTime,
        id: data.inspectionBooking.Id,
        inspectionType: data.inspectionBooking.InspectionType
      }
    }
    setClickedInspection(clickedInspectionData)
    setShowInspectionSidebar(true)
  }

  const openServiceSidebar = (event: any) => {
    const data = JSON.parse(event.data)
    const clickedService: any = {
      vehicleId: data.vehicle.Id,
      companyId: data.vehicle.CompanyId,
      internNumber: data.vehicle.Internnumber,
      regNumber: data.vehicle.RegNumber,
      customFieldVehicleValueId: data.customFieldVehicleValueId,
      controlType: "Service"
    }
    if (data?.carWorkshop) {
      clickedService.inspectionBooking = {
        carWorkshopId: data.CarWorkshop.CarWorkshopId,
        dateTime: data.CarWorkshop.DateTime,
        id: data.CarWorkshop.Id,
        inspectionType: "Service"
      }
    }
    setClickedService(clickedService)
    setShowServiceSidebar(true)
  }

  const jsonData = JSON.parse(event?.data)
  const eventType = event?.typeId
  
  return (
    <>
      <InspectionBookingSidebar
        clickedInspection={clickedInspection}
        showSidebar={showInspectionSidebar}
        setShowSidebar={setShowInspectionSidebar}
        loadInspections={() => {
          reloadCalendarEvents && reloadCalendarEvents()
        }}
      />
      <ServiceApproveSidebar
        clickedService={clickedService}
        showSidebar={showServiceSidebar}
        setShowSidebar={setShowServiceSidebar}
        loadChecks={() => {
          reloadCalendarEvents && reloadCalendarEvents()
        }}
      />
        <StyledDialog
          fullWidth
          onClose={handleClose}
          open={open}>
          <DialogTitle>
            <Box
              gap={1.5}
              marginBottom={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center"
              }}
            >
              <FontAwesomeIcon
                style={{ marginTop: "1px" }}
                size="xl"
                color={statusIconColor(event?.typeId, event?.status)}
                icon={statusIcon(event?.typeId, event?.status) as IconProp}
              />
              <Box>
                <Typography variant="h4">
                  {event?.title}
                </Typography>
                <Typography variant="bodySmall">
                  {event?.subtitle}
                </Typography>
              </Box>
              <StyledBoxClose onClick={() => { handleClose() }}>
                <FontAwesomeIcon size="xl" color="grey" icon={faXmark} />
              </StyledBoxClose>
            </Box>
            <Typography variant="bodySmall" color="textSecondary">
              Status
            </Typography>
            <Typography fontWeight="500">
              {event?.typeId ? statusLabel(event?.typeId, event?.status || 0) : undefined}
            </Typography>
            {eventType === EventType.Service || eventType === EventType.Besiktning ? (
              <>
                {(event?.status === VehicleCheckStatus.Upcoming || event?.status === VehicleCheckStatus.Late || event?.status === VehicleCheckStatus.waiting) && jsonData.attendanceDate ? (
                  <Typography
                    variant="bodyMedium"
                    color={statusColor(event?.typeId, event?.status)}
                  >
                    {moment().isBefore(jsonData.attendanceDate) ? "Förfaller" : "Försenad"} {moment(jsonData.attendanceDate).format("DD MMMM YYYY")}
                  </Typography>
                ) : <></>}
                <Typography mt={2} variant="bodySmall" color="textSecondary">
                  Beskrivning
                </Typography>
                {jsonData.inspectionBooking ? (
                  <>
                    <Typography variant="bodySmall" fontWeight="500">Plats: {jsonData.inspectionBooking.CarWorkshop.Name}</Typography>
                    <Typography variant="bodySmall" fontWeight="500">Datum: {moment(jsonData.inspectionBooking.DateTime).format("YYYY-MM-DD")}</Typography>
                    <Typography variant="bodySmall" fontWeight="500">Tid: {moment(jsonData.inspectionBooking.DateTime).format("HH:mm")}</Typography>
                  </>
                ) : jsonData.carWorkshop ? (
                  <>
                    <Typography variant="bodySmall" fontWeight="500">Plats: {jsonData.carWorkshop.Name}</Typography>
                    <Typography variant="bodySmall" fontWeight="500">Datum: {moment(jsonData.approvedDate).format("YYYY-MM-DD")}</Typography>
                    <Typography variant="bodySmall" fontWeight="500">Tid: {moment(jsonData.approvedDate).format("HH:mm")}</Typography>
                  </>
                ) : (
                  <Typography variant="bodyMedium">{event?.description ? event?.description : "-"}</Typography>
                )}
              </>
            ) : (
              <>
                <Typography mt={2} variant="bodySmall" color="textSecondary">Beskrivning</Typography>
                <Typography variant="bodyMedium">{event?.description ? event?.description : "-"}</Typography>
              </>
            )}
          </DialogTitle>
          <Divider />
          <List disablePadding>
            <ListItem disableGutters disablePadding>
              <Grid container flexDirection="column">
                {eventType === EventType.Besiktning ? (
                  <InspectionButton
                    event={event}
                    openSidebar={openInspectionSidebar}
                    handleClose={handleClose}
                  />
                ) : eventType === EventType.Service ? (
                  <ServiceButton
                    event={event}
                    openSidebar={openServiceSidebar}
                    handleClose={handleClose}
                  />
                ) : (
                  <></>
                )}
                <StyledBoxLink
                  onClick={() => {
                    routerHistory.push(allRoutes.VehicleregistryVehiclesDetails(event?.referenceId))
                  }}
                >
                  <StyledBox display="flex" justifyContent="space-between" alignItems={"center"}>
                    <Grid container gap={2}>
                      <FontAwesomeIcon size="xl" color="grey" icon={faCar} />
                      <Typography variant="h5" color="primary">Gå till fordon</Typography>
                    </Grid>
                    <StyledFontAwesomeIcon size="xl" icon={faArrowRight} />
                  </StyledBox>
                </StyledBoxLink>
              </Grid>
            </ListItem>
          </List>
          {eventType === "Arbetsorder" && (
            <ListItem disableGutters>
              <ListItemButton
                onClick={() =>
                  routerHistory.push(
                    allRoutes.IssuereportsWorkordersDetails(JSON.parse(event?.data).ReferenceId)
                  )
                }>
                <ListItemAvatar>
                  <Avatar>
                    <FontAwesomeIcon icon={faCarWrench as IconProp} />
                  </Avatar>
                </ListItemAvatar>
              <ListItemText primary="Hantera arbetsorder" />
            </ListItemButton>
          </ListItem>
        )}
      </StyledDialog>
    </>
  )
}
