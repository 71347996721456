// This translates the names of icons (from appstructure) to actual icons
import {
  faAlien,
  faBellExclamation,
  faBuilding,
  faCalendar,
  faCarBurst,
  faCarBus,
  faCarWrench,
  faCog,
  faDiamondExclamation,
  faFileLines,
  faFolder,
  faGraduationCap,
  faHandshake,
  faHeartBroken,
  faHome,
  faIdCard,
  faInventory,
  faMap,
  faMoneyCheckEdit,
  faNewspaper,
  faTable,
  faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MenuIcon = ({ name }) => {
  return name === "DashboardIcon" ? (
    <FontAwesomeIcon icon={faHome} size="lg" className="fa-fw" />
  ) : name === "NewsIcon" ? (
    <FontAwesomeIcon icon={faNewspaper} size="lg" className="fa-fw" />
  ) : name === "CustomerSettingsIcon" ? (
    <FontAwesomeIcon icon={faCog} size="lg" className="fa-fw" />
  ) : name === "CustomersIcon" ? (
    <FontAwesomeIcon icon={faBuilding} size="lg" className="fa-fw" />
  ) : name === "InventoryIcon" ? (
    <FontAwesomeIcon icon={faInventory} size="lg" className="fa-fw" />
  ) : name === "GraduationCap" ? (
    <FontAwesomeIcon icon={faGraduationCap} size="lg" className="fa-fw" />
  ) : name === "AdminExamIcon" ? (
    <FontAwesomeIcon icon={faMoneyCheckEdit} size="lg" className="fa-fw" />
  ) : name === "CarBus" ? (
    <FontAwesomeIcon icon={faCarBus} size="lg" className="fa-fw" />
  ) : name === "GearIcon" ? (
    <FontAwesomeIcon icon={faHeartBroken} size="lg" className="fa-fw" />
  ) : name === "MapIcon" ? (
    <FontAwesomeIcon icon={faMap} size="lg" className="fa-fw" />
  ) : name === "IssueReportsIcon" ? (
    <FontAwesomeIcon icon={faCarWrench} size="lg" className="fa-fw" />
  ) : name === "AlarmIcon" ? (
    <FontAwesomeIcon icon={faBellExclamation} size="lg" className="fa-fw" />
  ) : name === "ReportIcon" ? (
    <FontAwesomeIcon icon={faTable} size="lg" className="fa-fw" />
  ) : name === "SysAdminLearningIcon" ? (
    <FontAwesomeIcon icon={faGraduationCap} size="lg" className="fa-fw" />
  ) : name === "PlanningIcon" ? (
    <FontAwesomeIcon icon={faCalendar} size="lg" className="fa-fw" />
  ) : name === "AssemblyIcon" ? (
    <FontAwesomeIcon icon={faIdCard} size="lg" className="fa-fw" />
  ) : name === "PartnerIcon" ? (
    <FontAwesomeIcon icon={faHandshake} size="lg" className="fa-fw" />
  ) : name === "DocumentsIcon" ? (
    <FontAwesomeIcon icon={faFolder} size="lg" className="fa-fw" />
  ) : name === "PayslipIcon" ? (
    <FontAwesomeIcon icon={faFileLines} size="lg" className="fa-fw" />
  ) : name === "DeviationIcon" ? (
    <FontAwesomeIcon icon={faCarBurst} size="lg" className="fa-fw" />
  ) : name === "CrisisManagementIcon" ? (
    <FontAwesomeIcon icon={faTriangleExclamation} size="lg" className="fa-fw" />
  ) : name === "ViolationIcon" ? (
    <FontAwesomeIcon icon={faDiamondExclamation} size="lg" className="fa-fw" />
  ) : (
    <FontAwesomeIcon icon={faAlien} size="lg" className="fa-fw" />
  )
}
export default MenuIcon
