import { faGasPump, faLockA, faPlug, faUserLock } from "@fortawesome/pro-solid-svg-icons"
import moment from "moment"
import { statusString, statusStringForPosition } from "pages/assembly/helpers/helpers"
import UnitResultCard from "./UnitResultCard"

interface AssemblyField {
  name: string
  value: string
  type: string
  fields: AssemblyField[]
}

interface AssemblyData {
    status: string
    alcoholAlarmActive?: boolean
    driverCardActive?: boolean
    online?: string
    positionReceivedTime?: string
    tripFuel?: number
    totalTripFuel?: number
    mastercanReceivedTime?: string
    generalInfo: AssemblyField[]
    positions: AssemblyField[]
    mastercanData: AssemblyField[]
}

interface UnitResultProps {
  result: AssemblyData
}

const UnitResult = (props: UnitResultProps) => {
  const { result } = props

  return (
    <>
      <UnitResultCard
        // title="Position"
        icon={faPlug}
        label="Senaste Position"
        value={
          result.positionReceivedTime
            ? moment(result.positionReceivedTime).format("YYYY-MM-DD HH:mm:ss")
            : "-"
        }
        compareData={statusStringForPosition(result.status)}
      />
      <UnitResultCard
        // title="Alkolås"
        icon={faLockA}
        label="Alkolås"
        value={statusString(!!result.alcoholAlarmActive)}
        compareData={!!result.alcoholAlarmActive}
      />

      <UnitResultCard
        // title="Startspärr"
        icon={faUserLock}
        label="Startspärr"
        value={statusString(!!result.driverCardActive)}
        compareData={!!result.driverCardActive}
      />

      <UnitResultCard
        // title="Canbus"
        icon={faPlug}
        label="Senaste Canbus"
        value={
          result.mastercanReceivedTime
            ? moment(result.mastercanReceivedTime).format("YYYY-MM-DD HH:mm:ss")
            : "-"
        }
        compareData={!!result.mastercanReceivedTime}
      />

      <UnitResultCard
        // title="Trip fuel"
        icon={faGasPump}
        label="Trip fuel"
        compareData={!!result.totalTripFuel || !!result.tripFuel}
        value={result.tripFuel ? result.tripFuel : "-"}
        labelSecondary="Total fuel"
        valueSecondary={result.totalTripFuel ? result.totalTripFuel : "-"}
      />
    </>
  )
}

export default UnitResult
