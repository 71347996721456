import { Button, ButtonGroup, styled } from "@mui/material"

export const StyledButtonGroupSwitch = styled(ButtonGroup)(({ theme, color }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: "25rem!important",
  padding: "3px"
}))

export const StyledButtonSwitch = styled(Button)<{ active: boolean }>(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.background.paper : theme.palette.grey[200],
  borderRadius: "25rem!important",
  fontWeight: 400,
  border: 0,
  color: active ? theme.palette.grey[900] : theme.palette.grey[700]
}))
