import * as Yup from "yup";

export const contractValidationSchema = Yup.object().shape({
    name: Yup.string().required("Kundnamn är obligatoriskt").max(50, "Kundnamn får max vara 50 tecken långt"),
    internNumber: Yup.string().required("Internnr är obligatoriskt").max(50, "Internnr får max vara 50 tecken långt"),
    assignmentNr: Yup.string().required("Avtalsnummer är obligatoriskt").max(50, "Avtalsnummer får max vara 50 tecken långt"),
    nrOfHours: Yup.string().required("Timmar är obligatoriskt").max(50, "Timmar får max vara 50 tecken långt"),
    startDate: Yup.string().required("Startdatum är obligatoriskt").max(50, "Startdatum får max vara 50 tecken långt"),
    endDate: Yup.string().required("Slutdatum är obligatoriskt").max(50, "Slutdatum får max vara 50 tecken långt")
});
