import { List } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import { QuickLink } from "components/List/QuickLink"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import React, { useContext, useEffect } from "react"
export const DashboardQuickLinks = ({ route, title }) => {
  const appContext = useContext(AppContext)
  const isVehicleRegistryAdmin = hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)
  const isVehicleRegistryReports = hasRole(appContext.appUser, allRoles.VehicleRegistry_Reports)
  const isVehicleRegistryAlarms = hasRole(appContext.appUser, allRoles.VehicleRegistry_Alarms)

  const [userReportTemplates, userReportTemplatesApi] = useApi()

  useEffect(() => {
    const loadStuff = async () => {
      userReportTemplatesApi.get(apiEndpoints.vehicleregistry.getuserreporttemplates)
    }

    loadStuff()
  }, [userReportTemplatesApi])

  return (
    <List disablePadding>
      <QuickLink route={allRoutes.VehicleregistryVehicles} text="Fordonsregister" />

      {isVehicleRegistryReports && <QuickLink route={allRoutes.VehicleregistryReports} text="Rapporter" />}
      {isVehicleRegistryAlarms && <QuickLink route={allRoutes.Vehiclealarm} text="Fordonslarm" />}
      {isVehicleRegistryReports && isVehicleRegistryAdmin && (
        <>
          <QuickLink
            route={allRoutes.VehicleregistryVehiclesImportvtr}
            text="Importera från Transportstyrelsen"
          />
          <QuickLink route={allRoutes.VehicleregistryVehiclesImport} text="Importera fordon" />
          <QuickLink route={allRoutes.VehicleregistryReportsReporttemplates} text="Rapportmallar" />

          {userReportTemplates.status === 2 &&
            userReportTemplates.data.some((x) => x.name === "Miljörapport: Fordon") && ( //TODO fixa hårdkodning
              <>
                <QuickLink route={allRoutes.VehicleregistryReportsFueldata} text="Tankfiler" />
                <QuickLink
                  route={allRoutes.VehicleregistryReportsFueldataImport}
                  text="Importera tankfiler"
                />
              </>
            )}
          {userReportTemplates.status === 2 &&
            userReportTemplates.data.some((x) => x.name === "Miljörapport: Avtal") && (
              <>
                <QuickLink route={allRoutes.VehicleregistryReportsContract} text="Avtal" />
                <QuickLink route={allRoutes.VehicleregistryReportsContractImport} text="Importera avtal" />
              </>
            )}
        </>
      )}
    </List>
  )
}
