import { Box, CardContent, Grid, Typography } from "@mui/material"

import { faCommentLines, faLayerGroup } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LinkPaperBox from "components/LinkPaperBox"
import { allRoutes } from "generated/routes"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import useStyles from "./styles.js"

const ExaminationInfoCardVertical = (props) => {
  const { t } = useTranslation()

  const { item, assignedCourses } = props
  const styleProps = {
    image: "/assets/learning/card_".concat(item.examinationType).concat(".jpg")
  }

  const classes = useStyles(styleProps)

  return (
    <LinkPaperBox mt={1} mb={3} p={0}>
      <NavLink to={allRoutes.LearningUserExamsDetails(item.userExaminationId)}>
        <Box item className={classes.image} xs={12} />
        <CardContent className={classes.content}>
          <Typography variant="h5" gutterBottom>
            {assignedCourses ? assignedCourses?.examinationName : item.examinationName}
          </Typography>
          <Box marginBottom={1}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item className={classes.flex}>
                <FontAwesomeIcon icon={faCommentLines} className={classes.icon} />
                <Typography variant="bodySmall">
                  {item.examinationQuestionCount} {t("learning.titles.questions")}
                </Typography>
              </Grid>
              <Grid item className={classes.flex}>
                <FontAwesomeIcon icon={faLayerGroup} className={classes.icon} />
                <Typography variant="bodySmall">
                  {item.examinationAreaCount} {t("learning.titles.segments")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </NavLink>
    </LinkPaperBox>
  )
}
export default ExaminationInfoCardVertical
