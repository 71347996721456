import makeStyles from "@mui/styles/makeStyles"

const drawerWidth = "240px"

const useStyles = makeStyles((theme) => ({
  gridContainer: { height: "100%" },
  background: {
    background: theme.palette.background.dark
  },
  appBar: {
    background: theme.palette.background.dark,
    borderLeft: "3px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "none",
    marginLeft: drawerWidth,
    minHeight: 48,
    "& a": {
      fontWeight: 400,
      opacity: 0.7
    },
    "& .Mui-selected": {
      color: "white",
      fontWeight: 700,
      opacity: 1
    }
  },
  mainToolbar: ({ openDrawer }) => ({
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    justifyContent: "space-between",
    display: "flex",
    minHeight: "48px",

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    },
    marginLeft: !openDrawer ? `70px` : `236px`,
    padding: 0
  }),

  menuButton: {
    marginLeft: 0,
    color: "white"
  },
  hide: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },

  drawer: {
    position: "relative",
    overflowX: "hidden",
    flexShrink: 0,
    width: (props) => (props.openDrawer ? drawerWidth : "60px"),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen
    }),
    "& .MuiListItemIcon-root": {
      minWidth: "35px"
    },
    "& .MuiTab-labelIcon": {
      flexDirection: "row",
      justifyContent: "left",
      color: "white",
      "& svg": {
        marginRight: "13px"
      }
    },
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen
      }),
      [theme.breakpoints.up("sm")]: {
        width: (props) => (props.openDrawer ? drawerWidth : "60px")
      }
    },

    [theme.breakpoints.up("sm")]: {
      borderRight: "1px solid #212737"
    },
    [theme.breakpoints.down("xs")]: {
      width: (props) => (props.openDrawer ? drawerWidth : 0),
      flexShrink: 0,
      whiteSpace: "nowrap"
    }
  },

  list: {
    padding: theme.spacing(1, 2),
    WebkitTransition: "margin 0.2s ease-in",
    transition: "margin 0.2s ease-in",
    marginLeft: (props) => (props.openDrawer ? 16 : 0)
  },
  nested: {
    padding: theme.spacing(0.5, 3)
  },

  drawerArrowIcon: {
    color: "white",
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    borderRadius: "0.3rem 0 0 0.3rem",
    padding: "8px",
    marginBottom: "8px"
  },
  tabsLevel1: {
    "& a": {
      color: "white"
    }
  },
  tabsLevel2: {
    background: "#E6E7E9",
    minHeight: "34px",
    color: "#454444",

    "& a": {
      padding: "12px 16px;",
      textDecoration: "none",
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.87)",
      minHeight: "34px",
      fontWeight: 400,
      minWidth: 115,
      "&.Mui-selected": { fontWeight: 700, color: "#454444" }
    },
    "& .MuiTab-wrapper": {
      color: "red"
    },

    "& .MuiTab-root": {
      minHeight: "24px"
    }
    // "& .MuiTabs-indicator": {
    //     display: "none"
    // }
  },
  mainLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    WebkitTransition: "background-image 0.5s ease-in-out",
    transition: "background-image 0.5s ease-in-out",
    backgroundPosition: "center",
    backgroundSize: (props) => (props.openDrawer ? "auto 30px" : "auto 25px"),
    height: "100px",
    width: "100%",
    backgroundImage: (props) =>
      props.openDrawer
        ? "url('assets/transportportalen-logo-inverted.svg')"
        : "url('assets/transportportalen-symbol.svg')"
  },
  tabsVertical: {
    color: "white",
    marginLeft: (props) => props.openDrawer && 15,
    height: "100%",
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "left",
      [theme.breakpoints.up("sm")]: {
        justifyContent: (props) => !props.openDrawer && "center"
      }
    },
    "& .MuiTab-labelIcon": {
      minHeight: "48px",
      opacity: 0.7,
      borderRadius: "0.3rem 0 0 0.3rem",
      "& .MuiTab-wrapper > *:first-of-type": {
        marginRight: "13px",
        marginBottom: 0
      },
      paddingLeft: (props) => props.openDrawer && "15px"
    },
    "& .MuiTab-root": {
      minWidth: "auto",
      color: "white",
      opacity: 0.7
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.08)!important",
      opacity: 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
    height: "60px",
    background: "white",

    "& img": {
      width: "auto",
      maxWidth: "100%",
      padding: (props) => (props.openDrawer ? "0 25px" : "5px")
    }
  },
  tabsRoot: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    }
  },

  scroller: {
    [theme.breakpoints.down("md")]: {
      flexGrow: "0"
    }
  },
  bigIndicator: {
    height: 4
  },
  bigIndicatorVertical: {
    width: 4,
    left: 0,
    borderRadius: "0.3rem 0 0 0.3rem"
  },
  logoResp: { width: "100%", maxWidth: "160px", height: "auto", minHeight: "40px", overflow: "hidden" },
  tabsLabel: {
    color: "#fff",
    paddingLeft: "15px",
    paddingTop: "20px"
  },
  sidebarDrawer: {
    flexShrink: 1
  },
  drawerPaper: {
    backgroundColor: "#ffff",
    width: "450px",
    paddingBottom: "50px",
    marginTop: "50px",
    zIndex: 1999,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%"
    }
  },
  showInSm: {
    display: "none",
    [theme.breakpoints.only("sm")]: {
      display: "flex"
    }
  }
}))

export default useStyles
