import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React, { useContext, useEffect, useRef, useState } from "react"
import Moment from "react-moment"
import { ImportFuelContext } from "./ImportFuelFormStateProvider"
import { useStyles } from "./useStyles"

export const PreviewImportArea = () => {
  const importDataContext = useContext(ImportFuelContext)

  const classes = useStyles()

  const [importData, setImportdata] = useState(importDataContext.getDataToImport())

  const [selectedData, setDataFields] = useState(importDataContext.getSelectedData())

  const tableRef = useRef()
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)

  const getSize = () => {
    if (!tableRef.current) return
    if (tableRef && tableRef.current.getBoundingClientRect() != null) {
      const elemFromTop = tableRef.current.getBoundingClientRect().top + 62
      setHeight(window.innerHeight - elemFromTop)
      const elemFromLeft = tableRef.current.getBoundingClientRect().left
      setWidth(window.innerWidth - elemFromLeft)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", getSize)
  }, [])

  useEffect(() => {
    if (tableRef) {
      const elemFromTop = tableRef.current.getBoundingClientRect().top + 58
      setHeight(window.innerHeight - elemFromTop)

      const elemFromLeft = tableRef.current.getBoundingClientRect().left
      setWidth(window.innerWidth - elemFromLeft)
    }
  }, [])
  return (
    <Box bgcolor="background.paper" borderRadius="8px" className={classes.rootShadow} paddingY={1}>
      <Container>
        <Box paddingBottom={3}>
          <Typography variant="h2" gutterBottom>
            Förhandsgranska
          </Typography>

          <Typography variant="bodySmall">I fälten nedan visas datan som kommer importeras</Typography>
        </Box>
      </Container>
      <Box>
        <TableContainer
          className={classes.tableContainer}
          style={{ height: height, width: width }}
          ref={tableRef}>
          <Table size="small" className={classes.table} stickyHeader>
            <TableHead>
              <TableRow>
                {selectedData.current.columns.map((column) => (
                  <TableCell>{column.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {importData &&
                importData.map((row) => (
                  <TableRow key={row.name}>
                    {selectedData.current.columns.map(function (col) {
                      return (
                        <TableCell component="td" scope="row">
                          {col.dataType === "date" ? (
                            <Moment format="YYYY-MM-DD HH:mm" globallocale="sv">
                              {row[col.id]}
                            </Moment>
                          ) : (
                            row[col.id]
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
