import {Box, Grid, Typography} from '@mui/material'
import moment from 'moment'

export const AssignmentListItem = (props) => {
  const {assignment} = props
  return (
    <Box marginY={2}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={8} md={3}>
          <Typography variant="h2">
            {moment(assignment.starttid).format('HH:mm')} {' - '}
            {moment(assignment.sluttid).format('HH:mm')}
          </Typography>
        </Grid>
        <Grid item sm={6} md={1}>
          <Typography variant="caption" color="textSecondary">
            Avg.tid
          </Typography>
          <Typography>
            {assignment.turStarttid
              ? moment(assignment.turStarttid).format('HH:mm')
              : '-'}
          </Typography>
        </Grid>
        <Grid item sm={6} md={2}>
          <Typography variant="caption" color="textSecondary">
            Internnr
          </Typography>
          <Typography>{assignment.fordonStr}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container direction="column" justifyContent="flex-end">
            <Grid item>
              <Typography variant="caption" color="textSecondary">
                Tjänst
              </Typography>

              <Typography>{assignment.beskrivning}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textSecondary">
            Notering
          </Typography>
          <Typography>
            {assignment.anmarkning ? assignment.anmarkning : '-'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
