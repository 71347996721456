import { Container } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useContext, useEffect } from "react"
import { EditCustomerForm } from "../../components/EditCustomerForm"

const EditCustomer = () => {
  const [customerResult, getCustomer] = useApiGet()
  const [logoResult, getLogoData] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    getCustomer(apiEndpoints.customeradmin.getcustomer)
  }, [getCustomer])
  useEffect(() => {
    if (customerResult.data && customerResult.data.logoName) {
      getLogoData(
        apiEndpoints.customeradmin.getcustomerlogobyname.concat("?name=" + customerResult.data.logoName)
      )
    }
  }, [customerResult, getLogoData])

  useEffect(() => {
    loadingContext.setLoading(() => customerResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => logoResult.status === 1)
  })

  return (
    <>
      {customerResult.status === 2 ? (
        <EditCustomerForm initialValues={customerResult.data} logoResult={logoResult} />
      ) : (
        <Container>
          <SkeletonCard />
        </Container>
      )}
    </>
  )
}
export default EditCustomer
