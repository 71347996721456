import React, { createContext, useState } from "react"

export const ExamFormContext = createContext()
const shuffleArray = (array) => {
  let i = array.length - 1
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}
export const ExamFormStateProvider = ({ children }) => {
  const values = React.useRef([])

  const [randomArea, setRandomArea] = useState([])
  const getRandomizeQuestions = (exam) => {
    let newExam = exam

    const randomizedAreas = newExam?.areas?.map((area) => {
      const randomizedQuestions = shuffleArray(area)?.questions?.map((question) => {
        const shuffledoptions = shuffleArray(question.options)
        return {
          ...question,
          options: shuffledoptions,
          shuffleId: `${question.id}Q${Math.floor(Math.random() * 2) + 1}`
        }
      })
      return {
        ...area,
        areaQuestions: randomizedQuestions
      }
    })

    newExam.areas = randomizedAreas
    setRandomArea(newExam)
    return newExam
  }

  const updateAnswer = (answer) => {
    const prevValues = values.current

    values.current = [...prevValues.filter((s) => s.questionId !== answer.questionId), answer]
  }

  const getSelectedAnswers = (qId, isSingle) => {
    var answers = []
    let question = values.current.filter((q) => q.questionId === qId)[0]
    if (question) {
      question.answer.forEach((a) => {
        answers.push(a)
      })
    }
    return !isSingle ? answers : answers.length > 0 ? answers[0] : ""
  }

  return (
    <ExamFormContext.Provider
      value={{ values, randomArea, updateAnswer, getSelectedAnswers, getRandomizeQuestions }}>
      {children}
    </ExamFormContext.Provider>
  )
}
