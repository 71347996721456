export const statusButtonText = (value) => {
  switch (value) {
    case 1:
      return "Spara och skicka"
    case 2:
      return "Avboka arbetsorder"
    case 3:
      return "Avboka arbetsorder"
    case 4:
      return "Stäng och avsluta"
    default:
      return "Spara och skicka"
  }
}

export const statusButtonColor = (value) => {
  switch (value) {
    case 1:
      return "primary"
    case 2:
      return "error"
    case 3:
      return "error"
    case 4:
      return "success"
    default:
      return "primary"
  }
}

export const statusSubText = (value) => {
  switch (value) {
    case 1:
      return "När ordern är skickad kan du inte göra ändringar längre."
    case 2:
      return "Om du avbokar ordern skickas en bekräftelse till samma e-postadress."
    case 3:
      return "Om du avbokar ordern skickas en bekräftelse till samma e-postadress."
    case 4:
      return "När du stänger ordern uppdateras status på alla åtgärdade felrapporter"
    case 6:
      return "Din arbetsorder är avbokad"
    default:
      return "När ordern är skickad kan du inte göra ändringar längre."
  }
}
