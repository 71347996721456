import { TextField } from '@mui/material';
import React from 'react';
import { useStyles } from "../../../components/useStyles";

const EmailBoxInput = ({ values, handleBlur, handleChange, touched, errors }) => {

    const classes = useStyles();

    return (
        <TextField
            className={classes.whiteLabel}
            placeholder="Ange e-postadress"
            autoComplete="off"
            fullWidth
            id="emailRecipient"
            value={values.emailRecipient}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.emailRecipient && errors.emailRecipient ? true : false}
            helperText={touched.emailRecipient && errors.emailRecipient ? errors.emailRecipient : null}
            variant="outlined"
            InputProps={{ classes: { input: classes.formAreaInput }, disableUnderline: true }}
        />
    )
}

export default EmailBoxInput