import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Box, Container, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useContext, useEffect, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"
import { EditCustomerNavigation } from "../../components/EditCustomerNavigation"

const EditCustomerUsers = ({ match }) => {
  const globalClasses = globalUseStyles()
  const [usersResult, getUsers] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)
  const routerHistory = useHistory()
  
  useEffect(() => {
    getUsers(apiEndpoints.systemadmincustomers.getcustomerusers.replace("{id}", match.params.id))
  }, [getUsers, match.params.id])

  useEffect(() => {
    loadingContext.setLoading(() => usersResult.status === 1)
  })

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const openSettingsDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeSettingsDropdown = () => {
    setAnchorEl(null)
  }

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        numeric: false,
        Header: "Namn",
        hiddenFilter: true
      },
      {
        accessor: "username",
        numeric: true,
        disablePadding: false,
        Header: "Användarnamn",
        hiddenFilter: true
      },
      {
        accessor: "email",
        numeric: false,
        disablePadding: false,
        Header: "E-post",
        hiddenFilter: true
      },
      {
        accessor: (cell) => {
          return cell.disabled ? "Ja" : ""
        },
        numeric: false,
        disablePadding: false,
        Header: "Inaktiverad",
        hiddenFilter: true
      }
    ],
    []
  )
  return (
    <div>
      <EditCustomerNavigation customerId={match.params.id} />
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Administrera användare</Typography>
            <Box>
              <Button
                color="primary"
                component={Link}
                to={allRoutes.SystemadminCustomersEditUsersAdd(match.params.id)}>
                Lägg till användare
              </Button>
              <IconButton
                aria-label="Inställningar"
                aria-controls="settings-menu"
                aria-haspopup="true"
                onClick={openSettingsDropdown}
                size="large">
                <MoreVertIcon fontSize="large" />
              </IconButton>
              <Menu id="settings-menu" anchorEl={anchorEl} open={open} onClose={closeSettingsDropdown}>
                <MenuItem
                  onClick={() => {
                    routerHistory.push(allRoutes.SystemadminCustomersEditUsersImport(match.params.id))
                  }}>
                  Importera användare
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    routerHistory.push(
                      allRoutes.SystemadminCustomersEditUsersImportlegacyexams(match.params.id)
                    )
                  }}>
                  Importera utbildningsresultat
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          rows={usersResult.status === 2 && usersResult.data}
          status={usersResult.status}
          columns={columns}
          rowProps={(row) => ({
            onClick: () =>
              routerHistory.push(
                allRoutes.SystemadminCustomersEditUsersEdit(match.params.id, row.original.id)
              )
          })}
        />
      </Box>
    </div>
  )
}
export default EditCustomerUsers
