import { ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"

import React from "react"

export const SkeletonCardAvatar = () => {
  return (
    <>
      <PaperBox>
        <ListItem disableGutters>
          <ListItemAvatar>
            <Skeleton variant="circular" width={55} height={55} sx={{ marginRight: 2 }} />
          </ListItemAvatar>
          <ListItemText>
            <Typography color="secondary" variant="bodyMedium">
              <Skeleton />
            </Typography>
            <Typography variant="h1">
              <Skeleton />
            </Typography>
          </ListItemText>
        </ListItem>
      </PaperBox>
    </>
  )
}
