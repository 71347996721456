import { Box, Divider, Grid, Typography } from "@mui/material"
import React from "react"
import { TextCell } from "./components/TextCell"
import moment from "moment/moment"
import FormPreviewFileThumbnail from "../../components/Form/FormComponents/FormUploadFile/FormPreviewFileThumbnail"

const TechnicalData = ({ data, assemblyData }) => {
  return (
    <>
      <Grid container direction="row" spacing={5}>
        <Grid item xs={12} md={8}>
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Kontrollbesiktning
            </Typography>
            <Service data={data} />
          </Box>
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Axlar och hjul
            </Typography>
            <AxlesAndWheels data={data} />
          </Box>
          <Divider light />
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Mått och vikt
            </Typography>
            <WeightsAndMeasures data={data} />
          </Box>
          <Divider light />
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Motor och miljö
            </Typography>
            <EngineAndEnvironment data={data} />
          </Box>
          <Divider light />
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Passagerare
            </Typography>
            <Passenger data={data} />
          </Box>
          <Divider light />
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Kopplingsanordning och bromsar
            </Typography>
            <CouplingDeviceAndBrakes data={data} />
          </Box>
          <Divider light />
          {assemblyData?.data?.attachments?.length > 0 && (
            <Box py={3}>
              <Typography variant="h3" color="primary" marginBottom={2}>
                Bilder på montering
              </Typography>
              <AssemblyImages data={assemblyData} />
            </Box>
          )}
          <Divider light />
          <Box py={3}>
            <Typography variant="h3" color="primary" marginBottom={2}>
              Övrigt
            </Typography>
            <Other data={data} />
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box bgcolor="background.light" padding={3} borderRadius="10px">
            <Grid container direction="column">
              <Grid item>
                <TextCell label="Växellåda" text={data.vaxellada} />
              </Grid>
              <Grid item>
                <TextCell label="Drivmedel 1" text={data.drivMedel1Kod} />
              </Grid>
              <Grid item>
                <TextCell label="Sittande passagerare " text={data.antalSittande} />
              </Grid>
              <Grid item>
                <TextCell label="Totalt antal passagerare" text={data.antalPassagerare} />
              </Grid>
            </Grid>
          </Box>
          <Box my={3} bgcolor="background.light" padding={3} borderRadius="10px">
            <Grid container direction="column">
              <Grid item>
                <Typography color="primary" variant="h3" gutterBottom>
                  Senaste besiktning
                </Typography>
              </Grid>
              <Grid item>
                <TextCell
                  label="Besiktad"
                  text={
                    data.besiktningsdatum && (
                      <Typography variant="bodyMedium" fontWeight={600}>{moment.utc(data.besiktningsdatum).local().format("YYYY-MM-DD")}</Typography>
                    )
                  }
                />
              </Grid>
              <Grid item>
                <TextCell label="Besiktningsstation" text={data.besiktningsstation} />
              </Grid>
              <Grid item>
                <TextCell label="Besiktningsprogramkod" text={data.besiktningsprogramkod} />
              </Grid>
              <Grid item>
                <TextCell label="Mätarställning" text={data.matarstallning} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

const AxlesAndWheels = ({ data }) => {
  return (
    <Grid container direction="row" spacing={5}>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <TextCell label="Axlar" text={data.antalAxlar} />
          </Grid>
          <Grid item>
            <TextCell label="Axelavstånd 1" text={data.axelavstand1} />
          </Grid>
          <Grid item>
            <TextCell label="Axelavstånd 2" text={data.axelavstand2} />
          </Grid>
          <Grid item>
            <TextCell label="Axelavstånd 3" text={data.axelavstand3} />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <TextCell label="Däckdimension fram" text={data.dackdimensionFram} />
          </Grid>
          <Grid item>
            <TextCell label="Däckdimension bak" text={data.dackdimensionBak} />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" spacing={5}>
          <Grid item>
            <TextCell label="Fälgdimension fram" text={data.falgdimensionFram} />
          </Grid>
          <Grid item>
            <TextCell label="Fälgdimension bak" text={data.falgdimensionBak} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const WeightsAndMeasures = ({ data }) => {
  return (
    <Grid container direction="row" spacing={5}>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <TextCell label="Bredd" text={data.bredd} />
          </Grid>
          <Grid item>
            <TextCell label="Höjd" text={data.hojd} />
          </Grid>
          <Grid item>
            <TextCell label="Längd" text={data.langd} />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <TextCell label="Max last" text={data.maxLastvikt} />
          </Grid>
          <Grid item>
            <TextCell label="Tjänstevikt" text={data.tjanstevikt} />
          </Grid>
          <Grid item>
            <TextCell label="Totalvikt" text={data.totalvikt} />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" spacing={5}>
          <Grid item>
            <TextCell label="Kategori av däck Axel 1" text={data.kategoriAvDackAxel1} />
          </Grid>
          <Grid item>
            <TextCell label="Kategori av däck Axel 2" text={data.kategoriAvDackAxel2} />
          </Grid>
          <Grid item>
            <TextCell label="Kategori av däck Axel 3" text={data.kategoriAvDackAxel3} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const EngineAndEnvironment = ({ data }) => {
  return (
    <>
      <Grid container direction="row" spacing={5}>
        <Grid item>
          <TextCell label="EEG Godkännandenummer" text={data.eeg} />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={5}>
        <Grid item>
          <TextCell label="Växellåda" text={data.vaxellada} />
        </Grid>
        <Grid item>
          <TextCell label="Effekt" text={data.driv1Motoreffekt} />
        </Grid>
        <Grid item>
          <TextCell label="Fordonskategori EU" text={data.fordonskategoriEU} />
        </Grid>

        <Grid item>
          <TextCell label="Miljöklass" text={data.miljoklass} />
        </Grid>
        <Grid item>
          <TextCell label="Miljöklass Euro" text={data.miljoklassEuro} />
        </Grid>
        <Grid item>
          <TextCell label="Emmisionsvärde Enhet" text={data.emmisionsvardeEnhet} />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={5}>
        <Grid item>
          <Typography variant="h3" color="primary" marginBottom={2}>
            Drivmedel 1
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={5}>
        <Grid item>
          <TextCell label="Drivmedel" text={data.drivMedel1Kod} />
        </Grid>
        <Grid item>
          <TextCell label="Karosserikod" text={data.karosserikoDriv1} />
        </Grid>

        <Grid item>
          <TextCell label="Förbrukning" text={data.forbrukningDriv1} />
        </Grid>
        <Grid item>
          <TextCell label="CO2" text={data.CO2Driv1} />
        </Grid>
        <Grid item>
          <TextCell label="NOx" text={data.nOxDriv1} />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={5}>
        <Grid item>
          <Typography variant="h3" color="primary" marginBottom={2}>
            Drivmedel 2
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={5}>
        <Grid item>
          <TextCell label="Drivmedel" text={data.drivMedel2Kod !== "0" && data.drivMedel2Kod} />
        </Grid>

        <Grid item>
          <TextCell label="Karosserikod" text={data.karosserikoDriv2} />
        </Grid>

        <Grid item>
          <TextCell label="Förbrukning" text={data.forbrukningDriv2} />
        </Grid>
      </Grid>
    </>
  )
}

const AssemblyImages = ({ data }) => {
  return (
    <Grid container direction="row" spacing={2}>
      {data?.data?.attachments.map((attachment) => (
        <Grid item xs={12} md={4}>
          <FormPreviewFileThumbnail
            fileId={attachment.fileId}
            fileName={attachment.fileName}
            Data={attachment.base64}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const Other = ({ data }) => {
  return (
    <Grid container direction="row" spacing={5}>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <TextCell label="Tankvolym" text={data.tankvolymDriv1} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const CouplingDeviceAndBrakes = ({ data }) => {
  return (
    <Grid container direction="row" spacing={5}>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <TextCell label="Kopplingsanordningar" text={data.kopplingsanordning} />
          </Grid>
          <Grid item>
            <TextCell
              label="Utrustning"
              text={`${data.utrustning1}, ${data.utrustning2}, ${data.utrustning3}`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Passenger = ({ data }) => {
  return (
    <Grid container direction="row" spacing={5}>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <TextCell label="Sittande passagerare " text={data.antalSittande} />
          </Grid>
          <Grid item>
            <TextCell label="Totalt antal passagerare" text={data.antalPassagerare} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Service = ({ data }) => {
  return (
    <Grid container direction="row" spacing={5}>
      <Grid item md={8}>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <TextCell label="Besiktningsgrupp" text={data.besiktningsgrupp} />
          </Grid>
          <Grid item>
            <TextCell
              label="Besiktning senast"
              text={
                data.besiktningsdatumtom && (
                  <Typography variant="bodyMedium" fontWeight={600}>{moment.utc(data.besiktningsdatumtom).local().format("YYYY-MM-DD")}</Typography>
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <TextCell
              label="Föregående besiktning"
              text={
                data.foregaendeBesiktningsdatum &&
                moment.utc(data.foregaendeBesiktningsdatum).isAfter(moment.utc("0001-01-01")) && (
                  <Typography variant="bodyMedium" fontWeight={600}>{moment.utc(data.foregaendeBesiktningsdatum).local().format("YYYY-MM-DD")}</Typography>
                )
              }
            />
          </Grid>
          <Grid item>
            <TextCell label="Föregående besiktningsprogramkod" text={data.foregBesiktningsprogramkod} />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <TextCell label="Besiktningsstation" text={data.besiktningsstation} />
          </Grid>
          <Grid item>
            <TextCell label="Mätarställning färegående besiktning" text={data.foregaendeKorstracka} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TechnicalData