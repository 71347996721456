import React, { useContext, useEffect, useState } from "react"
import { useApiGet, useApiPost } from "auth/useApi2"
import { CompanyForm } from "../../components/CompanyForm"
import { companyValidationSchema } from "pages/systemadmin/pages/customers/components/validationSchema"
import { apiEndpoints } from "generated/apiEndpoints"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { Box, Container, Grid, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import { allRoutes } from "generated/routes"
import { PageTabs } from "components/navigation/PageTabs"
import SubTabs from "components/SubTabs"

const AddCompany = () => {
    const [customerResult, getCustomer] = useApiGet();
    const [addCompanyResult, addCompany] = useApiPost();
    const loadingContext = useContext(GlobalLoaderContext);
    const routerHistory = useHistory();
    const [tabValue, setTabValue] = useState(0)
    const [tabs, setTabs] = useState([
        { label: "Bolagsinformation" },
        { label: "Avdelningar" },
    ])

    useEffect(() => {
        // efter skapa success, redirecta till listan
        if (addCompanyResult.status === 2) routerHistory.push(allRoutes.CustomerSettingsCompanies());
    }, [addCompanyResult.status, routerHistory]);

    useEffect(() => {
        getCustomer(apiEndpoints.customeradmin.getcustomer);
    }, [getCustomer]);

    useEffect(() => {
        loadingContext.setLoading(() => customerResult.status === 1);
    });

    const handleSubmit = (objectToSave) => {
        addCompany(apiEndpoints.customeradmin.addcompany, objectToSave);
    };

    return (
        <div>
            <PageTabs
                routeBack
                tabs={[
                    {
                        path: allRoutes.CustomerSettingsCompanies(),
                        title: "Tillbaka"
                    }
                ]}
            />
            <Container>
                <Box marginY={3}>
                    <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h2">Lägg till bolag</Typography>
                    </Grid>
                </Box>
                {customerResult.status === 2 ? (
                  <>
                      <SubTabs tabs={tabs} value={tabValue} setValue={setTabValue} />
                      <CompanyForm
                        customer={customerResult.data}
                        validationSchema={companyValidationSchema}
                        onSubmit={handleSubmit}
                        result={addCompanyResult}
                        tabValue={tabValue}
                      />
                  </>
                ) : null}
            </Container>
        </div>
    );
};
export default AddCompany;
