import { Box, Container, Grid, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useContext, useEffect, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"
import { EditCustomerNavigation } from "../../components/EditCustomerNavigation"

const EditCustomerCompanies = ({ match }) => {
  const globalClasses = globalUseStyles()
  const routerHistory = useHistory()

  const [customerResult, getCustomer] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    getCustomer(apiEndpoints.systemadmincustomers.getcustomerbyid.replace("{id}", match.params.id))
  }, [getCustomer, match.params.id])

  useEffect(() => {
    loadingContext.setLoading(() => customerResult.status === 1)
  })

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        numeric: false,
        Header: "Namn"
      },
      {
        accessor: "organisationNumber",
        numeric: true,
        disablePadding: false,
        Header: "Orgnr"
      },
      {
        accessor: "streetAddress",
        numeric: false,
        disablePadding: false,
        Header: "Postadress"
      },
      {
        accessor: "postalCode",
        numeric: true,
        disablePadding: false,
        Header: "Postnummer"
      },
      {
        accessor: "cityAddress",
        numeric: false,
        disablePadding: false,
        Header: "Ort"
      }
    ],
    []
  )

  return (
    <div>
      <EditCustomerNavigation customerId={match.params.id} />
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Administrera bolag</Typography>
            <Button
              color="primary"
              component={Link}
              to={allRoutes.SystemadminCustomersEditCompaniesAdd(match.params.id)}>
              Lägg till Bolag
            </Button>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          rows={customerResult.status === 2 && customerResult.data.companies}
          status={customerResult.status}
          columns={columns}
          rowProps={(row) => ({
            onClick: () =>
              routerHistory.push(
                allRoutes.SystemadminCustomersEditCompaniesEdit(row.original.customerId, row.original.id)
              )
          })}
        />
      </Box>
    </div>
  )
}
export default EditCustomerCompanies
