import { Box, Container, Grid, Typography } from "@mui/material"
import { useApiGet, useApiPost } from "auth/useApi2"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { PageTabs } from "components/navigation/PageTabs"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { EquipmentForm } from "../../components/EquipmentForm"

const EquipmentAdd = () => {
  const [addEquipmentResult, addEquipment] = useApiPost()
  const routerHistory = useHistory()
  const loadingContext = React.useContext(GlobalLoaderContext)

  const [companyResult, getCompany] = useApiGet()
  const [storagesResult, getStorages] = useApiGet()
  const [devicesResult, getDevices] = useApiGet()
  const [equipmentTypesResult, getEquipmentTypes] = useApiGet()
  const [equipmentStatuses, getEquipmentStatuses] = useApiGet()

  const handleSubmit = (objectToSave) => {
    addEquipment(apiEndpoints.systemadmindeviceregistry.addequipment, objectToSave)
  }

  useEffect(() => {
    getCompany(apiEndpoints.systemadmindeviceregistry.listcompanies)
    getStorages(apiEndpoints.systemadmindeviceregistry.liststorages)
    getDevices(apiEndpoints.systemadmindeviceregistry.listdevices)
    getEquipmentTypes(apiEndpoints.systemadmindeviceregistry.listequipmenttypes)
    getEquipmentStatuses(apiEndpoints.systemadmindeviceregistry.getequipmentstatuses)
  }, [getCompany, getDevices, getStorages, getEquipmentTypes, getEquipmentStatuses])

  useEffect(() => {
    loadingContext.setLoading(() => companyResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => storagesResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => devicesResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => equipmentTypesResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => equipmentStatuses.status === 1)
  })

  useEffect(() => {
    if (addEquipmentResult.status === 2) routerHistory.push(allRoutes.SystemadminDevicesEquipment())
  }, [addEquipmentResult.status, routerHistory])

  const feedback = {
    errorTitle: "Utrustning kunde inte skapas"
  }

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.SystemadminDevicesEquipment(),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        <Box marginY={3}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Typography variant="h2">Lägg till utrustning</Typography>
          </Grid>
        </Box>
        {companyResult.status === 2 &&
          devicesResult.status === 2 &&
          equipmentTypesResult.status === 2 &&
          equipmentStatuses.status === 2 &&
          storagesResult.status === 2 && (
            <EquipmentForm
              companyResult={companyResult}
              storagesResult={storagesResult}
              devicesResult={devicesResult}
              equipmentTypesResult={equipmentTypesResult}
              equipmentStatuses={equipmentStatuses}
              onSubmit={handleSubmit}
              feedback={feedback}
              result={addEquipmentResult}
            />
          )}
      </Container>
    </div>
  )
}

export default EquipmentAdd
