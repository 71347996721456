import { Typography } from "@mui/material"
import ListItemText from "@mui/material/ListItemText"
import { makeStyles } from "@mui/styles"
import React, { useContext } from "react"
import { MapContext } from "../../MapContextProvider"

const useStyles = makeStyles(() => ({
  paragraphLarge: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#454444"
  },
  paragraphMedium: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#747474"
  }
}))

const DeviceLabel = ({ device }) => {
  const classes = useStyles()
  const { onDeviceListLabelClick } = useContext(MapContext)

  return (
    <ListItemText
      onClick={() => {
        onDeviceListLabelClick(device)
      }}>
      <Typography color="primary" className={classes.paragraphLarge} variant="paragraphLarge">
        {device.internalDeviceId}
      </Typography>
      <Typography className={classes.paragraphMedium} variant="paragraphMedium">
        {device.label}
      </Typography>
    </ListItemText>
  )
}

export default DeviceLabel
