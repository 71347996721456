import { Box, Container } from "@mui/material"
import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import { default as React, useEffect, useState } from "react"
import WorkOrderForm from "../../components/WorkOrderForm"

const WorkOrderEdit = ({ match }) => {
  const loadingContext = React.useContext(GlobalLoaderContext)
  const [workorderResult, workorderApi] = useApi()
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [vehicleId, setVehicle] = useState()
  const [updateResult, updateWorkOrderApi] = useApi()

  // get workorder data
  useEffect(() => {
    workorderApi.get(apiEndpoints.issuereportsadmin.getworkorderdetails.replace("{id}", match.params.id))
    return () => {
      workorderApi.leave()
    }
  }, [workorderApi, match.params.id])

  useEffect(() => {
    loadingContext.setLoading(() => workorderResult.status === 1)
  })

  // update workorder
  const onUpdate = (values) => {
    updateWorkOrderApi.put(apiEndpoints.issuereportsadmin.updateworkorder, values)
  }

  const updateAndSend = (values) => {
    let valuesObj = {
      ...values
    }
    valuesObj.sendEmail = true
    if (values.status === 1) {
      valuesObj.status = 2 // Skicka arbetsorder till verkstad
      updateWorkOrderApi.put(apiEndpoints.issuereportsadmin.updateworkorder, valuesObj)
    } else if (values.status === 2 || values.status === 3) {
      valuesObj.status = 6 // Avboka arbetsorder

      updateWorkOrderApi.put(apiEndpoints.issuereportsadmin.updateworkorder, valuesObj)
    } else if (values.status === 4) {
      valuesObj.status = 5 // Stäng och avsluta arbetsorder
      updateWorkOrderApi.put(apiEndpoints.issuereportsadmin.updateworkorder, valuesObj)
    }
  }

  // remove modal if update succeeded
  useEffect(() => {
    if (updateResult.status === 2) {
      setShowUpdateModal(false)
      workorderApi.get(apiEndpoints.issuereportsadmin.getworkorderdetails.replace("{id}", match.params.id))
    }
  }, [updateResult.status, workorderApi])

  const initialValues = workorderResult.status === 2 && workorderResult.data

  return (
    <div>
      <Container>
        <Box marginY={3} mt={0}>
          {workorderResult.status === 2 && (
            <WorkOrderForm
              updateResult={updateResult}
              onUpdate={onUpdate}
              showUpdateModal={showUpdateModal}
              setShowUpdateModal={setShowUpdateModal}
              initialValues={initialValues}
              match={match}
              setVehicle={setVehicle}
              vehicleId={vehicleId}
              workorderResult={workorderResult.status === 2 && workorderResult?.data}
              sendBooking={updateAndSend}
            />
          )}
        </Box>
      </Container>
    </div>
  )
}
export default WorkOrderEdit
