import { Box } from "@mui/material"
import { RouterPrompt } from "components/RouterPrompt"
import { Formik } from "formik"
import { useEffect, useRef } from "react"
import * as Yup from "yup"
import { Sidebar } from "../../components/Sidebar"
import { useStyles } from "../../components/useStyles"

import { faBoxesStacked } from "@fortawesome/pro-solid-svg-icons"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import {
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Typography
} from "@mui/material"
import Button from "components/Button"
import { CircleIcon } from "components/icons/CircleIcon"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"

const validateionSchema = Yup.object().shape({
  name: Yup.string().required("Namn är obligatoriskt"),

  active: Yup.bool()
})

const IssueReportCategoryForm = (props) => {
  const {
    showSidebar,
    setShowSidebarForm,
    editItemResult,
    addResult,
    updateResult,
    editMode,
    onAddSubmit,
    onUpdateSubmit
  } = props
  const classes = useStyles()

  const formRef = useRef()

  const initialValues =
    editMode && editItemResult.status === 2
      ? {
          ...editItemResult.data
        }
      : {
          id: 0,
          name: "",
          isActive: true
        }

  const handleSubmit = (values) => {
    if (editMode) {
      onUpdateSubmit(values)
    } else {
      onAddSubmit(values)
    }
  }
  useEffect(() => {
    if (!showSidebar) {
      formRef.current.resetForm()
    }
  }, [showSidebar])

  const buttonFeedbackResult = editMode ? updateResult : addResult

  return (
    <Sidebar showSidebar={showSidebar}>
      <Formik
        initialValues={initialValues}
        validationSchema={validateionSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formRef}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setFieldValue
        }) => (
          <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
            <RouterPrompt />
            <List className={classes.vehicleList}>
              <CloseSidebarButton
                toggleMenu={() => {
                  setShowSidebarForm(!showSidebar)
                }}
              />
              <Box pt={2} pb={3} className={classes.fullWidth}>
                <ListItemIcon className={classes.bigListItemIconWrapper}>
                  <CircleIcon icon={faBoxesStacked} color="primary" size="lg" />
                </ListItemIcon>

                <Box row="column" justifyContent="center">
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="h2">
                        Skapa kategori
                      </Typography>
                    }
                    secondary={
                      <Typography color="textSecondary" variant="bodySmall">
                        Kategori
                      </Typography>
                    }
                  />
                </Box>
              </Box>
              <Box className={classes.greyBorder}></Box>
              <ListItem className={classes.listItem}>
                <Box mt={5} className={classes.fullWidth}>
                  <ListItemText
                    classes={{
                      secondary: classes.listItemText,
                      primary: classes.listItemTextPrimary
                    }}
                    primary="Namn"
                    style={{ paddingBottom: "8px" }}
                  />
                </Box>
                <TextField
                  autoComplete="off"
                  className={classes.textField}
                  fullWidth
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && errors.name ? true : false}
                  helperText={touched.name && errors.name ? errors.name : null}
                  variant="outlined"
                  InputProps={{ classes: { input: classes.formAreaInput }, disableUnderline: true }}
                />
              </ListItem>

              <ListItem className={classes.listItem}>
                <Box className={classes.fullWidth}>
                  <ListItemText
                    classes={{
                      secondary: classes.listItemText,
                      primary: classes.listItemTextPrimary
                    }}
                    style={{ paddingBottom: "8px" }}
                    primary="Aktiv"
                  />
                </Box>

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(item) => {
                        const checked = item.target.checked
                        setFieldValue("isActive", checked)
                      }}
                      checked={values.isActive}
                    />
                  }
                />
              </ListItem>

              <Box pt={3} pb={5} display="flex" justifyContent="center">
                <Button
                  color="primary"
                  endIcon={<AddCircleIcon />}
                  variant="contained"
                  type="submit"
                  result={buttonFeedbackResult}>
                  {editMode ? "Spara kategori" : "Lägg till kategori"}
                </Button>
              </Box>
            </List>
          </form>
        )}
      </Formik>
    </Sidebar>
  )
}
export default IssueReportCategoryForm
