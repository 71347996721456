import { faSquarePlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, IconButton, ListItem, Typography } from "@mui/material"
import moment from "moment"
import { useState } from "react"
import { useStyles } from "./useStyles"

const ReadMoreReadLess = ({ description, date }) => {
  const classes = useStyles()
  const [isReadMoreShown, setReadMoreShown] = useState(description.length < 33)

  const ToggleBtn = () => {
    setReadMoreShown((prevState) => !prevState)
  }

  return (
    <Grid container mt={1} direction="row">
      <ListItem style={{ minHeight: "0px" }} className={classes.readMoreListItem}>
        <Grid container md={1} justifyContent="start" direction="row">
          <IconButton className={classes.readMoreIcon} color="primary" onClick={ToggleBtn}>
            <FontAwesomeIcon icon={faSquarePlus} size="xs" color="lightblue" />
          </IconButton>
        </Grid>
        <Grid container md={8} direction="row">
          <Typography color={isReadMoreShown ? "" : "grey"} variant="bodySmall">
            {isReadMoreShown ? description : description.substr(0, 33) + "..."}
          </Typography>
        </Grid>
        <Grid container md={3} direction="row" justifyContent="end">
          <Typography color={isReadMoreShown ? "" : "grey"} variant="bodySmall">
            {date && moment(date).format("YYYY-MM-DD")}
          </Typography>
        </Grid>
      </ListItem>
    </Grid>
  )
}

export default ReadMoreReadLess
