import { Grid, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { reasonOptions } from "../helpers/violationHelper"
import { ISelectListItem, IViewViolationProps } from "../violation.interface"

export const ViewViolation: React.FC<IViewViolationProps> = (props) => {
  const { values, drivingTypes, isUpdated } = props
  const { t } = useTranslation("translation", { keyPrefix: "violation" })

  return (
    <PaperBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12} marginBottom={2}>
            <Typography variant="h3">
              {t(`info_from_driver`, ``)}
              {!isUpdated && t(`not_updated`, ``)}
            </Typography>
          </Grid>

          <Grid item xs={12} marginBottom={2}>
            <Typography variant="labelMedium">{t(`updated_by_driver`, ``)}</Typography>
            <Typography variant="h5">
              {values?.updatedByDriver
                ? moment.utc(values?.updatedByDriver).local().format("YYYY-MM-DD HH:mm")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <Typography variant="labelMedium"> {t(`driving_type`, ``)} </Typography>
            <Typography variant="h5">
              {drivingTypes?.find((x: ISelectListItem) => x.id === values.drivingTypeId)?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <Typography variant="labelMedium">{t(`reason`, ``)}</Typography>
            <Typography variant="h5">
              {reasonOptions?.find((x: any) => x.name === values.reason)?.name}
            </Typography>
          </Grid>

          <Grid item xs={12} marginBottom={2}>
            <Typography variant="labelMedium">{t(`other`, ``)}</Typography>
            <Typography variant="h5">{values.explaination}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </PaperBox>
  )
}
