import { Box, Pagination } from "@mui/material"
import React from "react"
import PaginationItem from "@mui/material/PaginationItem"
import theme from "css/theme/light.js"
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded"
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded"
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded"
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded"

const PaginationForPages = ({ count, page, onChange, size }) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <Pagination
        showFirstButton
        showLastButton
        count={count}
        size={size}
        page={page}
        onChange={onChange}
        renderItem={(item) => (
          <PaginationItem
            sx={{ margin: "0px", color: theme.palette.grey["800"] }}
            components={{
              previous: KeyboardArrowLeftRoundedIcon,
              next: KeyboardArrowRightRoundedIcon,
              first: KeyboardDoubleArrowLeftRoundedIcon,
              last: KeyboardDoubleArrowRightRoundedIcon
            }}
            {...item}
          />
        )}
      />
    </Box>
  )
}

export default PaginationForPages
