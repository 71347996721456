import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { allRoutes } from "generated/routes"
import React from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { IExamSummaryCard } from "../interface/admin.interface"

const ExamOverviewCard = (props: IExamSummaryCard) => {
  const { id, examinationName, icon, subtitle1, subtitle2, subtitle3, attendeesCount, passedCount, expiresIn30Days, expiredCount } =
    props

  const { t } = useTranslation("translation", { keyPrefix: "learning.admin.overview" })

  return (
    <PaperBox {...props}>
      <Grid
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        item
        sx={{
          flexWrap: { xs: "wrap", md: "nowrap" },

          maxWidth: "100%"
        }}>
        <Grid container display="flex" flexWrap="wrap">
          <FontAwesomeIcon
            size="lg"
            color={theme.palette.primary["main"]}
            icon={icon}
            style={{ marginTop: "2px" }}
          />
          <Grid item>
            <Typography color={"primary"} variant="h2" fontWeight="400" mb={1}>
              {t(examinationName)}
            </Typography>
            <Typography>{attendeesCount}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "row", md: "column", lg: "row" },
          justifyContent: { xs: "space-between" },
          gap: { md: "16px" }
        }}
        gap={8}
        my={4}>
        <Grid item>
          <Typography variant="bodyMedium">{subtitle1}</Typography>
          {passedCount !== null ? (
            <Typography fontWeight={600} variant="bodyCompactSmall">
              {passedCount}
            </Typography>
          ) : (
            <Typography variant="bodySmall" color="textSecondary">
              <Skeleton />
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Typography variant="bodyMedium">{subtitle2}</Typography>
          {expiresIn30Days !== null ? (
            <Typography fontWeight={600} variant="bodyCompactSmall">
              {expiresIn30Days}
            </Typography>
          ) : (
            <Typography variant="bodySmall" color="textSecondary">
              <Skeleton />
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Typography variant="bodyMedium">{subtitle3}</Typography>
          {expiredCount !== null ? (
            <Typography fontWeight={600} variant="bodyCompactSmall">
              {expiredCount}
            </Typography>
          ) : (
            <Typography variant="bodySmall" color="textSecondary">
              <Skeleton />
            </Typography>
          )}
        </Grid>
      </Box>
      <Grid
        item
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}>
        <Grid item>
          {/* <Button

                        variant="outlined"
                        sx={{ backgroundColor: "#fff" }}
                        color="primary">
                        {t(`buttons.send_reminder`)}
                    </Button> */}
        </Grid>
        <Grid item>
          <Button
            component={NavLink}
            to={allRoutes.LearningAdminExamsDetails(id)}
            variant="contained"
            color="primary">
            {t(`buttons.administer`)}
          </Button>
        </Grid>
      </Grid>
    </PaperBox>
  )
}

export default ExamOverviewCard
