import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Box, FormControlLabel, Grid, Switch } from "@mui/material"
import Typography from "@mui/material/Typography"
import classnames from "classnames"
import PaperBox from "components/PaperBox"
import globalUseStyles from "css/theme/globalUseStyles.js"
import React from "react"

const VtrSwitch = (props) => {
  const { title, message, icon, img, value, handleChange, id } = props
  const globalClasses = globalUseStyles()

  return (
    <PaperBox marginBottom={2}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        {img || icon ? (
          <Grid item xs={2}>
            <Box>
              <Avatar
                aria-label={title}
                className={classnames(globalClasses.avatar, globalClasses.backgroundPrimary)}>
                {img && <img height="18px" width="18px" src={img} alt={title} />}
                {icon && icon && <FontAwesomeIcon icon={icon} size="lg" />}
              </Avatar>
            </Box>
          </Grid>
        ) : null}

        <Grid item xs={8}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="bodySmall">{message}</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            className={globalClasses.margin0}
            control={<Switch checked={value} onChange={handleChange} id={id} />}
          />
        </Grid>
      </Grid>
    </PaperBox>
  )
}

export default VtrSwitch
