import { useMsal } from "@azure/msal-react"
import { Box, Button, Container, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"

export const SignedOutPage = () => {
  const { instance } = useMsal()

  const initializeSignIn = () => {
    instance.loginRedirect()
  }

  return (
    <Container sx={{ pb: "24px" }}>
      <Box display="flex" justifyContent="space-between">
        <PaperBox>
          <Typography variant="h2" gutterBottom>
            Du är utloggad
          </Typography>
          <Typography gutterBottom>Logga in igen för att få åtkomst till Transportportalen</Typography>
          <Button variant="contained" color="primary" onClick={() => initializeSignIn()}>
            Logga in
          </Button>
        </PaperBox>
      </Box>
    </Container>
  )
}
