import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  boxShadow: {
    boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 4%)"
  },
  checkbox: {
    padding: 0
  },
  issueReportsList: {
    padding: "0",
    marginTop: "0",
    borderRadius: "8px",
    boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 4%)"
  },
  strongBlueText: {
    color: theme.palette.primary["main"],
    marginRight: 10
  },
  strongGreenText: {
    color: theme.palette.success["main"],
    marginRight: 10
  },
  strongRedText: {
    color: theme.palette.error["main"],
    marginRight: 10
  },
  whiteLabel: {
    background: "white"
  },
  externalBlueEmailBox: {
    backgroundColor: theme.palette.primary["light"],
    borderRadius: 10
  },
  externalGreenEmailBox: {
    backgroundColor: theme.palette.success["light"],
    borderRadius: 10
  },
  externalRedEmailBox: {
    backgroundColor: theme.palette.error["light"],
    borderRadius: 10
  },
  dateText: {
    margin: "3px 14px 16px 14px",
    color: "#E45C41",
    fontSize: "0.75rem"
  }
}))
