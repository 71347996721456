import { Box, Checkbox, Divider, FormControlLabel, List, ListItem, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import styles from "./style"
function makeStateFromData(companies, initCompanies, initDepartments, enabled) {
  const companiesWithSelection = []

  if (companies) {
    companies.forEach((c) => {
      const departmentsWithSelection = []
      c.departments.forEach((d) => {
        departmentsWithSelection.push({
          id: d.id,
          name: d.name,
          selected: enabled !== undefined ? enabled : initDepartments?.indexOf(d.id) >= 0
        })
      })
      companiesWithSelection.push({
        id: c.id,
        name: c.name,
        selected: enabled !== undefined ? enabled : initCompanies?.indexOf(c.id) >= 0,
        departments: departmentsWithSelection
      })
    })
  }

  return companiesWithSelection
}
const CompanyDepartmentSelection = ({ companies, initCompanies, initDepartments, onChange, disabled }) => {
  const classes = styles()
  const [state, setState] = useState([])
  const [enabled, setEnabled] = useState(
    companies.every(
      (x) =>
        initCompanies.some((y) => y === x.id) &&
        x.departments.every((d) => initDepartments.some((di) => di === d.id))
    )
  )

  useEffect(() => {
    const state = makeStateFromData(companies, initCompanies, initDepartments)
    setState(state)
  }, [companies, initCompanies, initDepartments])
  useEffect(() => {
    const allActive = state.every((x) => x.selected && x.departments.every((d) => d.selected))
    setEnabled(allActive)
  }, [state])
  return (
    <>
      {" "}
      <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.flexColumn}>
        <Typography variant="h5"> {t(`company_and_departments`, `Bolag och avdelningar`)}</Typography>
        <FormControlLabel
          label={t(`Välj alla`)}
          control={
            <Checkbox
              onChange={(e) => {
                const state = makeStateFromData(companies, initCompanies, initDepartments, !enabled)
                setState(state)
                setEnabled(e.target.checked)

                const checked = e.target.checked
                const newCompanies = []

                state.forEach((c) => {
                  const newDepartments = []
                  c.departments.forEach((d) => {
                    newDepartments.push({
                      ...d,
                      selected: checked
                    })
                  })

                  if (c.id === state.id) {
                    newCompanies.push({
                      ...c,
                      selected: checked,
                      departments: newDepartments
                    })
                  } else {
                    newCompanies.push({
                      ...c
                    })
                  }
                })
                setState(newCompanies)
                onChange(newCompanies)
              }}
              id={`company`}
              checked={enabled}
            />
          }
        />
      </Box>
      <List>
        {state.map((company) => {
          return (
            <React.Fragment key={company.hashKey}>
              <ListItem className={classes.listItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      onChange={(e) => {
                        const checked = e.target.checked
                        const newCompanies = []

                        state.forEach((c) => {
                          const newDepartments = []
                          c.departments.forEach((d) => {
                            newDepartments.push({
                              ...d,
                              selected: checked
                            })
                          })

                          if (c.id === company.id) {
                            newCompanies.push({
                              ...c,
                              selected: checked,
                              departments: newDepartments
                            })
                          } else {
                            newCompanies.push({
                              ...c
                            })
                          }
                        })
                        setState(newCompanies)
                        onChange(newCompanies)
                      }}
                      id="company"
                      checked={company.selected}
                    />
                  }
                  label={company.name}
                />
              </ListItem>
              {company.departments.length > 0 && (
                <ListItem className={classes.subItem}>
                  <Typography variant="caption" style={{ color: grey[600] }}>
                    Avdelningar
                  </Typography>
                </ListItem>
              )}
              {company.departments.map((department) => {
                return (
                  <ListItem key={department.id} className={classes.subItem}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disabled}
                          onChange={(e) => {
                            const checked = e.target.checked

                            const newCompanies = []

                            state.forEach((c) => {
                              const newDepartments = []
                              let departmentFound = false
                              c.departments.forEach((d) => {
                                if (d.id === department.id) {
                                  departmentFound = true
                                  newDepartments.push({
                                    ...d,
                                    selected: checked
                                  })
                                } else {
                                  newDepartments.push({
                                    ...d
                                  })
                                }
                              })
                              if (departmentFound) {
                                newCompanies.push({
                                  ...c,
                                  selected: checked ? checked : c.selected,
                                  departments: newDepartments
                                })
                              } else {
                                newCompanies.push({
                                  ...c
                                })
                              }
                            })

                            setState(newCompanies)
                            onChange(newCompanies)
                          }}
                          id="department"
                          checked={department.selected}
                        />
                      }
                      label={department.name}
                    />
                  </ListItem>
                )
              })}
              <Box my={1}>
                <Divider light />
              </Box>
            </React.Fragment>
          )
        })}
      </List>
    </>
  )
}
export default CompanyDepartmentSelection
