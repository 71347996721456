import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Card, Typography } from "@mui/material"
import theme from "css/theme/light"
import React from "react"
import { useHistory } from "react-router-dom"
import useStyles from "../../../../pages/deviation/pages/components/useStyles"
import { IFormCardButtonProps } from "./formcardbutton.interface"
// library.add(fas)

const FormCardButton: React.FC<IFormCardButtonProps> = (props) => {
  const { size, title, description, iconColor, icon, id, option } = props
  const classes = useStyles()
  const history = useHistory()

  const getColorClass = (iconColor: string | undefined) => {
    switch (iconColor) {
      case "primary":
        return theme.palette.primary["main"]
      case "success":
        return theme.palette.success["main"]
      case "warning":
        return theme.palette.warning["main"]
      case "error":
        return theme.palette.error["main"]
      default:
        return theme.palette.primary["main"]
    }
  }

  const getHoverColor = (iconColor: string | undefined) => {
    switch (iconColor) {
      case "primary":
        return theme.palette.primary["light"]
      case "success":
        return theme.palette.success["light"]
      case "warning":
        return theme.palette.warning["light"]
      case "error":
        return theme.palette.error["light"]
      default:
        return theme.palette.primary["light"]
    }
  }

  return (
    <Card className={classes.actionCard} onClick={() => history.push(`?id=${id?.toString()}`)}>
      <Box
        sx={{
          "&:hover": {
            backgroundColor: getHoverColor(iconColor)
          }
        }}
        className={classes.actionCardContent}>
        <Box mb={2} sx={{ color: getColorClass(iconColor) }}>
          {icon && <FontAwesomeIcon size={size ? (size as SizeProp) : "xl"} icon={icon} />}
        </Box>
        <Typography mb={1} variant="h4" color="textPrimary">
          {title}
        </Typography>
        <Typography color="text.secondary">{description}</Typography>
      </Box>
    </Card>
  )
}

export default FormCardButton
