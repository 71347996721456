import { IconProp } from "@fortawesome/fontawesome-svg-core"

export interface InspectionCardProps {
  status: number
  icon: IconProp
  title: string
  count: string
  quantityType: string
}

export enum VehicleStatus {
  DrivingBan = "Körförbud",
  Deregistered = "Avställd",
  Delayed = "Försenad"
}

export enum InspectionHistoryStatus {
  Passed = "Passed",
  Failed = "Failed",
  Waiting = "Waiting"
}

export interface FontAwesomeProps {
  status: InspectionHistoryStatus
}

export interface VehicleCheckHistoryDto {
  status: string
  vehicleId?: number
  regNumber?: string
  internNumber?: string
  customFieldName: string
  attendanceDate?: string
  attendanceKm?: number
  odometerReading?: number
  carWorkshop?: CarWorkshopDto
  date: string
  remark?: string
  location?: string
}

export interface CarWorkshopDto {
  id: number
  name: string
  emailAddress: string
  isActive: boolean
  companyId: number
}