import axios from "axios"
import React, { useState } from "react"
import { msalInstance } from "../index"
import { loginRequest } from "./authConfig"
import { IApiErrorResponse, IApiFunc, IApiResult } from "./interface/api.interface"

function getMessages(error: IApiErrorResponse) {
  const { response } = error
  const { request, ...errorObject } = response // take everything but 'request'

  if (errorObject) {
    if (errorObject.status === 400) {
      if (Array.isArray(errorObject.data)) {
        return errorObject.data
      }
    } else if (errorObject.status === 401) {
      return ["Behörighet saknas för den valda operationen"]
    } else if (errorObject.status === 500) {
      if (Array.isArray(errorObject.data)) {
        return errorObject.data
      }
    }
  }

  return ["Ett okänt fel inträffade"]
}

export const useApi = () => {
  const ignoreResult = React.useRef(false)

  const [result, setResult] = useState<IApiResult>({
    status: 0, // 0 idle, 1 loading, 2 success, 3 error
    messages: [], // onlyif 3
    data: null // only valid if 2
  })

  const get = React.useCallback(async (url: string) => {
    setResult({
      status: 1,
      messages: [],
      data: null
    })

    var accessToken = ""
    try {
      const account = msalInstance.getActiveAccount()
      if (!account) {
        throw Error(
          "No active account! Verify a user has been signed in and setActiveAccount has been called."
        )
      }

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      })
      //console.log("token response", response.accessToken);
      accessToken = response.accessToken
    } catch {
      // TODO: hantera detta, den kan kasta fel "login+required"
    }

    try {
      var result = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      if (!ignoreResult.current) {
        // hit kommer vi på "ok"
        setResult({
          status: 2,
          messages: [],
          data: result.data
        })
      } else {
        //console.log("ignoring result");
      }
    } catch (e: any) {
      try {
        //console.log(e);
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: getMessages(e),
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      } catch {
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: [],
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      }
    }
  }, [])
  const getBlob = React.useCallback(async (url: string, fileName: string) => {
    setResult({
      status: 1,
      messages: [],
      data: null
    })

    var accessToken = ""
    try {
      const account = msalInstance.getActiveAccount()
      if (!account) {
        throw Error(
          "No active account! Verify a user has been signed in and setActiveAccount has been called."
        )
      }

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      })

      accessToken = response.accessToken
    } catch {
      // TODO: hantera detta, den kan kasta fel "login+required"
    }

    try {
      axios({
        url: url, //your url
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        method: "GET",
        responseType: "blob" // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName) //or any other extension
        document.body.appendChild(link)
        link.click()

        if (!ignoreResult.current) {
          // hit kommer vi på "ok"
          setResult({
            status: 2,
            messages: [],
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      })
    } catch (e: any) {
      try {
        //console.log(e);
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: getMessages(e),
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      } catch {
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: [],
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      }
    }
  }, [])

  const post = React.useCallback(async (url: string, data: any[] | any) => {
    setResult({
      status: 1,
      messages: [],
      data: null
    })

    var accessToken = ""
    try {
      const account = msalInstance.getActiveAccount()
      if (!account) {
        throw Error(
          "No active account! Verify a user has been signed in and setActiveAccount has been called."
        )
      }

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      })

      accessToken = response.accessToken
    } catch {
      // TODO: hantera detta, den kan kasta fel "login+required"
    }

    try {
      var result = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      // hit kommer vi på "ok"
      if (!ignoreResult.current) {
        setResult({
          status: 2,
          messages: [],
          data: result.data
        })
      } else {
        //console.log("ignoring result");
      }
    } catch (e: any) {
      try {
        //console.log(e);
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: getMessages(e),
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      } catch {
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: [],
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      }
    }
  }, [])

  const put = React.useCallback(async (url: string, data: any[] | any) => {
    setResult({
      status: 1,
      messages: [],
      data: null
    })

    var accessToken = ""
    try {
      const account = msalInstance.getActiveAccount()
      if (!account) {
        throw Error(
          "No active account! Verify a user has been signed in and setActiveAccount has been called."
        )
      }

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      })

      accessToken = response.accessToken
    } catch {
      // TODO: hantera detta, den kan kasta fel "login+required"
    }

    try {
      var result = await axios.put(url, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      if (!ignoreResult.current) {
        // hit kommer vi på "ok"
        setResult({
          status: 2,
          messages: [],
          data: result.data
        })
      } else {
        //console.log("ignoring result");
      }
    } catch (e: any) {
      try {
        //console.log(e);
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: getMessages(e),
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      } catch {
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: [],
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      }
    }
  }, [])

  const del = React.useCallback(async (url: string) => {
    setResult({
      status: 1,
      messages: [],
      data: null
    })

    var accessToken = ""
    try {
      const account = msalInstance.getActiveAccount()
      if (!account) {
        throw Error(
          "No active account! Verify a user has been signed in and setActiveAccount has been called."
        )
      }

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      })

      accessToken = response.accessToken
    } catch {
      // TODO: hantera detta, den kan kasta fel "login+required"
    }

    try {
      var result = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      if (!ignoreResult.current) {
        // hit kommer vi på "ok"
        setResult({
          status: 2,
          messages: [],
          data: result.data
        })
      } else {
        //console.log("ignoring result");
      }
    } catch (e: any) {
      try {
        //console.log(e);
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: getMessages(e),
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      } catch {
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: [],
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      }
    }
  }, [])

  const file = React.useCallback(async (url: string, data: any[] | any) => {
    setResult({
      status: 1,
      messages: [],
      data: null
    })

    var accessToken = ""
    try {
      const account = msalInstance.getActiveAccount()
      if (!account) {
        throw Error(
          "No active account! Verify a user has been signed in and setActiveAccount has been called."
        )
      }

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      })

      accessToken = response.accessToken
    } catch {
      // TODO: hantera detta, den kan kasta fel "login+required"
    }

    try {
      const formData = new FormData()

      if (data.length > 0) {
        data.forEach((file: File) => {
          formData.append(`files`, file)
        })
      } else {
        formData.append("file", data)
      }

      var result = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data"
        }
      })
      if (!ignoreResult.current) {
        // hit kommer vi på "ok"
        setResult({
          status: 2,
          messages: [],
          data: result.data
        })
      } else {
        //console.log("ignoring result");
      }
    } catch (e: any) {
      try {
        //console.log(e);
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: getMessages(e),
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      } catch {
        if (!ignoreResult.current) {
          setResult({
            status: 3,
            messages: [],
            data: null
          })
        } else {
          //console.log("ignoring result");
        }
      }
    }
  }, [])

  const leave = React.useCallback(() => {
    //console.log("leave");
    ignoreResult.current = true
  }, [])

  const reset = React.useCallback(() => {
    //console.log("resetting request");
    ignoreResult.current = false
    setResult({
      status: 0,
      messages: [],
      data: null
    })
  }, [setResult])

  const apiFuncs = React.useMemo((): IApiFunc => {
    return {
      get,
      getBlob,
      post,
      put,
      del,
      file,
      leave,
      reset
    }
  }, [get, post, put, del, file, leave, reset, getBlob])

  return [result, apiFuncs]
}
