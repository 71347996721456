import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"

export function RouterPrompt(props) {
  const formik = useFormikContext()
  const history = useHistory()

  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState("")

  const unblockRef = useRef()

  function handleShowModal() {
    setShowPrompt(true)
  }

  function handleCancel() {
    setShowPrompt(false)
  }

  useEffect(() => {
    if (formik.dirty && formik.submitCount === 0) {
      unblockRef.current = history.block((location) => {
        setCurrentPath(location.pathname)
        handleShowModal()
        return false
      })
    }
    return () => {
      unblockRef.current && unblockRef.current()
    }
  }, [formik, history])

  function handleOK() {
    if (unblockRef) {
      unblockRef.current()
    }
    setShowPrompt(false)
    history.push(currentPath)
  }

  return showPrompt ? (
    <Dialog
      open={showPrompt}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs">
      <Grid container direction="column" alignItems="center">
        <DialogContent my={2}>
          <Typography variant="h4" paragraph>
            Du har osparade ändringar.
          </Typography>
          <Typography variant="bodySmall" paragraph>
            Är du säker på att du vill lämna sidan?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Stanna kvar
          </Button>
          <Button onClick={handleOK} color="primary" variant="contained">
            Lämna sida
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  ) : null
}
