import { Typography, Box } from '@mui/material'
import { CircleIcon } from 'components/icons/CircleIcon'
import React from 'react'
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export interface CContent {
  icon: FontAwesomeIconProps["icon"];
  size: FontAwesomeIconProps["size"];
  color: string;
  message: string;
}

const NoResultFound = (props: CContent) => {
  const { icon, size, color, message } = props;
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" paddingY={5}>
      <CircleIcon color={color} icon={icon} size={size} />
      <Typography align="center" variant="h4" marginTop={3}>
        {message}
      </Typography>
    </Box>
  )
}

export default NoResultFound