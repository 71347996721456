import { Box, Container, Grid, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allEquipmentStatuses } from "generated/equipmentStatuses"
import { allRoutes } from "generated/routes"
import moment from "moment"
import React, { useContext, useEffect, useMemo } from "react"
import Moment from "react-moment"
import { Link, useHistory } from "react-router-dom"

const equipmentStatusName = (status) => {
  switch (status) {
    case allEquipmentStatuses.OK:
      return "Fungerar"
    case allEquipmentStatuses.Broken:
      return "Trasig"
    case allEquipmentStatuses.AtService:
      return "På service"
    default:
      return ""
  }
}

const Equipment = () => {
  const globalClasses = globalUseStyles()
  const routerHistory = useHistory()

  const [equipmentResult, getEquipments] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    getEquipments(apiEndpoints.systemadmindeviceregistry.listequipments)
  }, [getEquipments])

  useEffect(() => {
    loadingContext.setLoading(() => equipmentResult.status === 1)
  })

  const columns = useMemo(
    () => [
      {
        accessor: "equipmentId",
        numeric: true,
        Header: "ID"
      },
      {
        accessor: "equipmentType",
        Header: "Utrustning"
      },
      {
        accessor: "equipmentDescription",
        Header: "Beskrivning"
      },
      {
        accessor: "mountedOn",
        Header: "Koppling/Lager"
      },
      {
        accessor: "companyName",
        Header: "Bolag"
      },
      {
        accessor: "subscriptionTime",
        numeric: false,
        Header: "Bindningstid",
        Cell: ({ cell }) => {
          return (
            <Moment format="YYYY-MM-DD" globallocale="sv">
              {cell.row.original.subscriptionTime}
            </Moment>
          )
        }
      },
      {
        accessor: (row) => {
          return row.created ? moment(row.created).format("YYYY-MM-DD HH:mm") : ""
        },
        numeric: false,
        Header: "Skapad"
      },
      {
        accessor: (row) => equipmentStatusName(row.status),
        numeric: false,
        Header: "Status",
        Filter: SelectColumnFilter,
        FilterHeader: "Visa alla",
        filter: "equals",
        FilterOptions: [
          {
            name: "Fungerar",
            value: allEquipmentStatuses.OK
          },
          {
            name: "Trasig",
            value: allEquipmentStatuses.Broken
          },
          {
            name: "På service",
            value: allEquipmentStatuses.AtService
          }
        ],
        mainFilter: true
      }
    ],
    []
  )

  return (
    <div>
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Utrustning</Typography>
            <Button
              component={Link}
              to={allRoutes.SystemadminDevicesEquipmentAdd()}
              color="primary"
              variant="contained">
              Lägg till utrustning
            </Button>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rowProps={(row) => ({
            onClick: () => routerHistory.push(allRoutes.SystemadminDevicesEquipmentEdit(row.original.id))
          })}
          rows={equipmentResult.status === 2 && equipmentResult.data}
          status={equipmentResult.status}
        />
      </Box>
    </div>
  )
}

export default Equipment
