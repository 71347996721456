import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    listHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "1em",
        fontSize: "1.4285714286em",

        "& svg": {
            fill: "#254467"
        }
    },

    filterWrap: {
        display: "flex",
        padding: "0 1rem",
        flexDirection: "column",

        "& > div": {
            display: "flex",
            justifyContent: "space-evenly"
        },

        "& button": {
            borderRadius: ".625rem",
            fontSize: "0.75rem",
            padding: "0.554rem 0",
            width: "100%",
            fontWeight: 500,
            boxShadow: "none",
            backgroundColor: "#F9F9F9",
            color: "black",

            "&:hover": {
                boxShadow: "none",
                backgroundColor: "#D9EEF4"
            },

            "&.primary": {
                backgroundColor: "#D9EEF4",
                color: "#254467"
            }
        }
    },

    searchField: {
        marginTop: ".75rem",
        marginBottom: "1.5rem",
        backgroundColor: "#F9F9F9",
        padding: ".8125rem 1.5rem",
        border: "none",
        outline: "none",
        borderRadius: ".625rem",
        lineHeight: 2.1,
        fontSize: "0.875rem",
        color: "#ABABAB",

        "&:placeholder": {
            color: "#ABABAB"
        }
    }
}));
