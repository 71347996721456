import React from "react"

import { Grid, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import { CircleIcon } from "components/icons/CircleIcon"

export const AttendeeCountCard = (props) => {
  const { icon, subtitle, data, color } = props
  return (
    <Grid item xs={12} md={6} lg={3}>
      <PaperBox>
        <ListItem disableGutters>
          <ListItemAvatar>
            <CircleIcon icon={icon} color={`${color}`} size="lg" sx={{ marginRight: "15px" }} />
          </ListItemAvatar>
          <ListItemText>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h4">{data}</Typography>
                <Typography variant="bodySmall" color="textSecondary">
                  {subtitle}
                </Typography>
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>
      </PaperBox>
    </Grid>
  )
}
