import { Box, Grid, List, ListItem, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import Button from "components/Button"

const DepartmentsList = ({ departments, removeDepartment }) => {
  return (
    <List>
      {departments.map((item, key) => {
        return (
          <ListItem key={key} divider disableGutters>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="bodySmall"> {item.name}</Typography>

                <Typography variant="caption" style={{ color: grey[600] }}>
                  {" "}
                  {item.description}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      removeDepartment(item)
                    }}
                    variant="link">
                    <Typography variant="caption">Ta bort</Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </ListItem>
        )
      })}
    </List>
  )
}
export default DepartmentsList
