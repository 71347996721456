import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import Button from "components/Button"
import { parse } from "date-fns"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import moment, { isDate } from "moment"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import PeoplePickerContainer from "./PeoplePicker"

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, "yyyy-MM-dd", new Date())

  return parsedDate
}

export const AddUserToExamDialogForm = (props) => {
  const {
    customerExaminationId,
    onCancel,
    onSelect,
    showModalOnClick,
    handleAddParticipants,
    selectedItem,
    setClickedItem,
    clickedItem,
    result,
    setShowAddUserDialog
  } = props

  const { t } = useTranslation("translation", { keyPrefix: "learning" })

  const formRef = useRef()

  const initialValues = {
    LastDateForPassedExam: null,
    LastPassedExam: null,
    userId: null,
    customerExaminationId: customerExaminationId
  }

  const addParticipantValidationSchema = Yup.object().shape(
    {
      userId: Yup.number().nullable().required(t(`admin.settings.validation.select_user`)),
      LastDateForPassedExam: Yup.string()
        .nullable()
        .when("LastPassedExam", {
          is: (value) => value === null,
          then: (schema) => schema.required(t(`admin.settings.validation.select_date`))
        }),
      LastPassedExam: Yup.string()
        .nullable()
        .when("LastDateForPassedExam", {
          is: (value) => value === null,
          then: (schema) => schema.required(t(`admin.settings.validation.select_date`))
        })
    },
    [["LastDateForPassedExam", "LastPassedExam"]]
  )

  return (
    <>
      <Dialog
        open={showModalOnClick}
        onClose={() => {
          setShowAddUserDialog(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth>
        <DialogTitle sx={{ p: "24px" }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h2">{t(`admin.participants.add_participants`)}</Typography>
            <IconButton aria-label="close" onClick={() => setShowAddUserDialog(false)} size="medium">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Formik
            innerRef={formRef}
            validationSchema={addParticipantValidationSchema}
            initialValues={initialValues}
            onSubmit={handleAddParticipants}
            enableReinitialize={true}>
            {({ handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue }) => (
              <form id="addParticipants" onSubmit={handleSubmit} noValidate>
                <PeoplePickerContainer
                  clickedItem={clickedItem}
                  formRef={formRef}
                  values={values}
                  errors={formRef?.current?.errors.userId}
                  touched={formRef?.current?.touched.userId}
                  hideSelectedValue
                  inititalSelectedValue={selectedItem}
                  onChange={(item) => {
                    setClickedItem(item)
                    if (item !== null) {
                      formRef?.current.setFieldValue("userId", item.id)
                    }
                    onSelect(item)
                  }}
                  onClose={() => {
                    onCancel()
                  }}
                  searchUrl={apiEndpoints.learningadmin.getuserstoadd.replace(
                    "{id}",
                    values.customerExaminationId
                  )}></PeoplePickerContainer>
                <Grid container spacing={2}>
                  <Grid item>
                    <Box my={1} pt={2}>
                      <InputLabel>
                        <Typography variant="labelMedium" fontWeight={600} color="textPrimary">
                          {t(`admin.participants.last_date`)}
                        </Typography>
                      </InputLabel>
                    </Box>
                    <FormControl fullWidth>
                      <DatePicker
                        value={values.LastDateForPassedExam ? moment(values.LastDateForPassedExam) : null}
                        inputFormat="YYYY-MM-DD"
                        onChange={(value) => {
                          setFieldValue(
                            "LastDateForPassedExam",
                            moment(value).isValid() ? moment(value).format("YYYY-MM-DD") : null
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            id="LastDateForPassedExam"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.LastPassedExam}
                            error={
                              touched.LastDateForPassedExam && errors.LastDateForPassedExam ? true : false
                            }
                            helperText={
                              touched.LastDateForPassedExam && errors.LastDateForPassedExam
                                ? errors.LastDateForPassedExam
                                : null
                            }
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </FormControl>

                    <Typography mt={1} variant="bodySmall">
                      {t(`admin.participants.not_earlier_approved`)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box my={1}>
                      <InputLabel>
                        <Typography variant="labelMedium" fontWeight={600} color="textPrimary">
                          {t(`admin.participants.last_approved_date`)}
                        </Typography>
                      </InputLabel>
                    </Box>
                    <FormControl fullWidth>
                      <DatePicker
                        value={values.LastPassedExam ? moment(values.LastPassedExam) : null}
                        inputFormat="YYYY-MM-DD"
                        onChange={(value) => {
                          setFieldValue(
                            "LastPassedExam",
                            moment(value).isValid() ? moment(value).format("YYYY-MM-DD") : null
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            id="LastPassedExam"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.LastPassedExam}
                            error={touched.LastPassedExam && errors.LastPassedExam ? true : false}
                            helperText={
                              touched.LastPassedExam && errors.LastPassedExam ? errors.LastPassedExam : null
                            }
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </FormControl>
                    <Typography mt={1} variant="bodySmall">
                      {t(`admin.participants.earlier_approved`)}
                    </Typography>
                  </Grid>
                  <Grid item mt={1}>
                    <Button color="primary" type="button" result={result} onClick={handleSubmit}>
                      {t(`admin.participants.button.add_participants`)}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}
