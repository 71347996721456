import { faLoader } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box, Drawer, IconButton, Tab, Tabs, Typography } from "@mui/material"
import { useTheme } from "@mui/styles"
import classNames from "classnames"
import { AppContext } from "components/AppContextProvider"
import { allRoutes } from "generated/routes"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, NavLink } from "react-router-dom"
import MenuIcon from "./MenuIcon"
import styles from "./style"

const SideDrawer = (props) => {
  const { t } = useTranslation()

  const menuTitles = t("app.menu.titles", { returnObjects: true })
  const { openDrawer, handleDrawerChange, matches } = props
  const appContext = useContext(AppContext)
  const classes = styles(props)
  const theme = useTheme()
  const [selectedPath, setPath] = useState("/")

  const handleChange = (_event, newValue) => {
    setPath(newValue)
    if (!matches) {
      handleDrawerChange(false)
    }
  }
  const drawer = (
    <>
      <Link to={allRoutes.Dashboard()}>
        <div className={classes.mainLogo}></div>
      </Link>

      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={selectedPath}
        className={classes.tabsVertical}
        onChange={handleChange}
        aria-label="Huvudmeny"
        indicatorColor="primary"
        classes={{ indicator: classes.bigIndicatorVertical }}>
        {appContext.isAuth &&
          appContext.appUser.app.normalPages
            .filter((x) => !x.unauthenticatedTemplate)
            .map((item) => {
              const translatedTitles = menuTitles.find((title) => title.menuId === item.menuId)
              return (
                <Tab
                  icon={<MenuIcon name={item.icon} />}
                  key={item.title}
                  component={NavLink}
                  to={item.route.path}
                  label={
                    (!matches && item.title) ||
                    (!openDrawer
                      ? ""
                      : openDrawer && !translatedTitles
                      ? item.title
                      : translatedTitles?.title)
                  }
                  value={item.route.matchPath}
                  isActive={(match, location) => {
                    if (
                      location.pathname.startsWith(item.route.matchPath) ||
                      (location.pathname === "/" && item.route.matchPath === "/dashboard")
                    ) {
                      setPath(item.route.matchPath)
                    }
                  }}
                />
              )
            })}
        {appContext.loadingAppstructure && (
          <Tab icon={<FontAwesomeIcon spin icon={faLoader} />} label={`Laddar`} />
        )}

        {appContext.isAuth && openDrawer && appContext.appUser.app.sysadmPages?.length > 0 && (
          <Typography variant="caption" className={classes.tabsLabel}>
            {t("settings.form.systemadmin")}
          </Typography>
        )}
        {appContext.isAuth &&
          appContext.appUser.app.sysadmPages.map((item) => {
            const translatedTitles = menuTitles.find((title) => title.menuId === item.menuId)
            return (
              <Tab
                icon={<MenuIcon name={item.icon} />}
                key={item.title}
                component={NavLink}
                to={item.route.path}
                label={
                  (!matches && item.title) ||
                  (!openDrawer ? "" : openDrawer && !translatedTitles ? item.title : translatedTitles?.title)
                }
                value={item.route.path}
                isActive={(match, location) => {
                  if (location.pathname.startsWith(item.route.matchPath)) {
                    setPath(item.route.matchPath)
                  }
                }}
              />
            )
          })}
      </Tabs>

      <Box textAlign="right">
        <IconButton className={classes.drawerArrowIcon} onClick={() => handleDrawerChange()} size="large">
          {!openDrawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <div className={classes.toolbar}>
          <img
            src={appContext.appUser.app.logoUrl ? appContext.appUser.app.logoUrl : theme.logo.src}
            alt={theme.logo.alt}
          />
        </div>
      </Box>
    </>
  )
  return (
    <nav>
      <Drawer
        variant={matches ? "permanent" : "temporary"}
        open={openDrawer}
        onClose={handleDrawerChange}
        className={classes.drawer}
        classes={{
          paper: classNames(classes.background)
        }}
        ModalProps={{
          keepMounted: true
        }}>
        {drawer}
      </Drawer>
    </nav>
  )
}

export default SideDrawer
