import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3)
  },
  content: {
    [theme.breakpoints.up("md")]: {
      marginBottom: 50
    }
  },
  stepper: {
    background: "transparent",
    marginBottom: 13,
    "& .MuiStep-vertical": {
      zIndex: 1
    },
    "& .MuiStepConnector-vertical": {
      borderLeftWidth: "4px",
      marginLeft: "11px",
      marginTop: "-2px",
      "& .MuiStepConnector-line": {
        minHeight: "34px"
      },
      "&.Mui-active .MuiStepConnector-line": {
        borderColor: theme.palette.primary["main"]
      },
      "&.Mui-completed .MuiStepConnector-line": {
        borderColor: theme.palette.success["main"]
      }
    }
  },
  progress: {
    bottom: "0",
    position: "fixed",
    width: "100%"
  },
  areaContent: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "56.25%",
    marginBottom: 25
  },
  reponsiveIframe: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    borderRadius: "10px"
  },
  stepIconComing: {
    color: theme.palette.primary["light"]
  },
  stepIconActive: {
    color: theme.palette.primary["main"],
    backgroundColor: theme.palette.primary["light"],
    borderRadius: 25,
    height: 14,
    width: 14,
    padding: 6
  },
  stepIconCompleted: {
    color: theme.palette.success["main"],
    backgroundColor: theme.palette.success["light"],
    borderRadius: 25,
    height: 14,
    width: 14,
    padding: 6
  },
  stepIconWarning: {
    color: theme.palette.warning["main"],
    backgroundColor: theme.palette.warning["light"],
    borderRadius: 25,
    height: 14,
    width: 14,
    padding: 6
  },
  stepLabel: {
    marginTop: "-2px",
    fontSize: "2rem",
    cursor: "pointer",
    fontWeight: 500,
    padding: 0,
    "& .MuiStepLabel-label": {
      fontSize: "1rem",
      fontWeight: 500
    }
  },
  stepTitle: {
    fontSize: 16,
    fontWeight: 500
  },
  stepText: {
    fontSize: 12,
    fontWeight: 400
  },
  mobileStepper: {
    maxWidth: "calc(100% - 11px)",
    flexGrow: 1,
    zIndex: 2,
    bottom: "0",
    position: "fixed",
    width: "100%",
    background: theme.palette.background.paper,
    boxShadow: "0px 0px 65px 0px rgb(0 0 0 / 6%)",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "calc(100% - 240px)"
    },
    [theme.breakpoints.down("xl")]: {
      "& .MuiLinearProgress-root": {
        display: "none"
      }
    }
  },
  redBox: {
    background: theme.palette.error["light"],
    color: theme.palette.error["main"],
    margin: 0
  },
  redText: {
    color: theme.palette.error["main"],
    margin: 0
  },
  greenBox: {
    background: theme.palette.success["light"],
    color: theme.palette.success["main"],
    margin: 0
  },
  greenText: {
    color: theme.palette.success["main"],
    margin: 0
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  box: {
    padding: "30px 20px",
    maxWidth: "311px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gridGap: "20px",
    "& :first-child": {
      fontSize: "24px"
    },
    "& :nth-child(2)": {
      color: "#000000",
      lineHeight: "19.5px"
    },
    "& :nth-child(3)": {
      maxWidth: "133px"
    }
  },
  sendButton: {
    backgroundColor: "#6BCA4A",
    paddingLeft: "5px"
  },
  icon: {
    backgroundColor: theme.palette.secondary["main"],
    borderRadius: "25px",
    margin: 0,
    padding: "5px",
    width: "32px",
    height: "auto"
  }
}))
