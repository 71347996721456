import { faCheck, faDownload, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import classNames from "classnames"
import PaperBox from "components/PaperBox"
import { UserNameAccronym } from "components/UserNameAccronym"
import { CircleIcon } from "components/icons/CircleIcon"
import theme from "css/theme/light"
import React from "react"
import Moment from "react-moment"
import { CertificateDownloadLink } from "shared/learning/components/CertificateRender"
import { useStyles } from "../useStyles"

export const Summary = (props) => {
  const { item } = props
  const classes = useStyles()

  return (
    <React.Fragment>
      <PaperBox paddingBottom={1} marginTop={0}>
        <Grid container direction={"column"} justifyContent="center" alignItems="center">
          <Box mb={2}>
            <Typography variant="caption" className={classes.textGrey} gutterBottom>
              <Moment format="L">{item.dateCompleted}</Moment>
            </Typography>
          </Box>
          <Typography variant="h1" color="primary" className={classNames(classes.result)}>
            {item.correctAnswersCount}
            <span className={classes.opacity5}> antal rätt</span>
          </Typography>
          <Box my={2}>
            {item.calculatedPassed ? (
              <Grid container direction="row" justifyContent="center" alignItems="center" my={2} spacing={1}>
                <Grid item>
                  <Avatar className={classNames(classes.green, classes.center, classes.small)}>
                    <FontAwesomeIcon icon={faCheck} size={"xs"} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="caption">Godkänd</Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="row" justifyContent="center" alignItems="center" my={2} spacing={1}>
                <Grid item>
                  <CircleIcon icon={faTimes} color="error" />
                </Grid>
                <Grid item>
                  <Typography variant="caption">Ej godkänd</Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>

        {item && item.calculatedPassed && (
          <Box className={classes.downloadPaper} my={2}>
            <CertificateDownloadLink item={item}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography>Ladda ner utbildningsbevis </Typography>
                <FontAwesomeIcon icon={faDownload} />
              </Grid>
            </CertificateDownloadLink>
          </Box>
        )}
      </PaperBox>
      <PaperBox py={1} px={2}>
        <List>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar
                sx={{ background: theme.palette.primary["light"], color: theme.palette.primary["dark"] }}>
                <UserNameAccronym name={item.userFullName} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="bodyMedium">{item.userFullName}</Typography>
              <Typography variant="caption" gutterBottom>
                {item.userEmail}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </PaperBox>
    </React.Fragment>
  )
}
