import { ListItem, ListItemAvatar, ListItemText, Skeleton, Typography, Grid } from "@mui/material"
import PaperBox from "components/PaperBox"
import React from "react"

export const SkeletonResultCard = () => {

    const numberOfCards = [1, 2, 3, 4, 5]

    return (
        <>
            {numberOfCards.map((index) => (
                <Grid key={index} item md={12} xs={12}>
                    <PaperBox paddingX={3} paddingY={3} marginY={0}>
                        <ListItem disableGutters sx={{ p: 0 }}>
                            <ListItemAvatar>
                                <Skeleton variant="circular" width={40} height={40} sx={{ marginRight: 2 }} />
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography color="secondary" variant="bodyMedium">
                                    <Skeleton />
                                </Typography>
                                <Typography variant="h1">
                                    <Skeleton />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </PaperBox>
                </Grid>
            ))
            }
        </>
    )
}
