import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material"
import Button from "components/Button"
import globalUseStyles from "css/theme/globalUseStyles.js"
import React, { useEffect } from "react"

export const VehicleAlarmNotificationDelete = ({ deleteNotificationSubmit, result, id }) => {
  const globalClasses = globalUseStyles()

  const [dialogOpen, setDialogOpen] = React.useState(false)

  const deleteNotification = () => {
    deleteNotificationSubmit(id)
  }

  useEffect(() => {
    if (result.status === 2) {
      setDialogOpen(false)
    }
  }, [result.status])

  const openRemoveDialog = () => {
    setDialogOpen(true)
  }

  const closeRemoveDialog = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <Box marginTop={5} marginBottom={10} marginX={3} textAlign="center">
        <Grid container direction="column" justifyContent="center" alignItems="center" alignContent="center">
          <Grid item className={globalClasses.backgroundGrey} sx={{ padding: 3 }}>
            <Typography variant="h5">Ta bort notifikation</Typography>
            <Typography variant="bodySmall" color="textSecondary">
              Användaren kommer längre inte få notifikationer om larm för det bolaget.
            </Typography>

            <Button color="primary" variant="text" onClick={openRemoveDialog}>
              Ta bort
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={closeRemoveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Ta bort notifikation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Är du säker att du vill ta bort notifikationen? Användaren kommer längre inte få notifikationer på
            e-post.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRemoveDialog} variant="text">
            Nej, avbryt
          </Button>
          <Button
            result={result}
            onClick={() => deleteNotification(id)}
            variant="contained"
            color="primary"
            autoFocus>
            Ja, ta bort
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
