import { faChalkboardTeacher } from "@fortawesome/pro-solid-svg-icons"
import { FormControlLabel, Grid, InputLabel, Switch, TextField, Typography } from "@mui/material"
import { useApiFile } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import FilePicker from "components/FilePicker"
import { CircleIcon } from "components/icons/CircleIcon"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light.js"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export const CustomerInformation = ({ values, handleBlur, handleChange, touched, errors, setFieldValue }) => {
  const [fileUploadResult, uploadFile] = useApiFile()
  const [nameExistResult, checkIfNameExistApi] = useApi()
  const [file, setFile] = useState(null)
  const { t } = useTranslation("translation", { keyPrefix: "settings.form" })
  useEffect(() => {
    const onAddImage = async () => {
      await uploadFile(apiEndpoints.systemadmincustomers.uploadcustomerlogo, file)
    }

    if (file) {
      onAddImage()
    }
  }, [file, uploadFile])

  const onLogoChanged = (value) => {
    setFieldValue("logoPreview", value)
  }

  useEffect(() => {
    const loadStuff = async () => {
      await checkIfNameExistApi.get(
        apiEndpoints.systemadmincustomers.checkifcustomeralreadyexist.replace("{customerName}", values.name)
      )
    }
    loadStuff()
  }, [checkIfNameExistApi, values.name])

  useEffect(() => {
    if (nameExistResult.status === 2) {
      setFieldValue("existingName", nameExistResult.data)
    }
  }, [nameExistResult, setFieldValue])

  return (
    <>
      <PaperBox>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLabel>
              <Typography variant="labelMedium" fontWeight={500} color={theme.palette.foreground.default}>
                {t("name")}
              </Typography>
            </InputLabel>
            <TextField
              autoComplete="off"
              fullWidth
              id="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && errors.name ? true : false}
              helperText={touched.name && errors.name ? errors.name : null}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>
              <Typography variant="labelMedium" fontWeight={500} color={theme.palette.foreground.default}>
                {t("orgnr")}
              </Typography>
            </InputLabel>
            <TextField
              autoComplete="off"
              fullWidth
              id="organisationNumber"
              value={values.organisationNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.organisationNumber && errors.organisationNumber ? true : false}
              helperText={
                touched.organisationNumber && errors.organisationNumber ? errors.organisationNumber : null
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              <Typography variant="labelMedium" fontWeight={500} color={theme.palette.foreground.default}>
                {t("streetAddress")}
              </Typography>
            </InputLabel>
            <TextField
              autoComplete="off"
              fullWidth
              id="streetAddress"
              value={values.streetAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.streetAddress && errors.streetAddress ? true : false}
              helperText={touched.streetAddress && errors.streetAddress ? errors.streetAddress : null}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel>
              <Typography variant="labelMedium" fontWeight={500} color={theme.palette.foreground.default}>
                {t("postalCode")}
              </Typography>
            </InputLabel>
            <TextField
              autoComplete="off"
              fullWidth
              id="postalCode"
              value={values.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.postalCode && errors.postalCode ? true : false}
              helperText={touched.postalCode && errors.postalCode ? errors.postalCode : null}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <InputLabel>
              <Typography variant="labelMedium" fontWeight={500} color={theme.palette.foreground.default}>
                {t("city")}
              </Typography>
            </InputLabel>
            <TextField
              autoComplete="off"
              fullWidth
              id="cityAddress"
              value={values.cityAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.cityAddress && errors.cityAddress ? true : false}
              helperText={touched.cityAddress && errors.cityAddress ? errors.cityAddress : null}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} marginTop={3}>
          <FilePicker
            setFieldValue={setFieldValue}
            fieldName="logoName"
            defaultPreviewImage={values.logoPreview && values.logoPreview.preview}
            setFile={setFile}
            fileUploadResult={fileUploadResult}
            onLogoChanged={onLogoChanged}
            label="Logotyp"
          />
        </Grid>
      </PaperBox>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} md={8}>
          <PaperBox marginBottom={2}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <CircleIcon icon={faChalkboardTeacher} color="primary" size="lg" />
              </Grid>

              <Grid item xs={8}>
                <Typography variant="h5">{t("demo_account")}</Typography>
                <Typography variant="bodySmall">{t("demo_account_description")}</Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch checked={values.isDemoAccount} onChange={handleChange} id="isDemoAccount" />
                  }
                />
              </Grid>
            </Grid>
          </PaperBox>
        </Grid>
      </Grid>
    </>
  )
}
