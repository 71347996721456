import { Grid, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { IViolationProps } from "../violation.interface"

export const ViolationDetail: React.FC<IViolationProps> = (props) => {
  const { values } = props
  const { t } = useTranslation("translation", { keyPrefix: "violation" })

  return (
    // lägg till importtype
    <PaperBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography variant="h3" fontSize={24}>
            {values?.name ?? "Saknar kopplad användare"}
          </Typography>
          <Typography variant="labelMedium" fontSize={14}>
            {t(`location`, ``)} {values?.location}
          </Typography>
        </Grid>
        {values?.missingUser && (
          <Grid item xs={12} md={2}>
            <Typography variant="labelMedium" fontSize={14}>
              {values.importType === "Idha" ? t(`name_in_idha`, ``) : t(`name_in_tachosafe`, ``)}
            </Typography>
            <Typography variant="h5" fontSize={14}>
              {values?.idhaUsername}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={2}>
          <Typography variant="labelMedium" fontSize={12}>
            {t(`violation_date`, ``)}
          </Typography>
          <Typography variant="h5" fontSize={14}>
            {moment.utc(values?.violationDate).local().format("YYYY-MM-DD HH:mm")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="labelMedium" fontSize={12}>
            {t(`driver_card`, ``)}
          </Typography>
          <Typography variant="h5" fontSize={14}>
            {values?.driverCardNr}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="labelMedium" fontSize={12}>
            {t(`violation_degree`, ``)}
          </Typography>
          <Typography variant="h5" fontSize={14}>
            {values?.violationDegree}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="labelMedium" fontSize={12}>
            {t(`import_type`, ``)}
          </Typography>
          <Typography variant="h5" fontSize={14}>
            {values?.importType}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="labelMedium" fontSize={12}>
            {t(`description`, ``)}
          </Typography>
          <Typography variant="h5" fontSize={14} fontWeight={400}>
            {values?.violationDescription}
          </Typography>
        </Grid>
      </Grid>
    </PaperBox>
  )
}
