import { Container } from "@mui/material";
import React from "react";

import AddTemplatePage from "../../components/AddTemplatePage";
import { ReportTemplateContextProvider } from "../../components/ReportTemplateContextProvider";

const AddTemplate = () => {
    return (
        <ReportTemplateContextProvider>
            <Container>
                <AddTemplatePage />
            </Container>
        </ReportTemplateContextProvider>
    );
};
export default AddTemplate;
