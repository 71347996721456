import { faBus, faCar, faPenToSquare, faTrailer, faTruck } from "@fortawesome/pro-solid-svg-icons"
import { Box, Button, Grid, Typography, useTheme } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import { CircleIcon } from "components/icons/CircleIcon"
import { allRoles } from "generated/roles"
import { useContext } from "react"
import { TextCell } from "./components/TextCell"
import { styled } from "@mui/material/styles"
import PaperBox from "components/PaperBox"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const vehicleIcon = (variant: string) => {
  switch (variant) {
    case "Lastbil" || "LB":
      return faTruck
    case "Personbil":
      return faCar
    case "Släpvagn":
      return faTrailer
    default:
      return faBus
  }
}

const StyledButton = styled(Button)({
  padding: 0,
  marginRight: "-12px",
  marginTop: "-20px",
  minWidth: "50px",
  minHeight: "50px"
})

interface HeaderProps {
  status: number
  headerData: any
  openForm: () => void
}

const StyledPaperBoxVehicleCard = styled(PaperBox)(({ isArchived }) => ({
  backgroundColor: isArchived ? "#F1F1F1" : "#F1FCFE",
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)"
}))

const Header = ({ headerData, openForm }: HeaderProps) => {
  const appContext = useContext(AppContext)
  const theme = useTheme()

  return (
    <Grid container direction="row" alignItems="flex-end" spacing={5} md={10}>
      <Grid item md={10}>
        <StyledPaperBoxVehicleCard isArchived={headerData.archived}>
          <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
            <Grid item>
              <Box component={Grid} container direction="row" marginBottom={3} alignItems="center">
                <CircleIcon size="lg" icon={vehicleIcon(headerData.fordonstyp)} color="#254467" />
                <Grid flexDirection="column">
                  <Typography marginLeft={2} variant="h2">
                    {headerData && headerData.regNumber}
                  </Typography>
                  <Typography marginLeft={2} variant="bodyMedium">
                    {headerData && headerData.fordonstyp}
                  </Typography>
                </Grid>
                {headerData.archived && (
                  <Box
                    sx={{ backgroundColor: "#F8CCC6", borderRadius: "20px" }}
                    px={3} py={0.5} ml={4} mb={2} color={"black"}
                  >
                    <Typography variant="paragraphMedium">
                      Inaktivt
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            {hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin) && (
              <Grid>
                <StyledButton sx={{
                  border: headerData.archived ? "2px grey solid" : ""
                }} onClick={() => openForm()}>
                  <FontAwesomeIcon
                    color={theme.palette.grey[600]}
                    style={{
                      borderRadius: "100%",
                      fontSize: "30px",
                      width: "26px",
                      height: "26px",
                      color: headerData.archived ? "" : "#254467",
                    }}
                    icon={faPenToSquare as IconProp}
                  />
                </StyledButton>
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item>
              <TextCell label="Internnr" text={headerData && headerData.internnumber} />
            </Grid>
            <Grid item>
              <TextCell label="Fabrikat" text={headerData && headerData.fabrikat} />
            </Grid>
            <Grid item>
              <TextCell
                label="Mätarställning"
                text={headerData && headerData.meterSetting}
              />
            </Grid>
            <Grid item>
              <TextCell label="Fordonstyp" text={headerData && headerData.fordonstyp} />
            </Grid>
            <Grid item>
              <TextCell label="Bolag" text={headerData && headerData.companyName} />
            </Grid>
            <Grid item>
              <TextCell label="Drivmedel" text={headerData && headerData.mainPropellant} />
            </Grid>
            <Grid item>
              <TextCell
                label="Färdskrivare"
                text={headerData && headerData.tachographIntegrationActive ? "Ja" : "Nej"}
              />
            </Grid>
          </Grid>
        </StyledPaperBoxVehicleCard>
      </Grid>
    </Grid>
  )
}

export default Header