import { grey } from "@mui/material/colors"
import { svSE } from "@mui/material/locale"

import imageFile from "./Cloudplanning.svg"
import blue from "./colors/blue"
import cyan from "./colors/cyan"
import accordionStyle from "./elements/accordion"

import { createTheme } from "@mui/material/styles"
import typographyStyle from "./elements/typography"

const image = {
  src: imageFile,
  alt: "clp-logo"
}
const defaultTheme = createTheme()

const theme = createTheme({
  typography: typographyStyle,
  logo: image,
  palette: {
    type: "light",
    primary: {
      main: "#4BADC6",
      light: "#D9EEF4",
      500: "#0093B2",
      dark: "#254467",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#4BAB29",
      light: "#DAF4D9",
      dark: "#35771D",
      contrastText: "#FFFFFF"
    },
    warning: {
      main: "#F49800",
      light: "#FFEAC7",
      500: "#F49800",
      dark: "#995E00",
      contrastText: "#FFFFFF"
    },
    error: {
      main: "#E45C41",
      light: "#FBDAD3",
      500: "#CE472D",
      dark: "#AF2C12",
      contrastText: "#FFFFFF"
    },
    success: {
      main: "#6BCA4A",
      light: "#DAF4D9",
      500: "#4BAB29",
      dark: "#35771D",
      contrastText: "#FFFFFF"
    },
    grey: grey,
    foreground: {
      default: "black",
      inverted: "white"
    },
    background: {
      default: "#F9FAFB",
      paper: "#ffffff",
      inverted: "#334466",
      secondary: "#eef0f2",
      dark: "#2E3547",
      light: cyan[100],
      blue: blue[700]
    }
  },
  background: {
    default: "#F9FAFB",
    paper: "#ffffff",
    inverted: "#334466",
    secondary: "#eef0f2",
    dark: "#2E3547",
    light: cyan[100]
  },
  tabs: {
    backgroundColor: "#6DC7DC"
  },

  components: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "20px",
          height: "20px"
        },
        "*::-webkit-scrollbar-track ": {
          backgroundColor: "transparent"
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "#d6dee1",
          borderRadius: "20px",
          border: "6px solid transparent",
          backgroundClip: "content-box"
        },
        "*::-webkit-scrollbar-thumb:hover ": {
          backgroundColor: "#a8bbbf"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "600",
          borderRadius: "25rem",
          fontSize: "16px",
          height: "42px"
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: 60,
          paddingBottom: 16
        },
        maxWidthLg: {
          [defaultTheme.breakpoints.up("lg")]: {
            maxWidth: "100%"
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          [defaultTheme.breakpoints.up("sm")]: {
            minWidth: "100px"
          },
          [defaultTheme.breakpoints.up("md")]: {
            minWidth: "115px"
          },
          "&.Mui-selected": { fontWeight: 700, color: "#454444" }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: blue[800],
          color: "white"
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiList-root": {
            "& .MuiListItem-root": {
              borderRadius: "0.3rem 0 0 0.3rem",
              minHeight: "46px"
            },
            "& .MuiListItemText-root": {
              margin: 0
            }
          },
          "& .MuiTab-labelIcon": {
            color: "white",
            minWidth: "auto",
            fontWeight: 400
          },
          "& .MuiTypography-caption": {
            color: grey[500]
          },
          "& .Mui-selected": {
            backgroundColor: grey[400],
            fontWeight: 700
          }
        }
      }
    },

    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: cyan[200],
          "&$completed": {
            color: cyan[500]
          },
          "&$active": {
            color: cyan[500]
          }
        },
        active: {}
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          "& $iconContainer": {
            paddingRight: "11px"
          }
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          "& $lineVertical": {
            border: "none"
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 65px 0px rgb(0 0 0 / 6%)",
          borderRadius: "8px"
        }
      }
    },

    MuiAccordion: accordionStyle
  },
  svSE
})
export default theme
