import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import moment from "moment"

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "assets/learning/Poppins-Regular.ttf"
    },
    {
      src: "assets/learning/Poppins-Bold.ttf",
      fontWeight: "bold"
    },
    {
      src: "assets/learning/Poppins-Light.ttf",
      fontWeight: "light"
    }
  ]
})

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "space-between"
  },
  section1: {
    margin: 10,
    marginTop: 30,
    padding: 10,
    textAlign: "center",
    justifyContent: "flex-center"
  },
  section2: {
    margin: 10,
    padding: 10,
    textAlign: "center",
    justifyContent: "flex-center"
  },
  section3: {
    margin: 10,
    marginBottom: 20,
    padding: 10,
    textAlign: "center",
    justifyContent: "flex-center"
  },
  title1: {
    fontFamily: "Poppins",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 32,
    letterSpacing: 4,
    marginBottom: 16,
    marginTop: 16
  },
  title2: {
    fontFamily: "Poppins",
    textTransform: "uppercase",
    fontSize: 16
  },
  title3: {
    fontFamily: "Poppins",
    fontSize: 16
  },
  normal: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "light"
  },
  small: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "light",
    width: 430,
    marginHorizontal: "auto",
    marginBottom: 16
  },
  name: {
    fontFamily: "Poppins",
    textTransform: "uppercase",
    fontSize: 32,
    marginTop: 16,
    marginBottom: 16
  },
  signature: {
    marginHorizontal: "auto",
    marginVertical: 0,
    height: "auto",
    width: 250
  },
  logo: {
    marginHorizontal: "auto",
    marginVertical: 20,
    height: 38,
    width: 200
  },
  bar: {
    height: 5,
    width: 92,
    marginVertical: 30,
    marginHorizontal: 230
  }
})

export const CertificateDocument = ({
  userFullName,
  examinationName,
  completedDate,
  examinationCertificate
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section1}>
          <Text style={styles.title3}>Transportportalen</Text>
          <Text style={styles.title1}>Utbildningsintyg</Text>
          <Image src="assets/learning/bar.png" style={styles.bar}></Image>
        </View>
        <View style={styles.section2}>
          <Text style={styles.normal}>Härmed intygas att</Text>
          {userFullName ? (
            <Text style={styles.name}>{userFullName}</Text>
          ) : (
            <Text style={styles.name}>{examinationCertificate.userFullName}</Text>
          )}
          <Text style={styles.small}>
            Har genomgått Cloudplannings {examinationName} med godkänt resultat.
          </Text>
          <Text style={styles.small}>Utfärdad: {moment(completedDate).format("YYYY-MM-DD")}</Text>
        </View>
        <View style={styles.section3}>
          <Image src="assets/learning/Emma-signatur.png" style={styles.signature}></Image>
          <Text style={styles.normal}>Emma Carlsson</Text>
          <Image src="assets/Cloudplanning-logotyp.png" style={styles.logo}></Image>
        </View>
      </Page>
    </Document>
  )
}
