import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Grid from "@mui/material/Grid"
import ClpButton from "components/Button"
import React, { useEffect, useState } from "react"

import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
    minWidth: "300px",
    maxWidth: "100%"
  },
  fullWidthPadding: {
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px"
  }
}))

const ContinueDialog = (props) => {
  const {
    title,
    message,
    onAddSubmit,
    setShowContinueModal,
    result,
    values,
    cancelBtnText,
    continueBtnText
  } = props

  const classes = useStyles()
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setShowContinueModal(false)
    setOpen(false)
  }

  const handleSubmit = () => {
    if (values) {
      onAddSubmit(values)
    } else {
      onAddSubmit()
    }
  }

  useEffect(
    (result) => {
      if (result && result.status === 2) {
        setOpen(false)
      }
    },
    [result?.status]
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={message}
      maxWidth="xs">
      <Grid container direction="column" alignItems="center">
        <DialogContent my={2}>
          <Typography align="center" variant="h4" paragraph>
            {title}
          </Typography>
          <Typography align="center" paragraph>
            {message}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.fullWidthPadding}>
          <Box display="flex" justifyContent="space-between" className={classes.fullWidth} mb={3}>
            <Button onClick={handleClose} color="primary" variant="outlined">
              {cancelBtnText ? cancelBtnText : "Stäng"}
            </Button>
            <ClpButton onClick={handleSubmit} color="primary" variant="contained" result={result}>
              {continueBtnText ? continueBtnText : "Slutför"}
            </ClpButton>
          </Box>
        </DialogActions>
      </Grid>
    </Dialog>
  )
}
export default ContinueDialog
