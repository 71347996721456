export const mapStyles = [
	{
		featureType: "administrative.country",
		elementType: "geometry",
		stylers: [
			{
				visibility: "simplified",
			},
			{
				hue: "#ff0000",
			},
		],
	},
];
