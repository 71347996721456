import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(3),
	},
	downloadPaper: {
		textDecoration: "none",
		color: theme.palette.foreground.default,
	},
}));
