import React, { useContext, useEffect, useMemo, useState } from "react"
import { IconButton, Tooltip } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck, faCircleXmark, faClockRotateLeft } from "@fortawesome/pro-solid-svg-icons"
import moment from "moment/moment"
import ClpTable from "components/ClpTable"
import { hasRole } from "auth/hasRole"
import { allRoles } from "generated/roles"
import { StyledTypography } from "shared/vehicle/InspectionService.styled"
import { AppContext } from "components/AppContextProvider"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import { useApi } from "auth/useApi3"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { apiEndpoints } from "generated/apiEndpoints"
import { StyledStatusIcon } from "./InspectionsHistoryTable.styled"
import { InspectionHistoryStatus, VehicleCheckHistoryDto } from "./Checks.interfaces"
import { InspectionHistoryModal } from "./InspectionHistoryModal"
import { allRoutes } from "generated/routes"
import { useHistory } from "react-router-dom"

export const ChecksHistoryTable = () => {
  const appContext = useContext(AppContext)
  const [historyResult, inspectionHistoryApi] = useApi() as [IApiResult, IApiFunc]
  const [clickedHistory, setClickedHistory] = useState<VehicleCheckHistoryDto>()
  const history = useHistory()

  useEffect(() => {
    const url = apiEndpoints.vehicleregistrychecks.getvehiclecheckhistorybycustomer
    inspectionHistoryApi.get(url)
  }, [])

  const historyColumns: any[] = useMemo(
    () => [
      {
        accessor: (row: VehicleCheckHistoryDto) => {
          return (
            <IconButton color="primary" size="large">
              {row.status === InspectionHistoryStatus.Passed ? (
                <Tooltip title={"Godkänd"}>
                  <StyledStatusIcon status={InspectionHistoryStatus.Passed} icon={faCircleCheck} size="xs" />
                </Tooltip>
              ) : row.status === InspectionHistoryStatus.Failed ? (
                <Tooltip title={"Underkänd"}>
                  <StyledStatusIcon status={InspectionHistoryStatus.Failed} icon={faCircleXmark} size="xs" />
                </Tooltip>
              ) : row.status === InspectionHistoryStatus.Waiting ? (
                <Tooltip title={"Väntar på svar från Transportstyrelsen"}>
                  <StyledStatusIcon status={InspectionHistoryStatus.Waiting} icon={faClockRotateLeft} size="xs" sx={{ transform: "scaleX(-1)" }} />
                </Tooltip>
              ) : <></>}
            </IconButton>
          )
        },
        Header: "Status"
      },
      {
        accessor: "regNumber",
        Header: "Regnr"
      },
      {
        accessor: "internNumber",
        Header: "Internnr"
      },
      {
        accessor: "customFieldName",
        Header: "Kontroll"
      },
      {
        accessor: (row: VehicleCheckHistoryDto) => {
          return row.attendanceDate ? moment(row.attendanceDate).utc().format("YYYY-MM-DD") : ""
        },
        Header: "Inställelse"
      },
      {
        accessor: "attendanceKm",
        Header: "Inst. km"
      },
      {
        accessor: "odometerReading",
        Header: "Mätarinställning"
      },
      {
        accessor: (row: any) => {
          return (row?.carWorkshop ? row.carWorkshop.name : "")
        },
        Header: "Plats"
      },
      {
        accessor: (row: VehicleCheckHistoryDto) => (row.date ? moment(row.date).local().format("YYYY-MM-DD HH:mm") : ""),
        Header: "Datum/tid"
      }
    ],
    []
  )

  useEffect(() => {
    if (hasRole(appContext.appUser, allRoles.SystemAdminDeviceRegistry)) {
      historyColumns.push({
        Cell: ({ cell }: any) => {
          return (
            <>
              <IconButton
                color="primary"
                size="large"
                onClick={() => {
                  setClickedHistory(cell.row.original)
                }}
              >
                <FontAwesomeIcon icon={faEye} size="xs" />
                <StyledTypography>Visa</StyledTypography>
              </IconButton>
            </>
          )
        },
        accessor: "action",
        Header: "Hantera",
        hiddenFilter: true
      })
    }
  }, [])

  function handleClose() {
    setClickedHistory(undefined)
  }

  return (
    <>
      <ClpTable
        columns={historyColumns}
        cellProps={(row: any) => ({
          onClick: () => history.push(allRoutes.VehicleregistryVehiclesDetails(row.original.vehicleId))
        })}
        rows={historyResult.status === 2 && historyResult.data}
        status={historyResult.status}
      />
      <InspectionHistoryModal
        showNavigation={true}
        handleClose={handleClose}
        clickedHistory={clickedHistory}
      />
    </>
  )
}