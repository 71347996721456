import { faTools } from "@fortawesome/pro-solid-svg-icons"
import {
  Autocomplete,
  Box,
  FormControl,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material"
import List from "@mui/material/List"
import { useApiGet } from "auth/useApi2"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import SidebarHeader from "components/sidedrawer/SidebarHeader"
import SidebarInputLabel from "components/sidedrawer/SidebarInputLabel"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useContext, useEffect, useState } from "react"
import { vehicleAlarmNotificationValidationSchema } from "./VehicleValidationSchema"
import { useStyles } from "./useStyles"

export const alarmType = (value) => {
  switch (value) {
    case 1:
      return "Alkolås"
    default:
      return "Förarkort"
  }
}

export const VehicleAlarmNotificationForm = ({
  formData,
  onSubmit,
  editVehicleAlarmNotification,
  result,
  feedback,
  setShowSidebar
}) => {
  const classes = useStyles()
  const appContext = useContext(AppContext)
  const initialValues = editVehicleAlarmNotification
    ? formData
    : {
        companyId: "",
        userId: null,
        alarmType: ""
      }

  const [usersResult, getUsers] = useApiGet()
  useEffect(() => {
    getUsers(apiEndpoints.vehiclealarm.getresponsibleusersforvehiclealarmnotificationdropdown)
  }, [getUsers])

  const [companyResult] = useState(appContext.appUser.customer.companies)

  const handleSubmit = async (values, { resetForm }) => {
    onSubmit(values)
  }
  return formData ? (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={vehicleAlarmNotificationValidationSchema}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <List className={classes.vehicleList} disablePadding>
            <CloseSidebarButton toggleMenu={() => setShowSidebar(false)} />
            <SidebarHeader
              icon={faTools}
              size="lg"
              color="primary"
              title={editVehicleAlarmNotification ? "Redigera notifikation" : "Lägg till notifikation"}
              infoText={
                <Typography color="secondary" variant="bodySmall" style={{ color: "#747474" }}>
                  Användare som läggs till får meddelande på e-post när ett larm kommer in på fordon som är
                  kopplat till bolaget.
                </Typography>
              }
            />

            <List sx={{ padding: "0" }}>
              <ListItem className={classes.listItem}>
                <Box pt={5} width="100%">
                  <SidebarInputLabel labelText="Larmtyp" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      id="alarmType"
                      value={values.alarmType}
                      onChange={(e) => {
                        setFieldValue("alarmType", e.target.value)
                      }}
                      label="Larmtyp"
                      onBlur={handleBlur}
                      error={touched.alarmType && errors.alarmType ? true : false}
                      helperText={touched.alarmType && errors.alarmType ? errors.alarmType : null}>
                      <MenuItem value={1}>Alkolås</MenuItem>
                      <MenuItem value={2}>Förarkort</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Box width="100%">
                  <SidebarInputLabel labelText="Användare" />
                  <FormControl sx={{ width: "100%" }}>
                    {usersResult.status === 2 && (
                      <Autocomplete
                        id="userid-autocomplete"
                        options={usersResult?.data}
                        getOptionLabel={(option) => (option.name ? option.name : "")}
                        value={usersResult.data.find((i) => i.id === values.userId) || null}
                        onChange={(e, value) => {
                          setFieldValue("userId", value?.id || null)
                          setFieldValue("user", value || null)
                        }}
                        onOpen={handleBlur}
                        includeInputInList
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="userId"
                            variant="outlined"
                            onBlur={handleBlur}
                            error={touched.userId && errors.userId ? true : false}
                            helperText={touched.userId && errors.userId ? errors.userId : null}
                          />
                        )}
                      />
                    )}
                  </FormControl>
                </Box>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Box width="100%">
                  <SidebarInputLabel labelText="Bolag" />
                  <FormControl sx={{ width: "100%" }}>
                    {companyResult.length > 0 && (
                      <Autocomplete
                        id="companyId-autocomplete"
                        options={companyResult}
                        getOptionLabel={(option) => (option.name ? option.name : "")}
                        value={companyResult.find((i) => i.id === values.companyId) || null}
                        onChange={(e, value) => {
                          setFieldValue("companyId", value?.id || null)
                          setFieldValue("company", value || null)
                        }}
                        onOpen={handleBlur}
                        includeInputInList
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="companyId"
                            variant="outlined"
                            onBlur={handleBlur}
                            error={touched.companyId && errors.companyId ? true : false}
                            helperText={touched.companyId && errors.companyId ? errors.companyId : null}
                          />
                        )}
                      />
                    )}
                  </FormControl>
                </Box>
              </ListItem>
            </List>

            <Box mt={3}>
              <Button color="primary" type="submit" result={result} feedback={feedback}>
                {editVehicleAlarmNotification ? "Spara ändringar" : "Lägg till"}
              </Button>
            </Box>
          </List>
        </form>
      )}
    </Formik>
  ) : null
}
