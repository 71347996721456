import styled from "@emotion/styled"
import { Box } from "@mui/material"
import theme from "css/theme/light"

export const CalenderListItemStyled = styled(Box)({
  backgroundColor: "#F9FAFB",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.primary.light
  }
})
