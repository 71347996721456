import { List } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"

const useStyles = makeStyles((theme) => ({
  todoListWrapper: {
    padding: "0",
    marginTop: "0",
    borderRadius: "10px",
    boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 4%)"
  }
}))

export const TodoListWrapper = ({ children, style }) => {
  const classes = useStyles()

  return (
    <List style={style} className={classes.todoListWrapper}>
      {children}
    </List>
  )
}
