export function issueReportStatusColor(status: number) {
  switch (status) {
    case 1:
      return "warning"
    case 2:
      return "primary"
    case 3:
      return "primary"
    case 4:
      return "primary"
    case 5:
      return "success"
    default:
      return undefined
  }
}
