import React, { createContext, useState } from "react";

export const ReportTemplateContext = createContext<any>(null);

export const ReportTemplateContextProvider = (props: any) => {
    const [selectedFields, setSelectedFields] = useState([]);
    const [fieldsToChoose, setFieldsToChoose] = useState({
        fieldGroups: [],
        name: "",
        description: ""
    });

    return (
        <ReportTemplateContext.Provider value={{ selectedFields, setSelectedFields, fieldsToChoose, setFieldsToChoose }}>
            {props.children}
        </ReportTemplateContext.Provider>
    );
};
