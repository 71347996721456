import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  stepper: {
    background: "transparent",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 40,
    marginBottom: 40,
    display: "flex",
    justifyContent: " center",
    flexWrap: "wrap",
    gap: "16px "
  },
  stepIcon: {
    color: theme.palette.grey["300"]
  },
  stepIconActive: {
    color: theme.palette.primary["main"]
  },
  stepIconCompleted: {
    color: theme.palette.success["main"]
  },
  stepLabel: {
    marginTop: "-2px",
    fontSize: "2rem",
    cursor: "pointer",
    padding: 0,
    "& .MuiStepLabel-label": {
      fontSize: "16px",
      paddingBottom: "4px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px"
      }
    },
    "& .MuiStepLabel-label.Mui-active": {
      borderBottom: "2px solid",
      color: theme.palette.primary["main"]
    }
  },
  actionCard: {
    cursor: "pointer"
  },
  actionCardContent: {
    minHeight: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 15px",
    flexDirection: "column",
    textAlign: "center",
    boxShadow: "1px 3px 3px 0px rgba(0, 0, 0, 0.10)"
  },
  bigRadioCard: {
    boxShadow: "1px 3px 3px 0px rgba(0, 0, 0, 0.10)",
    padding: "30px 15px",
    cursor: "pointer",
    borderRadius: "5px",
    display: "flex",
    alignItems: "start"
  },
  buttonCheckboxWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    padding: 0,
    margin: 0
  },
  comboboxInput: {
    boxShadow: "1px 3px 3px 0px rgba(0, 0, 0, 0.10)",
    padding: "16.5px 14px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid",
    borderColor: theme.palette.grey["400"],
    height: "56px",
    fontSize: "16px",
    color: theme.palette.grey[900],
    "&:hover": {
      borderColor: "#000"
    }
  },
  comboboxList: {
    backgroundColor: theme.palette.background["paper"],
    fontSize: "24px",
    border: `1px solid ${theme.palette.grey["400"]}`,
    borderRadius: "4px"
  },
  reviewCard: {
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "24px"
    }
  }
}))

export default useStyles
