import { List } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import { QuickLink } from "components/List/QuickLink"

import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import React, { useContext } from "react"
export const DashboardQuickLinks = ({ route, title }) => {
  // const classes = useStyles();
  const appContext = useContext(AppContext)
  const isUserIssueReports = hasRole(appContext.appUser, allRoles.IssueReports)
  const isAdminIssueReports = hasRole(appContext.appUser, allRoles.IssueReportsAdmin)

  return (
    <List disablePadding>
      <QuickLink route={allRoutes.IssuereportsArchive} text="Felrapporter" />

      {isAdminIssueReports && (
        <>
          <QuickLink route={allRoutes.IssuereportsWorkorders} text="Arbetsordrar" />
          <QuickLink route={allRoutes.IssuereportsWorkordersAdd} text=" Skapa arbetsorder" />
          <QuickLink route={allRoutes.IssuereportsArchiveCategories} text=" Kategorier" />
        </>
      )}
    </List>
  )
}
