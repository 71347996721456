import { Card, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { TodoListWrapper } from "components/dataDisplay/TodoList/TodoListWrapper"
import WorkOrderCardDetails from "./WorkOrderCardDetails"
import WorkOrderCardDropdown from "./WorkOrderCardDropdown"
import WorkOrderCardReadMessage from "./WorkOrderCardReadMessage"
import WordOrderCardReportList from "./WorkOrderCardReportList"
import WorkOrderCardWriteMessage from "./WorkOrderCardWriteMessage"

const WorkOrderCard = (props) => {
  const {
    vehiclesResult,
    initialValues,
    values,
    setFieldValue,
    setVehicle,
    handleChange,
    handleBlur,
    touched,
    errors,
    status,
    checked,
    vehicleId,
    vehicleIdFromDash
  } = props

  return (
    <Card>
      {status > 1 && values.message && (
        <>
          <WorkOrderCardReadMessage
            bgColor="#fafafa"
            title="Meddelande till verkstad"
            message={values.message}
          />
          {/* <WorkOrderCardReadMessage bgColor="#D9EEF4" title="Meddelande från verkstad" message={values.message} /> */}
        </>
      )}

      {status > 1 && <WorkOrderCardDetails bgColor="#fafafa" values={values} />}

      {status < 2 && (
        <Box p={4}>
          <WorkOrderCardDropdown
            vehicleIdFromDash={vehicleIdFromDash}
            initialValues={initialValues}
            vehicleId={vehicleId}
            vehiclesResult={vehiclesResult}
            setFieldValue={setFieldValue}
            setVehicle={setVehicle}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
          />
          <WorkOrderCardWriteMessage
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
          />
        </Box>
      )}

      <Box p={4}>
        <Typography mb={2} variant="h6">
          {status !== 1 ? "Felrapporter" : "Välj felrapporter"}
        </Typography>

        <TodoListWrapper>
          {values.status > 1
            ? values.issueReports
                .filter((x) => x.added)
                .map((issueReport, index) => (
                    <WordOrderCardReportList
                      setFieldValue={setFieldValue}
                      values={values}
                      issueReport={issueReport}
                      index={index}
                    />
                ))
            : values.issueReports.map((issueReport, index) => (
                <WordOrderCardReportList
                  setFieldValue={setFieldValue}
                  values={values}
                  issueReport={issueReport}
                  index={index}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
              ))}
        </TodoListWrapper>
      </Box>
    </Card>
  )
}

export default WorkOrderCard
