import { Box, Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { allRoutes } from "generated/routes"
import React, { useContext, useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"

import {
  DatePickerColumnFilter,
  NumberRangeColumnFilter,
  SelectColumnFilter
} from "components/ClpTable/ClpTableFilterTypes"

import ClpTable from "components/ClpTable"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"

const AdminLearningResults = () => {
  const globalClasses = globalUseStyles()
  const routerHistory = useHistory()
  const loadingContext = useContext(GlobalLoaderContext)
  const [examResultsResult, examResultsFuncs] = useApi()

  useEffect(() => {
    examResultsFuncs.get(apiEndpoints.learningadmin.getexamresultslonglist)
  }, [examResultsFuncs])

  useEffect(() => {
    console.log(examResultsResult)
    loadingContext.setLoading(examResultsResult.status === 1)
  })

  const columns = useMemo(
    () => [
      {
        accessor: "userFullname",
        Header: "Namn"
      },
      {
        accessor: "userEmail",
        Header: "E-post"
      },
      {
        accessor: "examinationName",
        Header: "Utbildning",
        mainFilter: true,
        Filter: SelectColumnFilter
      },
      {
        accessor: "userManagerName",
        Header: "Närmsta Chef",
        mainFilter: true,
        Filter: SelectColumnFilter
      },
      {
        accessor: "userCompanies",
        Header: "Bolag",
        mainFilter: true,
        Filter: SelectColumnFilter
      },
      {
        accessor: "examinationScore",
        Header: "Resultat",
        numeric: true,
        Filter: NumberRangeColumnFilter,
        filter: "between"
      },
      {
        accessor: (row) => {
          return row.examinationDate && moment(row.examinationDate).format("YYYY-MM-DD")
        },
        Header: "Utbildningsdatum",
        Filter: DatePickerColumnFilter
      }
    ],
    []
  )

  return (
    <div>
      <Container>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Box marginY={3}>
            <Typography variant="h1" gutterBottom>
              Utbildningsresultat
            </Typography>
            <Typography variant="paragraphLarge">
              Här kan du se resultat och ladda ner utbildningsbevis.
            </Typography>
          </Box>
        </Grid>
      </Container>

      <Box className={globalClasses.tableContainer}>
        <ClpTable
          rows={examResultsResult.status === 2 && examResultsResult.data}
          status={examResultsResult.status}
          columns={columns}
          rowProps={(row) => ({
            onClick: () => routerHistory.push(allRoutes.LearningAdminResultsDetails(row.original.id))
          })}
        />
      </Box>
    </div>
  )
}

export default AdminLearningResults
