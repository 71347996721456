import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./custom.css"
import * as serviceWorker from "./registerServiceWorker"

// import registerServiceWorker from './registerServiceWorker';
import { EventType, PublicClientApplication } from "@azure/msal-browser"
import { msalConfig } from "./auth/authConfig"

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href")
const rootElement = document.getElementById("root")

export const msalInstance = new PublicClientApplication(msalConfig)

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

ReactDOM.render(<App pca={msalInstance} />, rootElement)

serviceWorker.unregister()
//registerServiceWorker();
