import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Grid, IconButton, Typography } from "@mui/material"
import ListItemText from "@mui/material/ListItemText"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import { isValidElement, useState } from "react"

const useStyles = makeStyles((theme) => ({
  chevronBtn: {
    padding: 0,
    marginLeft: "8px",
    color: "black"
  }
}))

const TodoListReadMore = ({ id, categoryName, description, children }) => {
  const classes = useStyles()
  const [isReadMoreShown, setReadMoreShown] = useState(false)

  const ToggleBtn = () => {
    setReadMoreShown((prevState) => !prevState)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex">
            <ListItemText
              primary={
                <Box p={0} display="flex" gap={categoryName ? 2 : null}>
                  <Typography sx={{ fontSize: "18px" }} variant="bodyMedium" fontWeight="600">
                    {categoryName ? `${categoryName}:` : categoryName}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }} variant="bodyMedium">
                    {isReadMoreShown
                      ? description
                      : description.length < 50
                      ? description
                      : description.substr(0, 50) + "..."}
                  </Typography>
                </Box>
              }
            />
            <Box>
              <Typography variant="bodyMedium" fontWeight="300">
                {id && id.length > 0 ? "#" + id : null}
              </Typography>
              {description.length > 50 ||
                (isValidElement(children) && (
                  <IconButton className={classes.chevronBtn} onClick={ToggleBtn}>
                    {isReadMoreShown ? (
                      <ExpandLessIcon fontSize="large" />
                    ) : (
                      <ExpandMoreIcon fontSize="large" />
                    )}
                  </IconButton>
                ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isReadMoreShown ? children : null}
        </Grid>
      </Grid>
    </>
  )
}

export default TodoListReadMore
