import {
  faCheckCircle,
  faCircleX,
  faClock,
  faExclamationCircle,
  faQuestionCircle,
  faWarning
} from "@fortawesome/pro-solid-svg-icons"
import moment from "moment"

export const attendeeStatus = (status, label) => {
  switch (status) {
    case 0:
      return "Ej påbörjad"
    case 1:
      return "Godkänd"
    case 2:
      return "Ej godkänd"
    case 3:
      return label
    default:
      return "-"
  }
}

export function attendeeStatusColor(status) {
  switch (status) {
    case 0:
      return "primary"
    case 1:
      return "success"
    case 2:
      return "error"
    case 3:
      return "warning"
    default:
      return undefined
  }
}

export function attendeeStatusIcon(status) {
  switch (status) {
    case 0:
      return faQuestionCircle
    case 1:
      return faCheckCircle
    case 2:
      return faCircleX
    case 3:
      return faWarning
    default:
      return undefined
  }
}

export function validTo(lastDateForPassedExam) {
  if (lastDateForPassedExam !== null && moment(lastDateForPassedExam).diff(moment(), "days") > 30) {
    return { color: null, label: moment(lastDateForPassedExam).format("YYYY-MM-DD") }
  } else if (moment(lastDateForPassedExam).diff(moment(), "days") > 0) {
    return {
      color: "warning",
      icon: faClock,
      label: `Förfaller om ${moment(lastDateForPassedExam).diff(moment(), "days")} dagar`
    }
  } else if (lastDateForPassedExam === null || moment(lastDateForPassedExam).diff(moment(), "days") < 0) {
    return {
      color: "error",
      icon: faExclamationCircle,
      label:
        lastDateForPassedExam === null
          ? `Förfallen`
          : `Förfallen för ${moment(lastDateForPassedExam)
              .diff(moment(), "days")
              .toString()
              .replace("-", "")} dagar sedan`
    }
  }

  return null
}
