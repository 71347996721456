import React from "react"
import { useApi } from "auth/useApi3"
import { apiEndpoints } from "generated/apiEndpoints"
import { ExamFinishedSummary } from "./components/ExamFinishedSummary"

const FinishedExam = ({ match }) => {
  const resultId = match.params.resultId

  const [summaryResult, summaryApi] = useApi()
  React.useEffect(() => {
    summaryApi.get(apiEndpoints.learning.getfinishedexamresultsummary.replace("{id}", resultId))
  }, [summaryApi, resultId])

  return summaryResult.status === 2 && <ExamFinishedSummary item={summaryResult.data} />
}

export default FinishedExam
