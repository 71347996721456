import moment from "moment"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { Components, momentLocalizer, View } from "react-big-calendar"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Grid, Tab, Typography } from "@mui/material"
import "moment/locale/sv"

import { IconProp } from "@fortawesome/fontawesome-svg-core"
import CalendarListView from "./components/CalendarListView"
import { CalendarContext } from "./context/CalendarStateProvider"
import {
  ShortTypography,
  StyledBigCalendar,
  StyledTypographyOneLine,
  StyledTypographyTime
} from "./style/BigCalendar.styled"

import { AppContext } from "components/AppContextProvider"
import i18next from "i18next"
import { EventDialog } from "./components/CalendarDialog"
import CustomMonthView from "./components/CustomMonthView"
import { ToolbarCalendar } from "./components/ToolbarCalendar"
import { backgroundStatusColor, statusColor, statusIcon, statusIconColor } from "./helpers/calendarStatusEvent"
import { ICalendarProps } from "./interfaces/calendar.interface"
import { IEventProps } from "./interfaces/event.interface"
import { VehicleCheckStatus } from "helpers/Checks/ChecksStatusColor"
import { faLoader } from "@fortawesome/pro-solid-svg-icons"
import { styled } from "@mui/material/styles"

moment.locale(i18next.language || window.localStorage.i18nextLng, {
  week: {
    dow: 1,
    doy: 1
  }
})

const localizer = momentLocalizer(moment)

const components = {
  event: (props: any) => {
    const event = props?.event
    return (
      <Grid container flexDirection="column" justifyContent="flex-start"
        sx={{
          borderRadius: "8px",
          backgroundColor: backgroundStatusColor(event?.typeId, event?.status),
          "&:hover": {
            border: "1px solid " + statusColor(event?.typeId, event?.status),
          }
        }}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="baseline" flexShrink="1" flexWrap="nowrap">
          <Box display="flex">
            <FontAwesomeIcon
              style={{
                marginRight: "3px",
                marginTop: "1px",
                transform: event?.status === VehicleCheckStatus.waiting ? "scaleX(-1)" : ""
              }}
              size="sm"
              color={statusIconColor(event?.typeId, event.status)}
              icon={statusIcon(event.typeId, event?.status) as IconProp}
            />
            <Box sx={{ position: "relative", top: "-5px"}}>
              <StyledTypographyTime variant="labelSmall" color="grey">
                {event.start && moment(event.start).format("HH:mm")}
              </StyledTypographyTime>
              <StyledTypographyTime variant="labelSmall" color="grey">
                {event.end && event.end !== event.start && (" - ")}
              </StyledTypographyTime>
              <StyledTypographyTime variant="labelSmall" color="grey">
                {event.end && event.end !== event.start && moment(event.end).format("HH:mm")}
              </StyledTypographyTime>
            </Box>
          </Box>
          <Box sx={{ position: "relative", top: "-3px", maxWidth: event.end && event.end !== event.start ? "54%" : "70%" }}>
            <StyledTypographyOneLine noWrap variant="labelSmall">{event.subtitle}</StyledTypographyOneLine>
          </Box>
        </Grid>
        <Grid
          container
          mt={-1}
          sx={{
            maxHeight: '2rem',
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          <ShortTypography flexWrap="wrap" variant="labelSmall" fontWeight={550} sx={{ wordBreak: "break-word" }}>
            {event.title}
          </ShortTypography>
        </Grid>
      </Grid>
    )
  },
  toolbar: ToolbarCalendar,
  week: {
    header: ({ date, localizer }: any) => (
      <>
        <Typography>{localizer.format(date, "ddd")}</Typography>
        <Typography color="textPrimary" fontWeight={600}>
          {localizer.format(date, "DD")}
        </Typography>
      </>
    )
  }
} as Components

export interface EventDialogProps {
  event?: IEventProps
  open: boolean
  onClose: (value: any) => void
  reloadCalendarEvents?: () => void
}

const StyledSpinnerBox = styled(Box)({
  position: "absolute",
  left: "50%",
  top: "46%",
  transform: "translate(-50%, -50%)",
  zIndex: "5",
})

const BigCalendar: React.FC<ICalendarProps> = ({ reloadCalendarEvents }) => {
  const { date, view, setView, setDate, columnView, filterEvents, loadingEvents } = useContext(CalendarContext)
  const { selectedLanguage } = useContext(AppContext)
  const [filterEventsState, setfilterEventsState] = useState<any[]>([])
  const [eventDialog, setEventDialog] = useState<EventDialogProps | null>(null)
  useEffect(() => {
    setfilterEventsState(filterEvents)
  }, [filterEvents])

  const views = useMemo(
    () => ({
      month: columnView ? CustomMonthView : CalendarListView,
      week: columnView ? true : CalendarListView,
      day: columnView ? true : CalendarListView
    }),
    [columnView]
  )

  return (
    <>
      {loadingEvents && (
        <StyledSpinnerBox>
          <Tab icon={<FontAwesomeIcon spin icon={faLoader} style={{
            width: "20%",
            height: "20%",
          }} />} label={`Laddar kalendern`} />
        </StyledSpinnerBox>
      )}
      <StyledBigCalendar
        events={filterEventsState}
        components={components}
        localizer={localizer}
        scrollToTime={new Date(new Date().setHours(7, 0, 0, 0))}
        view={view}
        date={date}
        // @ts-ignore //Fel typ i big-react-calendar interface, om det är löst i kommande version, ta bort ignore
        views={views}
        timeslots={2}
        step={30}
        culture={selectedLanguage}
        className={view === "week" ? "rbc-week-view" : ""}
        eventPropGetter={(event: any) => CustomEvent(event, view)}
        dayLayoutAlgorithm={"no-overlap"}
        onNavigate={(date) => {
          setView("day")
          setDate(date)
        }}
        popup={true}
        messages={{
          showMore: (count) => `+${count} fler`
        }}
        onSelectEvent={(event) =>
          setEventDialog({
            reloadCalendarEvents: reloadCalendarEvents,
            event: event,
            open: true,
            onClose: () => setEventDialog(prevState => prevState && ({ ...prevState, open: false, }))
          })
        }
      />
      {eventDialog && <EventDialog {...eventDialog} reloadCalendarEvents={reloadCalendarEvents} />}
    </>
  )
}
const CustomEvent = (event: any, view?: View): { style: React.CSSProperties } => {
  var style: React.CSSProperties = {
    backgroundColor: view === "month" ? "transparent" : "rgba(217, 238, 244, 0.41)",
    borderRadius: "8px",
    color: "#254467",
    border: "8px",
    display: "block",
    left: "0px!important",
    width: "100%!important"
  }
  return {
    style: style
  }
}
export default BigCalendar
