import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import { MapContext } from "./MapContextProvider";
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 1,
        color: "#fff"
    }
}));

const LoadingMarkers = () => {
    const classes = useStyles();
    const { loadingMarkers } = useContext(MapContext);

    return (
        loadingMarkers && (
            <Backdrop className={classes.backdrop} open={loadingMarkers}>
                <CircularProgress />
            </Backdrop>
        )
    );
};
export default LoadingMarkers;
