import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  textFieldShadow: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {}
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#00000012 !important",
    boxShadow: "1px 3px 3px 0px rgba(0, 0, 0, 0.10)"
  },

  plusIconBackground: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "28px",
    minWidth: "28px"
  },

  addContactButton: {
    display: "flex",
    flexDirection: "row",
    padding: "15px",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    alignItems: "center",
    borderRadius: "10px",
    boxShadow: "0px 0px 16px 0px #0000001A",
    textAlign: "center",
    marginBottom: "2px",
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow: "-8px 8px 16px 0px #0000001A"
    }
  }
}))

export default useStyles
