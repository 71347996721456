import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  sosIconBackground: {
    backgroundColor: theme.palette.error["dark"],
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "28px",
    minWidth: "28px"
  },

  contactIconBackground: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "28px",
    minWidth: "28px"
  },

  documentIconBackground: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "56px",
    minWidth: "56px"
  },

  phoneContactButton: {
    display: "flex",
    flexDirection: "row",
    padding: "15px",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    alignItems: "center",
    borderRadius: "10px",
    boxShadow: "0px 0px 16px 0px #0000001A",
    textAlign: "center",
    marginBottom: "2px",
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow: "-8px 8px 16px 0px #0000001A"
    }
  },

  documentButton: {
    borderRadius: 0,
    padding: 0,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0px 0px 16px 0px #0000001A"
    }
  }
}))

export default useStyles
