import {StyleSheet, Text, View} from '@react-pdf/renderer'
import React, {Fragment} from 'react'

const borderColor = 'rgba(239, 239, 239, 1)'
const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottomColor: 'rgba(239, 239, 239, 1)',
    borderBottomWidth: 1
  },

  column: {
    flex: 1,
    paddingTop: '4px',
    textAlign: 'left',
    paddingLeft: 8,
    paddingRight: 8
  }
})

const TableRow = ({data}) => {
  const rowItems = (row) => {
    return row.cells
      .filter((x) => !x.column.hidden)
      .map((cell) => <Text style={styles.column}>{cell.value}</Text>)
  }

  const rows = data.map((row, index) => {
    return (
      <View style={styles.row} key={index}>
        {rowItems(row)}
      </View>
    )
  })

  return <Fragment>{rows}</Fragment>
}

export default TableRow
