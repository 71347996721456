import * as Yup from "yup"

export const validationFile = Yup.object().shape({
  excelFile: Yup.mixed().required("Du måste välja en fil")
})
export const validationSelectedFields = Yup.object().shape({
  internNumber: Yup.string().nullable().required("Du måste ange internnr"),
  regNumber: Yup.string().nullable(),
  mainPropellant: Yup.string().nullable().required("Du måste ange drivmedel"),
  vehicleType: Yup.string(),
  tachographIntegrationActive: Yup.bool(),
  company: Yup.string().nullable().required("Du måste ange bolag")
})
