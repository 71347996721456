import * as Yup from "yup"

export const customerValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Namn är obligatoriskt")
    .min(2, "Namn behöver vara minst 2 tecken långt")
    .nullable(),
  organisationNumber: Yup.number().nullable().typeError("Organisationsnummer behöver vara ett nummer."),
  streetAddress: Yup.string().nullable(),
  postalCode: Yup.number().nullable().typeError("Postnummer behöver vara ett nummer."),
  cityAddress: Yup.string().nullable(),
  newsMaxUsers: Yup.number().nullable().typeError("Antal licenser måste vara ett nummer"),
  learningMaxUsers: Yup.number().nullable().typeError("Antal licenser måste vara ett nummer"),
  customerAdminMaxUsers: Yup.number().nullable().typeError("Antal licenser måste vara ett nummer"),
  systemAdminCustomersMaxUsers: Yup.number().nullable().typeError("Antal licenser måste vara ett nummer"),
  systemAdminDeviceRegistryMaxUsers: Yup.number()
    .nullable()
    .typeError("Antal licenser måste vara ett nummer"),
  bplDaysShown: Yup.number().nullable().typeError("Antal dagar måste vara ett nummer")
})

export const companyValidationSchema = Yup.object().shape({
  name: Yup.string().required("Namn är obligatoriskt").min(2, "Namn behöver vara minst 2 tecken långt"),
  organisationNumber: Yup.number().typeError("Organisationsnummer behöver vara ett nummer."),
  streetAddress: Yup.string(),
  postalCode: Yup.string(),
  cityAddress: Yup.string()
})

export const departmentValidationSchema = Yup.object().shape({
  name: Yup.string().required("Namn är obligatoriskt").min(2, "Namn behöver vara minst 2 tecken långt"),
  description: Yup.string()
})

export const userValidationSchema = Yup.object().shape({
  name: Yup.string().nullable().required("Namn är obligatoriskt"),
  username: Yup.string()
    .nullable()
    .required("Användarnamn är obligatoriskt")
    .matches(/^(\S+$)/g, "Fältet kan inte innehålla blanksteg")
    .when("userExists", {
      is: (value) => value === true,
      then: (schema) => schema.matches(true, "Användare finns redan registrerad")
    }),
  email: Yup.string().nullable().email("E-post har felaktigt format"),
  bplUserId: Yup.number().nullable(true),
  userExists: Yup.boolean(),
  useUsername: Yup.boolean(),
  employeeId: Yup.string().nullable(true),
  bplSyncCompanyId: Yup.number()
    .nullable(true)
    .test("bplSyncCompanyId", "Bolag är inte kopplat till användaren", function (value, yupObj) {
      if (value !== null) {
        return yupObj?.parent?.companyIds?.includes(value)
      }
      return true
    })
})
export const userCreateValidationSchema = Yup.object().shape({
  name: Yup.string().nullable().required("Namn är obligatoriskt"),
  username: Yup.string()
    .nullable()
    .when("useUsername", {
      is: (value) => value === false,
      then: (schema) => schema.email("E-post har felaktigt format")
    })
    .when("userExists", {
      is: (value) => value === true,
      then: (schema) => schema.matches(true, "Användare finns redan registrerad")
    })
    .required("Användarnamn är obligatoriskt")
    .matches(/^(\S+$)/g, "Fältet kan inte innehålla blanksteg"),
  password: Yup.string().nullable().required("Lösenord är obligatoriskt"),
  sendEmailOnCreate: Yup.boolean(),
  userExists: Yup.boolean(),
  useUsername: Yup.boolean(),
  bplUserId: Yup.number().nullable(true)
})
