import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
interface CardProps {
  label: string
  value: any
  compareData: boolean
  icon: IconProp
  title?: string
  labelSecondary?: string
  valueSecondary?: any
}

const UnitResultCard = (props: CardProps) => {
  const { label, icon, value, compareData, title, labelSecondary, valueSecondary } = props

  return (
    <Grid item md={12} xs={12}>
      {title && (
        <Typography mb={2} variant="bodyCompactLarge" fontWeight="600">
          {title}
        </Typography>
      )}
      <PaperBox
        paddingX={3}
        paddingY={3}
        marginY={0}
        sx={{
          backgroundColor: compareData ? theme.palette.success["light"] : theme.palette.grey["100"]
        }}>
        <ListItem disableGutters sx={{ p: 0 }} alignItems="center">
          <ListItemAvatar sx={{ pl: "16px", mr: "16px" }}>
            <FontAwesomeIcon
              size="xl"
              icon={icon}
              color={compareData ? theme.palette.success["main"] : theme.palette.grey["500"]}
            />
          </ListItemAvatar>
          <ListItemText>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography
                  variant="labelMedium"
                  fontWeight="400"
                  color={compareData ? theme.palette.success["main"] : theme.palette.grey["500"]}>
                  {label}
                </Typography>
                <Typography variant="bodyMedium" fontWeight="600">
                  {value}
                </Typography>
              </Grid>
            </Grid>
            {labelSecondary && valueSecondary && (
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography
                    variant="labelMedium"
                    fontWeight="400"
                    color={compareData ? theme.palette.success["main"] : theme.palette.grey["500"]}>
                    {labelSecondary}
                  </Typography>
                  <Typography variant="bodyMedium" fontWeight="600">
                    {valueSecondary}
                  </Typography>
                </Grid>{" "}
              </Grid>
            )}
          </ListItemText>
        </ListItem>
      </PaperBox>
    </Grid>
  )
}

export default UnitResultCard
