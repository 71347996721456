import React, { useContext } from "react";

import { useStyles } from "../useStyles";
import ListItem from "@mui/material/ListItem";
import DeviceLabel from "./DeviceLabel";
import DeviceStatus from "./DeviceStatus";
import classNames from "classnames";
import { MapContext } from "../../MapContextProvider";
const DeviceListItem = ({ device }) => {
    const classes = useStyles();
    const { activeMarker } = useContext(MapContext);

    return (
        <ListItem
            className={classNames(classes.device, activeMarker && activeMarker.label === device.label && classes.activeListItem)}
            key={device.id}
            role={undefined}
            dense
            button
        >
            <DeviceLabel device={device} />
            <DeviceStatus device={device} />
        </ListItem>
    );
};
export default DeviceListItem;
