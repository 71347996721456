import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faPhone } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Grid, Typography } from "@mui/material"
import React from "react"
import styles from "./style.js"

interface PhoneContactLinkProps {
  name: string
  phoneNumber: string
}

const PhoneContactLink: React.FC<PhoneContactLinkProps> = (props) => {
  const style = styles()
  return (
    <Button className={style.phoneContactButton} href={"tel:" + props.phoneNumber}>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography color={"MenuText"}>{props.name}</Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems={"center"} justifyContent={"flex-end"}>
            <Grid item>
              <Typography color="textSecondary">{props.phoneNumber}</Typography>
            </Grid>
            <Grid item>
              <Box className={style.contactIconBackground} sx={{ marginLeft: 2 }}>
                <FontAwesomeIcon icon={faPhone as IconProp} color="white" size="sm" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Button>
  )
}

export default PhoneContactLink
