import { FormControl, FormControlLabel, Switch, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { useEffect } from "react"

export const useStyles = makeStyles((theme) => ({
  formAreaInput: {
    padding: "16px 12px"
  }
}))

function getDefaultValue(valueAsString, type, dateInfo) {
  switch (type) {
    case "Boolean":
      return valueAsString === "True" ? true : false
    case "Date":
      return valueAsString !== "" ? moment(valueAsString).toDate() : null
    default:
      return valueAsString
  }
}
const CustomFieldInput = (props) => {
  const classes = useStyles()
  const { values, setFieldValue, customField, handleChange, handleBlur, touched, errors, dateFields, initialValues } = props
  const fieldType = customField.type // Boolean, Text, Numeric, Date

  useEffect(() => {
    setFieldValue("customField" + customField.customFieldId, customField.value)
  }, [customField])

  const updateFieldArray = (customField, val) => {
    setFieldValue("customField" + customField.customFieldId, val)

    const newArray = [...values.customFieldValues]
    for (var i = 0; i < newArray.length; i++) {
      if (newArray[i].customFieldId === customField.customFieldId) {
        newArray[i].value = val
      }
    }
    setFieldValue("customFieldValues", newArray)
  }

  return fieldType === "Text" ? (
    <TextField
      autoComplete="off"
      fullWidth
      id={"customField" + customField.customFieldId}
      name={"customField" + customField.customFieldId}
      value={values["customField" + customField.customFieldId]}
      onChange={(e) => {
        handleChange(e)
        updateFieldArray(customField, e.target.value)
      }}
      onBlur={handleBlur}
      error={
        touched["customField" + customField.customFieldId] &&
        errors["customField" + customField.customFieldId]
          ? true
          : false
      }
      helperText={
        touched["customField" + customField.customFieldId] &&
        errors["customField" + customField.customFieldId]
          ? errors["customField" + customField.customFieldId]
          : null
      }
      variant="outlined"
      InputProps={{
        classes: { input: classes.formAreaInput },
        disableUnderline: true
      }}
    />
  ) : fieldType === "Boolean" ? (
    <FormControlLabel
      control={
        <Switch
          onChange={(item) => {
            const checked = item.target.checked
            const initiallyEmpty = initialValues["customField" + customField.customFieldId] === ""
            updateFieldArray(customField, checked ? "True" : initiallyEmpty ? "" : "False")
          }}
          onBlur={handleBlur}
          helperText={
            touched["customField" + customField.customFieldId] &&
            errors["customField" + customField.customFieldId]
              ? errors["customField" + customField.customFieldId]
              : null
          }
          checked={getDefaultValue(customField.value, fieldType)}
        />
      }
    />
  ) : fieldType === "Numeric" ? (
    <TextField
      autoComplete="off"
      type="number"
      fullWidth
      id={"customField" + customField.customFieldId}
      value={values["customField" + customField.customFieldId]}
      onChange={(e) => {
        handleChange(e)
        updateFieldArray(customField, e.target.value)
      }}
      onBlur={handleBlur}
      error={
        touched["customField" + customField.customFieldId] &&
        errors["customField" + customField.customFieldId]
          ? true
          : false
      }
      helperText={
        touched["customField" + customField.customFieldId] &&
        errors["customField" + customField.customFieldId]
          ? errors["customField" + customField.customFieldId]
          : null
      }
      variant="outlined"
      InputProps={{ classes: { input: classes.formAreaInput }, disableUnderline: true }}
    />
  ) : fieldType === "Date" ? (
    <FormControl fullWidth>
      <DatePicker
        value={
          values["customField" + customField.customFieldId]
            ? moment(values["customField" + customField.customFieldId])
            : null
        }
        onChange={(value) => {
          const selectedDate = moment(value).format("YYYY-MM-DD HH:mm:ss")
          setFieldValue("customField" + customField.customFieldId, selectedDate)
          updateFieldArray(customField, selectedDate)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={"customField" + customField.customFieldId}
            value={values["customField" + customField.customFieldId]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched["customField" + customField.customFieldId] &&
              errors["customField" + customField.customFieldId]
                ? true
                : false
            }
            helperText={
              touched["customField" + customField.customFieldId] &&
              errors["customField" + customField.customFieldId]
                ? errors["customField" + customField.customFieldId]
                : null
            }
          />
        )}
        id={"customField" + customField.customFieldId}
      />
    </FormControl>
  ) : null
}

export default CustomFieldInput
