import { Card, CardContent, Grid, Skeleton, Typography } from "@mui/material"

import React from "react"

export const SkeletonCardImage = () => {
  return (
    <>
      <Card>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item sm={4} xs={12}>
            <Skeleton sx={{ height: 225 }} animation="wave" variant="rectangular" />
          </Grid>
          <Grid item sm={8} xs={12}>
            <CardContent>
              <Typography variant="h6" gutterBottom color="primary" marginBottom={1}>
                <Skeleton />
              </Typography>
              <Typography variant="h4" gutterBottom>
                <Skeleton />
              </Typography>
              <Typography variant="bodySmall" color="textSecondary">
                <Skeleton />
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}
