import { faDownload } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { useParams } from "react-router-dom"

import { apiEndpoints } from "generated/apiEndpoints"

const LegacyResultDescription = () => {
  const [downloadResult, downloadApi] = useApi()
  const params = useParams()

  return (
    <>
      <Typography marginBottom={3}>
        Det här är ett importerat resultat från tidigare system och den detaljerade specifikationen kan du
        ladda ned genom att klicka på knappen nedan.
      </Typography>

      <Button
        color="primary"
        variant="contained"
        disabled={downloadResult.status === 1}
        startIcon={<FontAwesomeIcon icon={faDownload} />}
        onClick={() => {
          downloadApi.getBlob(
            apiEndpoints.learningadmin.downloadlegacypdf.replace("{id}", params.id),
            "testresultat.pdf"
          )
        }}>
        Ladda ner testresultat
      </Button>
    </>
  )
}
export default LegacyResultDescription
