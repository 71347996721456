import React, { useState } from "react"
import { useApi } from "auth/useApi3"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {faMagnifyingGlass, faTrashCanXmark} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Card,
  CardActions,
  CardMedia, CardMediaProps,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  Typography
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { IFormUploadAttachmentProps } from "./formuploadfile.interface"
import {apiEndpoints} from "../../../../generated/apiEndpoints";
import {IApiFunc, IApiResult} from "../../../../auth/interface/api.interface";

const MaterialDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0, 2, 1, 2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  },
  "& .MuiPaper-root": {
    maxWidth: "70%",
    borderRadius: "12px",
    [theme.breakpoints.between("xs", "md")]: {
      margin: 0,
      maxWidth: "80%"
    }
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.30)"
  }
}))

interface ExtendedCardMediaProps extends CardMediaProps {
  alt: string;
}

const StyledCardMedia = styled(CardMedia)<ExtendedCardMediaProps>({
  display: "flex",
  justifyContent: "center",
})

const protectedRoutes = [
    "/vehicleregistry/vehicles/"
]

const FormPreviewFileThumbnail = (props: IFormUploadAttachmentProps) => {
  const { fileId, fileName, Data, removeFile } = props
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [showComponent, setShowComponent] = useState(true)
  const shouldShowDeleteButton = !protectedRoutes.some(route => window.location.href.includes(route))
  
  const closeModal = () => {
    setDialogOpen(false)
  }

  return (
    <>
      {Data !== null && showComponent && (
        <>
          <Card>
            <>
              <StyledCardMedia
                component={"img"}
                sx={{ height: 150 }}
                alt={`Bild nr ${fileId}`}
                image={`data:image/png;base64,${Data}`}
                onClick={() => setDialogOpen(true)}
              />
              <CardActions sx={{ justifyContent: "center" }}>
                <Button size="small" onClick={() => setDialogOpen(true)}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass as IconProp}
                    style={{ color: "#34727F", marginRight: "5px" }}
                  />
                  <Typography>Öppna</Typography>
                </Button>
                {shouldShowDeleteButton && (
                  <Button size="small" onClick={() => removeFile(fileId)}>
                    <FontAwesomeIcon
                        icon={faTrashCanXmark as IconProp}
                        style={{ color: "#E45C41", marginRight: "5px" }}
                    />
                    <Typography>Ta bort</Typography>
                  </Button>
                )}
              </CardActions>
            </>
          </Card>
          <Modal open={dialogOpen} onClose={() => closeModal()} aria-labelledby="image">
            <MaterialDialog onClose={closeModal} aria-labelledby="customized-dialog-title" open={dialogOpen}>
              <Grid container justifyContent={"space-between"} alignItems={"center"} flexWrap={"nowrap"}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  {fileName}
                </DialogTitle>
                <Button
                  aria-label="close"
                  onClick={closeModal}
                  color="primary"
                  variant="outlined"
                  sx={{
                    mr: 2
                  }}>
                  Stäng
                </Button>
              </Grid>

              <DialogContent>
                <img
                  style={{
                    maxWidth: "100%"
                  }}
                  src={`data:image/jpeg;base64,${Data}`}
                  alt={`${fileName}`}
                  onClick={() => closeModal()}
                />
              </DialogContent>
            </MaterialDialog>
          </Modal>
        </>
      )}
    </>
  )
}

export default FormPreviewFileThumbnail
