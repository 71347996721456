import {
  Box,
  Card,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React from "react"
import Skeleton from "@mui/material/Skeleton"
import { SkeletonCard } from "components/skeleton/SkeletonCard"

const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const SkeletonPage = () => {
  return (
    <Container>
      <Grid container>
        <Grid md={4} xs={12} marginY={3}>
          <Typography color="textSecondary">
            <Skeleton animation="wave" />
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} mb={3}>
        <Grid item xs={12} md={6} lg={4}>
          <SkeletonCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SkeletonCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SkeletonCard />
        </Grid>
      </Grid>
      <Grid mb={3} item xs={12}>
        <SkeletonCard />
      </Grid>

      <Card>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {rows.map(() => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography color="textSecondary">
                      <Skeleton animation="wave" />
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color="textSecondary">
                      <Skeleton animation="wave" />
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color="textSecondary">
                      <Skeleton animation="wave" />
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color="textSecondary">
                      <Skeleton animation="wave" />
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color="textSecondary">
                      <Skeleton animation="wave" />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  )
}

export default SkeletonPage
