import { faCalendarCheck, faCalendarClock, faCalendarRange } from "@fortawesome/pro-solid-svg-icons"
import {
  Box,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@mui/material"
import { useApiGet, useApiPost } from "auth/useApi2"
import BplTokenLink from "components/BplTokenLink"
import Button from "components/Button"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import ActionCardDetailText from "./components/ActionCardDetailText"
import ActionCardDetails from "./components/ActionCardDetails"
import { ActionCardLoading } from "./components/ActionCardLoading"
import { AssignmentListItem } from "./components/AssignmentListItem"
import { AssignmentListLoading } from "./components/AssignmentListLoading"

const AssignmentButton = ({ currentAssignmentResult, toggleDialogAssignment }) => {
  if (currentAssignmentResult && currentAssignmentResult.data) {
    if (currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad) {
      return (
        <Button onClick={toggleDialogAssignment} color="primary" variant="contained">
          Bekräfta
        </Button>
      )
    } else if (currentAssignmentResult.data?.canBekrafta && currentAssignmentResult.data?.bekraftad) {
      return (
        <Button onClick={toggleDialogAssignment} color="error" variant="contained">
          Ångra bekräftad
        </Button>
      )
    } else {
      return (
        <Typography fontWeight={600}>
          {currentAssignmentResult.data.bekraftad ? "Bekräftad" : null}
        </Typography>
      )
    }
  }
  return null
}

const Overview = () => {
  const globalClasses = globalUseStyles()
  const loadingContext = useContext(GlobalLoaderContext)
  const [currentAssignmentResult, getCurrentAssignment] = useApiGet()
  const [timereportResult, getTimereport] = useApiGet()
  const [assignmentResult, getAssignment] = useApiGet()
  const [updateAssignmentResult, acceptAssignmentFunc] = useApiPost()
  const [updateTimeReportResult, acceptTimeReportFunc] = useApiPost()

  const [currentDate, setDate] = useState(moment().format("YYYY-MM-DD"))

  const [updatedAssignment, setUpdatedAssignment] = useState()

  const [updatedTimeReport, setUpdatedTimeReport] = useState()

  useEffect(() => {
    getAssignment(apiEndpoints.planning.getassignment.replace("{date}", currentDate))
  }, [getAssignment, currentDate])

  useEffect(() => {
    getCurrentAssignment(apiEndpoints.planning.getcurrentassignment)
  }, [getCurrentAssignment])

  useEffect(() => {
    getTimereport(apiEndpoints.planning.getcurrenttimereport)
  }, [getTimereport])

  useEffect(() => {
    loadingContext.setLoading(() => assignmentResult.status === 1)
    loadingContext.setLoading(() => currentAssignmentResult.status === 1)
  })

  useEffect(() => {
    if (updateTimeReportResult.status === 2) {
      getTimereport(apiEndpoints.planning.getcurrenttimereport)
      toggleDialogTimeReport()
    }
  }, [updateTimeReportResult.status])

  useEffect(() => {
    if (updateAssignmentResult.status === 2) {
      getCurrentAssignment(apiEndpoints.planning.getcurrentassignment)
      toggleDialogAssignment()
    }
  }, [updateAssignmentResult.status])

  useEffect(() => {
    setUpdatedAssignment(moment().format("YYYY-MM-DD HH:mm"))
  }, [currentAssignmentResult])

  useEffect(() => {
    setUpdatedTimeReport(moment().format("YYYY-MM-DD HH:mm"))
  }, [timereportResult])

  const acceptAssignment = () => {
    const values = {
      assignmentId: currentAssignmentResult?.data?.passStartSchemapostId,
      undo: currentAssignmentResult?.data?.bekraftad
    }
    acceptAssignmentFunc(apiEndpoints.planning.acceptassignment, values)
  }

  const [dialogAssignmentOpen, setDialogAssignmentOpen] = useState(false)

  const toggleDialogAssignment = () => {
    setDialogAssignmentOpen(!dialogAssignmentOpen)
  }

  const acceptTimeReport = () => {
    const values = {
      timeReportId: timereportResult?.data?.id,
      undo: timereportResult?.data?.isSubmitted
    }
    acceptTimeReportFunc(apiEndpoints.planning.accepttidrapport, values)
  }

  const [dialogTimeReportOpen, setDialogTimeReportOpen] = useState(false)

  const toggleDialogTimeReport = () => {
    setDialogTimeReportOpen(!dialogTimeReportOpen)
  }

  return (
    <Box mb={4}>
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Översikt</Typography>
          </Grid>
        </Box>

        <Grid container direction="row" justifyContent="space-between" spacing={3}>
          <Grid item md={8}>
            <PaperBox sx={{ marginTop: "0" }}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <Typography variant="h5" className={globalClasses.margin0}>
                    Mina uppdrag
                  </Typography>
                </Grid>
              </Grid>
              <Box marginTop={2}>
                <Divider light />
              </Box>

              {assignmentResult.status === 2 ? (
                assignmentResult.data.map((assignment, i) => {
                  const len = assignmentResult.data.length
                  const newDay = !moment(moment.utc(assignment.passStarttid).format("YYYY-MM-DD")).isSame(
                    moment.utc(assignmentResult.data[(i + len - 1) % len]?.passStarttid).format("YYYY-MM-DD")
                  )
                  return (
                    <>
                      {newDay && (
                        <Box marginTop={1}>
                          <Chip
                            color="primary"
                            className={globalClasses.backgroundPrimary}
                            label={moment.utc(assignment.passStarttid).format("dddd DD/MM")}
                          />
                        </Box>
                      )}
                      <AssignmentListItem assignment={assignment} key={i} />
                      {i !== assignmentResult.data.length - 1 && <Divider light />}
                    </>
                  )
                })
              ) : (
                <AssignmentListLoading />
              )}
              {assignmentResult.status === 2 && assignmentResult.data?.length < 0 && (
                <Typography>Du har inget aktuellt uppdrag just nu</Typography>
              )}
            </PaperBox>
          </Grid>
          <Grid item md={4}>
            {currentAssignmentResult.status === 2 ? (
              <ActionCardDetails
                bgClassName={
                  currentAssignmentResult.data.bekraftad === true
                    ? globalClasses.backgroundSuccess
                    : globalClasses.backgroundPrimary
                }
                sx={{ marginTop: "0" }}
                title="Aktuellt uppdrag"
                icon={currentAssignmentResult.data?.bekraftad === true ? faCalendarCheck : faCalendarRange}
                approved={currentAssignmentResult.data?.bekraftad}
                heading={
                  currentAssignmentResult &&
                  currentAssignmentResult.data?.beskrivning &&
                  currentAssignmentResult.data?.beskrivning
                }
                subText={
                  currentAssignmentResult && currentAssignmentResult.data?.stationsort
                    ? currentAssignmentResult.data?.stationsort
                    : "Du har inget aktuellt uppdrag just nu"
                }
                result={updateAssignmentResult}
                details={
                  currentAssignmentResult.status === 2 &&
                  currentAssignmentResult.data !== null && (
                    <Grid container direction="row">
                      <ActionCardDetailText
                        title="Datum"
                        data={moment.utc(currentAssignmentResult.data.passStarttid).format("YYYY-MM-DD")}
                      />
                      <ActionCardDetailText
                        title="Starttid"
                        data={
                          currentAssignmentResult.data.starttidsträng
                            ? currentAssignmentResult.data.starttidsträng
                            : null
                        }
                      />

                      <ActionCardDetailText title="Fordon" data={currentAssignmentResult.data.fordonStr} />
                    </Grid>
                  )
                }
                footer={
                  <>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={3}>
                      <Grid item>
                        <BplTokenLink
                          label="Förartavla"
                          to="client/forartavla"
                          variant="outlined"
                          color="primary"
                          sx={{ backgroundColor: "#fff" }}
                        />
                      </Grid>
                      <Grid item>
                        <AssignmentButton
                          currentAssignmentResult={currentAssignmentResult}
                          toggleDialogAssignment={toggleDialogAssignment}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item>
                        <Typography
                          variant="caption"
                          className={globalClasses.margin0}
                        >
                          Uppdaterad: {updatedAssignment}
                        </Typography>
                      </Grid>
                    </Grid> */}
                  </>
                }></ActionCardDetails>
            ) : (
              <ActionCardLoading sx={{ marginTop: "0" }} />
            )}
            {timereportResult.status === 2 ? (
              <ActionCardDetails
                title="Tidrapport"
                icon={faCalendarClock}
                heading={
                  timereportResult.data.passCount > 0 ? `${timereportResult.data?.passCount} Pass` : null
                }
                subText={
                  timereportResult.data.passCount > 0
                    ? `Period till ${
                        timereportResult.data?.period &&
                        moment(timereportResult.data.period).format("MMMM YYYY")
                      }`
                    : "Du har inget aktuell pass just nu"
                }
                result={updateTimeReportResult}
                chipText={timereportResult.data?.status}
                footer={
                  <>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={3}>
                      <Grid item>
                        <BplTokenLink
                          label="Tidrapport"
                          to="Client/Tidrapport/"
                          variant="outlined"
                          color="primary"
                        />
                      </Grid>
                      <Grid item>
                        {timereportResult &&
                        timereportResult.data &&
                        (!timereportResult.data?.isApproved || timereportResult.data?.isRevertable) ? (
                          <Button onClick={toggleDialogTimeReport} color="primary">
                            {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                              ? "Lämna in " + timereportResult.data.passCount + " pass"
                              : "Ångra inlämning"}
                          </Button>
                        ) : (
                          <Typography>Inlämnad</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </>
                }
              />
            ) : (
              <ActionCardLoading />
            )}
          </Grid>
        </Grid>
        <Dialog
          open={dialogTimeReportOpen}
          onClose={toggleDialogTimeReport}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
              ? "Vill du lämna in tidrapport?"
              : "Är du säker att du vill återställa tidrapport?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                ? "Vill du lämna in tidrapporten för godkännande?"
                : "Vill du återställa tidrapporten till aktiv status?"}{" "}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogTimeReport} variant="outlined">
              Avbryt
            </Button>
            <Button result={updateTimeReportResult} onClick={acceptTimeReport} color="primary" autoFocus>
              {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                ? "Lämna in tidrapport"
                : "Ångra tidrapport"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogAssignmentOpen}
          onClose={toggleDialogAssignment}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
              ? "Bekräfta uppdrag"
              : "Återställ uppdrag"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
                ? "Vill du bekräfta uppdrag?"
                : "Vill du återställa uppdrag?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogAssignment} variant="outlined">
              Avbryt
            </Button>
            <Button result={updateAssignmentResult} onClick={acceptAssignment} color="primary" autoFocus>
              {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
                ? "Bekräfta"
                : "Ångra bekräfta"}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  )
}

export default Overview
