import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, FormControl, Grid, NativeSelect } from "@mui/material"
import OutlinedInput from "@mui/material/OutlinedInput"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { MapContext } from "../MapContextProvider"
import { useStyles } from "./style"

const StartTime = () => {
  const { startDate, setStartDate } = useContext(MapContext)

  const setDate = (selectedStartDate: any) => {
    const selectedDate = moment(selectedStartDate)
    setStartDate(selectedDate)
  }

  return (
    <>
      <DateTimePicker
        value={startDate ? moment(startDate) : null}
        onChange={(newValue) => {
          setDate(newValue)
        }}
      />
    </>
  )
}
const EndTime = () => {
  const { startDate, endDate, setEndDate, hourList, resetEndDate } = useContext(MapContext)

  const setDate = (selectedEndDate: any) => {
    setEndDate(moment(selectedEndDate.target.value))
  }
  const classes = useStyles()
  const [updateButton, setUpdateButton] = useState(false)
  useEffect(() => {
    setUpdateButton(moment(startDate).isBetween(moment().add(-1, "day").format("YYYY-MM-DD HH:mm"), moment()))
  }, [startDate])

  return (
    <div className={classes.endDate}>
      <FormControl size={window.screen.width <= 480 ? "small" : "medium"}>
        <NativeSelect
          sx={{ mr: "16px" }}
          input={<OutlinedInput />}
          value={moment(endDate).format("YYYY-MM-DD HH:mm")}
          inputProps={{
            name: "endDate",
            id: "uncontrolled-native"
          }}
          onChange={(newValue: any) => {
            setDate(newValue)
          }}
          IconComponent={(_props) => <FontAwesomeIcon className={classes.icon} icon={faCaretDown} />}>
          {hourList.map((x: any, i: number) => {
            return (
              <option key={i} value={x.date}>
                {moment(x.date).isValid() && moment(x.date).format("YYYY-MM-DD HH:mm")} ({x.title})
              </option>
            )
          })}
        </NativeSelect>
      </FormControl>
      {updateButton && (
        <FontAwesomeIcon
          icon={faArrowsRotate}
          onClick={resetEndDate}
          size="lg"
          className={classes.updateIcon}
        />
      )}
    </div>
  )
}

const DateRangePickerTimeline = () => {
  const { activeMarker } = useContext(MapContext)
  return activeMarker ? (
    <>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <Box sx={{ fontWeight: 600 }}> Visar historik från:</Box>{" "}
        </Grid>

        <Grid item xs={12} md={4}>
          <StartTime />
        </Grid>
        <Grid item>
          <Box sx={{ fontWeight: 600 }}> Till:</Box>{" "}
        </Grid>
        <Grid item>
          <EndTime />
        </Grid>
      </Grid>
    </>
  ) : null
}

export default DateRangePickerTimeline
