import { Box, Container, Grid, Typography } from "@mui/material"
import { useApiGet, useApiPut } from "auth/useApi2"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { PageTabs } from "components/navigation/PageTabs"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import { useContext, useEffect } from "react"
import { DeviceForm } from "../../components/DeviceForm"

const DeviceRegisterEdit = ({ match }) => {
  const [deviceResult, getDevice] = useApiGet()
  const [editDeviceResult, editDevice] = useApiPut()
  const [companyResult, getCompany] = useApiGet()
  const [equipmentTypesResult, getEquipmentTypes] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    getDevice(apiEndpoints.systemadmindeviceregistry.getdevicebyid.replace("{id}", match.params.id))
    getCompany(apiEndpoints.systemadmindeviceregistry.listcompanies)
    getEquipmentTypes(apiEndpoints.systemadmindeviceregistry.listequipmenttypes)
  }, [getDevice, match.params.id, getCompany, getEquipmentTypes])

  const handleSubmit = (objectToSave) => {
    editDevice(apiEndpoints.systemadmindeviceregistry.updatedevice, objectToSave)
  }

  useEffect(() => {
    loadingContext.setLoading(() => companyResult.status === 1)
  })

  useEffect(() => {
    loadingContext.setLoading(() => equipmentTypesResult.status === 1)
  })

  useEffect(() => {
    loadingContext.setLoading(() => equipmentTypesResult.status === 1)
  })

  const feedback = {
    errorTitle: "Enhet kunde inte uppdateras"
  }

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.SystemadminDevicesRegister(),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        <Box marginY={3}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Typography variant="h2">Redigera enhet</Typography>
          </Grid>
        </Box>

        {companyResult.status === 2 && equipmentTypesResult.status === 2 && deviceResult.status === 2 && (
          <DeviceForm
            companyResult={companyResult}
            equipmentTypesResult={equipmentTypesResult}
            onSubmit={handleSubmit}
            feedback={feedback}
            editDevice={deviceResult.data}
            result={editDeviceResult}
          />
        )}
      </Container>
    </div>
  )
}

export default DeviceRegisterEdit
