import { Box, Container, Grid, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useContext, useEffect, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"

const EditCustomerCompanies = () => {
  const globalClasses = globalUseStyles()
  const routerHistory = useHistory()

  const [customerResult, getCustomer] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    getCustomer(apiEndpoints.customeradmin.getcustomer)
  }, [getCustomer])

  useEffect(() => {
    loadingContext.setLoading(() => customerResult.status === 1)
  })

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        numeric: false,
        Header: "Namn"
      },
      {
        accessor: "organisationNumber",
        numeric: true,
        disablePadding: false,
        Header: "Orgnr"
      },
      {
        accessor: "streetAddress",
        numeric: false,
        disablePadding: false,
        Header: "Postadress"
      },
      {
        accessor: "postalCode",
        numeric: true,
        disablePadding: false,
        Header: "Postnummer"
      },
      {
        accessor: "cityAddress",
        numeric: false,
        disablePadding: false,
        Header: "Ort",
        Cell: ({ cell }) => {
          return (
            <div component={Link} to={allRoutes.CustomerSettingsCompaniesEdit(cell.row.original.id)}>
              {cell.row.original.cityAddress}
            </div>
          )
        }
      }
    ],
    []
  )

  return (
    <div>
      <Container>
        <Box mb={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Typography variant="h1">Bolag</Typography>
            <Button color="primary" component={Link} to={allRoutes.CustomerSettingsCompaniesAdd()}>
              Lägg till Bolag
            </Button>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rowProps={(row) => ({
            onClick: () => routerHistory.push(allRoutes.CustomerSettingsCompaniesEdit(row.original.id))
          })}
          rows={customerResult.status === 2 && customerResult.data.companies}
          status={customerResult.status}
        />
      </Box>
    </div>
  )
}
export default EditCustomerCompanies
