import { faCloudUpload, faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import theme from "css/theme/light"
import { t } from "i18next"
import React, { useMemo } from "react"
import { useDropzone } from "react-dropzone"

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: "dashed"
  },
  label: {
    color: theme.palette.primary.main,
    top: "-11px",
    left: "9px",
    padding: "0 5px",
    background: theme.palette.background.paper,
    position: "absolute"
  },
  margin: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "8px 0",
    padding: 15
  },
  img: {
    display: "block",
    maxWidth: "100%",
    height: "auto"
  }
}))

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px",
  backgroundColor: theme.palette.primary["light"],
  color: theme.palette.primary["main"],
  outline: "none",
  transition: "background .24s ease-in-out",
  cursor: "pointer",
  margin: "0 15px",
  borderRadius: 4,
  textAlign: "center"
}

const activeStyle = {
  backgroundColor: "rgba(233,233,233, 1)"
}

const acceptStyle = {
  backgroundColor: "rgba(233,233,233, 1)"
}

const rejectStyle = {
  borderColor: "#ff1744"
}

const MultipleFilePicker = ({ setFiles, isLoading }) => {
  const classes = useStyles()

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    multiple: true,
    paramName: () => "files",
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles)
    }
  })
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <>
      <Box
        className={classes.container}
        bgcolor="background.light"
        border={1}
        borderColor="primary.main"
        borderRadius="4px">
        <Box spacing={3} py={2}>
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <FontAwesomeIcon spin={isLoading} icon={isLoading ? faSpinnerThird : faCloudUpload} size="3x" />
            <Typography marginTop={2}>
              {isLoading ? t(`global.form.file_picker.loading`) : t(`global.form.file_picker.label`)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default MultipleFilePicker
