import React from "react";

import { Grid, Container, Box, Typography, List, ListItem } from "@mui/material";
import { SimpleUploadFile } from "./components/SimpleUploadFile";
import { PageTabs } from "components/navigation/PageTabs";
import { allRoutes } from "generated/routes";
import PaperBox from "components/PaperBox";
import ImportResult from "./components/ImportResult";

const ImportUsers = ({ match }) => {
    const [importResult, setImportResult] = React.useState();
    return (
        <div>
            <PageTabs
                routeBack
                tabs={[
                    {
                        path: allRoutes.SystemadminCustomersEditUsers(match.params.id),
                        title: "Tillbaka"
                    }
                ]}
            />
            <Container>
                <Box marginY={3}>
                    <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h2">Importera användare</Typography>
                    </Grid>
                </Box>
                <PaperBox>
                    <Box mb={2}>
                        <Typography>
                            Importera användare genom att ladda upp en fil i CSV-format. Avskiljare ska vara semikolon (;) och fält med listvärden ska separeras
                            med kommatecken (,)
                        </Typography>
                        <Box mb={2}></Box>
                        <Typography>Filen får inte använda rubriker på kolumnerna utan endast innehålla data.</Typography>
                        <Box mb={2}></Box>
                        <Typography>Formatet på varje rad måste innehålla följande kolumner:</Typography>
                        <List>
                            <ListItem>Namn - Obligatoriskt, användarens fullständiga namn</ListItem>
                            <ListItem>Användarnamn - Obligatoriskt, måste vara unikt</ListItem>
                            <ListItem>Epost - Obligatoriskt om SkickaLoginuppgifter=Ja, måste vara unikt</ListItem>
                            <ListItem>SkickaLoginuppgifter - Ja/Nej</ListItem>
                            <ListItem>Bolag - Lista på bolagsnamn, bolagen måste finnas inlagda på kund</ListItem>
                            <ListItem>Avdelningar - Lista på avdelningsnamn, Avdelningarna måste finnas inlagda på kund</ListItem>
                            <ListItem>
                                Licenser - Namn på licenser, Tillhörande modul måste vara aktiverade på kund och ha tillräckligt med licenser kvar. Accepterade
                                ord: Kundinställningar, Nyheter, Utbildning, SystemAdminKunder, SystemAdminEnhetsregister, SystemAdminEvents
                            </ListItem>
                            <ListItem>
                                Roller - Utökade behörigheter, Tillhörande modul måste vara aktiverade på kund och även finnas med i licenslistan. Accepterade
                                ord: Utbildningsadministratör, Nyhetsadministratör
                            </ListItem>
                            <ListItem>
                                Utbildningar - Lista på utbildningsnamn. Utbildningsmodulen måste vara aktiverad på kund, och utbildningslicens måste också
                                finnas med i licenslistan.
                            </ListItem>
                            <ListItem>Närmsta Chef - Användarnamnet på den användare som ska sättas som närmsta chef</ListItem>
                        </List>
                        <Box mb={2}></Box>
                        <Typography>
                            Om någon rad har valideringsfel importeras inga rader. Korrigera isåfall den felande raden och ladda upp filen på nytt.
                        </Typography>
                        <Box mb={2}></Box>
                    </Box>
                    <SimpleUploadFile setImportResult={setImportResult} />
                    {importResult && <ImportResult items={importResult} />}
                </PaperBox>
            </Container>
        </div>
    );
};
export default ImportUsers;
