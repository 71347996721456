import { Button, ButtonGroup } from "@mui/material";
import React, { useState } from "react";

import { IDevice } from "../../devices.interface";
import { useStyles } from "./styles";

type SearchProps = {
    showItemByFilter: (filter: number) => void;
    search: (str: string) => void;
    devices: IDevice[];
};

const FilterSearch = ({ showItemByFilter, search, devices }: SearchProps) => {
    const classes = useStyles();

    const [selectedFilter, setSelectedFilter] = useState(0);

    const filterSelect = (filter: number) => {
        setSelectedFilter(filter);
        showItemByFilter(filter);
    };

    return (
        <div className={classes.filterWrap}>
            <ButtonGroup>
                <Button className={selectedFilter === 0 ? "primary" : "inherit"} onClick={() => filterSelect(0)} variant="contained">
                    {`Alla (${devices.length})`}
                </Button>
                <Button className={selectedFilter === 1 ? "primary" : "inherit"} onClick={() => filterSelect(1)} variant="contained">
                    {`Online (${devices.filter((device: IDevice) => device.status === 1 || device.status === 2).length})`}
                </Button>
                <Button className={selectedFilter === 3 ? "primary" : "inherit"} onClick={() => filterSelect(3)} variant="contained">
                    {`Offline (${devices.filter((device: IDevice) => device.status === 3).length})`}
                </Button>
            </ButtonGroup>

            <input placeholder={"Sök..."} minLength={3} className={classes.searchField} onChange={(e) => search(e.target.value)} />
        </div>
    );
};

export default FilterSearch;
