import { Box, Container, Grid, Typography } from "@mui/material"

import makeStyles from "@mui/styles/makeStyles"
import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import ContactInfoCard from "components/ContactInfoCard/ContactInfoCard"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { SkeletonCardImage } from "components/skeleton/SkeletonCardImage"
import { apiEndpoints } from "generated/apiEndpoints"
import React from "react"
import { useTranslation } from "react-i18next"
import { ExaminationInfoCard } from "./components/ExaminationInfoCard"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  navLink: {
    textDecoration: "none",
    color: theme.palette.foreground.default
  }
}))

const LearningDashboard = () => {
  const { t, i18n } = useTranslation()
  const translationAssigned = t("learning.overview.assigned", { returnObjects: true })
  const courseDetails = t("learning.details.course", { returnObjects: true })

  const classes = useStyles()

  const loadingContext = React.useContext(GlobalLoaderContext)

  const [assignedExams, assignedExamsFuncs] = useApi()
  const [passedExamsResult, passedExamsFuncs] = useApi()
  const [managerResult, managerFuncs] = useApi()

  React.useEffect(() => {
    assignedExamsFuncs.get(apiEndpoints.learning.getassignedexaminations)
    passedExamsFuncs.get(apiEndpoints.learning.getpassedresultsshortlist)
    managerFuncs.get(apiEndpoints.learning.getmanager)
    return () => {
      assignedExamsFuncs.leave()
      passedExamsFuncs.leave()
      managerFuncs.leave()
    }
  }, [assignedExamsFuncs, passedExamsFuncs, managerFuncs])

  React.useEffect(() => {
    loadingContext.setLoading(() => assignedExams.status === 1)
  })
  React.useEffect(() => {
    loadingContext.setLoading(() => passedExamsResult.status === 1)
  })

  return (
    <Container>
      <Grid container direction="row" spacing={5}>
        <Grid item md={8}>
          <Typography variant="h2" gutterBottom marginBottom={2}>
            {t("learning.overview.header")}
          </Typography>
          <Grid container direction="row" spacing={3}>
            {assignedExams.status === 2 &&
              assignedExams.data.map((item, key) => {
                let assignedCourse = translationAssigned.find(
                  (course) => course.examinationType === item.examinationType
                )
                return (
                  <Grid item xs={12} key={key}>
                    <ExaminationInfoCard assignedCourse={assignedCourse} item={item} showDescription={true} />
                  </Grid>
                )
              })}
          </Grid>

          {assignedExams.status === 1 && (
            <Grid container direction="row" spacing={3} marginTop={1}>
              {Array.from(new Array(2)).map((item) => (
                <Grid item xs={12} key={item}>
                  <SkeletonCardImage />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid item md={4} xs={12}>
          {managerResult.status === 2 && managerResult.data !== null && managerResult.data !== "" && (
            <>
              <Typography variant="h2" marginBottom={2}>
                {t("settings.user.manager")}
              </Typography>
              <ContactInfoCard data={managerResult.data} />
            </>
          )}
          {managerResult.status === 1 && (
            <Box marginTop={6}>
              <SkeletonCard />
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
export default LearningDashboard
