import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { faCircle } from "@fortawesome/pro-light-svg-icons"
import { Grid, TextField, Typography } from "@mui/material"
import TodoListCheckbox from "components/dataDisplay/TodoList/TodoListCheckbox"
import TodoListReadMore from "components/dataDisplay/TodoList/TodoListReadMore"
import TodoListRow from "components/dataDisplay/TodoList/TodoListRow"
import { useStyles } from "../../../components/useStyles"

const Checkboxes = ({ values, updateFieldArray, index }) => {
  switch (values.status) {
    case 1:
      return (
        <TodoListCheckbox
          color="success"
          icon={faCircle}
          status={values.status}
          updateFieldArray={updateFieldArray}
          checked={values.issueReports[index]?.added}
        />
      )
    case 2:
      return null
    case 3:
      return null
    case 4:
      return (
        <TodoListCheckbox
          disabled={true}
          icon={faCircleExclamation}
          status={values.status}
          updateFieldArray={updateFieldArray}
          checked={values.issueReports[index]?.status === 4 || values.issueReports[index]?.status === 5}
        />
      )
    case 5:
      return (
        <TodoListCheckbox
          disabled={true}
          icon={faCircleExclamation}
          status={values.status}
          updateFieldArray={updateFieldArray}
          checked={values.issueReports[index]?.status === 4 || values.issueReports[index]?.status === 5}
        />
      )
    case 6:
      return null
    default:
      return null
  }
}

const WordOrderCardReportList = ({
  setFieldValue,
  values,
  issueReport,
  index,
  handleChange,
  handleBlur,
  touched,
  errors
}) => {
  const classes = useStyles()

  const handleInputChange = (event) => {
    const newIssueReports = [...values.issueReports]
    newIssueReports[index].notice = event.target.value
    setFieldValue("issueReports", newIssueReports)
  }

  const updateFieldArray = (val) => {
    const newArray = [...values.issueReports]
    for (var i = 0; i < newArray.length; i++) {
      if (newArray[i].issueReportId === issueReport.issueReportId) {
        newArray[i].added = val
      }
    }
    setFieldValue("issueReports", newArray)
  }

  return (
    <>
      <TodoListRow
        id={issueReport.id}
        control={<Checkboxes values={values} updateFieldArray={updateFieldArray} index={index} />}>
        <TodoListReadMore
          description={issueReport.description}
          categoryName={issueReport.categoryName}
          id={issueReport.id}>
          {issueReport.notice ? (
            <>
              <Typography variant="h5">Notering</Typography>
              {values.status !== 1 ? (
                <Typography>{issueReport.notice}</Typography>
              ) : (
                <>
                  <TextField
                    p={2}
                    placeholder="Notering om felrapport..."
                    rows={2}
                    multiline
                    autoComplete="off"
                    className={classes.textField}
                    fullWidth
                    id={"notice"}
                    value={values.issueReports[index].notice}
                    onChange={handleInputChange}
                    error={touched.notice && errors.notice ? true : false}
                    helperText={touched.notice && errors.notice ? errors.notice : null}
                    variant="outlined"
                    InputProps={{ classes: { input: classes.formAreaInput }, disableUnderline: true }}
                    mt={1}
                  />
                </>
              )}
            </>
          ) : null}
        </TodoListReadMore>
      </TodoListRow>
      <Grid item xs={4}></Grid>
    </>
  )
}

export default WordOrderCardReportList
