import { Button, Grid, Typography } from "@mui/material"
import { Formik } from "formik"

import ApiButton from "components/Button"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { t as globalT } from "i18next"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { CustomerAdminInformation } from "./CustomerAdminInformation"
import CustomerCompanyInformation from "./CustomerCompanyInformation"
import { CustomerInformation } from "./CustomerInformation"
import CustomerModules from "./CustomerModules"
import { CustomerStepper } from "./CustomerStepper"
import { step1, step2, step3, step4 } from "./validationSchema"

export const CreateCustomerStepperForm = ({
  saveResult,
  feedback,
  handleSubmit,
  steps,
  activeStep,
  setActiveStep,
  customerResult
}) => {
  const formRef = useRef()

  const validationSteps = [step1, step2, step3, step4]
  const { t } = useTranslation("translation", { keyPrefix: "settings.wizard" })
  const handleNext = () => {
    if (activeStep === 0) {
      formRef.current.setFieldValue(`companies.${0}.name`, formRef.current.values.name)
      formRef.current.setFieldValue(
        `companies.${0}.organisationNumber`,
        formRef.current.values.organisationNumber
      )
      formRef.current.setFieldValue(`companies.${0}.streetAddress`, formRef.current.values.streetAddress)
      formRef.current.setFieldValue(`companies.${0}.postalCode`, formRef.current.values.postalCode)
      formRef.current.setFieldValue(`companies.${0}.cityAddress`, formRef.current.values.cityAddress)
      formRef.current.setFieldValue(`companies.${0}.hasVtrIntegration`, false)
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    if (activeStep === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    if (activeStep === 3) {
      const formValues = {
        ...formRef.current.values,
        adminUser: {
          ...formRef.current.values.adminUser,
          password: window.btoa(formRef.current.values.adminUser.password)
        }
      }
      handleSubmit(formValues)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return customerResult ? (
    <>
      <Formik
        innerRef={formRef}
        onSubmit={() => handleNext()}
        initialValues={customerResult}
        validationSchema={validationSteps[activeStep]}
        validateOnChange={false}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <CustomerStepper
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              steps={steps}
              result={saveResult}
              feedback={feedback}>
              {activeStep === 0 && (
                <Grid container justifyContent="center">
                  <Grid item xs md={8} lg={8} xl={6}>
                    <CustomerInformation
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
              )}
              {activeStep === 1 && (
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={10} lg={8} xl={6}>
                    <CustomerModules
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
              )}
              {activeStep === 2 && (
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={8} xl={6}>
                    <Grid item md={12} xs={12} sx={{ m: "auto", mb: "56px" }}>
                      <Typography variant="bodyMedium" textAlign="center">
                        {t(`description.step3`)}
                      </Typography>
                    </Grid>
                    <CustomerCompanyInformation
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
              )}
              {activeStep === 3 && (
                <Grid container alignItems="center" flexDirection="column">
                  <Grid item md={8} xs={12} sx={{ m: "auto", mb: "56px" }}>
                    <Typography variant="bodyMedium" textAlign="center">
                      {t(`description.step4`)}
                    </Typography>
                  </Grid>
                  <Grid item xs md={8} lg={6}>
                    <CustomerAdminInformation
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                  </Grid>
                </Grid>
              )}
            </CustomerStepper>
            <Grid container gap={2} justifyContent="center" mt={5} mb={5}>
              <Grid item>
                <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0}>
                  {globalT(`global.buttons.previous`)}
                </Button>
              </Grid>
              <Grid item>
                <ApiButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  result={saveResult}
                  feedback={feedback}>
                  {activeStep === steps.length - 1
                    ? globalT(`global.buttons.complete`)
                    : globalT(`global.buttons.next`)}
                </ApiButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  ) : (
    <SkeletonCard />
  )
}
