import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Grid, IconButton, TextField, Typography } from "@mui/material"
import { customerHasFeature } from "auth/customerHasFeature"
import { hasRole } from "auth/hasRole"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { SwitchColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import PaperBox from "components/PaperBox"
import { TabPanel } from "components/navigation/TabPanel"
import { Formik } from "formik"
import { allFeatures } from "generated/features"
import { allRoles } from "generated/roles"
import { departmentsWithListId } from "pages/customer/pages/settings/pages/companies/components/CompanyForm"
import VtrSwitch from "pages/customer/pages/settings/pages/companies/components/VtrSwitch"
import { companyValidationSchema } from "pages/systemadmin/pages/customers/components/validationSchema"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import BplIntegrationSwitch from "./BplIntegrationSwitch"
import DepartmentForm from "./DepartmentForm"
import DepartmentsList from "./DepartmentsList"
import ViolationSwitch from "./ViolationSwitch"
import { useStyles } from "./useStyles"

function companyInitialValues(company) {
  if (company) {
    company.bplIntegrationActive = company?.bplUrl !== null
  }
  return company
    ? company
    : {
      id: 0,
      name: "",
      organisationNumber: "",
      streetAddress: "",
      postalCode: "",
      cityAddress: "",
      hasVtrIntegration: false,
      bplIntegrationActive: false
    }
}

export const CompanyForm = ({
  customer,
  onSubmit,
  editCompany,
  result,
  openRemoveDialog,
  tabValue,
  workshopsResult,
  showSidebar,
  setShowSidebar,
  setCurrentWorkshop
}) => {
  const appContext = useContext(AppContext)
  const classes = useStyles()
  const formRef = useRef()

  const [departments, setDepartments] = useState(
    editCompany ? departmentsWithListId(editCompany.departments) : []
  )
  const editMode = editCompany ? true : false
  const initialValues = companyInitialValues(editCompany)

  const handleSubmit = async (values, { resetForm }) => {
    const customerId = customer.id
    const companyToCreate = {
      ...values,
      customerId: customerId,
      departments: departments
    }

    onSubmit(companyToCreate)
  }

  const workshopInitialFilters = [
    {
      id: "Aktiv",
      value: "Ja"
    }
  ]

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Verkstad"
      },
      {
        accessor: "emailAddress",
        Header: "E-postadress"
      },
      {
        accessor: (row) => {
          return row.isActive ? "Ja" : "Nej"
        },
        Header: "Aktiv",
        mainFilter: true,
        defaultValue: "Ja",
        Filter: SwitchColumnFilter,
        FilterOptions: ["Ja", "Nej"]
      }
    ],
    []
  )

  useEffect(() => {
    if (hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)) {
      columns.push({
        accessor: "edit",
        numeric: false,
        disablePadding: true,
        Header: "Hantera",
        align: "right",
        Cell: ({ cell }) => {
          return (
            <IconButton
              color="primary"
              size="large"
              onClick={() => {
                openSidebar(cell.row.original)
              }}>
              <FontAwesomeIcon icon={faEdit} size="xs" />
            </IconButton>
          )
        }
      })
    }
  }, [])

  function openSidebar(workshop) {
    setCurrentWorkshop(workshop)
    setShowSidebar(!showSidebar)
  }
  const [validationStep, setValidationStep] = useState(companyValidationSchema)

  return (
    <Formik
      innerRef={formRef}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationStep}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2}>
              <Grid item sm={8}>
                <PaperBox>
                  <Box mb={3}>
                    <Typography className={classes.boldText} variant="bodyMedium" color="textPrimary">
                      Bolagsinformation
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="name"
                        label="Namn"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && errors.name ? true : false}
                        helperText={touched.name && errors.name ? errors.name : null}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="organisationNumber"
                        label="Organisationsnummer"
                        value={values.organisationNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.organisationNumber && errors.organisationNumber ? true : false}
                        helperText={
                          touched.organisationNumber && errors.organisationNumber
                            ? errors.organisationNumber
                            : null
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="streetAddress"
                        label="Gatuaddress"
                        value={values.streetAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.streetAddress && errors.streetAddress ? true : false}
                        helperText={
                          touched.streetAddress && errors.streetAddress ? errors.streetAddress : null
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="postalCode"
                        label="Postnummer"
                        value={values.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.postalCode && errors.postalCode ? true : false}
                        helperText={touched.postalCode && errors.postalCode ? errors.postalCode : null}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="cityAddress"
                        label="Ort"
                        value={values.cityAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.cityAddress && errors.cityAddress ? true : false}
                        helperText={touched.cityAddress && errors.cityAddress ? errors.cityAddress : null}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent={editMode ? "space-between" : "flex-end"}>
                    {editMode && (
                      <Box my={3}>
                        <Button color="primary" variant="outlined" onClick={openRemoveDialog}>
                          Ta bort bolag
                        </Button>
                      </Box>
                    )}
                    <Box my={3} mx={1}>
                      <Button
                        result={result}
                        color="primary"
                        onClick={() => {
                          if (formRef.current) {
                            formRef.current.handleSubmit()
                          }
                        }}>
                        Spara
                      </Button>
                    </Box>
                  </Grid>
                </PaperBox>
              </Grid>
              <Grid item sm={4}>
                {customerHasFeature(appContext?.appUser?.customer, allFeatures.VehicleRegistry) && (
                  <VtrSwitch
                    img="assets/Transportstyrelsen-icon.jpg"
                    id={`hasVtrIntegration`}
                    title="Transportstyrelsen"
                    message="Ditt bolag har tillång att importera fordon från Transportstyrelsen"
                    value={values.hasVtrIntegration}
                    handleChange={() => {
                      setFieldValue(`hasVtrIntegration`, !values.hasVtrIntegration)
                    }}
                  />
                )}
                {customerHasFeature(appContext?.appUser?.customer, allFeatures.Planning) && (
                  <PaperBox>
                    <BplIntegrationSwitch
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      setValidationStep={setValidationStep}
                    />
                  </PaperBox>
                )}
                {customerHasFeature(appContext?.appUser?.customer, allFeatures.Violation) && (
                  <PaperBox>
                    <ViolationSwitch
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      editCompany={editCompany}
                      setValidationStep={setValidationStep}
                    />
                  </PaperBox>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <PaperBox>
                  <Box mb={3}>
                    <Typography className={classes.boldText} variant="bodyMedium" color="textPrimary">
                      Avdelningar
                    </Typography>
                  </Box>
                  <DepartmentForm
                    onSubmit={(item) => {
                      setDepartments((s) => {
                        return [
                          ...s,
                          {
                            ...item,
                            id: 0,
                            companyId: editMode ? editCompany.id : 0
                          }
                        ]
                      })
                    }}
                  />
                  <DepartmentsList
                    departments={departments}
                    removeDepartment={(item) => {
                      setDepartments((s) => {
                        return s.filter((x) => x.listId !== item.listId)
                      })
                    }}
                  />
                  <Grid container direction="row" justifyContent={editMode ? "space-between" : "flex-end"}>
                    {editMode && (
                      <Box my={3}>
                        <Button color="primary" variant="outlined" onClick={openRemoveDialog}>
                          Ta bort bolag
                        </Button>
                      </Box>
                    )}
                    <Box my={3} mx={1}>
                      <Button
                        result={result}
                        color="primary"
                        onClick={() => {
                          if (formRef.current) {
                            formRef.current.handleSubmit()
                          }
                        }}>
                        Spara
                      </Button>
                    </Box>
                  </Grid>
                </PaperBox>
              </Grid>
            </Grid>
          </TabPanel>
          {workshopsResult && (
            <TabPanel style={{ width: "80%" }} value={tabValue} index={2}>
              <PaperBox>
                <ClpTable
                  columns={columns}
                  rows={workshopsResult.status === 2 && workshopsResult.data}
                  status={workshopsResult.status}
                  initialFilters={workshopInitialFilters}
                />
              </PaperBox>
            </TabPanel>
          )}
        </form>
      )}
    </Formik>
  )
}
