import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { faCircle } from "@fortawesome/pro-light-svg-icons"
import TodoListCheckbox from "components/dataDisplay/TodoList/TodoListCheckbox"
import TodoListReadMore from "components/dataDisplay/TodoList/TodoListReadMore"
import TodoListRow from "components/dataDisplay/TodoList/TodoListRow"
import React from "react"

const Checkboxes = ({ values, updateFieldArray, index }) => {
  switch (values.status) {
    case 2:
      return null
    case 3:
      return (
        <TodoListCheckbox
          color="success"
          icon={faCircle}
          status={values.status}
          updateFieldArray={updateFieldArray}
          checked={values.issueReports[index]?.repaired}
        />
      )
    case 4:
      return (
        <TodoListCheckbox
          disabled={true}
          icon={faCircleExclamation}
          status={values.status}
          updateFieldArray={updateFieldArray}
          checked={values.issueReports[index]?.repaired}
        />
      )
    case 5:
      return (
        <TodoListCheckbox
          disabled={true}
          icon={faCircleExclamation}
          status={values.status}
          updateFieldArray={updateFieldArray}
          checked={values.issueReports[index]?.repaired}
        />
      )
    default:
      return null
  }
}

const ExternalIssueReports = (props) => {
  const { setFieldValue, values, issueReport, index } = props

  const updateFieldArray = (val) => {
    const newArray = [...values.issueReports]
    for (var i = 0; i < newArray.length; i++) {
      if (newArray[i].id === issueReport.id) {
        newArray[i].repaired = val
      }
    }
    setFieldValue("issueReports", newArray)
  }

  return (
    <TodoListRow
      id={issueReport.id}
      control={<Checkboxes values={values} updateFieldArray={updateFieldArray} index={index} />}>
      {
        <TodoListReadMore
          description={issueReport.description}
          categoryName={issueReport.categoryName}
          id={issueReport.id}
        />
      }
    </TodoListRow>
  )
}

export default ExternalIssueReports
