import { faEdit, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Chip, Container, Grid, IconButton, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import moment from "moment"
import { Link, useHistory } from "react-router-dom"

import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import IconForButton from "components/ButtonIcon"
import { useContext, useEffect } from "react"
import { deviationPriorityProps } from "../helpers/deviationPriority"
import { deviationStatusProps } from "../helpers/deviationStatus"

const DeviationList = () => {
  const history = useHistory()
  const globalClasses = globalUseStyles()
  const loadingContext = useContext(GlobalLoaderContext)

  const [deviationResult, deviationApi] = useApi() as [IApiResult, IApiFunc]

  useEffect(() => {
    deviationApi.get(apiEndpoints.deviation.get)
    return () => {
      deviationApi.leave()
    }
  }, [deviationApi])

  useEffect(() => {
    loadingContext.setLoading(() => deviationResult.status === 1)
  })

  const columns = [
    {
      accessor: "customerDeviationId",
      Header: "Id",
      Cell: ({ cell }: any) => {
        return <Typography>#{cell.row.original.customerDeviationId}</Typography>
      }
    },
    {
      accessor: "name",
      Header: "Avvikelse",
      mainFilter: true,
      Filter: SelectColumnFilter
    },
    {
      accessor: "status",
      Header: "Status",
      mainFilter: true,
      Filter: SelectColumnFilter,
      FilterOptions: [
        { name: "Ohanterad", value: "0" },
        { name: "Tilldelad", value: "1" },
        { name: "Hanterad", value: "2" }
      ],
      Cell: ({ cell }: any) => {
        const statusProps = deviationStatusProps(cell.row.original.status)
        return (
          <Box>
            <FontAwesomeIcon color={statusProps.color} icon={statusProps.icon as IconProp} />
            <Typography variant="labelMedium" marginLeft={1} color={statusProps.color}>
              {statusProps.title}
            </Typography>
          </Box>
        )
      }
    },
    {
      accessor: "priority",
      Cell: ({ cell }: any) => {
        const prioProps = deviationPriorityProps(cell.row.original.priority)
        return (
          <Chip
            color={prioProps?.color !== null ? prioProps?.color : "default"}
            variant="outlined"
            label={prioProps?.title}
          />
        )
      },
      Header: "Prioritet",
      mainFilter: true,
      Filter: SelectColumnFilter,
      FilterHeader: "Prio",
      align: "left",
      FilterOptions: [
        {
          name: "Ingen",
          value: 0
        },
        {
          name: "Låg",
          value: 1
        },
        {
          name: "Medel",
          value: 2
        },
        {
          name: "Hög",
          value: 3
        }
      ]
    },
    {
      accessor: "assignedUser",
      Header: "Tilldelad",
      Filter: SelectColumnFilter,
      mainFilter: true
    },
    {
      accessor: "createdTime",
      Header: "Skapad",
      Cell: ({ cell }: any) => {
        return cell.row.original.createdTime
          ? moment.utc(cell.row.original.createdTime).local().format("YYYY-MM-DD HH:mm")
          : "-"
      }
    },
    {
      accessor: "createdByName",
      Header: "Skapad av"
    },
    {
      accessor: "updatedTime",
      Header: "Hanterad",
      Cell: ({ cell }: any) => {
        return cell.row.original.updatedTime
          ? moment.utc(cell.row.original.updatedTime).local().format("YYYY-MM-DD HH:mm")
          : "-"
      }
    },
    {
      accessor: "updatedByName",
      Header: "Hanterad av"
    },
    {
      accessor: "edit",
      numeric: false,
      disablePadding: true,
      Header: "",
      align: "right",
      Cell: ({ cell }: any) => {
        return (
          <IconButton size="large" component={Link} to={allRoutes.DeviationListDetails(cell.row.original.id)}>
            <FontAwesomeIcon icon={faEdit as IconProp} size="xs" />
          </IconButton>
        )
      }
    }
  ]

  const listRows = deviationResult.status === 2 ? deviationResult.data : []
  return (
    <div>
      <Container>
        <Box marginY={3} mb={2}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box order={2} p={1} flexGrow={2}>
              <Grid item>
                <Typography variant="h2">Avvikelser</Typography>
              </Grid>
            </Box>
            <Box order={3} p={1}>
              <Button
                variant="contained"
                component={Link}
                to={allRoutes.DeviationListCreate()}
                endIcon={<IconForButton icon={faPlus as IconProp} color="primary" />}
                color="primary">
                Skapa avvikelse
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          cellProps={(row: any) => ({
            onClick: () => history.push(allRoutes.DeviationListDetails(row.original.id))
          })}
          columns={columns}
          rows={deviationResult.status === 2 && listRows}
          status={deviationResult.status}
        />
      </Box>
    </div>
  )
}

export default DeviationList
