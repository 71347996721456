import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Chip, IconButton, InputBase, Stack, Typography } from "@mui/material"
import React, { useState } from "react"

type SearchProps = {
  setRegnr: any
  clickedRegnr: any
}

const SearchBar = ({ setRegnr, clickedRegnr }: SearchProps) => {
  const [value, setValue] = useState<string>()

  const [type, setType] = useState<string>(clickedRegnr.type)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    value && setRegnr({ type: type, value: value })
  }

  return (
    <Box>
      <Box
        display="flex"
        sx={{ flexWrap: "wrap" }}
        alignItems="center"
        marginBottom={2}
        justifyContent="space-between">
        <Typography>Välj sökalternativ:</Typography>
        <Stack direction="row" spacing={1}>
          <Chip
            label="Regnr"
            color={type === "regNr" ? "primary" : "default"}
            variant={type !== "regNr" ? "outlined" : "filled"}
            onClick={() => setType("regNr")}
          />

          <Chip
            label="Internnr"
            color={type === "internNr" ? "primary" : "default"}
            variant={type !== "internNr" ? "outlined" : "filled"}
            onClick={() => setType("internNr")}
          />
        </Stack>
      </Box>
      <Box
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",

          border: "1px solid #eee",
          borderRadius: "4px"
        }}
        onSubmit={onSubmit}
        noValidate
        autoComplete="off">
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={type === "internNr" ? "Sök internnr..." : " Sök regnr..."}
          inputProps={{ "aria-label": "Sök" + type === "internNr" ? " internnr" : "Sök" + " regnr" }}
          value={value}
          onChange={handleChange}
        />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="Sök">
          <FontAwesomeIcon icon={faSearch} />
        </IconButton>
      </Box>
    </Box>
  )
}

export default SearchBar
