import { faWrench } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Grid } from "@mui/material"
import { allRoutes } from "generated/routes"
import React from "react"
import { Link } from "react-router-dom"
import { useStyles } from "./useStyles"

const ArchiveSubCellTitle = ({ cell }) => {
  const classes = useStyles()

  return (
    <Grid className={classes.expandableHeaderContainer}>
      <Grid ml={3} className={classes.expandableHeader}>
        <Button
          component={Link}
          to={{
            pathname: allRoutes.IssuereportsWorkordersAdd(),
            state: cell.row.original.vehicleId
          }}
          Button
          className={classes.expandableBtn}
          variant="text"
          color="primary">
          Skapa Arbetsorder
          <FontAwesomeIcon style={{ marginLeft: "8px" }} icon={faWrench} />
        </Button>
      </Grid>
    </Grid>
  )
}

export default ArchiveSubCellTitle
