import { Box, DialogActions, DialogContent, Theme, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { FontAwesomeProps, InspectionHistoryStatus } from "./Checks.interfaces"

export const StyledDialogContent = styled(DialogContent)({
  width: "100%"
})

export const StyledTypographyModal = styled(Typography)({
  marginTop: "30px"
})

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.primary["main"]
}))

function statusToColor(status: InspectionHistoryStatus, theme: Theme): string {
  switch (status) {
    case InspectionHistoryStatus.Passed:
      return theme.palette.secondary.main
    case InspectionHistoryStatus.Waiting:
      return theme.palette.grey[500]
    case InspectionHistoryStatus.Failed:
      return theme.palette.error.main
    default:
      return "inherit"
  }
}

export const StyledFontAwesomeIconStatus = styled(FontAwesomeIcon)<FontAwesomeProps>(({ theme, status }) => {
  const color: string = statusToColor(status, theme)
  return {
    color,
    paddingRight: "12px"
  }
})

export const StyledDialogActions = styled(DialogActions)({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
  paddingLeft: "24px",
  paddingRight: "24px"
})

export const StyledBox = styled(Box)({
  width: "100%",
  minWidth: "300px",
  maxWidth: "100%"
})

export const StyledBoxClose = styled(Box)({
  marginLeft: "auto",
  cursor: "pointer",
  height: "24px",
  borderRadius: "20px"
})

export const StyledStatusIcon = styled(FontAwesomeIcon)<FontAwesomeProps>(({ theme, status }) => {
  const color: string = statusToColor(status, theme)
  return {
    color
  }
})