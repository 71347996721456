import * as Yup from "yup"

export const folderValidationSchema = Yup.object().shape({
  name: Yup.string().required("Namn är obligatoriskt").max(150, "Namn får max vara 150 tecken långt"),
  description: Yup.string().max(300, "Beskrivning får max vara 300 tecken långt")
})

export const attachmentValidationSchema = Yup.object().shape({
  name: Yup.string().required("Namn är obligatoriskt").max(150, "Namn får max vara 150 tecken långt")
})

export const fileValidationSchema = Yup.object().shape({
  attachments: Yup.array().of(attachmentValidationSchema)
})
