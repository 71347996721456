export const statusButtonText = (value) => {
  switch (value) {
    case 2:
      return "Skicka bokning"
    case 3:
      return "Avboka"
    case 4:
      return null
    case 5:
      return null
    default:
      return "Skicka bokning"
  }
}

export const statusButtonColor = (value) => {
  switch (value) {
    case 2:
      return "primary"
    case 3:
      return "error"
    case 4:
      return null
    case 5:
      return null
    default:
      return "primary"
  }
}

export const statusSubText = (value) => {
  switch (value.status) {
    case 3:
      return `Om du avbokar ordern skickas en bekräftelse på detta till ${value.customerName}.`
    case 6:
      return `Arbetsorder är avbokad`
    default:
      return null
  }
}

export const statusAlertText = (value) => {
  switch (value) {
    case 1:
      return "Vill du skicka arbetsorder till angiven e-post?"
    case 2:
      return "Vill du avboka arbetsorder?"
    case 3:
      return "Vill du avboka arbetsorder?"
    case 4:
      return "Vill du stänga och avsluta arbetsorder?"
    default:
      return "Vill du stänga och avsluta arbetsorder?"
  }
}
