import { useApi } from "auth/useApi3"
import React, { useState } from "react"

import { Container } from "@mui/material"
import { PageTabs } from "components/navigation/PageTabs"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import { useHistory } from "react-router-dom"
import { CreateCustomerStepperForm } from "./components/stepper/CreateCustomerForm"

const CreateCustomer = () => {
  const [customerResult, customerFunc] = useApi()
  const [saveResult, saveFuncs] = useApi()
  const [activeStep, setActiveStep] = useState(0)
  const routerHistory = useHistory()

  React.useEffect(() => {
    // efter skapa success, redirecta till listan
    if (saveResult.status === 2) routerHistory.push(allRoutes.SystemadminCustomers())
  }, [saveResult.status, routerHistory])

  React.useEffect(() => {
    const getData = async () => {
      await customerFunc.get(apiEndpoints.systemadmincustomers.getemptycustomer)
    }
    getData()
  }, [customerFunc])

  const handleSubmit = (values) => {
    saveFuncs.post(apiEndpoints.systemadmincustomers.addcustomer, {
      ...values
    })
  }

  const feedback = {
    errorTitle: "Kunde inte spara kund"
  }

  const steps = [
    {
      pageTitle: "Skapa ny kund"
    },
    {
      pageTitle: "Välj moduler"
    },

    {
      pageTitle: "Skapa bolag & avdelningar"
    },
    {
      pageTitle: "Skapa adminkonto"
    }
  ]

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.SystemadminCustomers(),
            title: "Tillbaka"
          }
        ]}
      />
      <Container>
        {customerResult && steps && (
          <CreateCustomerStepperForm
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            steps={steps}
            saveResult={saveResult}
            feedback={feedback}
            handleSubmit={handleSubmit}
            customerResult={customerResult.data}
          />
        )}
      </Container>
    </div>
  )
}
export default CreateCustomer
