import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import axios from "axios"
import { apiEndpoints } from "generated/apiEndpoints"
import i18next from "i18next"
import { createContext, useEffect, useState } from "react"
import { matchPath, useHistory, useLocation } from "react-router-dom"
import { useApi } from "../auth/useApi3"
import { msalInstance } from "../index"

export const AppContext = createContext()

const defaultAppUser = {
  app: {
    normalPages: [],
    sysadmPages: [],
    routes: [
      {
        unauthenticatedTemplate: true,
        path: "/external/workorder/:guid",
        componentName: "ExternalWorkorder"
      }
    ]
  },
  customer: {},
  roles: [],
  profile: {},
  userSettings: []
}
const emptyState = {
  pageSize: 25,
  pageIndex: 0,
  expanded: {},
  sortBy: [],
  filters: [],
  hiddenColumns: []
}

const getLang = (lng) => {
  switch (lng) {
    case "en-US":
      return "en"
    case "sv-SE":
      return "sv"
    default:
      return lng
  }
}
const AppContextProvider = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const isAuthenticated = useIsAuthenticated()
  const { inProgress, instance } = useMsal()

  const [tableState, setTableState] = useState({
    state: emptyState,
    columns: null,
    filters: []
  })
  const [fileVersion, setFileVersion] = useState()
  const [appUserResult, appUserFuncs] = useApi()
  const [loadingAppstructure, setLoadingAppstructure] = useState(true)
  const [previousPath, setPreviousPath] = useState()
  const [navigationHistory, setNavigationHistory] = useState([])
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(true)

  const [isSidebarOpen, toggleSideBar] = useState(false)
  const [language, setLanguage] = useState(
    i18next.language ? i18next.language : window.localStorage.i18nextLng
  )
  useEffect(() => {
    const handleLanguageChanged = () => {
      setLanguage(i18next.language ? i18next.language : window.localStorage.i18nextLng)
    }

    i18next.on("languageChanged", handleLanguageChanged)

    return () => {
      i18next.off("languageChanged", handleLanguageChanged)
    }
  }, [i18next.language])

  // TODO: loadingFailed
  useEffect(() => {
    const loadStuff = async () => {
      setLoadingAppstructure(true)

      if (inProgress === "none" && isAuthenticated) {
        await appUserFuncs.get(apiEndpoints.app.getappuser)
      }

      try {
        var result = await axios.get("/version.txt")
        setFileVersion(result.data)
      } catch {
        setLoadingAppstructure(false)
      }
    }
    loadStuff()
  }, [inProgress, isAuthenticated, appUserFuncs])

  useEffect(() => {
    if (appUserResult.status === 2) {
      setLoadingAppstructure(false)
    }
  }, [appUserResult.status])

  useEffect(() => {
    if (previousPath !== location.pathname) {
      setPreviousPath(location.pathname)
      setNavigationHistory((prevHistory) => [...prevHistory, { path: location.pathname }])
    }
  }, [location.pathname, previousPath])

  useEffect(() => {
    const currentRoute = appUserResult?.data?.app.routes.find((route) =>
      matchPath(location.pathname, { path: route.matchPath, exact: true, strict: false })
    )

    const previousRoute = appUserResult?.data?.app.routes.find((route) =>
      matchPath(previousPath, { path: route.matchPath, exact: true, strict: false })
    )

    if (!currentRoute?.matchPath.includes(":id") && !previousRoute?.matchPath.includes(":id")) {
      setTableState((prevState) => {
        return {
          ...prevState,
          paginationIndex: 0
        }
      })
    }
  }, [location])

  // ett sätt att redirecta till login automatiskt
  useEffect(() => {
    const match = matchPath(location.pathname, {
      path: "/external/workorder/:guid",
      exact: true,
      strict: false
    })

    if (
      inProgress === "none" &&
      !isAuthenticated &&
      !location.pathname.includes("/loggedout") &&
      match === null
    ) {
      instance.loginRedirect()
    }
  }, [inProgress, isAuthenticated, instance, location])

  useEffect(() => {
    async function fetchData() {
      try {
        const account = msalInstance.getActiveAccount()
        if (!account) {
          throw Error(
            "No active account! Verify a user has been signed in and setActiveAccount has been called."
          )
        }
      } catch {
        setUserIsAuthenticated(false)
      }
    }
    if (isAuthenticated) {
      fetchData()
    }
  }, [history.location.pathname, isAuthenticated])

  const signOutUser = () => {
    setUserIsAuthenticated(false)
    instance.logoutRedirect()
  }

  return (
    isAuthenticated && (
      <AppContext.Provider
        value={{
          isAuth: userIsAuthenticated,
          appUser: appUserResult.status === 2 ? appUserResult.data : defaultAppUser,
          fileVersion: fileVersion,
          tableState: tableState,
          setTableState: setTableState,
          loadingAppstructure: loadingAppstructure,
          toggleSideBar: () => toggleSideBar(!isSidebarOpen),
          isSidebarOpen: isSidebarOpen,
          selectedLanguage: getLang(language),
          navigationHistory: navigationHistory,
          signOutUser: () => signOutUser()
        }}>
        <>{children}</>
      </AppContext.Provider>
    )
  )
}
export default AppContextProvider
