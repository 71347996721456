import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
	auth: {
		clientId: "785af914-6395-4416-bacf-258934ce9526",
		authority:
			"https://clpauth.b2clogin.com/clpauth.onmicrosoft.com/B2C_1_SignInEmailUsername",
		knownAuthorities: ["clpauth.b2clogin.com"],
		redirectUri: "/",
		postLogoutRedirectUri: "https://www.transportportalen.se/", // får inte vara app.transportportalen.se eller annan sajt där appen huserar för då blir det evighetsredirect.
	},
	cache: {
		storeAuthStateInCookie: isIE || isEdge || isFirefox,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				// TODO: bortkommenterat för prod, men borde pluggas in i ev framtida loggningsramverk
				// switch (level) {
				//     case LogLevel.Error:
				//         console.error(message);
				//         return;
				//     case LogLevel.Info:
				//         console.info(message);
				//         return;
				//     case LogLevel.Verbose:
				//         console.debug(message);
				//         return;
				//     case LogLevel.Warning:
				//         console.warn(message);
				//         return;
				//     default:
				//         return;
				//}
			},
		},
	},
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
	scopes: [
		"openid",
		"profile",
		"https://clpauth.onmicrosoft.com/companyportalclientapi/API.Access",
	],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};
