import { faSave } from "@fortawesome/pro-solid-svg-icons"
import { Box, Grid, Typography } from "@mui/material"
import IconForButton from "components/ButtonIcon"

import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Formik, FormikValues } from "formik"
import React, { useEffect, useState } from "react"
// import * as Yup from "yup"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import ClpButton from "components/Button"
import { apiEndpoints } from "generated/apiEndpoints"
import { IFormAreaProps } from "../FormArea/formarea.interface"
import FormComponents from "../FormComponents/FormComponents"
import { IFormFieldProps } from "../FormField/formfield.interface"
import { IFormGroupProps } from "../FormGroup/formgroup.interface"
import { IFormAnswer, IFormTemplateEditProps } from "./formtemplate.interface"

const EditFormTemplate: React.FC<IFormTemplateEditProps> = (props) => {
  const { id, formTemplateId, formAreas, feature, setEditForm } = props
  const [formResult, postForm] = useApi() as [IApiResult, IApiFunc]
  const [allAreas, setAllAreas] = useState<IFormAreaProps[]>([])
  const [activeStep, setActiveStep] = useState<number>(0)
  const [initialValues, setInitialValues] = useState<FormikValues>({})

  useEffect(() => {
    formAreas &&
      formAreas.length > 0 &&
      formAreas.map((area: IFormAreaProps) => {
        return area.formGroups.map((formGroup: IFormGroupProps) => {
          return formGroup.formFields.map((field: IFormFieldProps) => {
            const newObj = {
              ...initialValues,
              [field.id]: field.value
            }
            return setInitialValues(Object.assign(initialValues, newObj))
          })
        })
      })

    setAllAreas(formAreas)
  }, [formAreas, initialValues])

  const handleSubmitFormArea = async (values: any) => {
    const mapValues: Array<IFormAnswer> = values
      ? Object.entries(values).map(([key, value]) => {
          const mapValue = {
            formResultId: id,
            formFieldId: key,
            value: value?.toString()
          }
          return mapValue
        })
      : []
    const postValues = {
      id: id,
      formTemplateId: formTemplateId,
      formAnswers: mapValues
    }

    postForm.post(apiEndpoints.form.createorupdateformresult, postValues)
  }

  useEffect(() => {
    if (formResult.status === 2) {
      setEditForm(false)
    }
  }, [formResult])
  return Object.keys(initialValues).length !== 0 ? (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmitFormArea(values)}
      enableReinitialize={true}>
      {(props) => {
        const { values, handleSubmit } = props
        return (
          <Box>
            <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
              <>
                {allAreas.map((area: IFormAreaProps) => (
                  <Box marginBottom={2}>
                    <Typography variant="h2" color="primary" gutterBottom>
                      {area.labelDefault}
                    </Typography>

                    {area?.formGroups?.map((formGroup: IFormGroupProps) => (
                      <Box marginBottom={3}>
                        <Typography variant="h3" gutterBottom marginBottom="20px">
                          {formGroup.labelDefault}
                        </Typography>
                        {formGroup.formFields.map((field: IFormFieldProps) => {
                          const parsedDependencyIds =
                            field.dependencyIds && JSON.parse(field.dependencyIds.toString())

                          const dependencyCheck = area?.formGroups?.findIndex(
                            (formGroup: IFormGroupProps) => {
                              const formValue = formGroup.formFields.findIndex((ff: IFormFieldProps) => {
                                return parsedDependencyIds?.findIndex(
                                  (d: any) => d.formFieldKey === ff.formFieldKey && values[ff.id] === d.value
                                )
                              })
                              return formValue
                            }
                          )

                          return (
                            dependencyCheck !== -1 && (
                              <FormComponents
                                initialValues={initialValues}
                                handleNext={() => handleSubmitFormArea(values)}
                                field={field}
                                formikProps={props}
                              />
                            )
                          )
                        })}
                      </Box>
                    ))}
                  </Box>
                ))}

                <Grid container direction="row" gap={2} justifyContent="center" mt={5} mb={5}>
                  <Grid item>
                    <ClpButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      result={formResult}
                      startIcon={<IconForButton icon={faSave as IconProp} color="primary" />}>
                      Spara
                    </ClpButton>
                  </Grid>
                </Grid>
              </>
            </form>
          </Box>
        )
      }}
    </Formik>
  ) : (
    <></>
  )
}

export default EditFormTemplate
