import { Box, Grid } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import Button from "components/Button"
import PaperBox from "components/PaperBox"
import { Formik, FormikProps } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect, useRef, useState } from "react"
import { IAdminSettingProps, IDrivingTypesSettingsProps, ISelectListItem } from "../violation.interface"
import ViolationDrivingTypesAdd from "./ViolationDrivingTypesAdd"
import ViolationDrivingTypesRemove from "./ViolationDrivingTypesRemove"

const AdminDrivingTypes: React.FC<IAdminSettingProps> = (props) => {
  const { companyId } = props
  const [violationDrivingTypesResult, violationDrivingTypesApi] = useApi() as [IApiResult, IApiFunc]
  const [violationGetResult, violationGetApi] = useApi() as [IApiResult, IApiFunc]

  const formRef = useRef<FormikProps<any>>(null)

  const [initialValues, setInitialValues] = useState<IDrivingTypesSettingsProps>({
    drivingTypes: [],
    companyId: companyId
  })

  useEffect(() => {
    violationGetApi.get(
      apiEndpoints.violationadmin.getviolationdrivingtypessettings.replace(
        "{companyid}",
        companyId.toString()
      )
    )
  }, [violationDrivingTypesResult])

  useEffect(() => {
    if (violationGetResult.status == 2) {
      setInitialValues(violationGetResult.data)
      setviolationDrivingTypes(drivingTypesListId(violationGetResult.data.drivingTypes))
    }
  }, [violationGetResult.status])

  function drivingTypesListId(items: Array<ISelectListItem>) {
    return items.map((item: ISelectListItem) => {
      return {
        ...item,
        listId: item.id
      }
    })
  }

  const [violationDrivingTypes, setviolationDrivingTypes] = useState<ISelectListItem[]>(
    initialValues ? drivingTypesListId(initialValues.drivingTypes) : []
  )

  const handleSubmit = (values: IDrivingTypesSettingsProps) => {
    const violationValues: IDrivingTypesSettingsProps = {
      ...values,
      drivingTypes: violationDrivingTypes || []
    }
    violationDrivingTypesApi.post(
      apiEndpoints.violationadmin.postviolationdrivingtypesetting,
      violationValues
    )
  }

  return (
    <PaperBox>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid item xs={12} mb={3}>
              <ViolationDrivingTypesAdd
                onSubmit={(item: any) => {
                  setviolationDrivingTypes((s: any) => {
                    return [
                      ...s,
                      {
                        ...item,
                        id: 0
                      }
                    ]
                  })
                }}
              />
              <ViolationDrivingTypesRemove
                categories={violationDrivingTypes}
                removeViolationDrivingType={(item: any) => {
                  setviolationDrivingTypes((s: any) => {
                    return s.filter((x: any) => x.listId !== item.listId)
                  })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box pb={2} display="flex" justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  result={violationDrivingTypesResult}>
                  Spara
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Formik>
    </PaperBox>
  )
}

export default AdminDrivingTypes
