import moment from "moment"
import MarkerIcon from "./MarkerIcon"
import { IDevicePosition } from "./devices.interface"

const DeviceMarker = (
  device: IDevicePosition,
  map: any,
  onMarkerClick: any,
  infoWindow: any,
  activeMarker?: any
) => {
  const marker = new google.maps.Marker({
    position: { lat: device.currentPosition.lat, lng: device.currentPosition.long },
    map,
    visible: true,
    title: device.label,
    zIndex: 100
  })
  MarkerIcon(marker, 0, device)

  marker.addListener("click", () => {
    onMarkerClick(device)
    MarkerIcon(marker, 0, device, activeMarker)
    infoWindow?.setContent(
      `<strong>${device?.internalDeviceId}</strong> | ${device?.label}<br>${moment(
        device?.currentPosition?.timestamp
      ).format("YYYY-MM-DD HH:mm")} <br> Hastighet: ${device?.speed} km/h`
    )
    infoWindow?.open(map, marker)
    var latLng = marker.getPosition()
    map.setCenter(latLng)
  })

  return marker
}
export default DeviceMarker
