import { Box, Grid } from "@mui/material"
import LoadingTypography from "components/skeleton/LoadingTypography"
import React from "react"

export const AssignmentListLoading = () => {
  return (
    <>
      <Box marginY={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
          <Grid item xs={8} md={3}>
            <LoadingTypography variant="h2" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item sm={6} md={1}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item sm={6} md={2}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container direction="column" justifyContent="flex-end">
              <Grid item>
                <LoadingTypography variant="caption" />

                <LoadingTypography variant="bodyMedium" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
        </Grid>
      </Box>
      <Box marginY={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
          <Grid item xs={8} md={3}>
            <LoadingTypography variant="h2" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item sm={6} md={1}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item sm={6} md={2}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container direction="column" justifyContent="flex-end">
              <Grid item>
                <LoadingTypography variant="caption" />

                <LoadingTypography variant="bodyMedium" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
        </Grid>
      </Box>
      <Box marginY={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
          <Grid item xs={8} md={3}>
            <LoadingTypography variant="h2" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item sm={6} md={1}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item sm={6} md={2}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container direction="column" justifyContent="flex-end">
              <Grid item>
                <LoadingTypography variant="caption" />

                <LoadingTypography variant="bodyMedium" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
        </Grid>
      </Box>

      <Box marginY={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
          <Grid item xs={8} md={3}>
            <LoadingTypography variant="h2" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item sm={6} md={1}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item sm={6} md={2}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container direction="column" justifyContent="flex-end">
              <Grid item>
                <LoadingTypography variant="caption" />

                <LoadingTypography variant="bodyMedium" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <LoadingTypography variant="caption" />
            <LoadingTypography variant="bodyMedium" />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
