import { Box } from '@mui/system';
import { allRoutes } from "generated/routes";
import React from 'react';
import { Link } from "react-router-dom";

const ArchiveTableLinkCell = ({ groupedName, name, groupVehicles, row }) => {
    return (
        <Box
            display="block"
            component={Link}
            to={allRoutes.IssuereportsArchiveIssuereport(row.original.id)}
            style={{
                color: "inherit",
                textDecoration: "inherit"
            }}
        >
            {!groupVehicles ? name : groupedName}
        </Box>

    )
}

export default ArchiveTableLinkCell