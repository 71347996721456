import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Box, Button, ButtonGroup, Container, Grid, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import PaginationForPages from "components/paginationForPages/PaginationForPages"
import usePagination from "components/paginationForPages/usePagination"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import IssueReportCard from "pages/issuereports/pages/dashboard/components/DashboardCard"
import React, { useContext, useEffect, useState } from "react"
import ImportIssueReportForm from "../../components/CustomForms/ImportIssueReportForm"
import { DashboardQuickLinks } from "./components/DashboardQuickLinks"

const IssueReportsDashboard = () => {
  const appContext = useContext(AppContext)

  // open sidebar
  const [showSidebarForm, setShowSidebarForm] = useState(false)
  const openAdd = () => {
    setShowSidebarForm(true)
  }

  const loadingContext = React.useContext(GlobalLoaderContext)
  const [issuereportResult, issuereportFuncs] = useApi()

  useEffect(() => {
    issuereportFuncs.get(apiEndpoints.issuereports.getissuereportsoverview)
    return () => {
      issuereportFuncs.leave()
    }
  }, [issuereportFuncs])

  useEffect(() => {
    loadingContext.setLoading(() => issuereportResult.status === 1)
  })

  // update dashboard data
  const updateExistingData = () => {
    issuereportFuncs.get(apiEndpoints.issuereports.getissuereportsoverview)
  }

  // pagination
  let [page, setPage] = useState(1)
  const itemsPerPage = 10
  const count = Math.ceil(issuereportResult.status === 2 && issuereportResult.data.length / itemsPerPage)
  const issueReportResultPagination = usePagination(
    issuereportResult.status === 2 && issuereportResult.data,
    itemsPerPage
  )

  // handle page on pagination
  const handleChange = (e, page) => {
    setPage(page)
    issueReportResultPagination.jump(page)
  }

  return (
    <Box pb={3}>
      <Container>
        <Grid container direction="row" justifyContent="flex-end">
          {issuereportResult.status === 2 ? (
            hasRole(appContext.appUser, allRoles.IssueReports) && (
              <ButtonGroup>
                <Button
                  onClick={() => openAdd()}
                  endIcon={<AddCircleIcon />}
                  variant="contained"
                  color="primary">
                  Skapa felrapport
                </Button>
              </ButtonGroup>
            )
          ) : (
            <SkeletonCard width="177px" />
          )}
        </Grid>
        <Grid container direction="row" justifyContent="space-between" pt={2} spacing={4}>
          <Grid item md={8} xl={6}>
            {issuereportResult.status === 2 ? (
              <>
                <Typography mb={2} variant="h4">
                  Senast uppdaterade
                </Typography>
                <PaperBox>
                  {issueReportResultPagination.currentData().map((assignment) => (
                    <IssueReportCard assignment={assignment} />
                  ))}

                  <PaginationForPages count={count} page={page} onChange={handleChange} size="medium" />
                </PaperBox>
              </>
            ) : (
              <SkeletonCard />
            )}
          </Grid>
          <Grid item md={4} xl={3} xs={12}>
            {issuereportResult.status === 2 ? (
              <>
                <Typography variant="h4">Snabblänkar</Typography>
                <PaperBox>
                  <DashboardQuickLinks />
                </PaperBox>
              </>
            ) : (
              <SkeletonCard />
            )}
          </Grid>
        </Grid>
      </Container>

      <ImportIssueReportForm
        updateExistingData={updateExistingData}
        setShowSidebarForm={setShowSidebarForm}
        showSidebarForm={showSidebarForm}
      />
    </Box>
  )
}
export default IssueReportsDashboard
