import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faSave } from "@fortawesome/pro-solid-svg-icons"
import { Autocomplete, Box, FormControl, InputLabel, TextField, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import ClpButton from "components/Button"
import IconForButton from "components/ButtonIcon"
import theme from "css/theme/light"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { IDeviationProps } from "pages/deviation/deviation.interface"
import { useEffect } from "react"
import { priorityOptions } from "../helpers/deviationPriority"
import { statusOptions } from "../helpers/deviationStatus"

const EditDeviationForm = (props: IDeviationProps) => {
  const { id } = props

  const [deviationResult, postForm] = useApi() as [IApiResult, IApiFunc]
  const [usersResult, getAssignedUsers] = useApi() as [IApiResult, IApiFunc]
  useEffect(() => {
    getAssignedUsers.get(apiEndpoints.deviation.getusers.replace("{feature}", props.feature))
  }, [])

  const handleSubmit = async (values: any) => {
    const postValues = {
      id: id,
      status: values.status,
      priority: values.priority,
      assignedUserId: values.assignedUserId ? values.assignedUserId : 0
    }
    postForm.put(apiEndpoints.deviation.updatedeviation, postValues)
  }
  return (
    <Formik initialValues={props} onSubmit={(values) => handleSubmit(values)} enableReinitialize={true}>
      {(props) => {
        const { values, handleSubmit, setFieldValue, handleBlur, handleChange } = props

        return (
          <Box>
            <form id="DeviationForm" onSubmit={handleSubmit} noValidate>
              <>
                <Box marginBottom={2}>
                  <>
                    <InputLabel>
                      <Typography variant="labelLarge" fontWeight={500}>
                        Status *
                      </Typography>
                    </InputLabel>

                    <FormControl fullWidth>
                      <Autocomplete
                        sx={{ width: "100%", background: theme.palette.background.paper }}
                        options={statusOptions}
                        getOptionLabel={(option: any) => (option?.label ? option?.label : "")}
                        value={statusOptions?.find((x: any) => x.value === values.status) || null}
                        onChange={(e, option) => setFieldValue("status", option?.value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={id?.toString()}
                            variant="outlined"
                            defaultValue={values.status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      />
                    </FormControl>
                  </>
                </Box>
                <Box marginBottom={2}>
                  <>
                    <InputLabel>
                      <Typography variant="labelLarge" fontWeight={500}>
                        Prioritet *
                      </Typography>
                    </InputLabel>
                    <FormControl fullWidth>
                      <Autocomplete
                        sx={{ width: "100%", background: theme.palette.background.paper }}
                        options={priorityOptions}
                        getOptionLabel={(option: any) => option?.label}
                        value={priorityOptions?.find((x: any) => x.value === values.priority) || null}
                        onChange={(e, option) => setFieldValue("priority", option?.value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={id?.toString()}
                            variant="outlined"
                            defaultValue={values.priority}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      />
                    </FormControl>
                  </>
                </Box>
                <Box marginBottom={2}>
                  <>
                    <InputLabel>
                      <Typography variant="labelLarge" fontWeight={500}>
                        Tilldela
                      </Typography>
                    </InputLabel>
                    <FormControl fullWidth>
                      <Autocomplete
                        sx={{ width: "100%", background: theme.palette.background.paper }}
                        options={usersResult.status === 2 ? usersResult.data : []}
                        getOptionLabel={(option: any) => `${option?.username}`}
                        value={usersResult?.data?.find((x: any) => x.id === values.assignedUserId) || null}
                        onChange={(e, option) => setFieldValue("assignedUserId", option?.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={id?.toString()}
                            variant="outlined"
                            defaultValue={values.assignedUserId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      />
                    </FormControl>
                  </>
                </Box>
                <ClpButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  result={deviationResult}
                  startIcon={
                    <IconForButton
                      icon={faSave as IconProp}
                      color="primary"
                    />
                  }>
                  Spara
                </ClpButton>
              </>
            </form>
          </Box>
        )
      }}
    </Formik>
  )
}

export default EditDeviationForm
