import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faFile, faPlus, faTrashCanXmark } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Checkbox, Divider, Grid, TextField, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import Button from "components/Button"
import ErrorDialog from "components/ErrorDialog"
import TextEditor from "components/Fields/TextEditor"
import MultipleFilePicker from "components/MultipleFilePicker"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { FieldArray, Form, Formik, FormikProps } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect, useRef, useState } from "react"
import styles from "./style.js"

interface ContactInfo {
  name: string
  phoneNumber: string
}

export interface ICrisisInfoAttachment {
  id: number
  name: string
  crisisInfoId: number
  fileId: string
  fileName: string
}

interface CrisisInfoData {
  title: string
  subTitle: string
  otherContactInfos?: ContactInfo[]
  showManagerContactInfo: boolean
  body: string
  attachments?: any[]
}

interface Props {
  initialCrisisInfo?: CrisisInfoData
  handleSubmit: (objectToSave: any) => void
  result: any
  feedback: any
}

const CrisisInfoForm: React.FC<Props> = (props) => {
  const initialValues: CrisisInfoData = props.initialCrisisInfo
    ? {
        title: props.initialCrisisInfo.title,
        subTitle: props.initialCrisisInfo.subTitle,
        otherContactInfos: props.initialCrisisInfo.otherContactInfos,
        showManagerContactInfo: props.initialCrisisInfo.showManagerContactInfo,
        body: props.initialCrisisInfo.body,
        attachments: props.initialCrisisInfo.attachments
      }
    : {
        title: "",
        subTitle: "",
        otherContactInfos: [{ name: "", phoneNumber: "" }],
        showManagerContactInfo: false,
        body: "",
        attachments: []
      }

  const [fileUploadResult, uploadFile] = useApi() as [IApiResult, IApiFunc]
  const [fileUpsertResult, upsertFile] = useApi() as [IApiResult, IApiFunc]
  const [fileUpdateResult, updateFile] = useApi() as [IApiResult, IApiFunc]
  const [fileDeleteResult, deleteFile] = useApi() as [IApiResult, IApiFunc]
  const [editFile, setEditFile] = React.useState<null | any>(null)
  const [files, setFiles] = useState([])
  const [filesPreview, setFilesPreview] = useState(initialValues.attachments)
  const [textEditorValue, setTextEditorValue] = useState()
  const formRef = useRef() as any
  const formRefAttachments = useRef<FormikProps<any>>(null)
  const style = styles()

  useEffect(() => {
    if (files) {
      files.map((file) => {
        return uploadFile.file(apiEndpoints.crisismanagement.uploadattachment, file)
      })
    }
  }, [files, uploadFile])

  useEffect(() => {
    if (fileUploadResult.status === 2) {
      upsertFile.post(apiEndpoints.crisismanagement.uploadattachmenttocrisisinfo, fileUploadResult.data)
    }
  }, [fileUploadResult])

  useEffect(() => {
    if (fileUploadResult.data && fileUploadResult.data.name) {
      setFilesPreview((p) => {
        return [...(p as any[]), fileUploadResult.data]
      })
    }
  }, [fileUploadResult])

  useEffect(() => {
    if (fileUpsertResult.status === 2) {
      formRefAttachments?.current?.setFieldValue("attachments", [
        ...formRefAttachments?.current?.values.attachments,
        fileUpsertResult.data
      ])
    }
  }, [fileUpsertResult])

  useEffect(() => {
    if (formRef.current && textEditorValue) {
      formRef.current.setFieldValue("body", textEditorValue)
    }
  }, [textEditorValue])

  useEffect(() => {
    if (fileUpsertResult.status === 2) {
      formRefAttachments?.current?.setFieldValue("attachments", [
        ...formRefAttachments?.current?.values.attachments,
        fileUpsertResult.data
      ])
    }
  }, [fileUpsertResult])

  const saveFile = (valuesObj: any) => {
    if (editFile !== null)
      updateFile.put(apiEndpoints.crisismanagement.updateattachment, valuesObj?.attachments[editFile])
  }

  const onRemoveAttachment = (id: any, index: number) => {
    if (id !== null) {
      deleteFile.del(apiEndpoints.crisismanagement.deleteattachment.replace("{id}", id))
      const newPreviewFiles = [...(filesPreview as any)]
      newPreviewFiles.splice(index, 1)
      setFilesPreview(newPreviewFiles)
    }
  }

  return (
    <>
      <Formik
        innerRef={formRef}
        onSubmit={props.handleSubmit}
        initialValues={initialValues}
        // validationSchema={articleValidationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setFieldValue
        }) => (
          <Form>
            <Grid container direction={"row-reverse"} spacing={3} paddingBottom={10}>
              <Grid item lg={5} md={12} xs={12} justifyContent={"space-between"}>
                <PaperBox sx={{ backgroundColor: theme.palette.primary.light }}>
                  <Typography variant="h3" color={theme.palette.primary.main}>
                    Inställningar
                  </Typography>

                  <Grid container alignItems={"center"}>
                    <Checkbox
                      onChange={handleChange}
                      id="showManagerContactInfo"
                      checked={values.showManagerContactInfo}
                      sx={{ paddingLeft: 0 }}
                    />
                    <Typography variant="h6">Visa kontaktinfo till närmsta chef</Typography>
                  </Grid>
                </PaperBox>
              </Grid>

              <Grid item lg={7} md={12}>
                <PaperBox sx={{ boxShadow: "0px 8px 13px 0px #0000001A" }}>
                  <Grid container>
                    <Typography variant="h3" marginBottom={4}>
                      Redigera uppgifter
                    </Typography>

                    <Grid item xs={12}>
                      <Typography variant="h6" marginBottom={1}>
                        Titel
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.title && errors.title ? true : false}
                        helperText={touched.title && errors.title ? errors.title : null}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            root: style.cssOutlinedInput,
                            focused: style.cssFocused,
                            notchedOutline: style.notchedOutline
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6" marginBottom={1} marginTop={2}>
                        Ingress
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="subTitle"
                        value={values.subTitle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.subTitle && errors.subTitle ? true : false}
                        helperText={touched.subTitle && errors.subTitle ? errors.subTitle : null}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            root: style.cssOutlinedInput,
                            focused: style.cssFocused,
                            notchedOutline: style.notchedOutline
                          }
                        }}
                      />
                    </Grid>

                    <Typography variant="h6" marginBottom={1} marginTop={2} width={"100%"}>
                      Kontaktuppgifter Trafikområde
                    </Typography>

                    <FieldArray name="otherContactInfos">
                      {(fieldArrayProps) => {
                        const { push, remove, form } = fieldArrayProps
                        const { values } = form
                        const { otherContactInfos } = values
                        return (
                          <>
                            {otherContactInfos.map((contactInfo: ContactInfo, index: number) => (
                              <Grid container xs={12} spacing={2}>
                                <Grid item xs={5}>
                                  <TextField
                                    autoComplete="off"
                                    fullWidth
                                    id={`otherContactInfos.${index}.name`}
                                    label="Namn"
                                    value={contactInfo.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      touched.otherContactInfos && errors.otherContactInfos ? true : false
                                    }
                                    helperText={
                                      touched.otherContactInfos && errors.otherContactInfos
                                        ? errors.otherContactInfos
                                        : null
                                    }
                                    variant="outlined"
                                    InputProps={{
                                      classes: {
                                        root: style.cssOutlinedInput,
                                        focused: style.cssFocused,
                                        notchedOutline: style.notchedOutline
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <TextField
                                    autoComplete="off"
                                    fullWidth
                                    id={`otherContactInfos.${index}.phoneNumber`}
                                    label="Telefonnummer"
                                    value={contactInfo.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      touched.otherContactInfos && errors.otherContactInfos ? true : false
                                    }
                                    helperText={
                                      touched.otherContactInfos && errors.otherContactInfos
                                        ? errors.otherContactInfos
                                        : null
                                    }
                                    variant="outlined"
                                    InputProps={{
                                      classes: {
                                        root: style.cssOutlinedInput,
                                        focused: style.cssFocused,
                                        notchedOutline: style.notchedOutline
                                      }
                                    }}
                                    sx={{ marginBottom: 1.5 }}
                                  />
                                </Grid>
                                <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                                  <Button
                                    fullWidth
                                    variant="text"
                                    startIcon={<FontAwesomeIcon icon={faTrashCanXmark as IconProp} />}
                                    sx={{
                                      color: theme.palette.error.main,
                                      minWidth: "90px",
                                      padding: 0,
                                      marginBottom: 1.5,
                                      marginLeft: 1
                                    }}
                                    onClick={() => remove(index)}>
                                    Ta bort
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}

                            <Button
                              variant="outlined"
                              onClick={() => push({ name: "", phoneNumber: "" })}
                              style={{ padding: 5 }}>
                              <Box className={style.plusIconBackground} sx={{ marginRight: 1 }}>
                                <FontAwesomeIcon icon={faPlus as IconProp} color="white" size="lg" />
                              </Box>
                              <Typography sx={{ marginRight: 1 }}>Lägg till fler</Typography>
                            </Button>
                          </>
                        )
                      }}
                    </FieldArray>

                    <Grid item xs={12}>
                      <Typography variant="h6" marginBottom={1} marginTop={2}>
                        Övrig information
                      </Typography>
                      <TextEditor
                        value={initialValues.body}
                        setData={(e) => {
                          setTextEditorValue(e)
                        }}
                        uploadApi={apiEndpoints.crisismanagement.uploadbodyattachment}
                      />
                    </Grid>

                    {fileUpsertResult.status === 3 ||
                      (fileUploadResult.status === 3 && (
                        <ErrorDialog title="Uppladdning misslyckades" message="" />
                      ))}
                    <Grid item xs={12}>
                      <Typography variant="h6" marginBottom={1} marginTop={2}>
                        Ladda upp dokument
                      </Typography>
                      <MultipleFilePicker
                        isLoading={fileUpsertResult.status === 1 || fileUploadResult.status === 1}
                        setFiles={setFiles}
                      />
                    </Grid>
                    <Grid container marginTop={1}>
                      {filesPreview?.map((attachment: ICrisisInfoAttachment, index: number) => (
                        <>
                          <Grid item xs={12}>
                            <Box>
                              <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                  <Grid container>
                                    <Grid item>
                                      <FontAwesomeIcon
                                        icon={faFile as IconProp}
                                        color={theme.palette.primary.main}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography width={"100%"} marginLeft={1}>
                                        {attachment.name}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Button
                                    onClick={() => onRemoveAttachment(attachment.id, index)}
                                    variant="text"
                                    startIcon={<FontAwesomeIcon icon={faTrashCanXmark as IconProp} />}
                                    sx={{
                                      color: theme.palette.error.main
                                    }}>
                                    Ta bort
                                  </Button>
                                </Grid>
                              </Grid>
                              {index != filesPreview.length - 1 ? (
                                <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                              ) : null}
                            </Box>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </PaperBox>
              </Grid>
            </Grid>
            <Box position={"fixed"} bottom={"32px"} right={"16px"}>
              <Button color="primary" type="submit" result={props.result} feedback={props.feedback}>
                spara krishantering
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}
export default CrisisInfoForm
