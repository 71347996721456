import { faCloudUpload, faTrashAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, IconButton, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import React, { useEffect, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    borderWidth: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed"
  },
  label: {
    color: "rgba(0, 0, 0, 0.54)",
    top: "-11px",
    left: "9px",
    padding: "0 5px",
    background: theme.palette.background.paper,
    position: "absolute"
  },
  margin: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "8px 0",
    padding: 15
  },
  img: {
    display: "block",
    maxWidth: "100%",
    height: "auto"
  },
  removeButton: {
    position: "absolute",
    bottom: 0,
    right: 0
  }
}))

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "background .24s ease-in-out",
  cursor: "pointer",
  margin: "0 15px",
  borderRadius: 4,
  textAlign: "center"
}

const activeStyle = {
  backgroundColor: "rgba(233,233,233, 1)"
}

const acceptStyle = {
  backgroundColor: "rgba(233,233,233, 1)"
}

const rejectStyle = {
  borderColor: "#ff1744"
}

const FilePicker = ({
  setFieldValue,
  fieldName,
  defaultPreviewImage,
  setFile,
  fileUploadResult,
  onLogoChanged,
  label
}) => {
  const classes = useStyles()
  const [previewFile, setPreviewFile] = useState()
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: "image/*",
    multiple: false,
    paramName: () => "file",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0]
      setFile(file)
      onLogoChanged({ preview: URL.createObjectURL(file), name: file.name })
      setPreviewFile({ preview: URL.createObjectURL(file), name: file.name })
    }
  })
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const removeFile = () => () => {
    setFile(null)
    setPreviewFile(null)
    setFieldValue(fieldName, null)
  }

  useEffect(() => {
    if (defaultPreviewImage) {
      setPreviewFile({
        preview: defaultPreviewImage,
        name: defaultPreviewImage
      })
    }
  }, [defaultPreviewImage, setFile])

  useEffect(() => {
    if (fileUploadResult?.data && fileUploadResult?.data.name) {
      setFieldValue(fieldName, fileUploadResult.data.name)
    }
  }, [fileUploadResult])

  return (
    <Box
      className={classes.container}
      bgcolor="background.paper"
      border={1}
      borderColor="grey.400"
      borderRadius="4px">
      <Typography className={classes.label} variant="caption">
        {label}
      </Typography>
      <Box spacing={3} py={2}>
        {!previewFile && (
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <FontAwesomeIcon icon={faCloudUpload} size="3x" />
            <p>Släpp fil eller klicka här för att välja fil</p>
          </div>
        )}
        {previewFile && previewFile.preview && (
          <div className={classes.thumbsContainer}>
            <img src={previewFile.preview} className={classes.img} alt="" />
            <IconButton
              type="button"
              aria-label="Ta bort"
              className={classes.removeButton}
              onClick={removeFile()}
              size="large">
              <FontAwesomeIcon icon={faTrashAlt} size="xs" />
            </IconButton>
          </div>
        )}
      </Box>
    </Box>
  )
}
export default FilePicker
