import {StyleSheet, View} from '@react-pdf/renderer'
import React from 'react'
import InvoiceTableHeader from './TableHeader'
import InvoiceTableRow from './TableRow'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'column',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    width: '100%',
    marginTop: 24,
    borderWidth: 1,
    borderColor: 'rgba(239, 239, 239, 1)'
  }
})

const ItemsTable = ({columns, data}) => {
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader columns={columns} />
      <InvoiceTableRow data={data} />
    </View>
  )
}

export default ItemsTable
