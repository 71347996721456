import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"

const ExternalDetailsCard = ({ values, bgColor, title }) => {
  return (
    <Box mt={5}>
      <Typography fontWeight={500} color="primary" variant="h5">
        {title}
      </Typography>
      <Box
        flexWrap="wrap"
        borderRadius={2}
        backgroundColor={bgColor}
        display="flex"
        flexDirection="row"
        p={4}
        alignItems="center"
        justifyContent="space-between">
        <Box>
          <Typography variant="bodySmall">Reg.Nr.</Typography>
          <Typography variant="h6">{values.regNumber}</Typography>
        </Box>
        <Box>
          <Typography variant="bodySmall">Chassinummer</Typography>
          <Typography variant="h6">{values.chassiNumber}</Typography>
        </Box>
        <Box>
          <Typography variant="bodySmall">Årsmodell</Typography>
          <Typography variant="h6">{values.vehicleYear}</Typography>
        </Box>
        <Box>
          <Typography variant="bodySmall">Modell</Typography>
          <Typography variant="h6">{values.model}</Typography>
        </Box>
        <Box>
          <Typography variant="bodySmall">Tillverkare</Typography>
          <Typography variant="h6">{values.model?.split(" ")[0]}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ExternalDetailsCard
