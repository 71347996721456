import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { useApiDelete, useApiPost, useApiPut } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useContext, useEffect, useState } from "react"
import { VehicleAlarmNotificationBar } from "./components/VehicleAlarmNotificationBar"

export const alarmType = (value) => {
  switch (value) {
    case 1:
      return "Alkolås"
    default:
      return "Förarkort"
  }
}
const VehicleAlarmSettings = () => {
  const loadingContext = useContext(GlobalLoaderContext)

  const [showSidebar, setShowSidebar] = useState(false)
  const [formData, setFormData] = useState()
  const [editable, setEditable] = useState(false)
  const [result, setResult] = useState(null)

  const [vehicleAlarmNotificationsResult, vehiclesAlarmNotificationFuncs] = useApi([])
  const [updateVehicleAlarmNotificationResult, updateVehicleAlarmNotification] = useApiPut()
  const [createVehicleAlarmNotificationResult, createVehicleAlarmNotification] = useApiPost()
  const [deleteResult, deleteNotification, resetDeleteState] = useApiDelete()

  useEffect(() => {
    vehiclesAlarmNotificationFuncs.get(
      apiEndpoints.vehicleregistryadmin.getvehiclealarmnotificationsbycustomerid
    )
    return () => {
      vehiclesAlarmNotificationFuncs.leave()
    }
  }, [vehiclesAlarmNotificationFuncs])

  useEffect(() => {
    loadingContext.setLoading(() => vehicleAlarmNotificationsResult.status === 1)
  })

  const getFormData = (row) => {
    setEditable(true)
    setFormData(row)
    setShowSidebar(true)
  }
  const getEmptyFormData = (row) => {
    setEditable(false)
    setFormData(row)
    setShowSidebar(true)
  }

  const feedback = {
    errorTitle: "Notifikation kunde inte skapas"
  }

  const handleSubmit = (values) => {
    if (editable) {
      updateVehicleAlarmNotification(apiEndpoints.vehicleregistryadmin.updatevehiclealarmnotification, values)
    } else {
      createVehicleAlarmNotification(apiEndpoints.vehicleregistryadmin.createvehiclealarmnotification, values)
    }
  }

  useEffect(() => {
    console.log(updateVehicleAlarmNotificationResult)
    setResult(updateVehicleAlarmNotificationResult)
    if (updateVehicleAlarmNotificationResult.status === 2) {
      vehiclesAlarmNotificationFuncs.get(
        apiEndpoints.vehicleregistryadmin.getvehiclealarmnotificationsbycustomerid
      )
      setShowSidebar(false)
      setFormData(null)
    }
  }, [updateVehicleAlarmNotificationResult, vehiclesAlarmNotificationFuncs])

  useEffect(() => {
    setResult(createVehicleAlarmNotificationResult)
    if (createVehicleAlarmNotificationResult.status === 2) {
      vehiclesAlarmNotificationFuncs.get(
        apiEndpoints.vehicleregistryadmin.getvehiclealarmnotificationsbycustomerid
      )
      setShowSidebar(false)
      setFormData(null)
    }
  }, [createVehicleAlarmNotificationResult, vehiclesAlarmNotificationFuncs])

  const handleDelete = (id) => {
    deleteNotification(apiEndpoints.vehicleregistryadmin.deletevehiclealarmnotification.replace("{id}", id))
  }

  useEffect(() => {
    setResult(deleteResult)

    if (deleteResult.status === 2) {
      vehiclesAlarmNotificationFuncs.get(
        apiEndpoints.vehicleregistryadmin.getvehiclealarmnotificationsbycustomerid
      )
      setShowSidebar(false)

      resetDeleteState()
    }
  }, [deleteResult, resetDeleteState, vehiclesAlarmNotificationFuncs])

  const columns = [
    {
      accessor: "alarmType",
      Cell: ({ cell }) => {
        return alarmType(cell.row.original.alarmType)
      },
      Header: "Larmtyp",
      mainFilter: true,
      Filter: SelectColumnFilter,
      filter: "equals",
      FilterHeader: "Larmtyp",
      FilterOptions: [
        {
          name: "Alkolås",
          value: 0
        },
        {
          name: "Förarkort",
          value: 1
        }
      ]
    },
    {
      accessor: "user.name",
      Header: "Namn"
    },
    {
      accessor: "user.email",
      Header: "E-post"
    },
    {
      accessor: "company.name",
      Header: "Bolag"
    }
  ]
  return (
    <div>
      <VehicleAlarmNotificationBar
        showSidebar={showSidebar}
        formData={formData}
        setShowSidebar={setShowSidebar}
        onSubmit={handleSubmit}
        editVehicleAlarmNotification={editable}
        result={result}
        feedback={feedback}
        deleteNotificationSubmit={handleDelete}
        deleteResult={deleteResult}
      />

      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Larminställningar</Typography>
            <Button color="primary" variant="contained" onClick={getEmptyFormData}>
              Lägg till
            </Button>
          </Grid>
        </Box>
      </Container>
      <Box bgcolor="background.paper" borderRadius="8px" pt={5} mt={4}>
        <ClpTable
          columns={columns}
          rowProps={(row) => ({
            onClick: () => getFormData(row.original)
          })}
          rows={vehicleAlarmNotificationsResult.status === 2 && vehicleAlarmNotificationsResult.data}
          status={vehicleAlarmNotificationsResult.status}
        />
      </Box>
    </div>
  )
}
export default VehicleAlarmSettings
