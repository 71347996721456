import { faCalendar, faDiamondExclamation, faEdit, faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Box, Chip, Divider, Grid, IconButton, Typography } from "@mui/material"
import TextField from "@mui/material/TextField"
import { customerHasFeature } from "auth/customerHasFeature"
import { hasRole } from "auth/hasRole"
import classnames from "classnames"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { SwitchColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import PaperBox from "components/PaperBox"
import globalUseStyles from "css/theme/globalUseStyles"
import theme from "css/theme/light"
import { Formik } from "formik"
import { allFeatures } from "generated/features"
import { allRoles } from "generated/roles"
import { companyValidationSchema } from "pages/systemadmin/pages/customers/components/validationSchema"
import { TabPanel } from "components/navigation/TabPanel"
import ViolationDrivingTypesAdd from "pages/violation/components/ViolationDrivingTypesAdd"
import ViolationDrivingTypesRemove from "pages/violation/components/ViolationDrivingTypesRemove"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import * as Yup from "yup"
import DepartmentForm from "./DepartmentForm"
import DepartmentsList from "./DepartmentsList"
import VtrSwitch from "./VtrSwitch"
import { useStyles } from "./useStyles"

export function departmentsWithListId(items) {
  return items.map((item) => {
    return {
      ...item,
      listId: item.id
    }
  })
}

function violationDriverTypesList(items) {
  return items?.map((item) => {
    return {
      ...item,
      listId: item.id
    }
  })
}

function companyInitialValues(company) {
  return company
    ? company
    : {
        id: 0,
        name: "",
        organisationNumber: "",
        streetAddress: "",
        postalCode: "",
        cityAddress: "",
        hasVtrIntegration: false,
        bplUrl: null
      }
}

export const CompanyForm = ({
  customer,
  company,
  workshopsResults,
  onSubmit,
  result,
  feedback,
  openRemoveDialog,
  tabValue,
  showSidebar,
  setShowSidebar,
  setCurrentWorkshop,
  editCompany
}) => {
  const appContext = useContext(AppContext)
  const classes = useStyles()
  const globalClasses = globalUseStyles()
  const [departments, setDepartments] = useState(company ? departmentsWithListId(company.departments) : [])
  const [violationDrivingTypes, setviolationDrivingTypes] = useState(
    editCompany ? violationDriverTypesList(editCompany.violationDrivingTypes) : []
  )
  const formRef = useRef()
  const editMode = company ? true : false

  const initialValues = companyInitialValues(company)

  const handleSubmit = async (values, { resetForm }) => {
    const customerId = customer.id
    const companyToCreate = {
      ...values,
      customerId: customerId,
      departments: departments,
      violationDrivingTypes: violationDrivingTypes
    }

    onSubmit(companyToCreate)
  }

  function openSidebar(workshop) {
    setCurrentWorkshop(workshop)
    setShowSidebar(!showSidebar)
  }

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Verkstad"
      },
      {
        accessor: "emailAddress",
        Header: "E-postadress"
      },
      {
        accessor: (row) => {
          return row.isActive ? "Ja" : "Nej"
        },
        Header: "Aktiv",
        mainFilter: true,
        defaultValue: "Ja",
        Filter: SwitchColumnFilter,
        FilterOptions: ["Ja", "Nej"]
      }
    ],
    []
  )

  useEffect(() => {
    if (hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)) {
      columns.push({
        accessor: "edit",
        numeric: false,
        disablePadding: true,
        Header: "Hantera",
        align: "right",
        Cell: ({ cell }) => {
          return (
            <IconButton
              color="primary"
              size="large"
              onClick={() => {
                openSidebar(cell.row.original)
              }}>
              <FontAwesomeIcon icon={faEdit} size="xs" />
            </IconButton>
          )
        }
      })
    }
  }, [])

  const workshopInitialFilters = [
    {
      id: "Aktiv",
      value: "Ja"
    }
  ]

  const [validationStep, setValidationStep] = useState(companyValidationSchema)

  useEffect(() => {
    if (initialValues.violationActive) {
      const violationObj = {
        violationEmail: Yup.string().nullable().required("E-post är obligatorisk")
      }
      setValidationStep(Yup.object().shape(violationObj))
    }
  }, [initialValues])

  return (
    <Formik
      innerRef={formRef}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationStep}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <PaperBox>
                  <Box mb={3}>
                    <Typography className={classes.boldText} variant="bodyMedium" color="textPrimary">
                      Bolagsinformation
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="name"
                        label="Namn"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && errors.name ? true : false}
                        helperText={touched.name && errors.name ? errors.name : null}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="organisationNumber"
                        label="Organisationsnummer"
                        value={values.organisationNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.organisationNumber && errors.organisationNumber ? true : false}
                        helperText={
                          touched.organisationNumber && errors.organisationNumber
                            ? errors.organisationNumber
                            : null
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="streetAddress"
                        label="Gatuaddress"
                        value={values.streetAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.streetAddress && errors.streetAddress ? true : false}
                        helperText={
                          touched.streetAddress && errors.streetAddress ? errors.streetAddress : null
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="postalCode"
                        label="Postnummer"
                        value={values.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.postalCode && errors.postalCode ? true : false}
                        helperText={touched.postalCode && errors.postalCode ? errors.postalCode : null}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="cityAddress"
                        label="Ort"
                        value={values.cityAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.cityAddress && errors.cityAddress ? true : false}
                        helperText={touched.cityAddress && errors.cityAddress ? errors.cityAddress : null}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      display="flex"
                      direction="row"
                      justifyContent={editMode ? "space-between" : "flex-end"}>
                      {editMode && (
                        <Box my={3}>
                          <Button color="primary" variant="outlined" onClick={openRemoveDialog}>
                            Ta bort bolag
                          </Button>
                        </Box>
                      )}
                      <Box my={3} mx={1}>
                        <Button
                          color="primary"
                          onClick={() => {
                            if (formRef.current) {
                              formRef.current.handleSubmit()
                            }
                          }}
                          result={result}
                          feedback={feedback}>
                          Spara
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </PaperBox>
              </Grid>
              <Grid item xs={12} md={4}>
                {customerHasFeature(appContext?.appUser?.customer, allFeatures.VehicleRegistry) && (
                  <VtrSwitch
                    img="assets/Transportstyrelsen-icon.jpg"
                    title="Transportstyrelsen"
                    id={`hasVtrIntegration`}
                    message="Ditt bolag har tillång att importera fordon från Transportstyrelsen"
                    value={values.hasVtrIntegration}
                    handleChange={() => {
                      setFieldValue(`hasVtrIntegration`, !values.hasVtrIntegration)
                    }}
                  />
                )}
                {customerHasFeature(appContext?.appUser?.customer, allFeatures.Planning) && (
                  <PaperBox>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      <Grid item xs={2}>
                        <Box>
                          <Avatar
                            aria-label="BPL Integration"
                            className={classnames(globalClasses.avatar, globalClasses.backgroundPrimary)}>
                            <FontAwesomeIcon icon={faCalendar} size="lg" />
                          </Avatar>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h5">BPL Integration</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Chip
                          color={values.bplUrl !== null ? "success" : "error"}
                          label={values.bplUrl !== null ? "Aktiverad" : "Ej aktiv"}
                        />
                      </Grid>
                    </Grid>
                    {values.bplUrl !== null ? (
                      <Box marginTop={2}>
                        <Divider />
                        <Box marginY={2}>
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid item xs={8} md={8}>
                              <Typography variant="h5" marginTop={2}>
                                Antal dagar
                              </Typography>
                              <Typography variant="bodyCompactSmall">
                                Antalet dagar visar hur långt fram förare ska se sina uppdrag
                              </Typography>
                            </Grid>
                            <Grid item xs={4} md={4} justifyContent="flex-end">
                              <TextField
                                autoComplete="off"
                                fullWidth
                                type="number"
                                id="bplDaysShown"
                                placeholder="1-200"
                                defaultValue={values.bplDaysShown ? values.bplDaysShown : 3}
                                value={values.bplDaysShown}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.bplDaysShown && errors.bplDaysShown ? true : false}
                                helperText={
                                  touched.bplDaysShown && errors.bplDaysShown ? errors.bplDaysShown : null
                                }
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        marginY={2}
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="column"
                        bgcolor={theme.palette.primary.light}
                        borderRadius="8px"
                        color={theme.palette.primary.dark}
                        p={2}
                        gap={1}>
                        <Box fontWeight="600">
                          <FontAwesomeIcon icon={faInfoCircle} size="1x" /> Kontakta support@cloudplanning.se
                          för att aktivera integration till BPL
                        </Box>
                      </Box>
                    )}
                  </PaperBox>
                )}

                {customerHasFeature(appContext?.appUser?.customer, allFeatures.Violation) && (
                  <PaperBox>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      <Grid item xs={2}>
                        <Box>
                          <Avatar
                            aria-label="Violation"
                            className={classnames(globalClasses.avatar, globalClasses.backgroundPrimary)}>
                            <FontAwesomeIcon icon={faDiamondExclamation} size="lg" />
                          </Avatar>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h5">Överträdelse</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Chip
                          color={values.violationActive ? "success" : "error"}
                          label={values.violationActive ? "Aktiverad" : "Ej aktiv"}
                        />
                      </Grid>
                    </Grid>
                    {values.violationActive ? (
                      <Box marginTop={2}>
                        <Divider />
                        <Box marginY={2}>
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid item xs={12} mb={3}>
                              <TextField
                                autoComplete="off"
                                fullWidth
                                id="violationEmail"
                                label="E-post för överträdelser"
                                value={values.violationEmail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.violationEmail && errors.violationEmail ? true : false}
                                helperText={
                                  touched.violationEmail && errors.violationEmail
                                    ? errors.violationEmail
                                    : null
                                }
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} mb={3}>
                              <ViolationDrivingTypesAdd
                                onSubmit={(item) => {
                                  setviolationDrivingTypes((s) => {
                                    return [
                                      ...s,
                                      {
                                        ...item,
                                        id: 0
                                      }
                                    ]
                                  })
                                }}
                              />
                              <ViolationDrivingTypesRemove
                                categories={violationDrivingTypes}
                                removeViolationDrivingType={(item) => {
                                  setviolationDrivingTypes((s) => {
                                    return s.filter((x) => x.listId !== item.listId)
                                  })
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        marginY={2}
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="column"
                        bgcolor={theme.palette.primary.light}
                        borderRadius="8px"
                        color={theme.palette.primary.dark}
                        p={2}
                        gap={1}>
                        <Box fontWeight="600">
                          <FontAwesomeIcon icon={faInfoCircle} size="1x" /> Kontakta bolagsadministratör för
                          aktivera överträdelser på bolaget
                        </Box>
                      </Box>
                    )}
                  </PaperBox>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <PaperBox>
                  <Box mb={3}>
                    <Typography className={classes.boldText} variant="bodyMedium" color="textPrimary">
                      Avdelningar
                    </Typography>
                  </Box>
                  <DepartmentForm
                    onSubmit={(item) => {
                      setDepartments((s) => {
                        return [
                          ...s,
                          {
                            ...item,
                            id: 0,
                            companyId: editMode ? company.id : 0
                          }
                        ]
                      })
                    }}
                  />
                  <DepartmentsList
                    departments={departments}
                    removeDepartment={(item) => {
                      setDepartments((s) => {
                        return s.filter((x) => x.listId !== item.listId)
                      })
                    }}
                  />
                  <Grid container direction="row" justifyContent={editMode ? "space-between" : "flex-end"}>
                    {editMode && (
                      <Box my={3}>
                        <Button color="primary" variant="outlined" onClick={openRemoveDialog}>
                          Ta bort bolag
                        </Button>
                      </Box>
                    )}
                    <Box my={3} mx={1}>
                      <Button
                        color="primary"
                        onClick={() => {
                          if (formRef.current) {
                            formRef.current.handleSubmit()
                          }
                        }}
                        result={result}
                        feedback={feedback}>
                        Spara
                      </Button>
                    </Box>
                  </Grid>
                </PaperBox>
              </Grid>
            </Grid>
          </TabPanel>
          {workshopsResults && (
            <TabPanel style={{ width: "80%" }} value={tabValue} index={2}>
              <PaperBox>
                <ClpTable
                  columns={columns}
                  rows={workshopsResults.status === 2 && workshopsResults.data}
                  status={workshopsResults.status}
                  initialFilters={workshopInitialFilters}
                />
              </PaperBox>
            </TabPanel>
          )}
        </form>
      )}
    </Formik>
  )
}
