import { IDeviationPriorityProps } from "pages/deviation/deviation.interface"

export const priorityOptions: Array<{ label: string; value: number }> = [
  { label: "Ingen", value: 0 },
  { label: "Låg", value: 1 },
  { label: "Medel", value: 2 },
  { label: "Hög", value: 3 }
]

export const deviationPriorityProps = (id: number) => {
  switch (id) {
    case 0:
      return {
        title: "Ingen",
        color: "default"
      } as IDeviationPriorityProps
    case 1:
      return {
        title: "Låg",
        color: "primary"
      } as IDeviationPriorityProps
    case 2:
      return {
        title: "Medel",
        color: "warning"
      } as IDeviationPriorityProps
    case 3:
      return {
        title: "Hög",
        color: "error"
      } as IDeviationPriorityProps
    default:
      return {
        title: "Ingen",
        color: "default"
      } as IDeviationPriorityProps
  }
}
