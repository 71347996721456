import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Box, Button, Container, Grid, IconButton, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import CustomFieldForm from "./IssueReportCategoryForm"

import React, { useState } from "react"

const IssueReportCategorys = () => {
  const globalClasses = globalUseStyles()
  const loadingContext = React.useContext(GlobalLoaderContext)

  const [categoryResult, categoryFuncs] = useApi()

  React.useEffect(() => {
    categoryFuncs.get(apiEndpoints.issuereportsadmin.getissuereportcategories)
    return () => {
      categoryFuncs.leave()
    }
  }, [categoryFuncs])

  React.useEffect(() => {
    loadingContext.setLoading(() => categoryResult.status === 1)
  })

  const [editMode, setEditMode] = useState(false)
  const [showSidebarForm, setShowSidebarForm] = useState(false)

  // datafetch states and handlers
  // const [listResult, listApi] = useApi();
  const [addResult, addApi] = useApi()
  const [updateResult, updateApi] = useApi()
  const [editItemResult, editItemApi] = useApi()

  // page events from form (after Formik has validated, but the server can still say no so form listens to add/update result state)
  const onAddSubmit = (values) => {
    addApi.post(apiEndpoints.issuereportsadmin.createissuereportcategory, values)
  }
  const onUpdateSubmit = (values) => {
    updateApi.put(apiEndpoints.issuereportsadmin.updateissuereportcategory, values)
  }

  const openEdit = (id) => {
    // show the sidebar in editMode, load any dependant data and pass it along as props
    setEditMode(true)
    setShowSidebarForm(true)
    editItemApi.get(apiEndpoints.issuereportsadmin.getissuereportcategorybyid.replace("{id}", id))
    updateApi.reset()
    addApi.reset()
  }

  const openAdd = () => {
    // show sidebar in !editMode, if anything needs to be loaded it also needs to be triggered and passed as prop
    setEditMode(false)
    setShowSidebarForm(true)
    editItemApi.reset()
    updateApi.reset()
    addApi.reset()
  }

  React.useEffect(() => {
    loadingContext.setLoading(() => categoryFuncs.status === 1)
  })

  React.useEffect(() => {
    if (updateResult.status === 2 || addResult.status === 2) {
      categoryFuncs.get(apiEndpoints.issuereportsadmin.getissuereportcategories)
      setShowSidebarForm(false)
    }
  }, [updateResult, addResult, categoryFuncs])

  const columns = [
    {
      accessor: "name",
      Header: "Namn"
    },
    {
      accessor: "totalIssueReports",
      Header: "Antal felrapporter"
    },
    {
      accessor: "edit",
      numeric: false,
      disablePadding: true,
      Header: "",
      align: "right",
      Cell: ({ cell }) => {
        return (
          <IconButton
            onClick={() => {
              openEdit(cell.row.original.id)
            }}
            size="large">
            <FontAwesomeIcon icon={faEdit} size="xs" />
          </IconButton>
        )
      }
    }
  ]

  const listRows = categoryResult.status === 2 ? categoryResult.data : []
  return (
    <div>
      <Container>
        <CustomFieldForm
          showSidebar={showSidebarForm}
          setShowSidebarForm={setShowSidebarForm}
          editItemResult={editItemResult}
          addResult={addResult}
          updateResult={updateResult}
          editMode={editMode}
          onAddSubmit={onAddSubmit}
          onUpdateSubmit={onUpdateSubmit}
        />
        <Box marginY={3} mb={2}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box order={2} p={1} flexGrow={2}>
              <Grid item>
                <Typography variant="h2">Kategorier</Typography>
                <Typography>Administrera kategorier för felrapporter</Typography>
              </Grid>
            </Box>
            <Box order={3} p={1}>
              <Button
                onClick={() => {
                  openAdd()
                }}
                variant="contained"
                endIcon={<AddCircleIcon />}
                color="primary">
                Ny kategori
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rows={categoryResult.status === 2 && listRows}
          status={categoryResult.status}
        />
      </Box>
    </div>
  )
}

export default IssueReportCategorys
