import AddCircleIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/Delete"
import { Button } from "@mui/material"
import { Box } from "@mui/system"
import ClpButton from "components/Button"
import { allRoutes } from "generated/routes"
import React from "react"
import { Link, useHistory } from "react-router-dom"

const WorkOrderButtons = ({
  values,
  buttonFeedbackResultDelete,
  setShowDeleteModal,
  buttonFeedbackResultUpdate,
  setShowUpdateModal,
  buttonFeedbackResult,
  initialValues
}) => {
  const routerHistory = useHistory()
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        justifyContent: initialValues.id > 0 && initialValues.status < 2 ? "center" : "space-between"
      }}
      mt={5}>
      <Box order={1} pr={2}>
        <Button
          variant="outlined"
          onClick={() => {
            routerHistory.push(allRoutes.IssuereportsWorkorders())
          }}
          color="primary">
          Tillbaka
        </Button>
      </Box>

      {initialValues.id > 0 && initialValues.status < 2 && (
        <Box order={1} flexGrow={2}>
          <ClpButton
            result={buttonFeedbackResultDelete}
            onClick={() => {
              setShowDeleteModal(true)
            }}
            endIcon={<DeleteIcon />}
            color="error"
            variant="contained">
            Ta bort arbetsorder
          </ClpButton>
        </Box>
      )}

      <Box order={2} display="flex">
        {values.status === 1 && (
          <Box>
            {values.id !== 0 ? (
              <ClpButton
                result={buttonFeedbackResultUpdate}
                onClick={() => {
                  setShowUpdateModal(true)
                }}
                color="primary"
                variant="contained">
                Spara
              </ClpButton>
            ) : (
              <ClpButton result={buttonFeedbackResult} type="submit" color="primary" variant="contained">
                Spara
              </ClpButton>
            )}
          </Box>
        )}

        {values.status === 6 && (
          <Box ml={1}>
            <ClpButton
              color="primary"
              component={Link}
              to={{
                pathname: allRoutes.IssuereportsWorkordersAdd(),
                state: values.vehicleId
              }}
              endIcon={<AddCircleIcon />}
              variant="contained">
              Skapa arbetsorder
            </ClpButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default WorkOrderButtons
