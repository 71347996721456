import { Box } from "@mui/material"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import SuccessDialog from "components/Dialogs/SuccessDialog"
import ErrorDialog from "components/ErrorDialog"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useContext, useEffect, useMemo, useState } from "react"
import Header from "./components/Header/Header"
import ImportVtrForm from "./components/ImportVtrForm/ImportVtrForm"
import { useStyles } from "./useStyles"

const ImportVtr = () => {
  // states
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showContinueModal, setShowContinueModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  // styles
  const classes = useStyles()

  // a page with a list and a drawer for add/edit form
  const globalClasses = globalUseStyles()

  // fetch data
  const [addResult, addApi] = useApi()
  const [lastUpdatedDate, lastUpdatedDateFunc] = useApi()
  const [vtrResult, vtrApi] = useApi()

  // send data on submit
  const onAddSubmit = () => {
    addApi.get(apiEndpoints.vehicleregistryadmin.importvtrvehicles)
  }

  // loading when waiting on list
  const loadingContext = useContext(GlobalLoaderContext)
  useEffect(() => {
    loadingContext.setLoading(() => vtrResult.status === 1)
  })

  // load the list on first visit
  useEffect(() => {
    vtrApi.get(apiEndpoints.vehicleregistryadmin.getvehicledetailsbyorgnr)
    return () => {
      vtrApi.leave()
    }
  }, [vtrApi])

  // get last updated date
  useEffect(() => {
    lastUpdatedDateFunc.get(apiEndpoints.vehicleregistry.getlastupdatefromtransportagency)
    return () => {
      lastUpdatedDateFunc.leave()
    }
  }, [lastUpdatedDateFunc])

  // show sidebar
  const [showSidebarForm, setShowSidebarForm] = useState(false)
  const openAdd = () => {
    setShowSidebarForm(true)
  }

  // update grid on add api call success finish, and close the drawer
  useEffect(() => {
    if (addResult.status === 2) {
      setShowSidebarForm(false)
      setShowSuccessModal(true)
      vtrApi.get(apiEndpoints.vehicleregistry.getvehicledetailsbyorgnr)
    }
  }, [addResult, vtrApi])

  // clptable columns
  const columns = useMemo(
    () => [
      {
        accessor: "bolag",
        Header: "Bolag"
      },
      {
        accessor: "regNr",
        Header: "Regnr"
      },
      {
        accessor: "organisationsNr",
        Header: "Organisationsnr"
      },
      {
        accessor: "fordonsTyp",
        Header: "Fordonstyp"
      }
    ],
    []
  )

  const listRows = vtrResult.status === 2 ? vtrResult.data : []

  return (
    <div>
      {showErrorModal && (
        <ErrorDialog
          title="Import av fordon misslyckades"
          message="Försök importera fordonen igen. Om problem kvarstår kontakta administatör."
        />
      )}
      {showSuccessModal && (
        <SuccessDialog setShowSidebarForm={setShowSidebarForm} title="Import av fordon lyckades!" />
      )}
      {showContinueModal && (
        <ContinueDialog
          setShowContinueModal={setShowContinueModal}
          onAddSubmit={onAddSubmit}
          result={addResult}
          message={`Du kommer importera ${listRows.length} st fordon, vill du slutföra import?`}
        />
      )}

      <ImportVtrForm
        setShowContinueModal={setShowContinueModal}
        listRows={listRows}
        showSidebar={showSidebarForm}
        setShowSidebar={setShowSidebarForm}
      />
      <Header lastUpdatedDate={lastUpdatedDate?.data} openAdd={openAdd} dashboardResult={listRows} />
      <Box mt={5} className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rows={vtrResult.status === 2 && vtrResult.data}
          status={vtrResult.status}
        />
      </Box>
    </div>
  )
}

export default ImportVtr
