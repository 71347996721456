import { IEventProps } from "./event.interface"

export enum EventType {
  Besiktning = "Besiktning",
  Service = "Service"
}

export interface IInspection {
  companyId: number
  regNumber: string
  internNumber: string
  customFieldVehicleValueId: number
  inspectionBooking: {
    id: number
    dateTime: Date
    carWorkshopId: number
    inspectionType: string
  }
}

export interface CheckButtonProps {
  event?: IEventProps
  openSidebar: (event: any) => void,
  handleClose: () => void,
}