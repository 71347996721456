import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, FormControlLabel, Grid, InputLabel, Switch, TextField, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { StandardButton } from "components/Button"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { Field } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export const CustomerAdminInformation = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "settings.form" })
  const [newPasswordResult, newPasswordApi] = useApi()
  const [generatedPassword, setGeneratedPassword] = useState()
  const [usernameExistResult, checkIfUsernameExistApi] = useApi()
  useEffect(() => {
    if (newPasswordResult.status === 2) {
      setGeneratedPassword(newPasswordResult.data)
      setFieldValue("adminUser.password", newPasswordResult.data)
    }
  }, [newPasswordResult, setFieldValue])

  useEffect(() => {
    if (generatedPassword) {
      setFieldTouched("adminUser.password", true)
    }
  }, [generatedPassword])

  useEffect(() => {
    const loadStuff = async () => {
      await checkIfUsernameExistApi.get(
        apiEndpoints.systemadmincustomers.checkifusernamealreadyexist.replace(
          "{userName}",
          values.adminUser?.username
        )
      )
    }
    loadStuff()
  }, [checkIfUsernameExistApi, values.adminUser?.username])

  useEffect(() => {
    if (usernameExistResult.status === 2) {
      setFieldValue("adminUser.existingUsername", usernameExistResult.data)
    }
  }, [usernameExistResult, setFieldValue])
  return (
    <PaperBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel>
            <Typography variant="labelMedium" fontWeight={500} color={theme.palette.foreground.default}>
              {t("name")}
            </Typography>
          </InputLabel>
          <Field name="adminUser.name">
            {({ field, form: { touched, errors }, meta }) => (
              <TextField
                {...field}
                autoComplete="off"
                fullWidth
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.adminUser?.name && errors.adminUser?.name ? true : false}
                helperText={touched.adminUser?.name && errors.adminUser?.name ? errors.adminUser?.name : null}
                variant="outlined"
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            <Typography variant="labelMedium" fontWeight={500} color={theme.palette.foreground.default}>
              {t("phone")}
            </Typography>
          </InputLabel>
          <Field name="adminUser.phone">
            {({ field, form: { touched, errors }, meta }) => (
              <TextField
                {...field}
                autoComplete="off"
                fullWidth
                id="phone"
                value={values.adminUser?.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.adminUser?.phone && errors.adminUser?.phone ? true : false}
                helperText={
                  touched.adminUser?.phone && errors.adminUser?.phone ? errors.adminUser?.phone : null
                }
                variant="outlined"
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                onChange={() =>
                  setFieldValue("adminUser.sendEmailOnCreate", !values.adminUser?.sendEmailOnCreate)
                }
                id="adminUser.sendEmailOnCreate"
                checked={values.adminUser?.sendEmailOnCreate}
              />
            }
            label={t("send_password_to_email")}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            <Typography variant="labelMedium" fontWeight={500} color={theme.palette.foreground.default}>
              {t("email")}
            </Typography>
          </InputLabel>
          <Field name="adminUser.email">
            {({ field, form: { touched, errors }, meta }) => (
              <TextField
                {...field}
                autoComplete="off"
                fullWidth
                id="email"
                value={values.adminUser?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.adminUser?.email && errors.adminUser?.email ? true : false}
                helperText={
                  touched.adminUser?.email && errors.adminUser?.email ? errors.adminUser?.email : null
                }
                variant="outlined"
              />
            )}
          </Field>
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            <Typography variant="labelMedium" fontWeight={500} color={theme.palette.foreground.default}>
              {t("username")}
            </Typography>
          </InputLabel>
          <Field name="adminUser.username">
            {({ field, form: { touched, errors }, meta }) => (
              <TextField
                {...field}
                autoComplete="off"
                fullWidth
                id="username"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.adminUser?.username && errors.adminUser?.username ? true : false}
                helperText={
                  touched.adminUser?.username && errors.adminUser?.username
                    ? errors.adminUser?.username
                    : null
                }
                variant="outlined"
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            <Typography variant="labelMedium" fontWeight={500} color={theme.palette.foreground.default}>
              {t("password")}
            </Typography>
          </InputLabel>
          <Field name="adminUser.password">
            {({ field, form: { touched, errors }, meta }) => (
              <TextField
                {...field}
                autoComplete="off"
                fullWidth
                id="password"
                type={generatedPassword === values.adminUser?.password ? "text" : "password"}
                value={values.adminUser?.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.adminUser?.password && errors.adminUser?.password ? true : false}
                helperText={
                  touched.adminUser?.password && errors.adminUser?.password
                    ? errors.adminUser?.password
                    : null
                }
                variant="outlined"
              />
            )}
          </Field>
          <Box display="flex" alignContent="center" justifyContent="center" marginTop={3}>
            <StandardButton
              color="primary"
              startIcon={<FontAwesomeIcon spin={newPasswordResult.status === 1} icon={faCircleNotch} />}
              onClick={() => {
                newPasswordApi.get(apiEndpoints.util.getpasswordsuggestion)
              }}>
              {t("button.generate_secure_password")}
            </StandardButton>
          </Box>
        </Grid>
      </Grid>
    </PaperBox>
  )
}
