import { Grid } from "@mui/material"
import PaperBox from "components/PaperBox"
import LoadingBox from "components/skeleton/LoadingBox"
import LoadingTypography from "components/skeleton/LoadingTypography"
import React from "react"

const LoadingAvailableExamsTemplate = () => {
  return [0, 1].map((value) => (
    <Grid item xs={12} md={6} key={value}>
      <PaperBox>
        <LoadingBox height="175px" width="100%" />
        <LoadingTypography variant="h6" />
        <LoadingTypography variant="bodySmall" width="60%" />
      </PaperBox>
    </Grid>
  ))
}
export default LoadingAvailableExamsTemplate
