import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect } from "react"
import IssueReports from "../../components/IssueReports"

const ReportDetails = ({ match }) => {
  const [issuereportResult, issuereportFuncs] = useApi()
  const loadingContext = React.useContext(GlobalLoaderContext)
  useEffect(() => {
    issuereportFuncs.get(apiEndpoints.issuereports.getissuereport.replace("{id}", match.params.id))

    return () => {
      issuereportFuncs.leave()
    }
  }, [issuereportFuncs, match.params.id])

  useEffect(() => {
    loadingContext.setLoading(() => issuereportResult.status === 1)
  }, [issuereportResult.status])
  return (
    <div>
      <IssueReports issuereportResult={issuereportResult} />
    </div>
  )
}
export default ReportDetails
