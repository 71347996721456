import { Box, Container, Grid, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect } from "react"
import Moment from "react-moment"

const DataCell = ({ item }: any) => {
  return (
    <Box display="flex">
      <Typography variant="bodySmall" color={theme.palette.grey[600]} marginRight={1}>
        {item[0]}:
      </Typography>
      {item[1]}
    </Box>
  )
}
const SystemEvents = () => {
  const [systemEventsResult, listSystemEvents] = useApi() as [IApiResult, IApiFunc]

  useEffect(() => {
    listSystemEvents.get(apiEndpoints.systemadmindeviceregistry.listsystemevents)

    return () => {
      listSystemEvents.leave()
    }
  }, [listSystemEvents])

  const columns = [
    {
      accessor: "eventTime",
      numeric: false,
      Header: "Tidpunkt",
      Cell: ({ cell }: any) => {
        return (
          <Moment utc local format="YYYY-MM-DD HH:mm">
            {cell.row.original.eventTime}
          </Moment>
        )
      }
    },
    {
      accessor: "user.name",
      Header: "Användare",
      mainFilter: true,
      Filter: SelectColumnFilter
    },
    {
      accessor: "systemEventType",
      numeric: false,
      Header: "Typ",
      mainFilter: true,
      Filter: SelectColumnFilter
    },

    {
      accessor: "serializedDictionaryValues",
      numeric: false,
      Header: "Data",
      Cell: ({ cell }: any) => {
        return Object.entries(cell.row.original.dictionaryValues).map(
          (item, key) => item && <DataCell item={item} />
        )
      }
    }
  ]

  return (
    <div>
      <Container>
        <Box marginY={3} mb={2}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box order={2} p={1} flexGrow={2}>
              <Grid item>
                <Typography variant="h1"> Systemhändelser</Typography>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box>
        <ClpTable
          rows={systemEventsResult.status === 2 && systemEventsResult.data}
          status={systemEventsResult.status}
          columns={columns}
        />
      </Box>
    </div>
  )
}
export default SystemEvents
