import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Grid, Typography } from "@mui/material"
import { AppContext } from "components/AppContextProvider"
import theme from "css/theme/light"
import moment from "moment"
import { useContext, useState } from "react"
import { CalendarProps, DateFormat, Event } from "react-big-calendar"
import { EventDialogProps } from "../BigCalendar"
import CalendarContext from "../context/CalendarStateProvider"
import { statusColor, statusIcon } from "../helpers/calendarStatusEvent"
import { CalenderListItemStyled } from "../style/CalenderListViewItem.styled"
import { EventDialog } from "./CalendarDialog"

const eventsList = ({ date, events, view }: any) => {
  switch (view) {
    case "month":
      return events.filter((event: Event) => {
        const currentDate = moment(date)
        return moment(event.start).isSame(currentDate, "month")
      })
    case "week":
      return events.filter((event: Event) => {
        const currentDate = moment(date)

        return moment(event.start).isSame(currentDate, "week")
      })
    default:
      return events.filter((event: Event) => {
        const currentDate = moment(date)

        return moment(event.start).isSame(currentDate, "day")
      })
  }
}

function CalendarListView({ ...props }: CalendarProps) {
  const { date, events } = props
  const { view, setView, setDate, loadingEvents } = useContext(CalendarContext)
  const { selectedLanguage } = useContext(AppContext)
  const currentEvents = eventsList({ date, events, view })
  const [eventDialog, setEventDialog] = useState<EventDialogProps | null>(null)
  let groupedEvents = currentEvents
    .sort((a: any, b: any) => moment(a.start).valueOf() - moment(b.start).valueOf())
    .reduce((grouped: any, item: any) => {
      const year = `${moment(item.start).locale(selectedLanguage).format("YYYY")}`
      const month = `${moment(item.start).locale(selectedLanguage).format("MMMM")}`
      const day = `${moment(item.start)}`

      if (!grouped[year]) {
        grouped[year] = {}
      }

      if (!grouped[year][month]) {
        grouped[year][month] = {}
      }

      if (!grouped[year][month][day]) {
        grouped[year][month][day] = []
      }

      const eventItem: any = {
        title: item.title,
        subtitle: item.subtitle,
        start: item.start,
        end: item.end,
        iconProps: {
          icon: statusIcon(item.typeId, item.status) as IconProp,
          color: statusColor(item.typeId, item?.status)
        }
      }

      grouped[year][month][day].push(eventItem)

      return grouped
    }, {})

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {groupedEvents !== null && Object.entries(groupedEvents).length > 0 ? (
        Object.entries(groupedEvents).map(([groupedYear, years], index) => {
          return (
            <>
              {index !== 0 && Object.entries(groupedEvents)[index - 1].toString() !== groupedYear && (
                <Box display="flex" flexDirection="column" marginTop={3}>
                  <Typography variant="h2" color="text.primary" textTransform="capitalize">
                    {groupedYear}
                  </Typography>
                </Box>
              )}

              {Object.entries(years as any[]).map(([groupedMonth, days]) => {
                return (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h5" color="text.primary" gutterBottom textTransform="capitalize">
                      {groupedMonth}
                    </Typography>
                    {Object.entries(days as any[]).map(([groupedDay, items]) => {
                      return (
                        <CalenderListItemStyled
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          borderRadius={2}
                          marginBottom={1}
                          paddingY={2}>
                          <Grid container direction="row">
                            <Grid item direction="column" textAlign="center" sx={{ width: "50px" }}>
                              <Typography
                                variant="h4"
                                color={theme.palette.primary.dark}
                                onClick={() => {
                                  setView("day")
                                  setDate(new Date(groupedDay))
                                }}>
                                {moment(groupedDay).locale(selectedLanguage).format("D")}
                              </Typography>
                            </Grid>
                            <Grid item xs="auto">
                              <Box
                                display="flex"
                                flexDirection="column"
                                style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.14)" }}>
                                {items.map((item: any, index: number) => {
                                  return (
                                    <Box
                                      onClick={() =>
                                        setEventDialog({
                                          event: item,
                                          open: true,
                                          onClose: () => setEventDialog(prevState => prevState && ({ ...prevState, open: false, }))
                                        })
                                      }
                                      display="flex"
                                      flexDirection="row"
                                      gap={3}
                                      marginBottom={index !== items.length - 1 ? 1 : 0}
                                      paddingLeft={3}>
                                      {item.iconProps && item.iconProps.icon && (
                                        <FontAwesomeIcon
                                          icon={item.iconProps.icon}
                                          color={item.iconProps.color}
                                        />
                                      )}
                                      {item.end !== null && item.start !== item.end && (
                                        <Typography
                                          variant="labelSmall"
                                          fontWeight={600}
                                          color={theme.palette.primary.dark}>
                                          {moment(item.start).locale(selectedLanguage).format("HH:mm")}

                                          {!item.allDay && (
                                            <>-{moment(item.end).locale(selectedLanguage).format("HH:mm")}</>
                                          )}
                                        </Typography>
                                      )}
                                      <Typography variant="labelSmall">
                                        {item.title} {item.subtitle}
                                      </Typography>
                                    </Box>
                                  )
                                })}
                              </Box>
                            </Grid>
                          </Grid>
                        </CalenderListItemStyled>
                      )
                    })}
                  </Box>
                )
              })}
            </>
          )
        })
      ) : (
        <Box textAlign="center" paddingY={3}>
          <Typography variant="h4" color="textSecondary">
            {loadingEvents && groupedEvents !== null ? <>Laddar...</> : <>Inga händelser</>}
          </Typography>
        </Box>
      )}
      {eventDialog && <EventDialog {...eventDialog} />}
    </div>
  )
}

CalendarListView.title = (date: Date, localized: { formats: DateFormat[]; culture: string }) => {
  return `${moment(date).format("MMMM")}`
}
export default CalendarListView
