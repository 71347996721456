import { faCheck, faChevronDown, faSync, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material"
import theme from "css/theme/light"
import React, { useEffect, useState } from "react"
import { useStyles } from "./useStyles"

const fuelDataHeader = (errorMessages) => {
  if (errorMessages) {
    if (
      (errorMessages.invalidRows &&
        errorMessages.invalidRows.length > 0 &&
        errorMessages.nrOfValidRows &&
        errorMessages.nrOfValidRows > 0) ||
      (errorMessages.nrOfUpdatedRows && errorMessages.nrOfUpdatedRows > 0)
    ) {
      return "Lyckade rader har importerats!"
    } else if (
      (errorMessages.invalidRows &&
        errorMessages.invalidRows.length < 1 &&
        errorMessages.nrOfValidRows &&
        errorMessages.nrOfValidRows > 0) ||
      (errorMessages.nrOfUpdatedRows && errorMessages.nrOfUpdatedRows > 0)
    ) {
      return "Importering lyckad!"
    } else {
      return "Inga rader kunde importeras."
    }
  }
}

const fuelDataErrorMessage = (errorMessages) => {
  if (errorMessages) {
    if (
      (errorMessages.invalidRows &&
        errorMessages.invalidRows.length > 0 &&
        errorMessages.nrOfValidRows &&
        errorMessages.nrOfValidRows > 0) ||
      (errorMessages.nrOfUpdatedRows && errorMessages.nrOfUpdatedRows > 0)
    ) {
      return "Du kan granska de misslyckade raderna nedan."
    } else if (
      (errorMessages.invalidRows &&
        errorMessages.invalidRows.length < 1 &&
        errorMessages.nrOfValidRows &&
        errorMessages.nrOfValidRows > 0) ||
      (errorMessages.nrOfUpdatedRows && errorMessages.nrOfUpdatedRows > 0)
    ) {
      return "Alla rader kunde importeras."
    } else {
      return "Du kan granska de misslyckade raderna nedan och göra om importen."
    }
  }
}
export const SuccessArea = ({ submitResult }) => {
  const classes = useStyles()

  const [errorMessages, setErrorMessages] = useState()
  useEffect(() => {
    setErrorMessages(submitResult.data)
  }, [submitResult.data])

  return (
    <Container>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item>
          <Box bgcolor="background.dark" color="foreground.inverted" borderRadius="8px" padding={10}>
            <Typography variant="h1" align="center" gutterBottom>
              {fuelDataHeader(errorMessages)}
            </Typography>
            <Typography variant="bodySmall" align="center">
              {fuelDataErrorMessage(errorMessages)}
            </Typography>
            <Box marginTop={5} />
            <Grid container direction="row" justifyContent="center">
              {errorMessages && (
                <Grid item>
                  <List className={classes.list}>
                    <ListItem>
                      <ListItemIcon>
                        <FontAwesomeIcon color={theme.palette.success["main"]} icon={faCheck} />
                      </ListItemIcon>

                      <ListItemText
                        primary={`${
                          errorMessages.nrOfValidRows && errorMessages.nrOfValidRows
                        } tankrader importerades`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FontAwesomeIcon color={theme.palette.primary["main"]} icon={faSync} />
                      </ListItemIcon>

                      <ListItemText
                        primary={`${
                          errorMessages.nrOfUpdatedRows && errorMessages.nrOfUpdatedRows
                        } uppdaterade tankrader`}
                      />
                    </ListItem>
                    <Accordion className={classes.accordion}>
                      <ListItem>
                        <ListItemIcon>
                          <FontAwesomeIcon color={theme.palette.error.main} icon={faTimes} />
                        </ListItemIcon>
                        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                          <Typography>
                            {`${
                              errorMessages.invalidRows && errorMessages.invalidRows.length
                            } tankrader kunde inte importeras`}
                          </Typography>
                        </AccordionSummary>
                      </ListItem>
                      <AccordionDetails>
                        {errorMessages.invalidRows &&
                          errorMessages.invalidRows.map((row, index) => (
                            <Grid
                              key={index}
                              container
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}>
                              <Grid item>
                                <Typography>Radnr: {row.rowNr}</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{row.errorMessage}</Typography>
                              </Grid>
                            </Grid>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  </List>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
