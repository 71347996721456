export const UserNameAccronym = (props) => {
    const { name } = props;

    let nameOk = name && name.length > 0;

    let parts = name.split(' ');

    const abbr = parts.length > 1 ? "" + parts[0][0] + parts[1][0] : parts[0][0];

    return nameOk ? abbr : "??";
}