const { makeStyles } = require("@mui/styles")

export const useStyles = makeStyles((theme) => ({
  //NodataCard
  card: {
    display: "flex"
  },
  navLink: {
    textDecoration: "none"
    // color: theme.palette.cyan[400]
  },
  noNewsAvatar: {
    width: "60px",
    height: "60px",
    // background: theme.palette.cyan[100],
    color: theme.palette.primary[600],
    marginRight: "16px"
  },
  noNewsContent: {
    display: "flex",
    alignItems: "center",
    padding: "24px"
  }
}))
