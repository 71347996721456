import { Container } from "@mui/material"
import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useContext, useEffect } from "react"
import { CustomerForm } from "../../../../components/CustomerForm"
import { EditCustomerNavigation } from "../../components/EditCustomerNavigation"

const EditCustomer = ({ match }) => {
  const [customerResult, customerFuncs] = useApi()
  const [saveResult, saveFuncs] = useApi()

  const [logoResult, logoFuncs] = useApi()
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    const getData = async () => {
      await customerFuncs.get(
        apiEndpoints.systemadmincustomers.getcustomerbyid.replace("{id}", match.params.id)
      )
    }
    getData()
  }, [customerFuncs, match.params.id])

  useEffect(() => {
    if (customerResult.data && customerResult.data.logoName) {
      logoFuncs.get(
        apiEndpoints.systemadmincustomers.getcustomerlogobyname.concat(
          "?name=" + encodeURIComponent(customerResult.data.logoName)
        )
      )
    }
  }, [customerResult, logoFuncs])

  useEffect(() => {
    loadingContext.setLoading(() => customerResult.status === 1)
  })

  useEffect(() => {
    loadingContext.setLoading(() => logoResult.status === 1)
  })

  const feedback = {
    errorTitle: "Kunde inte spara kund"
  }

  const handleSubmit = async (values) => {
    saveFuncs.put(apiEndpoints.systemadmincustomers.updatecustomer, {
      ...values
    })
  }

  return (
    <div>
      <EditCustomerNavigation customerId={match.params.id} />
      <Container>
        {customerResult.status === 2 && (logoResult.status === 2 || !customerResult.data.logoName) ? (
          <CustomerForm
            editCustomer={customerResult.data}
            saveResult={saveResult}
            feedback={feedback}
            logoResult={logoResult}
            handleSubmit={handleSubmit}
          />
        ) : null}
      </Container>
    </div>
  )
}
export default EditCustomer
