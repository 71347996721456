import React, { useEffect, useContext } from "react";
import { apiEndpoints } from "generated/apiEndpoints";
import { Typography, Container, Grid, Box } from "@mui/material";
import { useApiGet, useApiPut } from "auth/useApi2";
import PaperBox from "components/PaperBox";
import { StorageForm } from "../../components/StorageForm";
import { GlobalLoaderContext } from "components/GlobalLoadingProvider";
import { PageTabs } from "components/navigation/PageTabs";
import { allRoutes } from "generated/routes";

const StorageEdit = ({ match }) => {
  const [storageResult, getStorage] = useApiGet();
  const [editStorageResult, editStorage] = useApiPut();
  const [companyResult, getCompany] = useApiGet();
  const loadingContext = useContext(GlobalLoaderContext);

  useEffect(() => {
    getStorage(
      apiEndpoints.systemadmindeviceregistry.getstoragebyid.replace(
        "{id}",
        match.params.id
      )
    );
    getCompany(apiEndpoints.systemadmindeviceregistry.listcompanies);
  }, [getStorage, match.params.id, getCompany]);

  const handleSubmit = (objectToSave) => {
    objectToSave.companyId = objectToSave.company.id;
    editStorage(
      apiEndpoints.systemadmindeviceregistry.updatestorage,
      objectToSave
    );
  };

  useEffect(() => {
    loadingContext.setLoading(() => companyResult.status === 1);
  });

  useEffect(() => {
    loadingContext.setLoading(() => storageResult.status === 1);
  });

  const feedback = {
    errorTitle: "Lager kunde inte skapas",
  };

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.SystemadminDevicesStorage(),
            title: "Tillbaka",
          },
        ]}
      />
      <Container>
        <Box marginY={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h2">Redigera lagerplats</Typography>
          </Grid>
        </Box>
        <PaperBox>
          {storageResult.status === 2 && companyResult.status === 2 ? (
            <StorageForm
              editStorage={storageResult.data}
              feedback={feedback}
              onSubmit={handleSubmit}
              result={editStorageResult}
              companyResult={companyResult}
            />
          ) : null}
        </PaperBox>
      </Container>
    </div>
  );
};
export default StorageEdit;
