import React from "react";
import { Chip } from "@mui/material";
import { useStyles } from "./useStyles";

export const VehicleAlarmChip = ({ status }) => {
    const classes = useStyles();
    return (
        <Chip
            size="small"
            className={
                status === "Aktiv"
                    ? classes.redChip
                    : status === "Avslutad"
                    ? classes.greenChip
                    : status === "Pågående"
                    ? classes.yellowChip
                    : null
            }
            label={status}
        />
    );
};
