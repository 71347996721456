import { Box, Grid, List, ListItem, Typography } from "@mui/material"
import Button from "components/Button"

const ViolationDrivingTypesRemove = ({ categories, removeViolationDrivingType }) => {
  return (
    <List>
      {categories.map((item, key) => {
        return (
          <ListItem key={key} divider disableGutters>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="bodySmall"> {item.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      removeViolationDrivingType(item)
                    }}
                    variant="link">
                    <Typography variant="caption">Ta bort</Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </ListItem>
        )
      })}
    </List>
  )
}
export default ViolationDrivingTypesRemove
