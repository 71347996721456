import React from "react";
import { useStyles } from "./useStyles";
import Drawer from "@mui/material/Drawer";
import { VehicleList } from "../VehicleList/VehicleList";

export const VehicleBar = ({
    showSidebar,
    formData,
    setShowSidebar,
    onSubmit
}) => {
    const classes = useStyles();

    return (
        <div>
            <React.Fragment key="right">
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={showSidebar}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <VehicleList
                        formData={formData}
                        setShowSidebar={setShowSidebar}
                        onSubmit={onSubmit}
                    />
                </Drawer>
            </React.Fragment>
        </div>
    );
};
