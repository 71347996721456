import { faMemoCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { CircleIcon } from "components/icons/CircleIcon"
import React from "react"

const WorkOrderTitle = ({ initialValues, title }) => {
  return (
    <Box mb={4} display="flex" flexDirection="row" alignItems="center">
      <CircleIcon icon={faMemoCircleCheck} color="primary" size="lg" />
      <Box order={2} p={2} flexGrow={2}>
        <Grid item>
          <Typography variant="h2">
            {initialValues.id ? `#${initialValues.id}` : "Skapa arbetsorder"}
          </Typography>
          <Typography variant="bodySmall">{title ? `${title}` : "Arbetsorder"}</Typography>
        </Grid>
      </Box>
    </Box>
  )
}

export default WorkOrderTitle
