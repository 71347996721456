import ClpTable from "components/ClpTable";
import { useMemo } from "react";

const ImportResult = ({ items }) => {
    const columns = useMemo(
        () => [
            {
                accessor: (cell) => {
                    return cell.successImport ? "Importerad" : "Trasig";
                },
                Header: "Importstatus"
            },
            {
                accessor: (cell) => {
                    return cell.isValid ? "OK" : "Inte OK";
                },
                Header: "Valideringstatus"
            },
            {
                accessor: (cell) => {
                    return cell.userImportDto.namn;
                },
                Header: "Namn"
            },
            {
                accessor: (cell) => {
                    return cell.userImportDto.användarnamn;
                },
                Header: "Användarnamn"
            },
            {
                accessor: (cell) => {
                    return cell.generatedPassword;
                },
                Header: "Lösenord"
            },
            {
                accessor: (cell) => {
                    return cell.userImportDto.skickaLoginuppgifter ? "Ja" : "Nej";
                },
                Header: "Skicka inlogg per mail"
            },
            {
                accessor: (cell) => {
                    return cell.messages.map((item) => {
                        return <span>{item}; </span>;
                    });
                },
                Header: "Meddelanden"
            }
        ],
        []
    );

    return <ClpTable rows={items} columns={columns} />;
};

export default ImportResult;
