import { Autocomplete, FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import Button from "components/Button";
import { Formik } from "formik";
import { storageValidationSchema } from "pages/systemadmin/pages/devices/components/validationSchema";
import React from "react";

export const StorageForm = ({ onSubmit, editStorage, result, feedback, companyResult }) => {
    const initialValues = editStorage
        ? editStorage
        : {
              companyId: null,
              name: "",
              maximumCapacity: 0,
              isActive: true,
              storageDescription: "",
              adress: ""
          };

    const editMode = editStorage ? true : false;

    const handleSubmit = async (values, { resetForm }) => {
        const storageToCredate = {
            ...values
        };
        onSubmit(storageToCredate);
    };

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={storageValidationSchema}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="companyId"
                                        options={companyResult.data}
                                        getOptionLabel={(option) => (option.name ? option.name : "")}
                                        value={companyResult.data.find((i) => i.id === values.companyId) || ""}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.id === value.id;
                                        }}
                                        onChange={(e, value) => {
                                            setFieldValue("companyId", value?.id);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Bolag"
                                                variant="outlined"
                                                name="companyId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.companyId && errors.companyId ? true : false}
                                                helperText={touched.companyId && errors.companyId ? errors.companyId : null}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="off"
                                        fullWidth
                                        id="name"
                                        label="Namn"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.name && errors.name ? true : false}
                                        helperText={touched.name && errors.name ? errors.name : null}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        autoComplete="off"
                                        fullWidth
                                        type="number"
                                        id="maximumCapacity"
                                        label="Max antal i lager"
                                        value={values.maximumCapacity}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.maximumCapacity && errors.maximumCapacity ? true : false}
                                        helperText={touched.maximumCapacity && errors.maximumCapacity ? errors.maximumCapacity : null}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                onChange={(item) => {
                                                    const checked = item.target.checked;
                                                    setFieldValue("isActive", checked);
                                                }}
                                                checked={values.isActive}
                                            />
                                        }
                                        label="Aktiv"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete="off"
                                        fullWidth
                                        id="storageDescription"
                                        label="Beskrivning"
                                        value={values.storageDescription}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.storageDescription && errors.storageDescription ? true : false}
                                        helperText={touched.storageDescription && errors.storageDescription ? errors.storageDescription : null}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete="off"
                                        fullWidth
                                        id="adress"
                                        label="Adress"
                                        value={values.adress}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.adress && errors.adress ? true : false}
                                        helperText={touched.adress && errors.adress ? errors.adress : null}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" style={{ marginTop: "2rem" }}>
                        <Button color="primary" type="submit" result={result} feedback={feedback}>
                            {editMode ? "Spara" : "Lägg till"}
                        </Button>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};
