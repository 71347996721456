import React, { useContext } from "react"
import { ToolbarProps } from "react-big-calendar"

import {
  faBarsFilter,
  faCalendarDay,
  faChevronLeft,
  faChevronRight,
  faListUl
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Chip, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import "moment/locale/sv"
import BigCalendarDatePickers from "./BigCalendarDatePicker"

import { AppContext } from "components/AppContextProvider"
import { StyledButtonGroupSwitch, StyledButtonSwitch } from "components/ButtonSwitch/ButtonSwitch"
import moment from "moment"
import { CalendarContext } from "../context/CalendarStateProvider"
import { updateFilter } from "../helpers/updateFilter"
import { ToolbarFilter } from "./ToolbarFilter"

export const ToolbarCalendar = (toolbar: ToolbarProps) => {
  const {
    date,
    view,
    setView,
    setDate,
    setColumnView,
    columnView,
    setFilterView,
    filterView,
    filters,
    selectedFilters,
    setSelectedFilters
  } = useContext(CalendarContext)

  const [datepickerAnchorEl, setDatePickerAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(datepickerAnchorEl)
  const openDatePicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDatePickerAnchorEl(event.currentTarget)
  }
  const closeDatePicker = () => {
    setDatePickerAnchorEl(null)
  }

  const goToNextOrPreviosDate = (isNext: boolean) => {
    let currentDate = new Date(date.getTime())
    switch (view) {
      case "month": {
        isNext ? currentDate.setMonth(date.getMonth() + 1) : currentDate.setMonth(date.getMonth() - 1)
        setDate(currentDate)
        return
      }

      case "week": {
        isNext ? currentDate.setDate(date.getDate() + 7) : currentDate.setDate(date.getDate() - 7)
        setDate(currentDate)
        return
      }
      default: {
        isNext ? currentDate.setDate(date.getDate() + 1) : currentDate.setDate(date.getDate() - 1)
        setDate(currentDate)
        return
      }
    }
  }

  return (
    <Box sx={{ borderBottom: "1px solid #D9E6EA", marginBottom: 2 }}>
      <Grid container direction="row" justifyContent="space-between" marginBottom={2} spacing={3}>
        <Grid item display="flex" justifyContent="space-between" xs={0} md={2.5} alignItems="center">
          <IconButton
            onClick={() => {
              goToNextOrPreviosDate(false)
            }}>
            <FontAwesomeIcon size="xs" icon={faChevronLeft} />
          </IconButton>
          <Button
            color="inherit"
            id="datepicker-button"
            aria-controls={open ? "datepicker-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={openDatePicker}>
            <Typography textTransform="capitalize" variant="h4" display="inline-block">
              <ToolbarTitle />
            </Typography>
          </Button>
          <Menu
            id="datepicker-menu"
            anchorEl={datepickerAnchorEl}
            open={open}
            onClose={closeDatePicker}
            MenuListProps={{
              "aria-labelledby": "datepicker-button"
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}>
            <MenuItem>
              <BigCalendarDatePickers />
            </MenuItem>
          </Menu>

          <IconButton
            onClick={() => {
              goToNextOrPreviosDate(true)
            }}>
            <FontAwesomeIcon size="xs" icon={faChevronRight} />
          </IconButton>
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <IconButton
              onClick={() => setFilterView(!filterView)}
              size="small"
              color={filterView ? "primary" : "inherit"}>
              <FontAwesomeIcon icon={faBarsFilter} />
            </IconButton>
            <IconButton
              onClick={() => setColumnView(false)}
              color={columnView ? "inherit" : "primary"}
              size="small">
              <FontAwesomeIcon icon={faListUl} />
            </IconButton>
            <IconButton
              onClick={() => setColumnView(true)}
              color={columnView ? "primary" : "inherit"}
              size="small">
              <FontAwesomeIcon icon={faCalendarDay} />
            </IconButton>

            <StyledButtonGroupSwitch variant="outlined" color="inherit">
              <StyledButtonSwitch onClick={() => setView("day")} active={view === "day"}>
                Dag
              </StyledButtonSwitch>
              <StyledButtonSwitch onClick={() => setView("week")} active={view === "week"}>
                Vecka
              </StyledButtonSwitch>
              <StyledButtonSwitch onClick={() => setView("month")} active={view === "month"}>
                Månad
              </StyledButtonSwitch>
            </StyledButtonGroupSwitch>
          </Box>
        </Grid>
      </Grid>
      {filterView && (
        <>
          <Grid container direction="row" marginBottom={2} spacing={1}>
            {filters.map((filter) => (
              <Grid item>
                <ToolbarFilter {...filter} />
              </Grid>
            ))}
          </Grid>
          <Grid container direction="row" marginBottom={2} spacing={1}>
            {selectedFilters.map((filter) =>
              Array.isArray(filter.selected)
                ? filter.selected.map((selected: any) => (
                    <Grid item>
                      <Chip
                        label={selected}
                        onDelete={() => {
                          const removedSelected = filter.selected.filter((x: any) => x !== selected)
                          setSelectedFilters(updateFilter(removedSelected, selectedFilters, filter))
                        }}
                      />
                    </Grid>
                  ))
                : null
            )}

            {selectedFilters.length > 0 && (
              <Grid item>
                <Chip
                  style={{ border: 0 }}
                  variant="outlined"
                  label="Rensa"
                  onClick={() => setSelectedFilters([])}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Box>
  )
}

const ToolbarTitle = () => {
  const { date, view } = useContext(CalendarContext)
  const { selectedLanguage } = useContext(AppContext)
  switch (view) {
    case "month":
      return <span>{moment(date).locale(selectedLanguage).format("MMMM YYYY")}</span>

    case "week":
      return (
        <span>
          Vecka {moment(date).locale(selectedLanguage).week()},{" "}
          {moment(date).locale(selectedLanguage).format("MMMM YYYY")}
        </span>
      )

    default:
      return <span>{moment(date).locale(selectedLanguage).format("dddd D MMM YYYY")}</span>
  }
}
