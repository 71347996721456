import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import globalUseStyles from "css/theme/globalUseStyles.js"
import React from "react"
import theme from "css/theme/light"

export const ActionCardDetails = (props) => {
  const { title, icon, details, footer, approved, subText, heading, bgClassName } = props

  const globalClasses = globalUseStyles()

  return (
    <PaperBox {...props} className={bgClassName ? bgClassName : null}>
      <Grid container direction="column" justifyContent="flex-start">
        <Grid alignItems="center" display="flex" item mb={3}>
          <FontAwesomeIcon
            size="lg"
            color={approved === true ? theme.palette.success["main"] : theme.palette.primary["main"]}
            icon={icon}
          />
          {title && (
            <Typography
              color={approved === true ? "secondary" : "primary"}
              variant="h2"
              ml={1}
              fontWeight="400">
              {title}
            </Typography>
          )}
        </Grid>
        {heading && (
          <Typography variant="h2" color="textPrimary" mb={1}>
            {heading}
          </Typography>
        )}
        <Grid item>
          {subText && (
            <Typography mb={2} color="textPrimary" variant="bodyMedium">
              {subText}
            </Typography>
          )}
        </Grid>
      </Grid>
      {details}
      {footer}
    </PaperBox>
  )
}

export default ActionCardDetails
