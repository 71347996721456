import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import Button from "components/Button"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import React, { Fragment } from "react"
import { Sidebar } from "../../../customfields/components/Sidebar"
import { useStyles } from "./useStyles"

const ImportVtrForm = (props: any) => {
  // props
  const { showSidebar, setShowSidebar, listRows, setShowContinueModal } = props

  // styles
  const classes = useStyles()

  // handle submit
  const handleSubmit = () => {
    setShowContinueModal(true)
  }

  // filter company names if there is a duplicate
  const uniqueName: string[] = []
  const filteredNames = listRows.filter((name: any) => {
    const isDuplicate = uniqueName.includes(name.bolag)

    if (!isDuplicate) {
      uniqueName.push(name.bolag)

      return true
    }

    return false
  })

  return (
    <Sidebar showSidebar={showSidebar}>
      <List className={classes.vehicleList}>
        <CloseSidebarButton
          toggleMenu={() => {
            setShowSidebar(!showSidebar)
          }}
        />
        <ListItem className={classes.listItem}>
          <Box className={classes.fullWidth}>
            <ListItemIcon className={classes.listItemIcon}>
              {listRows ? (
                <FontAwesomeIcon icon={faCheckCircle} color="#6BCA4A" />
              ) : (
                <FontAwesomeIcon icon={faCheckCircle} color="primary" />
              )}
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography color="textPrimary" variant="h2">
                  Importera Fordon
                </Typography>
              }
              secondary={
                <Typography color="textSecondary" variant="bodySmall">
                  Transportstyrelsen
                </Typography>
              }
            />
          </Box>
        </ListItem>
        {listRows.length > 0 ? (
          <Fragment>
            <Box mt={6}>
              <Typography color="textPrimary" variant="h3">
                Du kan importera <span className={classes.boldText}>{listRows.length} st</span> fordon från
                Transportstyrelsen, som tillhör bolagen:
              </Typography>
              <Box mt={2}>
                {filteredNames.map((name: any) => (
                  <Typography fontWeight="500" mb={1} display="block" color="textPrimary" variant="h5">
                    - {name.bolag}
                  </Typography>
                ))}
              </Box>

              <Box mt={4}>
                <Typography color="textSecondary" variant="bodyMedium">
                  Om du vill slutföra import av fordon klickar du på “importera fordon”.
                </Typography>
              </Box>
            </Box>
            <Box className={classes.greyBorder} mt={8} pt={2}>
              <Button onClick={handleSubmit} color="primary" variant="contained">
                Importera fordon
              </Button>
            </Box>
          </Fragment>
        ) : (
          <Box mt={6}>
            <Typography color="textPrimary" variant="h3">
              Du har för tillfället inga fordon att importera från Transportstyrelsen.
            </Typography>
          </Box>
        )}
      </List>
    </Sidebar>
  )
}

export default ImportVtrForm
