import { IFilterSelectedCalendarProps } from "../interfaces/filter.interface"

export function updateFilter(value: any, selectedFilters: any, filter: any) {
  const selectedValue = typeof value === "string" ? value.split(",") : value

  let updatedValue = []
  const filterExists = selectedFilters.some(
    (prevFilter: IFilterSelectedCalendarProps) => prevFilter.data === filter.data
  )

  if (filterExists) {
    if (selectedValue.length === 0) {
      updatedValue = selectedFilters.filter(
        (prevFilter: IFilterSelectedCalendarProps) => prevFilter.data !== filter.data
      )
    } else {
      updatedValue = selectedFilters.map((prevFilter: IFilterSelectedCalendarProps) =>
        prevFilter.data === filter.data ? { data: filter.data, selected: selectedValue } : prevFilter
      )
    }
  } else {
    updatedValue = [
      ...selectedFilters,
      { data: filter.data, selected: selectedValue }
    ] as IFilterSelectedCalendarProps[]
  }

  return updatedValue
}
