import React from 'react'
import TableRow from "@mui/material/TableRow";
import { TableCell } from '@mui/material';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from "@mui/icons-material";

const TableHeader = (props) => {
    const { headerGroup, subColumns, manualSortBy, manualSetSortState, manualSortState, } = props;
    return (
        <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
            {subColumns && subColumns.length > 0 && <TableCell></TableCell>}
            {headerGroup.headers.map((column, index) => {
                const headerProps = {
                    align: column.align ? column.align : "left",
                    key: index,
                    ...(manualSortBy && {
                        onClick: () => {
                            manualSetSortState({
                                sortby: column.id,
                                direction: manualSortState.sortby === column.id && manualSortState.direction === "asc" ? "desc" : "asc"
                            });
                        }
                    }),
                    ...(!manualSortBy && {
                        ...column.getHeaderProps(
                            column.getSortByToggleProps({
                                title: column.tooltip
                            })
                        )
                    })
                };

                return (
                    !column.hidden && (
                        <TableCell {...headerProps}>
                            <span
                                className={
                                    manualSortState && manualSortState.sortby === column.id
                                        ? "MuiButtonBase-root MuiTableSortLabel-root MuiTableSortLabel-active"
                                        : null
                                }
                            >
                                {column.render("Header")}
                                {column.isSorted || (manualSortState && manualSortState.sortby === column.id) ? (
                                    column.isSortedDesc ||
                                        (manualSortState &&
                                            manualSortState.sortby === column.id &&
                                            manualSortState.direction === "desc") ? (
                                        <ArrowDownwardOutlined />
                                    ) : (
                                        <ArrowUpwardOutlined />
                                    )
                                ) : (
                                    ""
                                )}
                            </span>
                        </TableCell>
                    )
                );
            })}
        </TableRow>
    )
}

export default TableHeader