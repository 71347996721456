import { useContext } from "react"

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material"
import "moment/locale/sv"

import { CalendarContext } from "../context/CalendarStateProvider"
import { updateFilter } from "../helpers/updateFilter"
import { IEventPropsWithIndex } from "../interfaces/event.interface"
import { IFilterCalendarProps } from "../interfaces/filter.interface"

export const ToolbarFilter = (filter: IFilterCalendarProps) => {
  const { events, setSelectedFilters, selectedFilters } = useContext(CalendarContext)

  const handleChange = (event: any) => {
    const {
      target: { value }
    } = event

    setSelectedFilters(updateFilter(value, selectedFilters, filter))
  }

  return (
    <>
      <FormControl sx={{ m: 1, width: 250 }} size="small">
        <InputLabel id="filter-checkbox-label">{filter.title}</InputLabel>
        <Select
          labelId={filter.title}
          label="Test"
          id={filter.title}
          multiple
          value={
            selectedFilters?.find((x) => x.data === filter.data)?.selected
              ? selectedFilters?.find((x) => x.data === filter.data)?.selected
              : []
          }
          onChange={handleChange}
          input={<OutlinedInput label={filter.title} />}
          renderValue={(selected) => selected?.join(", ")}>
          {events
            .filter(
              (event: IEventPropsWithIndex, index: number, self) =>
                index ===
                self.findIndex((e: IEventPropsWithIndex) =>
                  e[filter.data] === event[filter.data] ? event[filter.data] : null
                )
            )
            .map((x: IEventPropsWithIndex) => (
              <MenuItem key={x[filter.data]} value={x[filter.data]}>
                <Checkbox
                  checked={
                    selectedFilters.findIndex(
                      (selectedFilter) =>
                        selectedFilter.selected.findIndex((s: any) => s === x[filter.data]) !== -1
                    ) !== -1
                  }
                />
                <ListItemText primary={x[filter.data]} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  )
}
