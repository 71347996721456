import { Box, Container, Grid, Typography } from "@mui/material";
import { useApiGet, useApiPost } from "auth/useApi2";
import { PageTabs } from "components/navigation/PageTabs";
import { apiEndpoints } from "generated/apiEndpoints";
import { allRoutes } from "generated/routes";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ArticleForm from "../../components/ArticleForm";

const AddNews = () => {
    const [saveArticleResult, saveArticle] = useApiPost();
    const routerHistory = useHistory();

    const handleSubmit = (objectToSave) => {
        saveArticle(apiEndpoints.newsadmin.addarticle, objectToSave);
    };

    const [customerResult, getCustomer] = useApiGet();

    useEffect(() => {
        getCustomer(apiEndpoints.newsadmin.getcustomer);
    }, [getCustomer]);

    useEffect(() => {
        if (saveArticleResult.status === 2) routerHistory.push(allRoutes.NewsAdmin());
    }, [saveArticleResult.status, routerHistory]);

    const feedback = {
        errorTitle: "Nyhet kunde inte skapas"
    };

    return (
        <Box marginBottom={10}>
            <PageTabs
                routeBack
                tabs={[
                    {
                        path: allRoutes.NewsAdmin(),
                        title: "Tillbaka"
                    }
                ]}
            />
            <Container>
                <Grid container justifyContent="center">
                    <Grid item md={10} lg={8}>
                        <Box my={3}>
                            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h2">Lägg till nyhet</Typography>
                            </Grid>
                        </Box>
                        {customerResult.status === 2 ? (
                            <ArticleForm customer={customerResult.data} onSubmit={handleSubmit} result={saveArticleResult} feedback={feedback} />
                        ) : null}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default AddNews;
