import { Box, Container, Grid, Skeleton, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import BodyRichContent from "components/Fields/BodyRichContent"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import DocumentsComponent from "pages/crisismanagement/components/DoucmentsComponent"
import PhoneContactsComponent from "pages/crisismanagement/components/PhoneContactsComponent"
import SosButton from "pages/crisismanagement/components/SosButton"
import React, { useEffect } from "react"
import "./style.css"

const CrisisInfo = () => {
  const [crisisInfoResult, crisisInfoApi] = useApi() as [IApiResult, IApiFunc]

  useEffect(() => {
    crisisInfoApi.get(apiEndpoints.crisismanagement.getcrisisinfo)
  }, [crisisInfoApi])

  // const BodyRichContent = ({ content }: { content: any }) => {
  //   return <MUIRichTextEditor defaultValue={content} readOnly={true} toolbar={false} />
  // }

  return (
    <div>
      <Container>
        <Grid container direction={"row"} padding={3} spacing={3}>
          <Grid item md={7} xs={12}>
            <Box>
              {crisisInfoResult.status === 2 ? (
                <>
                  {console.log(crisisInfoResult)}
                  <Typography variant="h3" mb={1}>
                    {crisisInfoResult.data.title}
                  </Typography>
                  <Typography variant="h5" color="textSecondary">
                    {crisisInfoResult.data.subTitle}
                  </Typography>
                </>
              ) : crisisInfoResult.status === 3 ? (
                <></>
              ) : (
                <>
                  <Skeleton variant="text" width={400} sx={{ fontSize: "2em" }} />
                  <Skeleton variant="text" width={300} sx={{ fontSize: "1em" }} />
                </>
              )}
            </Box>

            <SosButton className="show-mobile" sx={{ marginTop: 4 }} />
            <Box marginTop={4} marginBottom={4} className="show-mobile">
              {crisisInfoResult.status === 2 ? (
                <PhoneContactsComponent
                  managerData={crisisInfoResult.data.managerContactInfo}
                  otherData={crisisInfoResult.data.otherContactInfos}
                />
              ) : null}
            </Box>
            <PaperBox>
              {crisisInfoResult.status === 2 ? (
                <BodyRichContent content={crisisInfoResult.data.body}></BodyRichContent>
              ) : crisisInfoResult.status === 3 ? (
                <Typography variant="h4">Ingen krisinformation hittades</Typography>
              ) : (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <br />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <br />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <br />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <br />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1em" }} />
                  <br />
                </>
              )}
            </PaperBox>
          </Grid>

          <Grid item md={5} xs={12}>
            <PaperBox
              sx={{ backgroundColor: theme.palette.error["light"], marginTop: 0 }}
              className="dont-show-mobile">
              <Typography variant="h3" color={"error"}>
                Larma SOS
              </Typography>
              <Typography mt={1}>Du råkar ut för olycka med personskador</Typography>
              <SosButton sx={{ marginTop: 3, marginBottom: 1 }} />
            </PaperBox>
            <Box marginTop={5} className="dont-show-mobile">
              {crisisInfoResult.status === 2 ? (
                <PhoneContactsComponent
                  managerData={crisisInfoResult.data.managerContactInfo}
                  otherData={crisisInfoResult.data.otherContactInfos}
                />
              ) : crisisInfoResult.status === 3 ? (
                <></>
              ) : (
                <>
                  <Skeleton variant="text" width={200} sx={{ fontSize: "1.5em" }} />
                  <Skeleton variant="rounded" height={55} sx={{ fontSize: "1.5em" }} />
                  <Skeleton variant="text" width={200} sx={{ fontSize: "1.5em", marginTop: 1 }} />
                  <Skeleton variant="rounded" height={55} sx={{ fontSize: "1.5em", marginBottom: 0.25 }} />
                  <Skeleton variant="rounded" height={55} sx={{ fontSize: "1.5em", marginBottom: 0.25 }} />
                  <Skeleton variant="rounded" height={55} sx={{ fontSize: "1.5em", marginBottom: 0.25 }} />
                </>
              )}
            </Box>
          </Grid>
          {crisisInfoResult.status === 3 ? (
            <></>
          ) : crisisInfoResult.status === 2 && crisisInfoResult.data.attachments ? (
            <DocumentsComponent attachments={crisisInfoResult.data.attachments} />
          ) : null}
        </Grid>
      </Container>
    </div>
  )
}
export default CrisisInfo
