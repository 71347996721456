import { faLongArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import makeStyles from "@mui/styles/makeStyles"
import { Link } from "react-router-dom"
const useStyles = makeStyles((theme) => ({
  Link: {
    textDecoration: "none",
    color: theme.palette.grey[800],
    fontWeight: 300
  }
}))

function RouterLink(props) {
  const { children, to } = props
  const classes = useStyles()
  return (
    <Link to={to} className={classes.Link}>
      {children} <FontAwesomeIcon icon={faLongArrowRight} />
    </Link>
  )
}

export default RouterLink
