import { Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const style = makeStyles({
  marginHeader: {
    marginBottom: "20px"
  },
  marginBody: {
    marginBottom: "5px"
  }
})

const AnonymousUserMessage = () => {
  const classes = style()

  return (
    <Grid item xs={12}>
      <Typography variant="h1" className={classes.marginHeader}>
        Någonting gick fel
      </Typography>
      <Typography variant="bodyMedium" className={classes.marginBody}>
        Du har kommit till denna sida eftersom ditt konto finns, men inte finns länkat på rätt sätt till en
        kund.
      </Typography>
      <Typography variant="bodyMedium" className={classes.marginBody}>
        Var vänlig kontakta din systemansvarige eller Cloudplannings support.
      </Typography>
    </Grid>
  )
}
export default AnonymousUserMessage
