import { faFileExcel, faFileImport, faSearch, faSitemap } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Container, MobileStepper, Step, StepLabel, Stepper } from "@mui/material"
import { useApi } from "auth/useApi3"
import clsx from "clsx"
import ApiResult from "components/ApiResultFeedback"
import ApiButton from "components/Button"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useContext, useState } from "react"
import { CompleteArea } from "./CompleteArea"
import { ImportContext } from "./ImportFormStateProvider"
import { PreviewImportArea } from "./PreviewImportArea"
import { SelectFields } from "./SelectFieldsArea"
import { SuccessArea } from "./SuccessArea"
import { UploadExcelToJson } from "./UploadExcelArea"
import { useStyles } from "./useStyles"
import { validationFile, validationSelectedFields } from "./validationSchema"

function getSteps() {
  return ["Ladda upp excel", "Ange identifikation", "Förhandsgranska", "Slutför"]
}

function getStepContent(stepIndex, setFieldValue, touched, errors, values, validateResult, resetForm) {
  switch (stepIndex) {
    case 0:
      return (
        <UploadExcelToJson
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
          resetForm={resetForm}
        />
      )
    case 1:
      return <SelectFields setFieldValue={setFieldValue} touched={touched} errors={errors} values={values} />
    case 2:
      return <PreviewImportArea />
    case 3:
      return <CompleteArea validateResult={validateResult} />

    default:
      return "Unknown"
  }
}

function StepIcon(props) {
  const classes = useStyles()
  const { active, completed } = props

  const icons = {
    1: <FontAwesomeIcon icon={faFileExcel} />,
    2: <FontAwesomeIcon icon={faSitemap} />,
    3: <FontAwesomeIcon icon={faSearch} />,
    4: <FontAwesomeIcon icon={faFileImport} />
  }

  return (
    <div
      className={clsx(classes.icon, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      {icons[String(props.icon)]}
    </div>
  )
}
export const ImportFormWizard = () => {
  const importDataContext = useContext(ImportContext)
  const steps = getSteps()
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    if (activeStep === 2) {
      validateForm()
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const [validateResult, validateFuncs] = useApi()

  const validateForm = () => {
    const rows = importDataContext.getDataToImport()
    validateFuncs.post(apiEndpoints.vehicleregistryadmin.validateimportvehicles, {
      Rows: rows
    })
  }

  const [submitResult, submitFuncs] = useApi()
  const submitForm = () => {
    const rows = importDataContext.getDataToImport()

    submitFuncs.post(apiEndpoints.vehicleregistryadmin.importvehicles, {
      Rows: rows
    })
    setActiveStep(activeStep + 1)
    console.log(submitResult)
  }

  const schemaArray = [validationFile, validationSelectedFields]
  const [formData] = useState({
    excelFile: "",
    internNumber: "",
    regNumber: "",
    mainPropellant: "HVO",
    vehicleType: "",
    tachographIntegrationActive: false,
    company: ""
  })
  const feedback = {
    errorTitle: "Kunde inte importera fordon"
  }
  return (
    <Formik
      initialValues={formData}
      onSubmit={() => {
        handleNext()
      }}
      validationSchema={schemaArray[activeStep]}>
      {({ values, errors, touched, handleSubmit, setFieldValue, resetForm }) => (
        <form onSubmit={handleSubmit} noValidate>
          {submitResult && submitResult.status === 3 && (
            <ApiResult result={submitResult} feedback={feedback} />
          )}
          <Container>
            {activeStep !== steps.length && (
              <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
          </Container>
          <div>
            {activeStep === steps.length ? (
              <div>
                <SuccessArea submitResult={submitResult} />
              </div>
            ) : (
              <div>
                {getStepContent(
                  activeStep,
                  setFieldValue,
                  touched,
                  errors,
                  values,
                  validateResult,
                  resetForm
                )}
              </div>
            )}
          </div>

          {activeStep !== steps.length && (
            <MobileStepper
              variant="progress"
              steps={4}
              position="static"
              activeStep={activeStep}
              className={classes.mobileStepper}
              nextButton={
                activeStep === 4 - 1 ? (
                  <ApiButton
                    color="primary"
                    variant="contained"
                    onClick={submitForm}
                    feedback={{
                      errorTitle: "Kunde inte skicka in provet"
                    }}>
                    Slutför importering
                  </ApiButton>
                ) : (
                  <Button color="primary" variant="contained" type="submit">
                    Nästa
                  </Button>
                )
              }
              backButton={
                activeStep !== 0 ? (
                  <Button onClick={handleBack} color="inherit">
                    Föregående
                  </Button>
                ) : (
                  <div></div>
                )
              }
            />
          )}
        </form>
      )}
    </Formik>
  )
}
