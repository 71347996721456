import { TextField, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from "../../../components/useStyles";

const WorkOrderCardWriteMessage = ({ values, handleChange, handleBlur, touched, errors }) => {

    const classes = useStyles();
    return (
        <>
            <Typography pb={1} variant="h6">
                Meddelande till verkstad
            </Typography>
            <TextField
                p={2}
                placeholder="Information om arbetsorder..."
                rows={3}
                multiline
                autoComplete="off"
                className={classes.textField}
                fullWidth
                id="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.message && errors.message ? true : false}
                helperText={touched.message && errors.message ? errors.message : null}
                variant="outlined"
                InputProps={{ classes: { input: classes.formAreaInput }, disableUnderline: true }}
            />
        </>)
}

export default WorkOrderCardWriteMessage