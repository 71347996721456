import React, { useState } from "react"

import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { faCircle } from "@fortawesome/free-solid-svg-icons"

import { Typography } from "@mui/material"
const AlertFeedback = ({ result, feedback }) => {
  const [open, setOpen] = useState(true)

  const handleClose = (newValue) => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs">
      <Grid container direction="column" alignItems="center">
        <Box bgcolor="error.light" width="1" mb={2} textAlign="center">
          <Grid item my={2}>
            <DialogTitle id="alert-dialog-title">
              <Box className="fa-layers fa-fw text-center fa-3x" color="error.main">
                <FontAwesomeIcon icon={faCircle} color="error.main" />
                <FontAwesomeIcon icon={faTimes} color="error.contrastText" inverse size="xs" />
              </Box>
            </DialogTitle>
          </Grid>
        </Box>
        <DialogContent my={2}>
          <Typography variant="h4" paragraph>
            {feedback && feedback.errorTitle ? feedback.errorTitle : "Ett fel inträffade"}
          </Typography>
          {result.messages.map((item, index) => {
            return (
              <Typography key={index} paragraph>
                {item}
              </Typography>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Box mb={3}>
            <Button onClick={handleClose} color="primary" variant="contained">
              Stäng
            </Button>
          </Box>
        </DialogActions>
      </Grid>
    </Dialog>
  )
}
export default AlertFeedback
