import React, { useContext, useEffect } from "react"

import { Autocomplete, Box, Container, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import { ReportResult } from "./components/ReportResult/ReportResult"
import { useStyles } from "./components/style"

const ReportSelector = ({ templates, parameters, setParameters }) => {
  const [value, setValue] = React.useState(parameters.selectedTemplate && parameters.selectedTemplate.id)
  const classes = useStyles()

  useEffect(() => {
    if (templates && templates.length > 1) {
      setValue(templates[0].id)
    }
  }, [templates])

  return (
    <Select
      variant="outlined"
      labelId="sortby-label"
      id="selectedReportTemplate"
      className={classes.formControl}
      value={value}
      onChange={(event) => {
        const value = event.target.value
        setValue(value)
        const selectedTemplate = templates.filter((template) => template.id === value)[0]

        setParameters((prevState) => {
          return {
            ...prevState,
            selectedTemplate: selectedTemplate,
            showDateRange: value > 0 ? selectedTemplate.requireDate : ""
          }
        })
      }}>
      {templates &&
        templates.map((item) => {
          return <MenuItem value={item.id}>{item.name}</MenuItem>
        })}
    </Select>
  )
}

const DateFrom = ({ parameters, setParameters }) => {
  return (
    <DatePicker
      label="Från datum"
      value={parameters.dateFrom ? moment(parameters.dateFrom) : null}
      onChange={(value) => {
        setParameters((prevState) => {
          return {
            ...prevState,
            dateFrom: value
          }
        })
      }}
      inputFormat="YYYY-MM-DD"
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

const DateTo = ({ parameters, setParameters }) => {
  return (
    <DatePicker
      label="Till datum"
      value={parameters.dateTo ? moment(parameters.dateTo) : null}
      onChange={(value) => {
        setParameters((prevState) => {
          return {
            ...prevState,
            dateTo: value
          }
        })
      }}
      inputFormat="YYYY-MM-DD"
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

const FilterSubTable = ({ parameters, setParameters }) => {
  const [contractDataResult, contractDataApi] = useApi()
  const loadingContext = useContext(GlobalLoaderContext)
  //   const [subContracts, setSubContracts] = React.useState([])
  const [name, setValue] = React.useState()
  const [subContract, setSubContractValue] = React.useState()
  const [subContractsResult, subContractDataApi] = useApi()
  React.useEffect(() => {
    if (
      parameters.selectedTemplate != null &&
      parameters.selectedTemplate.id === 3 &&
      contractDataResult.status !== 2
    ) {
      contractDataApi.get(apiEndpoints.vehicleregistryreporting.getcontractlistdistinct)

      subContractDataApi.get(apiEndpoints.vehicleregistryreporting.getsubcontractlistdistinct)
    }
    return () => {
      contractDataApi.leave()
    }
  }, [contractDataApi, subContractDataApi, parameters])

  useEffect(() => {
    loadingContext.setLoading(() => contractDataResult.status === 1)
  })

  //   useEffect(() => {
  //     if (contractDataResult.status === 2) {
  //       const filtredSubContract = [
  //         ...new Set(
  //           contractDataResult.data
  //             .filter((x) => x.subContract !== null && x.subContract !== '')
  //             .map((item) => item.subContract)
  //         )
  //       ]
  //       setSubContracts(filtredSubContract)
  //     }
  //   }, [contractDataResult])

  return (
    <>
      <Grid item>
        <Autocomplete
          id="selectedReportTemplate"
          options={contractDataResult.status === 2 && contractDataResult.data}
          getOptionLabel={(option) => (option?.name ? option.name : "")}
          value={contractDataResult.status === 2 ? contractDataResult.data.find((i) => i.name === name) : ""}
          onChange={(e, selectedValue) => {
            setValue(selectedValue?.name)
            const selectedName = {
              ...parameters.filter,
              name: selectedValue?.name
            }

            setParameters((prevState) => {
              return {
                ...prevState,
                filter: selectedName
              }
            })
          }}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Kunder" variant="outlined" />}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id="subContracts"
          options={subContractsResult.status === 2 && subContractsResult.data}
          getOptionLabel={(option) => (option?.subContract ? option.subContract : "")}
          value={
            subContractsResult.status === 2
              ? subContractsResult.data.find((i) => i.subContract === subContract)
              : ""
          }
          onChange={(e, selectedValue) => {
            setSubContractValue(selectedValue?.subContract)
            const selectedSubContract = {
              ...parameters.filter,
              subcontract: selectedValue?.subContract
            }

            setParameters((prevState) => {
              return {
                ...prevState,
                filter: selectedSubContract
              }
            })
          }}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Underavtal" variant="outlined" />}
        />
        {/* 
        <Autocomplete
          id="selectedReportTemplate"
          options={subContracts.length > 0 && subContracts}
          getOptionLabel={(option) => (option ? option : '')}
          value={
            (subContracts.length > 0 &&
              subContracts.find((i) => i === subContractsResult)) ||
            ''
          }
          onChange={(e, selectedValue) => {
            setSubContractValue(selectedValue)
            const selectedSubContract = {
              ...parameters.filter,
              subcontract: selectedValue
            }

            setParameters((prevState) => {
              return {
                ...prevState,
                filter: selectedSubContract
              }
            })
          }}
          sx={{width: 300}}
          renderInput={(params) => (
            <TextField {...params} label="Underavtal" variant="outlined" />
          )}
        /> */}
      </Grid>
    </>
  )
}

const ReportParameters = ({ parameters, setParameters, templates }) => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item>
        <ReportSelector templates={templates} parameters={parameters} setParameters={setParameters} />
      </Grid>
      {parameters.selectedTemplate && parameters.selectedTemplate.id === 3 && (
        <FilterSubTable templates={templates} parameters={parameters} setParameters={setParameters} />
      )}
      {parameters.selectedTemplate && parameters.selectedTemplate.requireDate ? (
        <>
          <Grid item>
            <DateFrom parameters={parameters} setParameters={setParameters} />
          </Grid>
          <Grid item>
            <DateTo parameters={parameters} setParameters={setParameters} />
          </Grid>
        </>
      ) : null}
    </Grid>
  )
}

const ReportsIndexPage = () => {
  const globalClasses = globalUseStyles()

  // lite copypasta för default denna månad
  var date = new Date()
  var firstDay = date.setMonth(date.getMonth() - 1)
  var lastDay = new Date()

  const [reportTemplatesResult, reportTemplatesApi] = useApi()
  const loadingContext = useContext(GlobalLoaderContext)

  const [parameters, setParameters] = React.useState({
    selectedTemplate: null,
    showDateRange: false,
    dateFrom: firstDay,
    dateTo: lastDay,
    filter: { name: null, subcontract: null }
  })

  useEffect(() => {
    reportTemplatesApi.get(apiEndpoints.vehicleregistryreporting.getavailablereporttemplates)

    return () => {
      reportTemplatesApi.leave()
    }
  }, [reportTemplatesApi])
  useEffect(() => {
    reportTemplatesResult.status === 2 &&
      setParameters((prevState) => {
        return {
          ...prevState,
          selectedTemplate: reportTemplatesResult.data[0]
        }
      })
  }, [reportTemplatesResult])

  useEffect(() => {
    loadingContext.setLoading(() => reportTemplatesResult.status === 1)
  })

  return (
    <div>
      <Container>
        <Box mb={4}>
          <Typography variant="h1">Rapporter</Typography>
        </Box>
      </Container>
      <PaperBox p={0}>
        <Box paddingX={3}>
          <ReportParameters
            parameters={parameters}
            setParameters={setParameters}
            templates={reportTemplatesResult.data}
          />
        </Box>
      </PaperBox>
      <Box className={globalClasses.tableContainer}>
        {reportTemplatesResult.status === 2 ? <ReportResult parameters={parameters} /> : null}
      </Box>
    </div>
  )
}
export default ReportsIndexPage
