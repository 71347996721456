import { FormControlLabel, ListItem } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  issueReportRow: {
    borderBottom: "1px solid #E1E1E1",
    padding: "16px",
    "&:last-child": {
      border: "none"
    }
  }
}))

const TodoListRow = ({ control, children, id }) => {
  const classes = useStyles()

  return (
    <ListItem className={classes.issueReportRow} key={id}>
      <FormControlLabel control={control} />
      {children}
    </ListItem>
  )
}

export default TodoListRow
