import * as Yup from "yup"

export const getValidation = (datatype: number, optional?: boolean, label?: string) => {
  const validationRules = {
    0: optional ? Yup.string().nullable() : Yup.string().nullable().required(`${label} är obligatoriskt`),
    1: optional
      ? Yup.number().typeError(`${label} måste vara ett nummer`)
      : Yup.number().typeError(`${label} måste vara ett nummer`).required(`${label} är obligatoriskt`),
    2: optional
      ? Yup.boolean().nullable().typeError(`${label} must be a boolean`)
      : Yup.boolean().nullable().required(`${label} är obligatoriskt`),
    3: optional
      ? Yup.date().nullable().typeError(`${label} måste vara ett datum`)
      : Yup.date()
          .nullable()
          .typeError(`${label} måste vara ett datum`)
          .required(`${label} är obligatoriskt`),
    4: optional
      ? Yup.date().nullable().typeError(`${label} måste vara ett datum`)
      : Yup.date()
          .nullable()
          .typeError(`${label} måste vara ett datum`)
          .required(`${label} är obligatoriskt`),
    5: optional ? Yup.string().nullable() : Yup.string().nullable().required(`${label} är obligatoriskt`),
    6: optional ? Yup.string().nullable() : Yup.string().required(`${label} är obligatoriskt`)
  } as any

  return validationRules[datatype] || null
}
