import { Box, Grid, Typography } from '@mui/material';
import React from 'react'

interface NoSearchDataProps {
    title: string;
    text: string;
}

const NoSearchData = (props: NoSearchDataProps) => {
    const { text, title } = props;
    return (
        <Grid item md={12} xs={12} display="flex" flexDirection="column" justifyContent="center">
            <Box>
                <Typography mb={1} variant="h1">{title}</Typography>
                <Typography>{text}</Typography>
            </Box>
        </Grid>
    )
}

export default NoSearchData