import makeStyles from "@mui/styles/makeStyles"

const globalUseStyles = makeStyles((theme) => ({
  backgroundPrimary: {
    backgroundColor: theme.palette.primary["light"],
    color: `${theme.palette.primary["main"]}!important`
  },
  backgroundSuccess: {
    background: `${theme.palette.success["light"]}!important`,
    color: `${theme.palette.success["main"]}!important`
  },
  backgroundError: {
    background: `${theme.palette.error["light"]}!important`,
    color: `${theme.palette.error["main"]}!important`
  },
  backgroundWarning: {
    background: `${theme.palette.warning["light"]}!important`,
    color: `${theme.palette.warning["main"]}!important`
  },
  backgroundGrey: {
    background: `${theme.palette.grey[100]}!important`,
    color: `${theme.palette.grey[800]}!important`
  },
  avatar: {
    fontSize: "1rem",
    marginRight: 24,
    [theme.breakpoints.down("xl")]: {
      marginRight: 14
    }
  },
  select: {
    minWidth: "250px",
    maxWidth: "100%"
  },
  tableContainer: {
    background: "#ffffff",

    width: "100%",
    [theme.breakpoints.up("sm")]: {
      "& > div": {
        position: "relative",
        "& .MuiTableContainer-root": {
          overflow: "auto"
        }
      }
    }
  },
  margin0: {
    margin: 0
  },
  padding0: {
    padding: 0
  },
  marginTop5: {
    marginTop: "5px"
  },
  marginBottom5: {
    marginBottom: "5px"
  },
  black500: {
    color: "rgb(0 0 0 / 55%)"
  },
  black600: {
    color: "rgb(0 0 0 / 87%)"
  }
}))
export default globalUseStyles
