import { ListItemIcon, Box, Typography, ListItemText } from '@mui/material'
import React from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export interface CContent {
    icon: FontAwesomeIconProps["icon"];
    labelText: string;
    valueText: any;
}

const SidebarInputLabel = (props: CContent) => {

    const { icon, labelText, valueText } = props;

    return (
        <Box pb={valueText ? 0 : 1} display="flex" alignItems="center">
            {icon &&
                <ListItemIcon>
                    <FontAwesomeIcon icon={icon} size="lg" color="#4BADC6" />
                </ListItemIcon>}

            <ListItemText
                primary={
                    <Typography color="textPrimary" fontWeight="600">
                        {labelText && labelText}
                    </Typography>
                }
                secondary={
                    <Typography color="textSecondary">
                        {valueText && valueText}
                    </Typography>
                }
            />
        </Box>)
}

export default SidebarInputLabel