import { ArchiveTableSelectChip } from "./ArchiveTableSelectChip";

export const statusString = (value) => {
    switch (value) {
        case 1:
            return "Öppen";
        case 2:
            return "Planerad";
        case 3:
            return "Pågående";
        case 4:
            return "Åtgärdad";
        case 5:
            return "Avslutad";
        default:
            return "-";
    }
};

export const status = (value) => {
    switch (value) {
        case 1:
            return <ArchiveTableSelectChip status={statusString(1)} />;
        case 2:
            return <ArchiveTableSelectChip status={statusString(2)} />;
        case 3:
            return <ArchiveTableSelectChip status={statusString(3)} />;
        case 4:
            return <ArchiveTableSelectChip status={statusString(4)} />;
        default:
            return <ArchiveTableSelectChip status={statusString(1)} />;
    }
};

export const prio = (value) => {
    switch (value) {
        case 1:
            return "Låg";
        case 2:
            return "Medel";
        case 3:
            return "Hög";
        default:
            return "-";
    }
};

