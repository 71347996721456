import { faPaperPlaneTop, faPhone } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Link, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import React from "react"

const ExternalOrderBox = ({ values }) => {
  return (
    <PaperBox>
      <Box p={0} pb={1} direction="column">
        <Typography variant="bodySmall">Beställare</Typography>
        <Typography mt={1} variant="h5" color="initial">
          {values.customerName}
        </Typography>
      </Box>
      <Box pt={1} pb={1} direction="column">
        <Typography variant="bodySmall">Referens</Typography>
        <Typography mt={1} variant="h5" color="initial">
          {values.contact?.name}
        </Typography>
        {values.contact?.email && (
          <Box display="flex" alignItems="center" direction="row" mb={1}>
            <FontAwesomeIcon color="#2ea2bf" icon={faPaperPlaneTop} size="md" />
            <Link sx={{ textDecoration: "none" }} component="a" href={`mailto:${values.contact.email}`}>
              <Typography color="primary" ml={1} variant="bodyMedium">
                {values.contact.email}
              </Typography>
            </Link>
          </Box>
        )}
        {values.contact?.phone && (
          <Box pb={0} display="flex" alignItems="center" direction="row">
            <FontAwesomeIcon color="#2ea2bf" icon={faPhone} size="md" />
            <Link sx={{ textDecoration: "none" }} component="a" href={`tel:${values.contact.phone}`}>
              <Typography color="primary" ml={1} variant="bodyMedium">
                {values.contact.phone}
              </Typography>
            </Link>
          </Box>
        )}
      </Box>
    </PaperBox>
  )
}

export default ExternalOrderBox
