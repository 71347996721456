import { Box, Dialog } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledBoxLink = styled(Box)(({ theme }) => ({
  padding: "12px 24px",
  textDecoration: "none",
  width: "100%",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.primary.light
  }
}))

export const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "400px"
  }
})