import React, { Fragment } from "react"
import { faUserLock, faPlug, faSignalStream, faBus, faKey } from "@fortawesome/pro-solid-svg-icons"
import { OverviewCard } from "./OverviewCard"

export const Overview = ({ overviewData }) => {
  const cards = [
    {
      title: "Fordon",
      icon: faBus,
      value: overviewData !== null ? overviewData.totalDevices : "-"
    },
    {
      title: "Fordon online",
      icon: faSignalStream,
      value: overviewData !== null ? overviewData.devicesOnlineLast24h : "-"
    },
    {
      title: "Canbus",
      icon: faPlug,
      value: overviewData !== null ? overviewData.devicesWithAnySentMasterCanMessage : "-"
    },
    {
      title: "Startspärr aktiv",
      icon: faUserLock,
      value: overviewData !== null ? overviewData.devicesWithAnySentDriverCard : "-"
    },
    {
      title: "Alkolås aktiv",
      icon: faKey,
      value: overviewData !== null ? overviewData.devicesWithAnySentAlcoholAlarms : "-"
    }
  ]

  return (
    <Fragment>
      {cards.map((card) => {
        return <OverviewCard key={card.title} card={card} />
      })}
    </Fragment>
  )
}
