import ClpTable from "components/ClpTable";

export const NoReportSelectedTable = () => {
    return (
        <ClpTable
            rows={[
                {
                    loadingReport: "Hämtar rapport.."
                }
            ]}
            columns={[
                {
                    accessor: "noSelectedReport",
                    Header: "Inga rader"
                }
            ]}
        />
    );
};
