import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React, { useContext, useEffect, useRef, useState } from "react"
import Moment from "react-moment"
import { ImportFuelContext } from "./ImportFuelFormStateProvider"
import { useStyles } from "./useStyles"

const SelectColumn = (props) => {
  const classes = useStyles()
  const { row, setFieldValue, importDataContext, value } = props

  const [selectedOption, setSelectedOption] = useState(value)

  const [dataFields] = useState([
    { name: "Ej vald", id: "" },
    { name: "Drivmedel", id: "propellant", dataType: "string" },
    { name: "Mätarställning", id: "odometer", dataType: "number" },
    { name: "Volym", id: "fuelVolume", dataType: "number" },
    { name: "Transaktionsdatum ", id: "transactionTime", dataType: "date" }
  ])

  const handleImportSelect = (event, fieldName) => {
    setFieldValue(event.target.value ? event.target.value : selectedOption, event.target.value)
    setSelectedOption(event.target.value)
    const dataType = event.target[event.target.selectedIndex].getAttribute("data-type")

    importDataContext.updateColumns(event.target.value, dataType, fieldName)
  }

  return (
    <FormControl className={classes.formControl} size="small">
      <Select native value={selectedOption} onChange={(e) => handleImportSelect(e, row.Header)}>
        {dataFields &&
          dataFields.map((item) => (
            <option key={item.id} value={item.id} data-type={item.dataType}>
              {item.name}
            </option>
          ))}
      </Select>
    </FormControl>
  )
}

export const SelectFields = (props) => {
  const importDataContext = useContext(ImportFuelContext)

  const { touched, errors, setFieldValue, values } = props

  const classes = useStyles()
  const [dataValues] = useState(importDataContext.getImportedData())

  const [excelHeaders] = useState(importDataContext.getExcelHeaders())

  const handleChange = (event) => {
    importDataContext.updateIdendifikation(event.target.value)
    setFieldValue("regnr", event.target.value)
  }
  const tableRef = useRef()
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)

  const getSize = () => {
    if (!tableRef.current) return
    if (tableRef && tableRef.current.getBoundingClientRect() != null) {
      const elemFromTop = tableRef.current.getBoundingClientRect().top + 62
      setHeight(window.innerHeight - elemFromTop)
      const elemFromLeft = tableRef.current.getBoundingClientRect().left
      setWidth(window.innerWidth - elemFromLeft)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", getSize)
  }, [])

  useEffect(() => {
    if (tableRef) {
      const elemFromTop = tableRef.current.getBoundingClientRect().top + 58
      setHeight(window.innerHeight - elemFromTop)

      const elemFromLeft = tableRef.current.getBoundingClientRect().left
      setWidth(window.innerWidth - elemFromLeft)
    }
  }, [])

  return (
    <>
      <Box bgcolor="background.paper" className={classes.rootShadow} paddingY={1}>
        <Container>
          <Box paddingBottom={3}>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <Typography variant="h2" gutterBottom>
                  Identifiera ditt fordon
                </Typography>

                <Typography variant="bodySmall">
                  Välj sätt att identifiera dig med hjälp av listan till höger
                </Typography>
              </Grid>
              <Grid item>
                <FormControl
                  className={classes.formControlLarge}
                  error={touched.regnr && errors.regnr}
                  variant="outlined">
                  <InputLabel id="regnr">Registreringsnummer</InputLabel>
                  <Select
                    label="Registreringsnummer"
                    labelId="regnr"
                    id="regnr"
                    value={values.regnr}
                    onChange={(e) => handleChange(e, setFieldValue)}>
                    {excelHeaders.map((item, index) => (
                      <MenuItem key={index} data-index={index} value={item.Header}>
                        {item.Header}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.regnr && errors.regnr && <FormHelperText>{errors.regnr}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box>
          <TableContainer
            className={classes.tableContainer}
            style={{ height: height, width: width }}
            ref={tableRef}>
            <Table size="small" className={classes.table} stickyHeader>
              <TableHead>
                <TableRow>
                  {excelHeaders.map((row, index) => {
                    if (values.regnr !== row.Header) {
                      return (
                        <TableCell key={index} className={row.fieldName !== null && "locked"}>
                          <SelectColumn
                            row={row}
                            setFieldValue={setFieldValue}
                            importDataContext={importDataContext}
                            value={values[row.fieldName]}
                          />
                          <Typography variant="caption">{row.Header}</Typography>
                        </TableCell>
                      )
                    } else {
                      return (
                        <TableCell className={row.fieldName !== null && "locked"}>
                          <Typography variant="bodySmall">Regnr</Typography>
                          <Typography variant="caption">{row.Header}</Typography>
                        </TableCell>
                      )
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataValues &&
                  dataValues.current.excelData
                    .slice(0, 100) // TO DO: Lazy loading
                    .map((row) => (
                      <TableRow key={row.name}>
                        {excelHeaders.map((header, index) => {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              className={row[header.Header] != null && header.fieldName !== null && "locked"}>
                              {header.dataType === "date" ? (
                                <Moment format="YYYY-MM-DD HH:mm" globallocale="sv">
                                  {row[header.Header]}
                                </Moment>
                              ) : (
                                row[header.Header]
                              )}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={
          (touched.propellant && errors.propellant) ||
          (touched.odometer && errors.odometer) ||
          (touched.fuelVolume && errors.fuelVolume) ||
          (touched.transactionTime && errors.transactionTime)
        }>
        <Box bgcolor="background.dark" color="foreground.inverted" borderRadius="8px" padding={1}>
          <FormControl
            className={classes.formControl}
            error={
              (touched.propellant && errors.propellant) ||
              (touched.odometer && errors.odometer) ||
              (touched.fuelVolume && errors.fuelVolume) ||
              (touched.transactionTime && errors.transactionTime)
            }
            variant="outlined">
            {touched.regnr && errors.regnr && <FormHelperText>{errors.regnr}</FormHelperText>}
            {touched.propellant && errors.propellant && <FormHelperText>{errors.propellant}</FormHelperText>}
            {touched.odometer && errors.odometer && <FormHelperText>{errors.odometer}</FormHelperText>}
            {touched.fuelVolume && errors.fuelVolume && <FormHelperText>{errors.fuelVolume}</FormHelperText>}
            {touched.transactionTime && errors.transactionTime && (
              <FormHelperText>{errors.transactionTime}</FormHelperText>
            )}
          </FormControl>
        </Box>
      </Snackbar>
    </>
  )
}
