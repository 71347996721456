import React, { useState } from "react"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { IFormUploadPreviewModalProps } from "./formuploadpreviewmodal.interface"

const FormUploadPreviewModal = (props: IFormUploadPreviewModalProps) => {
  const { attachment, open } = props
  const [dialogOpen, setDialogOpen] = useState<boolean>(open)

  const closeModal = () => {
    setDialogOpen(false)
  }

  return (
    attachment && (
      <>
        <Dialog open={dialogOpen} onClose={closeModal}>
          <DialogTitle>
            {" "}
            <Grid container justifyContent={"space-between"} alignItems={"center"} flexWrap={"nowrap"}>
              {attachment?.fileName}
              <Button
                aria-label="close"
                onClick={closeModal}
                color="primary"
                variant="outlined"
                sx={{
                  mr: 2
                }}>
                Stäng
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <img
              style={{
                maxWidth: "100%"
              }}
              src={`data:image/jpeg;base64,${attachment.Data}`}
              alt={`${attachment?.fileName}`}
              onClick={closeModal}
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </>
    )
  )
}

export default FormUploadPreviewModal
