import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import moment from 'moment'
import {useEffect, useState} from 'react'

const getMonths = () => {
  const months = []
  const dateStart = moment()
  const dateEnd = moment().add(11, 'month')
  while (dateEnd.diff(dateStart, 'months') >= 0) {
    months.push(dateStart.format('MM'))
    dateStart.add(1, 'month')
  }
  return months
}

export const MonthPicker = ({month, setMonth}) => {
  const [months, setMonths] = useState()
  useEffect(() => {
    setMonths(getMonths())
  }, [])

  return (
    <FormControl variant="outlined">
      <InputLabel id="month-label">Månad</InputLabel>
      <Select
        labelId="month-label"
        id="month-select"
        label="Månad"
        value={month}
        onChange={(event) => setMonth(event.target.value)}
      >
        {months?.map((monthItem) => (
          <MenuItem key={monthItem} value={monthItem}>
            {moment().month(monthItem).add(-1, 'month').format('MMMM')}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
