const accordionStyle = {
    styleOverrides: {
        root: {
            borderBottom: "1px solid #eeeeee",
            boxShadow: "none",
            "&:before": {
                height: 0
            },
            "& .MuiAccordionSummary-content, .MuiAccordionDetails-root": {
                display: "block"
            }
        }
    }
};
export default accordionStyle;
