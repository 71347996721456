export const validityOptionName = (value) => {
  switch (value) {
    case "five_years":
      return `dropdown_values.five_years`
    case "two_years":
      return `dropdown_values.two_years`
    case "one_year":
      return `dropdown_values.one_year`
    case "six_months":
      return `dropdown_values.six_months`
    case "three_months":
      return `dropdown_values.three_months`
    default:
      return `dropdown_values.one_year`
  }
}

export const reminderOptionName = (value) => {
  switch (value) {
    case "three_months":
      return `dropdown_values.three_months`
    case "thirty_days":
      return `dropdown_values.thirty_days`
    default:
      return null
  }
}

export const recurringReminderName = (value) => {
  switch (value) {
    case "every_ten_days":
      return `dropdown_values.ten_days`
    case "every_three_days":
      return `dropdown_values.three_days`
    default:
      return null
  }
}
