import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import React from "react"

const ClpTableRow = (props) => {
  const { row, rowProps, subColumns, toggleRowOpen, open, visibleColumns, renderRowSubComponent, cellProps } =
    props

  return (
    <>
      <TableRow
        style={{ backgroundColor: row.isExpanded && "#f9fafb" }}
        className={row.isExpanded && "expanded"}
        {...row.getRowProps(rowProps && rowProps(row))}
        key={"row-data-" + row.id}>
        {subColumns && subColumns.length > 0 && (
          <TableCell>
            <FontAwesomeIcon
              style={{
                cursor: "pointer",
                color: "#2ea2bf"
              }}
              id={row.id}
              onClick={() => toggleRowOpen(row.id)}
              icon={open === row.id ? faMinus : faPlus}
            />
          </TableCell>
        )}

        {row.cells.map((cell, i) => {
          return (
            !cell.column.hidden && (
              <TableCell
                {...cell.getCellProps(!cell.column.hiddenFilter && cellProps && cellProps(row))}
                data-label={cell.column.Header}
                align={cell.column.align ? cell.column.align : "left"}
                style={{
                  padding: cell.column.disablePadding && 0,
                  cursor: "pointer"
                }}>
                {cell.render("Cell")}
              </TableCell>
            )
          )
        })}
      </TableRow>
      {open === row.id && (
        <TableRow className="expanded">
          <TableCell colSpan={12}>
            {renderRowSubComponent({
              row,
              subColumns
            })}
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default ClpTableRow
