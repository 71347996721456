import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  folderFrame: {
    border: `1px solid ${theme.palette.grey[100]}`,
    display: "block",
    borderRadius: 4,
    padding: 15,
    "&.active": {
      background: theme.palette.primary["light"],
      borderColor: theme.palette.primary["dark"]
    }
  }
}))
