import theme from "css/theme/light"

export enum VehicleCheckStatus {
  Upcoming = 1,
  Booked = 2,
  Late = 3,
  passed = 4,
  failed = 5,
  waiting = 6
}

export function checksMainStatusColor(status: number) {
  switch (status) {
    case VehicleCheckStatus.Upcoming:
      return theme.palette.warning.main
    case VehicleCheckStatus.Booked:
      return theme.palette.primary.main
    case VehicleCheckStatus.passed:
      return theme.palette.success.main
    case VehicleCheckStatus.Late:
    case VehicleCheckStatus.failed:
      return theme.palette.error.main
    case VehicleCheckStatus.waiting:
      return theme.palette.grey[700]
    default:
      return theme.palette.primary.main
  }
}

export function checkLightStatusColor(status: VehicleCheckStatus) {
  switch (status) {
    case VehicleCheckStatus.Upcoming:
      return theme.palette.warning.light
    case VehicleCheckStatus.Booked:
      return theme.palette.primary.light
    case VehicleCheckStatus.Late:
      return theme.palette.error.light
    case VehicleCheckStatus.passed:
      return theme.palette.success.light
    case VehicleCheckStatus.failed:
      return theme.palette.error.light
    case VehicleCheckStatus.waiting:
      return theme.palette.grey[200]
    default:
      return
  }
}