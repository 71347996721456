import { Avatar, Divider, Grid, Switch, TextField, Typography } from "@mui/material"
import PaperBox from "components/PaperBox"
import MenuIcon from "components/navigation/MenuIcon"
import globalUseStyles from "css/theme/globalUseStyles"
import { FieldArray } from "formik"
import { t as globalT } from "i18next"
import { useTranslation } from "react-i18next"
import { FeatureBox } from "./FeatureBox"

export const diableIfPartnerOrSystemAdmin = (module: any, modules: any[]) => {
  if (
    module.feature === "PartnerCustomers" &&
    modules?.findIndex((m): any => m.feature === "SystemAdminCustomers" && m.enabled) !== -1
  ) {
    return true
  } else if (
    module.feature === "SystemAdminCustomers" &&
    modules?.findIndex((m): any => m.feature === "PartnerCustomers" && m.enabled) !== -1
  ) {
    return true
  }
}

export const ModuleBox = ({
  handleChange,
  handleBlur,
  menuIcon,
  index,
  setFieldValue,
  module,
  arrayName,
  modules,
  values,
  touched,
  errors
}: any) => {
  const globalClasses = globalUseStyles()

  const { t } = useTranslation("translation", { keyPrefix: "global.features" })

  return (
    <PaperBox>
      <Grid container direction="row" justifyContent="flex-start" spacing={3}>
        <Grid item xs={3} md={1}>
          <Avatar
            aria-label={module?.name}
            className={`${globalClasses.avatar} ${globalClasses.backgroundPrimary}`}>
            <MenuIcon name={menuIcon} />
          </Avatar>
        </Grid>
        <Grid item xs={9} md={11}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={10} md={11}>
              <Typography variant="h5">
                {t(`title.${module?.feature.toLowerCase()}`, { defaultValue: module?.name })}
              </Typography>
              <Typography variant="bodySmall">
                {t(`description.${module?.feature.toLowerCase()}`, { defaultValue: module?.description })}
              </Typography>
            </Grid>

            <Grid item xs={2} md={1} justifyContent="flex-end" textAlign="right">
              <Switch
                onChange={() => setFieldValue(`${arrayName}.${index}.enabled`, !module?.enabled)}
                checked={module?.enabled}
                disabled={diableIfPartnerOrSystemAdmin(module, modules)}
              />
            </Grid>
          </Grid>

          {module?.enabled && module?.maxUsersEnabled && (
            <Grid item xs={12}>
              <Divider sx={{ marginY: 1 }} light />
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={10} md={9}>
                  <Typography variant="labelMedium" display="block">
                    {globalT("settings.form.max_users")}
                  </Typography>
                  <Typography variant="bodyCompactSmall">
                    {globalT("settings.form.max_users_description")}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={3} justifyContent="flex-end">
                  <TextField
                    size="small"
                    autoComplete="off"
                    fullWidth
                    id={`${arrayName}.${index}.maxUsers`}
                    type="number"
                    value={module?.maxUsers ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {module?.enabled && (
            <FieldArray
              name={`modules.${index}`}
              render={() => (
                <div>
                  {module?.features && module.features.length > 0 && <Divider sx={{ marginY: 1 }} light />}
                  {module?.features &&
                    module.features.length > 0 &&
                    module.features.map((feature: any, featureIndex: number) => (
                      <FeatureBox
                        index={featureIndex}
                        feature={feature}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        menuIcon={module.icon}
                        setFieldValue={setFieldValue}
                        arrayName={`modules.${index}.features.${featureIndex}`}
                      />
                    ))}
                </div>
              )}
            />
          )}
        </Grid>
      </Grid>
    </PaperBox>
  )
}
