import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    box: {
        borderRadius: "4px"
    }
}));

const LoadingBox = ({ width, height, variant, noAnimate }) => {
    const classes = useStyles();
    return (
        <Skeleton
            className={classes.box}
            animation={noAnimate ? false : "wave"}
            variant={variant ? variant : "rect"}
            width={width ? width : "40px"}
            height={height ? height : "40px"}
        ></Skeleton>
    );
};

export default LoadingBox;
