import { Autocomplete, FormControl, InputLabel, TextField, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import globalUseStyles from "css/theme/globalUseStyles"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { t } from "i18next"
import { FC, useEffect, useState } from "react"
import { IFormSelectProps } from "./formvehiclepicker.interface"

const FormVehiclePicker: FC<IFormSelectProps> = (props) => {
  const globalClasses = globalUseStyles()
  const { id, labelKey, labelDefault, handleChange, value, optional } = props
  const [vehiclesResult, vehiclesApi] = useApi() as [IApiResult, IApiFunc]
  const [vehicleOptions, setVehicleOptions] = useState<Array<{ label: string; value: string }>>()

  useEffect(() => {
    vehiclesApi.get(apiEndpoints.vehicleregistry.getvehiclelist)
  }, [])

  useEffect(() => {
    if (vehiclesResult.status === 2) {
      const selectOptions: Array<{ label: string; value: string }> = vehiclesResult?.data
        ? vehiclesResult?.data?.map((vehicle: any) => ({
            label: vehicle.regNumber,
            value: vehicle.id
          }))
        : []
      setVehicleOptions(selectOptions)
    }
  }, [vehiclesResult])
  return (
    <>
      <InputLabel className={globalClasses.marginBottom5}>
        <Typography variant="labelLarge" fontWeight={500}>
          {labelKey ? t(labelKey, labelDefault) : labelDefault} {!optional && "*"}
        </Typography>
      </InputLabel>
      <FormControl fullWidth>
        <Autocomplete
          loading
          loadingText="Laddar..."
          sx={{ width: "100%", background: theme.palette.background.paper }}
          id={id?.toString()}
          options={vehicleOptions ? vehicleOptions : []}
          getOptionLabel={(option) => option?.label}
          onChange={(e, option) => handleChange && handleChange(option?.value)}
          includeInputInList
          value={
            vehicleOptions?.find(
              (i) =>
                i.value?.toString() === value?.toString() ||
                i.value?.toString() === JSON.parse(value)?.Id?.toString()
            ) || null
          }
          renderInput={(params) => <TextField {...params} name={id?.toString()} variant="outlined" />}
        />
      </FormControl>
    </>
  )
}

export default FormVehiclePicker
