import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Container, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import ClpTable from "components/ClpTable"
import globalUseStyles from "css/theme/globalUseStyles"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect, useState } from "react"

const Payslip = () => {
  const [listPayslipsResult, listPayslipsApi] = useApiGet()
  const [downloadPayslipResult, downloadPayslipApi] = useApiGet()
  const [loadingSalary, setLoadingSalary] = useState(null)
  const globalClasses = globalUseStyles()

  useEffect(async () => {
    const result = await listPayslipsApi(apiEndpoints.payslip.listpayslips)
  }, [])

  const DownloadPdf = (pdf, filename) => {
    const linkSource = `data:application/pdf;base64,${pdf}`
    const downloadLink = document.createElement("a")

    downloadLink.href = linkSource
    downloadLink.download = filename
    downloadLink.setAttribute("type", "hidden")
    document.body.appendChild(downloadLink)
    downloadLink.click()
  }

  const asyncOnDownLoadButton = async (salaryPeriodId) => {
    setLoadingSalary(salaryPeriodId)
    await downloadPayslipApi(apiEndpoints.payslip.downloadpayslip.replace("{salaryPeriodId}", salaryPeriodId))
    downloadPayslipResult.status = 0
  }

  if (downloadPayslipResult.status === 2) {
    DownloadPdf(
      downloadPayslipResult.data.pdfStr,
      "Lonespec_" + downloadPayslipResult.data.salaryPeriodPaymentDate
    )
    downloadPayslipResult.status = 0
    setLoadingSalary(null)
  }
  const columns = [
    {
      accessor: "salaryPeriodName",
      numeric: false,
      Header: "Period"
    },
    {
      accessor: "salaryPeriodPaymentDate",
      numeric: false,
      Header: "Utbetalningsdag"
    },
    {
      accessor: "salaryPeriodId",
      numeric: false,
      align: "right",
      Cell: ({ cell }) => {
        return (
          <Button
            size="small"
            variant="outlined"
            startIcon={
              downloadPayslipResult.status === 1 &&
              loadingSalary === cell.row.original.salaryPeriodId && (
                <FontAwesomeIcon spin icon={faCircleNotch} />
              )
            }
            onClick={() => {
              asyncOnDownLoadButton(cell.row.original.salaryPeriodId)
            }}>
            Ladda ned
          </Button>
        )
      }
    }
  ]

  return (
    <div>
      <Container>
        <Box marginY={3} mb={2}>
          <Typography variant="h1">Lön</Typography>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          rows={
            listPayslipsResult.status === 2
              ? listPayslipsResult?.data.length > 0
                ? listPayslipsResult?.data
                : []
              : []
          }
          status={listPayslipsResult.status}
          columns={columns}
        />
      </Box>
    </div>
  )
}
export default Payslip
