import React from "react";
import { Grid } from "@mui/material";
import LoadingTypography from "components/skeleton/LoadingTypography";
import LoadingBox from "components/skeleton/LoadingBox";
import PaperBox from "components/PaperBox";

const LoadingAdminSummaryTemplate = () => {
    return [0, 1].map((value) => (
        <Grid item xs={12} key={value}>
            <PaperBox marginTop={1} marginBottom={3}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={4} md={3}>
                        <LoadingBox height="125px" width="100%" />
                    </Grid>
                    <Grid item xs={8} md={8}>
                        <LoadingTypography variant="h5" />
                        <LoadingTypography variant="caption" />
                        <LoadingTypography variant="caption" />
                        <LoadingTypography variant="caption" />
                        <LoadingTypography variant="caption" />
                    </Grid>
                </Grid>
            </PaperBox>
        </Grid>
    ));
};
export default LoadingAdminSummaryTemplate;
