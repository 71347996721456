import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import moment from "moment"
import Moment from "react-moment"

interface UnitDetailsProps {
  category: string
  fields: Array<FieldProps>
}
interface FieldProps {
  name: string
  value: string
  type: string
  fields: FieldProps[]
}

const JsonArray = ({ values }: any) => {
  return values.map((field: any) => (
    <Box>
      <Typography variant="bodyMedium" fontWeight="400" display="flex">
        {field.name}:
        <Typography ml={1} variant="bodyMedium" fontWeight="600">
          {field.value}
        </Typography>
      </Typography>
    </Box>
  ))
}

const FieldType = (field: FieldProps) => {
  switch (field.type) {
    case "Boolean":
      return field.value === "True" ? (
        <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.success["main"]} />
      ) : (
        "-"
      )
    case "DateTime":
      return field.value ? (
        moment(field.value).isValid() && (
          <Moment format="YYYY-MM-DD HH:mm:ss" locale="sv">
            {field.value}
          </Moment>)
      ) : (
        "-"
      )
    case "Percent":
      return field.value ? field.value + "%" : "-"
    case "List":
      return <Box flexWrap="wrap" gap={2} display="flex" flexDirection="row" alignItems="flex-start"><JsonArray values={field.fields} /></Box>
    case "Temp":
      return field.value ? field.value + "°" : "-"
    case null:
      return field.value ? field.value : "-"
    default:
      return field.value
  }
}

const UnitDetails = ({ category, fields }: UnitDetailsProps) => {
  return (
    <PaperBox sx={{ padding: "20px", mt: 0, mb: "16px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}>
      <Typography variant="h3" mb={2} color="primary">
        {category}
      </Typography>
      <Box flexWrap="wrap" gap={3} display="flex" flexDirection="row" alignItems="flex-start">
        {fields &&
          fields.map((field: FieldProps) => (
            <Box>
              <Typography mb={1} color="textSecondary" variant="bodySmall">
                {field.name}
              </Typography>
              <Typography sx={{ display: "flex" }} variant="bodyMedium" fontWeight="600">
                {FieldType(field)}
              </Typography>
            </Box>
          ))}
      </Box>
    </PaperBox>
  )
}

export default UnitDetails
