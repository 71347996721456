import React from "react";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from "components/Button";
import { IRemoveDialog } from "../../interfaces/removedialog.interface";

export const RemoveDialog = (props: IRemoveDialog) => {
    const { openDialog, setDialogOpen, deleteResult, deleteTemplate } = props;
    const handleClose = () => {
        setDialogOpen(false);
    };
    return (
        <Dialog open={openDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Ta bort rapportmall?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">Är du säker att du vill ta bort rapportmall? Ditt val går inte att ångra.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    Avbryt
                </Button>
                <Button result={deleteResult} onClick={deleteTemplate} color="primary" autoFocus>
                    Ja, jag är säker
                </Button>
            </DialogActions>
        </Dialog>
    );
};
