import React from "react"
import { Box, IconButton } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-solid-svg-icons"

const ManualPagination = (props) => {
  const { pageOptions, pageIndex, setPage, currentpage, totalPage } = props
  return (
    <Box>
      <span>
        Sida{" "}
        <strong>
          {pageIndex} av {pageOptions.length}
        </strong>{" "}
      </span>
      <IconButton onClick={() => setPage(1)} disabled={currentpage === 1} size="large">
        <FontAwesomeIcon icon={faChevronDoubleLeft} size="xs" />
      </IconButton>
      <IconButton
        onClick={() => setPage((s) => (s === 0 ? 0 : s - 1))}
        disabled={currentpage === 1}
        size="large">
        <FontAwesomeIcon icon={faChevronLeft} size="xs" />
      </IconButton>
      <IconButton onClick={() => setPage((s) => s + 1)} disabled={currentpage === totalPage} size="large">
        <FontAwesomeIcon icon={faChevronRight} size="xs" />
      </IconButton>
      <IconButton onClick={() => setPage(totalPage)} disabled={currentpage === totalPage} size="large">
        <FontAwesomeIcon icon={faChevronDoubleRight} size="xs" />
      </IconButton>
    </Box>
  )
}

export default ManualPagination
