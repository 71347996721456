import { Box, Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Overview } from "./components/overview/Overview"
import { useStyles } from "./useStyles"

const Devices = () => {
  const classes = useStyles()
  const globalClasses = globalUseStyles()
  const [deviceRegistryResult, deviceRegistryFunc] = useApi()
  const [deviceRegistryBoxesResult, deviceRegistryBoxesFunc] = useApi()

  useEffect(() => {
    deviceRegistryFunc.get(apiEndpoints.systemadmindeviceregistry.getdashboarddata)
    return () => {
      deviceRegistryFunc.leave()
    }
  }, [deviceRegistryFunc])

  React.useEffect(() => {
    const interval = setInterval(() => {
      deviceRegistryBoxesFunc.get(apiEndpoints.systemadmindeviceregistry.getdashboarddata)
    }, 10000)

    return () => {
      deviceRegistryBoxesFunc.leave()
      clearInterval(interval)
    }
  }, [deviceRegistryBoxesFunc])

  const [overviewData, setOverviewData] = useState(null)
  const [timestamp, setTimestamp] = useState("")

  React.useEffect(() => {
    if (deviceRegistryBoxesResult.status === 2 || deviceRegistryResult.status === 2) {
      setOverviewData(
        deviceRegistryBoxesResult.data ? deviceRegistryBoxesResult.data : deviceRegistryResult.data
      )
      setTimestamp(moment().format("YYYY-MM-DD HH:mm:ss"))
    }
  }, [deviceRegistryBoxesResult])

  const columns = [
    {
      accessor: "deviceId",
      Header: "ID"
    },
    {
      accessor: "internalDeviceId",
      Header: "Internnr"
    },
    {
      accessor: "companyName",
      Header: "Bolag"
    },
    {
      accessor: (row) => {
        return row.engineTripFuelTime ? moment(row.engineTripFuelTime).format("YYYY-MM-DD HH:mm") : ""
      },
      Header: "Tripfuel"
    },
    {
      accessor: (row) => {
        return row.engineTotalFuelUsedTime
          ? moment(row.engineTotalFuelUsedTime).format("YYYY-MM-DD HH:mm")
          : ""
      },
      Header: "Totalfuel"
    },
    {
      accessor: (row) => {
        return row.eighResolutionEngineTotalFuelUsedTime
          ? moment(row.eighResolutionEngineTotalFuelUsedTime).format("YYYY-MM-DD HH:mm")
          : ""
      },
      Header: "High Resolution TotalFuel"
    },
    {
      accessor: (row) => {
        return row.latestTimeGps ? moment(row.latestTimeGps).format("YYYY-MM-DD HH:mm") : ""
      },
      Header: "GPS time"
    },
    {
      accessor: "deviceDescription",
      Header: "Beskrivning"
    },

    {
      accessor: (row) => {
        return row.alcoholAlarmIsNotActive !== null && !row.alcoholAlarmIsNotActive ? "Ja" : "-"
      },
      Header: "Alkolås Aktiv"
    },
    {
      accessor: (row) => {
        return row.driverCardIsNotActive !== null && !row.driverCardIsNotActive ? "Ja" : "-"
      },
      Header: "Startspärr Aktiv"
    },
    {
      accessor: (row) => {
        return row.latestTimeOnline ? moment(row.latestTimeOnline).format("YYYY-MM-DD HH:mm") : ""
      },
      Header: "Online"
    }
  ]

  return (
    <div>
      <Container>
        <Box>
          <Typography variant="h1" gutterBottom>
            Översikt
          </Typography>
        </Box>

        <Box marginBottom={2} column>
          <Box className={classes.lastUpdate} mb={1}>
            <Typography variant="bodySmall">Senast uppdaterad: {timestamp}</Typography>
          </Box>
          <Grid container spacing={2}>
            <Overview
              overviewData={deviceRegistryResult.status === 2 ? deviceRegistryResult.data : overviewData}
            />
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rows={deviceRegistryResult.status === 2 && deviceRegistryResult.data?.deviceData}
          status={deviceRegistryResult.status}
        />
      </Box>
    </div>
  )
}

export default Devices
