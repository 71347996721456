import { Box, Container, Grid, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import moment from "moment"
import React, { useContext, useEffect, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"

const Storage = () => {
  const globalClasses = globalUseStyles()
  const routerHistory = useHistory()

  const [storageResult, getStorage] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    getStorage(apiEndpoints.systemadmindeviceregistry.liststorages)
  }, [getStorage])

  useEffect(() => {
    loadingContext.setLoading(() => storageResult.status === 1)
  })
  const columns = useMemo(
    () => [
      {
        accessor: "name",
        numeric: false,
        disablePadding: false,
        Header: "Namn"
      },
      {
        accessor: "companyName",
        numeric: false,
        disablePadding: false,
        Header: "Bolag"
      },
      {
        accessor: "equipmentCount",
        numeric: true,
        disablePadding: false,
        Header: "Antal kopplade utrustningar"
      },
      {
        accessor: (row) => {
          return row.created ? moment(row.created).format("YYYY-MM-DD hh:mm") : ""
        },
        numeric: false,
        Header: "Skapad"
      }
    ],
    []
  )

  return (
    <div>
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Lagerplatser</Typography>
            <Button
              component={Link}
              to={allRoutes.SystemadminDevicesStorageAdd()}
              color="primary"
              variant="contained">
              Lägg till lagerplats
            </Button>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rowProps={(row) => ({
            onClick: () => routerHistory.push(allRoutes.SystemadminDevicesStorageEdit(row.original.id))
          })}
          rows={storageResult.status === 2 && storageResult.data}
          status={storageResult.status}
        />
      </Box>
    </div>
  )
}

export default Storage
