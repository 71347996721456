import moment from "moment";
import React, { createContext, useRef } from "react";

export const ImportFuelContext = createContext();

const convertData = (header, row) => {
    switch (header.dataType) {
        case "string":
            return row[header.Header];
        case "number":
            return parseInt(row[header.Header]) ? parseInt(row[header.Header]) : 0;
        case "date":
            return moment(row[header.Header]).local().format("YYYY-MM-DD HH:mm");
        default:
            return row[header.Header];
    }
};

export const ImportFuelFormStateProvider = ({ children }) => {
    const values = useRef({
        current: {}
    });
    const updateImportData = (data) => {
        values.current = data;
    };
    const getImportedData = () => {
        return values;
    };

    const excelHeaders = useRef({
        current: {}
    });
    const addExcelHeaders = (data) => {
        excelHeaders.current = data;
    };
    const getExcelHeaders = (data) => {
        return excelHeaders.current;
    };

    const selectedData = useRef({
        fields: [],
        columns: [
            {
                name: "Radnr",
                id: "rowNr",
                dataType: "string",
                fieldName: ""
            },
            {
                name: "Regnr",
                id: "regNumber",
                dataType: "string",
                fieldName: ""
            },
            {
                name: "Drivmedel",
                id: "propellant",
                dataType: "string",
                fieldName: ""
            },
            {
                name: "Mätarställning",
                id: "odometer",
                dataType: "number",
                fieldName: ""
            },
            {
                name: "Volym",
                id: "fuelVolume",
                dataType: "number",
                fieldName: ""
            },
            {
                name: "Transaktionsdatum ",
                id: "transactionTime",
                dataType: "date",
                fieldName: ""
            }
        ]
    });
    const getSelectedData = () => {
        return selectedData;
    };

    const updateIdendifikation = (data) => {
        updateColumns("regNumber", "string", data);
    };

    const updateColumns = (value, dataType, fieldName) => {
        if (!value) {
            let removeFieldName = excelHeaders.current.find((x) => x.accessor === fieldName);
            if (removeFieldName) {
                removeFieldName.fieldName = null;
                removeFieldName.dataType = null;
            }
        }
        if (fieldName && value) {
            let removeFieldName = excelHeaders.current.find((x) => x.fieldName === value);
            if (removeFieldName) {
                removeFieldName.fieldName = null;
                removeFieldName.dataType = null;
            }

            let column = excelHeaders.current.find((x) => x.Header === fieldName);
            if (column) {
                column.fieldName = value;
                column.dataType = dataType;
            }
        }
        return excelHeaders;
    };

    const getDataToImport = () => {
        let excelData = [];

        values.current.excelData.map((row, index) => {
            const filteredRow = {};
            filteredRow["rowNr"] = index + 1;
            excelHeaders.current
                .filter((x) => x.fieldName !== null)
                .map((header) => {
                    filteredRow[header.fieldName] = convertData(header, row);
                });

            filteredRow["regNumber"] !== undefined && excelData.push(filteredRow);
        });
        return excelData;
    };

    return (
        <ImportFuelContext.Provider
            value={{
                updateImportData,
                getImportedData,
                getSelectedData,
                getDataToImport,
                updateIdendifikation,
                updateColumns,
                addExcelHeaders,
                getExcelHeaders
            }}
        >
            {children}
        </ImportFuelContext.Provider>
    );
};
