import { Autocomplete, Stack, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect } from "react"
import { useStyles } from "../../../components/useStyles"

const WorkOrderCardDropdown = ({
  vehiclesResult,
  initialValues,
  vehicleIdFromDash,
  setFieldValue,
  setVehicle,
  values,
  handleChange,
  handleBlur,
  touched,
  errors
}) => {
  const classes = useStyles()

  useEffect(() => {
    setVehicle(vehicleIdFromDash)
  }, [vehicleIdFromDash])

  return (
    <Box sx={{ maxWidth: 220 }} mb={4}>
      <Typography pb={1} variant="h6">
        {vehiclesResult ? "Välj fordon" : "Vald fordon"}
      </Typography>
      {vehiclesResult ? (
        <Autocomplete
          className={classes.fullWidth}
          id="vehicleId"
          options={vehiclesResult ? vehiclesResult : null}
          getOptionLabel={(option) => (option.internnumber ? option.internnumber : null)}
          value={vehiclesResult ? vehiclesResult.find((i) => i.id === values.vehicleId) : null}
          getOptionSelected={(option, value) => {
            return option.id === value.id
          }}
          onChange={(e, value) => {
            setFieldValue("vehicleId", value?.id)
            setVehicle(value?.id)
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Box sx={{ fontWeight: 600, minWidth: "100px" }}>{option.internnumber}</Box>
                <Box sx={{ opacity: 0.6 }}>{option.regNumber}</Box>
              </Stack>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              name="vehicleId"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.vehicleId && errors.vehicleId ? true : false}
              helperText={touched.vehicleId && errors.vehicleId ? errors.vehicleId : null}
            />
          )}
        />
      ) : (
        <Typography variant="bodyLarge">{values.regNumber}</Typography>
      )}
    </Box>
  )
}

export default WorkOrderCardDropdown
