import {Font, StyleSheet, Text, View} from '@react-pdf/renderer'
import React from 'react'
Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: 'assets/learning/Poppins-Regular.ttf'
    },
    {
      src: 'assets/learning/Poppins-Bold.ttf',
      fontWeight: 'bold'
    },
    {
      src: 'assets/learning/Poppins-Light.ttf',
      fontWeight: 'light'
    }
  ]
})

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    marginTop: 4
  },
  reportTitle: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 4,
    marginTop: 4
  }
})

const Title = ({title}) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{title}</Text>
  </View>
)

export default Title
