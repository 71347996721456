import { Box, Button, ButtonGroup, Container, Grid, Typography } from "@mui/material";
import { GlobalLoaderContext } from "components/GlobalLoadingProvider";
import { allRoutes } from "generated/routes";
import { DashboardCard } from "pages/vehicleregistry/pages/dashboard/DashboardCard";
import React, { useContext, useEffect } from "react";
import LastUpdatedDate from "../LastUpdatedDate/LastUpdatedDate";

const Header = (props) => {
    const { openAdd, dashboardResult, lastUpdatedDate } = props;

    // loading while waiting
    const loadingContext = useContext(GlobalLoaderContext);
    useEffect(() => {
        loadingContext.setLoading(() => dashboardResult.status === 1);
    });

    return (
        <Container>
            <Box>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h1">Importera från Transportstyrelsen</Typography>
                    <ButtonGroup color="primary">
                        <Button
                            color="primary"
                            onClick={() => {
                                openAdd();
                            }}
                            variant="contained"
                        >
                            Importera från Transportstyrelsen
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Box>

            <LastUpdatedDate date={lastUpdatedDate} />

            {dashboardResult && (
                <Grid spacing={2} container direction="row">
                    <Grid item md={4} lg={3} direction="column">
                        <DashboardCard
                            img="assets/Transportstyrelsen-icon.jpg"
                            route={allRoutes.VehicleregistryVehiclesImportvtr()}
                            itemLength={dashboardResult.length}
                            title="Importera från Transportstyrelsen"
                            quantityType="st"
                        />
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default Header;
