import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import React, { useState } from "react"

import { Typography } from "@mui/material"

const SuccessDialog = ({ title, message, resetModalState, onAddSubmit, values }) => {
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    if (onAddSubmit && values) {
      onAddSubmit(values)
      setOpen(false)
      if (resetModalState) {
        resetModalState(false)
      }
    }
    setOpen(false)
    if (resetModalState) {
      resetModalState(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={message}
      maxWidth="xs">
      <Grid container direction="column" alignItems="center">
        <Box bgcolor="success.50" width="1" mb={2} textAlign="center">
          <Grid item my={2}>
            <DialogTitle id="alert-dialog-title">
              <Box className="fa-layers fa-fw text-center fa-3x" color="success.A100">
                <FontAwesomeIcon icon={faCircle} color="#89BE3F" />
                <FontAwesomeIcon icon={faCheck} color="success.contrastText" inverse size="xs" />
              </Box>
            </DialogTitle>
          </Grid>
        </Box>
        <DialogContent my={2}>
          <Typography align="center" variant="h4" paragraph>
            {title}
          </Typography>
          <Typography align="center" paragraph>
            {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box mb={3}>
            <Button onClick={handleClose} color="primary" variant="contained">
              OK
            </Button>
          </Box>
        </DialogActions>
      </Grid>
    </Dialog>
  )
}
export default SuccessDialog
