import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCloudDownload, faPen, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Chip, Container, Grid, Typography } from "@mui/material"
import { pdf } from "@react-pdf/renderer"
import { hasRole } from "auth/hasRole"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import IconForButton from "components/ButtonIcon"
import EditFormTemplate from "components/Form/FormTemplate/EditFormTemplate"
import { PdfFormTemplate } from "components/Form/FormTemplate/PdfFormTemplate"
import ViewFormTemplate from "components/Form/FormTemplate/ViewFormTemplate"
import LoadingPage from "components/Loading/LoadingPage"
import PaperBox from "components/PaperBox"
import { apiEndpoints } from "generated/apiEndpoints"
import EditDeviationForm from "pages/deviation/pages/components/EditDeviationForm"
import { deviationPriorityProps } from "pages/deviation/pages/helpers/deviationPriority"
import { deviationStatusProps } from "pages/deviation/pages/helpers/deviationStatus"
import React, { useContext, useEffect, useState } from "react"

const DeviationDetails: React.FC = ({ match }: any) => {
  const appContext = useContext(AppContext)
  const [deviation, getDeviation] = useApi() as [IApiResult, IApiFunc]
  const [formResult, getForm] = useApi() as [IApiResult, IApiFunc]
  const [editForm, setEditForm] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>(null)

  useEffect(() => {
    getDeviation.get(apiEndpoints.deviation.getbyid.replace("{id}", match.params.id))
  }, [getDeviation, match])

  useEffect(() => {
    console.log(editForm)
    if (deviation.status === 2 && !editForm) {
      getForm.get(
        apiEndpoints.form.getformresultbyid
          .replace("{id}", deviation.data?.formResult?.id)
          .replace("{formTemplate}", deviation.data?.formResult?.formTemplate?.childFeatures[0])
      )
    }
  }, [getForm, deviation, editForm])

  useEffect(() => {
    if (formResult.status === 2) {
      setFormData(formResult.data)
    }
  }, [formResult])

  return (
    <Container>
      <Grid container spacing={3} display={"flex"} justifyContent={"space-between"}>
        <Grid item md={9} xs={12}>
          <PaperBox>
            <Grid container justifyContent={"flex-end"} spacing={2} marginBottom={2}>
              <Grid item>
                {formData && (
                  <Button
                    variant="outlined"
                    startIcon={
                      <IconForButton icon={faCloudDownload as IconProp} noPadding={true} color="primary" />
                    }
                    onClick={async () => {
                      let blobPDF = await pdf(<PdfFormTemplate {...formData} />).toBlob()

                      const link = document.createElement("a")
                      link.href = window.URL.createObjectURL(blobPDF)
                      link.download = `${deviation?.data?.name}`
                      link.click()
                      window.URL.revokeObjectURL(link.href)
                    }}>
                    Ladda ner
                  </Button>
                )}
              </Grid>
              {hasRole(appContext.appUser, formData?.childFeatures[0].toString() + "Admin") && (
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={
                      <IconForButton
                        icon={editForm ? (faTimes as IconProp) : (faPen as IconProp)}
                        noPadding={true}
                        color="primary"
                      />
                    }
                    onClick={() => setEditForm(!editForm)}>
                    {editForm ? "Avbryt" : "Redigera avvikelse"}
                  </Button>
                </Grid>
              )}
            </Grid>
            {(deviation.status === 1 || formResult.status === 1) && <LoadingPage />}

            {formResult.status === 2 && (
              <>
                {deviation.status === 2 && formData && (
                  <>
                    {hasRole(appContext.appUser, formData?.childFeatures[0].toString() + "Admin") &&
                    editForm ? (
                      <EditFormTemplate
                        {...formData}
                        id={deviation.data?.formResult?.id}
                        formTemplateId={formData?.id}
                        feature={"Deviation"}
                        setEditForm={setEditForm}
                      />
                    ) : (
                      <ViewFormTemplate {...formData} />
                    )}
                  </>
                )}
              </>
            )}
          </PaperBox>
        </Grid>
        <Grid item md={3} xs={12}>
          {deviation.status === 2 &&
            formResult.status === 2 &&
            (hasRole(appContext.appUser, formData?.childFeatures[0].toString() + "Admin") ? (
              <EditDeviationForm {...deviation.data} feature={formResult.data.childFeatures} />
            ) : (
              <>
                <Box marginBottom={2}>
                  <Typography variant="inputLabel" fontWeight={500} color="textSecondary">
                    Status
                  </Typography>

                  <Box>
                    <FontAwesomeIcon
                      color={deviationStatusProps(deviation.data.status).color}
                      icon={deviationStatusProps(deviation.data.status).icon as IconProp}
                    />
                    <Typography
                      variant="labelMedium"
                      marginLeft={1}
                      color={deviationStatusProps(deviation.data.status).color}>
                      {deviationStatusProps(deviation.data.status).title}
                    </Typography>
                  </Box>
                </Box>
                <Box marginBottom={2}>
                  <>
                    <Typography variant="inputLabel" fontWeight={500} color="textSecondary">
                      Prioritet
                    </Typography>

                    <Chip
                      color={
                        deviationPriorityProps(deviation.data.priority)?.color !== null
                          ? deviationPriorityProps(deviation.data.priority)?.color
                          : "default"
                      }
                      variant="outlined"
                      label={deviationPriorityProps(deviation.data.priority)?.title}
                    />
                  </>
                </Box>
                <Box marginBottom={2}>
                  <>
                    <Typography variant="inputLabel" fontWeight={500} color="textSecondary">
                      Tilldelad
                    </Typography>
                    <Typography variant="bodyMedium" fontWeight={500}>
                      {deviation.data.assignedUser}
                    </Typography>
                  </>
                </Box>
              </>
            ))}
        </Grid>
      </Grid>
      <Grid container mx={"auto"} gap={3} display={"flex"} justifyContent={"center"} p={0}></Grid>
    </Container>
  )
}
export default DeviationDetails
