
import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { Box, SxProps, Theme } from "@mui/material"
import classNames from "classnames"
import globalUseStyles from "css/theme/globalUseStyles.js"
import styles from "./style"

export interface IIcon {
  icon: FontAwesomeIconProps["icon"]
  size: FontAwesomeIconProps["size"]
  color: string
  sx?: SxProps<Theme> | undefined
}

export const getFontSize = (size: string | undefined) => {
  switch (size) {
    case "xl":
      return "2x"
    case "lg":
      return "xl"
    case "md":
      return "lg"
    case "sm":
      return "md"
    case "xs":
      return "sm"
    default:
      return "md"
  }
}

export const getColorClass = (color: string | undefined) => {
  const globalClasses = globalUseStyles()
  switch (color) {
    case "primary":
      return globalClasses.backgroundPrimary
    case "success":
      return globalClasses.backgroundSuccess
    case "grey":
      return globalClasses.backgroundGrey
    case "warning":
      return globalClasses.backgroundWarning
    case "error":
      return globalClasses.backgroundError
    default:
      return color
  }
}

export const CircleIcon = (props: IIcon) => {
  const classes = styles(props)
  const { icon, size, color } = props

  return (
    <Box className={classNames(classes.iconBox, getColorClass(color))} {...props}>
      <FontAwesomeIcon size={getFontSize(size) as SizeProp} icon={icon} />
    </Box>
  )
}
