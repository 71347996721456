import { Box, Grid } from "@mui/material";
import { useApi } from "auth/useApi3";
import { GlobalLoaderContext } from "components/GlobalLoadingProvider";
import { apiEndpoints } from "generated/apiEndpoints";
import React, { useContext, useEffect } from "react";
import TemplateForm from "../components/TemplateForm/TempleteForm";

import { ReportTemplateContext } from "./ReportTemplateContextProvider";

const AddTemplatePage = ({ match }) => {
    const loadingContext = useContext(GlobalLoaderContext);
    const { setRouteId, reportFields, selectedFields, setSelectedFields, setFieldsToChoose, fieldsToChoose } = useContext(ReportTemplateContext);

    const [addFormTemplateResult, addFormTemplateFuncs] = useApi(); // for getting the "base template" for the form (for edit this is the edit object)
    const [createTemplateResult, createTemplateFuncs] = useApi(); // for saving a new template based on the form

    React.useEffect(() => {
        addFormTemplateFuncs.get(apiEndpoints.vehicleregistryreporting.getemptyreporttemplatetemplate);
        return () => {
            addFormTemplateFuncs.leave();
        };
    }, [addFormTemplateFuncs]);
    useEffect(() => {
        if (addFormTemplateResult.status === 2) {
            setFieldsToChoose(addFormTemplateResult.data);
            let selectedField = [];
            addFormTemplateResult.data?.fieldGroups?.map((fieldGroup, groupIndex) => {
                return fieldGroup?.items.map((checkedFilter, index) => {
                    if (checkedFilter.checked) {
                        checkedFilter.groupIndex = groupIndex;
                        checkedFilter.ReportFieldCategoryEnum = fieldGroup.reportFieldCategoryEnum;
                        checkedFilter.index = index;
                        checkedFilter.title = fieldGroup.title;
                        selectedField.push(checkedFilter);
                    }
                });
            });
        }
    });
    React.useEffect(() => {
        loadingContext.setLoading(() => addFormTemplateResult.status === 1);
    });

    const createTemplate = (values) => {
        let sortedSelectedFields = [];
        selectedFields.map((sf, index) => {
            sf.order = index;
            return sortedSelectedFields.push(sf);
        });

        const templateData = {
            name: values.name,
            description: values.description,
            fields: sortedSelectedFields
        };

        createTemplateFuncs.post(apiEndpoints.vehicleregistryreporting.addreporttemplate, templateData);
    };

    return (
        <>
            <Box mb={4}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center"></Grid>
            </Box>
            {addFormTemplateResult.status === 2 && (
                <TemplateForm editMode={false} onSubmit={createTemplate} addFormTemplate={fieldsToChoose} submitFeedbackResult={createTemplateResult} />
            )}
        </>
    );
};
export default AddTemplatePage;
