import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  exportIcon: {
    marginRight: "5px"
  },

  tableScroll: {
    width: "100%",
    height: "100%"
  },
  table: {
    "& thead": {
      top: "48px",
      position: "sticky",
      backgroundColor: theme.palette.background["default"]
    },
    "& .MuiTableCell-head": {
      cursor: "pointer"
    },
    "& .MuiSvgIcon-root": {
      opacity: "0",
      fontSize: "18px",
      transition:
        "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      marginLeft: "4px",
      userSelect: "none",
      marginRight: "4px"
    },
    "& .MuiTableSortLabel-active svg, .MuiSvgIcon-root": {
      color: "#254467",
      opacity: "1"
    },
    "& tbody tr": {
      "&:hover": {
        background: "#f9fafb"
      },
      "&.expanded": {
        "& .MuiTable-root": {
          paddingTop: "8px"
        },
        "&:hover": {
          background: "#f9fafb"
        },
        "& .MuiTableCell-root": {
          borderTop: "1px solid rgba(224, 224, 224, 1)",
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          paddingLeft: "16px"
        },
        "& .MuiTableCell-root:last-child": {
          borderRight: "1px solid rgba(224, 224, 224, 1)"
        },
        "& .MuiTableCell-root:first-child": {
          borderLeft: "1px solid rgba(224, 224, 224, 1)"
        },
        background: "#ffffff",
        border: "none",
        "&  th": {
          background: "#e5e5e5"
        },
        "& > td": {
          paddingTop: "0 !important"
        },
        "& td": {
          background: "#ffffff",
          padding: "8px"
        }
      }
    },
    [theme.breakpoints.down("xl")]: {
      border: 0,

      "& thead": {
        border: "none",
        clip: "rect(0 0 0 0)",
        height: "1px",
        margin: "-1px",
        padding: 0,
        position: "absolute",
        width: "1px"
      },

      "& tr": {
        borderBottom: "3px solid #ddd",
        display: "block",
        marginBottom: " .625em"
      },

      "& td": {
        borderBottom: "1px solid #ddd",
        display: "block",
        fontSize: " .8em",
        textAlign: "right",
        minHeight: "29px",
        "&.MuiTableCell-sizeSmall:first-of-type": {
          paddingLeft: "8px"
        }
      },

      "& td::before": {
        content: "attr(data-label)",
        float: "left",
        fontWeight: "bold",
        textTransform: "uppercase"
      },

      "& td:last-child": {
        borderBottom: 0
      }
    }
  },
  footerRow: {
    backgroundColor: "rgba(239, 239, 239, 1)",
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    zIndex: 100,
    "& td": {
      fontWeight: 700,
      color: "rgba(0, 0, 0, 0.87)",
      border: 0
    }
  },
  [theme.breakpoints.up("sm")]: {
    footerRow: {
      position: "sticky",
      bottom: 0
    }
  },
  footer: {
    background: "rgb(249 250 251)"
  },
  formControl: {
    minWidth: 200
  },
  expandableHeader: {
    border: "none",
    display: "flex"
  },
  expandableCell: {
    border: "none"
  },
  expandableBtn: {
    display: "flex",
    textDecoration: "1px underline",
    color: theme.palette.primary["main"]
  },
  expandIcon: {
    color: theme.palette.primary["main"]
  },
  expandableHeaderContainer: {
    display: "flex",
    alignItems: "center"
  },
  globalFilter: {
    display: "flex",
    gridGap: "20px"
  }
}))
