import moment from "moment"
import React, { createContext, useRef } from "react"

export const ImportContext = createContext()

const convertData = (header, row) => {
  switch (header.dataType) {
    case "string":
      return row[header.Header]
    case "number":
      return parseInt(row[header.Header]) ? parseInt(row[header.Header]) : 0
    case "date":
      return moment(row[header.Header]).local().format("YYYY-MM-DD HH:mm")
    default:
      return row[header.Header]
  }
}

export const ImportFormStateProvider = ({ children }) => {
  const values = useRef({
    current: {}
  })
  const updateImportData = (data) => {
    values.current = data
  }
  const getImportedData = () => {
    return values
  }

  const excelHeaders = useRef({
    current: {}
  })
  const addExcelHeaders = (data) => {
    excelHeaders.current = data
  }
  const getExcelHeaders = (data) => {
    return excelHeaders.current
  }

  const selectedData = useRef({
    fields: [],
    columns: [
      {
        name: "Radnr",
        id: "rowNr",
        dataType: "string",
        fieldName: ""
      },
      {
        name: "Regnr",
        id: "regNumber",
        dataType: "string",
        fieldName: ""
      },
      {
        name: "Internnr",
        id: "internNumber",
        dataType: "string",
        fieldName: ""
      },
      {
        name: "Drivmedel",
        id: "mainPropellant",
        dataType: "string",
        fieldName: ""
      },
      {
        name: "Fordonstyp", // Tom sträng
        id: "vehicleType",
        dataType: "string",
        fieldName: ""
      },
      {
        name: "Färdskrivare", // Default värde
        id: "tachographIntegrationActive",
        dataType: "bool",
        fieldName: ""
      },
      {
        name: "Bolag",
        id: "company",
        dataType: "string",
        fieldName: ""
      }
    ]
  })
  const getSelectedData = () => {
    return selectedData
  }

  const updateColumns = (value, dataType, fieldName) => {
    if (!value) {
      let removeFieldName = excelHeaders.current.find((x) => x.accessor === fieldName)
      if (removeFieldName) {
        removeFieldName.fieldName = null
        removeFieldName.dataType = null
      }
    }
    if (fieldName && value) {
      let removeFieldName = excelHeaders.current.find((x) => x.fieldName === value)
      if (removeFieldName) {
        removeFieldName.fieldName = null
        removeFieldName.dataType = null
      }

      let column = excelHeaders.current.find((x) => x.Header === fieldName)
      if (column) {
        column.fieldName = value
        column.dataType = dataType
      }
    }
    return excelHeaders
  }

  const getDataToImport = () => {
    let excelData = []
    values.current.excelData.map((row, index) => {
      const filteredRow = {}
      filteredRow["rowNr"] = index + 1
      filteredRow["mainPropellant"] = "HVO"
      filteredRow["tachographIntegrationActive"] = false
      excelHeaders.current
        .filter((x) => x.fieldName !== null)
        .map((header) => {
          filteredRow[header.fieldName] = convertData(header, row)
        })
      excelData.push(filteredRow)
    })
    return excelData
  }

  return (
    <ImportContext.Provider
      value={{
        updateImportData,
        getImportedData,
        getSelectedData,
        getDataToImport,
        updateColumns,
        addExcelHeaders,
        getExcelHeaders
      }}>
      {children}
    </ImportContext.Provider>
  )
}
