import { ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import LoadingBox from "components/skeleton/LoadingBox"
import LoadingTypography from "components/skeleton/LoadingTypography"
import React from "react"

export const StatsCardLoading = (props) => {
  return (
    <>
      <ListItem disableGutters>
        <ListItemAvatar>
          <LoadingBox variant="circle" />
        </ListItemAvatar>
        <ListItemText>
          <LoadingTypography variant="bodyMedium" />
          <LoadingTypography variant="h2" />
        </ListItemText>
      </ListItem>
      <ListItem disableGutters>
        <LoadingTypography variant="caption" />
      </ListItem>
    </>
  )
}
