import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3)
  },
  listItem: {
    borderRadius: 5,
    "& span": {
      opacity: 0.5
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  red: {
    background: theme.palette.error["light"],
    color: theme.palette.error["dark"],
    "& span": {
      opacity: 1
    }
  },
  green: {
    background: theme.palette.success["light"],
    color: theme.palette.success["dark"],
    "& span": {
      opacity: 1
    }
  },
  downloadPaper: {
    textAlign: "center",
    "& a": {
      backgroundColor: "#fafafa",
      padding: "15px",
      borderRadius: 3,
      textDecoration: "none",
      color: theme.palette.grey[600],
      display: "block"
    }
  },
  result: {
    fontWeight: 700,
    color: theme.palette.primary[300]
  },
  opacity5: {
    opacity: 0.5
  },
  rowReverseMobile: {
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column-reverse"
    }
  }
}))
