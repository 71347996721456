import { Box, Container, Grid, Typography } from "@mui/material";
import { useApiPost } from "auth/useApi2";
import { apiEndpoints } from "generated/apiEndpoints";
import { allRoutes } from "generated/routes";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ContractForm } from "../../components/ContractForm";

const AddContract = () => {
    const [saveContractResult, saveContract] = useApiPost();
    const routerHistory = useHistory();

    const handleSubmit = (objectToSave) => {
        console.log(objectToSave);
        saveContract(apiEndpoints.vehicleregistryadmin.addcontract, objectToSave);
    };

    useEffect(() => {
        if (saveContractResult.status === 2) routerHistory.push(allRoutes.VehicleregistryReportsContract());
    }, [saveContractResult.status, routerHistory]);

    const feedback = {
        errorTitle: "Avtal kunde inte skapas"
    };

    return (
        <div>
            <Container>
                <Grid container justifyContent="center">
                    <Grid item md={8} lg={6}>
                        <Box my={3}>
                            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h2">Lägg till avtal</Typography>
                            </Grid>
                        </Box>

                        <ContractForm onSubmit={handleSubmit} result={saveContractResult} feedback={feedback} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};
export default AddContract;
