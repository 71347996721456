import { ActionType, ModalState } from "./InspectionBookingSidebar.interfaces"
import React, { Dispatch, SetStateAction } from "react"
import { useFormikContext } from "formik"
import Dialog from "@mui/material/Dialog"
import { Box, Button, DialogActions, DialogContent, Grid, Typography } from "@mui/material"

export const initialModalState = {
  actionType: ActionType.Cancel,
  isOpen: false,
  title: "",
  message: ""
}

interface DialogModalProps {
  modalData: ModalState
  setModalData: Dispatch<SetStateAction<ModalState>>
  setShowSidebar: (showSidebar: boolean) => void
}

export const ModalDialog = ({ modalData, setModalData, setShowSidebar }: DialogModalProps) => {
  const { handleSubmit } = useFormikContext()

  function handleCloseDialog() {
    setModalData((prevData: ModalState) => ({ ...prevData, isOpen: false }))
  }

  return (
    <Dialog
      open={modalData.isOpen}
      onClose={handleCloseDialog}
      aria-labelledby={modalData.title}
      aria-describedby={modalData.message}
      maxWidth="xs">
      <Grid container direction="column" alignItems="center">
        <DialogContent>
          <Typography align="left" variant="h4" paragraph>
            {modalData.title}
          </Typography>
          <Typography align="left" paragraph>
            {modalData.message}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ width: "90%" }}>
          <Box display="flex" sx={{ width: "100%" }} justifyContent="space-between" mb={5}>
            <Button
              sx={{ padding: "0 40px" }}
              type="button"
              variant="outlined"
              onClick={() => {
                handleCloseDialog()
              }}>
              Nej
            </Button>
            <Button
              sx={{ padding: "0 40px" }}
              variant="contained"
              type="button"
              onClick={() => {
                if (modalData.actionType === ActionType.Save) {
                  handleSubmit()
                } else {
                  setShowSidebar(false)
                }
                handleCloseDialog()
              }}
            >
              Ja
            </Button>
          </Box>
        </DialogActions>
      </Grid>
    </Dialog>
  )
}