import * as Yup from "yup"

export const defaultSchema = Yup.object().shape({
  regNumber: Yup.string()
    .min(6, "Registreringsnummer måste vara minst 6 tecken långt")
    .max(10, "Registreringsnummer kan max vara 10 tecken långt"),
  internnumber: Yup.string().required("Internnummer är obligatoriskt"),
  companyId: Yup.number().nullable().required("Bolag är obligatoriskt"),
  vehicleType: Yup.string().nullable(),
  basicFuelConsumptionKm: Yup.number(),
  averageSpeed: Yup.number(),
  mainPropellantId: Yup.number().nullable(),
  tachographIntegrationActive: Yup.bool(),
  archived: Yup.bool(),
  meterSetting: Yup.number().required("Mätarställning är obligatoriskt")
})