import { IDevicePosition } from "./devices.interface";

const MarkerIcon = (marker: google.maps.Marker, rotation?: number, device?: IDevicePosition, activeMarker?: any) => {
    let markerColor = "";
    let markerStrokeColor = "";
    if (activeMarker?.label === device?.label) {
        markerColor = "#254467";
        markerStrokeColor = "#2e3547";
    } else {
        markerColor = device?.status === 3 ? "#F24E1E" : device?.status === 1 || device?.status === 2 ? "#89BE3F" : "#89BE3F";
        markerStrokeColor = device?.status === 3 ? "#a92700" : device?.status === 1 || device?.status === 2 ? "#2F6C12" : "#2F6C12";
    }

    const icon = marker.setIcon({
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: markerColor,
        fillOpacity: 1,
        scale: 7,
        strokeColor: markerStrokeColor,
        strokeWeight: 1,
        rotation: rotation
    });

    return icon;
};
export default MarkerIcon;
