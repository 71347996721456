import { faFlag } from "@fortawesome/pro-solid-svg-icons"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import {
  Autocomplete,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import { useApiFile } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import SuccessDialog from "components/Dialogs/SuccessDialog"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import MultipleFilePickerPreviewList from "components/MultipleFilePickerPreviewList"
import { RouterPrompt } from "components/RouterPrompt"
import { CircleIcon } from "components/icons/CircleIcon"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { Sidebar } from "pages/vehicleregistry/pages/vehicles/pages/customfields/components/Sidebar"
import { useContext, useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import ReadMoreReadLess from "./ReadMoreReadLess"
import { ReadMoreReadLessTitle } from "./ReadMoreReadLessTitle"
import { useStyles } from "./useStyles"

// validation
const currentValidationSchema = Yup.object().shape({
  vehicleId: Yup.string().required("Fordon är obligatoriskt,"),
  issueReportCategoryId: Yup.string().required("Kategori är obligatoriskt"),
  description: Yup.string()
    .required("Beskrivning är obligatoriskt")
    .max(300, "Beskrivning får max vara 300 tecken långt")
})

const ImportIssueReportForm = ({ showSidebarForm, setShowSidebarForm, updateExistingData }) => {
  // styles
  const classes = useStyles()

  // formik initialvalues
  const initialValues = {
    vehicleId: "",
    issueReportCategoryId: "",
    description: "",
    attachments: []
  }

  // datafetch states and handlers
  const [vehiclesResult, vehiclesApi] = useApi()
  const [categorysResult, categoryApi] = useApi()
  const [vehicleCategoriesResult, vehicleCategoriesApi] = useApi()
  const [addResult, addApi] = useApi()
  const [fileUploadResult, uploadFile] = useApiFile()
  const [files, setFiles] = useState([])
  const [filesPreview, setFilesPreview] = useState(initialValues.attachments)
  const [showDescription, setShowDescription] = useState(false)
  const [chosenCategory, setChosenCategory] = useState("")
  const [issueReports, setIssueReports] = useState([])
  const [showContinueModal, setShowContinueModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [chosenRegNumber, setChosenRegNumber] = useState("")
  const formRef = useRef()

  // GET vehicles for regNumber
  useEffect(() => {
    vehiclesApi.get(apiEndpoints.issuereports.getvehiclelist)
    return () => {
      vehiclesApi.leave()
    }
  }, [vehiclesApi])

  /* GET Categories */
  useEffect(() => {
    categoryApi.get(apiEndpoints.issuereports.getissuereportcategories)
    return () => {
      categoryApi.leave()
    }
  }, [categoryApi])

  /* File management */
  useEffect(() => {
    if (files) {
      files.map((file) => {
        return uploadFile(apiEndpoints.issuereports.uploadfile, file)
      })
    }
  }, [files, uploadFile])

  useEffect(() => {
    if (fileUploadResult.data && fileUploadResult.data.name) {
      setFilesPreview((p) => {
        return [...p, fileUploadResult.data]
      })
    }
  }, [fileUploadResult])

  const onLogoChanged = (value) => {
    setFilesPreview(value)
  }

  /* Show images in a list */
  useEffect(() => {
    if (addResult.status === 2) {
      categoryApi.get(apiEndpoints.issuereports.getissuereportcategories)
      if (updateExistingData !== null) {
        updateExistingData()
      }
    }
  }, [addResult])

  useEffect(() => {
    if (addResult.status === 2) {
      setShowContinueModal(false)
      setShowSuccessModal(true)
      setShowSidebarForm(false)
    }
  }, [addResult])

  // useEffect(() => {
  //   if (addResult.status === 2) {
  //     issueReportsApi.get(apiEndpoints.issuereports.getissuereportsarchive)
  //     issueReportsGroupedApi.get(apiEndpoints.issuereports.getissuereportsgroupedbyvehiclesandbycustomerid)
  //   }
  // }, [addResult, issueReportsApi, issueReportsGroupedApi])

  // loading
  const loadingContext = useContext(GlobalLoaderContext)
  useEffect(() => {
    loadingContext.setLoading(() => vehiclesResult.status === 1)
    loadingContext.setLoading(() => categorysResult.status === 1)
    loadingContext.setLoading(() => addResult.status === 1)
    loadingContext.setLoading(() => fileUploadResult.status === 1)
  })

  // handle submit formik form & show continue modal
  const handleSubmit = () => {
    setShowContinueModal(true)
  }

  // post data & set states for modals/sidebar
  const onAddSubmit = (values) => {
    addApi.post(apiEndpoints.issuereports.createissuereport, values)
  }

  // clear form when sidebar is closed
  useEffect(() => {
    if (!showSidebarForm) {
      formRef.current.resetForm()
      setShowDescription(false)
      setChosenRegNumber("")
      setChosenCategory("")
    }
  }, [showSidebarForm])

  // show description state
  const handleShowDescription = () => {
    formRef.current.setFieldTouched("vehicleId", true)
    formRef.current.setFieldTouched("issueReportCategoryId", true)

    if (formRef.current.values.vehicleId !== "" && formRef.current.values.issueReportCategoryId !== "") {
      setShowDescription(true)
    }
  }

  // Go back button
  const handleShowIssueReport = () => {
    setShowDescription(false)
  }

  // Show descriptions depending on their category
  useEffect(() => {
    if (chosenRegNumber && chosenCategory) {
      vehicleCategoriesApi.get(
        apiEndpoints.issuereports.getissuereportsbyvehicleidandcategoryid
          .replace("{vehicleid}", chosenRegNumber)
          .replace("{issuereportcategoryid}", chosenCategory)
      )
    }
  }, [chosenCategory, chosenRegNumber, vehicleCategoriesApi])

  useEffect(() => {
    console.log(vehicleCategoriesResult)
    if (vehicleCategoriesResult.status === 2) {
      setIssueReports(vehicleCategoriesResult.data)
    } else {
      setIssueReports([])
    }
  }, [vehicleCategoriesResult])

  const buttonFeedbackResult = addResult

  return (
    <Sidebar showSidebar={showSidebarForm}>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formRef}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setFieldValue
        }) => (
          <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
            <RouterPrompt />
            <List className={classes.vehicleList}>
              <CloseSidebarButton
                toggleMenu={() => {
                  setShowSidebarForm(!showSidebarForm)
                }}
              />
              <Box pt={2} pb={3} className={classes.fullWidth}>
                <CircleIcon icon={faFlag} color="primary" size="lg" sx={{ marginRight: "15px" }} />

                <Box row="column" justifyContent="center">
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="h2">
                        Skapa felrapport
                      </Typography>
                    }
                    secondary={
                      <Typography color="textSecondary" variant="bodySmall">
                        Fordon
                      </Typography>
                    }
                  />
                </Box>
              </Box>
              <Box className={classes.greyBorder}>
                <ListItem className={classes.listItem}>
                  <Box mt={5}>
                    <ListItemText
                      classes={{
                        secondary: classes.listItemText,
                        primary: classes.listItemTextPrimary
                      }}
                      style={{ paddingBottom: "8px" }}
                      primary="Välj fordon"
                    />
                  </Box>

                  {vehiclesResult.status === 2 && (
                    <Autocomplete
                      className={classes.fullWidth}
                      id="vehicleId"
                      options={vehiclesResult.data}
                      getOptionLabel={(option) => (option.internnumber ? option.internnumber : "")}
                      value={vehiclesResult.data.find((i) => i.id === values.vehicleId) || ""}
                      getOptionSelected={(option, value) => {
                        return option.id === value.id
                      }}
                      onChange={(e, value) => {
                        setFieldValue("vehicleId", value?.id)
                        setChosenRegNumber(value?.id)
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Stack direction="row" spacing={2} justifyContent="space-between">
                            <Box sx={{ fontWeight: 600, minWidth: "100px" }}>{option.internnumber}</Box>
                            <Box sx={{ opacity: 0.6 }}>{option.regNumber}</Box>
                          </Stack>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          name="vehicleId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.vehicleId && errors.vehicleId ? true : false}
                          helperText={touched.vehicleId && errors.vehicleId ? errors.vehicleId : null}
                        />
                      )}
                    />
                  )}
                </ListItem>
              </Box>

              <ListItem className={classes.listItem}>
                <ListItemText
                  classes={{
                    secondary: classes.listItemText,
                    primary: classes.listItemTextPrimary
                  }}
                  style={{ paddingBottom: "8px" }}
                  primary="Mätarställning"
                />
                <TextField
                  type="number"
                  rows={1}
                  autoComplete="off"
                  className={classes.textField}
                  fullWidth
                  id="meterSetting"
                  value={values.meterSetting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  InputProps={{
                    classes: { input: classes.formAreaInput },
                    disableUnderline: true,
                    inputProps: { min: 0 }
                  }}
                />
              </ListItem>

              <ListItem className={classes.listItem}>
                <ListItemText
                  classes={{
                    secondary: classes.listItemText,
                    primary: classes.listItemTextPrimary
                  }}
                  style={{ paddingBottom: "8px" }}
                  primary="Typ av fel"
                />

                {categorysResult.status === 2 && (
                  <Autocomplete
                    className={classes.fullWidth}
                    id="issueReportCategoryId"
                    options={categorysResult.data}
                    getOptionLabel={(option) => (option.name ? option.name : "")}
                    value={categorysResult.data.find((i) => i.id === values.issueReportCategoryId) || ""}
                    getOptionSelected={(option, value) => {
                      return option.id === value.id
                    }}
                    onChange={(e, value) => {
                      setFieldValue("issueReportCategoryId", value?.id)
                      setChosenCategory(value?.id)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        name="issueReportCategoryId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.issueReportCategoryId && errors.issueReportCategoryId ? true : false}
                        helperText={
                          touched.issueReportCategoryId && errors.issueReportCategoryId
                            ? errors.issueReportCategoryId
                            : null
                        }
                      />
                    )}
                  />
                )}
              </ListItem>
              {showDescription === true ? (
                <>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      classes={{
                        secondary: classes.listItemText,
                        primary: classes.listItemTextPrimary
                      }}
                      style={{ paddingBottom: "8px" }}
                      primary="Beskrivning"
                    />
                    <TextField
                      rows={4}
                      multiline
                      autoComplete="off"
                      className={classes.textField}
                      fullWidth
                      id="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.description && errors.description ? true : false}
                      helperText={touched.description && errors.description ? errors.description : null}
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.formAreaInput },
                        disableUnderline: true
                      }}
                    />
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <ListItemText
                      classes={{
                        secondary: classes.listItemText,
                        primary: classes.listItemTextPrimary
                      }}
                      style={{ paddingBottom: "8px" }}
                      primary="Notering"
                    />
                    <TextField
                      rows={3}
                      multiline
                      autoComplete="off"
                      className={classes.textField}
                      fullWidth
                      id="notice"
                      value={values.notice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.notice && errors.notice ? true : false}
                      helperText={touched.notice && errors.notice ? errors.notice : null}
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.formAreaInput },
                        disableUnderline: true
                      }}
                    />
                  </ListItem>

                  <Box className={classes.greyBorder}>
                    <MultipleFilePickerPreviewList
                      setFieldValue={setFieldValue}
                      fieldName="attachments"
                      setFilesPreview={setFilesPreview}
                      defaultPreviewFiles={filesPreview}
                      setFiles={setFiles}
                      files={files}
                      fileUploadResult={fileUploadResult}
                      onLogoChanged={onLogoChanged}
                      labelInBox={"Bifoga bilder från din enhet"}
                    />
                  </Box>
                </>
              ) : (
                <Box>
                  {chosenRegNumber && chosenCategory && issueReports.length > 0 ? (
                    <>
                      {" "}
                      <ReadMoreReadLessTitle />
                      {issueReports.map((data) => (
                        <Box>
                          <ReadMoreReadLess date={data.createdDate} description={data.description} />
                        </Box>
                      ))}
                    </>
                  ) : (
                    chosenRegNumber &&
                    chosenCategory && (
                      <Typography mt={2} mb={2} fontSize="13px" color="success.main">
                        Det finns inga befintliga felrapporter på fordonet
                      </Typography>
                    )
                  )}
                </Box>
              )}

              <Box pt={3} pb={5} display="flex" justifyContent="center">
                {showDescription === false ? (
                  <Button color="primary" variant="contained" onClick={handleShowDescription}>
                    Nästa steg
                  </Button>
                ) : (
                  <Box display="flex" justifyContent="center" direction="row">
                    <Box pr={2}>
                      <Button color="primary" variant="contained" onClick={handleShowIssueReport}>
                        Tillbaka
                      </Button>
                    </Box>
                    <Box>
                      <Button color="primary" endIcon={<AddCircleIcon />} variant="contained" type="submit">
                        Skicka felrapport
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </List>

            {showContinueModal && (
              <ContinueDialog
                result={addResult}
                setShowContinueModal={setShowContinueModal}
                onAddSubmit={onAddSubmit}
                values={values}
                message={`Du kommer skapa och skicka in en felrapport, vill du fortsätta?`}
                continueBtnText="Skicka felrapport"
                cancelBtnText="Avbryt"
              />
            )}

            {showSuccessModal && (
              <SuccessDialog
                resetModalState={setShowSuccessModal}
                title="Din felrapport är sparad och ett ärende kommer upprättas. Du får en notis om ärendets status när det uppdateras."
              />
            )}
          </form>
        )}
      </Formik>
    </Sidebar>
  )
}
export default ImportIssueReportForm
