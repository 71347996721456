export const statusString = (value: boolean) => {
  switch (value) {
    case true:
      return "Aktiv"
    default:
      return "-"
  }
}

export const statusStringForPosition = (value: string): boolean => {
  switch (value) {
    case "Online":
      return true
    default:
      return false
  }
}

export const checkNumber = (value: any) => {
  switch (value) {
    case value:
      return true
    case value >= 0:
      return true
    default:
      return false
  }
}

export const totalFuelNumber = (value: any) => {
  switch (value) {
    case value:
      return value
    case value >= 0:
      return value
    default:
      return "-"
  }
}

export const tripFuelNumber = (value: any) => {
  switch (value) {
    case value:
      return value
    case value >= 0:
      return value
    default:
      return "-"
  }
}
