import { Box, Grid, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import Button from "components/Button"
import FormUploadFile from "components/Form/FormComponents/FormUploadFile/FormUploadFile"
import PaperBox from "components/PaperBox"
import { apiEndpoints } from "generated/apiEndpoints"
import { excelDateToJSDate } from "helpers/Excel/ExcelDateToJSDate"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import XLSX from "xlsx"
import { IAdminSettingProps, IIdhaFile, IIdhaRequest } from "../violation.interface"

const AdminUploadIdhaFile: React.FC<IAdminSettingProps> = (props) => {
  const { companyId } = props

  const [fileUploadResult, uploadFile] = useApi() as [IApiResult, IApiFunc]
  const [fileNames, setFileNames] = useState<string[]>()
  const [idhaList, setIdhaList] = useState<(IIdhaFile | undefined)[]>()
  const { t } = useTranslation("translation", { keyPrefix: "violation.admin" })

  const handleFileUpload = async (files: File[]) => {
    files.map((file: File) => {
      if (
        file &&
        (file.type === "application/vnd.ms-excel" ||
          file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      ) {
        const reader = new FileReader()

        reader.onload = async (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer)
          const workbook = XLSX.read(data, { type: "array" })
          const sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]
          const json: (string | undefined)[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
          const elementsAfterFirstEight = json.slice(8)

          setFileNames((prevFileNames) => [file.name, ...(prevFileNames ?? [])])

          const IdhaListItems: (IIdhaFile | undefined)[] = elementsAfterFirstEight.map((row) => {
            if (row.length > 0) {
              const idhaFileObj: IIdhaFile = {
                RegulationId: row[0] || "",
                ViolationNr: row[1] || "",
                ViolationDegree: row[2] || "",
                ViolationDescription: row[5] || "",
                Notes: row[7] || "",
                DateTime: excelDateToJSDate(row[8] || "").toISOString(),
                TimeSize: row[9] || "",
                Surname: row[10] || "",
                Firstname: row[11] || "",
                Id: row[12] || "",
                DriverCardNr: row[13] || "",
                Location: row[14] || ""
              }
              return idhaFileObj
            }
          })

          setIdhaList((previIdhaFiles) => [...(previIdhaFiles || []), ...IdhaListItems])
        }
        reader.readAsArrayBuffer(file)
      } else {
        alert("Please upload a valid Excel file.")
      }
    })
  }

  const handleSubmit = async () => {
    const request: IIdhaRequest = {
      IdhaList: idhaList || [],
      CompanyId: companyId ?? 0
    }

    await uploadFile.post(apiEndpoints.violationadmin.uploadidhafile, request)
  }

  return (
    <PaperBox>
      <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={12} mb={3}>
          <Grid item xs={12} marginBottom={2}>
            <FormUploadFile handleChange={(files: File[]) => handleFileUpload(files)} />
          </Grid>
          {fileNames?.map((fileName, index) => {
            return (
              <Grid item xs={12} marginBottom={2} key={index}>
                <Typography variant="labelLarge" fontWeight={500}>
                  {fileName}
                </Typography>
              </Grid>
            )
          })}

          <Grid item xs={12}>
            <Box pb={2} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                result={fileUploadResult}
                onClick={handleSubmit}>
                Ladda upp
              </Button>
            </Box>
          </Grid>

          {fileUploadResult.status === 2 && (
            <Grid item xs={3}>
              <Typography variant="labelLarge" fontWeight={500} color="success">
                {t(`upload_file_success`, ``)}
              </Typography>
            </Grid>
          )}
          {fileUploadResult.status === 3 && (
            <Grid>
              <Typography variant="labelLarge" fontWeight={500} color="error">
                {t(`upload_file_failed`, ``)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </PaperBox>
  )
}

export default AdminUploadIdhaFile
