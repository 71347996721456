import { Box, Container } from "@mui/material"
import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import WorkOrderForm from "../../components/WorkOrderForm"

const WorkOrderCreate = (props) => {
  const loadingContext = React.useContext(GlobalLoaderContext)
  const [vehiclesResult, vehiclesApi] = useApi()
  const [addResult, addApi] = useApi()
  const [showContinueModal, setShowContinueModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const routerHistory = useHistory()
  const [vehicleId, setVehicle] = useState()
  const [vehicleIdFromDash, setVehicleIdFromDash] = useState(props.location.state)
  const [issueReportsResult, issueReportApi] = useApi()

  // get vehicles that has a issueReport
  useEffect(() => {
    vehiclesApi.get(apiEndpoints.issuereportsadmin.getvehicleswithworkissues)
    return () => {
      vehiclesApi.leave()
    }
  }, [vehiclesApi])

  useEffect(() => {
    loadingContext.setLoading(() => addResult.status === 1)
  })

  useEffect(() => {
    loadingContext.setLoading(() => vehiclesResult.status === 1)
  })

  // take vehicleId and render issueReports
  useEffect(() => {
    if (vehicleId) {
      issueReportApi.get(
        apiEndpoints.issuereportsadmin.getissuereportsforworkorderbyvehicleid.replace(
          "{vehicleId}",
          vehicleId
        )
      )
    }
  }, [vehicleId, issueReportApi])

  // post data & set states for modals
  const onAddSubmit = (values) => {
    addApi.post(apiEndpoints.issuereportsadmin.createworkorder, values)
  }

  // post data & set states for modals
  const sendBooking = (values) => {
    addApi.post(apiEndpoints.issuereportsadmin.sendworkorder, values)
  }

  // handle modals on add
  const handleModals = () => {
    setShowContinueModal(false)
    setShowSuccessModal(true)
  }

  // update grid on add api call success finish
  useEffect(() => {
    if (addResult.status === 2) {
      routerHistory.push(allRoutes.IssuereportsWorkorders())
    }
  }, [addResult.status, routerHistory, vehiclesApi])

  const initialValues = {
    vehicleId: vehicleId,
    message: "",
    status: 1,
    id: 0,
    issueReports: issueReportsResult.status === 2 ? issueReportsResult.data : []
  }

  return (
    <div>
      <Container>
        <Box marginY={3} mt={0}>
          <WorkOrderForm
            vehicleIdFromDash={vehicleIdFromDash}
            handleModals={handleModals}
            addResult={addResult}
            vehiclesApi={vehiclesApi}
            setVehicle={setVehicle}
            vehicleId={vehicleId}
            initialValues={initialValues}
            onAddSubmit={onAddSubmit}
            setShowContinueModal={setShowContinueModal}
            showContinueModal={showContinueModal}
            showSuccessModal={showSuccessModal}
            setShowSuccessModal={setShowSuccessModal}
            vehiclesResult={vehiclesResult.status === 2 && vehiclesResult.data}
            sendBooking={sendBooking}
          />
        </Box>
      </Container>
    </div>
  )
}

export default WorkOrderCreate
