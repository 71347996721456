import { Box, Chip, Container, Grid, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { DatePickerColumnFilter, SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import moment from "moment"
import React, { useContext, useEffect, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"

const NewsAdmin = () => {
  const globalClasses = globalUseStyles()
  const routerHistory = useHistory()

  const [getNewsResult, getNews] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    getNews(apiEndpoints.newsadmin.listarticles)
  }, [getNews])

  useEffect(() => {
    loadingContext.setLoading(() => getNewsResult.status === 1)
  })

  const columns = useMemo(
    () => [
      {
        accessor: "subject",
        numeric: false,
        Header: "Rubrik"
      },
      {
        accessor: (row) => {
          return row.publishedDate ? moment(row.publishedDate).format("YYYY-MM-DD hh:mm") : ""
        },
        numeric: false,
        Filter: DatePickerColumnFilter,
        Header: "Skapad"
      },
      {
        accessor: "publishedByUser.name",
        numeric: false,
        Header: "Skapad av"
      },
      {
        accessor: (row) => {
          return row.priorityEndDate ? moment(row.priorityEndDate).format("YYYY-MM-DD hh:mm") : ""
        },
        numeric: false,
        Header: "Prioriteringsdatum"
      },
      {
        accessor: (row) => {
          return row.priorityEndDate && moment().isSameOrBefore(row.priorityEndDate) ? "Ja" : "Nej"
        },
        numeric: false,
        Header: "Prioritering",
        Filter: SelectColumnFilter,
        FilterOptions: [
          {
            name: "Viktigt",
            value: "Ja"
          },
          {
            name: "Ej viktigt",
            value: "Nej"
          },
        ],
        mainFilter: true,
        align: "center",
        Cell: ({ cell }) => {
          const isPriority = cell.row.original.priorityEndDate && moment().isSameOrBefore(cell.row.original.priorityEndDate)
          return isPriority ? (
            <Chip color="primary" label="Viktigt" />
          ) : <></>
        },
      }
    ],
    []
  )

  return (
    <div>
      <Container>
        <Box mt={2} mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">Administrera nyheter</Typography>

            <Button component={Link} color="primary" to={allRoutes.NewsAdminAdd()}>
              Ny nyhetsartikel
            </Button>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          rows={getNewsResult.status === 2 && getNewsResult.data}
          status={getNewsResult.status}
          columns={columns}
          rowProps={(row) => ({
            onClick: () => routerHistory.push(allRoutes.NewsAdminEdit(row.original.id))
          })}
        />
      </Box>
    </div>
  )
}

export default NewsAdmin
