import { Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useContext, useEffect } from "react"
import { EventTypesList } from "./components/EventTypesList"
import { EventsList } from "./components/EventsList"

const Events = () => {
  const [eventResult, eventFuncs] = useApi()
  const [eventTypesResult, eventTypeFuncs] = useApi()
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    eventFuncs.get(apiEndpoints.systemadminsystem.gettoplatestevents)
    eventTypeFuncs.get(apiEndpoints.systemadminsystem.geteventtypeswithstatusanddeliverymethod)
    return () => {
      eventFuncs.leave()
      eventTypeFuncs.leave()
    }
  }, [eventFuncs, eventTypeFuncs])

  useEffect(() => {
    loadingContext.setLoading(() => eventResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => eventTypesResult.status === 1)
  })

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <Typography variant="h4">Systemhändelser</Typography>
          {eventResult.status === 1 && <SkeletonCard />}
          {eventResult.status === 2 && <EventsList items={eventResult.data} />}
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography variant="h4">Händelsetyper</Typography>
          {eventResult.status === 1 && <SkeletonCard />}
          {eventTypesResult.status === 2 && <EventTypesList items={eventTypesResult.data} />}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Events
