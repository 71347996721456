import { customerHasFeature } from "auth/customerHasFeature"
import { AppContext } from "components/AppContextProvider"
import ButtonLink from "components/ButtonLink"
import { allRoutes } from "generated/routes"
import React, { useContext } from "react"

const CrisisButton: React.FC = () => {
  const appContext = useContext(AppContext)

  return (
    <>
      {customerHasFeature(appContext.appUser.customer, "CrisisManagement") && (
        <ButtonLink
          color="warning"
          variant="outlined"
          sx={{ marginRight: 2 }}
          route={allRoutes.CrisismanagementCrisisinfo()}>
          Vid kris klicka här
        </ButtonLink>
      )}
    </>
  )
}

export default CrisisButton
