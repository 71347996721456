import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useStyles } from "../../../components/useStyles";

const WorkOrderCardReadMessage = ({ message, title, bgColor }) => {
    const classes = useStyles();

    return (
        <Box>
            <Typography variant="h6" p={4} pb={2}>
                {title}
            </Typography>
            <Box backgroundColor={bgColor} className={classes.greyBox}>
                <Typography p={2}>{message}</Typography>
            </Box>
        </Box>)
}

export default WorkOrderCardReadMessage