import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Container, Grid, IconButton, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import React from "react"
import { NavLink } from "react-router-dom"

const ListTemplates = () => {
  const globalClasses = globalUseStyles()
  const loadingContext = React.useContext(GlobalLoaderContext)

  const [templatesResult, templatesFuncs] = useApi()

  React.useEffect(() => {
    templatesFuncs.get(apiEndpoints.vehicleregistryreporting.getreporttemplates)
    return () => {
      templatesFuncs.leave()
    }
  }, [templatesFuncs])

  React.useEffect(() => {
    loadingContext.setLoading(() => templatesResult.status === 1)
  })

  const columns = [
    {
      accessor: "name",
      Header: "Namn"
    },
    {
      accessor: "description",
      Header: "Beskrivning"
    },
    {
      accessor: "edit",
      numeric: false,
      disablePadding: true,
      Header: "",
      align: "right",
      Cell: ({ cell }) => {
        return (
          cell.row.original.isEditable && (
            <IconButton
              component={NavLink}
              to={allRoutes.VehicleregistryReportsReporttemplatesEdit(cell.row.original.id)}
              size="large">
              <FontAwesomeIcon icon={faEdit} size="xs" />
            </IconButton>
          )
        )
      }
    }
  ]
  return (
    <>
      <Container>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h1">Rapportmallar</Typography>

          <Button
            component={NavLink}
            to={allRoutes.VehicleregistryReportsReporttemplatesAdd()}
            color="primary"
            variant="contained">
            Lägg till mall
          </Button>
        </Grid>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rows={templatesResult.status === 2 && templatesResult.data}
          status={templatesResult.status}
        />
      </Box>
    </>
  )
}
export default ListTemplates
