import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faFolder, faLayerGroup } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { AppContext } from "components/AppContextProvider"
import ClpTable from "components/ClpTable"
import NoResultFound from "components/Dialogs/NoResultFound"
import PaperBox from "components/PaperBox"
import UserAvatar from "components/UserAvatar"
import { Sidebar } from "components/navigation/Sidebar"
import globalUseStyles from "css/theme/globalUseStyles"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import { useHistory } from "react-router-dom"
import LoadingPage from "../../components/Loading/LoadingPage"
import FolderForm from "./components/Form/FolderForm"
import { IDocumentFormValues } from "./interface/folder.interface"

const Documents = () => {
  const history = useHistory()
  const globalClasses = globalUseStyles()
  const { t } = useTranslation("translation", { keyPrefix: "documents" })
  const appContext = useContext(AppContext)
  const [foldersResult, listFoldersApi] = useApi() as [IApiResult, IApiFunc]

  const [addFolderResult, addFolder] = useApi() as [IApiResult, IApiFunc]

  const handleSubmit = (values: IDocumentFormValues) => {
    addFolder.post(apiEndpoints.documents.adddocument, values)
  }

  useEffect(() => {
    if (addFolderResult.status === 2) {
      listFoldersApi.get(apiEndpoints.documents.listfolders)
      appContext.toggleSideBar()
    }
  }, [addFolderResult])

  useEffect(() => {
    listFoldersApi.get(apiEndpoints.documents.listfolders)

    return () => {
      listFoldersApi.leave()
    }
  }, [listFoldersApi])

  const columns = [
    {
      accessor: "name",
      Header: "Mapp",
      Cell: ({ row }: any) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <FontAwesomeIcon color={theme.palette.warning["main"]} icon={faFolder as IconProp} />
            <Typography display="inline-block" variant="bodyMedium" fontWeight="600">
              {row.original.name}
            </Typography>
          </Stack>
        )
      }
    },
    {
      accessor: "totalAttachments",
      Header: "Filer",
      Cell: ({ row }: any) => {
        return (
          <>
            {row.original.totalAttachments} {t(`list.files`)}
          </>
        )
      }
    },
    {
      accessor: "createdByUser.name",
      Header: "Skapad av",
      Cell: ({ row }: any) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <UserAvatar label={row.original?.createdByUser?.name} size="sm" style={{ marginRight: 0 }} />
            <Typography variant="bodySmall">{row.original?.createdByUser?.name}</Typography>
          </Stack>
        )
      }
    },
    {
      accessor: "last_updated",
      Header: t(`last_updated`),
      Cell: ({ row }: any) => {
        return (
          row.original.updatedDate && (
            <Moment utc local format="YYYY-MM-DD">
              {row.original.updatedDate}
            </Moment>
          )
        )
      }
    }
  ]

  const initialValues: IDocumentFormValues = {
    id: 0,
    name: "",
    description: "",
    body: "",
    isVisibleStartPage: false,
    companyIds: [],
    departmentIds: [],
    attachments: [],
    handleSubmit: () => null,
    totalAttachments: 0
  }
  return foldersResult.status === 1 ? (
    <Container>
      <LoadingPage />
    </Container>
  ) : (
    <div>
      <Container>
        {hasRole(appContext.appUser, allRoles.DocumentsAdmin) && (
          <Sidebar>
            <FolderForm {...initialValues} handleSubmit={handleSubmit} results={addFolderResult} />
          </Sidebar>
        )}

        <Box marginY={3} mb={2}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box order={2} p={1} flexGrow={2}>
              <Grid item>
                <Typography variant="h1"> {t(`title`)}</Typography>
              </Grid>
            </Box>
            <Box order={3} p={1}>
              {hasRole(appContext.appUser, allRoles.DocumentsAdmin) && (
                <Button onClick={() => appContext.toggleSideBar()} variant="contained" color="primary">
                  {t(`add_button`)}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        {foldersResult.status === 2 && foldersResult.data.length === 0 && (
          <PaperBox>
            <NoResultFound
              icon={faLayerGroup as IconProp}
              message={
                hasRole(appContext.appUser, allRoles.DocumentsAdmin)
                  ? t(`no_folder_created_admin`)
                  : t(`no_folder_created`)
              }
              color="primary"
              size="lg"
            />
          </PaperBox>
        )}
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          cellProps={(row: any) => ({
            onClick: () => history.push(allRoutes.DocumentsDetails(row.original.id))
          })}
          rows={foldersResult.status === 2 && foldersResult.data}
          status={foldersResult.status}
          columns={columns}
        />
      </Box>
    </div>
  )
}
export default Documents
