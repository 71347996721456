import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Button from "components/Button"
import { Formik } from "formik"
import { nanoid } from "nanoid"
import { departmentValidationSchema } from "pages/systemadmin/pages/customers/components/validationSchema"

const DepartmentForm = ({ onSubmit }) => {
  const initialValues = {
    name: "",
    description: ""
  }

  const handleSubmit = async (values, { resetForm }) => {
    const departmentToSave = {
      ...values,
      listId: nanoid()
    }
    resetForm()
    onSubmit(departmentToSave)
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={departmentValidationSchema}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                id="name"
                label="Namn"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && errors.name ? true : false}
                helperText={touched.name && errors.name ? errors.name : null}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                id="description"
                label="Beskrivning"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.description && errors.description ? true : false}
                helperText={touched.description && errors.description ? errors.description : null}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Box pb={2} display="flex" justifyContent="flex-end">
                <Button color="primary" type="button" variant="outlined" size="small" onClick={handleSubmit}>
                  Lägg till
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}
export default DepartmentForm
