import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  // Chips
  redChip: {
    backgroundColor: theme.palette.error["light"],
    color: theme.palette.error["main"],
    borderRadius: "100vh"
  },
  greenChip: {
    backgroundColor: theme.palette.success["50"],
    color: theme.palette.success["500"],
    borderRadius: "100vh"
  },
  yellowChip: {
    backgroundColor: theme.palette.warning["light"],
    color: theme.palette.warning["main"],
    borderRadius: "100vh"
  },
  blueChip: {
    backgroundColor: theme.palette.primary["50"],
    color: theme.palette.primary["600"],
    borderRadius: "100vh"
  },

  // Delete icon
  deleteIcon: {
    color: "#BD2121 !important",
    fontSize: "20px !important"
  },
  deleteButton: {
    padding: "2px 4px 2px 4px",
    boxShadow: "none"
  },

  // Tableheader
  expandableHeader: {
    border: "none",
    display: "flex"
  },
  expandableCell: {
    border: "none"
  },
  expandableBtn: {
    display: "flex",
    color: theme.palette.primary["main"],
    backgroundColor: "none",
    paddingTop: "2px",
    paddingBottom: "2px",
    "&:hover": {
      background: "none",
      textDecoration: "1px underline"
    }
  },
  expandableHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}))
