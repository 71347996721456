import { Box, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { ILastUpdatedDate } from "../Interface/headerData.interface"
import { useStyles } from "./useStyles"

const LastUpdatedDate = (props: ILastUpdatedDate) => {
  const { date } = props
  const classes = useStyles()

  return (
    <div>
      <Box className={classes.date} mt={5} mb={2}>
        <Typography variant="bodySmall" color="textSecondary">
          Transportstyrelsedata hämtad: {moment(date).format("YYYY-MM-DD")}
        </Typography>
      </Box>
    </div>
  )
}
export default LastUpdatedDate
