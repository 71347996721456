import { BottomNavigation, Box, Container } from "@mui/material"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useApiFile, useApiPut } from "auth/useApi2"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import FilePicker from "components/FilePicker"
import PaperBox from "components/PaperBox"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { useContext, useEffect, useState } from "react"
import { customerValidationSchema } from "./customerValidationSchema"

export const EditCustomerForm = ({ initialValues, logoResult }) => {
  const [saveCustomerResult, saveCustomer] = useApiPut()
  const [fileUploadResult, uploadFile] = useApiFile()
  const [file, setFile] = useState(null)
  const appContext = useContext(AppContext)
  const handleSubmit = async (values) => {
    const mergeItem = {
      ...initialValues,
      ...values
    }

    saveCustomer(apiEndpoints.customeradmin.updatecustomer, mergeItem)
  }

  const [logoPreview, setLogoPreview] = useState()
  useEffect(() => {
    if (logoResult.status === 2) {
      onLogoChanged(logoResult.data)
    }
  }, [logoResult])

  useEffect(() => {
    const onAddImage = async () => {
      await uploadFile(apiEndpoints.customeradmin.uploadcustomerlogo, file)
    }

    if (file) {
      onAddImage()
    }
  }, [file, uploadFile])

  const onLogoChanged = (value) => {
    setLogoPreview(value)
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={customerValidationSchema}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Container>
            <Box marginY={3}>
              <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h2">Kunduppgifter</Typography>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <PaperBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="name"
                        label="Namn"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && errors.name ? true : false}
                        helperText={touched.name && errors.name ? errors.name : null}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="organisationNumber"
                        label="Organisationsnummer"
                        value={values.organisationNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.organisationNumber && errors.organisationNumber ? true : false}
                        helperText={
                          touched.organisationNumber && errors.organisationNumber
                            ? errors.organisationNumber
                            : null
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="streetAddress"
                        label="Gatuaddress"
                        value={values.streetAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.streetAddress && errors.streetAddress ? true : false}
                        helperText={
                          touched.streetAddress && errors.streetAddress ? errors.streetAddress : null
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="postalCode"
                        label="Postnummer"
                        value={values.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.postalCode && errors.postalCode ? true : false}
                        helperText={touched.postalCode && errors.postalCode ? errors.postalCode : null}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="cityAddress"
                        label="Ort"
                        value={values.cityAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.cityAddress && errors.cityAddress ? true : false}
                        helperText={touched.cityAddress && errors.cityAddress ? errors.cityAddress : null}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FilePicker
                        setFieldValue={setFieldValue}
                        fieldName="logoName"
                        defaultPreviewImage={
                          logoPreview && `data:${logoPreview.contenType};base64,${logoPreview.base64Contents}`
                        }
                        setFile={setFile}
                        fileUploadResult={fileUploadResult}
                        onLogoChanged={onLogoChanged}
                        label="Logotyp"
                      />
                    </Grid>
                  </Grid>
                </PaperBox>
              </Grid>
            </Grid>
          </Container>
          <Box marginTop={15}></Box>
          <Box sx={{ position: "fixed", bottom: 8, left: 0, right: 15 }} elevation={3}>
            <BottomNavigation
              sx={{ justifyContent: "flex-end", backgroundColor: "transparent", height: "auto" }}>
              <Button color="primary" type="submit" result={saveCustomerResult}>
                Spara
              </Button>
            </BottomNavigation>
          </Box>
        </form>
      )}
    </Formik>
  )
}
