import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from "@mui/material";
import React from "react";
import { useStyles } from "./useStyles";

export const ArchiveTableDeleteIcon = ({ handleClick, data }) => {

    const classes = useStyles();

    return (
        <IconButton
            onClick={() => handleClick(data.row.original.id)}
            className={classes.deleteButton}
        >
            <FontAwesomeIcon
                className={classes.deleteIcon}
                icon={faTrashCan}
                color="red"
                size="xs"
            />
        </IconButton>
    );
};
