import { faCalendar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Box, Divider, FormControlLabel, Grid, Switch, TextField } from "@mui/material"
import Typography from "@mui/material/Typography"
import classnames from "classnames"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { useEffect, useState } from "react"
import * as Yup from "yup"

const BplIntegrationSwitch = (props: any) => {
  const { values, setFieldValue, handleChange, handleBlur, errors, touched, setValidationStep } = props
  const globalClasses = globalUseStyles()

  useEffect(() => {
    const violationObj = {
      bplIntegrationActive: Yup.boolean(),
      bplUrl: Yup.string().when("bplIntegrationActive", {
        is: true,
        then: (schema) => schema.required("Url till bpl är obligatorisk"),
        otherwise: (schema) => schema.nullable()
      }),
    }
    setValidationStep(Yup.object().shape(violationObj))
  }, [values])

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={2}>
          <Box>
            <Avatar
              aria-label="BPL Integration"
              className={classnames(globalClasses.avatar, globalClasses.backgroundPrimary)}>
              <FontAwesomeIcon icon={faCalendar} size="lg" />
            </Avatar>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">BPL Integration</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            label=""
            className={globalClasses.margin0}
            control={
              <Switch
                checked={values.bplIntegrationActive}
                onChange={(event) => {
                  if (values.bplIntegrationActive) {
                    setFieldValue("bplUrl", null)
                  }
                  setFieldValue("bplIntegrationActive", event.target.checked)
                }}
              />
            }
          />
        </Grid>
      </Grid>
      {values.bplIntegrationActive && (
        <Box marginTop={2}>
          <Divider />
          <Box marginY={2}>
            <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Url till BPL</Typography>
              </Grid>
              <Grid item xs={12} lg={9} justifyContent="flex-end">
                <TextField
                  style={{ marginTop: 5 }}
                  autoComplete="on"
                  fullWidth
                  type="text"
                  id="bplUrl"
                  name="bplUrl"
                  placeholder="https://kund-bpl..."
                  value={values.bplUrl}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  error={touched.bplUrl && errors.bplUrl ? true : false}
                  helperText={touched.bplUrl && errors.bplUrl ? errors.bplUrl : null}
                  size="small"
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box marginY={2}>
            <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs={8} md={8}>
                <Typography variant="h5">Antal dagar</Typography>
                <Typography variant="bodyCompactSmall">
                  Antalet dagar visar hur långt fram förare ska se sina uppdrag
                </Typography>
              </Grid>
              <Grid item xs={4} md={4} justifyContent="flex-end">
                <TextField
                  autoComplete="off"
                  fullWidth
                  type="number"
                  id="bplDaysShown"
                  placeholder="1-200"
                  defaultValue={values.bplDaysShown ? values.bplDaysShown : 3}
                  value={values.bplDaysShown}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.bplDaysShown && errors.bplDaysShown ? true : false}
                  helperText={touched.bplDaysShown && errors.bplDaysShown ? errors.bplDaysShown : null}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  )
}

export default BplIntegrationSwitch
