const { makeStyles } = require("@mui/styles")

export const useStyles = makeStyles((theme) => ({
  // ContactInfoCard
  contact: {
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    borderRadius: "10px"
  }
}))
