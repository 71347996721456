import { faDownload, faExternalLinkSquare } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, IconButton, Typography } from "@mui/material"
import { pdf } from "@react-pdf/renderer"
import ClpTable from "components/ClpTable"
import { DatePickerColumnFilter, SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { allRoutes } from "generated/routes"
import moment from "moment"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { CertificateDocument } from "shared/learning/components/CertificateDocument"
import { attendeeStatus, attendeeStatusColor, attendeeStatusIcon, validTo } from "./helpers/AttendeeStatus"

export const AttendeeTable = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "learning.admin.settings" })

  const globalClasses = globalUseStyles()

  const { items, setShowReminderModal, setClickedRowIds, examinationName } = props

  const columns = useMemo(
    () => [
      {
        accessor: "userFullName",
        Header: "Namn"
      },
      {
        accessor: "email",
        Header: "E-post"
      },

      {
        accessor: "status",
        Cell: ({ cell }) => {
          const label =
            cell.row.original.lastDateForPassedExam &&
            moment(cell.row.original.lastDateForPassedExam).diff(moment(), "days") > 0
              ? `Löper ut om ${moment(cell.row.original.lastDateForPassedExam).diff(moment(), "days")} dagar`
              : moment(cell.row.original.lastDateForPassedExam).diff(moment(), "days") < 0
              ? `Löpte ut för ${moment(cell.row.original.lastDateForPassedExam)
                  .diff(moment(), "days")
                  .toString()
                  .replace("-", "")} dagar sedan`
              : `Löpt ut`
          return (
            <Box color={`${attendeeStatusColor(cell.row.original.status)}.main`}>
              <FontAwesomeIcon icon={attendeeStatusIcon(cell.row.original.status)} />
              <Typography sx={{ marginLeft: "5px" }} variant="labelMedium">
                {attendeeStatus(cell.row.original.status, label)}
              </Typography>
            </Box>
          )
        },
        Header: "Status",
        FilterHeader: "Status",
        mainFilter: true,
        Filter: SelectColumnFilter,
        FilterOptions: [
          {
            name: "Godkänd",
            value: 1
          },
          {
            name: "Ej påbörjad",
            value: 0
          },
          {
            name: "Ej godkänd",
            value: 2
          },
          {
            name: "Löper ut om 30 dagar",
            value: 3
          }
        ]
      },
      {
        accessor: (row) => {
          return row.latestActivity ? moment(row.latestActivity).format("YYYY-MM-DD") : ""
        },
        Header: "Senast godkänd",
        Filter: DatePickerColumnFilter
      },
      {
        accessor: "lastDateForPassedExam",
        Header: "Giltig till",
        Cell: ({ cell }) => {
          var validObj = validTo(cell.row.original.lastDateForPassedExam)
          return (
            <Box color={`${validObj?.color}.main`}>
              {validObj?.icon && <FontAwesomeIcon icon={validObj?.icon} />}
              <Typography sx={{ marginLeft: "5px" }} variant="labelMedium">
                {validObj?.label}
              </Typography>
            </Box>
          )
        }
      },
      {
        accessor: (row) => {
          return row.lastReminderSent
            ? moment.utc(row.lastReminderSent).local().format("YYYY-MM-DD HH:mm")
            : ""
        },
        Header: "Påminnelse skickad"
      },
      {
        accessor: "manager",
        Header: "Närmsta chef"
      },
      {
        Cell: ({ cell }) => {
          return (
            ((cell.row.original.email && cell.row.original.status !== 1) ||
              (cell.row.original.email &&
                cell.row.original.lastDateForPassedExam !== null &&
                moment(cell.row.original.lastDateForPassedExam).diff(moment(), "days") < 30)) && (
              <Button size="small" variant="contained" color="primary">
                <Typography
                  fontSize="12px"
                  onClick={() => {
                    setClickedRowIds({
                      userExaminationId: cell.row.original.id,
                      userId: cell.row.original.userId,
                      userName: cell.row.original.userFullName
                    })
                    setShowReminderModal(true)
                  }}
                  variant="bodySmall">
                  {t(`button.send_reminder`)}
                </Typography>
              </Button>
            )
          )
        },
        accessor: "action",
        Header: "",
        hiddenFilter: true
      },

      {
        accessor: "download",
        disablePadding: true,
        Header: "",
        align: "right",
        Cell: ({ cell }) => {
          return (
            cell.row.original.status === 1 && (
              <IconButton
                component={Box}
                size="large"
                onClick={async () => {
                  let blobPDF = await pdf(
                  <CertificateDocument
                    userFullName={cell.row.original.userFullName}
                    examinationName={examinationName}
                    completedDate={cell.row.original.latestActivity}
                  />
                  ).toBlob()

                  const link = document.createElement("a")
                  link.href = window.URL.createObjectURL(blobPDF)
                  link.download = `Utbildningsbevis-${cell.row.original.userFullName}.pdf`
                  link.click()
                  window.URL.revokeObjectURL(link.href)
                }}>
                <FontAwesomeIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} icon={faDownload} size="xs" />
              </IconButton>
            )
          )
        }
      },
      {
        accessor: "edit",
        disablePadding: true,
        Header: "",
        align: "right",
        Cell: ({ cell }) => {
          return (
            cell.row.original.hasResult && (
              <IconButton
                component={Link}
                to={allRoutes.LearningAdminResultsDetails(cell.row.original.resultId)}
                size="large">
                <FontAwesomeIcon icon={faExternalLinkSquare} size="xs" />
              </IconButton>
            )
          )
        }
      }
    ],
    []
  )

  const initialSort = [
    { id: "status", desc: true },
    { id: "userFullName", desc: false }
  ]

  return (
    <Box className={globalClasses.tableContainer}>
      <ClpTable
        columns={columns}
        rows={items.status === 2 && items.data}
        status={items.status}
        sortParams={initialSort}
      />
    </Box>
  )
}
