import { Button, Container, Grid } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { AppContext } from "components/AppContextProvider"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import PaperBox from "components/PaperBox"
import { PageTabs } from "components/navigation/PageTabs"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import { t as globalT } from "i18next"
import FileForm from "pages/documents/components/Form/FileForm"
import FolderForm from "pages/documents/components/Form/FolderForm"
import { IDocumentFormValues } from "pages/documents/interface/folder.interface"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

const EditFolder = ({ match }: any) => {
  const { t } = useTranslation("translation", { keyPrefix: "documents" })
  const appContext = useContext(AppContext)
  const [folderResult, getFolderApi] = useApi() as [IApiResult, IApiFunc]
  const [deleteResult, deleteFolder] = useApi() as [IApiResult, IApiFunc]
  const [showContinueModal, setShowContinueModal] = useState(false)
  const [updateFolderResult, updateFolder] = useApi() as [IApiResult, IApiFunc]
  const [initialValues, setInitialValues] = useState<IDocumentFormValues>()
  const routerHistory = useHistory()

  const handleSubmit = (values: IDocumentFormValues) => {
    updateFolder.put(apiEndpoints.documents.updatedocument, values)
  }

  useEffect(() => {
    getFolderApi.get(apiEndpoints.documents.getdocument.replace("{id}", match.params.id))

    return () => {
      getFolderApi.leave()
    }
  }, [getFolderApi])

  useEffect(() => {
    if (folderResult.status === 2) {
      setInitialValues(folderResult.data)
    }
  }, [folderResult])

  useEffect(() => {
    if (updateFolderResult.status === 2) {
      getFolderApi.get(apiEndpoints.documents.getdocument.replace("{id}", match.params.id))
    }
  }, [updateFolderResult, match])

  useEffect(() => {
    if (deleteResult.status === 2) {
      routerHistory.push(allRoutes.Documents())
    }
  }, [deleteResult])

  const removeFile = () => {
    deleteFolder.del(apiEndpoints.documents.deletedocument.replace("{id}", match.params.id))
  }

  return (
    <div>
      <PageTabs
        routeBack
        tabs={[
          {
            path: allRoutes.Documents(),
            title: globalT(`global.buttons.back`)
          }
        ]}
      />
      <Container>
        <Grid pt={4} container direction="row" justifyContent="space-between" spacing={3}>
          <Grid item xs={12} md={4} lg={5} xl={5}>
            {hasRole(appContext.appUser, allRoles.DocumentsAdmin) && initialValues !== undefined && <FileForm {...initialValues} />}
          </Grid>
          <Grid item xs={12} md={8} lg={7} xl={7}>
            {folderResult.status === 2 && initialValues && (
              <PaperBox>
                <FolderForm {...initialValues} handleSubmit={handleSubmit} results={updateFolderResult} />
              </PaperBox>
            )}
          </Grid>
        </Grid>
        {hasRole(appContext.appUser, allRoles.DocumentsAdmin) && (
          <Grid container direction="row" justifyContent="space-between" marginY={2}>
            <Grid item>
              <Button type="button" variant="contained" color="error" onClick={() => {setShowContinueModal(!showContinueModal)}}>
                {t(`folder.remove_button`)}
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
      {showContinueModal && (
        <ContinueDialog
          result={deleteResult}
          setShowContinueModal={setShowContinueModal}
          onAddSubmit={removeFile}
          message={`Är du säker på att du vill ta bort mappen ${folderResult.data?.name} och alla uppladdade filer, ditt val går inte att ångra?`}
          continueBtnText="Ja, ta bort"
          cancelBtnText="Avbryt"
        />
      )}
    </div>
  )
}
export default EditFolder
