import { faChartLine, faLeaf, faLeafHeart } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DatePicker } from "@mui/x-date-pickers"

import { Avatar, Box, Container, Grid, TextField, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import classnames from "classnames"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import { StatsCard } from "pages/planning/components/StatsCard"
import { StatsCardLoading } from "pages/planning/components/StatsCardLoading"
import { useContext, useEffect, useMemo, useState } from "react"

export const Percentage = (props) => {
  const { value, maxDecimals } = props
  var percentageValue = value * 100

  if (maxDecimals !== undefined) {
    percentageValue = percentageValue && parseFloat(percentageValue.toFixed(maxDecimals))
  }

  return <span>{percentageValue} %</span>
}

const Date = ({ date, setDate }) => {
  function disableDays(date) {
    return date > moment()
  }

  return (
    <DatePicker
      value={date ? moment(date) : null}
      shouldDisableDate={disableDays}
      onChange={(newValue) => setDate(moment(newValue).format("YYYY-MM-DD"))}
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

const EcoDriving = () => {
  const globalClasses = globalUseStyles()
  const [date, setDate] = useState(moment())
  const [ecodrivingResult, getEcoDriving] = useApiGet()

  const [ecodrivingDetailsResult, getEcoDrivingDetails] = useApiGet()

  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    getEcoDriving(apiEndpoints.planning.getecodriving)
  }, [getEcoDriving])

  useEffect(() => {
    getEcoDrivingDetails(
      apiEndpoints.planning.getdetailedecodriving.replace("{date}", moment(date).format("YYYY-MM-DD"))
    )
  }, [getEcoDrivingDetails])

  useEffect(() => {
    getEcoDrivingDetails(
      apiEndpoints.planning.getdetailedecodriving.replace("{date}", moment(date).format("YYYY-MM-DD"))
    )
  }, [date])

  useEffect(() => {
    loadingContext.setLoading(() => ecodrivingResult.status === 1)
  })
  function LeafPercentageIcon(props) {
    const icon =
      props.value < 0.95 ? (
        <Avatar
          aria-label="icon"
          className={classnames(globalClasses.avatar, globalClasses.backgroundSuccess)}>
          <FontAwesomeIcon icon={faLeafHeart} />
        </Avatar>
      ) : props.value >= 0.95 && props.value <= 1.05 ? (
        <Avatar
          aria-label="icon"
          className={classnames(globalClasses.avatar, globalClasses.backgroundPrimary)}>
          <FontAwesomeIcon icon={faLeaf} />
        </Avatar>
      ) : props.value > 1.05 ? (
        <Avatar aria-label="icon" className={classnames(globalClasses.avatar, globalClasses.backgroundError)}>
          <FontAwesomeIcon icon={faLeaf} />
        </Avatar>
      ) : null

    return icon
  }

  function percentageLabel(value) {
    const icon =
      value < 0.989
        ? "Du förbrukar mindre än dina kollegor"
        : value >= 0.989 && value <= 1.01
        ? "Du förbrukar i snitt lika som dina kollegor"
        : value > 1.01
        ? "Du förbrukar mer än dina kollegor"
        : null

    return icon
  }

  const columns = useMemo(
    () => [
      {
        accessor: "ecoTurId",
        Header: "Tur"
      },
      {
        accessor: "turbeskrivning",
        Header: "Tjänst"
      },
      {
        accessor: "fordonRegistreringsnummer",
        Header: "Buss"
      },
      {
        accessor: (row) => {
          return row.ecoIndex && parseFloat(row.ecoIndex.toFixed(2))
        },
        Header: "Jämförelseindex"
      },
      {
        accessor: (row) => {
          return row.distance && parseFloat(row.distance.toFixed(2))
        },
        Header: "Distans (KM)"
      },
      {
        accessor: (row) => {
          return row.outsideTemp && parseFloat(row.outsideTemp.toFixed(2))
        },
        Header: "Medeltemperatur (C)"
      },
      {
        accessor: (row) => {
          return row.avgFuelUsed && parseFloat(row.avgFuelUsed.toFixed(2))
        },
        Header: "Snittförbrukning (l/Mil)"
      }
    ],
    []
  )

  return (
    <div>
      <Container>
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item md={8}>
              <Typography variant="h1" gutterBottom>
                Sparsam körning
              </Typography>
              <Typography variant="paragraphLarge" gutterBottom>
                Här ser du en presentation på hur din bränsleförbrukning är jämfört med dina kollegor. Du
                jämförs på varje tur du kör och kan se resultaten i procent (%) per dag, per vecka och per
                månad.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container direction="row" spacing={3}>
          <Grid item sm={3}>
            <PaperBox>
              {ecodrivingResult.status === 2 ? (
                <StatsCard
                  bigIcon={
                    ecodrivingResult.status === 2 ? (
                      <LeafPercentageIcon value={ecodrivingResult.data.ecoIndexDay} />
                    ) : null
                  }
                  statsText="Min dag"
                  statsDesc="Senaste arbetsdagen"
                  statsValue={
                    ecodrivingResult.status === 2 ? (
                      <Percentage value={ecodrivingResult.data.ecoIndexDay} maxDecimals={1} />
                    ) : (
                      "-"
                    )
                  }
                  statsIconText={ecodrivingResult.status === 2 && ecodrivingResult.data?.dayLabel}
                  subText={percentageLabel(ecodrivingResult.data.ecoIndexDay)}
                />
              ) : (
                <StatsCardLoading />
              )}
            </PaperBox>
          </Grid>
          <Grid item sm={3}>
            <PaperBox>
              {ecodrivingResult.status === 2 ? (
                <StatsCard
                  bigIcon={
                    ecodrivingResult.status === 2 ? (
                      <LeafPercentageIcon value={ecodrivingResult.data.ecoIndexWeek} />
                    ) : null
                  }
                  statsText="Min vecka"
                  statsDesc="Senaste 7 dagarna"
                  statsValue={
                    ecodrivingResult.status === 2 ? (
                      <Percentage value={ecodrivingResult.data.ecoIndexWeek} maxDecimals={1} />
                    ) : (
                      "-"
                    )
                  }
                  statsIconText={ecodrivingResult.status === 2 && ecodrivingResult.data?.weekLabel}
                  subText={percentageLabel(ecodrivingResult.data.ecoIndexWeek)}
                />
              ) : (
                <StatsCardLoading />
              )}
            </PaperBox>
          </Grid>
          <Grid item sm={3}>
            <PaperBox>
              {ecodrivingResult.status === 2 ? (
                <StatsCard
                  bigIcon={
                    ecodrivingResult.status === 2 ? (
                      <LeafPercentageIcon value={ecodrivingResult.data.ecoIndexMonth} />
                    ) : null
                  }
                  statsText="Min månad"
                  statsDesc="Senaste 30 dagarna"
                  statsValue={
                    ecodrivingResult.status === 2 ? (
                      <Percentage value={ecodrivingResult.data.ecoIndexMonth} maxDecimals={1} />
                    ) : (
                      "-"
                    )
                  }
                  statsIconText={ecodrivingResult.status === 2 && ecodrivingResult.data?.weekLabel}
                  subText={percentageLabel(ecodrivingResult.data.ecoIndexMonth)}
                />
              ) : (
                <StatsCardLoading />
              )}
            </PaperBox>
          </Grid>
          <Grid item sm={3}>
            <PaperBox>
              {ecodrivingResult.status === 2 ? (
                <StatsCard
                  bigIcon={
                    <Avatar
                      aria-label="icon"
                      className={classnames(globalClasses.avatar, globalClasses.backgroundPrimary)}>
                      <FontAwesomeIcon icon={faChartLine} />
                    </Avatar>
                  }
                  statsText="Min förändring mot föregående månad"
                  statsValue={
                    ecodrivingResult.status === 2 ? (
                      <Percentage
                        value={ecodrivingResult.data.ecoIndexMonth - ecodrivingResult.data.ecoIndexPrevMonth}
                        maxDecimals={1}
                      />
                    ) : (
                      "-"
                    )
                  }
                  statsIconText={ecodrivingResult.status === 2 && ecodrivingResult.data?.weekLabel}
                />
              ) : (
                <StatsCardLoading />
              )}
            </PaperBox>
          </Grid>
        </Grid>
      </Container>
      <Box className={globalClasses.tableContainer} marginTop={5}>
        <Box paddingX={3} paddingY={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2" gutterBottom>
                Dagens Turer
              </Typography>
              <Typography variant="paragraphLarge">
                Välj ett specifikt datum och se hur varje tur gick den dagen.
              </Typography>
            </Grid>
            <Grid item>
              <Date date={date} setDate={setDate} />
            </Grid>
          </Grid>
        </Box>

        <ClpTable
          columns={columns}
          rows={ecodrivingDetailsResult.status === 2 && ecodrivingDetailsResult.data}
          status={ecodrivingDetailsResult.status}
        />
      </Box>
    </div>
  )
}

export default EcoDriving
