import Drawer from "@mui/material/Drawer"
import { AppContext } from "components/AppContextProvider"
import React, { useContext } from "react"
import styles from "./style"

export const Sidebar = ({ children }) => {
  const classes = styles()
  const appContext = useContext(AppContext)

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          variant="persistent"
          className={classes.sidebarDrawer}
          anchor="right"
          open={appContext.isSidebarOpen}
          classes={{
            paper: classes.drawerPaper
          }}>
          {children}
        </Drawer>
      </React.Fragment>
    </div>
  )
}
