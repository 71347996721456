import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Typography } from "@mui/material"
import theme from "css/theme/light"
import { t } from "i18next"
import { useStyles } from "./useStyles"

export const ExamAreaHeader = (props) => {
  const { data } = props
  const classes = useStyles()
  const vimeoIframe = `https://player.vimeo.com/video/${data.html.split(/:(.+)/)[1]}`
  return (
    <>
      <Typography variant="h3" marginBottom={3}>
        {data.title}
      </Typography>
      {data.html && data.html.length > 0 ? (
        <div className={classes.areaContent}>
          <iframe
            className={classes.reponsiveIframe}
            width="640"
            height="360"
            title={data.title}
            src={vimeoIframe}
            frameBorder="0"></iframe>
        </div>
      ) : null}
      {data.information && (
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          bgcolor={theme.palette.primary.light}
          borderRadius="8px"
          color={theme.palette.primary.dark}
          p={3}
          gap={1}>
          <Box fontWeight="600">
            <FontAwesomeIcon icon={faInfoCircle} size="1x" /> {t("learning.titles.important_info")}
          </Box>
          <Typography>{data.information}</Typography>
        </Box>
      )}
    </>
  )
}
