import { faCircleCheck, faCircleQuestion, faPaperPlane } from "@fortawesome/pro-solid-svg-icons"
import theme from "css/theme/light"
import { IDeviationStatusProps } from "pages/deviation/deviation.interface"

export const statusOptions: Array<{ label: string; value: number }> = [
  { label: "Ohanterad", value: 0 },
  { label: "Tilldelad", value: 1 },
  { label: "Hanterad", value: 2 }
]

export const deviationStatusProps = (id: number) => {
  switch (id) {
    case 0:
      return {
        title: "Ohanterad",
        icon: faCircleQuestion,
        color: theme.palette.grey[600],
        description: ""
      } as IDeviationStatusProps
    case 1:
      return {
        title: "Tilldelad",
        icon: faPaperPlane,
        color: theme.palette.primary["main"],
        description: ""
      } as IDeviationStatusProps
    case 2:
      return {
        title: "Hanterad",
        icon: faCircleCheck,
        color: theme.palette.success["main"],
        description: ""
      } as IDeviationStatusProps
    default:
      return {
        title: "Ohanterad",
        icon: faCircleQuestion,
        color: theme.palette.grey[600],
        description: ""
      } as IDeviationStatusProps
  }
}
