import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import styles from "./style.js";

interface SosButtonProps extends ButtonProps {}

const SosButton: React.FC<SosButtonProps> = (props) => {

  const style = styles()

  return (
    <Button variant="contained" color={"error"} style={{padding: 5}} {...props} href="tel:112">
      <Box className={style.sosIconBackground} sx={{ marginRight: 1 }}>
      <FontAwesomeIcon icon={faPhone as IconProp} size="sm"/>
      </Box> 
      <Typography sx={{ marginRight: 1 }}>RING SOS</Typography>
    </Button>
  )
}

export default SosButton
