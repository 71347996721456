import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import moment from 'moment'
import {useState} from 'react'

export const YearPicker = ({year, setYear}) => {
  const [years, setYears] = useState([
    moment().month() === 11 && moment().add(1, 'year').format('YYYY'),
    moment().format('YYYY'),
    moment().add(-1, 'year').format('YYYY')
  ])

  return (
    <FormControl variant="outlined">
      <InputLabel id="year-label">År</InputLabel>
      <Select
        labelId="year-label"
        label="År"
        id="year-select"
        value={moment(year).format('YYYY')}
        onChange={(event) => setYear(event.target.value)}
      >
        {years?.map((yearItem) => (
          <MenuItem key={yearItem} value={yearItem}>
            {yearItem}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
