import { faBusAlt } from "@fortawesome/pro-solid-svg-icons"
import { Autocomplete, Box, Button, FormControl, ListItem, MenuItem, Select, TextField } from "@mui/material"
import List from "@mui/material/List"
import { useApiGet } from "auth/useApi2"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import SidebarHeader from "components/sidedrawer/SidebarHeader"
import SidebarInputLabel from "components/sidedrawer/SidebarInputLabel"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useEffect } from "react"
import Moment from "react-moment"
import { VehicleAlarmChip } from "../VehicleAlarmChip"
import { useStyles } from "../VehicleBar/useStyles"

export const status = (value) => {
  switch (value) {
    case 1:
      return <VehicleAlarmChip status="Pågående" />
    case 2:
      return <VehicleAlarmChip status="Avslutad" />
    default:
      return <VehicleAlarmChip status="Aktiv" />
  }
}

export const alarmType = (value) => {
  switch (value) {
    case 1:
      return "Alkolås"
    default:
      return "Förarkort"
  }
}

export const VehicleList = ({ formData, setShowSidebar, onSubmit, showSidebar }) => {
  const classes = useStyles()
  const [usersResult, getUsers] = useApiGet()
  useEffect(() => {
    if (formData != null)
      getUsers(
        apiEndpoints.vehiclealarm.getresponsibleusersforvehiclealarmeditresponsibledropdown.replace(
          "{vehicleAlarmId}",
          formData.id
        )
      )
  }, [getUsers, formData])

  const handleSubmit = async (values, { resetForm }) => {
    onSubmit(values)
  }

  const toggleMenu = () => {
    setShowSidebar(false)
  }

  return formData ? (
    <Formik onSubmit={handleSubmit} initialValues={formData} enableReinitialize={true}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <List className={classes.vehicleList}>
            <CloseSidebarButton toggleMenu={toggleMenu} />
            <SidebarHeader
              icon={faBusAlt}
              color="primary"
              size="lg"
              title={formData.vehicle.regNumber}
              subtitle={formData.vehicle.internnumber}
              footer={status(formData.status)}
            />

            <List sx={{ padding: "40px 0 0 0" }}>
              <ListItem className={classes.listItem}>
                <SidebarInputLabel labelText="Fordons id" valueText={formData.vehicle.internnumber} />
              </ListItem>
              <ListItem className={classes.listItem}>
                <SidebarInputLabel labelText="Registreringsnummer" valueText={formData.vehicle.regNumber} />
              </ListItem>

              <ListItem className={classes.listItem}>
                <SidebarInputLabel labelText="Larmtyp" valueText={alarmType(formData.alarmType)} />
              </ListItem>

              <ListItem className={classes.listItem}>
                <SidebarInputLabel
                  labelText="Larmtid"
                  valueText={<Moment format="YYYY-MM-DD HH:mm">{formData.alarmDate}</Moment>}
                />
              </ListItem>

              <ListItem className={classes.listItemInput}>
                <SidebarInputLabel labelText="Åtgärd" />

                <TextField
                  autoComplete="off"
                  fullWidth
                  id="measure"
                  value={values.measure}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    classes: { input: classes.formAreaInput },
                    disableUnderline: true
                  }}
                />
              </ListItem>

              <ListItem className={classes.listItemInput}>
                <SidebarInputLabel labelText="Ansvarig" />

                <FormControl className={classes.fullWidth}>
                  {usersResult.status === 2 && (
                    <Autocomplete
                      id="userid-autocomplete"
                      options={usersResult?.data}
                      getOptionLabel={(option) => (option.name ? option.name : "")}
                      value={usersResult.data.find((i) => i.id === values.userId) || null}
                      onChange={(e, value) => {
                        setFieldValue("userId", value?.id || null)
                        setFieldValue("user", value || null)
                      }}
                      onOpen={handleBlur}
                      includeInputInList
                      renderInput={(params) => <TextField {...params} name="userId" variant="outlined" />}
                    />
                  )}
                </FormControl>
              </ListItem>

              <ListItem className={classes.listItemInput}>
                <SidebarInputLabel labelText="Status" />

                <FormControl className={classes.fullWidth} FormControl variant="outlined">
                  <Select
                    id="status"
                    value={values.status}
                    onChange={(e) => {
                      setFieldValue("status", e.target.value)
                    }}
                    MenuProps={{
                      disableScrollLock: true
                    }}>
                    <MenuItem value={0}>Aktiv</MenuItem>
                    <MenuItem value={1}>Pågående</MenuItem>
                    <MenuItem value={2}>Avslutad</MenuItem>
                  </Select>
                </FormControl>
              </ListItem>
            </List>

            <Box pt={3} pb={5}>
              <Button color="primary" type="submit" variant="contained">
                Spara ändringar
              </Button>
            </Box>
          </List>
        </form>
      )}
    </Formik>
  ) : null
}
