import { Box, Grid } from "@mui/material"
import React from "react"

const MoreFilters = (props) => {
  const { headerGroups } = props
  return (
    <Box my={2} padding={3} width={1}>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column, index) => {
            return (
              !column.hiddenFilter &&
              !column.mainFilter &&
              column.Header && (
                <Grid item key={index}>
                  {column.render("Filter", column.render("Header"))}
                </Grid>
              )
            )
          })
        )}{" "}
      </Grid>
    </Box>
  )
}

export default MoreFilters
