import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  dateRangeContainer: {
    position: "absolute",
    top: 75,
    zIndex: 1999
  },
  dateRange: {
    background: "#fff",
    padding: "5px 10px"
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54);",
    fontSize: "1.5rem",
    marginRight: 5
  },
  endDate: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      color: "#254467"
    }
  },
  updateIcon: {
    cursor: "pointer"
  }
}))
