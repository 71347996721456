import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useStyles } from "../../../components/useStyles";

const WorkOrderCardDetails = ({ values, bgColor }) => {

    const classes = useStyles();

    return (
        <Box backgroundColor={bgColor} className={classes.greyBox} p={2} mt={4}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Box p={2}>
                    <Typography>Reg.Nr.</Typography>
                    <Typography variant="h6">{values.regNumber}</Typography>
                </Box>
                <Box p={2}>
                    <Typography>Chassinummer</Typography>
                    <Typography variant="h6">{values.chassiNumber}</Typography>
                </Box>
                <Box p={2}>
                    <Typography>Årsmodell</Typography>
                    <Typography variant="h6">{values.vehicleYear}</Typography>
                </Box>
                <Box p={2}>
                    <Typography>Modell</Typography>
                    <Typography variant="h6">{values.model}</Typography>
                </Box>
                <Box p={2}>
                    <Typography>Tillverkare</Typography>
                    <Typography variant="h6">{values.model?.split(" ")[0]}</Typography>
                </Box>
            </Box>
        </Box>)
}

export default WorkOrderCardDetails