import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react"
import { AppContext } from "components/AppContextProvider"
import { hasRole } from "auth/hasRole"
import { allRoles } from "generated/roles"
import { Sidebar } from "pages/vehicleregistry/pages/vehicles/pages/customfields/components/Sidebar"
import {
  Box,
  Button,
  DialogContent,
  Divider,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from "@mui/material"
import { Formik } from "formik"
import { RouterPrompt } from "components/RouterPrompt"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import ListItem from "@mui/material/ListItem"
import { CircleIcon } from "components/icons/CircleIcon"
import { faCar, faFileArrowUp, faPenField, faXmark } from "@fortawesome/pro-solid-svg-icons"
import { StyledListItemText } from "../InspectionService.styled"
import { DateTimePicker } from "@mui/x-date-pickers"
import moment from "moment/moment"
import { t } from "i18next"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import { apiEndpoints } from "generated/apiEndpoints"
import { useApi } from "auth/useApi"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { WorkshopData } from "pages/customer/pages/settings/pages/companies/components/Workshop/Workshop.interfaces"
import Dialog from "@mui/material/Dialog"
import Grid from "@mui/material/Grid"
import {
  StyledBox,
  StyledBoxClose,
  StyledFontAwesomeIcon,
  StyledTypographyModal
} from "pages/vehicleregistry/pages/checks/InspectionsHistoryTable.styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useHistory, useLocation } from "react-router-dom"
import { allRoutes } from "generated/routes"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { NoWorkshopReminder } from "./InspectionBookingSidebar"

interface ServiceApproveSidebarProps {
  setShowSidebar: (state: boolean) => void,
  showSidebar: boolean
  clickedService: any
  loadChecks: () => void
}

const ServiceApprovalValidationSchema = Yup.object().shape({
  workshopId: Yup.number()
    .required("Verkstad är obligatoriskt")
    .integer("Välj verkstad")
    .positive("Välj verkstad"),
  dateTime: Yup.date().required("Datum är obligatoriskt"),
  meterSetting: Yup.number().integer("Måste vara heltal").positive("Måste vara över 0").required("Mätarställning är obligatoriskt")
})

interface ServiceFormValues {
  workshopId: number,
  dateTime: string
  meterSetting: number
}

const initialFormValues: ServiceFormValues = {
  workshopId: 0,
  dateTime: "",
  meterSetting: 0
}

export interface ApproveCheckDto {
  customFieldVehicleValueId: number
  carWorkshopId: number
  dateTime: moment.Moment
  meterSetting: number
}

interface DocumentReminderProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  vehicleId: number
}

const DocumentReminder = ({ isOpen, setIsOpen, vehicleId }: DocumentReminderProps) => {
  const history = useHistory()
  const location = useLocation()

  const handleClose = () => {
    setIsOpen(false)
  }

  const redirect = (query: string) => {
    handleClose()
    history.push(allRoutes.VehicleregistryVehiclesDetails(vehicleId) + query)
  }

  const isVehicleCard = location.pathname.match(/\/vehicleregistry\/vehicles\/[1-9]+\/details/)

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return
        handleClose()
      }}
      maxWidth="xs"
    >
      <Grid container direction="column" alignItems="center">
        <DialogContent>
          <Grid container flexDirection={"row"}>
            <Box sx={{marginTop: "8px"}}>
              <Typography variant="h3">Kom ihåg!</Typography>
            </Box>
            <StyledBoxClose onClick={() => { handleClose() }}>
              <FontAwesomeIcon size="xl" color="grey" icon={faXmark} />
            </StyledBoxClose>
          </Grid>
          <Grid container flexDirection={"column"} mb={5}>
            <StyledTypographyModal align="left" variant="paragraphMedium" color="textSecondary">
              Glöm inte att ladda upp serviceprotokollet! Detta gör du under
              Dokument-tabben på fordonskortet.
            </StyledTypographyModal>
            {!isVehicleCard && (
              <StyledTypographyModal align="left" variant="paragraphMedium" color="textSecondary">
                Du kan även klicka på länken nedan för att navigera dit direkt.
              </StyledTypographyModal>
            )}
          </Grid>
          {!isVehicleCard && (
            <>
              <Divider sx={{ width: "calc(100% + ) + 48px", marginLeft: "-24px", marginRight: "-24px" }}/>
              <Box mt={3}>
                <Box
                  style={{ textDecoration: "none", width: "100%", cursor: "pointer" }}
                  onClick={() => { redirect("?tab=documents") }}
                >
                  <StyledBox display="flex" justifyContent="space-between" mb={3} gap={3} alignItems={"center"}>
                    <Grid container gap={2} ml={0.3}>
                      <FontAwesomeIcon size="xl" color="grey" icon={faFileArrowUp} />
                      <Typography variant="h5" color="primary">Ladda upp protokoll</Typography>
                    </Grid>
                    <StyledFontAwesomeIcon size="xl" icon={faArrowRight} />
                  </StyledBox>
                </Box>
                <Box
                  style={{ textDecoration: "none", width: "100%", cursor: "pointer" }}
                  onClick={() => { redirect("") }}
                >
                  <StyledBox display="flex" justifyContent="space-between" gap={2} alignItems={"center"}>
                    <Grid container gap={2}>
                      <FontAwesomeIcon size="xl" color="grey" icon={faCar} />
                      <Typography variant="h5" color="primary">Gå till fordon</Typography>
                    </Grid>
                    <StyledFontAwesomeIcon size="xl" icon={faArrowRight} />
                  </StyledBox>
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
      </Grid>
    </Dialog>
  )
}

export const ServiceApproveSidebar = ({ setShowSidebar, showSidebar, clickedService, loadChecks }: ServiceApproveSidebarProps) => {
  const appContext = useContext(AppContext)
  const [serviceResult, serviceApi] = useApi() as [IApiResult, IApiFunc]
  const [servicePostResult, servicePostApi] = useApi() as [IApiResult, IApiFunc]
  const [workshopResult, workshopApi] = useApi() as [IApiResult, IApiFunc]
  const formRef = useRef() as any
  const [formikValues, setFormikValues] = useState(initialFormValues)
  const [showReminder, setShowReminder] = useState<boolean>(false)
  const [showNoWorkshopReminder, setShowNoWorkshopReminder] = useState(false)
  
  const handleFormikSubmit = (formValues: ServiceFormValues) => {
    const { customFieldVehicleValueId } = clickedService
    const { workshopId, meterSetting, dateTime } = formValues
    const formData: ApproveCheckDto = {
      customFieldVehicleValueId,
      dateTime: moment(dateTime).utc(),
      carWorkshopId: workshopId,
      meterSetting
    }
    const url = apiEndpoints.vehicleregistrychecks.approveservice
    setShowReminder(true)
    servicePostApi.post(url, formData)
  }

  useEffect(() => {
    if (servicePostResult.status !== 2) return
    loadChecks()
    setShowSidebar(false)
  }, [servicePostResult])

  useEffect(() => {
    if (!showSidebar) return
    formRef.current.resetForm()
    const url = `${apiEndpoints.vehicleregistrychecks.getactivecompanycarworkshops}?companyId=${clickedService?.companyId}`
    workshopApi.get(url)
    setFormikValues(initialFormValues)
  }, [showSidebar])

  useEffect(() => {
    if (workshopResult.status !== 2) return
    if (workshopResult.data.length === 0) {
      setFormikValues(prevState => ({ ...prevState, workshopId: 0 }))
      setShowNoWorkshopReminder(true)
      return
    } else if (workshopResult.data.length <= 0) return
    setFormikValues(prevState => ({ ...prevState, workshopId: workshopResult.data[0].id }))
  }, [workshopResult])

  return (
    <>
      <DocumentReminder isOpen={showReminder} setIsOpen={setShowReminder} vehicleId={clickedService?.vehicleId} />
      {hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin) && (
        <Sidebar showSidebar={showSidebar}>
          <Box sx={{ height: "100%", margin: "20px" }}>
            <Formik
              innerRef={formRef}
              initialValues={formikValues}
              validationSchema={ServiceApprovalValidationSchema}
              onSubmit={(values) => {
                handleFormikSubmit(values)
              }}
              enableReinitialize={true}>
              {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleBlur, isValid, dirty, setFieldTouched }) => (
                <form style={{ height: "100%" }} id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
                  <NoWorkshopReminder
                    isOpen={showNoWorkshopReminder}
                    setIsOpen={setShowNoWorkshopReminder}
                    clickedInspection={clickedService}
                  />
                  <RouterPrompt />
                  <List sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <Box style={{ position: "absolute", left: "-20px" }}>
                      <CloseSidebarButton
                        toggleMenu={() => {
                          setShowSidebar(!showSidebar)
                        }}
                      />
                    </Box>
                    <ListItem sx={{ borderBottom: "1px solid #e0e0e0" }}>
                      <Box pt={5} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <CircleIcon
                            icon={faPenField}
                            color="primary"
                            size="lg"
                            sx={{ marginRight: "15px" }}
                          />
                          <Box justifyContent="center">
                            <ListItemText
                              primary={
                                <>
                                  <Typography color="textPrimary" variant="h2">
                                    Godkänn {clickedService?.controlType.toLowerCase()}
                                  </Typography>
                                  <Typography color="textSecondary" variant="h4">
                                    {clickedService?.customFieldName}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <Typography color="textSecondary" variant="bodyMedium">
                                  {clickedService?.regNumber} / {clickedService?.internNumber}
                                </Typography>
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <StyledListItemText
                        primary={
                          <InputLabel>
                            <Typography variant="h6" color={"black"}>
                              Verkstad
                            </Typography>
                          </InputLabel>
                        }
                        secondary={
                          <>
                            <Select
                              fullWidth
                              name="workshopId"
                              value={values.workshopId}
                              onChange={handleChange}
                              error={touched.workshopId && errors.workshopId ? true : false}
                            >
                              {workshopResult.data &&
                                workshopResult.data
                                  .map((workshop: WorkshopData) => (
                                      <MenuItem key={workshop.name} value={workshop.id}>
                                        {workshop.name}
                                      </MenuItem>
                                    )
                                  )}
                            </Select>
                            {touched.workshopId && errors.workshopId && (
                              <div style={{ color: "red" }}>
                                {errors.workshopId}
                              </div>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <StyledListItemText
                        primary={
                          <InputLabel>
                            <Typography variant="h6" color={"black"}>
                              Datum & tid
                            </Typography>
                          </InputLabel>
                        }
                        secondary={
                          <DateTimePicker
                            value={values.dateTime ? moment(values.dateTime) : null}
                            format={"YYYY-MM-DD HH:mm"}
                            onChange={(value) => {
                              setFieldValue(
                                "dateTime",
                                moment(value).isValid() ? moment(value).format("YYYY-MM-DD HH:mm") : null
                              )
                            }}
                            slotProps={{
                              textField:
                                {
                                  variant: "outlined",
                                  helperText: touched.dateTime && errors.dateTime ? errors.dateTime : null,
                                  error: touched.dateTime && errors.dateTime ? true : false
                                }
                            }}
                          />
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <StyledListItemText
                        primary={
                          <InputLabel>
                            <Typography variant="h6" color={"black"}>
                              Mätarställning vid kontroll
                            </Typography>
                          </InputLabel>
                        }
                        secondary={
                          <TextField
                            placeholder={"Kilometer"}
                            autoComplete="off"
                            fullWidth
                            type="number"
                            id="meterSetting"
                            value={values.meterSetting > 0 ? values.meterSetting : ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.meterSetting && errors.meterSetting ? true : false}
                            helperText={touched.meterSetting && errors.meterSetting ? errors.meterSetting : null}
                            variant="outlined"
                          />
                        }
                      />
                    </ListItem>
                    <Box sx={{ marginTop: "Auto", marginBottom: "26px", width: "100%" }}>
                      <ListItem sx={{ flexDirection: "column", gap: "14px" }}>
                        <Button
                          sx={{ width: "100%", margin: "0 10px" }}
                          type="button"
                          variant="contained"
                          onClick={
                            () => {
                              if (!dirty) {
                                Object.keys(values).forEach(valueName => {
                                  setFieldTouched(valueName, true)
                                })
                                return
                              }
                              if (!isValid) {
                                Object.keys(values).forEach(valueName => {
                                  setFieldTouched(valueName, true)
                                })
                                return
                              }
                              handleSubmit()
                            }}
                        >
                          Spara
                        </Button>
                        <Button
                          sx={{ width: "100%", margin: "0 10px" }}
                          type="button"
                          variant={"outlined"}
                          onClick={() => {
                            setShowSidebar(false)
                          }}>
                          {t("global.buttons.cancel")}
                        </Button>
                      </ListItem>
                    </Box>
                  </List>
                </form>
              )}
            </Formik>
          </Box>
        </Sidebar>
      )}
    </>
  )
}