import { faChevronRight, faGraduationCap } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, Typography } from "@mui/material"
import { CircleIcon } from "components/icons/CircleIcon"
import LinkPaperBox from "components/LinkPaperBox"
import { allRoutes } from "generated/routes"
import { NavLink } from "react-router-dom"
import useStyles from "./styles.js"

const ExamSummaryCard = (props) => {
  const { item } = props

  const classes = useStyles()

  return (
    <LinkPaperBox mt={1} mb={3} paddingY={3} paddingX={3}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        component={NavLink}
        to={allRoutes.LearningAdminExamsDetails(item.id)}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
            <Grid item xs={4} md={3}>
              <CircleIcon icon={faGraduationCap} color="primary" size="lg" sx={{ marginRight: "15px" }} />
            </Grid>
            <Grid item xs={8} md={8}>
              <Typography variant="h5">{item.examinationName}</Typography>
              <Typography variant="caption" className={classes.textGrey} gutterBottom>
                {item.attendeesCount} Deltagare
              </Typography>
            </Grid>
            <Grid item md={1}>
              <FontAwesomeIcon className={classes.textGrey} icon={faChevronRight} size="2x" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LinkPaperBox>
  )
}
export default ExamSummaryCard
