import React from "react";
import { Tabs, Tab } from "@mui/material";

const SubTabs = ({ tabs, value, setValue }) => {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div style={{ width: "100%" }}>
            <Tabs
                indicatorColor="primary"
                value={value}
                onChange={handleChange}
                variant={"scrollable"}
            >
                {tabs &&
                    tabs.map((item, index) => {
                        return (
                            <Tab
                                id={`tab-${index}`}
                                label={item.label}
                                key={index}
                            />
                        );
                    })}
            </Tabs>
        </div>
    );
};
export default SubTabs;
