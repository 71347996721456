import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, IconButton } from "@mui/material"
import { AppContext } from "components/AppContextProvider"
import { useContext } from "react"

const Pagination = (props) => {
  const {
    pageOptions,
    pageIndex,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    setPage
  } = props

  const appContext = useContext(AppContext)

  const nextPageClick = () => {
    nextPage()
  }

  const previousPageClick = () => {
    previousPage()
  }

  const firstPageClick = () => {
    gotoPage(0)
  }

  const lastPageClick = () => {
    gotoPage(pageCount - 1)
  }

  return (
    <Box>
      <span>
        Sida{" "}
        <strong>
          {pageIndex + 1} av {pageOptions.length < 1 ? 1 : pageOptions.length}
        </strong>{" "}
      </span>
      <IconButton onClick={firstPageClick} disabled={!canPreviousPage} size="large">
        <FontAwesomeIcon icon={faChevronDoubleLeft} size="xs" />
      </IconButton>
      <IconButton onClick={previousPageClick} disabled={!canPreviousPage} size="large">
        <FontAwesomeIcon icon={faChevronLeft} size="xs" />
      </IconButton>
      <IconButton onClick={nextPageClick} disabled={!canNextPage} size="large">
        <FontAwesomeIcon icon={faChevronRight} size="xs" />
      </IconButton>
      <IconButton onClick={lastPageClick} disabled={!canNextPage} size="large">
        <FontAwesomeIcon icon={faChevronDoubleRight} size="xs" />
      </IconButton>
    </Box>
  )
}

export default Pagination
