import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCheck, faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Card, Grid, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import ClpButton from "components/Button"
import IconForButton from "components/ButtonIcon"
import PaperBox from "components/PaperBox"
import { RouterPrompt } from "components/RouterPrompt"
import theme from "css/theme/light"
import { Formik, FormikValues } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import { t as globalT } from "i18next"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { IFormAreaProps } from "../FormArea/formarea.interface"
import FormComponents from "../FormComponents/FormComponents"
import { IFormFieldProps } from "../FormField/formfield.interface"
import { IFormGroupProps } from "../FormGroup/formgroup.interface"
import { FormStepper } from "../Stepper/FormStepper"
import { IFormResult, IFormTemplateProps } from "./formtemplate.interface"
import { getValidation } from "./validation"

const FormTemplate: React.FC<IFormTemplateProps> = (props) => {
  const { id, name, formAreas, apiSubmitEndpoint, formAnswers } = props
  const [formResult, postForm] = useApi() as [IApiResult, IApiFunc]
  const [deviationResult, postDeviation] = useApi() as [IApiResult, IApiFunc]
  const [assignedArea, setAssignedArea] = useState<IFormAreaProps>()
  const [allAreas, setAllAreas] = useState<IFormAreaProps[]>([])
  const [activeStep, setActiveStep] = useState<number>(0)
  const [initialValues, setInitialValues] = useState<FormikValues>({})
  const [validationSteps, setValidationSteps] = useState<any>([])
  useEffect(() => {
    const newValidationSteps = formAreas?.map((area: IFormAreaProps) => {
      const newValidationStep = area.formGroups.flatMap((formGroup: IFormGroupProps) =>
        formGroup.formFields.map((field: IFormFieldProps) => {
          const defaultValue = formAnswers?.find(
            (x) => x.formFieldId.toString() === field.id.toString()
          )?.value
          const newObj = {
            ...initialValues,
            [field.id]: defaultValue ? defaultValue : field.value
          }
          setInitialValues(Object.assign(initialValues, newObj))

          return {
            [field.id]: getValidation(field.dataType, field.optional, field.labelDefault)
          }
        })
      )

      return Yup.object().shape(Object.assign({}, ...newValidationStep))
    })

    setValidationSteps(newValidationSteps)

    //Om det finns fler än ett steg ska det läggas till ett sista granska steg
    if (formAreas.length > 1) {
      const lastStep = {
        id: formAreas.length,
        labelDefault: "Granska och skicka in",
        labelKey: "review_and_complete",
        step: formAreas.length,
        stepLabel: "Granska och skicka in"
      } as IFormAreaProps
      setAllAreas([...formAreas, lastStep])
    } else {
      setAllAreas(formAreas)
    }
  }, [formAnswers, formAreas])

  useEffect(() => {
    const activeStepObject = allAreas?.find((area) => area.step === activeStep)
    setAssignedArea(activeStepObject)
  }, [activeStep, allAreas])

  const handleNext = async (values: any) => {
    const mapValues: Array<IFormResult> = values
      ? Object.entries(values).map(([key, value]) => {
          const mapValue = {
            formFieldId: key,
            value: value?.toString()
          }

          return mapValue
        })
      : []
    if (activeStep === allAreas.length - 1) {
      handleSubmit(mapValues)
    } else {
      handleSubmitFormArea(mapValues)
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const handleSubmitFormArea = async (values: Array<IFormResult>) => {
    const postValues = {
      id: formResult?.data?.id,
      formTemplateId: id,
      status: 0,
      formAnswers: values
    }

    postForm.post(apiEndpoints.form.createorupdateformresult, postValues)
  }

  const handleSubmit = async (values: Array<IFormResult>) => {
    const postValues = {
      id: 0,
      formResult: {
        id: formResult?.data?.id,
        formTemplateId: id,
        status: 0,
        formAnswers: values
      }
    }
    postDeviation.post(apiSubmitEndpoint, postValues)
  }

  return Object.keys(initialValues).length !== 0 ? (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleNext(values)}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSteps[activeStep]}>
      {(props) => {
        const { values, handleSubmit, errors } = props

        return (
          <Box>
            <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
              <RouterPrompt />
              {deviationResult.status !== 2 ? (
                <Box style={{ maxWidth: "800px" }} mx="auto">
                  {allAreas.length > activeStep && (
                    <FormStepper
                      name={name}
                      activeStep={activeStep}
                      areas={allAreas}
                      assignedArea={assignedArea}
                    />
                  )}
                  {activeStep === formAreas.length ? (
                    <>
                      {allAreas.map((area: IFormAreaProps) => {
                        return area?.formGroups?.map((formGroup: IFormGroupProps) => (
                          <Box marginBottom={3}>
                            <Typography variant="bodyLarge" gutterBottom>
                              {formGroup.labelDefault}
                            </Typography>
                            {formGroup.formFields.map((field: IFormFieldProps) => {
                              const parsedDependencyIds =
                                field.dependencyIds && JSON.parse(field.dependencyIds.toString())

                              const dependencyCheck = area?.formGroups?.findIndex(
                                (formGroup: IFormGroupProps) => {
                                  const formValue = formGroup.formFields.findIndex((ff: IFormFieldProps) => {
                                    return parsedDependencyIds?.findIndex(
                                      (d: any) =>
                                        d.formFieldKey === ff.formFieldKey && values[ff.id] === d.value
                                    )
                                  })
                                  return formValue
                                }
                              )

                              return (
                                dependencyCheck !== -1 && (
                                  <FormComponents
                                    initialValues={initialValues}
                                    handleNext={() => handleNext(values)}
                                    field={field}
                                    formikProps={props}
                                  />
                                )
                              )
                            })}
                          </Box>
                        ))
                      })}
                    </>
                  ) : (
                    assignedArea?.formGroups?.map((formGroup: IFormGroupProps) => (
                      <Box
                        marginBottom={3}
                        component={
                          formGroup.formFields.findIndex(
                            (field: IFormFieldProps) => field.component === "Checkbox"
                          ) !== -1 && formGroup.formFields.length > 1
                            ? PaperBox
                            : Box
                        }>
                        <Typography variant="h3" gutterBottom marginBottom="20px">
                          {formGroup.labelDefault}
                        </Typography>
                        {formGroup.formFields.map((field: IFormFieldProps) => {
                          const parsedDependencyIds =
                            field.dependencyIds && JSON.parse(field.dependencyIds.toString())

                          const dependencyCheck = assignedArea?.formGroups?.findIndex(
                            (formGroup: IFormGroupProps) => {
                              const formValue = formGroup.formFields.findIndex((ff: IFormFieldProps) => {
                                return parsedDependencyIds?.findIndex(
                                  (d: any) => d.formFieldKey === ff.formFieldKey && values[ff.id] === d.value
                                )
                              })
                              return formValue
                            }
                          )

                          return (
                            dependencyCheck !== -1 && (
                              <FormComponents
                                initialValues={initialValues}
                                handleNext={() => handleNext(values)}
                                field={field}
                                formikProps={props}
                              />
                            )
                          )
                        })}
                      </Box>
                    ))
                  )}
                  <Grid container direction="row" gap={2} justifyContent="center" mt={5} mb={5}>
                    <Grid item>
                      {activeStep !== 0 && (
                        <Button
                          color="primary"
                          variant="text"
                          onClick={handleBack}
                          disabled={activeStep === 0}
                          startIcon={
                            <IconForButton
                              icon={faChevronLeft as IconProp}
                              noPadding={true}
                              color="primary"
                            />
                          }>
                          {globalT(`global.buttons.previous`)}
                        </Button>
                      )}{" "}
                    </Grid>
                    <Grid item>
                      {activeStep === allAreas.length - 1 ? (
                        <ClpButton
                          onClick={handleSubmit}
                          color="success"
                          variant="contained"
                          result={deviationResult}>
                          {globalT(`deviation.form.button.send_deviation`)}
                        </ClpButton>
                      ) : (
                        <Button
                          endIcon={<IconForButton color="primary" icon={faChevronRight as IconProp} />}
                          color={"primary"}
                          variant="contained"
                          type="submit">
                          {globalT(`global.buttons.next`)}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                deviationResult.status === 2 && (
                  <Grid
                    container
                    direction="row"
                    gap={2}
                    justifyContent="center"
                    alignContent="center"
                    mt={5}
                    mb={5}>
                    <Grid item>
                      <Card sx={{ padding: 3, textAlign: "center" }}>
                        <FontAwesomeIcon
                          icon={faCheck as IconProp}
                          size="5x"
                          color={theme.palette.success.main}
                        />
                        <Typography variant="h1">Tack för hjälpen!</Typography>
                        <Typography variant="bodyMedium" color="textSecondary">
                          Din avvikelse har rapporterats och närmsta chef har notifierats.
                        </Typography>
                        <Button
                          sx={{ marginTop: 3 }}
                          variant="contained"
                          component={Link}
                          to={allRoutes.Deviation()}
                          color="primary">
                          Tillbaka till översikt
                        </Button>
                      </Card>
                    </Grid>
                  </Grid>
                )
              )}
            </form>
          </Box>
        )
      }}
    </Formik>
  ) : (
    <></>
  )
}

export default FormTemplate
