import { Box, Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import { apiEndpoints } from "generated/apiEndpoints"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ExamOverviewCard from "./components/ExamOverviewCard"
import { examOverviewIcons, examOverviewName } from "./helpers/examOverview"

const AdminLearning = () => {
  const { t } = useTranslation("translation", { keyPrefix: "learning.admin" })

  const loadingContext = React.useContext(GlobalLoaderContext)

  const [examSummaries, examSummariesFuncs] = useApi()

  const [exams, setExams] = useState([])

  React.useEffect(() => {
    examSummariesFuncs.get(apiEndpoints.learningadmin.getexamsummaries)
    // examResultsFuncs.get(apiEndpoints.learningadmin.getexamresultsshortlist.replace("{limit}", 8))
  }, [examSummariesFuncs])

  React.useEffect(() => {
    loadingContext.setLoading(examSummaries.status === 1)
  })

  React.useEffect(() => {
    if (examSummaries.status === 2) {
      setExams(examSummaries.data)
    }
  })

  return (
    <div>
      <Container>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h1" gutterBottom>
              {t(`titles.courses`)}
            </Typography>
            <Typography variant="paragraphLarge" gutterBottom>
              {t(`overview.manage_courses`)}
            </Typography>
          </Box>
        </Grid>
        <Box marginTop={2} marginBottom={8}>
          <Grid container spacing={3} display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
            {examSummaries.status === 2 && exams.length > 0 ? (
              exams.map((item) => {
                return (
                  <Grid item md={3}>
                    <ExamOverviewCard
                      key={item.id}
                      id={item.id}
                      examinationName={examOverviewName(item.examinationName)}
                      attendeesCount={`${item.attendeesCount} ${t(`overview.participants`)}`}
                      passedCount={
                        examSummaries.status === 1 ? null : `${item.passedCount} av ${item.attendeesCount}`
                      }
                      expiresIn30Days={examSummaries.status === 1 ? null : item.expiresIn30Days}
                      expiredCount={examSummaries.status === 1 ? null : item.expired}
                      subtitle1={t(`overview.approved`)}
                      subtitle2={t(`overview.days_left`)}
                      subtitle3={t("overview.expired")}
                      icon={examOverviewIcons(item.id)}
                    />
                  </Grid>
                )
              })
            ) : (
              <>
                <Grid mt={3} item md={4} xs={12}>
                  <SkeletonCard sx={{ py: 4 }} />
                </Grid>
                <Grid mt={3} item md={4} xs={12}>
                  <SkeletonCard sx={{ py: 4 }} />
                </Grid>
                <Grid mt={3} item md={4} xs={12}>
                  <SkeletonCard sx={{ py: 4 }} />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Container>
    </div>
  )
}
export default AdminLearning
