import { Autocomplete, FormControl, InputLabel, TextField, Typography } from "@mui/material"
import globalUseStyles from "css/theme/globalUseStyles"
import theme from "css/theme/light"
import { t } from "i18next"
import React, { FC } from "react"
import { IFormSelectProps, IFormSelectValueProps } from "./formselect.interface"

const FormSelect: FC<IFormSelectProps> = (props) => {
  const globalClasses = globalUseStyles()
  const { id, labelKey, labelDefault, optional, options, handleChange, value } = props

  return (
    <>
      <InputLabel className={globalClasses.marginBottom5}>
        <Typography variant="labelLarge" fontWeight={500}>
          {labelKey ? t(labelKey, labelDefault) : labelDefault} {!optional && "*"}
        </Typography>
      </InputLabel>
      <FormControl fullWidth>
        <Autocomplete
          sx={{ width: "100%", background: theme.palette.background.paper }}
          getOptionLabel={(option: IFormSelectValueProps) => option?.label}
          onChange={(e, option) => handleChange && handleChange(option?.value)}
          includeInputInList
          {...props}
          renderInput={(params) => (
            <TextField {...params} {...props} name={id?.toString()} variant="outlined" />
          )}
        />
      </FormControl>
    </>
  )
}

export default FormSelect
