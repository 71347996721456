const MarkerIconArrow = (marker: google.maps.Marker, rotation?: number) => {
    const icon = marker.setIcon({
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        fillColor: "#6DC7DC",
        fillOpacity: 1,
        scale: 5,
        strokeColor: "#6DC7DC",
        strokeWeight: 1,
        rotation: rotation
    });

    return icon;
};
export default MarkerIconArrow;
