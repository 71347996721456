import React from "react"

import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    flexColumn: {
      display: "flex",
      [theme.breakpoints.down("lg")]: {
        flexDirection: "column",
        alignItems: "flex-start"
      }
    }
  })
)

const SortForm = ({ onChange, setState, state }) => {
  const classes = useStyles()
  return (
    <Box className={classes.flexColumn} flexDirection="horizontal" mb={1}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography>Sortera efter</Typography>
      </Box>
      <Box>
        <FormControl className={classes.formControl} size="small">
          <Select
            variant="outlined"
            labelId="sortby-label"
            id="sortby"
            value={state.sortby}
            onChange={(event) => {
              const value = event.target.value
              setState((s) => {
                return {
                  ...s,
                  sortby: value
                }
              })
              onChange({
                ...state,
                sortby: value
              })
            }}>
            <MenuItem value={0}>Senaste först</MenuItem>
            <MenuItem value={1}>Äldsta först</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}
export default SortForm
