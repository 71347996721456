import { FormControl, InputAdornment, InputLabel, Select } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/pro-solid-svg-icons"
import MenuItem from "@mui/material/MenuItem"
import { makeStyles } from "@mui/styles"
import theme from "css/theme/light.js"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const useStyles = makeStyles({
  select: {
    color: theme.palette.primary["contrastText"],
    fontSize: "14px",
    fontWeight: 400
  }
})

const LanguageButton = ({ showLangName }) => {
  const { i18n } = useTranslation()

  const lngs = {
    en: { nativeName: "English" },
    sv: { nativeName: "Svenska" }
  }

  const [language, setLanguage] = useState(i18n.resolvedLanguage)

  const changeLanguage = (event) => {
    setLanguage(event.target.value)
  }

  const classes = useStyles()

  return (
    <FormControl sx={{ pr: "8px" }}>
      {showLangName ? (
        <Select
          className={classes.select}
          IconComponent={ExpandMoreIcon}
          value={language}
          onChange={changeLanguage}
          autoWidth
          variant="standard"
          disableUnderline
          sx={{
            ".MuiSelect-icon": {
              color: theme.palette.primary["contrastText"]
            },
            ".css-16t0q34-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
              backgroundColor: "transparent"
            }
          }}
          startAdornment={
            <InputAdornment className={classes.selectAdornment} position="start">
              <FontAwesomeIcon color={theme.palette.primary["main"]} size="md" icon={faGlobe} />
            </InputAdornment>
          }>
          {Object.keys(lngs).map((lng) => (
            <MenuItem
              key={lng}
              value={lng}
              sx={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "disabled",
                color: i18n.resolvedLanguage !== lng && theme.palette.grey["700"]
              }}
              onClick={() => i18n.changeLanguage(lng)}>
              {lngs[lng].nativeName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          variant="standard"
          disableUnderline
          value={language}
          onChange={changeLanguage}
          IconComponent={() => null}
          renderValue={() => (
            <FontAwesomeIcon color={theme.palette.primary["main"]} size="lg" icon={faGlobe} />
          )}
          sx={{
            ".MuiSelect-icon": {
              color: theme.palette.primary["contrastText"]
            },
            ".css-16t0q34-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
              backgroundColor: "transparent"
            },
            ".css-16t0q34-MuiSelect-select-MuiInputBase-input-MuiInput-input": {
              padding: "4px !important"
            }
          }}>
          {Object.keys(lngs).map((lng) => {
            if (lng in lngs) {
              return (
                <MenuItem
                  key={lng}
                  value={lng}
                  sx={{
                    fontWeight: i18n.resolvedLanguage === lng ? "bold" : "disabled",
                    color: i18n.resolvedLanguage !== lng && "#757575"
                  }}
                  onClick={() => i18n.changeLanguage(lng)}>
                  {lngs[lng].nativeName}
                </MenuItem>
              )
            }
            return null
          })}
        </Select>
      )}
    </FormControl>
  )
}

export default LanguageButton
