import {Document, Page, StyleSheet} from '@react-pdf/renderer'
import React from 'react'
import ItemsTable from './ItemsTable'
import Title from './Title'

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1.5,
    flexDirection: 'column'
  }
})

const PrintTemplate = ({columns, data, prepareRow, printTitle}) => {
  data.forEach((r) => {
    prepareRow(r)
  })

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        {/* <View style={{width: '200px'}}>
          <Image
            style={{width: 'auto', height: 'auto'}}
            src="assets/transportportalen-logotyp.png"
          />
        </View> */}
        <Title title={printTitle ? printTitle : 'Transportportalen'} />
        <ItemsTable data={data} columns={columns} />
      </Page>
    </Document>
  )
}

export default PrintTemplate
