import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import makeStyles from "@mui/styles/makeStyles"
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"
import { useEffect, useState } from "react"
import { CertificateDocument } from "./CertificateDocument"

const useStyles = makeStyles({
  viewer: {
    height: "600px",
    width: "100%"
  },
  link: {
    textDecoration: "none"
  }
})
export const CertificateRender = ({ item }) => {
  const classes = useStyles()
  return (
    <PDFViewer className={classes.viewer}>
      <CertificateDocument
        examinationCertificate={item.examinationCertificate}
        userFullName={item.userFullName}
        examinationName={item.examinationName}
        completedDate={item.completedDate}
      />
    </PDFViewer>
  )
}
export const CertificateDownloadLink = ({ item, children }) => {
  const classes = useStyles()

  const [docItem, setDocItem] = useState()

  useEffect(() => {
    setDocItem(item)
  }, [item])
  return docItem ? (
    <PDFDownloadLink
      className={classes.link}
      document={
        <CertificateDocument
          examinationCertificate={docItem.examinationCertificate}
          userFullName={docItem.userFullName}
          examinationName={docItem.examinationName}
          completedDate={docItem.completedDate}
        />
      }
      fileName={`utbildningsbevis-${docItem.userFullName}`}>
      {({ blob, url, loading, error }) => {
        console.log(loading)
        return loading ? (
          <FontAwesomeIcon
            style={{ color: "rgba(0, 0, 0, 0.54)", padding: "10px", fontSize: "21px" }}
            icon={faSpinner}
            spin
            size="xs"
          />
        ) : (
          children
        )
      }}
    </PDFDownloadLink>
  ) : null
}
