import Drawer from '@mui/material/Drawer'
import React from 'react'
import {useStyles} from './useStyles'
import {VehicleAlarmNotificationDelete} from './VehicleAlarmNotificationDelete'
import {VehicleAlarmNotificationForm} from './VehicleAlarmNotificationForm'

export const VehicleAlarmNotificationBar = ({
  showSidebar,
  formData,
  editVehicleAlarmNotification,
  onSubmit,
  feedback,
  result,
  setShowSidebar,
  deleteNotificationSubmit,
  deleteResult
}) => {
  const classes = useStyles()

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={showSidebar}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <VehicleAlarmNotificationForm
            formData={formData}
            onSubmit={onSubmit}
            editVehicleAlarmNotification={editVehicleAlarmNotification}
            feedback={feedback}
            result={result}
            setShowSidebar={setShowSidebar}
          />
          {editVehicleAlarmNotification && (
            <VehicleAlarmNotificationDelete
              deleteNotificationSubmit={deleteNotificationSubmit}
              result={deleteResult}
              feedback={feedback}
              id={formData?.id}
            />
          )}
        </Drawer>
      </React.Fragment>
    </div>
  )
}
// export default VehicleBar;
