import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  buttonCheckbox: {
    margin: 0,
    marginBottom: 6,
    // marginRight: 5,
    width: "100%",
    padding: "10px 0",
    // borderRadius: "100vh",
    // boxShadow: "1px 3px 3px 0px rgba(0, 0, 0, 0.10)",

    "& > span": {
      padding: 0,
      marginRight: 7
    }
  },
  uncheckedIcon: {
    color: theme.palette.grey["300"]
  }
}))

export default useStyles
