import { Container } from "@mui/material";
import React from "react";
import { ReportTemplateContextProvider } from "../../components/ReportTemplateContextProvider";
import UpdateTemplatePage from "../../components/UpdateTemplatePage";

const EditTemplate = ({ match }) => {
    return (
        <>
            <ReportTemplateContextProvider>
                <Container>
                    <UpdateTemplatePage match={match} />
                </Container>
            </ReportTemplateContextProvider>
        </>
    );
};
export default EditTemplate;
