import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MenuIcon from "@mui/icons-material/Menu"
import { AppBar, Box, Grid, Hidden, IconButton, Tab, Tabs, Toolbar, useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import classnames from "classnames"
import ApiLoading from "components/ApiLoading"
import CrisisButton from "components/navigation/CrisisButton"
import LanguageButton from "components/navigation/LanguageButton"
import { allRoutes } from "generated/routes"
import React, { useEffect, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { LoginLogout } from "../../auth/LoginLogoutButton"
import { AppContext } from "../AppContextProvider"
import styles from "./style"

const getTabs = (path, app) => {
  // app.normalPages
  // app.sysadmPages
  // häri kan vi hitta sidor som matchar vår nuvarande route längre upp i trädet på toplevelnivå
  // och hitta första nivån av undersidor

  let tabs = []
  let match = null

  const normalMatch = app.normalPages.filter((item) => path.startsWith(item.route.matchPath)) // sök bland normala sidor
  if (normalMatch.length > 0) {
    match = normalMatch[0] // första bästa
  }

  if (match === null) {
    const sysadmMatch = app.sysadmPages.filter((item) => path.startsWith(item.route.matchPath)) // sök bland sysadmin sidor
    if (sysadmMatch.length > 0) {
      match = sysadmMatch[0] // första bästa
    }
  }

  if (match !== null && match.tabs !== null) {
    tabs = match.tabs
  }

  return tabs
}

const getSubTabs = (path, currentTabs, subTabs) => {
  let tabs = []
  let match = null
  const subMatch = currentTabs.filter((item) => path.startsWith(item.path))

  if (subMatch.length > 0) {
    match = subMatch[0]
  }

  if (match !== null && match.subTabs !== null) {
    tabs = match.subTabs
  }
  return tabs
}

const getPreviousPage = (path, subTabs) => {
  let tabs = null
  let match = null
  if (subTabs.length > 0) {
    subTabs.map((subTab) => {
      if (subTab && subTab.subTabs !== null) {
        const subMatch = subTab.subTabs.find((item) => path === item.path)

        if (subMatch) {
          match = { title: "Tillbaka", path: subTab.path }
        }
      }
    })
  }
  if (match !== null) {
    tabs = { title: "Tillbaka", path: match.path }
  }

  return tabs
}

const MuAppBar = (props) => {
  const { openDrawer, handleDrawerChange } = props

  const classes = styles(props)
  const theme = useTheme()
  const appContext = React.useContext(AppContext)
  const location = useLocation()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  const [tabs1, setTabs1] = React.useState([])
  const [subTabs, setSubTabs] = React.useState([])
  const [selectedPath, setPath] = useState()
  const [selectedSubPath, setSubPath] = useState()
  const [previousPage, setPreviousPage] = useState()

  useEffect(() => {
    if (appContext && appContext.appUser) {
      let tabs = getTabs(location.pathname, appContext.appUser.app)
      setTabs1(tabs)
      let currentSubTabs = getSubTabs(location.pathname, tabs, subTabs)
      setSubTabs(currentSubTabs)
      let currentPreviousPage = getPreviousPage(location.pathname, currentSubTabs)
      setPreviousPage(currentPreviousPage)
    }
  }, [appContext, location.pathname])

  useEffect(() => {
    if (!selectedPath) {
      setPath(location.pathname)
    }
  }, [selectedPath, location.pathname])

  useEffect(() => {
    if (!selectedSubPath) {
      setSubPath(location.pathname)
    }
  }, [selectedSubPath, location.pathname])

  const handleChange = (_event, newValue) => {
    setPath(newValue)
  }

  const handleChangeSubPath = (_event, newValue) => {
    setSubPath(newValue)
  }

  return (
    <AppBar position="fixed" className={classnames(classes.appBar)}>
      <Hidden smUp implementation="css">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid>
            {appContext.isAuth && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => handleDrawerChange()}
                edge="start"
                className={classes.menuButton}
                size="large">
                <MenuIcon />
              </IconButton>
            )}
          </Grid>

          <Link style={{ opacity: "1" }} to={allRoutes.Dashboard()}>
            <img
              src={"assets/transportportalen-logo-inverted.svg"}
              alt={"logo"}
              className={classes.logoResp}
            />
          </Link>
          <Grid>
            <Hidden smUp implementation="css">
              <Box display="flex" alignItems="center">
                {appContext.isAuth && <LanguageButton />}
                <LoginLogout />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smDown={tabs1.length > 0 ? false : true}>
        <Toolbar className={classes.mainToolbar}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <div style={{ width: "100%" }}>
              <Grid
                container
                direction="row"
                justifyContent={tabs1.length > 0 ? "space-between" : "flex-end"}
                alignItems="center">
                {selectedPath != null && tabs1.length > 0 && (
                  <Tabs
                    onChange={handleChange}
                    value={selectedPath}
                    className={classes.tabsLevel1}
                    indicatorColor="primary"
                    classes={{
                      indicator: classes.bigIndicator,
                      scroller: classes.scroller,
                      root: classes.tabsRoot
                    }}
                    variant={"scrollable"}
                    scrollButtons={matches && "auto"}>
                    {appContext.isAuth &&
                      tabs1.map((item, index) => {
                        return (
                          <Tab
                            key={item.title}
                            component={NavLink}
                            to={item.path}
                            label={item.title}
                            value={item.path}
                            isActive={(match, location) => {
                              if (!match) {
                                return false
                              }
                              setPath(match.url)
                            }}
                          />
                        )
                      })}
                  </Tabs>
                )}

                <Hidden mdDown implementation="css">
                  <Box display="flex" alignItems="center">
                    {appContext.isAuth && (
                      <>
                        <CrisisButton />
                        <LanguageButton showLangName={true} />
                      </>
                    )}
                    <LoginLogout />
                  </Box>
                </Hidden>
                <Box className={classes.showInSm} alignItems="center">
                  {appContext.isAuth && <LanguageButton />} <LoginLogout />
                </Box>
              </Grid>

              {previousPage && (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.tabsLevel2}>
                  <NavLink to={previousPage.path} label={previousPage.title} value={previousPage.path}>
                    <FontAwesomeIcon icon={faChevronLeft} size="xs" /> {previousPage.title}
                  </NavLink>
                </Grid>
              )}
              {appContext.isAuth && subTabs && subTabs.length > 0 && !previousPage && (
                <Tabs
                  indicatorColor="primary"
                  className={classes.tabsLevel2}
                  value={selectedSubPath}
                  onChange={handleChangeSubPath}
                  variant={"scrollable"}>
                  {subTabs.map((item, index) => {
                    return (
                      <Tab
                        key={item.title}
                        component={NavLink}
                        to={item.path}
                        label={item.title}
                        value={item.path}
                        isActive={(match, location) => {
                          if (!match) {
                            return false
                          }
                          setSubPath(match.url)
                        }}
                      />
                    )
                  })}
                </Tabs>
              )}
            </div>
          </Grid>
        </Toolbar>
      </Hidden>
      <ApiLoading />
    </AppBar>
  )
}

export default MuAppBar
