import { Box } from "@mui/material";
import { DefaultColumnFilter, SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes";
import { allRoutes } from "generated/routes";
import moment from "moment";
import { Link } from "react-router-dom";

const getFilterType = (filterType) => {
    switch (filterType) {
        case 1:
            return SelectColumnFilter;
        default:
            return DefaultColumnFilter;
    }
};

const getFilterLabel = (title) => {
    switch (title) {
        case "Kundnamn":
            return "Alla kunder";

        default:
            return `Alla ${title}`;
    }
};

const getValueFromKey = (row, data) => {
    switch (data.dataType) {
        case 1: // string
            return row.items[data.accessor];
        case 2: // integer
            return isNaN(row.items[data.accessor]) || row.items[data.accessor] === "" ? 0 : parseInt(row.items[data.accessor]);
        case 3: // dateshort
            return row.items[data.accessor] && moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD");
        case 4: // datedetailed
            return row.items[data.accessor] && moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD hh:mm");
        case 5: // decimal 2
            return !isNaN(row.items[data.accessor]) ? parseFloat(row.items[data.accessor]).toFixed(2).replace(".", ",") : "";
        case 6: // date only
            return row.items[data.accessor] && moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD");
        case 7: // boolean
            return row.items[data.accessor] !== undefined ? (row.items[data.accessor] === true ? "Ja" : "Nej") : "";
        default:
            return row.items[data.accessor] ? row.items[data.accessor] : null;
    }
};

export const convertBeStructureToDataGridStructure = (apiResultData) => {
    // vi får ett objekt från api som liknar datagrid men vi behöver ändå konvertera det till nån form av react
    // främst när vi typbestämmer kolumner och hur de ska visas.

    let reportData = {
        columns: [],
        subColumns: [],
        rows: apiResultData.rows,
        footer: apiResultData.totalSum
    };

    apiResultData.columns.forEach((data, row) => {
        reportData.columns.push({
            accessor: (row) => {
                switch (data.dataType) {
                    case 1: // string
                        return row.items[data.accessor];
                    case 2: // integer
                        return isNaN(row.items[data.accessor]) || row.items[data.accessor] === "" ? 0 : parseInt(row.items[data.accessor]);
                    case 3: // dateshort
                        return moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD");
                    case 4: // datedetailed
                        return moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD hh:mm");
                    case 5: // decimal 2
                        return parseFloat(row.items[data.accessor]).toFixed(2).replace(".", ",");
                    default:
                        return row.items[data.accessor];
                }
            },
            Header: data.title,
            tooltip: data.description,
            Filter: getFilterType(data.datafilterId),
            FilterHeader: getFilterLabel(data.title),
            mainFilter: data.mainFilter,
            Cell: ({ row }) => {
                return (
                    row.original.items && (
                        <Box
                            display="block"
                            component={Link}
                            to={allRoutes.VehicleregistryVehiclesDetails(row.original.id)}
                            style={{
                                color: "inherit",
                                textDecoration: "inherit"
                            }}
                        >
                            {getValueFromKey(row.original, data)}
                        </Box>
                    )
                );
            }
        });
    });

    reportData.columns.push({
        accessor: "id",
        Header: "",
        tooltip: "",
        hidden: true
    });

    //SubColumns
    apiResultData.subColumns.forEach((data) => {
        reportData.subColumns.push({
            accessor: (row) => {
                switch (data.dataType) {
                    case 1: // string
                        return row.items[data.accessor];
                    case 2: // integer
                        return isNaN(row.items[data.accessor]) || row.items[data.accessor] === "" ? 0 : parseInt(row.items[data.accessor]);
                    case 3: // dateshort
                        return moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD");
                    case 4: // datedetailed
                        return moment(row.items[data.accessor]).isValid() && moment(row.items[data.accessor]).format("YYYY-MM-DD hh:mm");
                    case 5: // decimal 2
                        return parseFloat(row.items[data.accessor]).toFixed(2).replace(".", ",");
                    default:
                        return row.items[data.accessor];
                }
            },
            Header: data.title,
            tooltip: data.description,
            Filter: getFilterType(data.datafilterId),
            FilterHeader: getFilterLabel(data.title),
            mainFilter: data.mainFilter,
            Cell: ({ row }) => {
                return (
                    row.original.items && (
                        <Box
                            display="block"
                            component={Link}
                            to={allRoutes.VehicleregistryVehiclesDetails(row.original.id)}
                            style={{
                                color: "inherit",
                                textDecoration: "inherit"
                            }}
                        >
                            {getValueFromKey(row.original, data)}
                        </Box>
                    )
                );
            }
        });
    });
    return reportData;
};
