import React from 'react'
import { ListItem, ListItemText, Skeleton } from "@mui/material"

const numberOfCards = [1, 2, 3]

export const SkeletonDetails = () => {
    return (
        <>
            {numberOfCards.map((index) => (
                <ListItem disableGutters sx={{ p: 0, mt: 2 }}>
                    <ListItemText sx={{ m: 0 }}>
                        <Skeleton variant="rounded" height={100} />
                    </ListItemText>
                </ListItem>
            ))
            }

        </>
    )
}

